import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HasAccess from '../../components/HasAccess/HasAccess';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';

import reducer from '../../reducers/callMonitoring';
import { types as AppTypes, actions as AppActions } from '../../reducers/app';
import { currentCompanyLevel, currentCompanyOrgType, orgTypes } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import saga from '../../sagas/callMonitoring';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as CallMonitoringAction, types as callMonitoringTypes } from '../../reducers/callMonitoring';

import CallMonitoringTable from '../../containers/CallMonitoringTable/CallMonitoringTable';

function CallMonitoringPage(props) {
    function modalHandleOpen() {
        props.setCurrentModal(callMonitoringTypes.ADD_CALL_MONITORING);
    }

    useEffect(() => {
        props.setPageTitle('Call Monitoring');
        props.setPageTitleId('call-monitoring-header');
    }, []);

    return (
        <div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                    <HasAccess
                        permissions={["ADD_CALL_MONITORING"]}
                    >
                        {props.currentCompanyOrgType === orgTypes.CUSTOMER &&
                            <Button className="button" id="btnAddCallMonitoring" variant="contained" color="primary" onClick={modalHandleOpen} >
                                Configure
                            </Button>
                        }
                    </HasAccess>
                </Grid>
            </Grid>
            <br/>
            <CallMonitoringTable setCurrentModal={props.setCurrentModal} 
                            fetchCallMonitoring={props.fetchCallMonitoring} />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    currentCompanyOrgType: currentCompanyOrgType()
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchCallMonitoring: () => dispatch(CallMonitoringAction.getCallMonitoringRequest()),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'callMonitoring', reducer });
const withSaga = injectSaga({ key: 'callMonitoring', saga });
const withConnect = connect(mapStateToProps,mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(CallMonitoringPage);