import React, { useState } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import CompanyFeaturesApi from '../../../apis/companyFeaturesApi';
import { createStructuredSelector } from "reselect";
import { selectUserCompany, currentCompanyLevel } from '../../../reducers/auth';
import { actions as OrganizationManagementActions } from '../../../reducers/organizationManagement';
import { types as AppTypes, selectMyE911UserWelcomeEmailEnabled } from '../../../reducers/app';
import { connect } from "react-redux";
import Switch from '@material-ui/core/Switch';

import Grid from '@material-ui/core/Grid';

function MyE911UserWelcomeEmailFeature(props) {
    
    const [submitStatus, setSubmitStatus] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const company = props.company.toJS();

    const handleChange = () => {
        setDataLoading(true);
        (props.myE911UserWelcomeEmailEnabled ? 
            CompanyFeaturesApi.removeCompanyFeatures(company.id, 'MYE911_USER_WELCOME_EMAIL') : 
            CompanyFeaturesApi.addCompanyFeatures(company.id, 'MYE911_USER_WELCOME_EMAIL')
        ).then(response => {
            setSubmitStatus('success');
            setDataLoading(false);
            props.getOrganizationFeatures();
        }).catch(error => {
            console.log(error)
            setSubmitStatus('failure');
            setDataLoading(false);
        });
    }

    return (
         <div>
            {dataLoading && <LinearProgress/>}
            <Grid container justify="center" direction="row" alignItems="center" style={{margin: '20px'}}>
                {dataLoading && <LinearProgress/>}
                <Grid item xs={12} sm={3}>
                    <label htmlFor="mye911UserWelcomeEmailSwitch">Send Auto-Generated Welcome Email to MyE911 Users</label>
                </Grid>
                <Grid item xs={12} sm={1}>
                    <Switch
                        id="mye911UserWelcomeEmailSwitch"
                        name="mye911UserWelcomeEmailSwitch"
                        color="primary"
                        checked={props.myE911UserWelcomeEmailEnabled}
                        onChange={handleChange}
                        disabled={dataLoading || !props.disableInput}/>
                </Grid>
            </Grid>
            {submitStatus === 'failure' && 
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 680 }}>
                <ErrorOutlineIcon style={{ color: '#f4473a', marginRight: 10, fontSize: 30 }}/>
                <div style={{ color: '#f4473a' }}>ERROR! We were not able to set Auto-Generated Welcome Email to MyE911 Users feature. Please try again later.</div>
            </div>
            }
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    company: selectUserCompany(),
    myE911UserWelcomeEmailEnabled: selectMyE911UserWelcomeEmailEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationFeatures: () => dispatch(OrganizationManagementActions.getOrganizationFeatures())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MyE911UserWelcomeEmailFeature);