import { axiosInstance } from "./api";
/* Cirrus Admin-Service /company Controller */
const USER_PROFILE_ENDPOINT = "admin-service/userProfile/";
const USER_PROFILE_ROLE_TYPE_ENDPOINT = "admin-service/userProfile/roles";
const USER_PROFILE_PERMISSIONS_ENDPOINT = "/auth-service/permissions/";
const USER_PROFILE_ROLES_ENDPOINT = "/auth-service/roles";
const USER_PROFILE_COMPANY_ENDPOINT = USER_PROFILE_ENDPOINT + "company/";

export default class UsersAPI {
  static async getUsersList(companyId, page, pageSize, searchTerm, sortBy, direction, orgLevel) {
    let roleNames;
    if (orgLevel) {
      const roles = await UsersAPI.getRoles(orgLevel);
      roleNames = roles.data.filter(item => !item.hidden).map(item => item.name);
    }

    const params = {
      params: {
        page,
        pageSize,
        searchTerm,
        sortBy,
        direction,
        roles: roleNames ? roleNames.join() : null
      }
    };
    if (companyId) {
      return axiosInstance.get(
        USER_PROFILE_COMPANY_ENDPOINT + companyId,
        params
      );
    }
    return axiosInstance.get(USER_PROFILE_ENDPOINT, params);
  }
  
  static getUserById(userId) {
    return axiosInstance.get(USER_PROFILE_ENDPOINT + userId);
  }

  static addUser(user) {
    const payload = {
      roleName: user.roleName,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      companyId: user.companyId,
      ssoType: user.ssoType,
      eonUser: user.eonUser
    };

    return axiosInstance.post(USER_PROFILE_ENDPOINT, payload);
  }

  static editUser(user, row) {
    const payload = {
      id: row.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      companyId: user.companyId,
      roleName: user.roleName,
      ssoType: user.ssoType,
      eonUser: user.eonUser
    };

    return axiosInstance.put(USER_PROFILE_ENDPOINT, payload);
  }

  static deleteUser(id) {
    return axiosInstance.delete(USER_PROFILE_ENDPOINT + id);
  }

  /**
   *  @deprecated use getRoles() instead.
   */
  static getRoleTypes() {
    return axiosInstance.get(USER_PROFILE_ROLE_TYPE_ENDPOINT);
  }

  static getUserPermissions() {
    return axiosInstance.get(`${USER_PROFILE_PERMISSIONS_ENDPOINT}`);
  }

  static getRoles(orgLevel, apiRoles) {
    if (apiRoles === null || apiRoles === undefined)
      apiRoles = false;
    if (orgLevel === "ALL") {
      return axiosInstance.get(`${USER_PROFILE_ROLES_ENDPOINT}?orgLevel=${orgLevel}&apiRoles=${apiRoles}`)
          .then(response => {
            response.data.forEach(role => {
              role.displayName = role.name.split("_").map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
              }).join(" ");
            });

            return response;
          })
          .catch(error => {
            console.log(error);
            throw error;
          });
    }

    return axiosInstance.get(`${USER_PROFILE_ROLES_ENDPOINT}?orgLevel=${orgLevel}&apiRoles=${apiRoles}`);
  }

}
