import { axiosInstance } from './api';
/* Cirrus Admin-Service /locations Controller */
const LOCATIONS_ENDPOINT = 'geography-service/locations';
const LOCATIONS_ENDPOINT_SEARCH = 'geography-service/locations/search';
const SET_LOCATIONS_PARSED = '/geography-service/locations/parsed';
const SET_LOCATIONS_COMPACT = '/geography-service/locations/compactAddress';

export default class LocationsAPI {
    static getLocationById(locationId) {
        return axiosInstance.get(`${LOCATIONS_ENDPOINT}/${locationId}`)
    }
    
    static getLocations(orgId, page, pageSize, sortBy, direction, pattern, origin) {
        return axiosInstance.get(!pattern ? `${LOCATIONS_ENDPOINT}/parent/${orgId}` : `${LOCATIONS_ENDPOINT_SEARCH}`,{
            params: {
                page,
                pageSize,
                sortBy,
                direction,
                pattern,
                origin,
                orgId: pattern ? orgId : null
            }
        });
    }

    static addLocation(location) {
        const payload = {
            name: location.name,
            elin: location.phoneNumber,
            htmlLinkName: location.linkUrl ? location.linkName : '',
            htmlLinkUrl: location.linkUrl,
            info: location.info,
            fullAddress: location.address,
            latitude: location.address ? '' : location.latitude,
            longitude: location.address ? '' : location.longitude,
            organizationUnitId: location.organizationUnitId,
            orgNameOverride: location.overrideOrganizationName,
            alternateId: location.alternateId,
            callbackNumber: location.callbackNumber
        };
        return axiosInstance.post(LOCATIONS_ENDPOINT, payload);
    }

    static autoComplete(searchRequest, orgId) {
        const payload = {
            returnAddresses: true,
            returnLocations: false,
            searchText: searchRequest,
            orgId
        }
        return axiosInstance.post(`${LOCATIONS_ENDPOINT}/search`, payload);
    }
    
    static locationsAutoComplete(searchRequest, orgId, origin) {
        const payload = {
            returnAddresses: false,
            returnLocations: true,
            orgId,
            searchText: searchRequest,
            origin: origin ,
            pageSize: 100
        }
        return axiosInstance.post(`${LOCATIONS_ENDPOINT}/search`, payload);
    }
    
    static deleteLocation(locationId) {
        return axiosInstance.delete(`${LOCATIONS_ENDPOINT}/${locationId}`);
    }
    
    static getLocationUserCount(locationId) {
        return axiosInstance.get(`${LOCATIONS_ENDPOINT}/deviceUsers/currentLocation/${locationId}`);
    }

    static getLocationCount(organizationUnit) {
        return axiosInstance.get(`${LOCATIONS_ENDPOINT}/count/parent/${organizationUnit}`)
    }
    static editLocation(location) {
        const payload = {
            name: location.name,
            elin: location.phoneNumber,
            htmlLinkName: location.linkUrl ? location.linkName : '',
            htmlLinkUrl: location.linkUrl,
            info: location.info,
            id: location.id,
            fullAddress: location.fullAddress,
            latitude: location.fullAddress ? '' : location.latitude,
            longitude: location.fullAddress ? '' : location.longitude,
            orgNameOverride: location.overrideOrganizationName,
            alternateId: location.alternateId,
            callbackNumber: location.callbackNumber
        };
        return axiosInstance.put(LOCATIONS_ENDPOINT, payload);
    }

    static getLocationsByDeviceUserId(deviceUserId, page, pageSize, sortBy, direction){
        return axiosInstance.get(LOCATIONS_ENDPOINT + '/deviceUser/' +deviceUserId + `?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&direction=${direction}`);
    }

    static createLocationParsed(location) {
        const hasAddress = location.multipleAddressSelect.houseNumber ||
                            location.multipleAddressSelect.streetName ||
                            location.multipleAddressSelect.streetType ||
                            location.multipleAddressSelect.prefixDirectional ||
                            location.multipleAddressSelect.postDirectional ||
                            location.multipleAddressSelect.zipCode ||
                            location.multipleAddressSelect.city ||
                            location.multipleAddressSelect.stateProvince;

        const payload = {
            name: location.name,
            info: location.info,
            organizationUnitId: location.organizationUnitId,
            parsedAddress: {
                houseNumber: location.multipleAddressSelect.houseNumber,
                streetName: location.multipleAddressSelect.streetName,
                streetType: location.multipleAddressSelect.streetType,
                zipCode: location.multipleAddressSelect.zipCode,
                city: location.multipleAddressSelect.city,
                country: location.multipleAddressSelect.country,
                state: location.multipleAddressSelect.state,
                prefixDirectional: location.multipleAddressSelect.prefixDirectional,
                postDirectional: location.multipleAddressSelect.postDirectional
            },
            latitude: hasAddress ? '' : location.latitude,
            longitude: hasAddress ? '' : location.longitude,
            orgNameOverride: location.overrideOrganizationName,
            alternateId: location.alternateId,
            callbackNumber: location.callbackNumber
        };
        return axiosInstance.post(SET_LOCATIONS_PARSED, payload);
    }
    
    static editLocationParsed(location) {
        const hasAddress = location.multipleAddressSelect.houseNumber ||
                            location.multipleAddressSelect.streetName ||
                            location.multipleAddressSelect.streetType ||
                            location.multipleAddressSelect.prefixDirectional ||
                            location.multipleAddressSelect.postDirectional ||
                            location.multipleAddressSelect.zipCode ||
                            location.multipleAddressSelect.city ||
                            location.multipleAddressSelect.state;

        const payload = {
            name: location.name,
            info: location.info,
            id: location.id,
            organizationUnitId: location.parentId,
            parsedAddress: {
                houseNumber: location.multipleAddressSelect.houseNumber,
                streetName: location.multipleAddressSelect.streetName,
                streetType: location.multipleAddressSelect.streetType,
                zipCode: location.multipleAddressSelect.zipCode,
                city: location.multipleAddressSelect.city,
                country: location.multipleAddressSelect.country,
                state: location.multipleAddressSelect.state,
                prefixDirectional: location.multipleAddressSelect.prefixDirectional,
                postDirectional: location.multipleAddressSelect.postDirectional
            },
            latitude: hasAddress ? '' : location.latitude,
            longitude: hasAddress ? '' : location.longitude,
            orgNameOverride: location.overrideOrganizationName,
            alternateId: location.alternateId,
            callbackNumber: location.callbackNumber
        };
        return axiosInstance.put(SET_LOCATIONS_PARSED, payload);
    }

    static createLocationAlternate(location) {
        const hasAddress = location.houseNumber ||
                            location.street ||
                            location.streetType ||
                            location.prefixDirection ||
                            location.postDirection ||
                            location.zip ||
                            location.city ||
                            location.stateProvince;

        const payload = {
            name: location.name,
            organizationUnitId: location.organizationUnitId,
            elin: location.phoneNumber,
            info: location.info,
            htmlLinkName: location.linkUrl ? location.linkName : '',
            htmlLinkUrl: location.linkUrl,
            parsedAddress: {
                streetName: location.street,
                zipCode: location.zip,
                city: location.city,
                country: location.country,
                state: location.stateProvince,
                houseNumber: location.houseNumber,
                houseNumberExtension: location.houseNumberExtension,
                prefixDirectional: location.prefixDirection,
                streetType: location.streetType,
                postDirectional: location.postDirection,
                orgId: location.organizationUnitId
            },
            latitude: hasAddress ? '' : location.latitude,
            longitude: hasAddress ? '' : location.longitude,
            orgNameOverride: location.overrideOrganizationName,
            alternateId: location.alternateId,
            callbackNumber: location.callbackNumber
        };
        
        return axiosInstance.post(SET_LOCATIONS_PARSED, payload);
    }
    
    static editLocationAlternate(location) {
        const hasAddress = location.street ||
                            location.streetType ||
                            location.prefixDirection ||
                            location.postDirection ||
                            location.houseNumber ||
                            location.zip ||
                            location.city ||
                            location.stateProvince;

        const payload = {
            name: location.name,
            info: location.info,
            id: location.id,
            elin: location.phoneNumber,
            htmlLinkName: location.linkUrl ? location.linkName : '',
            htmlLinkUrl: location.linkUrl,
            parsedAddress: {
                streetName: location.street,
                zipCode: location.zip,
                city: location.city,
                country: location.country,
                state: location.stateProvince,
                houseNumber: location.houseNumber,
                houseNumberExtension: location.houseNumberExtension,
                prefixDirectional: location.prefixDirection,
                streetType: location.streetType,
                postDirectional: location.postDirection,
                orgId: location.organizationUnitId
            },
            latitude: hasAddress ? '' : location.latitude,
            longitude: hasAddress ? '' : location.longitude,
            orgNameOverride: location.overrideOrganizationName,
            alternateId: location.alternateId,
            callbackNumber: location.callbackNumber
        };
        
        return axiosInstance.put(SET_LOCATIONS_PARSED, payload);
    }
}