import React, { Component } from 'react';
import Button from '@material-ui/core/Button'; 
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/pidfloDevices';
import { connect } from 'react-redux';
import NumberFormatter from '../../../components/NumberFormatter/NumberFormatter';


class DeletePidfloDevicesModal extends Component {
    handleDelete = () => {
        this.props.submitDeletePidfloDevices(this.props.modalData.id);
    }
    
    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                        Please confirm that you would like to delete the following PIDF-LO device:
                        </p>
                    </Grid>
                    <Grid item >
                        <b id="pidfloDevicesPhoneNumber">{!isNaN(this.props.modalData.phoneNumber) ? <NumberFormatter number={this.props.modalData.phoneNumber} /> : this.props.modalData.phoneNumber}</b>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeletePidfloDevices: (id) => dispatch(actions.deletePidfloDevicesRequest(id))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper
)(DeletePidfloDevicesModal);