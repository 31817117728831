import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HasAccess from '../../components/HasAccess/HasAccess';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MacListTable from '../../containers/MacListTable/macListTable';

import { actions as MacAddressActions, makeSelectMacAddressList, makeSelectMacAddressListLoading } from '../../reducers/networkDiscovery';

import { types as locationsTypes } from '../../reducers/networkDiscovery';
import { actions as AppActions } from '../../reducers/app';

function MacList(props) {
    function modalHandleOpen(){
        props.setCurrentModal(locationsTypes.ADD_MAC_ADDRESS);
    }

        return (
            <div>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <HasAccess
                        permissions={["ADD_NETWORK_DISCOVERY"]}
                    >
                        <Button className="button" id="btnAddBuilding" variant="contained" color="primary" onClick={modalHandleOpen}>
                            Add MAC Address Mapping
                        </Button> 
                    </HasAccess>
                </Grid>
                <br />
                <MacListTable 
                macAddressList={props.macAddressList} 
                setCurrentModal={props.setCurrentModal} 
                loading={props.loading}/>
            </div>    
        );
    
}

MacList.propTypes = {
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    macAddressList: makeSelectMacAddressList(),
    loading: makeSelectMacAddressListLoading(),
});

const mapDispatchToProps = dispatch => {
    return {
        fetchMacList: (page, pageSize) => dispatch(MacAddressActions.getMacAddressRequest(page, pageSize)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MacList);
