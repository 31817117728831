import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/organizations';
import { connect } from 'react-redux';
import { orgTypes } from '../../../reducers/auth';


function DeleteOrganizationModal(props) {

    const [confirmed, setConfirmed] = useState(false);

    function handleDelete() {
        if(!confirmed && (props.modalData.orgType === orgTypes.SERVICE_PROVIDER || props.modalData.orgType === orgTypes.RESELLER)) {
            setConfirmed(true);
        }
        else {
            props.submitDeleteOrganization(props.modalData.id, props.modalData.parentId);
        }
    }

    function handleText() {
        if (!confirmed && (props.modalData.orgType === orgTypes.SERVICE_PROVIDER || props.modalData.orgType === orgTypes.RESELLER)) {
            return (
                <div style={{textAlign: 'center'}}>
                    <Grid item >
                        <p>
                            <span style={{ color: 'red', fontWeight: 'bold' }}>WARNING: </span> 
                            The following Organization cannot be deleted without first deleting all of its Sub-Organizations:
                        </p>
                    </Grid>
                    <Grid item >
                        <b id="organizationName">{props.modalData.name}</b>
                    </Grid>
                </div>
            );
        } else {
            return (
                <div style={{textAlign: 'center'}}>
                    <Grid item >
                        <p>Please confirm that you would like to delete the following organization: <br/></p>
                    </Grid>
                    <Grid item >
                        <b id="organizationName">{props.modalData.name}</b>
                    </Grid>
                </div>
            );
        }
    }

    const { modalLoading } = props;
    return(
        <div>
            {modalLoading && <LinearProgress/>}
            <Grid container 
                    direction="column" 
                    justify="center"
                    alignItems="center"
                    spacing={16} >
                {handleText()}
                <Grid item >
                    <Button id="deleteBtn"
                            variant="contained" 
                            color="secondary" 
                            onClick={handleDelete}>
                        DELETE
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteOrganization: (id, parentId) => dispatch(actions.deleteOrganizationRequest(id, parentId))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteOrganizationModal);