import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeviceUsersLocationsList from '../../containers/DeviceUsersLocationsList/deviceUsersLocationsList';
import DeviceUsersDevicesList from '../../containers/DeviceUsersDevicesList/deviceUsersDevicesList';

const DeviceUsersTabs = props => {
    const [currentTab, setCurrentTab] = useState(0);
    
    const TABS_CONST = {
        LOCATIONS: 'Locations',
        DEVICES: 'Devices'
    }
    
    const handleChange = (event, value) => {
        setCurrentTab(value);
    };

    return (
        <AppBar position="static" color="default">
            <Tabs className="tabs-bar"
                value={currentTab} 
                onChange={handleChange} 
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab id={`locations-header-${props.row.rowId}`} key={0} label={TABS_CONST.LOCATIONS}/> 
                <Tab id={`devices-header-${props.row.rowId}`} key={1} label={TABS_CONST.DEVICES}/> 
            </Tabs>
            {
                currentTab === 0 && <DeviceUsersLocationsList 
                    deviceUserId={props.row.original.id} 
                    currentLocationId={props.row.original.currentEmergencyResponseLocation ? props.row.original.currentEmergencyResponseLocation.id : null }
                    setCurrentModal={props.setCurrentModal} 
                />
            }
            {
                currentTab === 1 && <DeviceUsersDevicesList 
                    deviceUserId={props.row.original.id} 
                    currentLocationId={props.row.original.currentEmergencyResponseLocation ? props.row.original.currentEmergencyResponseLocation.id : null }
                    setCurrentModal={props.setCurrentModal} 
                    parentRowUserData={props.row.original}
                />
            }
            </AppBar>
    );
};

export default DeviceUsersTabs;