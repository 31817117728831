import { axiosInstance } from './api';
/* Cirrus Admin-Service /company Controller */
const COMPANY_ENDPOINT = 'admin-service/company/';
const COMPANY_FEATURES_ENDPOINT = 'admin-service/companyFeatures/company/';

export default class CompanyAPI {
    static getOrganizationList(page, pageSize, searchTerm, sortBy, direction) {
        return axiosInstance.get(COMPANY_ENDPOINT, {
            params: {
               page,
               pageSize,
               searchTerm,
               sortBy,
               direction
           }
        })
    }
    
    static getOrganizationByOrgType(page, pageSize, parentId, searchTerm, sortBy, sortDirection, type) {
        return axiosInstance.get(COMPANY_ENDPOINT, {
            params: {
               page,
               pageSize,
               parentId,
               searchTerm,
               sortBy,
               sortDirection,
               type: type === undefined || type.length === 0 ? type : type.join(), 
           }
        })
    }

    static getOrganizationsByParentId(parentId, page, pageSize, searchTerm, sortBy, direction) {
        if(parentId && parentId.length > 0) {
            const getOrganizationsByParentIdUrl = COMPANY_ENDPOINT+ `parent/${parentId}`;
            return axiosInstance.get(getOrganizationsByParentIdUrl, {
                params: {
                   page,
                   pageSize,
                   searchTerm,
                   sortBy,
                   direction
               }
            });
        }
        else {
            return axiosInstance.get(COMPANY_ENDPOINT, {
                params: {
                   page,
                   pageSize,
                   searchTerm,
                   sortBy,
                   direction
               }
            }) 
        }
    }

    static getOrganizationById(id) {
        return axiosInstance.get('/' + COMPANY_ENDPOINT + id);
    }

    static addOrganization(organization) {
        let payload = {
            description: organization.description,
            name: organization.name,
            subtenantEnabled: organization.allowSubTenants,
            parentId: organization.parentId,
            licenseModel: organization.licenseTypes,
            locationTreeType: organization.locationTreeType,
            requireLocationInfo: organization.requireLocationInfo,
            callRecordingEnabled: organization.callRecordingEnabled,
            cerEnabled: organization.cerEnabled,
            alternateDeviceUserID: organization.alternateDeviceUserID,
            enforceDeviceUidUniqueness: organization.enforceDeviceUidUniqueness,
            overrideDestination: organization.overrideDestination ? organization.overrideDestination.type : null,
            informaCastEnabled: organization.informaCastEnabled,
            managerDataSync : organization.managerDataSync,
            managerDataSyncKey : organization.managerDataSyncKey,
            alternateLocationId: organization.alternateLocationId,
            orgType: organization.orgType,
            usageBasedLicensing: organization.usageBasedLicensing,
            externalOrgId: organization.externalOrgId,
            features: organization.horizonPrimeEnabled ? ['HORIZON_PRIME'] : [] 
        }
        return axiosInstance.post(COMPANY_ENDPOINT, payload);
    }

    static editOrganization(organization, row) {
        let payload = {
            description: organization.description,
            name: organization.name,
            id: row.id,
            parentId: organization.parentId,
            subtenantEnabled: organization.allowSubTenants,
            locationTreeType: organization.locationTreeType,
            requireLocationInfo: organization.requireLocationInfo,
            callRecordingEnabled: organization.callRecordingEnabled,
            cerEnabled: organization.cerEnabled,
            alternateDeviceUserID: organization.alternateDeviceUserID,
            enforceDeviceUidUniqueness: organization.enforceDeviceUidUniqueness,
            overrideDestination: organization.overrideDestination ? organization.overrideDestination.type : null,
            informaCastEnabled: organization.informaCastEnabled,
            managerDataSync : organization.managerDataSync,
            managerDataSyncKey : organization.managerDataSyncKey,
            alternateLocationId: organization.alternateLocationId,
            orgType: organization.orgType,
            usageBasedLicensing: organization.usageBasedLicensing,
            externalOrgId: organization.externalOrgId,
            features: organization.horizonPrimeEnabled ? ['HORIZON_PRIME'] : [] 
        }
        return axiosInstance.put(COMPANY_ENDPOINT, payload);
    }

    static deleteOrganization(id) {
        return axiosInstance.delete(COMPANY_ENDPOINT + id);
    }
}
