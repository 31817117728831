import React, { useState, } from 'react';
import { withStyles } from "@material-ui/core/styles";

import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions as CallMonitoringActions } from '../../../reducers/callMonitoring';
import { connect } from 'react-redux';

import { userCompanyId, selectUserCompany } from '../../../reducers/auth';
import { selectCerEnabled }  from '../../../reducers/app';
import { createStructuredSelector } from 'reselect';

import AddEditCallMonitoringForm from './AddEditCallMonitoringForm';

const styles = theme => ({
    section: {
        width: '450px',
        display: 'flex',
        alignItems: 'flex-end'
    },
    table: {
        clear: 'both'
    },
    field: {
        width: '100%'
    },
    alignRight: {
        float: 'right',
        width: '45%',
        marginTop: '14px'
    },
    subtitle: {
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        left: '170px',
        top: '3px'
    },
    tooltip: {
      fontSize: '14px'
    }
});

// Variables
let checkChange = false;
let checkArray = [];

const locMethod = (locType) => {
    if (locType.length === 0)
        return ""
    if (locType[0].filterType === "CER_LOCATION") {
        return "CER_LOCATIONS"
    }
    else if (locType[0].filterType === "LOCATIONS" || "BUILDINGS"){
        return "E911_LOCATIONS"
    }
    else {
        return ""
    }
}  

const AddEditCallMonitoringModal = props => {
    const { modalLoading, classes, autoComplete, autoCompleteOptions, modalData, company } = props;
    const numbersArray = modalData ? modalData.phoneNumbers.split(',').map(item => (
        { phoneNumber: item }
    )) : [];
    const [phoneNumberList, setPhoneNumberList] = useState(numbersArray);
    const [phoneListIsEmpty, setPhoneListIsEmpty] = useState(phoneNumberList.length <= 0 ? true : false);
    const [selectedBuildings, setSelectedBuildings] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState(null);
    const [formikValues, setFormikValues] = useState(null);

    const isLocations = props.company.toJS().locationTreeType === 'SIMPLE' || (formikValues && formikValues.locationType == 'CER_LOCATIONS');


    const validationSchema = Yup.object({
        name: Yup.string("Enter a name")
                .trim().required("Name is a required field.")
                .max(255, "Name has a max limit of 255 characters."),
        phoneNumber: !phoneListIsEmpty ? 
            Yup.number()
                .min(10, 'Phone number must be at least 10 characters')
            :
            Yup.number()
                .required("Phone Number is a required field.")
                .min(10, 'Phone number must be at least 10 characters'),
        locationType: (formikValues && !formikValues.allBuildings) ? Yup.string("Choose A Building")
                .trim().required("Location Type is a required field.") : '',
        buildings: (formikValues && !isLocations && !formikValues.allBuildings) ? Yup.string("Choose A Building")
                .trim().required("Building is a required field.") : '',
        locations: (formikValues && isLocations && !formikValues.allBuildings) ? Yup.string("Choose A Building")
                .trim().required("Location is a required field.") : '',
    });
    
	return(
        <Formik
            initialValues={
                modalData ?
                { 
                    name: modalData.name,
                    phoneNumber: '',
                    allBuildings: !modalData.hasFilters,
                    locationType: modalData.hasFilters ? locMethod(modalData.filterData) : 'E911_LOCATIONS',
                    locations: modalData.filterData && modalData.filterData.length > 0 && modalData.filterData[0].filterType !== 'BUILDING' ? modalData.filterData : [],
                    buildings: modalData.filterData && modalData.filterData.length > 0 && modalData.filterData[0].filterType === 'BUILDING' ? modalData.filterData : [],
                } :
                {
                    name: '',
                    phoneNumber: '',
                    allBuildings: false,
                    locBuildChange: false,
                    locationType: props.cerEnabled ? '' : 'E911_LOCATIONS',
                    locations: [],
                    buildings: [],
                }
            }
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    let arr = [];
                    for (const item of phoneNumberList) {
                        arr.push(item.phoneNumber);
                    }
                    values.buildings = checkChange ? selectedBuildings : values.buildings;
                    values.locations = checkChange ? selectedLocations : values.locations;
                    values.parentId = props.companyId;
                    values.phoneNumberList = arr;
                    if (!modalData) {
                        props.submitAddCallMonitoringForm(values);
                    } else {
                        values.id = modalData.id;
                        props.submitEditCallMonitoringForm(values);

                    }
                }
            }
            render={formikProps => <AddEditCallMonitoringForm 
                                        cerEnabled={props.cerEnabled}
                                        selectedBuildings={selectedBuildings}
                                        selectedLocations={selectedLocations}
                                        setSelectedLocations={setSelectedLocations}
                                        setSelectedBuildings={setSelectedBuildings}
                                        setModalConfirm={props.setModalConfirm}
                                        phoneNumberList={phoneNumberList}
                                        setPhoneNumberList={setPhoneNumberList}
                                        companyId={props.companyId}
										autoCompleteOptions={autoCompleteOptions}
										autoComplete={autoComplete} 
										classes={classes} 
                                        modalLoading={modalLoading}
                                        modalData={modalData}
                                        company={company.toJS()}
                                        setPhoneListIsEmpty={setPhoneListIsEmpty}
                                        setFormikValues={setFormikValues}
										{...formikProps} />}
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
    company: selectUserCompany(),
    cerEnabled: selectCerEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddCallMonitoringForm: (callData) => dispatch(CallMonitoringActions.addCallMonitoringRequest(callData)),
        submitEditCallMonitoringForm: (callData) => dispatch(CallMonitoringActions.editCallMonitoringRequest(callData))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddEditCallMonitoringModal);