import React, { useState, useEffect, useCallback } from 'react';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';

import reducer from '../../reducers/callHistory';
import saga from '../../sagas/callHistory';
import { actions as CallHistoryActions } from '../../reducers/callHistory';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { createStructuredSelector } from 'reselect';

import CallHistoryTable from '../../containers/CallHistoryTable/CallHistoryTable';
import { debounce } from 'lodash';

const initialSortColumns = [
    {
        id: 'callTime',
        desc: true
    }
];

function CallHistoryPage(props) {
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    useEffect(() => {
        props.setPageTitle('Call History');
        props.setPageTitleId('call-history-header');
    }, []);
    
    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const isMatch = searchFieldRegex(inputValue);

        if (isMatch) {
            setSearchValue(inputValue);
            searchApiCall(
                inputValue, 
                props.pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC'
            );
        }
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, direction) => {
            if (inputValue.length > 0) {
                setPage(0);
                props.fetchCallHistoryList(1, pageSize, inputValue, sortBy, direction);
            } else if(inputValue.length === 0) {
                setPage(0);
                props.fetchCallHistoryList(1, pageSize, '', sortBy, direction)
            }
        }, 500)
    , []);

    return (
        <div>
            <SearchField 
                value={searchValue}
                onChange={handleInputSearchChange}
            />
            <br />
            <br />
            <CallHistoryTable 
                        setCurrentSortColumn={setCurrentSortColumn}
                        currentSortColumn={currentSortColumn}
                        setSortColumns={setSortColumns}
                        sortColumns={sortColumns}
                        setCurrentModal={props.setCurrentModal} 
                        searchTerm={searchValue}
                        page={page}
                        setPage={setPage}
                        pageSize={props.pageSize}  />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchCallHistoryList: (page, pageSize, searchTerm, sortBy, direction) => dispatch(CallHistoryActions.getCallHistoryRequest(page, pageSize, searchTerm, sortBy, direction)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data))
    }
};

const withReducer = injectReducer({ key: 'callHistory', reducer });
const withSaga = injectSaga({ key: 'callHistory', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(CallHistoryPage);