import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types } from '../../reducers/heldDevices';

class HeldDevicesEditMenu extends Component {
	user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	isDelete = this.user && this.user.permissions.includes('DELETE_HELD_DEVICES');

	heldOptions = [
		'View HELD Request',
	];
	
	heldPlusOptions = [
		'View HELD+ Request',
	];
	
	ITEM_HEIGHT = 48;
	state = {
		anchorEl: null,
	};

	componentDidMount() {
		if (this.isDelete) {
			this.heldOptions.unshift('Delete');
			this.heldPlusOptions.unshift('Delete');
		}
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleMenuItemClick = (option) => {
		this.handleClose();
		switch (option) {
			case 'Delete':
				this.props.setCurrentModal(types.DELETE_HELD_DEVICE, this.props.row.original);
				break;
			case 'View HELD Request':
				this.props.setCurrentModal(types.HELD_DEVICES_HELD_REQUEST, this.props.row.original);
				break;
			case 'View HELD+ Request':
				this.props.setCurrentModal(types.HELD_DEVICES_HELD_REQUEST, this.props.row.original);
				break;
			default:
				return null;
		}
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		
		return (
			<div>
				<IconButton
					id={`held-devices-action-menu-button-${this.props.row.index}`}
					aria-label="More"
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id={`held-devices-action-menu-${this.props.row.index}`}
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
					PaperProps={{
						style: {
							maxHeight: this.ITEM_HEIGHT * 6.5,
							minWidth: 200,
						},
					}}
				>
					{this.props.row.original.deviceType === 'HELD' ?
						this.heldOptions.map((option, index) => (
							<MenuItem id={`held-devices-item-${this.props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => this.handleMenuItemClick(option, event)}>
								{option}
							</MenuItem>
						))
						:
						this.heldPlusOptions.map((option, index) => (
							<MenuItem id={`held-devices-item-${this.props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => this.handleMenuItemClick(option, event)}>
								{option}
							</MenuItem>
						))
					}
				</Menu>
			</div>
		);
	}
}

HeldDevicesEditMenu.propTypes = {
	row: PropTypes.object,
	setCurrentModal: PropTypes.func
};


const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default connect(null, mapDispatchToProps)(HeldDevicesEditMenu);
