/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const PSTN = 'PSTN/'; 
export const types = {
    ADD_PSTN: PSTN + 'ADD_PSTN',
    GET_PSTN_REQUEST: PSTN + 'GET_PSTN_REQUEST',
    GET_PSTN_ERROR: PSTN + 'GET_PSTN_ERROR',
    GET_PSTN_SUCCESS: PSTN + 'GET_PSTN_SUCCESS',
    EDIT_PSTN: PSTN + 'EDIT_PSTN',
    DELETE_PSTN: PSTN + 'DELETE_PSTN',
};

export const initialState = fromJS({
    pstnList: List(),
    pstnLoading: false,
    pstnListPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_PSTN_REQUEST:
            return state.set('pstnLoading', true);
        case types.GET_PSTN_ERROR:
            return state.set('pstnLoading', false);
        case types.GET_PSTN_SUCCESS:
            return state.set('pstnLoading', false)
                        .set('pstnList', List(action.data))
                        .set('pstnListPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    addPstnRequest: (pstn) => ({ 
        type: types.ADD_PSTN, 
        data: pstn
    }),
    editPstnRequest: (pstn) => ({
        type: types.EDIT_PSTN,
        data: pstn
    }),
    deletePstnRequest: (id) => ({
        type: types.DELETE_PSTN,
        data: id
    }),
    getPstnRequest: (page, pageSize, sortBy, direction) => ({
        type: types.GET_PSTN_REQUEST,
        page, 
        pageSize,
        sortBy,
        direction
    }),
    getPstnError: () => ({
        type: types.GET_PSTN_ERROR
    }),
    getPstnSuccess: (pstnList, pageCount) => ({
        type: types.GET_PSTN_SUCCESS,
        data: pstnList,
        pageCount
    })
};

// Selectors
const selectPstn = state => state.get('pstn', initialState);

export const makeSelectPstnList = () =>
    createSelector(selectPstn, pstnState => pstnState.get('pstnList'));

export const makeSelectPstnListLoading = () =>
    createSelector(selectPstn, pstnState => pstnState.get('pstnLoading'));

export const makeSelectPstnPageCount = () =>
createSelector(selectPstn, pstnState => pstnState.get('pstnListPageCount'));