import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Error from '@material-ui/icons/Error'; 
import { compose } from 'redux';
import ModalWrapper from '../../../../components/ModalWrapper';

function WelcomeEmailTemplateError(props) {

    const {
        setModalClose,
        modalData
    } = props;

    return(
        <Grid container>
            <Grid container style={{margin: '20px'}}>
                <Grid item xs={12} sm={1}>
                    <Error style={{ color: 'red', fontSize: 40 }}/>
                </Grid>
                <Grid item xs={12} sm={11}>
                    <span style={{ color: 'red', fontWeight: 'bold' }}>ERROR:</span> Only one user-defined {modalData} Template can exist at a time.
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        onClick={setModalClose}
                        color="primary" 
                        variant="contained">Cancel</Button>
            </Grid>
        </Grid>
    );
}

export default compose(ModalWrapper)(WelcomeEmailTemplateError);