import React from 'react';
import Button from '@material-ui/core/Button';
import Assignment from '@material-ui/icons/Assignment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as AppActions } from '../../reducers/app';

const  DetailsModalWrapper = props => {
    const { type, row, setCurrentModal, userRole, simple } = props;

    function handleClick(event) {
        row.original.userRole = userRole;
        row.original.simple = simple;
        setCurrentModal(type, row.original);
    };
  
    return (
        <div style={{ paddingLeft: 15 }}>
            <Button
                id={`details-info-button-${props.row.rowId}`}
                style={{ minWidth: 40 }}
                aria-haspopup="true"
                color="primary"
                onClick={handleClick}
                title="Detailed Info">
                <Assignment />
            </Button>
        </div>
    );
}


const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default compose(
    connect(null, mapDispatchToProps),
)(DetailsModalWrapper);