import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import Delete from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types, makeSelectDeviceUsersLocationsPageCount } from '../../reducers/deviceUsers';
import { Button } from '@material-ui/core';
import { actions as DeviceUsersActions} from '../../reducers/deviceUsers';

const initialSortColumns = [
    {
        id: 'name',
        desc:false
    }
]

function DeviceUsersLocationsListTable(props) {
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    function getGeoCoords(row) {
        if ((row.original.geoCoords) && (row.original.geoCoords.latitude) && (row.original.geoCoords.longitude)) {
            return <div title={`${row.original.geoCoords.latitude} , ${row.original.geoCoords.longitude}`}>{`${row.original.geoCoords.latitude} , ${row.original.geoCoords.longitude}`}</div>;
        } else if (row.original.address && row.original.address.latitude && row.original.address.longitude) {
            return <div title={`${row.original.address.latitude} , ${row.original.address.longitude}`}>{`${row.original.address.latitude} , ${row.original.address.longitude}`}</div>;
        } else {
            return <div></div>;
        }
    }

    const columns = [
        {
            Header: <div>Location Name</div>,
            id: "name",
            sortable: true,
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: <div>Address</div>,
            id: "address",
            sortable: false,
            Cell: row => <div title={row.original.address.normalizedAddress}>{row.original.address.normalizedAddress}</div>
        },
        {
            Header: <div>Geodetic Coordinates</div>,
            id: "geo-coords",
            sortable: false,
            Cell: row => getGeoCoords(row)
        },
        {
            Header: <div>Location Info</div>,
            id: "location",
            sortable: false,
            Cell: row => <div title={row.original.info}>{row.original.info}</div>
        },
        {
            Header: '',
            id: "edit-menu",
            sortable: false,
            width: 65,
            Cell: row => (
                <Button title="Delete" aria-label="Delete" id={`action-menu-button-${row.rowId}`}
                    style={{
                        color: row.original && row.original.id === props.currentLocationId ? '#fff' : ''
                    }} 
                    onClick={() => {
                        props.setCurrentModal(types.DELETE_DEVICE_USER_LOCATION, {
                                location: row.original,
                                locationId: row.original.deviceUserId
                        })
                    }}>
                    <Delete />
                </Button>
            )
            
        }
    ]


    const {
        locations, loading, getLocationsRequest
    } = props;

    let locationsArray = [];
    if(locations) {
        locationsArray = locations.toArray()
    }

    useEffect(() => {
        if (locationsArray && locationsArray.length === 0 && page > 0 && props.deviceUsersLocationsPageCount < page+1) {
            setPage(page-1)
        }
    }, [locationsArray && locationsArray.length === 0 && page > 0 && props.deviceUsersLocationsPageCount < page+1]);
    
    useEffect( () => {
        getLocationsRequest(props.deviceUserId, page+1, pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: page+1, 
            pageSize: pageSize, 
            sortBy: currentSortColumn.id, 
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, pageSize, page]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false} 
            className="-striped -highlight subtenantsListTable"
            showPaginationBottom={true}
            data={locationsArray}
            pages={props.deviceUsersLocationsPageCount <= 0 ? 1 : props.deviceUsersLocationsPageCount}
            page={page}
            minRows={locationsArray && locationsArray.length > 0 ? 0 : 5}
            defaultPageSize={25}
            columns={columns}
            loading={loading}
            LoadingComponent={ReactTableLoadingComponent}
            manual
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
            }}
            onPageSizeChange={(pageSize) => {
                setPageSize(pageSize)
                setPage(0);
            }}
            onPageChange={(pageIndex) => {
                setPage(pageIndex);
            }}
            getTrProps={
                (state, rowInfo, column) => {
                    let rowLocationId;
                    if(rowInfo) {
                        rowInfo.rowId = 'device-users-locations-row-' + rowInfo.original.id;
                        rowLocationId = rowInfo.original ? rowInfo.original.id : null;

                        if(rowLocationId === props.currentLocationId) {
                            return {
                                id: rowInfo.rowId,
                                style: {
                                    background: '#3f51b5',
                                    color: 'white',
                                    opacity: '.8'
                                }
                            };
                        }
                        else {
                            return {
                                id: rowInfo.rowId,
                            };
                        }
                    } else {
                        return {};
                    }
                }
            }
        />
    );
}

DeviceUsersLocationsListTable.propTypes = {
    locations: ImmutablePropTypes.list,
    loading: PropTypes.bool,
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = () => createStructuredSelector({
    deviceUsersLocationsPageCount: makeSelectDeviceUsersLocationsPageCount()
});

const mapDispatchToProps = dispatch => {
  return {
      getLocationsRequest: (deviceUserId, page, pageSize, sortBy, direction) => dispatch(DeviceUsersActions.getLocationsRequest(deviceUserId, page, pageSize, sortBy, direction)),
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
      setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceUsersLocationsListTable);
    