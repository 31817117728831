import clonedeep from 'lodash.clonedeep';

// CONSTANTS
export const HOURS_ARRAY = [
    { label: '12AM', value: '00' },
    { label: '01AM', value: '01' },
    { label: '02AM', value: '02' },
    { label: '03AM', value: '03' },
    { label: '04AM', value: '04' },
    { label: '05AM', value: '05' },
    { label: '06AM', value: '06' },
    { label: '07AM', value: '07' },
    { label: '08AM', value: '08' },
    { label: '09AM', value: '09' },
    { label: '10AM', value: '10' },
    { label: '11AM', value: '11' },
    { label: '12PM', value: '12' },
    { label: '01PM', value: '13' },
    { label: '02PM', value: '14' },
    { label: '03PM', value: '15' },
    { label: '04PM', value: '16' },
    { label: '05PM', value: '17' },
    { label: '06PM', value: '18' },
    { label: '07PM', value: '19' },
    { label: '08PM', value: '20' },
    { label: '09PM', value: '21' },
    { label: '10PM', value: '22' },
    { label: '11PM', value: '23' },
];

export const MINUTES_ARRAY = [
    { label: '00', value: '00' },
    { label: '15', value: '15' },
    { label: '30', value: '30' },
    { label: '45', value: '45' },
];

export const DAY_OF_MONTH_TOGGLE = {
    SPECIFIC_DAY: 'specificDay',
    FIRST_DAY: 'firstDayOfMonth',
    LAST_DAY: 'lastDayOfMonth',
};

export const DAY = {
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday'
}

export const DAY_SHORT = {
    SUN: 'Sun',
    MON: 'Mon',
    TUE: 'Tue',
    WED: 'Wed',
    THU: 'Thu',
    FRI: 'Fri',
    SAT: 'Sat'
}

// DATA STRUCTURES
/**
 * Cron table structure simplified
 * {
    - selectedDayToggle - [specificDayOfWeek, firstDayOfMonth, lastDayOfMonth]
    - hour 00-24
    - minute 00-15-30-45
    - specificDayArrayToggles - [Day,Value,Toggle:False/True]
 * }
*/
export const initialCronTableDataStructure = {
    dayOfMonthToggle: DAY_OF_MONTH_TOGGLE.SPECIFIC_DAY,
    hour: HOURS_ARRAY[0].value,
    minute: MINUTES_ARRAY[0].value,
    specificDayArrayToggles: [
        { key: DAY.SUNDAY, value: DAY_SHORT.SUN, toggle: false  },
        { key: DAY.MONDAY, value: DAY_SHORT.MON, toggle: false  },
        { key: DAY.TUESDAY, value: DAY_SHORT.TUE, toggle: false  },
        { key: DAY.WEDNESDAY, value: DAY_SHORT.WED, toggle: false  },
        { key: DAY.THURSDAY, value: DAY_SHORT.THU, toggle: false  },
        { key: DAY.FRIDAY, value: DAY_SHORT.FRI, toggle: false  },
        { key: DAY.SATURDAY, value: DAY_SHORT.SAT, toggle: false  }
    ],
    cronValue: ''
}

// CRON UTILITY FUNCTIONS

export function setInitialCronTableStructureFromExpression(cronExpression) {
    if(cronExpression) {
        try {
            const convertedCronExpressionStructure = convertCronExpressionToTableStructure(cronExpression);    
            let cronDataStructureCopy = clonedeep(initialCronTableDataStructure);
            cronDataStructureCopy.dayOfMonthToggle = convertedCronExpressionStructure.dayOfMonthToggle;
            cronDataStructureCopy.hour = convertedCronExpressionStructure.hour;
            cronDataStructureCopy.minute = convertedCronExpressionStructure.minute;

            if(convertedCronExpressionStructure.dayOfMonthToggle === DAY_OF_MONTH_TOGGLE.SPECIFIC_DAY) {
                if(convertedCronExpressionStructure.specificDaysString) {
                    if(convertedCronExpressionStructure.specificDaysString.includes('*')) { // no days selected
                        // set all day toggles to false
                        let weekDayArrayCopy = [...cronDataStructureCopy.specificDayArrayToggles];
                        weekDayArrayCopy.forEach(
                            (day) => {day.toggle = false}
                        );
                        cronDataStructureCopy.specificDayArrayToggles = weekDayArrayCopy;
                    } else {
                        // set checked day toggles based on intitial cron expression
                        let specificDayArrayTogglesCopy = [...cronDataStructureCopy.specificDayArrayToggles];
                        Object.keys(DAY_SHORT).forEach((day, i) => {
                            if(convertedCronExpressionStructure.specificDaysString.toLowerCase().includes(day.toLowerCase())) {
                                specificDayArrayTogglesCopy[i].toggle = true;
                            }
                        });
                        cronDataStructureCopy.specificDayArrayToggles = specificDayArrayTogglesCopy;
                    }
                }
            }
            cronDataStructureCopy.cronValue = generateCronExpression(
                0, 
                cronDataStructureCopy.minute, 
                cronDataStructureCopy.hour, 
                cronDataStructureCopy.dayOfMonthToggle, 
                '*', 
                convertDayTogglesToDayArray(cronDataStructureCopy.specificDayArrayToggles),
                '*' 
            );
            return (cronDataStructureCopy);
        } catch(error) {
            // for any cron issues just reset back to the initial
            console.log("couldn't process cron expression, setting to default");
            let cronDataStructureCopy = clonedeep(initialCronTableDataStructure);
            cronDataStructureCopy.cronValue = generateCronExpression(
                0, 
                cronDataStructureCopy.minute, 
                cronDataStructureCopy.hour, 
                cronDataStructureCopy.dayOfMonthToggle, 
                '*', 
                convertDayTogglesToDayArray(cronDataStructureCopy.specificDayArrayToggles),
                '*' 
            );
            return cronDataStructureCopy;
        }
    }
    return clonedeep(initialCronTableDataStructure);
}


/**
 * Cron table structure simplified
 * {
    - selectedDayToggle - [specificDayOfWeek, firstDayOfMonth, lastDayOfMonth]
    - hour 00-24
    - minute 00-15-30-45
    - specificDaysString - "Mon,Tue,Wed,..." | "*"
 * }
*/
export function convertCronExpressionToTableStructure(initialCronExpression) {
    let cronStructure = {};

    // split cron expression by space to get each value into array
    let cronValuesArray = initialCronExpression.split(" ");
    cronStructure.seconds = cronValuesArray[0];
    cronStructure.minute = cronValuesArray[1];
    cronStructure.hour = cronValuesArray[2];

    if(initialCronExpression.includes('1 1/1 ? *')) { // check if first day of month
        cronStructure.dayOfMonthToggle = DAY_OF_MONTH_TOGGLE.FIRST_DAY;
    }
    else if(initialCronExpression.includes('L * ? *')) { // check if last day of month
        cronStructure.dayOfMonthToggle = DAY_OF_MONTH_TOGGLE.LAST_DAY;
    }
    else { // otherwise must have no day or at least one or more day
        cronStructure.dayOfMonthToggle = DAY_OF_MONTH_TOGGLE.SPECIFIC_DAY;
        cronStructure.specificDaysString = cronValuesArray[5];
    }
    return cronStructure;
}

export function generateCronExpression(seconds, minutes, hours, dayOfMonth, month, dayOfWeek, year) {

    if (dayOfMonth === DAY_OF_MONTH_TOGGLE.SPECIFIC_DAY) {
        dayOfMonth = '?';
    } else if (dayOfMonth === DAY_OF_MONTH_TOGGLE.FIRST_DAY) {
        dayOfWeek = [];
        dayOfWeek.push('?');
        dayOfMonth = '1';
        month = '1/1';
    } else if (dayOfMonth === DAY_OF_MONTH_TOGGLE.LAST_DAY) {
        dayOfWeek = [];
        dayOfWeek.push('?');
        dayOfMonth = 'L';
    }
    
    return `${seconds} ${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek.length > 0 ? dayOfWeek.toString() : '*'} ${year}`;
}

export function convertDayTogglesToDayArray(specificDayArrayToggles) {
    let days = [];
    specificDayArrayToggles.forEach(
        (day) => {
            if(day.toggle) {
                days.push(day.value);
            }
        }
    )
    return days;
}