import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/lib/Async'

function LocationAsyncSelect(props) {
  useEffect(() => {
    if (Array.isArray(props.defaultValue)) {
      props.defaultValue.forEach(object => {
        object.label = object.name;
        object.value = object.id;
      });
    }
  }, []);

  return (
    <AsyncSelect
      placeholder=""
      onBlur={props.handleBlur}
      inputId={props.id}
      className={props.error ? 'multi-select error' : 'multi-select'}
      isDisabled={props.isDisabled ? props.isDisabled : false}
      isMulti={props.isMulti ? props.isMulti : false}
      defaultOptions={true}
      defaultValue={props.defaultValue ? props.defaultValue : false}
      onChange={props.onChange}
      loadOptions={(input) => {
         return props.getLocationsByParentId(props.selectedBuildingId, input);;
      }}
      closeMenuOnSelect={!props.isMulti}
      theme={(theme) => ({
        ...theme,
        colors: {
        ...theme.colors,
          primary25: 'rgba(0, 0, 0, 0.1)',
          primary: 'rgba(0, 0, 0, 0.2)',
        },
      })}
    />
  );
}

export default LocationAsyncSelect;