import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalErrorMessage from './ModalErrorMessage';
import history from '../../utils/history';
import './modalWrapper.css';

const ModalWrapper = (Content) => {
	const withModalWrapper = ({classes,
		modalOpen,
		setModalClose,
		title,
		modalLoading, 
		modalErrorDisplay,
		modalErrorMessage,
		modalConfirm,
		modalErrorOptions,
		setModalConfirm,
		setModalErrorClear,
		maxWidth, ...rest }) => {
		
		setTimeout(() => {
			const modal = document.querySelector('.modal > div > div div:last-child');
			if (modal) {
				modal.focus();
				modal.tabIndex = '0';
				modal.classList.add('focusable-modal');
			}
		}, 500);
		
		const isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);

		const handleClose = () => {
			if (window.location.pathname === '/alerts' && (title.toLowerCase().includes('add subscription') || title.toLowerCase().includes('edit subscription'))) {
				setModalErrorClear();
				
				if (modalConfirm === true) {
					setModalConfirm('showMessage');
				} else {
					setModalConfirm(false);
					setModalClose();
				}
			} else {
				setModalClose();
			}
		};

		return ( <Dialog
				open={modalOpen}
				classes={{ paper: classes.dialogPaper }}
				className={
					window.location.pathname === '/create-reports' && 
					title.toLowerCase().includes('generate') ? 'modal generate-reports' : 
					window.location.pathname === '/create-reports' && 
					title.toLowerCase().includes('schedule') ? 'modal schedule-reports' :
					window.location.pathname === '/scheduled-reports' && 
					title.toLowerCase().includes('report') ? 'modal schedule-reports' : 'modal'}
				maxWidth={maxWidth}
			>
				<DialogTitle disableTypography  id="dialog-title" className={classes.dialogTitle}  >
					{title}
					{history.location.pathname !== '/myAccount' &&
						<Button id="closeBtn" className={isIE11 ? classes.closeButtonIE : classes.closeButton} 
						onClick={handleClose}>close</Button>
					}
					{modalConfirm === 'showMessage' &&  <div className="show-message-alert">Please make sure you have saved all your changes before closing</div>}
				</DialogTitle>
				<DialogContent>
					{
					// window.location.pathname === '/organization-management' || 
					(modalErrorOptions && modalErrorOptions.displayTopErrorMessage === undefined)  || 
					!modalErrorDisplay ? '' : <ModalErrorMessage modalErrorOptions={modalErrorOptions} modalErrorMessage={modalErrorMessage}/> 
					}
					<Content modalErrorOptions={modalErrorOptions} modalErrorMessage={modalErrorMessage} modalLoading={modalLoading} setModalConfirm={setModalConfirm} setModalClose={setModalClose} {...rest}/> 
				</DialogContent>
			</Dialog>
		)
	};

	const styles = theme => ({
		dialogTitle: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			backgroundColor: theme.palette.primary.main,
			color: 'white'
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing.unit,
			color: 'white'
		},
		closeButtonIE: {
			position: 'absolute',
			right: theme.spacing.unit,
			color: 'white',
			top: '5px'
		}
	});
	
	return withStyles(styles)(withModalWrapper);
}

ModalWrapper.propTypes = {
	title: PropTypes.string,
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalLoading: PropTypes.bool,
	modalErrorDisplay: PropTypes.bool,
	modalErrorMessage: PropTypes.string,
	modalConfirm: PropTypes.bool
};

export default ModalWrapper;