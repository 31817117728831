import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ReactTableLoadingComponent extends Component {
    render() {
        return (
            this.props.loading ? 
                <div className='-loading -active'>
                    <div className='-loading-inner'>
                        <CircularProgress style={{ color: '#1b536a' }}/>
                    </div>
                </div>
            : null
        )
    }
}