import React, { Component, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { InputLabel, Switch } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import ModalWrapper from '../../../components/ModalWrapper';
import { makeCheckSsoSettings, actions as UsersActions, types } from '../../../reducers/users';
import { validate, buildValidationSchema } from './validation.js';
import { currentCompanyOrgType, orgTypes, userCompanyId } from '../../../reducers/auth';
import UsersAPI from '../../../apis/usersApi';
import RoleSelect from '../../../components/RoleSelect';
import './addUserModal.css';
import LicensingServiceApi from '../../../apis/licensingServiceApi';


const styles = () => ({
	btn: {
		marginTop: "10px"
	}
});
const AddEditUserForm = props => {
	const { values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			modalLoading,
			classes,
			companyId,
			ssoCheck,
			roleList,
			enhanced_Notification,
			modalData } = props;
	
	function handleScroll() {
		const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
		modal.scrollTo(0, 0);
	}

	function ssoDisable() {
        if(values.eonUser) {
            return true;
        }
        return false;
    }

	return (
		<form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
			{modalLoading && <LinearProgress/>}
			<br/>
			<RoleSelect 
				companyId={companyId} 
				value={values.roleName} 
				roleList={roleList} 
				handleChange={handleChange}
				modalLoading={modalLoading}
				disabled={roleList.length === 0}/>
				<span id="role-helper-text" className="error-prompt">{touched.roleName ? errors.roleName : ""}</span>
			{(ssoCheck) &&
			<>
				<InputLabel shrink={true} htmlFor="logSSO-label">Log in using SSO</InputLabel>
				<Switch
					id="logSSO"
					name="logSSO"
					checked={values.logSSO}
					onChange={handleChange}
					disabled={ssoDisable()}
					color="primary"
					inputProps={{ 'aria-label': 'primary checkbox' }}
				/>
			</>
			}
			{(((values.roleName === 'ORGANIZATION_ADMINISTRATOR' || values.roleName === 'E911_SECURITY' || values.roleName === 'E911_ADMINISTRATOR') && enhanced_Notification && (props.currentCompanyOrgType !== (orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM || orgTypes.SERVICE_PROVIDER)))) &&
			<>
				<br/>
				<InputLabel shrink={true} htmlFor="logSSO-label">EON User</InputLabel>
				<Switch
					id="eonUser"
					name="eonUser"
					checked={values.eonUser}
					onChange={handleChange}
					disabled={values.logSSO}
					color="primary"
					inputProps={{ 'aria-label': 'primary checkbox' }}
				/>
			</>
			}
			<TextField
				id="email"
				label="Email"
				name="email"
				type="email"
				onChange={handleChange}
				value={values.email}
				onBlur={handleBlur}
				disabled={modalLoading}
				fullWidth={true}
				required={true}
				error={((touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))))}/>
				<span id="email-helper-text" className="error-prompt">{((touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')))) ? errors.email : ''}</span>
			<br/>
			<TextField
				id="firstName"
				label="First Name"
				name="firstName"
				type="text"
				onChange={handleChange}
				value={values.firstName ? values.firstName : ''}
				onBlur={handleBlur}
				disabled={modalLoading}
				fullWidth={true}
				error={(touched.firstName && Boolean(errors.firstName)) || (errors.firstName && Boolean(errors.firstName.includes('max')))}/>
				<span id="firstName-helper-text" className="error-prompt">{(touched.firstName && Boolean(errors.firstName)) || (errors.firstName && Boolean(errors.firstName.includes('max'))) ? errors.firstName : ''}</span>
			<br/>
			<TextField
				id="lastName"
				label="Last Name"
				name="lastName"
				type="text"
				onChange={handleChange}
				value={values.lastName ? values.lastName : ''}
				onBlur={handleBlur}
				disabled={modalLoading}
				fullWidth={true}
				error={(touched.lastName && Boolean(errors.lastName)) || (errors.lastName && Boolean(errors.lastName.includes('max')))}/>
				<span id="lastName-helper-text" className="error-prompt">{(touched.lastName && Boolean(errors.lastName)) || (errors.lastName && Boolean(errors.lastName.includes('max'))) ? errors.lastName : ''}</span>
			<br/>
			{(!values.logSSO) &&
				<p className="help-text">
					Please note that the user will need to click on the 'Forgot/Reset Password' link <br/>
					on the log in page, before logging in for the first time.
				</p>
			}
			<Grid container direction="column" justify="center" alignItems="center">
				<Button id="add-user-submit-button"
						type="submit"
						disabled={modalLoading}
						className = {classes.btn}
						onClick={handleScroll}
						color="primary"
						variant="contained">Save</Button>
			</Grid>
		</form>
	);
};

function AddEditUserModal (props) {
	const { modalLoading, classes, companyId, checkSingleSignOn, ssoCheck, modalData } = props;

	const [enhanced_Notification, setEnhanced_Notification] = useState(false);
	const [roleList, setRoleList] = useState([]);

	useEffect(() => {
        if (props.currentCompanyOrgType !== orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM) {
            LicensingServiceApi.checkIfOrgHasLicense(companyId).then(response => {
                setEnhanced_Notification(response.data)
            });
        }
		
		UsersAPI.getRoles(props.currentCompanyOrgType).then(response => {
			const filteredData = response.data.filter(item => !item.hidden);
			setRoleList(filteredData);
		}).catch(error => {
			console.log(error);
		})
    }, []);
	
	useEffect(() => {
		props.checkSingleSignOn(companyId)
	}, []);

	return(
		<Formik
			enableReinitialize
			initialValues={
				!modalData ? 
				{
					roleName: '',
					email: '',
					firstName: '',
					lastName: '',
					logSSO: props.ssoCheck ? true : false,
					ssoType: props.ssoCheck ? 'SAML_SSO' : 'DEFAULT',
					eonUser: false
				} : {
					roleName: (modalData.role.name) ? modalData.role.name : '', 
					email: (modalData.email) ? modalData.email : '',
					firstName: (modalData.firstName) ? modalData.firstName : '',
					lastName: (modalData.lastName) ? modalData.lastName : '',
					logSSO: modalData.ssoType === 'SAML_SSO' ?  true : false,
					eonUser: modalData.eonUser
				}
			}
			validate={validate(buildValidationSchema)}
			onSubmit = {
				(values) => {
					values.ssoType = values.logSSO ? 'SAML_SSO' : 'DEFAULT'
					values.eonUser = values.eonUser;
					values.companyId = companyId;
					
					if (!modalData) {
						props.submitAddUserForm(values);
					} else {
						props.submitEditUserForm(values, modalData);
					}

				}
			}
			render={props => <AddEditUserForm 
								enhanced_Notification={enhanced_Notification}
								roleList={roleList}
								modalData={modalData}
								currentCompanyOrgType={props.currentCompanyOrgType}
								classes={classes} 
								modalLoading={modalLoading} 
								checkSingleSignOn={checkSingleSignOn} 
								ssoCheck={ssoCheck} {...props} 
								companyId={companyId}/>}
		/>
	);
}

const mapStateToProps = () => createStructuredSelector({
	companyId: userCompanyId(),
	ssoCheck: makeCheckSsoSettings(),
	currentCompanyOrgType: currentCompanyOrgType()
});

const mapDispatchToProps = dispatch => {
	return {
		submitAddUserForm: (user) => dispatch(UsersActions.addUserRequest(user)),
		submitEditUserForm: (user, row) => dispatch(UsersActions.editUserRequest(user, row)),
		checkSingleSignOn: (companyId) => dispatch(UsersActions.checkSingleSignOn(companyId))
	}
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	ModalWrapper,
	withStyles(styles)
)(AddEditUserModal);
