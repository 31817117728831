import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

const CERServerForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        setFieldValue,
        setFieldTouched,
        modalData,
        cerErrorResponseType,
        classes } = props;

    const [isInvalidIP, setIsInvalidIP] = useState(false);
    const [isDuplicateIP, setIsDuplicateIP] = useState(false);

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }
    
    useEffect(() => {
        if (cerErrorResponseType === 'http://cirrus.redskytech.com/errors/ip') {
            setIsInvalidIP(true);
            setIsDuplicateIP(false);
        } else if (cerErrorResponseType === 'http://cirrus.redskytech.com/errors/cerServer/ipAddress/duplicate') {
            setIsInvalidIP(false);
            setIsDuplicateIP(true);
        } else {
            setIsInvalidIP(false);
            setIsDuplicateIP(false);
        }
    }, [cerErrorResponseType]);

    return (
        <form className="cer-server-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <TextField
                id="ipAddress"
                label="*IP Address"
                
                name="ipAddress"
                type="ipAddress"
                onChange={handleChange}
                value={values.ipAddress}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.ipAddress && Boolean(errors.ipAddress)) || (errors.ipAddress && Boolean(errors.ipAddress.includes('max'))) || isInvalidIP || isDuplicateIP}/> 
                {isInvalidIP && 
                    <div id="ipAddress-invalid-helper-text" className="error-prompt">The IP address provided is not properly formatted.</div>
                }
                {isDuplicateIP && 
                    <div id="ipAddress-duplicate-helper-text" className="error-prompt">The IP address provided already exists.</div>
                }
                <div id="ipAddress-helper-text" className="error-prompt">{(touched.ipAddress && Boolean(errors.ipAddress)) || (errors.ipAddress && Boolean(errors.ipAddress.includes('max'))) ? errors.ipAddress : ''}</div>
            <br/>
            <br/>
            <TextField
                id="description"
                label="Description"
                name="description"
                rows={5}
                multiline={true}
                onChange={handleChange}
                value={values.description}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                variant= "outlined"
                error={(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max')))}
                />
                <div className="error-prompt">{(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max'))) ? errors.description : ''}</div>
            <br/>
            <br/>
            <br/>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

export default CERServerForm;