/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */

import { fromJS, List } from "immutable";
import { createSelector } from "reselect";

export const NOTIFICATION_INTEGRATIONS = "NOTIFICATION_INTEGRATIONS/";
export const types = {
  GET_NOTIFICATION_INTEGRATIONS: NOTIFICATION_INTEGRATIONS + "GET_NOTIFICATION_INTEGRATIONS",
  GET_NOTIFICATION_INTEGRATIONS_SUCCESS: NOTIFICATION_INTEGRATIONS + "GET_NOTIFICATION_INTEGRATIONS_SUCCESS",
  GET_NOTIFICATION_INTEGRATIONS_ERROR: NOTIFICATION_INTEGRATIONS + "GET_NOTIFICATION_INTEGRATIONS_ERROR",
  ADD_INFORMACAST_ENTITY: NOTIFICATION_INTEGRATIONS + "ADD_INFORMACAST_ENTITY",
  ADD_INFORMACAST_ENTITY_SUCCESS: NOTIFICATION_INTEGRATIONS + "ADD_INFORMACAST_ENTITY_SUCCESS",
  ADD_INFORMACAST_ENTITY_ERROR: NOTIFICATION_INTEGRATIONS + "ADD_INFORMACAST_ENTITY_ERROR",
  EDIT_INFORMACAST_ENTITY: NOTIFICATION_INTEGRATIONS + "EDIT_INFORMACAST_ENTITY",
  EDIT_INFORMACAST_ENTITY_SUCCESS: NOTIFICATION_INTEGRATIONS + "EDIT_INFORMACAST_ENTITY_SUCCESS",
  DELETE_INFORMACAST_ENTITY: NOTIFICATION_INTEGRATIONS + "DELETE_INFORMACAST_ENTITY",
  DELETE_INFORMACAST_ENTITY_SUCCESS: NOTIFICATION_INTEGRATIONS + "DELETE_INFORMACAST_ENTITY_SUCCESS",
  GET_COMPANY_LIST: NOTIFICATION_INTEGRATIONS + "GET_COMPANY_LIST",
  GET_COMPANY_LIST_SUCCESS: NOTIFICATION_INTEGRATIONS + "GET_COMPANY_LIST_SUCCESS",
  INFORMACAST_CONFIGURED: NOTIFICATION_INTEGRATIONS + "INFORMACAST_CONFIGURED",
  INFORMACAST_ERROR_RESPONSE: NOTIFICATION_INTEGRATIONS + "INFORMACAST_ERROR_RESPONSE"
};

export const initialState = fromJS({
  notificationIntegrationsList: List(),
  companyList: List(),
  notificationIntegrationsListLoading: false,
  informacastConfigured: false,
  informacastErrorResponse: ''
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATION_INTEGRATIONS:
      return state.set("notificationIntegrationsListLoading", true)
                  .set("notificationIntegrationsList", List())
                  .set("informacastConfigured", false);
    case types.GET_NOTIFICATION_INTEGRATIONS_SUCCESS:
      return state
        .set("notificationIntegrationsList", List(action.notificationIntegrations))
        .set("notificationIntegrationsListLoading", false)
    case types.GET_NOTIFICATION_INTEGRATIONS_ERROR:
      return state.set("notificationIntegrationsListLoading", false)
    case types.INFORMACAST_CONFIGURED:
      return state.set("informacastConfigured", action.configured);
    case types.INFORMACAST_ERROR_RESPONSE:
      return state.set("informacastErrorResponse", action.data)
    default:
      return state;
  }
};

// Action Creators
export const actions = {
  getNotificationIntegrationsRequest: () => ({
    type: types.GET_NOTIFICATION_INTEGRATIONS,
  }),
  notificationIntegrationsLoaded: (notificationIntegrations) => ({
    type: types.GET_NOTIFICATION_INTEGRATIONS_SUCCESS,
    notificationIntegrations
  }),
  notificationIntegrationsLoadingError: () => ({
    type: types.GET_NOTIFICATION_INTEGRATIONS_ERROR,
  }),
  addInformaCastEntityRequest: informaCastEntity => ({
    type: types.ADD_INFORMACAST_ENTITY,
    data: informaCastEntity
  }),
  addInformaCastEntitySuccess: () => ({
    type: types.ADD_INFORMACAST_ENTITY_SUCCESS
  }),
  addInformaCastEntityError: error => ({
    type: types.ADD_INFORMACAST_ENTITY_ERROR,
    error: error.data
  }),
  editInformaCastEntityRequest: (informaCastEntity, row) => ({
    type: types.EDIT_INFORMACAST_ENTITY,
    data: informaCastEntity,
    row: row
  }),
  deleteInformaCastEntityRequest: id => ({
    type: types.DELETE_INFORMACAST_ENTITY,
    data: id
  }),
  deleteInformaCastEntitySuccess: () => ({
    type: types.DELETE_INFORMACAST_ENTITY_SUCCESS
  }),
  informacastConfigured: (configured) => ({
    type: types.INFORMACAST_CONFIGURED,
    configured
  }),
  informacastErrorResponse: error => ({
    type: types.INFORMACAST_ERROR_RESPONSE,
    data: error
  })
};

// Selectors
const selectNotificationIntegrations = state => state.get("notificationIntegrations", initialState);

export const makeSelectNotificationIntegrationsList = () =>
  createSelector(
    selectNotificationIntegrations,
    notificationIntegrationsState => notificationIntegrationsState.get("notificationIntegrationsList")
  );

export const makeSelectNotificationIntegrationsListLoading = () =>
  createSelector(
    selectNotificationIntegrations,
    notificationIntegrationsState => notificationIntegrationsState.get("notificationIntegrationsListLoading")
  );

export const selectInformacastConfigured = () =>   createSelector(
  selectNotificationIntegrations,
  notificationIntegrationsState => notificationIntegrationsState.get("informacastConfigured")
);

export const selectInformacastErrorResponse = () =>   createSelector(
  selectNotificationIntegrations,
  informacastErrorResponse => informacastErrorResponse.get("informacastErrorResponse")
);
