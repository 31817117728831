import { axiosInstance } from './api';
/* Cirrus Admin-Service /companyFeatures Controller */
const COMPANY_FEATURES_ENDPOINT = '/admin-service/companyFeatures/company/';

export default class CompanyFeaturesAPI {

    static getCompanyFeatures(companyId) {
        return axiosInstance.get(COMPANY_FEATURES_ENDPOINT + companyId);
    }

    static addCompanyFeatures(companyId, ...featuresToAdd){
        return axiosInstance.put(COMPANY_FEATURES_ENDPOINT + companyId, featuresToAdd);
    }

    static removeCompanyFeatures(companyId, ...featuresToDelete){
        return axiosInstance.put(COMPANY_FEATURES_ENDPOINT + companyId + '/remove', featuresToDelete);
    }
}