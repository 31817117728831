import { call, put, takeLatest, all, select, takeEvery } from 'redux-saga/effects';
import {actions as AppActions, selectTableProps, types as App} from '../reducers/app';
 import { actions as ApiAccessKeyActions,
 		 types as ApiAccessKey } from '../reducers/apiAccessKey';
import ApiAccessKeyApi from '../apis/apiAccessKeyApi';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';

function* getApiAccessKeyList(action) {
	try {
		const response = yield call(ApiAccessKeyApi.getApiKeys, action.companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction);
		yield put(ApiAccessKeyActions.apiAccessKeySuccess(response.data, response.headers['x-pagination-count']));	
	} catch (error) {
		yield put(ApiAccessKeyActions.apiAccessKeyError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
    }
}

function* addApiAccessKey(action) {
	try {
		yield put(AppActions.setModalErrorClear());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading(true));
		const response = yield call(ApiAccessKeyApi.addApiKey, action.data);
		if (response.data) {
			yield put(AppActions.setModalLoading(false));
			yield put(AppActions.setCurrentModal(ApiAccessKey.ADD_API_ACCESS_KEY_SUCCESS, response.data));
		}
		yield put(ApiAccessKeyActions.getApiAccessKeyRequest(action.data.companyId, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editApiAccessKey(action) {
	try {
		yield put(AppActions.setModalErrorClear());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading(true));
		yield call(ApiAccessKeyApi.editApiKey, action.data);
		yield put(AppActions.setModalClose());
		yield put(ApiAccessKeyActions.getApiAccessKeyRequest(action.data.companyId, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteApiAccessKey(action) {
	try {
		yield put(AppActions.setModalErrorClear());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(ApiAccessKeyApi.deleteApiKey, action.data.apiKey);
		yield put(AppActions.setModalClose());
		yield put(ApiAccessKeyActions.getApiAccessKeyRequest(action.data.companyId, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch(error) {
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootOrganizationsSaga() {
	yield all([
		yield takeEvery(ApiAccessKey.GET_API_ACCESS_KEY, getApiAccessKeyList),
		yield takeLatest(ApiAccessKey.ADD_API_ACCESS_KEY, addApiAccessKey),
		yield takeLatest(ApiAccessKey.EDIT_API_ACCESS_KEY, editApiAccessKey),
		yield takeLatest(ApiAccessKey.DELETE_API_KEY, deleteApiAccessKey)
	]);
}

export default rootOrganizationsSaga; 