import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/deviceUsers';
import { userCompanyId } from '../../../reducers/auth';
import { connect } from 'react-redux';

import { selectAlternateIdEnabled } from '../../../reducers/app';
import { createStructuredSelector } from 'reselect';
import DeviceUserForm from './shared/DeviceUserForm';
import { styles, validationSchema } from './shared/SharedData';
import './shared/DeviceUser.css';

function AddEditDeviceUser(props) {
    const { modalLoading, classes, modalData, submitEditDeviceUserForm, submitAddDeviceUserForm, companyId } = props;
    const [identificationTypeOption, setIdentificationTypeOption] = useState(modalData ? getInitialPhoneToggleState(modalData) : 'PHONE_NUMBER');
    const [deviceUserIds, setDeviceUserIds] = useState(modalData ? modalData.alternateIdList : []);
    const [formikValues, setFormikValues] = useState(null);

    function getInitialPhoneToggleState(modalData) {
        if(modalData.alternateIdList.length > 0) {
            return 'DEVICE_USER_ID';
        } else {
            return 'PHONE_NUMBER';
        }
    }

    function getInitialFormDataState(modalData) {
        let initialValue;
        if (modalData) {
            if(!props.alternateDeviceUserIdEnabled || modalData.phoneNumber) {
                initialValue = { 
                    phoneNumber: modalData.phoneNumber ? modalData.phoneNumber.phoneNumber : '', 
                    email: modalData.email,
                    firstName: modalData.firstName,
                    lastName: modalData.lastName,
                    alternateId: '',
                    callbackNumber: modalData.callbackNumber,
                    heldUserId: modalData.heldUserId ? modalData.heldUserId : '',
                    enableHeldPlusFunctionality: modalData.heldUserId ? true : false,
                    enableMyE911Functionality: modalData.email ? true : false
                }
            }
            else {
                initialValue = { 
                    alternateId: modalData.alternateId,
                    callbackNumber: modalData.callbackNumber,
                    email: modalData.email,
                    firstName: modalData.firstName,
                    lastName: modalData.lastName,
                    phoneNumber: '',
                    heldUserId: modalData.heldUserId ? modalData.heldUserId : '',
                    enableHeldPlusFunctionality: modalData.heldUserId ? true : false,
                    enableMyE911Functionality: modalData.email ? true : false
                }
            }   
        } else {
            initialValue = { 
                alternateId: '',
                callbackNumber: '',
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                heldUserId: '',
                enableHeldPlusFunctionality: false,
                enableMyE911Functionality: false
            }
        }
        return initialValue;
    }
    
    return(
        <Formik
            initialValues={getInitialFormDataState(props.modalData)}
            validationSchema={validationSchema(props, identificationTypeOption, deviceUserIds, formikValues)}
            onSubmit = {
                (values) => {
                    let payload;
                    if(identificationTypeOption === 'DEVICE_USER_ID') {
                        payload = { 
                            alternateIdList: deviceUserIds.map(item => item.alternateId),
                            callbackNumber: values.callbackNumber, 
                            email: formikValues.enableMyE911Functionality ? values.email : '',
                            firstName: values.firstName,
                            lastName: values.lastName,
                            heldUserId: formikValues.enableHeldPlusFunctionality ? values.heldUserId : '',
                            enableHeldPlusFunctionality: values.enableHeldPlusFunctionality,
                            enableMyE911Functionality: values.enableMyE911Functionality
                        }
                    }
                    else {
                        payload =  {
                            phoneNumber: formikValues.enableMyE911Functionality ? values.phoneNumber : '', 
                            email: formikValues.enableMyE911Functionality ? values.email : '',
                            firstName: values.firstName,
                            lastName: values.lastName,
                            callbackNumber: values.callbackNumber,
                            heldUserId: formikValues.enableHeldPlusFunctionality ? values.heldUserId : '',
                            enableHeldPlusFunctionality: values.enableHeldPlusFunctionality,
                            enableMyE911Functionality: values.enableMyE911Functionality
                        };
                    }
                    if (modalData) {
                        payload.id = modalData.id;
                        submitEditDeviceUserForm(payload);
                    } else {
                        submitAddDeviceUserForm(payload);
                    }
                }
            }
            render={formikProps => <DeviceUserForm 
                                        setFormikValues={setFormikValues}
                                        modalErrorOptions={props.modalErrorOptions}
                                        companyId={companyId}
                                        setDeviceUserIds={setDeviceUserIds}
                                        deviceUserIds={deviceUserIds}
                                        classes={classes} 
                                        modalLoading={modalLoading} 
                                        identificationTypeOption={identificationTypeOption}
                                        setIdentificationTypeOption={setIdentificationTypeOption}
                                        alternateDeviceUserIdEnabled={props.alternateDeviceUserIdEnabled} 
                                        modalData={modalData}
                                        {...formikProps} />
                    }
        />
    );
}

const mapStateToProps = createStructuredSelector({
    alternateDeviceUserIdEnabled: selectAlternateIdEnabled(),
    companyId: userCompanyId()
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddDeviceUserForm: (deviceUser) => dispatch(actions.addDeviceUserRequest(deviceUser)),
        submitEditDeviceUserForm: (deviceUser) => dispatch(actions.editDeviceUserRequest(deviceUser))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddEditDeviceUser);