import React, { useState, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Formik } from 'formik';
import { compose } from 'redux';
import ModalWrapper from '../../../../components/ModalWrapper';
import { actions as AlertActions } from '../../../../reducers/alerts';
import { selectInformaCastEnabled, selectCerEnabled } from '../../../../reducers/app';
import { connect } from 'react-redux';
import { userCompanyId, selectUserCompany } from '../../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import AlertSubscriptionsForm from './shared/AlertSubscriptionForm';
import { validationSchema, styles } from './shared/sharedData';
import moment from 'moment';
import AlertsApi from "../../../../apis/alertsApi";
import LinearProgress from '@material-ui/core/LinearProgress';

const AlertsSubscriptionsModal = props => {
    const { modalLoading, classes, autoComplete, autoCompleteOptions, modalData, company, companyId } = props;

    const [userList, setUserList] = useState(!modalData ? [] : [...modalData.userRecipients]);
    const [smsList, setSmsList] = useState(!modalData ? [] : [...modalData.smsRecipients]);
    const [emailList, setEmailList] = useState(!modalData ? [] : [...modalData.emailRecipients]);
    const [informacastList, setInformacastList] = useState(!modalData ? [] : [...modalData.informacastRecipients]);
    const [currentTab, setTab] = useState(((modalData && modalData.alertTypeTO.name === 'UNDEFINED_NETWORK_LOCATION') || !companyId) ? 1 : 0);
    const [formValues, setFormValues] = useState(null);
    const [completeList, setCompleteList] = useState([...userList, ...smsList, ...emailList, ...informacastList]);
    const [showLoadingState, setShowLoadingState] = useState(false);
    
    const isLocations = props.company.toJS().locationTreeType === 'SIMPLE' || (formValues && formValues.locationType == 'CER_LOCATIONS');

    function fetchAllFilterData() {
        if (Number(modalData.currentPage) < Number(modalData.numPages)) {
            AlertsApi.getAlertsSubscriptionsWithFilters(modalData.id, Number(modalData.currentPage) + 1, modalData.pageSize)
                .then(response => {
                    modalData.filterData = modalData.filterData.concat(response.data);
                    modalData.currentPage = response.headers["x-pagination-page"];
                    fetchAllFilterData();
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            setShowLoadingState(false);
        }
    }

    useEffect(() => {
        if (modalData) {
            setShowLoadingState(true);
            fetchAllFilterData();
        }
    }, []);

    useEffect(() => {
        setCompleteList([...userList, ...smsList, ...emailList, ...informacastList]);
    }, [userList, emailList, smsList, informacastList]);

    return(
        showLoadingState ? <LinearProgress /> :
        <Formik
            initialValues={!modalData ? 
                { 
                    name: '',
                    alertType: '',
                    template: '',
                    timeZoneType: moment.tz.guess(),
                    user: '',
                    informacast: '',
                    sms: '',
                    email: '',
                    allBuildings: true,
                    buildings: [],
                    locations: [],
                    locationType: props.cerEnabled ? '' : 'E911_LOCATIONS',
                    enableFiltering: false,
                    allCorporateLocations: true,
                    allPersonalLocations: false

                } :
                { 
                    name: modalData.name,
                    alertType: modalData.alertTypeTO,
                    template: modalData.alertTemplate,
                    timeZoneType: modalData.timezone,
                    user: '',
                    informacast: '',
                    sms: '',
                    email: '',
                    allBuildings: !modalData.hasFilters, // no filters means all buildings/locations toggle should be on
                    locations: modalData.filterData && modalData.filterData.length > 0 && modalData.filterData[0].filterType !== 'BUILDING' ? modalData.filterData : [],
                    buildings: modalData.filterData && modalData.filterData.length > 0 && modalData.filterData[0].filterType === 'BUILDING' ? modalData.filterData : [],
                    locationType: props.cerEnabled ? '' : 'E911_LOCATIONS',
                    enableFiltering: modalData.allCorporateLocations && modalData.allPersonalLocations ? false : modalData.allCorporateLocations || modalData.allPersonalLocations || (modalData.filterData && modalData.filterData.length > 0) ? true : false,
                    allCorporateLocations: modalData.allCorporateLocations,
                    allPersonalLocations: modalData.allPersonalLocations
                }
            }
            enableReinitialize
            validationSchema={validationSchema(completeList, currentTab, formValues, isLocations)}
            onSubmit = {
                (values) => {
                    values.parentId = props.companyId;
                    values.userList = userList;
                    values.informacastList = informacastList;
                    values.smsList = smsList;
                    values.emailList = emailList;

                    if (!modalData) {
                        props.submitAddAlertSubscriptionForm(values);
                    } else {
                        values.id = modalData.id;
                        props.submitEditAlertSubscriptionForm(values);
                    }
                }
            }
            render={formikProps => <AlertSubscriptionsForm 
                                        setFormValues={setFormValues}
                                        completeList={completeList}
                                        currentTab={currentTab}
                                        setTab={setTab}
                                        informacastEnabled={props.informacastEnabled}
                                        company={company.toJS()}
                                        setModalConfirm={props.setModalConfirm}
                                        informacastList={informacastList}
                                        setInformacastList={setInformacastList}
                                        userList={userList}
                                        smsList={smsList}
                                        emailList={emailList}
                                        setUserList={setUserList}
                                        setSmsList={setSmsList}
                                        modalData={modalData}
                                        setEmailList={setEmailList}
                                        companyId={companyId}
										autoCompleteOptions={autoCompleteOptions}
										autoComplete={autoComplete} 
										classes={classes} 
                                        modalLoading={modalLoading}
                                        alertType={modalData && modalData.alertTypeTO}
                                        cerEnabled={props.cerEnabled}
                                        isAlertSubscription={true}
										{...formikProps} />}
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
    company: selectUserCompany(),
    informacastEnabled: selectInformaCastEnabled(),
    cerEnabled: selectCerEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddAlertSubscriptionForm: (alert) => dispatch(AlertActions.addAlertSubscriptionRequest(alert)),
        submitEditAlertSubscriptionForm: (alert) => dispatch(AlertActions.editAlertSubscriptionRequest(alert)),
        fetchAlertSubscriptionFilterData: (subId, pageNumber, pageSize) => dispatch(AlertActions.get)
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AlertsSubscriptionsModal);