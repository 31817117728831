import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Input, InputLabel, MenuItem, Select} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from "yup";
import { connect } from 'react-redux';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions as LicensesActions, selectManagingCompany } from '../../../reducers/manageLicenses';
import { createStructuredSelector } from "reselect";
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import './addLicenseModal.css';
import LicensingServiceApi from '../../../apis/licensingServiceApi';
import CompanyFeaturesAPI from '../../../apis/companyFeaturesApi';


const styles = () => ({
	btn: {
		marginTop: "10px"
	}
});


const AddLicenseForm = props => {
	const { values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			modalLoading,
			classes,
			date, 
			setDate,
			focused, 
			setFocused,
			licenseTypes,
			setLicenseTypes,
			setFieldValue,
			managingCompany
		} = props;

	useEffect(() => {
		CompanyFeaturesAPI.getCompanyFeatures(managingCompany.toJS().id).then(cfResponse => {
		LicensingServiceApi.getLicenseTypes().then(response => {
			if(managingCompany.toJS().usageBasedLicensing){
				const filteredList = response.data.filter(item => item.displayName == 'Enhanced Notification');
				setLicenseTypes(filteredList);
				setFieldValue('licenseType', filteredList[0]);
			} else if (managingCompany.toJS().managerDataSync) {
				setLicenseTypes(response.data);
			} else if (!cfResponse.data.includes("EMERGENCY_CALL_ASSIST")) {
				response.data = response.data.filter(item => item.displayName != 'E911 Manager ELIN');
				response.data = response.data.filter(item=> item.displayName != 'Emergency Call Assist');
				setLicenseTypes(response.data);
			}else {
				setLicenseTypes(response.data.filter(item => item.displayName != 'E911 Manager ELIN'));
			}
		});
	})
		const submitButton = document.getElementById('add-license-submit-button');
		const licenseType = document.getElementById('select-licenseType');
		submitButton.addEventListener('click', () => {
				licenseType.focus();
				licenseType.blur();
		});
	}, []);

	function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
	}
	
	const isOutsideRange = day => {
    	return	day.isBefore(moment().add(12, 'hours'));
	}

	return (
		<form className="license-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
			{modalLoading && <LinearProgress/>}
			<br/>
			<InputLabel shrink={true} htmlFor="license-label" required={true} error={touched.licenseType && Boolean(errors.licenseType)}>License Type</InputLabel>
			<Select
				id="license-label"
				name="licenseType"
				value={values.licenseType}
				onChange={handleChange}
				onBlur={handleBlur}
				disabled={modalLoading}
				input={<Input name="license"/>}
				fullWidth={true}
				error={touched.licenseType && Boolean(errors.licenseType)}>
					{licenseTypes.sort((a, b) => { return a.type.localeCompare(b.type) }).map((item, index) => (
						<MenuItem key={index} value={item}>
							<em>{item.displayName}</em>
						</MenuItem>	
					))}
			</Select>
			<span id="type-helper-text" className="error-prompt">{touched.licenseType ? errors.licenseType : ""}</span>
			<br/>
			{ values.licenseType && values.licenseType.licenseSubTypes && values.licenseType.licenseSubTypes.length > 0 &&
				<div>
					<InputLabel shrink={true} htmlFor="licenseSubType" required={true} error={touched.subType && Boolean(errors.subType)}>License Sub-Type</InputLabel>
					<Select
						id="licenseSubType"
						name="subType"
						value={values.subType}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled={modalLoading}
						input={<Input name="licenseSubType"/>}
						fullWidth={true}
						error={touched.subType && Boolean(errors.subType)}>
					{ values.licenseType.licenseSubTypes.sort((a, b) => { return a.type.localeCompare(b.type) }).map((item, index) => (
							<MenuItem key={index} value={item}>
								<em>{item.displayName}</em>
							</MenuItem>	
						))
					}
					</Select>
					<span id="type-helper-text" className="error-prompt">{touched.subType ? errors.subType : ""}</span>
					<br/>
				</div>
			}

			<TextField
				id="count"
				label="No. of Licenses"
				name="count"
				//type="number"
				min="0"
				step="1"
				onChange={handleChange}
				onKeyDown={e => {
					const isNum = e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode <= 8;
					if (!isNum) {
						e.preventDefault();
					}
				}}
				onPaste={e => {
					const str = e.clipboardData.getData('Text');
					const onlyNums = str.replace(/[^0-9]/g, '');
					if (str !== onlyNums) {
						e.preventDefault()
					}
				}} 
				value={values.count ? values.count : ''}
				onBlur={handleBlur}
				disabled={modalLoading}
				fullWidth={true}
				required={true}
				error={(touched.count && Boolean(errors.count)) || (errors.count && Boolean(errors.count.includes('max')))}/>
				<span id="count-helper-text" className="error-prompt">{(touched.count && Boolean(errors.count)) || (errors.count && Boolean(errors.count.includes('max'))) ? errors.count : ''}</span>
			<br/>
			<br/>
			{window.location.href.includes('RIGHT_TO_USE') ? '' : 
				<div>
					<InputLabel shrink={true} htmlFor="datePicker" required={true}>Expiration Date</InputLabel>
					<br/>
					<SingleDatePicker
						id="datePicker"
						readOnly={true}
						date={date}
						onDateChange={date => setDate(date)}
						focused={focused}
						onFocusChange={({ focused }) => setFocused(focused)}
						isOutsideRange={isOutsideRange}
						numberOfMonths={1}
						required={true}
						ariaLabel="Expiration Date"
					/>
				</div>
			}
			<br/>
			<TextField
                id="orderNumber"
                label="Order Number"
                name="orderNumber"
                onChange={handleChange}
                value={values.orderNumber}
                onBlur={handleBlur}
                fullWidth={true}
                error={(touched.orderNumber && Boolean(errors.orderNumber)) || (errors.orderNumber && Boolean(errors.orderNumber.includes('max')))}/> 
				<span id="orderNumber-helper-text" className="error-prompt">{(touched.orderNumber && Boolean(errors.orderNumber)) || (errors.orderNumber && Boolean(errors.orderNumber.includes('max'))) ? errors.orderNumber : ''}</span>
			<br/>
			<br/>
			<br/>
			<TextField
                id="notes"
                label="Notes"
                name="notes"
                rows={5}
                multiline={true}
                onChange={handleChange}
                value={values.notes}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                variant= "outlined"
				error={(touched.notes && Boolean(errors.notes)) || (errors.notes && Boolean(errors.notes.includes('max')))}/> 
				<span id="notes-helper-text" className="error-prompt">{(touched.notes && Boolean(errors.notes)) || (errors.notes && Boolean(errors.notes.includes('max'))) ? errors.notes : ''}</span>
			<br/>
			<br/>
			<Grid style={{ margin: '20px 0' }} container direction="column" justify="center" alignItems="center">
				<Button	id="add-license-submit-button"
						type="submit"
						disabled={modalLoading}
						className = {classes.btn}
						onClick={handleScroll}
						color="primary"
						variant="contained">Save</Button>
			</Grid>
		</form>
	);
};

const AddLicenseModal = props => {
	const { modalLoading, classes, managingCompany} = props;
	const [date, setDate] = useState(moment().add(1, 'year'));
	const [focused, setFocused] = useState(false);
	const [licenseTypes, setLicenseTypes] = useState([]);

	return(
		<Formik
			initialValues={{
				licenseType: '',
				subType: '',
				count: '',
			}}
			validate= { (values) => {
                // Only do this if you need to access the value from the form to make something required
                const validationSchema =  Yup.object({
					licenseType: Yup.string("Select a type")
							.trim()
							.required("License Type is a required field."),
					count: Yup.number("Enter number of licenses")
							.integer().positive().required("Number of licenses is a required field."),
					orderNumber: Yup.string("Enter an order number")
							.max(50, "Order number has a max limit of 50 characters."),
					notes: Yup.string("Enter notes")
							.max(255, "Notes has a max limit of 255 characters."),
					subType: values.licenseType && values.licenseType.licenseSubTypes && values.licenseType.licenseSubTypes.length > 0 ? Yup.string("Select a type")
								.trim()
								.required("Sub-Type is a required field.") : Yup.string("Select a sub-type")
				});
				
                try {
                    validateYupSchema(values, validationSchema, true, {});
                } catch (err) {
                    return yupToFormErrors(err);
                }
                return {}
            }}
			onSubmit = {
				(values) => {
					values.companyId = props.modalData;
					values.expires = date._d;
					let copy = {...values};
					copy.type = values.licenseType.type;
					copy.subType = values.subType.type;
					props.submitAddLicenseForm(copy);
				}
			}
			render={formikProps => <AddLicenseForm licenseTypes={licenseTypes} setLicenseTypes={setLicenseTypes} managingCompany={managingCompany} classes={classes} modalLoading={modalLoading} {...formikProps} date={date} setDate={setDate} focused={focused} setFocused={setFocused} />}
		/>
	);
}

const mapStateToProps = createStructuredSelector({
	managingCompany: selectManagingCompany(),
});

const mapDispatchToProps = dispatch => {
	return {
		submitAddLicenseForm: (license) => dispatch(LicensesActions.addLicenseRequest(license))
	}
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	ModalWrapper,
	withStyles(styles)
)(AddLicenseModal);
