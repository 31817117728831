import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { InputLabel, Tooltip } from "@material-ui/core";
import Info from '@material-ui/icons/Info';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Help from '@material-ui/icons/Help';
import Slide from '@material-ui/core/Slide';
import ContentEditable from 'react-contenteditable';
import Switch from '@material-ui/core/Switch';
import ModalErrorMessage from '../../../../components/ModalWrapper/ModalErrorMessage';

import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { actions as AlertActions } from '../../../../reducers/alerts';
import { selectAlternateIdEnabled, selectLocationAlternateIdEnabled, selectCerEnabled } from '../../../../reducers/app';
import { modalErrorDisplay,
    modalErrorMessage } from '../../../../reducers/app';
import { selectUserCompany } from '../../../../reducers/auth';
import { connect } from 'react-redux';
import AlertsAPI from '../../../../apis/alertsApi';
import './AddAlertsTemplates.css';
import he from 'he';


const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    paper: {
        position: 'absolute',
        zIndex: 999,
        marginTop: theme.spacing.unit,
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    tooltip: {
      fontSize: '14px'
    }
});

const EditAlertsTemplatesForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        setFieldValue,
        deviceAlternateIdEnabled,
        locationAlternateIdEnabled,
        cerEnabled,
        classes } = props;   const [previewFlag, setPreviewFlag] = useState(false);
    const [previewContent, setPreviewContent] = useState('');
    const [previewSubject, setPreviewSubject] = useState('');
    const [isIE11, setIsIE11] = useState(false);
    const [focusOnSubjectField, setFocusOnSubjectField] = useState(false);
    const [focusOnCanvasField, setFocusOnCanvasField] = useState(false);
    const [activeBorder, setActiveBorder] = useState('');

    useEffect(() => {
        const isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);
        const canvas = document.querySelector('.canvas');
        const subject = document.querySelector('.subject');
        const name = document.querySelector('#name');
        let elm = document.querySelector('.subject');

        if (isIE11) {
            setIsIE11(true);
            document.addEventListener('click', () => {
                const activeElmClass = document.activeElement.className;
                if (activeElmClass === 'subject') {
                    setFocusOnSubjectField(true);
                    setFocusOnCanvasField(false);
                    setActiveBorder('subject');
                    addNewTagEventListener();
                } else if (activeElmClass === 'canvas') {
                    setFocusOnSubjectField(false);
                    setFocusOnCanvasField(true);
                    setActiveBorder('canvas');
                    addNewTagEventListener();
                }
            });

            subject.addEventListener('click', () => {
                elm = subject;
                elm.addEventListener('blur', () => {
                    elm.focus();
                });
            });
            
            canvas.addEventListener('click', () => {
                elm = canvas;
                elm.addEventListener('blur', () => {
                    elm.focus();
                });
            });
            
            name.addEventListener('click', () => {
                elm = name;
                elm.focus();
            });
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            addNewTagEventListener();
        }, 100);

        return () => {
            clearInterval(interval);
        }
    }, []);

    /**
     * Get alert template tags hook
     */
    useEffect(() => {
        AlertsAPI.getAlertTemplateTags(props.alertTypeValue.name).then(
            response => {
                if(response.data && response.data.length > 0) {
                    if(props.company.toJS().locationTreeType !== 'SIMPLE'){
                        if(deviceAlternateIdEnabled && locationAlternateIdEnabled) {
                            setFieldValue('tags', response.data);
                        }
                        else if(!deviceAlternateIdEnabled && locationAlternateIdEnabled ){
                            setFieldValue('tags', response.data.filter( tag => tag.templateTag !== '<[DEVICE_USER_ID]>'));
                        }
                        else if(!locationAlternateIdEnabled && deviceAlternateIdEnabled){
                            setFieldValue('tags', response.data.filter( tag => tag.templateTag !== '<[ALTERNATE_ID]>' ));
                        }
                        else if(!locationAlternateIdEnabled && !deviceAlternateIdEnabled){
                            setFieldValue('tags', response.data.filter( tag => tag.templateTag !== '<[ALTERNATE_ID]>' && tag.templateTag !== '<[DEVICE_USER_ID]>'));
                        }    
                    }
                    else{
                        if(deviceAlternateIdEnabled && locationAlternateIdEnabled) {
                            setFieldValue('tags', response.data.filter( tag => tag.templateTag !== '<[BUILDING_NAME]>'));
                        }
                        else if(!deviceAlternateIdEnabled && locationAlternateIdEnabled ){
                            setFieldValue('tags', response.data.filter( tag => tag.templateTag !== '<[DEVICE_USER_ID]>' && tag.templateTag !== '<[BUILDING_NAME]>'));
                        }
                        else if(!locationAlternateIdEnabled && deviceAlternateIdEnabled){
                            setFieldValue('tags', response.data.filter( tag => tag.templateTag !== '<[ALTERNATE_ID]>'  && tag.templateTag !== '<[BUILDING_NAME]>'));
                        }
                        else if(!locationAlternateIdEnabled && !deviceAlternateIdEnabled){
                            setFieldValue('tags', response.data.filter( tag => tag.templateTag !== '<[ALTERNATE_ID]>' && tag.templateTag !== '<[DEVICE_USER_ID]>' && tag.templateTag !== '<[BUILDING_NAME]>'));
                        }
                    }   
                }
                /**
                * Convert tags to labels
                */
                
                // Converts unicode symbols in html back to normal string, i.e &lt; converts to <
                let decodedContent = he.decode(props.modalData.content);
                let decodedSubject = he.decode(props.modalData.subject);
                
                decodedContent = decodedContent.replace(/\n/g, '<br/>');
                decodedSubject = decodedSubject.replace(/\n/g, '<br/>');

                response.data.forEach(item => {
                    // Find tag by regex, for example finds '<[Event_Time]>'
                    const findTagsRegex = RegExp(item.templateTag.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
                    // Replaces matched value >SAMPLE_CONTENT</span>
                    decodedContent = decodedContent.replace(findTagsRegex, `<span contenteditable="false" data-sample="${item.sampleContent}" data-content="${item.templateTag}" class="tag" data-type="label">${item.label}</span>`);
                    decodedSubject = decodedSubject.replace(findTagsRegex, `<span contenteditable="false" data-sample="${item.sampleContent}" data-content="${item.templateTag}" class="tag" data-type="label">${item.label}</span>`);
                });

                setFieldValue('content', decodedContent);
                setFieldValue('subject', decodedSubject);
                addNewTagEventListener();
            }
        )
    }, []);
    /**
     * On preview toggle, this effect will look for any detached tag
     * elements in the DOM with labal data attribute and 
     * attach an onClick listener to remove from content box.
     */
    useEffect(() => {
        addNewTagEventListener();
    }, [previewFlag]);
    
    const addNewTagEventListener = () => {
        const tag = document.querySelectorAll('.tag');
        tag.forEach(elm => {
            if(elm.getAttribute('data-type') === 'label') {
                elm.addEventListener('click', function() {
                    this.remove(); // removes this element from the DOM
                });
            }
        });
    }

    /**
     * Preview button handler
     */
    const handlePreviewToggle = () => {
        if (!previewFlag) {
            // Converts unicode symbols in html back to normal string, i.e &lt; converts to <
            let decodedContent = he.decode(values.content);
            let decodedSubject = he.decode(values.subject);
            
            decodedContent = decodedContent.replace(/<span.*?data-sample="(.+?)".*?<\/span>/g, "$1");
            decodedSubject = decodedSubject.replace(/<span.*?data-sample="(.+?)".*?<\/span>/g, "$1");

            setPreviewContent(decodedContent);
            setPreviewSubject(decodedSubject);
            setPreviewFlag(true);
        } else {
            setPreviewFlag(false);
        }
    };
    
    const handleContentChange = (event) => {
        setFieldValue('content', event.target.value);
    }

    const handleSubjectChange = (event) => {
        if (event.target.value.includes('HTML Link')) {
            setFieldValue('subject', values.subject);
        } else {
            setFieldValue('subject', event.target.value);
        }
    }

    function pasteHtmlAtCaret(html) {
        let sel;
        let range;
        if (window.getSelection) {
            // IE9 and non-IE
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                range.deleteContents();
    
                // Range.createContextualFragment() would be useful here but is
                // non-standard and not supported in all browsers (IE9, for one)
                const el = document.createElement("div");
                el.innerHTML = html;
                let frag = document.createDocumentFragment(), node, lastNode;
                while ( (node = el.firstChild) ) {
                    lastNode = frag.appendChild(node);
                }
                range.insertNode(frag);
                
                // Preserve the selection
                if (lastNode) {
                    range = range.cloneRange();
                    range.setStartAfter(lastNode);
                    range.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }
        } else if (document.selection && document.selection.type != "Control") {
            // IE < 9
            document.selection.createRange().pasteHTML(html);
        }
    }

    function handleScroll() {
        const modal = document.querySelector('.template-form');
        modal.scrollTo(0, 0);
    }
    
    return (
        <form onSubmit={handleSubmit} autoComplete="off" className="template-form" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <div className="template-name">
                <TextField
                    id="name"
                    label="*Template Name"
                    name="name"
                    type="name"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/> 
                <br/>
                <div>
                    <InputLabel shrink={true} htmlFor="alert-label" error={touched.template && Boolean(errors.template)}>Alert/Email Type:</InputLabel>
                    <div>{props.alertTypeValue.text}</div>
                </div>
            </div>
            <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <div className="template-wrapper">
                <div className="template-tags">
                <h2>Template Tags <span className={isIE11 ? "help-icon secondary" : "help-icon"}><Help /></span></h2>
                        <ul>
                        {isIE11 ? 
                                values.tags.map((item, index) => (
                                    //item.label === "Building Name" ? "" :
                                    <div className="row">
                                        <li style={{ 
                                                cursor: 'pointer'
                                            }}
                                            className="clickable"
                                            key={index} 
                                            data-tag={item.label}
                                            data-content={item.templateTag}
                                            onClick={event => {
                                                const tag = event.target.getAttribute('data-tag');
                                                const subject = document.querySelector('.subject');
                                                const canvas = document.querySelector('.canvas');
                                                const newElement = document.createElement('span');
                                                const nonBreakingSpace = '&nbsp;';

                                                newElement.setAttribute('contenteditable', false);
                                                newElement.setAttribute('class', 'tag');
                                                newElement.setAttribute('data-sample', item.sampleContent);
                                                newElement.setAttribute('data-content', item.templateTag);
                                                newElement.setAttribute('data-type', 'label');
                                                newElement.innerHTML = tag;

                                                if (focusOnSubjectField && !focusOnCanvasField) {
                                                    pasteHtmlAtCaret(newElement.outerHTML);
                                                    pasteHtmlAtCaret(nonBreakingSpace);
                                                    values.subject = subject.innerHTML;
                                                } else if (!focusOnSubjectField && focusOnCanvasField) {
                                                    pasteHtmlAtCaret(newElement.outerHTML);
                                                    pasteHtmlAtCaret(nonBreakingSpace);
                                                    values.content = canvas.innerHTML;
                                                }

                                                addNewTagEventListener();
                                            }}
                                        >
                                            {item.label}
                                            <span className="arrow" onClick={e => {
                                                e.stopPropagation();
                                                e.nativeEvent.stopImmediatePropagation();
                                            }}>-{">"}</span>
                                        </li>
                                        <Tooltip 
                                            className="tooltip" title={item.description}
                                            classes={{
                                                tooltip: classes.tooltip
                                            }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                e.nativeEvent.stopImmediatePropagation();
                                            }}
                                        >
                                            <span tabIndex={0}><Info /></span>
                                        </Tooltip>
                                    </div>
                                )) : 
                                values.tags.map((item, index) => (
                                   // item.label === "Building Name" ? "" :
                                    <div key={index} className="row">
                                        <li 
                                            className="draggable" 
                                            draggable={true} 
                                            key={index} 
                                            data-tag={item.label} // this data-attribute holds the syntax for the tag
                                            data-content={item.templateTag}
                                            onDragStart={ 
                                                (event) => {
                                                    const tag = event.target.getAttribute('data-tag'); // get this elements data-tag
                                                    // becomes a detached html element within content, react has no control anymore
                                                    const html = `<span contenteditable="false" class="tag" data-sample="${item.sampleContent}" data-content="${item.templateTag}" data-type="label">${tag}</span>&nbsp;`; // put the tag into the html content
                                                    event.dataTransfer.setData('text/html', html);
                                                }
                                            }
                                            onDragEnd={
                                                () => {
                                                    addNewTagEventListener();
                                                }
                                            }
                                        >
                                            {item.label}
                                            <span className="arrow">-{">"}</span>
                                        </li>
                                        <Tooltip 
                                            className="tooltip" title={item.description}
                                            classes={{
                                                tooltip: classes.tooltip
                                            }}
                                        >
                                            <span tabIndex={0}><Info /></span>
                                        </Tooltip>
                                    </div>
                                ))
                            }
                        </ul>
                </div>
                <div className="template-preview">
                    <h2>Preview</h2>
                    <Switch
                        value={previewFlag}
                        onChange={handlePreviewToggle}
                        className="template-switch"
                        color="primary"
                    />
                   { !previewFlag && 
                    <div>
                        <ContentEditable
                            style={{ 
                                border: activeBorder === 'subject' && isIE11 && '2px solid #303f9f',
                                borderBottom: (touched.subject && errors.subject) ? '1px solid red' : '1px solid #000',
                            }}
                            onKeyDown={e => {
                                if (e.key === '&' || e.key === '>' || e.key === '<') {
                                    e.preventDefault();
                                }

                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    pasteHtmlAtCaret('<br/>');
                                }
                            }}
                            onPaste={e => {
                                const str = e.clipboardData.getData('Text');
                                const newStr = str.replace(/<|>|&/g, '');
                                if (str !== newStr) {
                                    e.preventDefault()
                                }
                            }} 
                            html={values.subject ? values.subject : ''}
                            onChange={handleSubjectChange}
                            className="subject"
                            data-text="Subject line" />
                            <span id="subject-helper-text" className="error-prompt">{touched.subject ? errors.subject : ""}</span>
                        <ContentEditable 
                            style={{ 
                                border: (activeBorder === 'canvas' && isIE11) ? '2px solid #303f9f' : touched.content && Boolean(errors.content) ? '1px solid red' : '1px solid #000',
                            }}
                            onKeyDown={e => {
                                if (e.key === '&' || e.key === '>' || e.key === '<') {
                                    e.preventDefault();
                                }

                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    pasteHtmlAtCaret('<br/>');
                                }
                            }}
                            onPaste={e => {
                                const str = e.clipboardData.getData('Text');
                                const newStr = str.replace(/<|>|&/g, '');
                                if (str !== newStr) {
                                    e.preventDefault()
                                }
                            }}
                            className="canvas" 
                            data-text="*Body" 
                            html={values.content ? values.content : ''} 
                            onChange={handleContentChange} />
                            <span id="content-helper-text" className="error-prompt">{touched.content ? errors.content : ""}</span>
                    </div> 
                   }
                   { previewFlag && 
                    <div>
                        <ContentEditable 
                            disabled={true}
                            className="subject"
                            data-text="Subject line"
                            style={{
                                cursor: 'not-allowed',
                                borderBottom: (touched.subject && errors.subject) ? '1px solid red' : '1px solid #000',
                            }}
                            html={previewSubject ? previewSubject : ''}/>
                        <span id="subject-helper-text" className="error-prompt">{touched.subject ? errors.subject : ""}</span>
                        <ContentEditable 
                            disabled={true}
                            className="canvas" 
                            data-text="*Body" 
                            style={{
                                cursor: 'not-allowed',
                                border: touched.content && Boolean(errors.content) ? '1px solid red' : '1px solid #000',
                            }}
                            html={previewContent ? previewContent : ''}/>
                            <span id="content-helper-text" className="error-prompt">{touched.content ? errors.content : ""}</span>
                    </div> 
                    }
                </div>
            </div>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type={'submit'} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

const EditAlertsTemplatesModal = props =>  {
    const { modalLoading, 
            modalOpen, 
            setModalClose,
            modalErrorDisplay,
            modalErrorMessage,
            deviceAlternateIdEnabled,
            locationAlternateIdEnabled,
            cerEnabled,
            classes } = props;
    
    const table = document.querySelector('.ReactTable');

    useEffect(() => {
        table.style.display = 'none';

        return () => {
            table.style.display = 'flex';
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const modal = document.querySelector('.fullscreen-modal > div > div');
            if (modal) {
                modal.focus();
                modal.tabIndex = '0';
            }
        }, 500);
    }, []);

    return (
        <div>
            <Dialog
                className="fullscreen-modal"
                fullScreen={false}
                disableEscapeKeyDown={true}
                open={modalOpen}
                onClose={setModalClose}
                disableBackdropClick
                style={{ overflow: 'none' }}
            >
                <AppBar id="template-app-bar" className={classes.appBar}>
                <Toolbar>
                    <IconButton color="inherit" onClick={() => {
                        setModalClose();
                    }} aria-label="Close" style={{ position: 'absolute', right: '5px', top: '8px', fontSize: '18px', borderRadius: '4px' }}>
                        CLOSE
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.flex}>
                    Edit Template
                    </Typography>
                </Toolbar>
                </AppBar>
                {modalErrorDisplay && <ModalErrorMessage modalErrorMessage={modalErrorMessage}/> }
                <Formik
                    initialValues={{ 
                        name: props.modalData.name, 
                        content: '',
                        subject: '',
                        tags: [],
                    }}
                    validate= { (values) => {
                        const clone = {...values};
                        clone.content = values.content.replace(/<br>|&nbsp;/g, '');

                        const validationSchema = Yup.object({
                            name: Yup.string("Enter a name")
                                    .trim()
                                    .required("Name is a required field.")
                                    .max(100, "Name has a max limit of 100 characters."),
                            subject: Yup.string("Enter a subject")
                                    .trim()
                                    .max(255, "Subject has a max limit of 255 characters."),
                            content: Yup.string("Enter content")
                                    .trim()
                                    .required("Content is a required field.")
                        })
                        try {
                            validateYupSchema(clone, validationSchema, true, {});
                        } catch (err) {
                            return yupToFormErrors(err);
                        }
                        return {}
                    }}
                    onSubmit = {
                        (values) => {
                            // Converts unicode symbols in html back to normal string, i.e &lt; converts to <
                            let decodedContent = he.decode(values.content);
                            let decodedSubject = he.decode(values.subject);

                            decodedContent = decodedContent
                                                .replace(/(<br\/?>)?<\/div>|<br\/?>/g, '\n')                                                
                                                .replace(/<span.*?data-content="(<\[[\w_]+?\]>)".*?<\/span>/g, "$1")
                                                .replace(/<(?!\[)[^>]*>/g,"")
                                                .replace(/&/g,"");
                            
                            decodedSubject = decodedSubject
                                                .replace(/(<br\/?>)?<\/div>|<br\/?>/g, '\n')                                                
                                                .replace(/<span.*?data-content="(<\[[\w_]+?\]>)".*?<\/span>/g, "$1")
                                                .replace(/<(?!\[)[^>]*>/g,"")
                                                .replace(/&/g,"");

                            values.content = decodedContent;
                            values.subject = decodedSubject;
                            values.id = props.modalData.id;
                            values.userDefined = props.userDefined;
                            values.alertType = props.modalData.alertType.name;
                            values.companyId = props.modalData.companyId;
                            props.submitEditAlertsTemplatesForm(values);
                        }
                    }
                    render={formikProps => <EditAlertsTemplatesForm 
                                                classes={classes}
                                                modalData={props.modalData} 
                                                modalLoading={modalLoading}
                                                alertTypeValue={props.modalData.alertType}
                                                deviceAlternateIdEnabled={deviceAlternateIdEnabled}
                                                locationAlternateIdEnabled={locationAlternateIdEnabled}
                                                cerEnabled={cerEnabled}
                                                company={props.company}
                                                {...formikProps}
                                                />}
                />
            </Dialog>
        </div>
    );
}

EditAlertsTemplatesModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
    modalErrorDisplay: modalErrorDisplay(),
    modalErrorMessage: modalErrorMessage(),
    deviceAlternateIdEnabled: selectAlternateIdEnabled(),
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled(),
    cerEnabled: selectCerEnabled(),
    company: selectUserCompany(),
});

const mapDispatchToProps = dispatch => {
    return {
        submitEditAlertsTemplatesForm: (template) => dispatch(AlertActions.editAlertTemplateRequest(template))        
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(EditAlertsTemplatesModal);