import { axiosInstance } from './api';
/* Cirrus Admin-Service /addressManagement Controller */
const ADDRESS_MANAGEMENT_ENDPOINT = 'geography-service/address';
const RESCAN_ENDPOINT = 'geography-service/address/revalidate';

export default class AddressManagementAPI {
    static getAddressManagement(page, pageSize, searchTerm, sortBy, direction) {
        return axiosInstance.get(ADDRESS_MANAGEMENT_ENDPOINT, {
            params: {
                page, 
                pageSize,
                searchTerm,
                sortBy,
                direction
            }
        });
    }

    static addressManagementRescan(addressId) {
        return axiosInstance.get(RESCAN_ENDPOINT, {
            params: {
                addressId
            }
        });
    }
}