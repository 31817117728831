import React, { useEffect } from 'react';
import Select from "@material-ui/core/Select";
import { Input, InputLabel, MenuItem } from "@material-ui/core";
import moment from 'moment';
import 'moment-timezone';

const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    let p = mins >= 0 ? '-' : '+'; // POSIX offset is inverted
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${p}${h}:${m}`;
}

// Legacy Timezone selections
const legacyTimezones = [
    {
        name: 'Canada/Atlantic',
        offset: moment.tz.zone('Canada/Atlantic').utcOffset(new Date())
    },
    {
        name: 'US/Eastern',
        offset: moment.tz.zone('US/Eastern').utcOffset(new Date())
    },
    {
        name: 'US/Central',
        offset: moment.tz.zone('US/Central').utcOffset(new Date())
    },
    {
        name: 'US/Mountain',
        offset: moment.tz.zone('US/Mountain').utcOffset(new Date())
    },
    {
        name: 'US/Pacific',
        offset: moment.tz.zone('US/Pacific').utcOffset(new Date())
    },
    {
        name: 'US/Alaska',
        offset: moment.tz.zone('US/Alaska').utcOffset(new Date())
    },
    {
        name: 'US/Hawaii',
        offset: moment.tz.zone('US/Hawaii').utcOffset(new Date())
    },
]

// Combine US/Canada timezones
export const zones = moment.tz.zonesForCountry('US', true)
    .concat(moment.tz.zonesForCountry('CA', true))
    .concat(legacyTimezones)
    .sort((a,b) => a.offset - b.offset)
    .map(
    (timezone) => {
        return {
            key: timezone.name,
            value: timezone.name.replaceAll('_', ' ') + " (UTC" + convertMinsToHrsMins(timezone.offset) + ")",
        }
    }
);

function TimeZone(props) {
    const { 
        value,
        onChange,
        disabled,
        touched,
        errors,
        fieldRequired,
        displayCurrentTimeZone
    } = props;

    return (
        <div>
            <InputLabel shrink={true} htmlFor="timeZoneType" error={touched.timeZoneType && Boolean(errors.timeZoneType)}>{fieldRequired && '*'}Time Zone</InputLabel>
            <div>
            <Select
                id="timeZoneType"
                name="timeZoneType"
                value={value}
                onChange={onChange}
                input={<Input name="timeZone"/>}
                error={touched.timeZoneType && Boolean(errors.timeZoneType)}>
                {
                    zones.map((zoneObj, index) => <MenuItem key={index} value={zoneObj.key}>{zoneObj.value}</MenuItem>)
                }
            </Select>
            </div>
        </div>
    );
}

export default TimeZone;