import React  from 'react';
import AsyncSelect from 'react-select/lib/Async';
import UsersAPI from '../../../../../apis/usersApi';
import { connect } from 'react-redux';
import { userCompanyId } from '../../../../../reducers/auth';
import './AlertSubscriptionAsyncSelect.css';
import { createStructuredSelector } from 'reselect';
import EonUsersAPI from "../../../../../apis/eonUsersApi";

function AlertSubscriptionAsyncSelect(props) {
  const promiseOptions = inputValue => {
    return EonUsersAPI.getAllEonUsers(props.companyId, inputValue ? inputValue : null).then(response => {
      response.data.forEach(obj => {
        obj.value = obj.id;
        obj.label = obj.email;
      });
      return response.data;
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <AsyncSelect
      placeholder=""
      inputId={props.id}
      className={props.error ? 'multi-select error' : 'multi-select'}
      isDisabled={props.isDisabled ? props.isDisabled : false}
      isMulti={props.isMulti ? props.isMulti : false}
      defaultOptions={true}
      defaultValue={props.defaultValue ? props.defaultValue : false}
      onChange={props.onChange}
      loadOptions={promiseOptions}
      closeMenuOnSelect={!props.isMulti}
      theme={(theme) => ({
        ...theme,
        colors: {
        ...theme.colors,
          primary25: 'rgba(0, 0, 0, 0.1)',
          primary: 'rgba(0, 0, 0, 0.2)',
        },
      })}  
    />
  );
}

const mapStateToProps = () => createStructuredSelector({
	companyId: userCompanyId()
});

export default connect(mapStateToProps)(AlertSubscriptionAsyncSelect)