import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { actions as AppActions, selectEonUserWelcomeEmailEnabled } from '../../reducers/app';
import { types } from '../../reducers/eonUsers';
import history from '../../utils/history';
import { createStructuredSelector } from 'reselect';
import { actions as EonUserActions } from '../../reducers/eonUsers';
import handleCopyToClipboard from '../../utils/handleCopyToClipboard';

function EonUserEditMenu(props) {
	let options;
	const ITEM_HEIGHT = 48;
	
	const [anchorEl, setAnchorEl] = useState(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [copyToClipboardFailed, setCopyToClipboardFailed] = useState(false);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

		setSnackbarOpen(false);
    };

	const handleMenuItemClick = (option) => {
		handleClose();
		switch (option) {
			case 'Edit':
				props.setCurrentModal(types.EDIT_EON_USER, props.row.original);
				break;
			case 'Delete':
				props.setCurrentModal(types.DELETE_EON_USER, props.row.original);
				break;
			case 'Copy UUID to Clipboard':
				handleCopyToClipboard(props.row.original.id, setSnackbarOpen, setCopyToClipboardFailed);
				break;
			default:
				return null;
		}
	};

	const open = Boolean(anchorEl);
	const buildEditMenu = () => {
		const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
		const isEdit = user && user.permissions.includes('EDIT_EON_USERS');
		const isDelete = user && user.permissions.includes('DELETE_EON_USERS');
		const isEonUser = props.row.original.role.name === 'EON_USER';

		options = [];
		if (isEonUser && isEdit) options.push('Edit');
		if (isEonUser && isDelete) options.push('Delete');
		options.push('Copy UUID to Clipboard');
	}
	buildEditMenu();

	return (
		<div>
			<IconButton
				id={`eon-user-action-menu-button-${props.row.index}`}
				aria-label="More"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id={`eon-user-action-menu-${props.row.index}`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 6.5,
						minWidth: 200,
					},
				}}
			>
					{options.map((option, index) => (
						<MenuItem id={`eon-user-item-${props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => handleMenuItemClick(option, event)}>
							{option}
						</MenuItem>
					))}
			</Menu>
			<Snackbar
				className={`snackbar-popup ${copyToClipboardFailed && 'error'}`}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				message={<div>
					{copyToClipboardFailed ? 
						<span id="client-snackbar">
							The code/ID was not copied to your clipboard. Please copy manually:<br/>
							{props.row.original.id}
						</span>
						:
						<span id="client-snackbar">
							<CheckCircleIcon className={"classes.icon classes.iconVariant"} />
							The code/ID has been copied to your clipboard
						</span>
					}
				</div>}
				action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={handleSnackbarClose}
				>
					<CloseIcon />
				</IconButton>,
				]}
			/>
		</div>
	);
}

const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
	}
};

export default connect(null, mapDispatchToProps)(EonUserEditMenu);
