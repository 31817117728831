import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types } from '../../reducers/pidfloDevices';
import handleCopyToClipboard from '../../utils/handleCopyToClipboard';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

function PidfloDevicesEditMenu(props)  {
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [copyToClipboardFailed, setCopyToClipboardFailed] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isEdit = user && user.permissions.includes('EDIT_PIDFLO_DEVICES');
	const isDelete = user && user.permissions.includes('DELETE_PIDFLO_DEVICES');

	const options = [
		'Copy UUID to Clipboard'
	];

	if (isDelete) options.unshift('Delete'); 
	if (isEdit) options.unshift('Edit');
	
	const ITEM_HEIGHT = 48;

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

		setSnackbarOpen(false);
    };

	const handleMenuItemClick = (option) => {
		handleClose();
		switch (option) {
			case 'Edit':
				props.setCurrentModal(types.EDIT_PIDFLO_DEVICES, props.row.original);
				break;
			case 'Delete':
				props.setCurrentModal(types.DELETE_PIDFLO_DEVICES, props.row.original);
				break;
			case 'Copy UUID to Clipboard':
				handleCopyToClipboard(props.row.original.id, setSnackbarOpen, setCopyToClipboardFailed)
			default:
				return null;
		}
	};

	
	
	const open = Boolean(anchorEl);

		return (
			<div>
				<IconButton
					id={`pidfloDevices-action-menu-button-${props.row.index}`}
					aria-label="More"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id={`pidfloDevices-action-menu-${props.row.index}`}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 6.5,
							minWidth: 200,
						},
					}}
				>
					{options.map((option, index) => (
						<MenuItem id={`pidfloDevices-item-${props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => handleMenuItemClick(option, event)}>
							{option}
						</MenuItem>
					))}
				</Menu>
				<Snackbar
				className={`snackbar-popup ${copyToClipboardFailed && 'error'}`}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				message={<div>
					{copyToClipboardFailed ? 
						<span id="client-snackbar">
							The code/ID was not copied to your clipboard. Please copy manually:<br/>
							{props.row.original.id}
						</span>
						:
						<span id="client-snackbar">
							<CheckCircleIcon className={"classes.icon classes.iconVariant"} />
							The code/ID has been copied to your clipboard
						</span>
					}
				</div>}
				action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={handleSnackbarClose}
				>
					<CloseIcon />
				</IconButton>,
				]}
			/>
			</div>
		);
	}


PidfloDevicesEditMenu.propTypes = {
	row: PropTypes.object,
	setCurrentModal: PropTypes.func
};


const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default connect(null, mapDispatchToProps)(PidfloDevicesEditMenu);
