import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import NumberFormat from 'react-number-format';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Tooltip } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import FormLabel from '@material-ui/core/FormLabel';

import DeviceUserIdsListTable from './DeviceUserIdsListTable';
import DeviceUserAPI from '../../../../apis/deviceUsersApi';

const DeviceUserForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        modalData,
        setFieldValue,
        setFieldTouched,
        identificationTypeOption,
        setIdentificationTypeOption,
        alternateDeviceUserIdEnabled,
        setValues,
        setErrors,
        setTouched,
        deviceUserIds,
        setDeviceUserIds,
        companyId,
        modalErrorOptions,
        classes,
        setFormikValues } = props;

    const [notUnique, setNotUnique] = useState(false);
    const [maxLength, setMaxLength] = useState(false);
    const [isdeviceUserIdAsPhone, setIsdeviceUserIdAsPhone] = useState(false);

    const phoneNumberHandleOnChange = (values) => {
        if (values.value) {
            setFieldValue('phoneNumber', values.value);
        } else {
            setFieldValue('phoneNumber', '');
        }
        setFieldTouched('phoneNumber', true);
    }

    const callbackNumberHandleOnChange = (values) => {
        if (values.value) {
            setFieldValue('callbackNumber', values.value);
        } else {
            setFieldValue('callbackNumber', '');
        }
        setFieldTouched('callbackNumber', true);
    }

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    function handleIdentificationTypeChange(e) {
        setIdentificationTypeOption(e.target.value);
        // reset form data on toggle
        setErrors({});
        setTouched({});
    }

    const handleDeviceUserIds = () => {
        if (
            values.alternateId &&
            (!errors.alternateId || errors.alternateId.includes('At least one Device User ID must be added to the list below.')) && 
            !deviceUserIds.find(item => item.alternateId === values.alternateId)
        ) {
            DeviceUserAPI.validateAlternateId(values.alternateId, companyId).then(response => {
                if (deviceUserIds.length < 50) {
                    setDeviceUserIds([
                        ...deviceUserIds,
                        {
                            alternateId: values.alternateId
                        }
                    ]);
                    
                    setMaxLength(false);
                    setFieldValue('alternateId', '');
                } else {
                    setMaxLength(true);
                }

                setNotUnique(false);
            }).catch(error => {
                if(error.response.data.type === "http://cirrus.redskytech.com/errors/deviceUserIdAsPhone"){
                setIsdeviceUserIdAsPhone(true);
            }else{
                setNotUnique(true);
            }
            });
        } else {
            if (values.alternateId && !Boolean(errors.alternateId)) {
                setNotUnique(true);
            }
        }
    }

    const deviceUserIdsDeleteHandler = row => {
        setDeviceUserIds(
            deviceUserIds.filter(item => item.alternateId !== row.original.alternateId)
        );
    }

    function handleClear() {
        if (modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) {
            modalErrorOptions.backendFieldError.name = '';
        }
    }

    useEffect(() => {
        if (deviceUserIds.length < 50) {
            setMaxLength(false);
        }
    }, [deviceUserIds.length]);
    
    useEffect(() => {
        setFormikValues(values);
    }, [values]);
    
    useEffect(() => {
        if (!modalData) {
            setIdentificationTypeOption('PHONE_NUMBER');
        }
    }, [values.enableMyE911Functionality]);

    return (
            <form className="device-user-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                {modalLoading && <LinearProgress/>}
                <TextField
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={(touched.firstName && Boolean(errors.firstName)) || (errors.firstName && Boolean(errors.firstName.includes('max')))}/>
                    <span id="firstName-helper-text" className="error-prompt">{(touched.firstName && Boolean(errors.firstName)) || (errors.firstName && Boolean(errors.firstName.includes('max'))) ? errors.firstName : ''}</span>
                <br/>
                <TextField
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={(touched.lastName && Boolean(errors.lastName)) || (errors.lastName && Boolean(errors.lastName.includes('max')))}/>
                    <span id="lastName-helper-text" className="error-prompt">{(touched.lastName && Boolean(errors.lastName)) || (errors.lastName && Boolean(errors.lastName.includes('max'))) ? errors.lastName : ''}</span>
                <br/>
                <div>
                        <Grid container direction="row"  style={ {position: 'relative'}}>
                            <NumberFormat 
                                autoComplete="off"
                                id="callbackNumber"
                                label={(values.enableMyE911Functionality && identificationTypeOption === "DEVICE_USER_ID")? '*Callback Number' : 'Callback Number'}
                                name="callbackNumber"
                                type="tel"
                                onValueChange={callbackNumberHandleOnChange}
                                value={values.callbackNumber}
                                onBlur={handleBlur}
                                disabled={modalLoading}
                                fullWidth={true}
                                format="(###) ###-####" 
                                customInput={TextField}
                                error={touched.callbackNumber && Boolean(errors.callbackNumber)}
                                mask="_"/>
                                <span id="callbackNumber-helper-text" className="error-prompt">{touched.callbackNumber ? errors.callbackNumber : ""}</span>
                            <Tooltip title="A 10-digit callback number to be used in the case of a dropped call.">

                                <span style={{ position: 'absolute', right:'0px', top:'20px'}} tabIndex={0}><Info /></span>
                            </Tooltip>
                        </Grid>
                        <br/>
                    </div>
                    <br/>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Enable or disable HELD+. Note: If you disable before saving, any values entered will be cleared.">
                        <span style={{ margin: '0 15px 0 7px'}} tabIndex={0}><Info /></span>
                    </Tooltip>
                    <label htmlFor="enableHeldPlusFunctionality">Enable HELD+ Functionality</label>
                    <Switch
                        id="enableHeldPlusFunctionality"
                        name="enableHeldPlusFunctionality"
                        color="primary"
                        checked={values.enableHeldPlusFunctionality}
                        onChange={handleChange}
                        disabled={modalLoading || (modalData && !!modalData.heldUserId)}
                        value='enableHeldPlusFunctionality'
                    /> 
                </div>
                {values.enableHeldPlusFunctionality &&
                    <div>
                        <TextField
                            id="heldUserId"
                            label={values.enableHeldPlusFunctionality ? '*HELD+ User ID' : 'HELD+ User ID'}
                            name="heldUserId"
                            onChange={handleChange}
                            value={values.heldUserId}
                            onBlur={handleBlur}
                            disabled={modalLoading || (modalData && !!modalData.heldUserId)}
                            fullWidth={true}
                            error={((touched.heldUserId && Boolean(errors.heldUserId)) || (errors.heldUserId && Boolean(errors.heldUserId.includes('max'))))}/>
                            <span id="heldUserId-helper-text" className="error-prompt">{((touched.heldUserId && Boolean(errors.heldUserId)) || (errors.heldUserId && Boolean(errors.heldUserId.includes('max')))) ? errors.heldUserId : ''}</span>
                        <br/>
                    </div>
                }
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Enable or disable MyE911. Note: If you disable before saving, any values entered will be cleared.">
                        <span style={{ margin: '0 15px 0 7px'}} tabIndex={0}><Info /></span>
                    </Tooltip>
                    <label htmlFor="enableMyE911Functionality">Enable MyE911 Functionality</label>
                    <Switch
                        id="enableMyE911Functionality"
                        name="enableMyE911Functionality"
                        color="primary"
                        checked={values.enableMyE911Functionality}
                        onChange={handleChange}
                        disabled={modalLoading || (modalData && !!modalData.email)}
                        value='enableMyE911Functionality'
                    /> 
                </div>
                {values.enableMyE911Functionality && 
                    <div>
                        <TextField
                            id="email"
                            label={values.enableMyE911Functionality ? '*Email' : 'Email'}
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                            disabled={modalLoading || (modalData && !!modalData.email)}
                            fullWidth={true}
                            error={((touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))))}/>
                            <span id="email-helper-text" className="error-prompt">{((touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')))) ? errors.email : ''}</span>
                        <br/>
                    </div>
                }
                {(alternateDeviceUserIdEnabled && values.enableMyE911Functionality) && 
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Tooltip title="If the device user has not been assigned a 10-digit phone number, select 'Device User ID', so the ID used to alternatively identify the device user in your system can be entered."
                        >
                            <span style={{ margin: '0 5px 0 7px'}} tabIndex={0}><Info /></span>
                        </Tooltip>
                        <FormLabel htmlFor="idType" className={classes.label}>Identification Type:</FormLabel>
                        <RadioGroup id="idType" value={identificationTypeOption} onChange={handleIdentificationTypeChange} row>
                            <FormControlLabel value={"PHONE_NUMBER"} control={<Radio color="primary" />} label="Phone Number" />
                            <FormControlLabel value={"DEVICE_USER_ID"} control={<Radio color="primary" />} label="Device User ID"/>
                        </RadioGroup>
                    </Grid>
                }
                {(values.enableMyE911Functionality && identificationTypeOption === "PHONE_NUMBER") &&
                    <div>
                        <NumberFormat 
                            autoComplete="off"
                            id="phoneNumber"
                            label={values.enableMyE911Functionality ? '*Phone Number' : 'Phone Number'}
                            name="phoneNumber"
                            type="tel"
                            onValueChange={(e) => {
                                phoneNumberHandleOnChange(e);
                                handleClear();
                            }}
                            value={values.phoneNumber}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            format="(###) ###-####" 
                            customInput={TextField}
                            mask="_"
                            error={(touched.phoneNumber && Boolean(errors.phoneNumber)) || (modalErrorOptions && !!modalErrorOptions.backendFieldError && !!modalErrorOptions.backendFieldError.name)}/>
                {(modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) && 
                    <span id="phoneNumber-backend-helper-text" className="error-prompt">{modalErrorOptions.backendFieldError.name}</span>
                }
                            <span id="phoneNumber-helper-text" className="error-prompt">{touched.phoneNumber ? errors.phoneNumber : ""}</span>
                        <br/>
                    </div>
                }
                {(values.enableMyE911Functionality && identificationTypeOption === "DEVICE_USER_ID") &&
                    <div>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <TextField
                                id="alternateId"
                                label={deviceUserIds.length === 0 && values.enableHeldPlusFunctionality ? "*Device User ID" : "Device User ID"}
                                name="alternateId"
                                onChange={handleChange}
                                value={values.alternateId}
                                onBlur={handleBlur}
                                disabled={modalLoading}
                                fullWidth={true}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                error={maxLength || notUnique || isdeviceUserIdAsPhone || (touched.alternateId && Boolean(errors.alternateId)) || (errors.alternateId && Boolean(errors.alternateId.includes('max')))}/>
                            <Button style={{ margin: '0px 0 7px 20px' }}
                                id="deviceUser-add-deviceId-button"
                                disabled={modalLoading} 
                                className="button"
                                color="primary" 
                                onClick={handleDeviceUserIds}
                                variant="contained">Add</Button>
                        </div>
                        <span style={{ marginTop: -2 }} id="alternateId-helper-text" className="error-prompt">{(touched.alternateId && Boolean(errors.alternateId)) || (errors.alternateId && Boolean(errors.alternateId.includes('max'))) ? errors.alternateId : ''}</span>
                        {(isdeviceUserIdAsPhone && values.alternateId.length > 0) && 
                             <span className="error-prompt" style={{ marginTop: -2 }}>The value entered in Device User ID already exists as a Phone Number.</span>
                        }
                        {(notUnique && values.alternateId.length > 0) && 
                            <span className="error-prompt" style={{ marginTop: -2 }}>The ID entered is not unique. Please enter another ID and try again.</span>
                        }
                        {maxLength && 
                            <span className="error-prompt" style={{ marginTop: -2 }}>Only 50 Device User IDs can be added per Device User.</span>
                        }
                    </div>
                }
                {alternateDeviceUserIdEnabled && identificationTypeOption === 'DEVICE_USER_ID' && 
                    <DeviceUserIdsListTable
                        deviceUserIds={deviceUserIds}
                        deviceUserIdsDeleteHandler={deviceUserIdsDeleteHandler}
                    />
                }
                <Grid container direction="column" justify="center" alignItems="center">
                    <Button id="saveBtn"
                            type="submit" 
                            disabled={modalLoading} 
                            className = {classes.btn}
                            onClick={handleScroll}
                            color="primary" 
                            variant="contained">Save</Button>
                </Grid>
            </form>
    );
}

export default DeviceUserForm;