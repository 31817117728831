import React from 'react';

function SearchField(props) {
    return (
        <input
            aria-label='Search Field'
            placeholder="Search"
            className="search-input"
            value={props.value}
            onChange={props.onChange}
        />
    );
}

export default SearchField;