import React, { Component, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions as EonUsersActions, types } from '../../../reducers/eonUsers';
import { validationSchema } from './validation.js';
import { createStructuredSelector } from "reselect";
import { userCompanyId } from '../../../reducers/auth';

const styles = () => ({
	btn: {
		marginTop: "10px"
	}
});

const AddEditEonUserForm = props => {
	const { values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit, 
			modalLoading,
			classes
	} = props;

	function handleScroll() {
		const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
		modal.scrollTo(0, 0);
	}

	return (
		<form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
			{modalLoading && <LinearProgress/>}
			<br/>
			<TextField
				id="email"
				label="Email"
				name="email"
				type="email"
				onChange={handleChange}
				value={values.email}
				onBlur={handleBlur}
				disabled={modalLoading}
				fullWidth={true}
				required={true}
				error={((touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))))}/>
				<span id="email-helper-text" className="error-prompt">{((touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')))) ? errors.email : ''}</span>
			<br/>
			<TextField
				id="firstName"
				label="First Name"
				name="firstName"
				type="text"
				onChange={handleChange}
				value={values.firstName ? values.firstName : ''}
				onBlur={handleBlur}
				disabled={modalLoading}
				fullWidth={true}
				error={(touched.firstName && Boolean(errors.firstName)) || (errors.firstName && Boolean(errors.firstName.includes('max')))}/>
				<span id="firstName-helper-text" className="error-prompt">{(touched.firstName && Boolean(errors.firstName)) || (errors.firstName && Boolean(errors.firstName.includes('max'))) ? errors.firstName : ''}</span>
			<br/>
			<TextField
				id="lastName"
				label="Last Name"
				name="lastName"
				type="text"
				onChange={handleChange}
				value={values.lastName ? values.lastName : ''}
				onBlur={handleBlur}
				disabled={modalLoading}
				fullWidth={true}
				error={(touched.lastName && Boolean(errors.lastName)) || (errors.lastName && Boolean(errors.lastName.includes('max')))}/>
				<span id="lastName-helper-text" className="error-prompt">{(touched.lastName && Boolean(errors.lastName)) || (errors.lastName && Boolean(errors.lastName.includes('max'))) ? errors.lastName : ''}</span>
			<br/>
			{(!values.logSSO) &&
				<p className="help-text">
					Please note that the user will need to click on the 'Forgot/Reset Password' link <br/>
					on the log in page, before logging in for the first time.
				</p>
			}
			<Grid container direction="column" justify="center" alignItems="center">
				<Button type="submit" 
					disabled={modalLoading}
					className = {classes.btn}
					onClick={handleScroll}
					color="primary"
					variant="contained">Save</Button>
			</Grid>
		</form>
	);
};

function AddEditEonUserModal(props) {
	const { modalLoading, classes, modalData, companyId } = props;
	
	return(
		<Formik
			initialValues={
				modalData ? 
				{
					email: (modalData.email) ? modalData.email : '',
					firstName: (modalData.firstName) ? modalData.firstName : '',
					lastName: (modalData.lastName) ? modalData.lastName : '',
				} : {
					email: '',
					firstName: '',
					lastName: '',
				}
			}
			validationSchema={validationSchema}
			onSubmit = {
				(values) => {
					values.companyId = companyId;
					
					if (modalData) {
						props.submitEditEonUserForm(values, modalData);
					} else {
						props.submitAddEonUserForm(values);
					}
				}
			}
			render={props => <AddEditEonUserForm modalLoading={modalLoading} classes={classes} {...props} />}
		/>
	);
}

const mapStateToProps = () => createStructuredSelector({
	companyId: userCompanyId()
});

const mapDispatchToProps = dispatch => {
    return {
		submitEditEonUserForm: (eonUser, row) => dispatch(EonUsersActions.editEonUserRequest(eonUser, row)),
		submitAddEonUserForm: (eonUser) => dispatch(EonUsersActions.addEonUserRequest(eonUser))
	}
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	ModalWrapper,
	withStyles(styles)
) (AddEditEonUserModal);