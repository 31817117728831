import * as Yup from "yup";

export const validationSchema = (completeList, currentTab, formValues, isLocations) => (
      Yup.object().shape({
        name: Yup.string("Enter a name")
                .trim().required("Name is a required field.")
                .max(100, "Name has a max limit of 100 characters."),
        alertType: Yup.object().shape({
            value: Yup.string("Choose alert type")
                .trim().required("Alert Type is a required field."),
        }),
        template: Yup.object().shape({
            name: Yup.string("Choose alert type")
                .trim().required("Alert Template is a required field."),
        }),
        timeZoneType: Yup.string("Choose Time Zone")
                .trim().required("Time Zone is a required field."),
        locationType: (formValues && !formValues.allCorporateLocations && !formValues.allPersonalLocations) ? Yup.string("Choose A Building")
                .trim().required("Location Type is a required field.") : '',
        buildings: (formValues && !isLocations && !formValues.allCorporateLocations && !formValues.allPersonalLocations) ? Yup.string("Choose A Building")
                .trim().required("Building is a required field.") : '',
        locations: (formValues && isLocations && !formValues.allCorporateLocations && !formValues.allPersonalLocations) ? Yup.string("Choose A Building")
                .trim().required("Location is a required field.") : '',
        user: currentTab === 0 && completeList.length <= 0 ?
                Yup.string("Choose user")
                .trim()
                .required("At least one recipient must be added to the list.")
                :
                Yup.string("Choose user")
                .trim(),
        informacast: currentTab === 3 && completeList.length <= 0 ?
                 Yup.string("Choose informacast")
                .trim()
                .required("At least one recipient must be added to the list.")
                :
                Yup.string("Choose informacast")
                .trim()
                .nullable(),
        sms: currentTab === 1 && completeList.length <= 0 ?
                Yup.string("Enter a number")
                .trim()
                .min(9, 'Please enter a valid phone number')
                .required("At least one recipient must be added to the list.")
                :
                Yup.string("Enter a number")
                .trim()
                .min(9, 'Please enter a valid phone number'),
        email: currentTab === 2 && completeList.length <= 0 ?
                Yup.string("Enter an email")
                .trim()
                .email('Please enter a valid email')
                .max(100, "Email has a max limit of 100 characters.")
                .required("At least one recipient must be added to the list.")
                :
                Yup.string("Enter an email")
                .trim()
                .email('Please enter a valid email')
                .max(100, "Email has a max limit of 100 characters.")
    
        }).test(
            currentTab === 0 ? 'user' : currentTab === 1 ? 'sms' : currentTab === 2 ? 'email' : currentTab === 3 ? 'informacast' : '',
            null,
            (obj) => {
                if (completeList.length <= 0) {
                    return new Yup.ValidationError(
                        'At least one recipient must be added to the list.',
                        null,
                        currentTab === 0 ? 'user' : currentTab === 1 ? 'sms' : currentTab === 2 ? 'email' : currentTab === 3 ? 'informacast' : ''
                    )
                } else {
                    return true;
                }
            }
        )
);

export const styles = theme => ({
    form: {
        width: '550px'
    },
    btn: {
        marginTop: '23px',
        float: 'right'
    },
    section: {
        width: '450px',
        float: 'left'
    },
    table: {
        clear: 'both'
    },
    field: {
        width: '100%'
    },
    alignRight: {
        float: 'right',
        width: '45%',
        marginTop: '14px'
    },
    subtitle: {
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        left: '110px',
        top: '3px'
    },
    tooltip: {
      fontSize: '14px'
    }
});