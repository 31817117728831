import React, { useEffect } from 'react';
import Info from '@material-ui/icons/Info';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';


import { selectUserCompany } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import './emergencyCallAssist.css';
import AddressAndGeoComponent from '../../components/AddressAndGeoComponent/AddressAndGeoComponent';

function EmergencyCallAssistForm(props){
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        modalLoading,
        classes } = props;
    const handleOnChange = (values) => {
        if (values.value) {
            props.setFieldValue('callbackNumber', values.value);
        } else {
            props.setFieldValue('callbackNumber', '');
        }
        props.setFieldTouched('callbackNumber', true);
    }

    return <form style={{ display: 'flex', flexDirection: 'column' }} className="emergency-call-assist-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
        {modalLoading && <LinearProgress/>}
        <TextField
            id="name"
            label="Caller Name"
            name="name"
            type="name"
            onChange={handleChange}
            value={values.name ? values.name : ''}
            onBlur={handleBlur}
            disabled={modalLoading}
            fullWidth={true}
            error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/> 
            <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <br/>
            <div> 
                <NumberFormat 
                    autoComplete="no"
                    id="callbackNumber"
                    label="*Callback Number"
                    name="callbackNumber"
                    type="text"
                    onValueChange={handleOnChange}
                    value={values.callbackNumber}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    className={classes.field}
                    fullWidth={true}
                    error={touched.callbackNumber && Boolean(errors.callbackNumber)}
                    format="(###) ###-####" 
                    customInput={TextField}
                    mask="_"/> 
                    <span id="callbackNumber-helper-text" className="error-prompt">{touched.callbackNumber ? errors.callbackNumber : ""}</span>
            </div>
        <br/>
        <AddressAndGeoComponent 
            {...props}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip style={{ position: 'static', zIndex: 1, marginLeft: 'auto', marginBottom: -40 }} title="Room/Floor/Apt/Ste" classes={{

                    tooltip: classes.tooltip
            }}>
                <span className={classes.icon} tabIndex={0}><Info /></span>
            </Tooltip>
            <TextField
                id="info"
                label={props.company.toJS().requireLocationInfo ? "*Location Information" : "Location Information"}
                name="info"
                type="info"
                onChange={handleChange}
                className={classes.info}
                value={values.info ? values.info : ''}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.info && Boolean(errors.info)) || (errors.info && Boolean(errors.info.includes('max')))}/>
                <span id="info-helper-text" className="error-prompt">{(touched.info && Boolean(errors.info)) || (errors.info && Boolean(errors.info.includes('max'))) ? errors.info : ''}</span>
        </div>
        <TextField
            id="agentInfo"
            label="Agent Info"
            name="agentInfo"
            type="agentInfo"
            onChange={handleChange}
            value={values.agentInfo ? values.agentInfo : ''}
            onBlur={handleBlur}
            disabled={modalLoading}
            fullWidth={true}
            error={(touched.agentInfo && Boolean(errors.agentInfo)) || (errors.agentInfo && Boolean(errors.agentInfo.includes('max')))}/> 
            <span id="agentInfo-helper-text" className="error-prompt">{(touched.agentInfo && Boolean(errors.agentInfo)) || (errors.agentInfo && Boolean(errors.agentInfo.includes('max'))) ? errors.agentInfo : ''}</span>
            <br/>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: 300 }}>
                <label htmlFor="callType" style={{ display: 'inline-block', marginBottom: 20 }}>*Call Type:</label>
                <Select
                        style={{ display: 'inline-block', marginBottom: 30 }}
                        id="callType"
                        name="callType"
                        value={values.callType}
                        onChange={handleChange}
                        disabled={modalLoading}
                        fullWidth={true}
                    >
                        <MenuItem value="EMERGENCY">EMERGENCY</MenuItem>
                        <MenuItem value="TEST">TEST</MenuItem>
                    </Select>
            </div>
            <div style={{ width: 300, marginLeft: 50 }}>
                <label 
                    htmlFor="routeType" 
                    style={{ 
                        display: 'inline-block', 
                        marginBottom: 20,
                        color: (touched.routeType && Boolean(errors.routeType)) || (errors.routeType && Boolean(errors.routeType.includes('max'))) ? 'red' : 'initial'
                    }}
                >
                    *Route Type:
                </label>
                <Select
                        style={{ display: 'inline-block' }}
                        id="routeType"
                        name="routeType"
                        value={values.routeType}
                        onChange={handleChange}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={(touched.routeType && Boolean(errors.routeType)) || (errors.routeType && Boolean(errors.routeType.includes('max')))}
                    >
                        <MenuItem value="SIP">SIP</MenuItem>
                        <MenuItem value="PSTN">PSTN</MenuItem>
                    </Select>
                    <span id="routeType-helper-text" className="error-prompt">{(touched.routeType && Boolean(errors.routeType)) || (errors.routeType && Boolean(errors.routeType.includes('max'))) ? errors.routeType : ''}</span>
            </div>
        </div>
        <Grid container direction="column" justify="center" alignItems="center">
            <Button id="saveBtn"
                    type="submit" 
                    disabled={modalLoading} 
                    className = {classes.btn}
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    color="primary" 
                    variant="contained">Validate Location</Button>
        </Grid>
    </form>
}

const mapStateToProps = () => createStructuredSelector({
    company: selectUserCompany()
});

export default connect(mapStateToProps)(EmergencyCallAssistForm);