import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/heldDevices';
import { connect } from 'react-redux';

class DeleteHeldDeviceModal extends Component {

    handleDelete = () => {        
        this.props.submitDeleteHeldDevice(this.props.modalData.id);
    }

    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like to delete a HELD Device.
                        </p>
                    </Grid>
                    {/* <Grid item >
                        <span>Building Name</span><br/>
                        <b id="buildingName">{this.props.modalData.name}</b>
                    </Grid> */}
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteHeldDevice: (id) => dispatch(actions.deleteHeldDeviceRequest(id))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteHeldDeviceModal);