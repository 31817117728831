import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as DeviceUsersActions, selectLocationsByParentId, locationsLoading } from '../../reducers/deviceUsers';
import { createStructuredSelector } from 'reselect';
import DeviceUsersLocationsListTable from '../../containers/DeviceUsersLocationsListTable/deviceUsersLocationsListTable';

class DeviceUsersLocationsList extends Component {
    render() {
        return <DeviceUsersLocationsListTable 
                    deviceUserId={this.props.deviceUserId} 
                    locations={this.props.locations} 
                    loading={this.props.loading} 
                    setCurrentModal={this.props.setCurrentModal}
                    currentLocationId={this.props.currentLocationId}/>
    }
}

DeviceUsersLocationsList.propTypes = {
    deviceUserId: PropTypes.string.isRequired,
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({       
        locations: selectLocationsByParentId(ownProps.deviceUserId),
        loading: locationsLoading(ownProps.deviceUserId)
    });
}

export default connect(mapStateToProps)(DeviceUsersLocationsList);