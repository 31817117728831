import { call, put, takeLatest, all } from 'redux-saga/effects';
import TestCallGeneratorAPI from '../apis/testCallGeneratorApi';
import { actions as AppActions } from '../reducers/app';
import { actions as TestCallGeneratorActions,
		 types as TestCallGeneratorTypes } from '../reducers/testCallGenerator';
import errorHandler from '../utils/errorHandler';

function* testCallGenerator(action) {
	try {
		yield call(TestCallGeneratorAPI.testCallGenerator, action.data);
	} catch (error) {
		if (error) {
			yield put(AppActions.setModalError(errorHandler(error)));
		}
	}
}

function* rootLocationsSaga() {
	yield all([
		yield takeLatest(TestCallGeneratorTypes.TEST_CALL_GENERATOR_REQUEST, testCallGenerator)
	]);
}

export default rootLocationsSaga;
