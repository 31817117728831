import { axiosInstance } from './api';
import moment from 'moment';

/* Cirrus Admin-Service /locations Controller */
const LICENSING_SERVICE_ENDPOINT = '/licensing-service/';
const GET_LICENSES_ENDPOINT = LICENSING_SERVICE_ENDPOINT + 'licensing/';
const GET_LICENSE_TYPES_ENDPOINT = LICENSING_SERVICE_ENDPOINT + 'licenseTypes';
const GET_LICENSES_COUNT_ENDPOINT = LICENSING_SERVICE_ENDPOINT + 'licensing/counts/';
const CHECK_IF_ORG_HAS_LICENSE_ENDPOINT = LICENSING_SERVICE_ENDPOINT + 'license/allow';
const DELETE_LICENSE_ENDPOINT = LICENSING_SERVICE_ENDPOINT + 'license/';

export default class LicensingServiceApi {

    static getLicensesByOrgId(orgId, page, pageSize) {
        return axiosInstance.get(GET_LICENSES_ENDPOINT + orgId, {
            params: {
                page,
                pageSize
            }
        });
    }


    static addLicense(license) {
        let payload;
        if (window.location.href.includes('RIGHT_TO_USE')) {
            payload = {
                count: license.count,
                orgId: license.companyId,
                type: license.type,
                subType: license.subType,
                orderNumber: license.orderNumber,
                notes: license.notes 
            };    
        } else {
            payload = {
                count: license.count,
                orgId: license.companyId,
                expires: moment(license.expires).format('YYYY-MM-DD'),
                type: license.type,
                subType: license.subType,
                orderNumber: license.orderNumber,
                notes: license.notes 
            };    
        }
        
        return axiosInstance.post(GET_LICENSES_ENDPOINT, payload);
    }

    static getLicenseCount(orgId) {
        return axiosInstance.get(GET_LICENSES_COUNT_ENDPOINT + orgId);
    }
    
    static getLicenseCountLocation(orgId) {
        return axiosInstance.get(GET_LICENSES_COUNT_ENDPOINT + orgId + '?parentType=LOCATION');
    }
    
    static getLicenseCountUser(orgId) {
        return axiosInstance.get(GET_LICENSES_COUNT_ENDPOINT + orgId + '?parentType=USER');
    }
    
    static getLicenseCountsByOrgList(orgIds) {
        return axiosInstance.post(GET_LICENSES_COUNT_ENDPOINT, orgIds);
    }
    
    static checkIfOrgHasLicense(orgId) {
        return axiosInstance.get(`${CHECK_IF_ORG_HAS_LICENSE_ENDPOINT}?licenseTypes=ENHANCED_NOTIFICATION&orgId=${orgId}`);
    }

    static getLicenseTypes() {
        return axiosInstance.get(GET_LICENSE_TYPES_ENDPOINT);
    }
    
    static deleteLicense(id) {
        return axiosInstance.delete(DELETE_LICENSE_ENDPOINT + id);
    }
}