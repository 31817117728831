import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './AlertsSubscriptionsRecipientListTable.css';
import Delete from '@material-ui/icons/Delete';
import Sms from '@material-ui/icons/Sms';
import Email from '@material-ui/icons/Email';
import Users from './assets/users.png';
import Group from '@material-ui/icons/Group';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';

function AlertsSubscriptionsRecipientsTable(props) {
    const buildTableColumns = [
        {
            Header: "",
            id: "icons",
            width: 65,
            resizable: false,
            Cell: row => {
                if (row.original.email) {
                    return <Email/>;
                } else if (row.original.phoneNumber) {
                    return <Sms/>;
                } else if (row.original.user) {
                    return <img aria-hidden={true} style={{ width: '27px' }} src={Users} alt="Users icon"/>;
                } else if (row.original.name) {
                    return <span className="combined-icon">
                                <Group/>
                                <NotificationsIcon className="notification-icon"/>
                            </span>;
                }
            }
        },
        {
            Header: "Recipients",
            id: "recipients",
            resizable: false,
            Cell: row => {
                if (row.original.email) {
                    return <div title={row.original.email}>{row.original.email}</div>;
                } else if (row.original.phoneNumber) {
                    if (typeof row.original.carrier === 'string' || row.original.carrier instanceof String) {
                        return <div title={row.original.phoneNumber}>{row.original.phoneNumber}</div>;                       
                    } 
                    else {
                        return <div title={row.original.phoneNumber}><NumberFormatter number={row.original.phoneNumber} /></div>;
                    }
                } else if (row.original.name) {
                    return <div title={row.original.name}>{row.original.name}</div>;
                } else if (row.original.user) {
                    return <div title={row.original.user}>{row.original.user}</div>;
                }
            }
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            resizable: false,
            Cell: row => displayDeleteButton(row)
        }
    ];

    const displayDeleteButton = (row) => {
        if(row.original.listType === 'EMAIL_LIST' || row.row.email || row.original.email)
            return <Delete id={`alert-subscription-email-delete-button-${row.index}`} onClick={() => props.emailListDeleteHandler(row)} row={row} />;
        if(row.original.listType === 'USER_LIST' || row.row.user || row.original.user)
            return <Delete id={`alert-subscription-user-delete-button-${row.index}`} onClick={() => props.userListDeleteHandler(row)} row={row} />;
        if(row.original.listType === 'INFORMACAST_LIST' || row.row.name || row.original.name)
            return <Delete id={`alert-subscription-informa-delete-button-${row.index}`} onClick={() => props.informacastListDeleteHandler(row)} row={row} />;
        if(row.original.listType === 'SMS_LIST' || row.row.phoneNumber || row.original.phoneNumber)
            return <Delete id={`alert-subscription-phoneNumber-delete-button-${row.index}`} onClick={() => props.smsListDeleteHandler(row)} row={row} />;
    }

    const combinedList = [
        ...props.userList,
        ...props.informacastList,
        ...props.emailList,
        ...props.smsList
    ];
    
    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight alert-sub-recipient-table"
            showPaginationBottom={false}
            minRows={combinedList && combinedList.length > 0 ? 0 : 5}
            data={combinedList}
            pageSize={combinedList ? combinedList.length : 1}
            columns={buildTableColumns}
            style={{clear: 'both', margin: '70px 0px 30px', height: '400px'}}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'alert-subscription-recipients-row-' + rowInfo.index,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

export default (AlertsSubscriptionsRecipientsTable);