import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from "formik";
import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import { validateEdit, buildEditValidationSchema } from "../ModalConductor/UserModals/validation";
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { actions as AppActions, 
		 types as AppTypes, 
		 selectProfileErrorDisplay, 
		 selectProfileErrorMessage, 
		 selectMyAccountFormSubmitted } from '../../reducers/app';
import { makeCheckSsoSettings, actions as UsersActions } from '../../reducers/users';
import saga from '../../sagas/users';
import reducer from '../../reducers/users';
import { connect } from "react-redux";
import ModalErrorMessage from '../../components/ModalWrapper/ModalErrorMessage';
import { selectUser } from '../../reducers/auth';
import { InputLabel, Switch } from '@material-ui/core';

const styles = () => ({
	"content": {
		"paddingLeft": 100,
		"paddingRight": 100
	},
	"btn": {
		"marginTop": "15px"
	}
});

const MyAccountForm = props => {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		classes,
		profileErrorDisplay,
		profileErrorMessage,
		setProfileErrorClear,
		companyId,
		ssoCheck,
		checkSingleSignOn
	} = props;

	useEffect( () => {
		checkSingleSignOn(companyId)
  	}, []);

	return (
		<div className={classes.content}>
			<form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
				{profileErrorDisplay && <ModalErrorMessage modalErrorMessage={profileErrorMessage}/>}
				<TextField
					id="email"
					label="Email"
					
					name="email"
					type="text"
					onChange={handleChange}
					value={values.email}
					onBlur={handleBlur}
					fullWidth={true}
					error={((touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))))}/>
					<span id="email-helper-text" className="error-prompt">{((touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')))) ? errors.email : ''}</span>
				<br/>
				<TextField
					id="firstName"
					label="First Name"
					
					name="firstName"
					type="text"
					onChange={handleChange}
					value={values.firstName}
					onBlur={handleBlur}
					fullWidth={true}
					error={(touched.firstName && Boolean(errors.firstName)) || (errors.firstName && Boolean(errors.firstName.includes('max')))}/>
					<span id="firstName-helper-text" className="error-prompt">{(touched.firstName && Boolean(errors.firstName)) || (errors.firstName && Boolean(errors.firstName.includes('max'))) ? errors.firstName : ''}</span>
				<br/>
				<TextField
					id="lastName"
					label="Last Name"
					
					name="lastName"
					type="text"
					onChange={handleChange}
					value={values.lastName}
					onBlur={handleBlur}
					fullWidth={true}
					error={(touched.lastName && Boolean(errors.lastName)) || (errors.lastName && Boolean(errors.lastName.includes('max')))}/>
					<span id="lastName-helper-text" className="error-prompt">{(touched.lastName && Boolean(errors.lastName)) || (errors.lastName && Boolean(errors.lastName.includes('max'))) ? errors.lastName : ''}</span>
				<br/>
				{values.ssoType !== 'SAML_SSO' &&
					<div>
						<TextField
							id="password"
							label="New Password"
							
							name="password"
							type="password"
							onChange={handleChange}
							value={values.password}
							onBlur={handleBlur}
							fullWidth={true}
							error={(touched.password && Boolean(errors.password)) || (errors.password && Boolean(errors.password.includes('max')))}/>
							<span id="password-helper-text" className="error-prompt">{(touched.password && Boolean(errors.password)) || (errors.password && Boolean(errors.password.includes('max'))) ? errors.password : ''}</span>
						<br/>
						<small>(Leave empty to keep current password)</small>
						<TextField
							id="confirmPassword"
							label="Confirm New Password"
							
							name="confirmPassword"
							type="password"
							onChange={handleChange}
							value={values.confirmPassword}
							onBlur={handleBlur}
							fullWidth={true}
							error={(touched.confirmPassword && Boolean(errors.confirmPassword)) || (errors.confirmPassword && Boolean(errors.confirmPassword.includes('max')))}/>
							<span id="confirmPassword-helper-text" className="error-prompt">{(touched.confirmPassword && Boolean(errors.confirmPassword)) || (errors.confirmPassword && Boolean(errors.confirmPassword.includes('max'))) ? errors.confirmPassword : ''}</span>
					</div>
				}
				
				<Grid container direction="column" justify="center" alignItems="center">
					<Button id="my-account-submit-button"
						type="submit"
						className={classes.btn}
						color="primary"
						onClick={() => {
							setProfileErrorClear();
						}}
						variant="contained">Save</Button>
				</Grid>
			</form>
		</div>
	);
};

function MyAccount(props) {
	const user = props.user.toJS();
	const { checkSingleSignOn, ssoCheck } = props;

	useEffect(() => {
        props.setPageTitle('My Account');
		props.setPageTitleId('my-account-header');
		return () => {
			props.setMyAccountFormSubmitted(false);
			props.setProfileErrorClear();
		}
    }, []);

	return (
		<div>
			<Formik
				initialValues={{
					id: user.id,
					email: user.email,
					role: user.role,
					firstName: user.firstName,
					lastName: user.lastName,
					password: '',
					confirmPassword: '',
					logSSO: user.ssoType === 'SAML_SSO' ?  true : false,
					ssoType: user.ssoType,
					companyId: user.company.id

				}}
				validate={validateEdit(buildEditValidationSchema)}
				onSubmit = {
					(values) => {					
						values.ssoType = values.logSSO ? 'SAML_SSO' : 'DEFAULT'
						if ((user.email !== values.email) || values.password.length > 0) {
							props.setMyAccountFormSubmitted(true);
						}
						props.submitProfileRequest(values);
					}
				}
				render={formikProps => <MyAccountForm classes={props.classes} 
													  {...formikProps}
													  profileErrorDisplay={props.profileErrorDisplay}
													  profileErrorMessage={props.profileErrorMessage} 
													  setProfileErrorClear={props.setProfileErrorClear}
													  ssoCheck={ssoCheck}
													  checkSingleSignOn={checkSingleSignOn}
													  companyId={user.company.id}/>}
			/>
		</div>
	);
}

const mapStateToProps = () => createStructuredSelector({
	user: selectUser(),
	profileErrorDisplay: selectProfileErrorDisplay(),
	profileErrorMessage: selectProfileErrorMessage(),
	myAccountFormSubmitted: selectMyAccountFormSubmitted(),
	ssoCheck: makeCheckSsoSettings()
});

const mapDispatchToProps = dispatch => {
	return {
		submitProfileRequest: (user, currentProfile) => dispatch(AppActions.submitProfileRequest(user, currentProfile)),
		setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
		setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
		setMyAccountFormSubmitted: data => dispatch(AppActions.setMyAccountFormSubmitted(data)),
		setProfileErrorClear: () => dispatch(AppActions.setProfileErrorClear()),
		checkSingleSignOn: (companyId) => dispatch(UsersActions.checkSingleSignOn(companyId))
	}
};

const withReducer = injectReducer({ key: 'users', reducer });
const withSaga = injectSaga({ key: 'users', saga });

export default compose(
	withReducer,
    withSaga,
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
) (MyAccount);
