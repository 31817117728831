import React, { useEffect, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";

import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions, selectAddressIsAlternate, selectAddressIsAmbiguous, makeSelectMultipleAddressList } from '../../../reducers/locations';
import { selectUserCompany } from '../../../reducers/auth';

import SimpleTreeTypeForm from './forms/SimpleTreeTypeForm';
import DefaultTreeTypeForm from './forms/DefaultTreeTypeForm';
import { LocationValidation } from './Validations';
import { selectLocationAlternateIdEnabled } from '../../../reducers/app';
import HomepageAPI from '../../../apis/homepageApi';
import LicensingServiceApi from '../../../apis/licensingServiceApi';

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    location: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    },
    autoCompleteOptions: {
        width: '355px'
    },
    paper: {
        position: 'absolute',
        zIndex: 9999,
        marginTop: theme.spacing.unit,
    }
});

const AddLocationForm = props => {
    const handleOnChange = (values) => {
        if (values.value) {
            props.setFieldValue('phoneNumber', values.value);
        } else {
            props.setFieldValue('phoneNumber', '');
        }
        props.setFieldTouched('phoneNumber', true);
    }

    const handleCallbackNumberOnChange = (values) => {
        if (values.value) {
            props.setFieldValue('callbackNumber', values.value);
        } else {
            props.setFieldValue('callbackNumber', '');
        }
        props.setFieldTouched('callbackNumber', true);
    }

    if (props.modalData.simple)
        return <SimpleTreeTypeForm {...props} requiredInfo={props.requiredInfo} locationLicenseDeficit={props.locationLicenseDeficit}  handleOnChange={handleOnChange} handleCallbackNumberOnChange={handleCallbackNumberOnChange}/>
    else
        return <DefaultTreeTypeForm {...props} requiredInfo={props.requiredInfo} locationLicenseDeficit={props.locationLicenseDeficit} handleOnChange={handleOnChange} handleCallbackNumberOnChange={handleCallbackNumberOnChange}/>
}

const AddLocationModal = props => {
    const companyId = props.company.toJS().id;
    const usageBasedLicensing = props.company.toJS().usageBasedLicensing;
    const requiredInfo = props.company.toJS().requireLocationInfo;
    const locationTreeType = props.company.toJS().locationTreeType;
    const [overrideOrganizationNameToggle, setOverrideOrganizationNameToggle] = useState(false);
    const [phoneNumberToggle, setPhoneNumberToggle] = useState('PHONE_NUMBER');
    const [locationLicenseDeficit, setLocationLicenseDeficit] = useState(false);
    const [geoCordsAreRequired, setGeoCordsAreRequired] = useState(true);
    const [addressIsRequired, setAddressIsRequired] = useState(true);
    const [addressAndGeoCoordsToggle, setAddressAndGeoCoordsToggle] = useState('address');

    useEffect(() => {
        return () => {
            props.setAddressIsAmbiguous(false);
            props.setAddressIsAlternate(false);
        }
    }, []);

    useEffect(() => {
        if (!usageBasedLicensing) {
            Promise.all([
                HomepageAPI.getCompanySummary(companyId).then(
                    (response) =>  response.data
                ).catch(error => {
                    console.log(error);
                }), 
                LicensingServiceApi.getLicenseCount(companyId).then(
                    (response) => response.data
                ).catch(error => {
                    console.log(error);
                })
            ]).then(stats => {
                const companyStats = stats[0];
                const licensingStats = stats[1];
                const numberOfLicensedLocations = !companyStats ? 0 : companyStats.numOfCERLocations + companyStats.numOfCorporateLocations;
                const numberOfLocationLicenses = !licensingStats ? 0 : licensingStats.Location;
                setLocationLicenseDeficit(numberOfLicensedLocations >= numberOfLocationLicenses);
            });
        }
    }, []);
    
    return(
        <Formik
            initialValues={{ 
                houseNumber: '',
                houseNumberExtension: '',
                prefixDirection: '',
                streetType: '',
                postDirection: '',
                street: '',
                city: '',
                stateProvince: '',
                zip: '',
                country: 'US',
                supplemental: '',
                name: '',
                phoneNumber: '',
                linkName: '',
                linkUrl: '',
                info: '',
                address: '',
                latitude: '',
                longitude: '',
                multipleAddressSelect: '',
                overrideOrganizationName: '',
                alternateId: '',
                callbackNumber: ''
            }}
            validate= { (values) => {
                // Only do this if you need to access the value from the form to make something required
                const validationSchema =  LocationValidation(props.isAlternate, requiredInfo, locationTreeType, props.locationAlternateIdEnabled, phoneNumberToggle, values, props.isAmbiguous, geoCordsAreRequired, addressIsRequired);
                try {
                    validateYupSchema(values, validationSchema, true, {});
                } catch (err) {
                    return yupToFormErrors(err);
                }
                return {}
            }}
            onSubmit = {
                (values) => {
                    if(props.modalData.simple) {
                        values.simple = true;
                        values.organizationUnitId = props.modalData.companyId;
                        if (typeof values.multipleAddressSelect === 'string' && values.multipleAddressSelect.length >= 1) {
                            values.multipleAddressSelect = JSON.parse(values.multipleAddressSelect);
                        }
                    } else {
                        values.organizationUnitId = props.modalData.id;
                        values.parentId = props.modalData.parentId;
                    }

                    if(!overrideOrganizationNameToggle) {
                        values.overrideOrganizationName=undefined
                    }

                    if(phoneNumberToggle === 'PHONE_NUMBER') {
                        values.alternateId = undefined;
                        values.callbackNumber = undefined;
                    }
                    else {
                        values.phoneNumber = undefined;
                    }
                   props.submitAddLocationForm(values);
                }
            }
            render={renderProps => <AddLocationForm 
                {...props} 
                {...renderProps}
                // addressesValue={addressesValue}
                // setAddressesValue={setAddressesValue}
                addressAndGeoCoordsToggle={addressAndGeoCoordsToggle}
                setAddressAndGeoCoordsToggle={setAddressAndGeoCoordsToggle}
                addressIsRequired={addressIsRequired}
                setAddressIsRequired={setAddressIsRequired}
                geoCordsAreRequired={geoCordsAreRequired}
                setGeoCordsAreRequired={setGeoCordsAreRequired}
                requiredInfo={requiredInfo} 
                locationLicenseDeficit = {locationLicenseDeficit}
                overrideOrganizationNameToggle={overrideOrganizationNameToggle}
                setOverrideOrganizationNameToggle={setOverrideOrganizationNameToggle}	
                phoneNumberToggle={phoneNumberToggle}	
                setPhoneNumberToggle={setPhoneNumberToggle}	
                modalErrorOptions={props.modalErrorOptions}		
                multipleAddressList={props.multipleAddresses}
                 />}
        />
    );
}

const mapStateToProps = ()  => createStructuredSelector({
    company: selectUserCompany(),
    multipleAddresses: makeSelectMultipleAddressList(),
    isAlternate: selectAddressIsAlternate(),
    isAmbiguous: selectAddressIsAmbiguous(),
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddLocationForm: (location) => dispatch(actions.addLocationRequest(location)),
        setAddressIsAmbiguous: (flag) => dispatch(actions.addressIsAmbiguous(flag)),
        setAddressIsAlternate: (flag) => dispatch(actions.addressIsAlternate(flag))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddLocationModal);