import React, { useState, useEffect } from "react";
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import Computer from '@material-ui/icons/Computer';
import Language from '@material-ui/icons/Language';
import Smartphone from '@material-ui/icons/Smartphone';
import Delete from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
import Phone from '@material-ui/icons/Phone';
import Add from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types, makeSelectDeviceUsersDevicesPageCount } from '../../reducers/deviceUsers';
import moment from 'moment';
import DeviceUsersDevicesDetailBox from './deviceUsersDevicesDetailBox';
import LocationDetailBox from '../DeviceUsersTable/LocationDetailBox';
import { actions as DeviceUsersActions} from '../../reducers/deviceUsers';

const initialSortColumns = [
    {
        id: 'last-access-time',
        desc:true
    }
]

function DeviceUsersDevicesListTable(props) {
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    function discoveryMethodEnumType(row) {
        if (row.original.type === 'HELD_PLUS') {
			return discoveryTypeEnumDisplay(row.original.discoveryType);
        } else {
            return discoveryTypeEnumDisplay(props.parentRowUserData.discoveryType);
        }
    }
    function discoveryTypeEnumDisplay(value) {
    	switch (value) {
    		case 'STICKY_IP':
            	return 'Sticky IP Mapping';
            case 'GATEWAY_MAC_ADDRESS':
                    return 'Gateway MAC Address';
            default:
            	return value;
    	}
    }

    const columns = [
        {
            Header: "Operating System",
            id: "operating-system",
            Cell: row => <div title={row.original.operatingSystem}>{row.original.operatingSystem}</div>
        },
        {
            Header: "Device Name",
            id: "name",
            Cell: row => <div title={row.original.name}>{row.original.type === 'HELD_PLUS' ? row.original.heldIdentifier : row.original.name}</div>
        }, 
        {
            Header: "Device Model",
            id: "device-model",
            Cell: row => <div title={row.original.model}>{row.original.model}</div>
        },
        {
            Header: <div>Current Location</div>,
            id: "current-location",
            width: 200,
            Cell: row => {
                    return <LocationDetailBox
                            row={row}
                            location={
                                row.original.type === 'HELD_PLUS' ? 
                                row.original.erl :
                                props.parentRowUserData.currentEmergencyResponseLocation
                            }
                            discoveryType={
                                row.original.type === 'HELD_PLUS' ? 
                                null :
                                props.parentRowUserData.discoveryType
                            }
                            discoveryValue={
                                row.original.type === 'HELD_PLUS' ? 
                                row.original.discoveryValue :
                                props.parentRowUserData.discoveryValue
                            }
                            locationSetMethod={
                                row.original.type === 'HELD_PLUS' ? 
                                row.original.locationSetMethod :
                                props.parentRowUserData.locationSetMethod
                            }
                            locationSetTime={
                                row.original.type === 'HELD_PLUS' ? 
                                row.original.locationSetTime :
                                props.parentRowUserData.locationSetTime
                            }
                        />;
            }
        },
        {
            Header: "Last Access Time (Device)",
            id: 'last-access-time',
            sortable: true,
            Cell: row => <div title={formatLastAccess(row.original.lastAccessTime)}> {
                formatLastAccess(row.original.lastAccessTime)
            } </div>
        },
        {
            Header: "Type",
            id: "type",
            width: 70,
            Cell: row => (
                <div style = {
                    {
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '5px'
                    }
                }>{row.original.type === 'MOBILE' ? 
                    <div title="Mobile">
                        <Smartphone style={{ color: '#1b536a' }} />
                    </div>
                : row.original.type === 'WEB' ? 
                    <div title="Web">
                        <Language style={{ color: '#1b536a' }} /> 
                    </div>
                : row.original.type === 'HELD_PLUS' ? 
                    <div title="HELD+">
                        <Phone style={{ color: '#1b536a' }}/>
                        <Add style={{ color: '#1b536a', fontSize: 16, margin: '0 0 8px -12px' }}/>
                    </div>
                :
                    <div title="Desktop">
                        <Computer style={{ color: '#1b536a' }} />
                    </div>
                }
                </div>
            )
        },
        {
            Header: "Discovery Method",
            id: "discovery-method",
            Cell: row => <div title={discoveryMethodEnumType(row)}>{discoveryMethodEnumType(row)}</div>
        },
        {
            Header: '',
            id: "edit-menu",
            Cell: row => 
            (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <DeviceUsersDevicesDetailBox parentRowUserData={props.parentRowUserData} row={row} />
                    {row.original.heldRequestMessage &&
                        <Button 
                        aria-label="HELD+ Request Message"
                        id={`held-message-button-${row.rowId}`}
                        title="HELD+ Request Message" 
                        onClick={() => {
                            props.setCurrentModal(types.SHOW_HELD_REQUEST_MESSAGE, {
                                    heldRequestMessage: row.original.heldRequestMessage
                            })
                        }}>
                            <CodeIcon />
                        </Button>
                    }
                    <Button 
                    aria-label="Delete" 
                    id={`delete-button-${row.rowId}`} 
                    title="Delete"
                    onClick={() => {
                        props.setCurrentModal(types.DELETE_DEVICE_USER_DEVICE, {
                                device: row.original,
                                deviceUserId: row.deviceUserId
                        })
                    }}>
                        <Delete />
                    </Button>
                </div>
            )
            
        }
    ];

    function formatLastAccess(callTime) {
        return callTime ? moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
    }

    const {
        devices, loading, getDeviceUsersDevicesRequest
    } = props;

    let devicesArray = [];
    
    if(devices) {
        devicesArray = devices.toArray()
    }

    useEffect(() => {
        if (devicesArray && devicesArray.length === 0 && page > 0 && props.deviceUsersDevicesPageCount < page+1) {
            setPage(page-1)
        }
    }, [devicesArray && devicesArray.length === 0 && page > 0 && props.deviceUsersDevicesPageCount < page+1]);


    useEffect( () => {
        getDeviceUsersDevicesRequest(props.deviceUserId, page+1, pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: page+1, 
            pageSize: pageSize, 
            sortBy: currentSortColumn.id, 
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, pageSize, page]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);
    
    return (
        <ReactTable
            resizable={false}
            sortable={false} 
            className="-striped -highlight subtenantsListTable"
            showPaginationBottom={true}
            data={devicesArray}
            pages={props.deviceUsersDevicesPageCount <= 0 ? 1 : props.deviceUsersDevicesPageCount}
            page={page}
            minRows={devicesArray && devicesArray.length > 0 ? 0 : 5}
            defaultPageSize={25}
            columns={columns}
            LoadingComponent={ReactTableLoadingComponent}
            manual
            loading={loading}
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
            }}
            onPageSizeChange={(pageSize) => {
                setPageSize(pageSize)
                setPage(0);
            }}
            onPageChange={(pageIndex) => {
                setPage(pageIndex);
            }}
            getTrProps={
                (state, rowInfo, column) => {
                    if (rowInfo) {
                        rowInfo.rowId = 'device-users-devices-row-' + rowInfo.original.deviceId;

                        if(rowInfo && rowInfo.original.type === 'HELD_PLUS' && !rowInfo.original.erl) {
                            return {
                                id: rowInfo.rowId,
                                style: {
                                    background: 'rgba(255, 70, 71, 0.45)',
                                    opacity: '.8',
                                    border: '1px solid red'
                                }
                            };
                        }
                        else {
                            return {
                                id: rowInfo.rowId,
                            };
                        }
                    } else {
                        return {};
                    }
                }
            }
        />
    );
}

DeviceUsersDevicesListTable.propTypes = {
    devices: ImmutablePropTypes.list,
    loading: PropTypes.bool,
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = () => createStructuredSelector({
    deviceUsersDevicesPageCount: makeSelectDeviceUsersDevicesPageCount()
});

const mapDispatchToProps = dispatch => {
  return {
      getDeviceUsersDevicesRequest: (deviceUserId, page, pageSize, sortBy, direction) => dispatch(DeviceUsersActions.getDeviceUsersDevicesRequest(deviceUserId, page, pageSize, sortBy, direction)),
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
      setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceUsersDevicesListTable);
    