import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Formik } from "formik";
import OrganizationManagementAPI from '../../../apis/organizationManagementApi';
import { createStructuredSelector } from "reselect";
import { selectUserCompany, changeSubtenant } from '../../../reducers/auth';
import { connect } from "react-redux";
import * as Yup from "yup";

const validationSchema = Yup.object({
    informaCastAccessToken: Yup.string("Enter a name")
            .trim()
            .max(256, "InformaCast Access Token has a max limit of 256 characters."),
})

const Form = props => {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
        handleSubmit,
        tokenSubmitStatus,
        dataLoading,
        isNotUnique,
        isInvalid,
        disableInput
	} = props;

	return (
		<div>
            {dataLoading && <LinearProgress/>}
			<form style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
				<div>
                    <TextField
                        style={{ width: 400, marginRight: 15 }}
                        id="informaCastAccessToken"
                        label="*InformaCast Access Token"
                        name="informaCastAccessToken"
                        onChange={handleChange}
                        value={values.informaCastAccessToken}
                        onBlur={handleBlur}
                        fullWidth={true}
                        disabled={!disableInput}
                        error={isNotUnique || tokenSubmitStatus === 'failure' || isInvalid || (touched.informaCastAccessToken && Boolean(errors.informaCastAccessToken)) || (errors.informaCastAccessToken && Boolean(errors.informaCastAccessToken.includes('max')))}/>
                        <div id="informaCastAccessToken-helper-text" className="error-prompt">{(touched.informaCastAccessToken && Boolean(errors.informaCastAccessToken)) || (errors.informaCastAccessToken && Boolean(errors.informaCastAccessToken.includes('max'))) ? errors.informaCastAccessToken : ''}</div>
                        {isNotUnique && 
                            <div id="informaCastAccessToken-duplicate-helper-text" className="error-prompt">The InformaCast access token provided already exists.</div>
                        }
                </div>
                <Button 
                    type="submit"
                    color="primary"
                    disabled={!disableInput ? true : values.informaCastAccessToken ? false : true}
                    variant="contained">Test &amp; Save</Button>
			</form>
            {(tokenSubmitStatus === 'success' && isInvalid == false) && 
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 580 }}>
                    <CheckCircleOutlineIcon style={{ color: '#1aa68f', marginRight: 10, fontSize: 30 }}/>
                    <div style={{ color: 'rgb(97, 95, 87)' }}>SUCCESS! We were able to successfully connect to Informacast! Your InformaCast Access Token has been saved.</div>
                </div>
            }
            {(tokenSubmitStatus === 'failure'  || isInvalid === true) && 
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 680 }}>
                    <ErrorOutlineIcon style={{ color: 'rgb(230, 0, 0)', marginRight: 10, fontSize: 30 }}/>
                    <div style={{ color: 'rgb(230, 0, 0)' }}>ERROR! We were not able to connect to InformaCast with the supplied access token; therefore, it was not saved. Please re-enter your InformaCast Access Token and try again.</div>
                </div>
            }
		</div>
	);
};

function InformaCastForm(props) {
    const [tokenSubmitStatus, setTokenSubmitStatus] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const [isNotUnique, setIsNotUnique] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const company = props.company.toJS();

    return (
        <div>
            <Formik
				initialValues={{
					informaCastAccessToken: company.informacastToken ? company.informacastToken : ''
                }}
                validationSchema={validationSchema}
				onSubmit = {
					(values) => {
                        setDataLoading(true);
                        OrganizationManagementAPI.addInformaCastToken(values.informaCastAccessToken, company.id).then(response => {
                            setTokenSubmitStatus('success');
                            setIsInvalid(false);
                            setIsNotUnique(false);
                            setDataLoading(false);

                            company.informacastToken = values.informaCastAccessToken;
                            props.changeSubtenant(company);
                        }).catch(error => {
                            if(error.response.data.type == 'http://cirrus.redskytech.com/errors/informacast/invalid/data'){
                                setIsInvalid(true);
                                setIsNotUnique(false);
                            }
                            else if (error.response.data.type == "http://cirrus.redskytech.com/errors/company/informacast/duplicate"){
                                setIsNotUnique(true);
                                setIsInvalid(false);
                            } else {
                                setTokenSubmitStatus('failure');
                            }
                            setDataLoading(false);
                        });
					}
				}
				render={formikProps => <Form disableInput={props.disableInput} isNotUnique={isNotUnique} isInvalid={isInvalid} dataLoading={dataLoading} tokenSubmitStatus={tokenSubmitStatus} {...formikProps} />}
			/>
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
	company: selectUserCompany()
});

const mapDispatchToProps = dispatch => {
    return {
        changeSubtenant: (company) => dispatch(changeSubtenant(company))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InformaCastForm);