import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../reducers/auth';
import { safeLogout } from '../../utils/authLocalStorageSync';

const styles = {
	root: {
		flexGrow: 1,
	},
	avatar: {
		margin: 5,
	},
	grow: {
		flexGrow: 1,
	},
		menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
};

function DefaultAppBar(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [displayName, setDisplayName] = useState('');
	const [displayAvatar, setDisplayAvatar] = useState('');
	const { classes } = props;
	const open = Boolean(anchorEl);

	useEffect( () => {
		const user = props.user.toJS();
		if(user.firstName && user.firstName.length > 0)
		{
			if(user.lastName.length > 0 )
			{
				setDisplayName(user.firstName + ' ' + user.lastName);
				setDisplayAvatar(user.firstName.substr(0,1) + user.lastName.substr(0,1));
			}
			else {
				setDisplayName(user.firstName);
				setDisplayAvatar(user.firstName.substr(0,1));
			}
		}
		else if(user.lastName && user.lastName > 0) {
			setDisplayName(user.lastName);
			setDisplayAvatar(user.lastName.substr(0,1));
		}
		else {
			setDisplayName(user.email);
			setDisplayAvatar(user.email.substr(0,1));
		}
	}, [props.user]);

	function handleMenu(event) {
		setAnchorEl(event.currentTarget);
	};

	function handleClose() {
		setAnchorEl(null);
	};

	function logout() {
		safeLogout();
	}

	return (
		<div className="app-bar">
			<AppBar position="static" color="primary">
				<Toolbar style={{ padding: 0, marginRight: '-10px' }}>
					<Typography  color="inherit" className={classes.grow}></Typography>
					
					<Typography color="inherit">
						{displayName}
					</Typography>

					<IconButton
						id="admin-menu-button"
						aria-owns={open ? 'admin-menu' : undefined}
						aria-haspopup="true"
						onClick={handleMenu}
						color="inherit"
					>
						<Avatar className={classes.avatar}>{displayAvatar.toUpperCase()}</Avatar>
					</IconButton>

					<Menu
						id="admin-menu"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={open}
						onClose={handleClose}>
						<MenuItem style={{ padding: 0, height: 'auto' }} onClick={handleClose}>
							<Link style={{ padding: '11px 16px', display: 'block', width: '100%', textDecoration: 'none' }} to="/myAccount">My Account</Link>
						</MenuItem>
						<MenuItem style={{ padding: 0, height: 'auto' }} onClick={handleClose}>
							<Link style={{ padding: '11px 16px', display: 'block', width: '100%', textDecoration: 'none' }} to="/about">About</Link>
						</MenuItem>
						<MenuItem style={{ padding: 0, height: 'auto' }} onClick={handleClose}>
							<a target='_blank' style={{ padding: '11px 16px', display: 'block', width: '100%', textDecoration: 'none' }}  href="https://resources.e911cloud.com/manuals/E911%20Cloud%20Services%20Release%20Notes%20for%20Service%20Providers.pdf">Release Notes</a>
						</MenuItem>
						<MenuItem onClick={logout}>Logout</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
		</div>
	);
}

DefaultAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => createStructuredSelector({
	user: selectUser()
});

export default connect(mapStateToProps)(withStyles(styles)(DefaultAppBar));