import React from 'react';
import LocationProvider from './LocationProvider';

const AppProviders = ({ children }) => {
  return (
        /* 
            Wrap LocationProvider with any new providers you need in here        
        */
        <LocationProvider>
          {children}
        </LocationProvider>
  );
};

export default AppProviders;
