/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const CALL_HISTORY = 'CALL_HISTORY/';
export const COMPANY_SUMMARY = 'COMPANY_SUMMARY/'; 
export const EVENTS = 'EVENTS/'; 
export const IMPORT_STATUS = 'IMPORT_STATUS/'; 
export const LICENSE_INFO = 'LICENSE_INFO/';
export const RSRC = 'RSRC/';
export const SYSTEM_SUMMARY = 'SYSTEM_SUMMARY/'; 

export const types = {
    GET_CALL_HISTORY_REQUEST: CALL_HISTORY + 'GET_CALL_HISTORY_REQUEST',
    GET_CALL_HISTORY_ERROR: CALL_HISTORY + 'GET_CALL_HISTORY_ERROR',
    GET_CALL_HISTORY_SUCCESS: CALL_HISTORY + 'GET_CALL_HISTORY_SUCCESS',
    CALL_HISTORY_DETAILS: CALL_HISTORY + 'CALL_HISTORY_DETAILS',
    CALL_HISTORY_RECORDING: CALL_HISTORY + 'CALL_HISTORY_RECORDING',
    GET_COMPANY_SUMMARY_REQUEST: COMPANY_SUMMARY + 'GET_COMPANY_SUMMARY_REQUEST',
    GET_COMPANY_SUMMARY_ERROR: COMPANY_SUMMARY + 'GET_COMPANY_SUMMARY_ERROR',
    GET_COMPANY_SUMMARY_SUCCESS: COMPANY_SUMMARY + 'GET_COMPANY_SUMMARY_SUCCESS',
    GET_EVENTS_REQUEST: EVENTS + 'GET_EVENTS_REQUEST',
    GET_EVENTS_ERROR: EVENTS + 'GET_EVENTS_ERROR',
    GET_EVENTS_SUCCESS: EVENTS + 'GET_EVENTS_SUCCESS',
    GET_IMPORT_STATUS_REQUEST: IMPORT_STATUS + 'GET_IMPORT_STATUS_REQUEST',
    GET_IMPORT_STATUS_ERROR: IMPORT_STATUS + 'GET_IMPORT_STATUS_ERROR',
    GET_IMPORT_STATUS_SUCCESS: IMPORT_STATUS + 'GET_IMPORT_STATUS_SUCCESS',
    GET_LICENSE_INFO_REQUEST: LICENSE_INFO + 'GET_LICENSE_INFO_REQUEST',
    GET_LICENSE_INFO_ERROR: LICENSE_INFO + 'GET_LICENSE_INFO_ERROR',
    GET_LICENSE_INFO_SUCCESS: LICENSE_INFO + 'GET_LICENSE_INFO_SUCCESS',
    GET_RSRC_REQUEST: RSRC + 'GET_RSRC_REQUEST',
    GET_RSRC_ERROR: RSRC + 'GET_RSRC_ERROR',
    GET_RSRC_SUCCESS: RSRC + 'GET_RSRC_SUCCESS',
    GET_SYSTEM_SUMMARY_REQUEST: SYSTEM_SUMMARY + 'GET_SYSTEM_SUMMARY_REQUEST',
    GET_SYSTEM_SUMMARY_ERROR: SYSTEM_SUMMARY + 'GET_SYSTEM_SUMMARY_ERROR',
    GET_SYSTEM_SUMMARY_SUCCESS: SYSTEM_SUMMARY + 'GET_SYSTEM_SUMMARY_SUCCESS'
};

export const initialState = fromJS({
    callHistoryList: List(),
    callHistoryLoading: false,
    callHistoryListPageCount: -1,
    companySummaryObject: fromJS(),
    companySummaryLoading: false,
    eventsList: List(),
    eventsLoading: false,
    eventsListPageCount: -1,
    importStatusList: List(),
    importStatusLoading: false,
    licenseInfoList: List(),
    licenseInfoLoading: false,
    rsrcList: List(),
    rsrcLoading: false,
    systemSummaryObject: fromJS(),
    systemSummaryLoading: false
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_CALL_HISTORY_REQUEST:
            return state.set('callHistoryLoading', true);
        case types.GET_CALL_HISTORY_ERROR:
            return state.set('callHistoryLoading', false);
        case types.GET_CALL_HISTORY_SUCCESS:
            return state.set('callHistoryLoading', false)
                        .set('callHistoryList', List(action.data))
                        .set('callHistoryListPageCount', action.pageCount);
        case types.GET_COMPANY_SUMMARY_REQUEST:
            return state.set('companySummaryLoading', true);
        case types.GET_COMPANY_SUMMARY_ERROR:
            return state.set('companySummaryLoading', false);
        case types.GET_COMPANY_SUMMARY_SUCCESS:
            return state.set('companySummaryLoading', false)
                        .set('companySummaryObject', fromJS(action.data));
        case types.GET_EVENTS_REQUEST:
            return state.set('eventsLoading', true);
        case types.GET_EVENTS_ERROR:
            return state.set('eventsLoading', false);
        case types.GET_EVENTS_SUCCESS:
            return state.set('eventsLoading', false)
                        .set('eventsList', List(action.data))
                        .set('eventsListPageCount', action.pageCount);
        case types.GET_IMPORT_STATUS_REQUEST:
            return state.set('importStatusLoading', true);
        case types.GET_IMPORT_STATUS_ERROR:
            return state.set('importStatusLoading', false);
        case types.GET_IMPORT_STATUS_SUCCESS:
            return state.set('importStatusLoading', false)
                        .set('importStatusList', List(action.data));
        case types.GET_LICENSE_INFO_REQUEST:
            return state.set('licenseInfoLoading', true);
        case types.GET_LICENSE_INFO_ERROR:
            return state.set('licenseInfoLoading', false);
        case types.GET_LICENSE_INFO_SUCCESS:
            return state.set('licenseInfoLoading', false)
                        .set('licenseInfoList', List(action.data));
        case types.GET_RSRC_REQUEST:
            return state.set('rsrcLoading', true);
        case types.GET_RSRC_ERROR:
            return state.set('rsrcLoading', false);
        case types.GET_RSRC_SUCCESS:
            return state.set('rsrcLoading', false)
                        .set('rsrcList', List(action.data));
        case types.GET_SYSTEM_SUMMARY_REQUEST:
            return state.set('systemSummaryLoading', true);
        case types.GET_SYSTEM_SUMMARY_ERROR:
            return state.set('systemSummaryLoading', false);
        case types.GET_SYSTEM_SUMMARY_SUCCESS:
            return state.set('systemSummaryLoading', false)
                        .set('systemSummaryObject', fromJS(action.data));
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    getCallHistoryRequest: (page, pageSize) => ({
        type: types.GET_CALL_HISTORY_REQUEST,
        page,
        pageSize
    }),
    getCallHistoryError: () => ({
        type: types.GET_CALL_HISTORY_ERROR,
    }),
    getCallHistorySuccess: (callHistoryList, pageCount) => ({
        type: types.GET_CALL_HISTORY_SUCCESS,
        data: callHistoryList,
        pageCount
    }),
    getCompanySummaryRequest: () => ({
        type: types.GET_COMPANY_SUMMARY_REQUEST
    }),
    getCompanySummaryError: () => ({
        type: types.GET_COMPANY_SUMMARY_ERROR
    }),
    getCompanySummarySuccess: (companySummaryObject) => ({
        type: types.GET_COMPANY_SUMMARY_SUCCESS,
        data: companySummaryObject
    }),
    getEventsRequest: (page, pageSize, startDate, endDate) => ({
        type: types.GET_EVENTS_REQUEST,
        page,
        pageSize,
        startDate,
        endDate
    }),
    getEventsError: () => ({
        type: types.GET_EVENTS_ERROR,
    }),
    getEventsSuccess: (eventsList, pageCount) => ({
        type: types.GET_EVENTS_SUCCESS,
        data: eventsList,
        pageCount
    }),
    getImportStatusRequest: () => ({
        type: types.GET_IMPORT_STATUS_REQUEST
    }),
    getImportStatusError: () => ({
        type: types.GET_IMPORT_STATUS_ERROR
    }),
    getImportStatusSuccess: (importStatusList) => ({
        type: types.GET_IMPORT_STATUS_SUCCESS,
        data: importStatusList
    }),
    getLicenseInfoRequest: () => ({
        type: types.GET_LICENSE_INFO_REQUEST
    }),
    getLicenseInfoError: () => ({
        type: types.GET_LICENSE_INFO_ERROR
    }),
    getLicenseInfoSuccess: (licenseInfoList) => ({
        type: types.GET_LICENSE_INFO_SUCCESS,
        data: licenseInfoList
    }),
    getRsrcRequest: (page, pageSize) => ({
        type: types.GET_RSRC_REQUEST,
        page,
        pageSize
    }),
    getRsrcError: () => ({
        type: types.GET_RSRC_ERROR
    }),
    getRsrcSuccess: (rsrcList) => ({
        type: types.GET_RSRC_SUCCESS,
        data: rsrcList
    }),
    getSystemSummaryRequest: () => ({
        type: types.GET_SYSTEM_SUMMARY_REQUEST
    }),
    getSystemSummaryError: () => ({
        type: types.GET_SYSTEM_SUMMARY_ERROR
    }),
    getSystemSummarySuccess: (systemSummaryObject) => ({
        type: types.GET_SYSTEM_SUMMARY_SUCCESS,
        data: systemSummaryObject
    })
};

// Selectors
const selectHomepage = state => state.get('homepage', initialState);

export const makeSelectCallHistoryList = () =>
    createSelector(selectHomepage, callHistoryState => callHistoryState.get('callHistoryList'));

export const makeSelectCallHistoryListLoading = () =>
    createSelector(selectHomepage, callHistoryState => callHistoryState.get('callHistoryLoading'));

export const makeSelectCallHistoryPageCount = () =>
    createSelector(selectHomepage, callHistoryState => callHistoryState.get('callHistoryListPageCount'));
    
export const makeSelectCompanySummaryObject = () =>
    createSelector(selectHomepage, companySummaryState => companySummaryState.get('companySummaryObject'));

export const makeSelectCompanySummaryObjectLoading = () =>
    createSelector(selectHomepage, companySummaryState => companySummaryState.get('companySummaryLoading'));

export const makeSelectEventsList = () =>
    createSelector(selectHomepage, eventsState => eventsState.get('eventsList'));

export const makeSelectEventsListLoading = () =>
    createSelector(selectHomepage, eventsState => eventsState.get('eventsLoading'));

export const makeSelectEventsPageCount = () =>
    createSelector(selectHomepage, eventsState => eventsState.get('eventsListPageCount'));
    
export const makeSelectImportStatusList = () =>
    createSelector(selectHomepage, importStatusState => importStatusState.get('importStatusList'));

export const makeSelectImportStatusListLoading = () =>
    createSelector(selectHomepage, importStatusState => importStatusState.get('importStatusLoading'));
    
export const makeSelectLicenseInfoList = () =>
    createSelector(selectHomepage, licenseInfoState => licenseInfoState.get('licenseInfoList'));

export const makeSelectLicenseInfoListLoading = () =>
    createSelector(selectHomepage, licenseInfoState => licenseInfoState.get('licenseInfoLoading'));
    
export const makeSelectRsrcList = () =>
    createSelector(selectHomepage, rsrcState => rsrcState.get('rsrcList'));

export const makeSelectRsrcListLoading = () =>
    createSelector(selectHomepage, rsrcState => rsrcState.get('rsrcLoading'));
    
export const makeSelectSystemSummaryObject = () =>
    createSelector(selectHomepage, systemSummaryState => systemSummaryState.get('systemSummaryObject'));

export const makeSelectSystemSummaryObjectLoading = () =>
    createSelector(selectHomepage, systemSummaryState => systemSummaryState.get('systemSummaryLoading'));