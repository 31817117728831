import React, {useEffect, useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Formik} from 'formik';
import * as Yup from "yup";
import {compose} from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import {actions} from '../../../reducers/apiAccessKey';
import {connect} from 'react-redux';
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {createStructuredSelector} from "reselect";
import {currentCompanyOrgType, orgTypes, selectUser, userCompanyId} from "../../../reducers/auth";
import RoleSelect from "../../../components/RoleSelect";
import UsersAPI from "../../../apis/usersApi";
import {IconButton, Input, InputAdornment, MenuItem, Select} from "@material-ui/core";
import {FileCopyOutlined, VisibilityOff} from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";

const validationSchema = Yup.object({
    description: Yup.string("Enter a description")
        .trim()
        .max(255, "Description has a max limit of 255 characters.")
        .required("Description is a required field."),
    roleName: Yup.string()
        .required('Please select a role.')
})

const styles = theme => ({
    btn: {
        marginTop: '20px',
    },
    apiKeyWarning: {
        color: '#000068',
        fontSize: '90%',
        textAlign: 'center',
        marginTop: '25px'
    },
});


const AddApiKeyForm = props => {
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        modalLoading,
        roleList,
        companyId,
        classes
    } = props;

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <TextField
                id="description"
                label="Description"
                name="description"
                type="description"
                onChange={handleChange}
                value={values.description}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                required={true}
                error={(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max')))}/>
            <span id="description-helper-text" className="error-prompt">{(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max'))) ? errors.description : ''}</span>
            <br/><br/>
            <RoleSelect
                companyId={companyId}
                value={values.roleName}
                roleList={roleList}
                handleChange={handleChange}
                modalLoading={modalLoading}
                required={true}
                disabled={roleList.length === 0}/>
            <span id="role-helper-text" className="error-prompt">{touched.roleName ? errors.roleName : ""}</span>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit"
                        disabled={modalLoading}
                        className={classes.btn}
                        onClick={handleScroll}
                        color="primary"
                        variant="contained">Generate</Button>
            </Grid>
        </form>
    );
}

const AddApiKeySuccessForm = props => {
    const {
        errors,
        touched,
        handleChange,
        handleBlur,
        classes,
        modalData
    } = props;

    const [showApiKey, setShowApiKey] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [copyToClipboardFailed, setCopyToClipboardFailed] = useState(false);
    const [rowIdValues, setRowIdValues] = useState('');

    const toggleShowApiKey = () => {
        setShowApiKey(!showApiKey);
    };

    const handleClose = () => {
        setSnackbarOpen(false);
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setRowIdValues(text);
            setCopyToClipboardFailed(false);
            setSnackbarOpen(true);
        } catch (err) {
            setCopyToClipboardFailed(true);
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            <form autoComplete="off" noValidate="novalidate">
                <TextField
                    id="description"
                    label="Description"
                    name="description"
                    type="description"
                    value={modalData.description}
                    disabled={true}
                    fullWidth={true}/>
                <br/><br/>
                <TextField
                    id="role"
                    label="Role"
                    name="role"
                    type="role"
                    value={modalData.role}
                    disabled={true}
                    fullWidth={true}/>
                <br/><br/>
                <TextField
                    id="api-key"
                    label="API Key"
                    name="api-key"
                    type="api-key"
                    value={showApiKey ? modalData.apiKeyInitialDisplay : modalData.maskedApiKey + "*".repeat(20)}
                    fullWidth={true}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle API key visibility"
                                    onClick={toggleShowApiKey}
                                >
                                    {showApiKey ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                                <IconButton
                                    aria-label="Copy API key to clipboard"
                                    onClick={() => copyToClipboard(modalData.apiKeyInitialDisplay)}
                                >
                                    <FileCopyOutlined/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Snackbar
                    className={`snackbar-popup ${copyToClipboardFailed && 'error'}`}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={<div>
                        {copyToClipboardFailed ?
                            <span id="client-snackbar">
                                Failed to copy API key to the clipboard, please copy it manually.<br/>
                            </span>
                            :
                            <span id="client-snackbar">
                                <CheckCircleIcon/>&nbsp;&nbsp;
                                The API key has been copied to your clipboard!
                            </span>
                        }
                    </div>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </form>
            <div className={classes.apiKeyWarning}>
                Please be sure to copy the API key now! You will be unable to view the key again.
            </div>
        </>
    );

}

const AddApiKeyModal = props => {
    const {
        modalLoading,
        classes,
        modalData,
        companyId,
    } = props;

    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        UsersAPI.getRoles(props.currentCompanyOrgType, true).then(response => {
            const filteredData = response.data.filter(item => item.apiKey);
            setRoleList(filteredData);
        }).catch(error => {
            console.log(error);
        })
    }, []);

    return (
        <Formik
            initialValues={{
                description: modalData ? modalData.description : '',
                roleName: modalData ? modalData.roleName : ''
            }}
            validationSchema={validationSchema}
            onSubmit={
                (values) => {
                    if (!modalData) {
                        const user = props.user.toJS();
                        values.companyId = companyId;
                        values.email = user.email;
                        props.submitAddApiKeyForm(values);
                    }
                }
            }
            render={renderProps => {
                if (!modalData) {
                    return <AddApiKeyForm
                        classes={classes}
                        modalLoading={modalLoading}
                        roleList={roleList}
                        currentCompanyOrgType={props.currentCompanyOrgType}
                        {...renderProps} />
                } else {
                    return <AddApiKeySuccessForm
                        classes={classes}
                        modalData={modalData}
                        {...renderProps} />
                }
            }}
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
    user: selectUser(),
    currentCompanyOrgType: currentCompanyOrgType()
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddApiKeyForm: (apiKey) => dispatch(actions.addApiAccessKeyRequest(apiKey))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddApiKeyModal);