import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions, selectLocationCount } from '../../../reducers/locations';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

class DeleteBuildingModal extends Component {
    componentDidMount() {
        this.props.getLocationCount(this.props.modalData.id);
    }

    handleDelete = () => {
        this.props.submitDeleteBuilding(this.props.modalData.id, this.props.modalData.parentId, this.props.modalData.origin);
    }

    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like the following building and all <b>{this.props.locationCount}</b> of its locations deleted:
                        </p>
                    </Grid>
                    <Grid item >
                        <b id="buildingName">{this.props.modalData.name}</b>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
const mapStateToProps = ()  => createStructuredSelector({
    locationCount: selectLocationCount()
});

const mapDispatchToProps = dispatch => {
    return {
        getLocationCount: (organizationUnit) => dispatch(actions.getLocationCount(organizationUnit)),
        submitDeleteBuilding: (id, parentId, origin) => dispatch(actions.deleteBuildingRequest(id, parentId, origin))
    }
}

export default compose(
    connect(mapStateToProps , mapDispatchToProps),
    ModalWrapper
)(DeleteBuildingModal);