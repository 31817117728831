import { axiosInstance } from './api';
/* Cirrus Admin-Service /sso/SingleSignOn Controller */
const SSO_ENDPOINT = 'admin-service/sso/singleSignOn/';
const SSO_EXISTS = 'admin-service/sso/singleSignOn/exists/';
const SSO_LOGIN = 'sso/login';
export default class SingleSignOnApi {
    static addSsoSettings(payload) {
        return axiosInstance.post(SSO_ENDPOINT, payload);
    }

    static checkSingleSignOn(companyId) {
        if (companyId === null || companyId === undefined){
            return axiosInstance.get(SSO_EXISTS)
        }
        return axiosInstance.get(SSO_EXISTS + companyId);
    }

    static deleteSsoSettings(companyId) {
        if (companyId === null || companyId === undefined){
            return axiosInstance.delete(SSO_ENDPOINT)
        }
        return axiosInstance.delete(SSO_ENDPOINT + companyId);
    }

    static getCompanySsoSettings(companyId) {
        if (companyId === null || companyId === undefined){
            return axiosInstance.get(SSO_ENDPOINT);
        }
        return axiosInstance.get(SSO_ENDPOINT + companyId);
    }

    static checkIsSso(email) {
        return axiosInstance.get(`${SSO_LOGIN}/${email}`);
    }
}