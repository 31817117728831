import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import UsersAPI from '../apis/usersApi';
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as UsersActions, types as Users } from '../reducers/users';
import { userCompanyId, currentCompanyOrgType } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';
import AlertsAPI from '../apis/alertsApi';
import SingleSignOnApi from '../apis/singleSignOnApi';

function* getUsersList(action) {
  try {
    const companyLevel = yield select(currentCompanyOrgType());
    const companyId = yield select(userCompanyId());
    const response = yield call(
      UsersAPI.getUsersList,
      companyId,
      action.page,
      action.pageSize,
      action.searchTerm,
      action.sortBy,
      action.sortDirection,
      companyLevel
    );
    
    yield put(
      UsersActions.usersLoaded(
        response.data,
        response.headers["x-pagination-count"]
      )
    );
  } catch (error) {
    yield put(UsersActions.usersLoadingError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);

  }
}

function* checkSingleSignOn(action) {
  try {
    const response = yield call(
      SingleSignOnApi.checkSingleSignOn, action.companyId);
    yield put(
        UsersActions.checkSingleSignOnLoaded(
        response.data,
      )
    );
  } catch (error) {
      yield put(UsersActions.checkSingleSignOnError());
		  yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
  }
}

function* addUser(action) {
  try {
    const tableProps = yield select(selectTableProps());

    yield put(AppActions.setModalLoading());
    yield call(UsersAPI.addUser, action.data);
    yield put(AppActions.setModalClose());
    yield put(UsersActions.getUsersRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
  } catch (error) {
    console.log(error);
    yield put(AppActions.setModalError(errorHandler(error)));
  }
}

function* editUser(action) {
  try {
    const tableProps = yield select(selectTableProps());

    yield put(AppActions.setModalLoading());
    yield call(UsersAPI.editUser, action.data, action.row);
    yield put(AppActions.setModalClose());
    yield put(UsersActions.getUsersRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
  } catch (error) {
    console.log(error);
    yield put(AppActions.setModalError(errorHandler(error)));
  }
}

function* deleteUser(action) {
  try {
    const tableProps = yield select(selectTableProps());

    yield put(AppActions.setModalLoading());
    yield call(UsersAPI.deleteUser, action.data.id);
    yield put(AppActions.setModalClose());
    yield put(UsersActions.getUsersRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
  } catch (error) {
    console.log(error);
    yield put(AppActions.setModalError(errorHandler(error)));
  }
}

function* resendUserWelcomeEmail(action) {
	try{
		yield call(AlertsAPI.sendEmail, action.alertType, action.recipient);
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootUsersSaga() {
  yield all([
    yield takeLatest(Users.GET_USERS, getUsersList),
    yield takeLatest(Users.CHECK_SINGLE_SIGNON, checkSingleSignOn),
    yield takeLatest(Users.ADD_USER, addUser),
    yield takeLatest(Users.EDIT_USER, editUser),
    yield takeLatest(Users.DELETE_USER, deleteUser),
    yield takeLatest(Users.RESEND_USER_WELCOME_EMAIL_REQUEST, resendUserWelcomeEmail)
  ]);
}

export default rootUsersSaga;
