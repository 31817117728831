import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as AppActions } from '../../reducers/app';
import ReportsListTable from '../../containers/ReportsListTable/ReportsListTable';

import reducer from '../../reducers/scheduledReports';
import saga from '../../sagas/createReports';

import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSagas';

function ReportsTemplates(props) {
    useEffect(() => {
        props.setPageTitle('Create Reports');
        props.setPageTitleId('create-reports-header');
    }, []);

    return (
        <div>
            <ReportsListTable />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'scheduledReports', reducer });
const withSaga = injectSaga({ key: 'createReports', saga });
const withConnect = connect(null,mapDispatchToProps);


export default compose(
    withReducer,
    withSaga,
    withConnect
)(ReportsTemplates);