import React, { Component } from 'react';
import Button from '@material-ui/core/Button'; 
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions, selectDeleteLocationUserCount } from '../../../reducers/locations';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';


class DeleteLocationCERModal extends Component {
    handleDelete = () => {
        this.props.submitDeleteLocation(this.props.modalData.id, this.props.modalData.parentId, this.props.modalData.origin);
    }
    
    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like to delete the following CER Location:
                        </p>
                    </Grid>
                    <Grid item >
                        <b id="locationName">{this.props.modalData.name}</b>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}
                                disabled={modalLoading}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteLocation: (id, parentId, origin, buildingFlag) => dispatch(actions.deleteLocationRequest(id, parentId, origin, buildingFlag)),
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper
)(DeleteLocationCERModal);