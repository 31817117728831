import React, { useState, useEffect } from 'react';
import { InputLabel, MenuItem, Select, Switch, Tooltip} from "@material-ui/core";
import Info from '@material-ui/icons/Info';
import BuildingOrLocationAsyncSelect from '../BuildingOrLocationAsyncSelect/BuildingOrLocationAsyncSelect';

function BuildingOrLocationMultiComponent(props) {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        modalLoading,
        setFieldValue,
        setFieldTouched,
        isAlertSubscription
    } = props;

    const isLocations = props.company.locationTreeType === 'SIMPLE' || values.locationType == 'CER_LOCATIONS';

    const [rerenderBuildingSelect, setRerenderBuildingSelect] = useState(true);

    const onSelectBuildings = (event) => {
        const selectedBuildings = event;

        if (isLocations) {
            setFieldValue('locations', selectedBuildings);
        } else {
            setFieldValue('buildings', selectedBuildings);
        }
    }

    const handleLocationTypeChange = (event) => {
        const value = event.target.value;
        if (isLocations) {
            setFieldValue('locations', []);
        } else {
            setFieldValue('buildings', []);
        }
        setFieldValue('locationType', value);
    }

    useEffect(() => {
        setRerenderBuildingSelect(false);
        setTimeout(() => {
            setRerenderBuildingSelect(true);
        }, 500);
    }, [values.locationType]);

    return (
        <div style={{ position: 'relative' }}>
            {!props.isAlertSubscription &&
            <div>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={
                                    'Turn on to monitor calls from all provisioned locations or leave off to monitor calls from specific locations.'
                                    } placement="top-start">
                                        <Info/>
                    </Tooltip>
                    <InputLabel htmlFor="allBuildings" shrink={false}>All Locations</InputLabel>
                    <Switch
                        id="allBuildings"
                        name="allBuildings" /* important! this is how handleChange finds the field to update */
                        checked={values.allBuildings}
                        onChange={handleChange}
                        disabled={modalLoading}/>
                </span>
            </div>
            }

          {props.isAlertSubscription &&
          <div>
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={
                    'Turn on to subscribe to alerts from specific locations or leave off to subscribe to alerts from all locations.' 
                 } placement="top-start">
                    <Info />
                </Tooltip>
                    <InputLabel htmlFor="enableFiltering" shrink={false}>Enable Alert Filtering</InputLabel>
                    <Switch
                        id="enableFiltering"
                        name="enableFiltering" /* important! this is how handleChange finds the field to update */
                        color="primary"
                        checked={values.enableFiltering}
                        onChange={handleChange}
                        disabled={modalLoading}/>
                </span>

            {values.enableFiltering && 
            
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title=
                        'Turn off to filter by specific corporate locations.'
                     placement="top-start">
                        <Info />
                    </Tooltip>
                    <InputLabel htmlFor="allCorporateLocations" shrink={false}>All Corporate Locations</InputLabel>
                    <Switch
                        id="allCorporateLocations"
                        name="allCorporateLocations" /* important! this is how handleChange finds the field to update */
                        color="primary"
                        checked={values.allCorporateLocations}
                        onChange={handleChange}
                        disabled={modalLoading}/>

                    <Tooltip title=
                        'Turn on to be alerted for all personal locations.' style={{paddingLeft: '40'}}
                     placement="top-start">
                        <Info />
                    </Tooltip>
                    <InputLabel htmlFor="allPersonalLocations" shrink={false}>All Personal Locations</InputLabel>
                    <Switch
                        id="allPersonalLocations"
                        name="allPersonalLocations" /* important! this is how handleChange finds the field to update */
                        color="primary"
                        checked={values.allPersonalLocations}
                        onChange={handleChange}
                        disabled={modalLoading}/>
                </span>
                }
                </div>
            }
            
                {((!values.allBuildings && !props.isAlertSubscription) || (props.isAlertSubscription && values.enableFiltering && !values.allCorporateLocations)) && 
                    <div>
                        {props.cerEnabled && 
                            <div>
                                <InputLabel shrink={true} htmlFor="locationType"
                                    error={touched.locationType && Boolean(errors.locationType)}> {!values.allPersonalLocations ? '*Location Type' : 'Location Type'}</InputLabel>
                                <Select
                                    id="locationType"
                                    name="locationType"
                                    onChange={handleLocationTypeChange}
                                    value={values.locationType ? values.locationType : ''}
                                    onBlur={handleBlur}
                                    disabled={modalLoading || (!props.isAlertSubscription ? values.allBuildings : values.allCorporateLocations)}
                                    fullWidth={true}
                                    error={touched.locationType && Boolean(errors.locationType)}> 
                                    <MenuItem value="CER_LOCATIONS"><em>CER Locations</em></MenuItem>
                                    <MenuItem value="E911_LOCATIONS"><em>E911 Locations</em></MenuItem>
                                </Select>
                                <span id="locationType-helper-text" className="error-prompt">{touched.locationType ? errors.locationType : ""}</span>
                                <br/>
                            </div>
                        }
                        {values.locationType &&
                        <div>
                            {rerenderBuildingSelect &&
                                <div style={{position: 'relative'}}>
                                    <InputLabel 
                                        error={isLocations ? touched.locations && Boolean(errors.locations) : touched.buildings && Boolean(errors.buildings)}
                                        id="buildings-label" 
                                        htmlFor="allBuildingsValue"
                                        shrink={true}>{
                                            isLocations ? !values.allPersonalLocations ? '*Locations' : 'Locations' : values.allPersonalLocations ? 'Buildings' 
                                            : '*Buildings'
                                        }</InputLabel>
                                        <div>
                                            <BuildingOrLocationAsyncSelect
                                                id="allBuildingsValue"
                                                isMulti={true}
                                                cerToggle={values.locationType === 'CER_LOCATIONS'}
                                                defaultValue={isLocations ? values.locations : values.buildings}
                                                error={isLocations ? touched.locations && Boolean(errors.locations) : touched.buildings && Boolean(errors.buildings)}
                                                onChange={onSelectBuildings}
                                                {...props}
                                            />
                                            <span id="building-helper-text" className="error-prompt">{
                                                isLocations ?
                                                    touched.locations ? errors.locations : "" 
                                                :
                                                    touched.buildings ? errors.buildings : ""
                                            }</span>
                                        </div>
                                </div>
                            }
                        </div>
                        }
                    </div>
                }
            </div>
    );
}

export default BuildingOrLocationMultiComponent;