import  React, { useEffect, useState }  from 'react';
import { Route, Switch } from 'react-router-dom';
import injectSaga from '../../utils/injectSagas';
import saga from '../../sagas/app';
import LoginPage from '../LoginPage';
import DefaultLayout from './defaultLayout';
import AuthorizedRoute from '../../components/AuthorizedRoute/AuthorizedRoute';
import IdleTimer from 'react-idle-timer';
import LogoutModal from './LogoutModals/LogoutModal';
import LogoutConfirmationModal from './LogoutModals/LogoutConfirmationModal';
import reactTableFindAndReplace from '../../utils/reactTableFindAndReplace';
import getOS from '../../utils/OSdetection';
import AppProviders from '../../components/Providers/AppProvider';

function App () {
    let idleTimer = null;
    const [open, setOpen] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    function handleOnIdle () {
        if (!window.location.pathname.includes('/login') && !window.location.hostname.includes('localhost')) {
            setOpen(true);
        }
    }

    useEffect( () => { 
        if (window.location.href.includes('inactivityLogout')) {
            setOpenConfirmation(true);
        }

        reactTableFindAndReplace('-pageInfo', 'pageNumberValue', 'Page Number Value', 'input[type="number"]');
        reactTableFindAndReplace('-pageSizeOptions', 'pageSizeValue', 'Page Size Value', 'select');
    }, [window.location.pathname]);

    useEffect(() => {
        if (getOS() === 'Windows') {
            document.body.classList.add('windows');
        }

        if (sessionStorage.getItem('skin') === 'five9') {
            document.body.classList.add('five9');
        }
    }, []);

    return (
        <AppProviders>
            <div>
                <LogoutModal
                    open={open}
                    setOpen={setOpen}
                    setOpenConfirmation={setOpenConfirmation}
                />
                <LogoutConfirmationModal
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                />
                <IdleTimer
                    ref={ref => { idleTimer = ref }}
                    timeout={600000}
                    onIdle={handleOnIdle}
                    debounce={250}
                />
                <Switch>
                    <Route exact path="/login" component={LoginPage}/>
                    <AuthorizedRoute path="/" component={DefaultLayout} />
                </Switch>
            </div>
        </AppProviders>
    );
}

const withSaga = injectSaga({ key: 'app', saga });
export default withSaga(App);