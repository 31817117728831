import * as Yup from "yup";

export function validationSchema() {
	return Yup.object({
		email: Yup.string("Enter a valid Email")
			.trim()
			.email("Email is invalid")
			.required("Email is a required field.")
			.max(100, "Email has a max limit of 100 characters."),
		firstName: Yup.string()
			.trim()
			.max(64, "First Name has a max limit of 64 characters."),
		lastName: Yup.string()
			.trim()
			.max(64, "Last Name has a max limit 64 characters."),
		});
}