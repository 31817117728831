import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { currentCompanyLevel } from '../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import ModalWrapper from '../../../components/ModalWrapper';

class HeldDevicesHeldRequest extends Component {
    html = this.props.modalData.heldRequestMessage ? this.props.modalData.heldRequestMessage
                        .replace(/</g, "&lt;")
                        .replace(/\n/g, "<br/>")
                        : 'No content'
    
    createMarkup() { return {__html: this.html}; };
    
    render() {
        return(
            <div style={{ marginTop: '20px' }}>
                <div dangerouslySetInnerHTML={this.createMarkup()} />
            </div>
        );
    }
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel()
});

export default compose(
    ModalWrapper,
    connect(mapStateToProps)
)(HeldDevicesHeldRequest);