import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as PortActions, selectPortByParentId, portsLoading } from '../../reducers/networkDiscovery';
import { createStructuredSelector } from 'reselect';
import PortListTable from '../../containers/PortListTable/portListTable';

class PortList extends Component {

    componentDidMount() {        
        this.props.getPortRequest(this.props.lldpId);
    }

    render() {
        return <PortListTable lldpId = {this.props.lldpId} portList={this.props.ports} loading={this.props.loading} setCurrentModal={this.props.setCurrentModal}/>
    }
}

PortList.propTypes = {
    lldpId: PropTypes.string.isRequired,
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({
        ports: selectPortByParentId(ownProps.lldpId),
        loading: portsLoading(ownProps.lldpId)
    });
}

const mapDispatchToProps = dispatch => {
    return {
        getPortRequest: (lldpId) => dispatch(PortActions.getPortRequest(lldpId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortList);
