/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const CALL_MONITORING = 'CALL_MONITORING/'; 
export const types = {
    ADD_CALL_MONITORING: CALL_MONITORING + 'ADD_CALL_MONITORING',
    GET_CALL_MONITORING_REQUEST: CALL_MONITORING + 'GET_CALL_MONITORING_REQUEST',
    GET_CALL_MONITORING_SUCCESS: CALL_MONITORING + 'GET_CALL_MONITORING_SUCCESS',
    EDIT_CALL_MONITORING: CALL_MONITORING + 'EDIT_CALL_MONITORING',
    DELETE_CALL_MONITORING: CALL_MONITORING + 'DELETE_CALL_MONITORING',
    CALL_MONITORING_NUMBERS: CALL_MONITORING + 'CALL_MONITORING_NUMBERS',
    GET_CALL_MONITORING_ERROR: CALL_MONITORING + 'GET_CALL_MONITORING_ERROR'
};

export const initialState = fromJS({
    callMonitoringList: List(),
    callMonitoringLoading: false,
    callMonitoringPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_CALL_MONITORING_REQUEST:
            return state.set('callMonitoringLoading', true);
        case types.GET_CALL_MONITORING_ERROR:
            return state.set('callMonitoringLoading', false);
        case types.GET_CALL_MONITORING_SUCCESS:
            return state.set('callMonitoringLoading', false)
                        .set('callMonitoringList', List(action.data))
                        .set('callMonitoringPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    addCallMonitoringRequest: (callMonitoring) => ({ 
        type: types.ADD_CALL_MONITORING, 
        data: callMonitoring
    }),
    getCallMonitoringRequest: (page, pageSize, sortBy, direction) => ({
        type: types.GET_CALL_MONITORING_REQUEST,
        page,
        pageSize,
        sortBy,
        direction
    }),
    getCallMonitoringSuccess: (callMonitoringList, pageCount) => ({
        type: types.GET_CALL_MONITORING_SUCCESS,
        data: callMonitoringList,
        pageCount
    }),
    editCallMonitoringRequest: (callMonitoring) => ({
        type: types.EDIT_CALL_MONITORING,
        data: callMonitoring
    }),
    deleteCallMonitoringRequest: (id) => ({
        type: types.DELETE_CALL_MONITORING,
        data: id
    }),
    getCallMonitoringError: () => ({
        type: types.GET_CALL_MONITORING_ERROR
    })
};

// Selectors
const selectCallMonitoring = state => state.get('callMonitoring', initialState);

export const makeSelectCallMonitoringList = () =>
    createSelector(selectCallMonitoring, callMonitoringState => callMonitoringState.get('callMonitoringList'));

export const makeSelectCallMonitoringListLoading = () =>
    createSelector(selectCallMonitoring, callMonitoringState => callMonitoringState.get('callMonitoringLoading'));

export const makeSelectCallMonitoringPageCount = () =>
    createSelector(selectCallMonitoring, callMonitoringState => callMonitoringState.get('callMonitoringPageCount'));