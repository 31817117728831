import React, {useEffect, useReducer, useState} from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import NumberFormat from 'react-number-format';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import AlertsSubscriptionsEditMenu from '../../containers/AlertsSubscriptionsEditMenu/alertsSubscriptionsEditMenu';
import './AlertsSubscriptionsListTable.css';
import {connect} from 'react-redux';
import {actions as AppActions, selectCerEnabled} from '../../reducers/app';
import {
    actions as AlertsActions,
    makeSelectAlertSubscriptionsFilterList,
    makeSelectAlertSubscriptionsFilterListLoading,
    makeSelectAlertSubscriptionsList,
    makeSelectAlertSubscriptionsPageCount,
    types
} from '../../reducers/alerts';
import ReactDOMServer from 'react-dom/server'
import {selectUserCompany} from '../../reducers/auth';
import {createStructuredSelector} from 'reselect';
import UsersAPI from '../../apis/usersApi';

function AlertsSubscriptionsListTable(props) {
    const {
        loading,
        informacastList,
        userList,
        setUserList,
        sortColumns,
        setSortColumns,
        currentSortColumn,
        setCurrentSortColumn,
        fetchAlertsSubscriptionsList,
        fetchSubscribedLocationsList,
        page,
        setPage,
        pageSize,
        alertSubscriptionsList,
        alertSubscriptionsPageCount,
        alertSubscriptionsFilterList,
        alertSubscriptionsFilterLoading
    } = props;

    const [subscribedLocationCell, setSubscribedLocationCell] = useState([]);

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
    const isEdit = user && user.permissions.includes('EDIT_ALERT_SUBSCRIPTIONS');
    const isDelete = user && user.permissions.includes('DELETE_ALERT_SUBSCRIPTIONS');

    const buildTableColumns = [
        {
            Header: "Subscription Name",
            id: "name",
            sortable: true,
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: "Alert Template Name",
            id: "template-name",
            width:210,
            Cell: row => <div title={row.original.alertTemplate.name}>{row.original.alertTemplate.name}</div>
        },
        {
            Header: "Recipients",
            id: "recipients",
            Cell: row => {
                const {
                    emailRecipients,
                    smsRecipients,
                    userRecipients,
                    informacastRecipients
                } = row.original;

                function handleClick() {
                    props.setCurrentModal(types.ALERT_SUBSCRIPTION_RECIPIENTS, {
                        combinedSortedList,
                        recipients,
                        row: row.original
                    });
                }
                
                const usersList = userRecipients.map(item => {
                    let foundItem = props.userList && props.userList.find(profile => profile.id === item.userProfileId);

                    if(!foundItem) {
                        return UsersAPI.getUserById(item.userProfileId).then(
                            (response) => {
                                foundItem = response.data;
                                props.userList.push(foundItem)
                                props.setUserList(props.userList);


                               if (foundItem) {
                                    item.user = foundItem.email;
                                    return item;
                               } else {
                                   return { userProfileId: 'no longer exists', user: '(User no longer exists)'};
                               }

                            }
                        );
                    } else {
                        item.user = foundItem.email;
                        return item;
                    }
                });
                const informacastList = informacastRecipients.map(item => {
                    const foundItem = props.informacastList && props.informacastList.find(profile => profile.id === item.id);
                    if (props.informacastList !== undefined && props.informacastList !== null && props.informacastList.length > 0){
                        if(!foundItem) return { id: 'no longer exists', name: '(Entity no longer exists)'};
                        return {
                            id: foundItem.id,
                            name: foundItem.name,
                        };
                    }   
                });
                
                const recipients = [...emailRecipients, ...smsRecipients, ...usersList, ...informacastList];

                const filteredRecipientsList = recipients.map((item, index) => {
                    if (item && item.email) {
                        return item.email;
                    } else if (item && item.phoneNumber) {
                        item.phoneNumber = ReactDOMServer.renderToStaticMarkup(<NumberFormat value={item.phoneNumber} displayType={'text'} format="(###) ###-####" />).replace(/<[^>]*>/g, "")
                        return item.phoneNumber;
                    } else if (item && item.user) {
                        return item.user;
                    } else if (item && item.name) {
                        return item.name;
                    }
                    return null;
                });

                const combinedSortedList = filteredRecipientsList.sort();
                
                return <div className="recipients-wrapper">{combinedSortedList.join(', ').substring(0, 55)}{combinedSortedList.toString().length >= 50 ? 
                            <span 
                                tabIndex={0}
                                onKeyDown={e => {
                                    if ((e.key === ' ' || e.key === 'Enter') && document.activeElement.tagName !== 'BUTTON') {
                                        e.preventDefault();
        
                                        const expandElm = document.querySelectorAll('.more-link')[row.index];
        
                                        if (expandElm) {
                                            expandElm.click();
                                        }
                                    }
                                }}
                                className={`more-link more-link-${row.index}`} 
                                onClick={() => handleClick()}>
                                    ...more
                            </span> : ''}
                        </div>
            }
        },
        {
            Header: "Subscribed Locations",
            id: "subscribed-locations",
            Cell: row => {
                const rowIndex = row.index;

                function handleClick(rowObj) {
                    const nextPage = Number(rowObj.currentPage) + 1;
                    fetchSubscribedLocationsList(rowObj.subId, nextPage, rowObj.pageSize)
                }

                if (rowIndex < subscribedLocationCell.length) {
                    return (
                        subscribedLocationCell[rowIndex].value === 'Not Applicable' ?
                            <span key={rowIndex}>Not Applicable</span>
                        :
                        subscribedLocationCell[rowIndex].value === 'All Personal Locations' ?
                            <span key={rowIndex} title='All Personal Locations'> All Personal Locations</span>
                        :
                        subscribedLocationCell[rowIndex].value === 'All Corporate Locations' ?
                            <span key={rowIndex} title='All Corporate Locations'> All Corporate Locations</span>
                        :
                        subscribedLocationCell[rowIndex].value === 'All Locations' ?
                            <span key={rowIndex} title="All Locations">All Locations</span>
                        :
                            <>
                                <span key={rowIndex}>{subscribedLocationCell[rowIndex].value}</span>
                                {Number(subscribedLocationCell[rowIndex].numPages) > Number(subscribedLocationCell[rowIndex].currentPage) && (
                                    <span 
                                        tabIndex={0}
                                        onKeyDown={e => {
                                            if ((e.key === ' ' || e.key === 'Enter') && document.activeElement.tagName !== 'BUTTON') {
                                                e.preventDefault();
                                                handleClick(subscribedLocationCell[rowIndex]);
                                            }
                                        }}
                                        className={`more-link`} 
                                        onClick={() => handleClick(subscribedLocationCell[rowIndex])}
                                    >
                                        ...more
                                    </span>
                                )}
                            </>
                    )
                }
            }
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                (isEdit || isDelete) ? <AlertsSubscriptionsEditMenu row={row} setCurrentModal={props.setCurrentModal} /> : null
            )
        }
    ];

    let alertSubscriptionsArray = [];

    if (alertSubscriptionsList) {
        alertSubscriptionsArray = alertSubscriptionsList.toArray();
    }

    useEffect(() => {
        if (alertSubscriptionsArray && alertSubscriptionsArray.length === 0 && page > 0 && alertSubscriptionsPageCount < page+1) {
            setPage(page-1)
        }
    }, [alertSubscriptionsArray && alertSubscriptionsArray.length === 0 && page > 0 && alertSubscriptionsPageCount < page+1]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    useEffect(() => {
        if (alertSubscriptionsArray.length > 0) {
            let locationCellArray = []

            alertSubscriptionsArray.forEach((row) => {
                const subId = row.id;
                const currentPage = row.currentPage;
                const numPages = row.numPages;
                const pageSize = row.pageSize;

                if (row.alertTypeTO.name !== 'REDSKY_933_TEST_CALL_RECEIVED' && row.alertTypeTO.name !== 'EMERGENCY_CALL_RECEIVED') {
                    locationCellArray = [...locationCellArray, createSubscribedLocationObject("Not Applicable", subId, currentPage, numPages, pageSize)];
                } else if (row.allPersonalLocations && !row.allCorporateLocations && !row.filterData) {
                    locationCellArray = [...locationCellArray, createSubscribedLocationObject("All Personal Locations", subId, currentPage, numPages, pageSize)];
                } else if (row.allCorporateLocations && !row.allPersonalLocations) {
                    locationCellArray = [...locationCellArray, createSubscribedLocationObject("All Corporate Locations", subId, currentPage, numPages, pageSize)];
                } else if (row.allPersonalLocations && !row.allCorporateLocations && row.filterData) {
                    const filterDataString = row.filterData.map((item, index) => (
                        `${index < 1 ? 'All Personal Locations, ' : ''}${item.ouName}${index < row.filterData.length - 1 ? ', ' : ''}`
                    )).join('');
                    locationCellArray = [...locationCellArray, createSubscribedLocationObject(filterDataString, subId, currentPage, numPages, pageSize)];
                } else if (row.filterData) {
                    const filterDataString = row.filterData.map((item, index) => (
                        `${item.ouName}${index < row.filterData.length - 1 ? ', ' : ''}`
                    )).join('');
                    locationCellArray = [...locationCellArray, createSubscribedLocationObject(filterDataString, subId, currentPage, numPages, pageSize)];
                } else {
                    locationCellArray = [...locationCellArray, createSubscribedLocationObject("All Locations", subId, currentPage, numPages, pageSize)];
                }
            });

            if (!arraysAreEqual(subscribedLocationCell, locationCellArray)) {
                setSubscribedLocationCell(locationCellArray);
            }
        }
    }, [alertSubscriptionsArray])

    function createSubscribedLocationObject(value, subId, currentPage, numPages, pageSize) {
        return {
            "value": value,
            "subId": subId,
            "currentPage": currentPage,
            "numPages": numPages,
            "pageSize": pageSize
        }
    }

    function arraysAreEqual(array1, array2) {
        if (array1.length !== array2.length) {
            return false;
        }

        for (let i = 0; i < array1.length; i++) {
            const obj1 = array1[i];
            const obj2 = array2[i];

            if (
                obj1.value !== obj2.value ||
                obj1.subId !== obj2.subId ||
                obj1.page !== obj2.page ||
                obj1.numPages !== obj2.numPages ||
                obj1.pageSize !== obj2.pageSize
            ) {
                return false;
            }
        }

        return true;
    }

    useEffect( () => {
        fetchAlertsSubscriptionsList(props.page+1, props.pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: props.page+1,
            pageSize: props.pageSize,
            sortBy: currentSortColumn.id,
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, props.pageSize, props.page]);

    useEffect(() => {
        const updatedState = [...subscribedLocationCell];
        const fetchedData = alertSubscriptionsFilterList.toArray();

        if (fetchedData.length > 0) {
            const subId = fetchedData[0].subscriptionId;
            const index = updatedState.findIndex((item) => item.subId === subId);

            if (index !== -1) {
                alertSubscriptionsArray[index].filterData = alertSubscriptionsArray[index].filterData.concat(fetchedData);

                fetchedData.forEach((data) => {
                    const { subscriptionId, ouName } = data;
                    const index = updatedState.findIndex((item) => item.subId === subscriptionId);
                    if (index !== -1) {
                        updatedState[index].value = `${updatedState[index].value}, ${ouName}`;
                    }
                });

                const currentPage = parseInt(updatedState[index].currentPage || 1);
                updatedState[index].currentPage = (currentPage + 1).toString();

                setSubscribedLocationCell(updatedState);
            }
        }
    }, [alertSubscriptionsFilterList])

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            key={alertSubscriptionsFilterLoading}
            resizable={false}
            sortable={false}
            className="-striped -highlight alert-sub-table"
            showPaginationBottom={true}
            data={alertSubscriptionsArray}
            minRows={alertSubscriptionsArray && alertSubscriptionsArray.length > 0 ? 0 : 5}
            pages={alertSubscriptionsPageCount <= 0 ? 1 : alertSubscriptionsPageCount}
            page={props.page}
            defaultPageSize={25}
            columns={buildTableColumns}
            manual
            loading={loading}
            onFetchData={(state, instance) => {
                props.setPageSize(state.pageSize);
                props.setCurrentPage(state.page+1);
                resetScrollInsideTable();
            }}
            onPageSizeChange={(pageSize) => {
                props.setPageSize(pageSize)
                props.setPage(0);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setCurrentPage(pageIndex + 1);
                props.setPage(pageIndex);
                resetScrollInsideTable();
            }}
            LoadingComponent={ReactTableLoadingComponent}
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
            }}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'alert-subscription-row-' + rowInfo.index,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    alertSubscriptionsList: makeSelectAlertSubscriptionsList(),
    alertSubscriptionsPageCount: makeSelectAlertSubscriptionsPageCount(),
    alertSubscriptionsFilterList: makeSelectAlertSubscriptionsFilterList(),
    alertSubscriptionsFilterLoading: makeSelectAlertSubscriptionsFilterListLoading(),
	company: selectUserCompany(),
    cerEnabled: selectCerEnabled()
});

const mapDispatchToProps = dispatch => {
  return {
            setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
            setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
			setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
            fetchAlertsSubscriptionsList: (page, pageSize, sortBy, direction) => dispatch(AlertsActions.getAlertSubscriptionsRequest(page, pageSize, sortBy, direction)),
            fetchSubscribedLocationsList: (subId, page, pageSize) => dispatch(AlertsActions.getAlertSubscriptionsFilterRequest(subId, page, pageSize)),
            setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsSubscriptionsListTable);