import React, { useState, useEffect } from 'react';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';

import reducer from '../../reducers/scheduledReports';
import saga from '../../sagas/scheduledReports';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { createStructuredSelector } from 'reselect';

import ScheduledReportsTable from '../../containers/ScheduledReportsTable/ScheduledReportsTable';

const initialSortColumns = [
    {
        id: 'name',
        desc:false
    }
]

function ScheduledReportsPage(props) {
    const [page, setPage] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    useEffect(() => {
        props.setPageTitle('Scheduled Reports');
        props.setPageTitleId('scheduled-reports-header');
    }, []);

    return (
        <div>
            <ScheduledReportsTable 
                setCurrentModal={props.setCurrentModal} 
                page={page}
                setPage={setPage}
                pageSize={props.pageSize}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                currentSortColumn={currentSortColumn}
                setCurrentSortColumn={setCurrentSortColumn}  />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data))
    }
};

const withReducer = injectReducer({ key: 'scheduledReports', reducer });
const withSaga = injectSaga({ key: 'scheduledReports', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(ScheduledReportsPage);