import { axiosInstance } from './api';
/* Cirrus Admin-Service /pstn Controller */
const PSTN_ENDPOINT = 'admin-service/pstnPeer';

export default class PstnAPI {
    static addPstn(pstn, companyId) {
        const payload = {
           name: pstn.name,
           organizationId: companyId,
           did: pstn.did,
           overrideDestination: pstn.overrideDestination,
           disableRelayCenter: pstn.disableRelayCenter ? pstn.disableRelayCenter : null
        };
        return axiosInstance.post(PSTN_ENDPOINT, payload);
    }
    
    static editPstn(pstn) {
        const payload = {
           name: pstn.name,
           id: pstn.id,
           did: pstn.did,
           overrideDestination: pstn.overrideDestination ? pstn.overrideDestination.type : null,
           disableRelayCenter: pstn.disableRelayCenter ? pstn.disableRelayCenter : null
        };
        return axiosInstance.put(PSTN_ENDPOINT, payload);
    }

    static getPstnById(companyId, page, pageSize, sortBy, direction) {
        return axiosInstance.get(`${PSTN_ENDPOINT}/${companyId}`, {
            params: {
                page, 
                pageSize,
                sortBy,
                direction
            }
        });
    }

    static getPstn(page, pageSize, sortBy, direction) {
        return axiosInstance.get(PSTN_ENDPOINT, {
            params: {
                page, 
                pageSize,
                sortBy,
                direction
            }
        });
    }
    
    static deletePstn(pstnId) {
        return axiosInstance.delete(`${PSTN_ENDPOINT}/${pstnId}`);
    }

    static getPstnPeerDestinationTypes() {
        return axiosInstance.get(`${PSTN_ENDPOINT}/destinationTypes`);
    }
}