import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Delete from '@material-ui/icons/Delete';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';

function CallMonitoringListTable(props) {
    const buildTableColumns = [
        {
            Header: "Phone Number",
            id: "phone-number",
            Cell: row => <div title={row.original.phoneNumber}>{!isNaN(row.original.phoneNumber) ? <NumberFormatter number={row.original.phoneNumber} /> : row.original.phoneNumber}</div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                <Delete id={`call-monitoring-delete-button-${row.index}`} onClick={() => props.phoneNumberListDeleteHandler(row)} row={row} />
            )
        }
    ];

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight call-monitoring-table"
            showPaginationBottom={false}
            data={props.phoneNumberList}
            minRows={props.phoneNumberList && props.phoneNumberList.length > 0 ? 0 : 5}
            pageSize={props.phoneNumberList ? props.phoneNumberList.length : 1}
            columns={buildTableColumns}
            style={{clear: 'both', height: '400px'}}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'call-monitoring-row-' + rowInfo.index,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

export default (CallMonitoringListTable);