import { provinces } from '../utils/stateAndCountryData';
import { axiosInstance } from './api';
/* Cirrus Admin-Service /locations Controller */
const BUILDINGS_ENDPOINT = 'geography-service/buildings';
const RESCAN_ENDPOINT = 'geography-service/address/revalidate';
const SET_LOCATIONS_PARSED = '/geography-service/buildings/parsed';
const SET_LOCATIONS_COMPACT = '/geography-service/buildings/compactAddress';

export default class BuildingsAPI {
    static addBuilding(building) {
        const payload = {
           name: building.name,
           parentOrganizationUnitId: building.parentId,
           fullAddress: building.address,
           latitude: building.address ? '' : building.latitude,
           longitude: building.address ? '' : building.longitude,
           supplementalData: building.supplemental,
           orgNameOverride: building.overrideOrganizationName,
           origin: 'default'
        };
        return axiosInstance.post(BUILDINGS_ENDPOINT, payload);
    }

    static editBuilding(building) {
        const payload = {
            addressId: building.addressId,
            fullAddress: building.address,
            latitude: building.address ? '' : building.latitude,
            longitude: building.address ? '' : building.longitude,
            buildingId: building.id,
            name: building.name,
            supplementalData: building.supplemental,
            orgNameOverride: building.overrideOrganizationName,
            origin: 'default'
        };
        return axiosInstance.put(BUILDINGS_ENDPOINT, payload);
    }

    static getBuildings(parentId, page, pageSize, searchTerm, sortBy, direction, origin) {
        return axiosInstance.get(`${BUILDINGS_ENDPOINT}/parent/${parentId}`, {
            params: {
                direction,
                sortBy,
                origin,
                page,
                pageSize: pageSize ? pageSize : 100,
                searchTerm
            }
        });
    }
    
    static getBuildingsNameSearch(parentId, page, pageSize, searchTerm, origin = 'default', sortBy, direction) {
        return axiosInstance.get(`${BUILDINGS_ENDPOINT}/parent/${parentId}`, {
            params: {
                direction,
                sortBy,
                nameOnly: true,
                origin,
                page,
                pageSize: pageSize ? pageSize : 25,
                searchTerm
            }
        });
    }
        
    static deleteBuilding(buildingId) {
        return axiosInstance.delete(`${BUILDINGS_ENDPOINT}/${buildingId}`);
    }

    static getBuildingById(buildingId) {
        return axiosInstance.get(`${BUILDINGS_ENDPOINT}/${buildingId}`);
    }

    static createBuildingParsed(location) {
        const hasAddress = location.address.houseNumber || 
                            location.address.streetName || 
                            location.address.streetType ||
                            location.address.prefixDirectional ||
                            location.address.postDirectional ||
                            location.address.zipCode || 
                            location.address.city || 
                            location.address.stateProvince;

        if(location.address.country === null){
            if(provinces.includes(location.address.state)){
                location.address.country = "CA";
            }
            else
            {
                location.address.country="US";
            }
        }

        const payload = {
            name: location.name,
            info: location.info,
            parentOrganizationUnitId: location.parentId,
            parsedAddress: {
                houseNumber: location.address.houseNumber,
                streetName: location.address.streetName,
                streetType: location.address.streetType,
                zipCode: location.address.zipCode,
                city: location.address.city,
                country: location.address.country,
                state: location.address.state,
                prefixDirectional: location.address.prefixDirectional,
                postDirectional: location.address.postDirectional
            },
            latitude: hasAddress ? '' : location.latitude,
            longitude: hasAddress ? '' : location.longitude,
            orgNameOverride: location.overrideOrganizationName,
            origin:'default'
        };
        return axiosInstance.post(SET_LOCATIONS_PARSED, payload);
    }
    
    static createBuildingAlternate(location) {
        const hasAddress = location.houseNumber || 
                            location.street || 
                            location.streetType ||
                            location.prefixDirectional ||
                            location.postDirectional ||
                            location.zip || 
                            location.city || 
                            location.stateProvince;

        const payload = {
            name: location.name,
            supplementalData: location.supplemental,
            parentOrganizationUnitId: location.parentId,
            parsedAddress: {
                streetName: location.street,
                zipCode: location.zip,
                city: location.city,
                country: location.country,
                state: location.stateProvince,
                houseNumber: location.houseNumber,
                houseNumberExtension: location.houseNumberExtension,
                prefixDirectional: location.prefixDirection,
                streetType: location.streetType,
                postDirectional: location.postDirection,
              },
            latitude: hasAddress ? '' : location.latitude,
            longitude: hasAddress ? '' : location.longitude,
            orgNameOverride: location.overrideOrganizationName,
            origin:'default'
        };
        
        return axiosInstance.post(SET_LOCATIONS_PARSED, payload);
    }
    
    static editBuildingAlternate(location) {
        const hasAddress = location.houseNumber || 
                            location.street || 
                            location.streetType ||
                            location.prefixDirectional ||
                            location.postDirectional ||
                            location.zip || 
                            location.city || 
                            location.stateProvince;

        const payload = {
            name: location.name,
            supplementalData: location.supplemental,
            buildingId: location.id,
            parsedAddress: {
                streetName: location.street,
                zipCode: location.zip,
                city: location.city,
                country: location.country,
                state: location.stateProvince,
                houseNumber: location.houseNumber,
                houseNumberExtension: location.houseNumberExtension,
                prefixDirectional: location.prefixDirection,
                streetType: location.streetType,
                postDirectional: location.postDirection,
                orgId: location.parentId
            },
            latitude: hasAddress ? '' : location.latitude,
            longitude: hasAddress ? '' : location.longitude,
            orgNameOverride: location.overrideOrganizationName,
            origin: 'default'
        };
    
        return axiosInstance.put(SET_LOCATIONS_PARSED, payload);
    }

    static editBuildingParsed (location) {
        const hasAddress = location.houseNumber || 
                            location.street|| 
                            location.streetType ||
                            location.prefixDirection ||
                            location.postDirection ||
                            location.zip || 
                            location.city || 
                            location.stateProvince;
        
        if(location.country === null){
            if(provinces.includes(location.state)){
                location.country = "CA";
            }
            else
            {
                location.country="US";
            }
        }

        const payload = {
            name: location.name,
            supplementalData: location.supplemental,
            buildingId: location.id,
            parsedAddress: {
                streetName: location.address.streetName,
                streetType: location.address.streetType,
                zipCode: location.address.zipCode,
                city: location.address.city,
                country: location.address.country,
                state: location.address.state,
                houseNumber: location.address.houseNumber,
                houseNumberExtension: location.address.houseNumberExtension,
                prefixDirectional: location.address.prefixDirectional,
                postDirectional: location.address.postDirectional,
            },
            latitude: hasAddress ? '' : location.latitude,
            longitude: hasAddress ? '' : location.longitude
        }
        return axiosInstance.put(SET_LOCATIONS_PARSED, payload);
    }

    static rescanAddress(addressId, companyId) {
        return axiosInstance.get(RESCAN_ENDPOINT, {
            params: {
                addressId,
                companyId
            }
        });
    }
}