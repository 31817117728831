import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Business from '@material-ui/icons/Business';
import Build from '@material-ui/icons/Build';
import Group from '@material-ui/icons/Group';
import MapIcon from '@material-ui/icons/Map';
import Assignment from '@material-ui/icons/Assignment';
import Create from '@material-ui/icons/Create';
import Schedule from '@material-ui/icons/Schedule';
import FolderSpecial from '@material-ui/icons/FolderSpecial';
import Computer from '@material-ui/icons/Computer';
import Phone from '@material-ui/icons/Phone';
import DialerSip from '@material-ui/icons/DialerSip';
import SettingsPhone from '@material-ui/icons/SettingsPhone';
import History from '@material-ui/icons/History';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PhoneCallback from '@material-ui/icons/PhoneCallback';
import PhonelinkSetup from '@material-ui/icons/PhonelinkSetup';
import { ReactComponent as HealthAndSafetyIcon } from '../../assets/health_and_safety.svg';
import Home from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { ReactComponent as AdminPanelSettings } from '../../assets/admin_panel_settings.svg';
import WbCloudy from '@material-ui/icons/WbCloudy';
import Place  from '@material-ui/icons/Place';
import MenuIcon from '@material-ui/icons/Menu';
import NetworkWifi  from '@material-ui/icons/NetworkWifi';
import Notifications  from '@material-ui/icons/Notifications';
import Info  from '@material-ui/icons/Info';
import CloudDownload  from '@material-ui/icons/CloudDownload';
import CloudUpload  from '@material-ui/icons/CloudUpload';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { currentCompanyLevel, selectUserCompany, selectUserRole, currentCompanyOrgType, orgTypes, selectUser, originalCompanyOrgType } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import { orgHasLicense, cirrusSkins, selectCerEnabled, selectInformaCastEnabled, selectEmergencyCallAssistEnabled } from '../../reducers/app';
import './sideNav.css';
import Logo from '../../assets/RedSky_Everbridge.svg';
import AttLogo from '../../assets/att-logo.png';
import { Button } from '@material-ui/core';
import { routeAndNavData, routeAndNavCheck } from './routeAndNavData';
import { VpnKey } from '@material-ui/icons';

function isViewAdministration() {
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
    const isviewUsers = user && user.permissions.includes('VIEW_DEVICE_USERS');
    const isviewHeldDevices = user && user.permissions.includes('VIEW_HELD_DEVICES');
    const isviewNetworkDiscovery = user && user.permissions.includes('VIEW_NETWORK_DISCOVERY');
    const isviewLocations = user && user.permissions.includes('VIEW_LOCATIONS');
    const isviewOrganizations = user && user.permissions.includes('VIEW_ORGANIZATIONS', 'VIEW_ADMINS_SAME_ORG', 'VIEW_ADMINS_SUB_ORG');
    const isviewNotificationIntegrations = user && user.permissions.includes('VIEW_NOTIFICATION_INTEGRATIONS');
    const isViewAlertSubscriptions = user && user.permissions.includes('VIEW_ALERT_SUBSCRIPTIONS');
    const isViewMy911Client = user && user.permissions.includes('VIEW_MYE911_CLIENT_OPTIONS');
    const isViewRoutingOptions = user && user.permissions.includes('VIEW_ROUTING_OPTIONS');
    return isviewOrganizations ||  isviewNotificationIntegrations|| isviewUsers || isviewHeldDevices || isviewNetworkDiscovery || isviewLocations || isViewAlertSubscriptions || isViewMy911Client || isViewRoutingOptions;
}

function isViewImporting() {
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
    const isAddEditUsers = user && (user.permissions.includes('ADD_DEVICE_USERS') || user.permissions.includes('EDIT_DEVICE_USERS'));
    const isAddEditNetworkDiscovery = user && (user.permissions.includes('ADD_NETWORK_DISCOVERY') || user.permissions.includes('EDIT_NETWORK_DISCOVERY'));
    const isAddEditLocations = user && (user.permissions.includes('ADD_LOCATIONS') || user.permissions.includes('EDIT_LOCATIONS'));
    const isAddEditPidfloDevices = user && (user.permissions.includes('ADD_PIDFLO_DEVICES') || user.permissions.includes('EDIT_PIDFLO_DEVICES'));
    return isAddEditUsers || isAddEditNetworkDiscovery || isAddEditLocations || isAddEditPidfloDevices;
}

function isCallAssist() {
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
    return user.permissions.includes('ADD_CALL_ASSIST');
}

const styles = () => ({
    root: {
        width: '100%',
        maxWidth: 320,
        background: 'white',
        opacity: .8,
        marginTop: '20px' ,
        height: '100%'
    }
});

const ExpansionPanel = withStyles({
    root: {
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiExpansionPanel);
  
  const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: 'transparent',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiExpansionPanelSummary);
  
  const ExpansionPanelDetails = withStyles(theme => ({
    root: {
    //   padding: theme.spacing(2),
    },
  }))(MuiExpansionPanelDetails);
  

const SideNav = props => {
    const [expanded, setExpanded] = useState('');
    const [attSkin, setAttSkin] = useState(false);
    const [removeMobileItems, setRemoveMobileItems] = useState(true);


    const handleChange = panel => (event, newExpanded
        ) => {
        setExpanded(newExpanded ? panel : false);
    };
    
    useEffect(() => {
        if (props.cirrusSkins === 'att') {
            setAttSkin(true);
        } else {
            setAttSkin(false);
        }
        
    }, [props.cirrusSkins]);

    useEffect(() => {
        const bodyEl = document.querySelector('body');

        function windowWidthCheck() {
            if (window.innerWidth <= 1240) {
                setRemoveMobileItems(false);
                bodyEl.classList.add('collapse-nav');
            } else {
                setRemoveMobileItems(true);
                bodyEl.classList.remove('collapse-nav');
            }
        }
        
        window.addEventListener('resize', windowWidthCheck);
        windowWidthCheck();


        const specifiedElement = document.querySelector('.sidenav');

        function handleOutsideClick(event) {
            const isClickInside = specifiedElement.contains(event.target);
            if (!isClickInside) {
            bodyEl.classList.remove('overlay-nav');   
            }
        }

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('resize', windowWidthCheck);
        };
    }, []);

    function handleNavExpand() {
        const bodyEl = document.querySelector('body');

        bodyEl.classList.toggle('overlay-nav');
    }

    return (
        <div className={attSkin ? 'sidenav att-skin' : 'sidenav'}>
            <div className="top-wrapper">
                {attSkin ?
                    <img src={AttLogo} alt="ATT Logo"/>
                    :
                    <span className="logo"></span>
                }
            </div>
            <div className="mid-wrapper">
            {!removeMobileItems &&
                <Button className="menu-icon" onClick={handleNavExpand}>
                    <MenuIcon/>
                </Button>
            }
            <Tooltip title={removeMobileItems ? '' : 'Dashboard'} placement="right">
                <ListItem button 
                        id="dashboard-nav-item"
                        component={Link} 
                        aria-label="Dashboard"
                        to="/">
                    <ListItemIcon aria-hidden={true}><Home /></ListItemIcon>
                    <ListItemText className="nav-link-text" inset primary="Dashboard" />
                </ListItem>
            </Tooltip>
                {isViewAdministration() &&
                    <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <ExpansionPanelSummary className="summary" aria-controls="panel1d-content" id="panel1d-header">
                        <Tooltip title={removeMobileItems ? '' : 'Administration'} placement="right">
                            <ListItem component='div' aria-label="Administration" id="administration-nav-item">
                                <ListItemIcon>
                                    <AdminPanelSettings style={{ width: 22, marginRight: '2px' }} aria-hidden={true}/>
                                </ListItemIcon>
                                <ListItemText className="nav-link-text" inset primary="Administration" />
                            </ListItem>
                        </Tooltip>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails id="panel1d-content" className="details">
                            <List component="div" disablePadding>
                                {routeAndNavData.map((item, index) => {
                                    if ((item.parentNavItem === 'Administration') &&
                                        (!routeAndNavCheck(item, props))
                                    ) {
                                        return (
                                            <Tooltip key={index} title={removeMobileItems ? '' : item.title} placement="right">
                                                <ListItem disabled={expanded !== 'panel1'} aria-label={item.title} id={item.id} button component={Link} to={item.path} className="nested">
                                                    <ListItemIcon aria-hidden={true}>
                                                        {item.title === 'Organizations' ? 
                                                            <Business className="sub-icon"/> :
                                                        item.title === 'Organization Management' ? 
                                                            <Assignment className="sub-icon"/> :
                                                        item.title === 'API Access Keys' ? 
                                                            <VpnKey className="sub-icon"/> :
                                                        item.title === 'Notification Integrations' ? 
                                                            <span>
                                                                <Group className="sub-icon"/>
                                                                <NotificationsIcon className="sub-icon notification-icon"/>
                                                            </span> : 
                                                        item.title === 'Administrators' ? 
                                                            <Group className="sub-icon"/> : ''
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText className="nav-link-text" inset primary={item.title} />
                                                </ListItem>                        
                                            </Tooltip>
                                        );
                                    } else {
                                        return '';
                                    }
                                })
                                }
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                }
                <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <ExpansionPanelSummary className="summary" aria-controls="panel2d-content" id="panel2d-header">
                    <Tooltip title={removeMobileItems ? '' : 'Configuration'} placement="right">
                        <ListItem component='div' aria-label="Configuration" id="configuration-nav-item">
                            <ListItemIcon aria-hidden={true}><Build/></ListItemIcon>
                            <ListItemText className="nav-link-text" inset primary="Configuration" />
                        </ListItem>
                    </Tooltip>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails id="panel2d-content" className="details">
                        <List component="div" disablePadding>
                            {routeAndNavData.map((item, index) => {
                                if ((item.parentNavItem === 'Configuration') &&
                                    (!routeAndNavCheck(item, props))
                                ) {
                                    return (
                                        <Tooltip key={index} title={removeMobileItems ? '' : item.title} placement="right">
                                            <ListItem disabled={expanded !== 'panel2'} aria-label={item.title} id={item.id} button component={Link} to={item.path} className="nested">
                                                <ListItemIcon aria-hidden={true}>
                                                    {item.title === 'Alerts & Emails' ? 
                                                        <Notifications className="sub-icon"/> :
                                                    item.title === 'E911 Locations' || item.title === 'CER Locations' ? 
                                                        <Place className="sub-icon"/> :
                                                    item.title === 'Users' ? 
                                                        <Computer className="sub-icon"/> : 
                                                    item.title === 'PIDF-LO Devices' ?
                                                        <PermPhoneMsgIcon className="sub-icon"/> :
                                                    item.title === 'Network Discovery' ? 
                                                        <NetworkWifi className="sub-icon"/> :
                                                    item.title === 'Call Routing - SIP' ? 
                                                        <DialerSip className="sub-icon"/> :
                                                    item.title === 'Call Routing - PSTN' ? 
                                                        <PhoneCallback className="sub-icon"/> :
                                                    item.title === 'Call Monitoring' ? 
                                                        <PhonelinkSetup className="sub-icon"/> :
                                                    item.title === 'EON Users' ? 
                                                        <HealthAndSafetyIcon className="sub-icon"/> :
                                                    item.title === 'Address Management' ? 
                                                        <MapIcon className="sub-icon"/>
                                                        : ''
                                                    }
                                                </ListItemIcon>
                                                <ListItemText className="nav-link-text" inset primary={item.title} />
                                            </ListItem>                        
                                        </Tooltip>
                                    );
                                } else {
                                    return '';
                                }
                            })
                            }
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <ExpansionPanelSummary className="summary" aria-controls="panel3d-content" id="panel3d-header">
                    <Tooltip title={removeMobileItems ? '' : 'Monitoring'} placement="right">
                        <ListItem component='div' aria-label="Monitoring" id="monitoring-nav-item">
                            <ListItemIcon aria-hidden={true}><WbCloudy/></ListItemIcon>
                            <ListItemText className="nav-link-text" inset primary="Monitoring" />
                        </ListItem>
                    </Tooltip>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails id="panel3d-content" className="details">
                        <List component="div" disablePadding>
                            {routeAndNavData.map((item, index) => {
                                    if ((item.parentNavItem === 'Monitoring') &&
                                        (!routeAndNavCheck(item, props))
                                    ) {
                                        return (
                                            <Tooltip key={index} title={removeMobileItems ? '' : item.title} placement="right">
                                                <ListItem disabled={expanded !== 'panel3'} aria-label={item.title} id={item.id} button component={Link} to={item.path} className="nested">
                                                    <ListItemIcon aria-hidden={true}>
                                                        {item.title === 'Test Call Generator' ? 
                                                            <SettingsPhone className="sub-icon"/> :
                                                        item.title === 'Call History' ? 
                                                            <History className="sub-icon"/> :
                                                        item.title === 'Events' ? 
                                                            <Info className="sub-icon"/> : 
                                                        item.title === 'HELD Devices' ? 
                                                            <Phone className="sub-icon"/> : ''
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText className="nav-link-text" inset primary={item.title} />
                                                </ListItem>                        
                                            </Tooltip>
                                        );
                                    } else {
                                        return '';
                                    }
                                })
                                }
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {  props.currentCompanyOrgType === orgTypes.CUSTOMER && isViewImporting() &&
                    <Tooltip title={removeMobileItems ? '' : 'Importing'} placement="right">
                        <ListItem
                                aria-label="Importing" 
                                id="importing-nav-item"
                                button 
                                component={Link} 
                                to="/importing">
                            <ListItemIcon aria-hidden={true}>
                                <CloudUpload/>
                            </ListItemIcon>
                            <ListItemText className="nav-link-text" inset primary="Importing" />
                        </ListItem>
                    </Tooltip>
                }
                <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <ExpansionPanelSummary className="summary" aria-controls="panel4d-content" id="panel4d-header">
                    <Tooltip title={removeMobileItems ? '' : 'Reports'} placement="right">
                        <ListItem component='div' aria-label="Reports" id="reports-nav-item">
                            <ListItemIcon aria-hidden={true}>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText className="nav-link-text" inset primary="Reports" />
                        </ListItem>
                    </Tooltip>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails id="panel4d-content" className="details">
                        <List component="div" disablePadding>
                            {routeAndNavData.map((item, index) => {
                                    if ((item.parentNavItem === 'Reports') &&
                                        (!routeAndNavCheck(item, props))
                                    ) {
                                        return (
                                            <Tooltip key={index} title={removeMobileItems ? '' : item.title} placement="right">
                                                <ListItem disabled={expanded !== 'panel4'} aria-label={item.title} id={item.id} button component={Link} to={item.path} className="nested">
                                                    <ListItemIcon aria-hidden={true}>
                                                        {item.title === 'Create Reports' ? 
                                                            <Create className="sub-icon"/> :
                                                        item.title === 'Scheduled Reports' ? 
                                                            <Schedule className="sub-icon"/> : ''
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText className="nav-link-text" inset primary={item.title} />
                                                </ListItem>                        
                                            </Tooltip>
                                        );
                                    } else {
                                        return '';
                                    }
                                })
                                }
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Tooltip title={removeMobileItems ? '' : 'Client Installers'} placement="right">
                    <ListItem
                            aria-label="Client Installers" 
                            id="downloads-nav-item"
                            button 
                            component={Link} 
                            to="/downloads">
                        <ListItemIcon aria-hidden={true}>
                            <CloudDownload/>
                        </ListItemIcon>
                        <ListItemText className="nav-link-text" inset primary="Client Installers" />
                    </ListItem>
                </Tooltip>
                {  props.currentCompanyOrgType === orgTypes.CUSTOMER && props.emergencyCallAssistEnabled && isCallAssist() &&
                    <Tooltip title={removeMobileItems ? '' : 'Emergency Call Assist'} placement="right">
                        <ListItem
                                aria-label="Emergency Call Assist" 
                                id="emergency-call-assist-nav-item"
                                button 
                                component={Link} 
                                to="/emergency-call-assist">
                            <ListItemIcon aria-hidden={true}>
                                <ContactPhoneIcon/>
                            </ListItemIcon>
                            <ListItemText className="nav-link-text" inset primary="Emergency Call Assist" />
                        </ListItem>
                    </Tooltip>
                }
                <Tooltip title={removeMobileItems ? '' : 'Online Help'} placement="right">
                <ListItem button component="a" target="_blank" href="https://resources.e911cloud.com/help/index.htm">
                        <ListItemIcon aria-hidden={true}>
                            <FolderSpecial/>
                        </ListItemIcon>
                        <ListItemText className="nav-link-text" inset primary="Online Help" />
                    </ListItem>
                </Tooltip>
            </div>
            {sessionStorage.getItem('skin') === 'five9' && 
                <div className="bottom-wrapper">
                    <img src={Logo} alt="Redsky Technologies " />
                </div>
            }
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    user: selectUser(),
    currentCompanyLevel: currentCompanyLevel(),
    orgHasLicense: orgHasLicense(),
    role: selectUserRole(),
    company: selectUserCompany(),
    cirrusSkins: cirrusSkins(),
    cerEnabled: selectCerEnabled(),
    informaCastEnabled: selectInformaCastEnabled(),
    emergencyCallAssistEnabled: selectEmergencyCallAssistEnabled(),
    currentCompanyOrgType: currentCompanyOrgType(),
    originalCompanyOrgType: originalCompanyOrgType()
});

export default connect(mapStateToProps)(withStyles(styles)(SideNav));