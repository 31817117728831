import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { actions as ScheduledReportsActions } from '../../reducers/scheduledReports';
import { types } from '../../reducers/scheduledReports';

class ScheduledReportsEditMenu extends Component {
	optionsStandard =
		[
			'Edit',
			'Delete',
			'Pause'
		];
	
	optionsStandardResume =
		[
			'Edit',
			'Delete',
			'Resume'
		];

	ITEM_HEIGHT = 48;
	state = {
		anchorEl: null,
	};

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleMenuItemClick = (option) => {
		this.handleClose();
		let modalData = {
			...this.props.row.original,
			page: this.props.page,
			pageSize: this.props.pageSize,
			currentSortColumn: this.props.currentSortColumn
		};
		
		switch (option) {
			case 'Edit':
				this.props.setCurrentModal(types.EDIT_SCHEDULED_REPORT, modalData);
				break;
			case 'Delete':
				this.props.setCurrentModal(types.DELETE_SCHEDULED_REPORT, modalData);
				break;
			case 'Pause':
				this.props.pauseScheduledReport(this.props.row.original, this.props.page, this.props.pageSize, this.props.currentSortColumn);
				break;
			case 'Resume':
				this.props.pauseScheduledReport(this.props.row.original, this.props.page, this.props.pageSize, this.props.currentSortColumn);
				break;
			default:
				return null;
		}
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div>
				<IconButton
					id={`scheduled-reports-action-menu-button-${this.props.row.index}`}
					aria-label="More"
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id={`scheduled-reports-action-menu-${this.props.row.index}`}
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
					PaperProps={{
						style: {
							maxHeight: this.ITEM_HEIGHT * 6.5,
							minWidth: 200,
						},
					}}
				>
					{this.props.row.original.paused ? 
						this.optionsStandardResume.map((option, index) => (
							<MenuItem id={`scheduled-reports-item-${this.props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => this.handleMenuItemClick(option, event)}>
								{option}
							</MenuItem>
						)) :
						this.optionsStandard.map((option, index) => (
							<MenuItem id={`scheduled-reports-item-${this.props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => this.handleMenuItemClick(option, event)}>
								{option}
							</MenuItem>
						))
					}
				</Menu>
			</div>
		);
	}
}

ScheduledReportsEditMenu.propTypes = {
	row: PropTypes.object,
	setCurrentModal: PropTypes.func
};


const mapDispatchToProps = dispatch => {
  return {
	  setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
	  pauseScheduledReport: (formData, page, pageSize, currentSortColumn) => dispatch(ScheduledReportsActions.pauseScheduledReport(formData, page, pageSize, currentSortColumn))
  }
};

export default connect(null, mapDispatchToProps)(ScheduledReportsEditMenu);
