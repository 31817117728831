import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HasAccess from '../../components/HasAccess/HasAccess';
import { connect } from 'react-redux';
import { actions as AlertsActions, types as AlertTypes } from '../../reducers/alerts';
import { actions as AppActions } from '../../reducers/app';
import AlertsTemplatesList from '../../containers/AlertsTemplatesList/AlertsTemplatesList';


function AlertsTemplates(props) {

    function modalHandleOpen() {
        props.setCurrentModal(AlertTypes.SELECT_ALERT_TEMPLATE);
    }

    return (
        <div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
            <HasAccess
                permissions={["ADD_ALERT_TEMPLATES"]}
            >
                <Button id="add-alert-template-button" className="button" variant="contained" color="primary" onClick={modalHandleOpen}>
                    Add Template
                </Button> 
            </HasAccess>
            </Grid>
            <br/>
            <AlertsTemplatesList />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
    }
};

export default connect(null,mapDispatchToProps)(AlertsTemplates);