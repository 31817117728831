/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';

export const DEVICE_USERS = 'DEVICE_USERS/'; 
export const types = {
    ADD_DEVICE_USER: DEVICE_USERS + 'ADD_DEVICE_USER',
    GET_DEVICE_USERS_REQUEST: DEVICE_USERS + 'GET_DEVICE_USERS_REQUEST',
    GET_DEVICE_USERS_SUCCESS: DEVICE_USERS + 'GET_DEVICE_USERS_SUCCESS',
    ADD_LOCATION: DEVICE_USERS + 'ADD_LOCATION',
    GET_LOCATIONS_REQUEST: DEVICE_USERS + 'GET_LOCATIONS',
    GET_LOCATIONS_SUCCESS: DEVICE_USERS + 'GET_LOCATIONS_SUCCESS',
    GET_LOCATIONS_ERROR: DEVICE_USERS + 'GET_LOCATIONS_ERROR',
    DELETE_LOCATION: DEVICE_USERS + 'DELETE_LOCATION',
    DELETE_DEVICE_USER_LOCATION: DEVICE_USERS + 'DELETE_DEVICE_USER_LOCATION',
    EDIT_DEVICE_USER: DEVICE_USERS + 'EDIT_DEVICE_USER',
    EDIT_DEVICE_USER_SUCCESS: DEVICE_USERS + 'EDIT_DEVICE_USER_SUCCESS',
    DELETE_DEVICE_USER: DEVICE_USERS + 'DELETE_DEVICE_USER',
    DELETE_DEVICE_USER_SUCCESS: DEVICE_USERS + 'DELETE_DEVICE_USER_SUCCESS',
    EDIT_LOCATION: DEVICE_USERS + 'EDIT_LOCATION',
    GET_DEVICE_USERS_DEVICES_REQUEST: DEVICE_USERS + 'GET_DEVICE_USERS_DEVICES_REQUEST',
    GET_DEVICE_USERS_DEVICES_SUCCESS: DEVICE_USERS + 'GET_DEVICE_USERS_DEVICES_SUCCESS',
    DELETE_DEVICE_USER_DEVICE: DEVICE_USERS + 'DELETE_DEVICE_USER_DEVICE',
    SHOW_HELD_REQUEST_MESSAGE: DEVICE_USERS + 'SHOW_HELD_REQUEST_MESSAGE',
    GET_DEVICE_USERS_ERROR: DEVICE_USERS + 'GET_DEVICE_USERS_ERROR',
    GET_DEVICE_USERS_DEVICES_ERROR: DEVICE_USERS + 'GET_DEVICE_USERS_DEVICES_ERROR',
    RESEND_MYE911_USER_WELCOME_EMAIL_REQUEST: DEVICE_USERS + 'RESEND_MYE911_USER_WELCOME_EMAIL_REQUEST',
    ALTERNATE_ID_DETAILS: DEVICE_USERS + 'ALTERNATE_ID_DETAILS',
    DEVICE_USERS_DEVICES_DETAILS: DEVICE_USERS + 'DEVICE_USERS_DEVICES_DETAILS'
};

export const initialState = fromJS({
    deviceUsersList: List(),
    deviceUsersLoading: false,
    deviceUsersDevicesList: Map(),
    locationsList: Map(),
    deviceUsersListPageCount: -1,
    deviceUsersLocationsListPageCount: -1,
    deviceUsersDevicesListPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_DEVICE_USERS_REQUEST:
            return state.set('deviceUsersLoading', true);
        case types.GET_DEVICE_USERS_ERROR:
            return state.set('deviceUsersLoading', false);
        case types.GET_DEVICE_USERS_SUCCESS:
            return state.set('deviceUsersLoading', false)
                        .set('deviceUsersList', List(action.data))
                        .set('deviceUsersListPageCount', action.pageCount);
        case types.GET_DEVICE_USERS_DEVICES_REQUEST:
            return state.setIn(['deviceUsersDevicesList', action.deviceUserId, 'loading'], true);
        case types.GET_DEVICE_USERS_DEVICES_ERROR:
            return state.setIn(['deviceUsersDevicesList', action.deviceUserId, 'loading'], false);
        case types.GET_DEVICE_USERS_DEVICES_SUCCESS:
        if (action.data === '') {
            action.data = [];
        }
        return state.setIn(['deviceUsersDevicesList', action.deviceUserId, 'list'], List(action.data))
                    .setIn(['deviceUsersDevicesList', action.deviceUserId, 'loading'], false)
                    .set('deviceUsersDevicesListPageCount', action.pageCount);
        case types.GET_LOCATIONS_REQUEST:
            return state.setIn(['locationsList', action.deviceUserId, 'loading'], true);
        case types.GET_LOCATIONS_SUCCESS:
            return state.setIn(['locationsList', action.deviceUserId, 'list'], List(action.locationsList))
                        .setIn(['locationsList', action.deviceUserId, 'loading'], false)
                        .set('deviceUsersLocationsListPageCount', action.pageCount);
        case types.GET_LOCATIONS_ERROR:
            return state.setIn(['locationsList', action.deviceUserId, 'loading'], false)	
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    addDeviceUserRequest: (deviceUser) => ({ 
            type: types.ADD_DEVICE_USER, 
            data: deviceUser
    }),
    editDeviceUserRequest: (deviceUser) => ({
        type: types.EDIT_DEVICE_USER,
        data: deviceUser
    }),
    deleteDeviceUserRequest: (id, parentId) => ({
        type: types.DELETE_DEVICE_USER,
        data: id,
        parentId
    }),
    deleteDeviceUserSuccess: () => ({
        type: types.DELETE_DEVICE_USER_SUCCESS
    }),
    getDeviceUsersRequest: (page, pageSize, searchTerm, sortBy, direction) => ({
        type: types.GET_DEVICE_USERS_REQUEST,
        page,
        pageSize,
        searchTerm,
        sortBy,
        direction
    }),
    getDeviceUsersError: () => ({
        type: types.GET_DEVICE_USERS_ERROR
    }),
    getDeviceUserSuccess: (deviceUserList, pageCount) => ({
        type: types.GET_DEVICE_USERS_SUCCESS,
        data: deviceUserList,
        pageCount
    }),
    getDeviceUsersDevicesRequest: (deviceUserId, page, pageSize, sortBy, direction) => ({
        type: types.GET_DEVICE_USERS_DEVICES_REQUEST,
        deviceUserId,
        page, 
        pageSize,
        sortBy,
        direction
    }),
    getDeviceUsersDevicesError: (deviceUserId) => ({
        type: types.GET_DEVICE_USERS_DEVICES_ERROR,
        deviceUserId
    }),
    getDeviceUserDevicesSuccess: (deviceUserList, deviceUserId, pageCount) => ({
        type: types.GET_DEVICE_USERS_DEVICES_SUCCESS,
        data: deviceUserList,
        deviceUserId,
        pageCount
    }),
    deleteDeviceUserDevicesRequest: (id, userId) => ({
        type: types.DELETE_DEVICE_USER_DEVICE,
        data: id,
        userId
    }),
    addLocationRequest: (location) => ({ 
        type: types.ADD_LOCATION, 
        data: location
    }),
    getLocationsRequest: (deviceUserId, page, pageSize, sortBy, direction) => ({
        type: types.GET_LOCATIONS_REQUEST,
        deviceUserId,
        page,
        pageSize,
        sortBy,
        direction
    }),
    getLocationsError: (deviceUserId) => ({
        type: types.GET_LOCATIONS_ERROR,
        deviceUserId
    }),
    getLocationsSuccess: (locationsList, deviceUserId, pageCount) => ({
        type: types.GET_LOCATIONS_SUCCESS,
        locationsList,
        deviceUserId,
        pageCount
    }),
    deleteLocationRequest: (locationId, deviceUserId) => ({
        type: types.DELETE_LOCATION,
        locationId,
        deviceUserId
    }),
    editLocationRequest: (location) => ({
        type: types.EDIT_LOCATION,
        data: location
    }),
    resendMyE911UserWelcomeEmail: (deviceUser) => ({
        type: types.RESEND_MYE911_USER_WELCOME_EMAIL_REQUEST,
        recipient: deviceUser.id,
        alertType: 'MYE911_USER_WELCOME_EMAIL'
    })
};

// Selectors
const selectDeviceUsers = state => state.get('deviceUsers', initialState);

export const makeSelectDeviceUsersList = () =>
    createSelector(selectDeviceUsers, deviceUsersState => deviceUsersState.get('deviceUsersList'));

export const makeSelectDeviceUsersListLoading = () =>
    createSelector(selectDeviceUsers, deviceUsersState => deviceUsersState.get('deviceUsersLoading'));
    
export const selectDeviceUsersDevices = createSelector(selectDeviceUsers, devicesState => devicesState.get('deviceUsersDevicesList'));
    
export const makeSelectDeviceUsersDevicesList = (parentId) =>
    createSelector(selectDeviceUsersDevices, deviceUsersDevicesState => deviceUsersDevicesState.getIn([parentId, 'list']));

export const makeSelectDeviceUsersDevicesListLoading = (parentId) =>
    createSelector(selectDeviceUsersDevices, deviceUsersDevicesState => deviceUsersDevicesState.getIn([parentId, 'loading']));

export const makeSelectDeviceUsersPageCount = () =>
    createSelector(selectDeviceUsers, deviceUsersState => deviceUsersState.get('deviceUsersListPageCount'));

export const makeSelectDeviceUsersLocationsPageCount = () =>
    createSelector(selectDeviceUsers, deviceUsersState => deviceUsersState.get('deviceUsersLocationsListPageCount'));

export const makeSelectDeviceUsersDevicesPageCount = () =>
    createSelector(selectDeviceUsers, deviceUsersState => deviceUsersState.get('deviceUsersDevicesListPageCount'));

export const selectLocationsList =
	createSelector(selectDeviceUsers, locationsState => locationsState.get('locationsList'));

export const selectLocationsByParentId = (parentId) => 
	createSelector(selectLocationsList, (locationsList) => locationsList.getIn([parentId, 'list']));

export const locationsLoading = (parentId) =>
    createSelector(selectLocationsList, (locationsList) => locationsList.getIn([parentId, 'loading']));
