function convertDateRange(reportType) {
    if (reportType === 'TODAY') return 'Today';
    else if (reportType === 'YESTERDAY') return 'Yesterday';
    else if (reportType === 'THIS_WEEK') return 'This Week';
    else if (reportType === 'LAST_WEEK') return 'Last Week';
    else if (reportType === 'THIS_MONTH') return 'This Month';
    else if (reportType === 'LAST_MONTH') return 'Last Month';
    else if (reportType === 'WITHIN_WEEK') return 'Within a Week';
    else if (reportType === 'WITHIN_TWO_WEEKS') return 'Within 2 Weeks';
    else if (reportType === 'WITHIN_MONTH') return 'Within a Month';
    else if (reportType === 'WITHIN_YEAR') return 'Within a Year';
    else return '';
}

export default convertDateRange;