import { useState, useEffect } from 'react';
import CompanyAPI from '../apis/companyApi';

/**
 * Custom hook that will manage stateful breadcrumb logic
 * Original Company is used to verify an org admins original login org type 
 * since we currently don't have multiple org admin types.
 */
export default function useBreadcrumbs(initialBreadCrumbPath, currentCompany, userRole, originalLoginCompany) {
    const [breadcrumbsPath, setBreadcrumbPath] = useState(initialBreadCrumbPath);
    const currentCompanyId = currentCompany.id;
    const originalOrgType = originalLoginCompany.toJS().orgType;
    const isSystem = originalLoginCompany.toJS().id === undefined;

    useEffect( () => {
        let orgList = [];
        // add system to top of list
        if(isSystem) {
            // system* (* is optional)
            orgList.push({id: null, name: 'System'});
        }

        if(currentCompany.orgType === 'SERVICE_PROVIDER') {
            // system* -> service provider
            orgList.push(currentCompany);
            setBreadcrumbPath(orgList);
        }
        else if(currentCompany.orgType === 'RESELLER') {
            if(originalOrgType === 'SERVICE_PROVIDER' || isSystem) {
                // system* -> service provider -> reseller
                CompanyAPI.getOrganizationById(currentCompany.parentId).then(response => {
                    const serviceProvider = response.data;
                    orgList.push(serviceProvider);
                    orgList.push(currentCompany);
                    setBreadcrumbPath(orgList);
                });
            }
            else {
                // reseller
                orgList.push(currentCompany);
                setBreadcrumbPath(orgList);
            }
        }
        else if(currentCompany.orgType === 'CUSTOMER') {
            if(originalOrgType === 'SERVICE_PROVIDER' || isSystem) {
                CompanyAPI.getOrganizationById(currentCompany.parentId).then(response => {
                    const parent = response.data;
                    if(parent.orgType === 'SERVICE_PROVIDER') { // system* -> service provider -> customer
                        orgList.push(parent);
                        orgList.push(currentCompany);
                        setBreadcrumbPath(orgList);
                    }
                    else { // system* -> service provider -> reseller -> customer
                        CompanyAPI.getOrganizationById(parent.parentId).then(response => {
                            const serviceProvider = response.data;
                            orgList.push(serviceProvider);
                            orgList.push(parent);
                            orgList.push(currentCompany);
                            setBreadcrumbPath(orgList);
                        });
                    }
                });
            }
            else if(originalOrgType === 'RESELLER') {
                // reseller -> customer
                CompanyAPI.getOrganizationById(currentCompany.parentId).then(response => {
                    const reseller = response.data;
                    orgList.push(reseller);
                    orgList.push(currentCompany);
                    setBreadcrumbPath(orgList);
                });
            }
            else {
                // customer
                orgList.push(currentCompany);
                setBreadcrumbPath(orgList);
            }
        }
        else {
            // system
            setBreadcrumbPath(orgList);
        }
    }, [currentCompanyId]) // only update this effect if current user company changes (CHANGE_SUBTENANT)

    return breadcrumbsPath;
}