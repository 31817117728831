import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MapIcon from '@material-ui/icons/Map';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

function AddressGeoCoordsToggle({ addressAndGeoCoordsToggle, setAddressAndGeoCoordsToggle }) {
    const handleAlignment = (event, newPosition) => {
        if (newPosition !== null) {
            if (newPosition === 'address') {
                setAddressAndGeoCoordsToggle('address');
            } else {
                setAddressAndGeoCoordsToggle('geoCoords');
            }
        }
      };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <ToggleButtonGroup
                    id="addressAndGeoCoords"
                    label="Address and Geodetic Coordinates"
                    value={addressAndGeoCoordsToggle}
                    style={{ display: 'inline-block' }}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="Address and Geodetic Coordinates Toggle"
                    selected={true}
                    >
                    <ToggleButton value="address" aria-label="left aligned">
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: 10 }}>Address</span>
                            <MapIcon />
                        </div>
                    </ToggleButton>
                    <ToggleButton value="geoCoords" aria-label="right aligned">
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: 10 }}>Geodetic Coordinates</span>
                            <MyLocationIcon />
                        </div>
                    </ToggleButton>
                </ToggleButtonGroup>
                <span style={{ position: 'relative', left: 10, top: 2 }}>
                    <Tooltip title="If the location or building possesses a physical street address, select the Address option. In cases where a location lacks a street address, such as a utility shed, opt for the Geodetic Coordinates option. Note that physical addresses will still be automatically geocoded upon entry even if the coordinates aren't manually entered here." placement="top-start">
                        <Info/>
                    </Tooltip>
                </span>
            </div>
    )
}

export default AddressGeoCoordsToggle;