import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import EonUsersAPI from '../apis/eonUsersApi';
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as EonUsersActions, types as EonUsers } from '../reducers/eonUsers';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';

function* getEonUsersList(action) {
  try {
    const companyId = yield select(userCompanyId());
    const response = yield call(
      EonUsersAPI.getEonUsersList,
      companyId,
      action.page,
      action.pageSize,
      action.searchTerm,
      action.sortBy,
      action.sortDirection
    );
    
    yield put(
      EonUsersActions.eonUsersLoaded(
        response.data,
        response.headers["x-pagination-count"]
      )
    );
  } catch (error) {
    yield put(EonUsersActions.eonUsersLoadingError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);

  }
}

function* addEonUser(action) {
  try {
    const tableProps = yield select(selectTableProps());

    yield put(AppActions.setModalLoading());
    yield call(EonUsersAPI.addEonUser, action.data);
    yield put(AppActions.setModalClose());
    yield put(EonUsersActions.getEonUsersRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
  } catch (error) {
    console.log(error);
    yield put(AppActions.setModalError(errorHandler(error)));
  }
}

function* editEonUser(action) {
  try {
    const tableProps = yield select(selectTableProps());

    yield put(AppActions.setModalLoading());
    yield call(EonUsersAPI.editEonUser, action.data, action.row);
    yield put(AppActions.setModalClose());
    yield put(EonUsersActions.getEonUsersRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
  } catch (error) {
    console.log(error);
    yield put(AppActions.setModalError(errorHandler(error)));
  }
}

function* deleteEonUser(action) {
  try {
    const tableProps = yield select(selectTableProps());

    yield put(AppActions.setModalLoading());
    yield call(EonUsersAPI.deleteEonUser, action.data.id);
    yield put(AppActions.setModalClose());
    yield put(EonUsersActions.getEonUsersRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
  } catch (error) {
    console.log(error);
    yield put(AppActions.setModalError(errorHandler(error)));
  }
}

function* rootEonUsersSaga() {
  yield all([
    yield takeLatest(EonUsers.GET_EON_USERS, getEonUsersList),
    yield takeLatest(EonUsers.ADD_EON_USER, addEonUser),
    yield takeLatest(EonUsers.EDIT_EON_USER, editEonUser),
    yield takeLatest(EonUsers.DELETE_EON_USER, deleteEonUser),
  ]);
}

export default rootEonUsersSaga;
