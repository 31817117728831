import React from "react";
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

function GeoCoordsComponent(props) {
    const { geoCordsAreRequired, values, errors, touched, handleChange, handleBlur, modalLoading, setFieldValue, setFieldTouched } = props;

    const handleClear = () => {
        setFieldValue('address', '');
        setFieldValue('houseNumber', '');
        setFieldValue('houseNumberExtension', '');
        setFieldValue('prefixDirection', '');
        setFieldValue('street', '');
        setFieldValue('streetType', '');
        setFieldValue('postDirection', '');
        setFieldValue('city', '');
        setFieldValue('stateProvince', '');
        setFieldValue('zip', '');
        setFieldValue('country', 'US');  
    };

    const handleLatChange = (values) => {
        if (values.value) {
            setFieldValue('latitude', values.value);
            handleClear();
        } else {
            setFieldValue('latitude', '');
        }
        setFieldTouched('latitude', true);
    }
    
    const handleLngChange = (values) => {
        if (values.value) {
            setFieldValue('longitude', values.value);
            handleClear();
        } else {
            setFieldValue('longitude', '');
        }
        setFieldTouched('longitude', true);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', maxWidth: 630 }}>
            <div style={{ marginRight: 20 }}>
                <NumberFormat 
                    autoComplete="no"
                    id="latitude"
                    label={geoCordsAreRequired ? 'Latitude' : '*Latitude'}
                    name="latitude"
                    type="text"
                    onValueChange={handleLatChange}
                    value={values.latitude}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={touched.latitude && Boolean(errors.latitude)}
                    customInput={TextField}
                    /> 
                    <span id="latitude-helper-text" className="error-prompt">{touched.latitude ? errors.latitude : ""}</span>
            </div>
            <div>
                <NumberFormat 
                    autoComplete="no"
                    id="longitude"
                    label={geoCordsAreRequired ? 'Longitude' : '*Longitude'}
                    name="longitude"
                    type="text"
                    onValueChange={handleLngChange}
                    value={values.longitude}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={touched.longitude && Boolean(errors.longitude)}
                    customInput={TextField}
                    /> 
                    <span id="longitude-helper-text" className="error-prompt">{touched.longitude ? errors.longitude : ""}</span>
            </div>
        </div>
    );
}

export default GeoCoordsComponent;