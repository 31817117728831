import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actions as CompanySummaryActions, makeSelectCompanySummaryObject, makeSelectCompanySummaryObjectLoading } from '../../../reducers/homepage';
import { selectUserCompany, currentCompanyOrgType, orgTypes, selectUser } from '../../../reducers/auth';
import { checkCustomPermission } from '../../../containers/App/routeAndNavData';
import './companySummaryListTable.css';
import { Link } from 'react-router-dom';
import HasAccess from '../../../components/HasAccess/HasAccess';


function CompanySummarySection(props) {
    useEffect(() => {
        props.fetchCompanySummaryList();
    }, [props.company.toJS().id])

    const {
        loading, companySummary, 
    } = props;
    let companySummaryObject;
    
    if (companySummary) {
        companySummaryObject = companySummary.toJS();
        
    }
    
    return (
        <div>
            <HasAccess
                permissions={["VIEW_ORGANIZATIONS", "VIEW_EON_USERS", "VIEW_DEVICE_USERS", "VIEW_LOCATIONS", "VIEW_HELD_DEVICES"]}
            >
                <h2>Summary</h2>
                <div className="company-summary-wrapper">
                    {companySummaryObject ? 
                        <ul>
                            {checkCustomPermission('CUSTOM_VIEW_ORGANIZATIONS', props.user) &&
                            <>
                                {props.currentCompanyOrgType !== orgTypes.CUSTOMER &&
                                    <li>
                                        <Link to="/organizations">
                                            <span id="subtenants-total-header">Customers</span>
                                            <span id="subtenants-total-value">{companySummaryObject.numOfSubTenants}</span>
                                        </Link>
                                    </li>
                                }
                                {props.currentCompanyOrgType !== orgTypes.CUSTOMER &&
                                    <li className="vertical-border"></li>
                                }
                            </>
                            }
                            {checkCustomPermission('CUSTOM_VIEW_ADMIN', props.user) &&
                            <>
                                <li>
                                    <Link to="/administrators">
                                        <span id="administrators-total-header">Administrators</span> 
                                        <span id="administrators-total-value">{companySummaryObject.numOfPortalUsers}</span>
                                    </Link>
                                </li>
                                <li className="vertical-border"></li>
                            </>
                            }
                            <HasAccess
                                permissions={["VIEW_EON_USERS"]}
                            >
                                {props.currentCompanyOrgType === orgTypes.CUSTOMER &&
                                    <li>
                                        <Link to="/eon-users">
                                            <span id="eon-users-total-header">EON Users</span> 
                                            <span id="eon-users-total-value">{companySummaryObject.numOfEonUsers}</span>
                                        </Link>
                                    </li>
                                }
                                {props.currentCompanyOrgType === orgTypes.CUSTOMER &&
                                    <li className="vertical-border"></li>
                                }
                            </HasAccess>
                            <HasAccess
                                permissions={["VIEW_DEVICE_USERS"]}
                            >
                                <li>
                                    {props.currentCompanyOrgType === orgTypes.CUSTOMER ? 
                                        <Link to="/users">
                                            <span id="users-total-header">Users</span> 
                                            <span id="users-total-value">{companySummaryObject.numOfDeviceUsers}</span>
                                        </Link>
                                        :
                                        <div>
                                            <span id="users-total-header">Users</span> 
                                            <span id="users-total-value">{companySummaryObject.numOfDeviceUsers}</span>
                                        </div>
                                    }
                                </li>
                                <li className="vertical-border"></li>
                            </HasAccess>
                            <HasAccess
                                permissions={["VIEW_LOCATIONS"]}
                            >
                                <li>
                                {props.currentCompanyOrgType === orgTypes.CUSTOMER ? 
                                    <Link to="/locations">
                                        <span id="number-of-locations-header">Locations</span> 
                                        <span id="number-of-locations-value">{companySummaryObject.numOfLocations}</span>
                                    </Link>
                                    :
                                    <div>
                                        <span id="number-of-locations-header">Locations</span> 
                                        <span id="number-of-locations-value">{companySummaryObject.numOfLocations}</span>
                                    </div>
                                }
                                </li>
                                <li className="vertical-border"></li>
                            </HasAccess>
                            <HasAccess
                                permissions={["VIEW_HELD_DEVICES"]}
                            >
                                <li>
                                {props.currentCompanyOrgType === orgTypes.CUSTOMER ? 
                                    <Link to="/held-devices">
                                        <span id="number-of-held-devices-header">HELD</span> 
                                        <span id="number-of-held-devices-value">{companySummaryObject.numOfHeldDevices}</span>
                                    </Link>
                                    :
                                    <div>
                                        <span id="number-of-held-devices-header">HELD</span> 
                                        <span id="number-of-held-devices-value">{companySummaryObject.numOfHeldDevices}</span>
                                    </div>
                                }
                                </li>
                                <li className="vertical-border"></li>
                            </HasAccess>
                            <HasAccess
                                permissions={["VIEW_PIDFLO_DEVICES"]}
                            >
                                <li>
                                {props.currentCompanyOrgType === orgTypes.CUSTOMER ? 
                                    <Link to="/pidflo-devices">
                                        <span id="number-of-pidflo-devices-header">PIDF-LO Devices</span> 
                                        <span id="number-of-pidflo-devices-value">{companySummaryObject.numOfPidfLoDevices}</span>
                                    </Link>
                                    :
                                    <div>
                                        <span id="number-of-pidflo-devices-header">PIDF-LO Devices</span> 
                                        <span id="number-of-pidflo-devices-value">{companySummaryObject.numOfPidfLoDevices}</span>
                                    </div>
                                }
                                </li>
                            </HasAccess>
                        </ul>
                        : ''
                    }
                    
                </div>
            </HasAccess>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    company: selectUserCompany(),
    companySummary: makeSelectCompanySummaryObject(),
    loading: makeSelectCompanySummaryObjectLoading(),
    currentCompanyOrgType: currentCompanyOrgType(),
    user: selectUser()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchCompanySummaryList: () => dispatch(CompanySummaryActions.getCompanySummaryRequest())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySummarySection);