import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import ScheduledReportsDetailBox from './ScheduledReportsDetailBox';
import { connect } from 'react-redux';
import {
    actions as ScheduledReportsActions,
    makeSelectScheduledReportsPageCount,
    makeSelectScheduledReportsList,
    makeSelectScheduledReportsListLoading
} from '../../reducers/scheduledReports';
import { actions as AppActions } from '../../reducers/app';
import { createStructuredSelector } from 'reselect';
import ScheduledReportsEditMenu from '../../containers/ScheduledReportsEditMenu/ScheduledReportsEditMenu';
import cronstrue from 'cronstrue';
import convertReportType from '../../utils/convertReportType';
import Chip from '@material-ui/core/Chip';
import { Tooltip } from '@material-ui/core';
import './ScheduledReportsTable.css';
  
function displayEditMenu(reportType) {
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
    const isviewUsers = user && user.permissions.includes('VIEW_DEVICE_USERS');
    const isviewCallHistory = user && user.permissions.includes('VIEW_CALL_HISTORY');
    const isviewHeldDevices = user && user.permissions.includes('VIEW_HELD_DEVICES');
    const isviewLicensing = user && user.permissions.includes('VIEW_LICENSING');
    const isviewNetworkDiscovery = user && user.permissions.includes('VIEW_NETWORK_DISCOVERY');
    const isviewLocations = user && user.permissions.includes('VIEW_LOCATIONS');

    return (reportType === 'DEVICE_USERS' && isviewUsers)  ||
    (reportType === 'CALL_HISTORY') ||
    (reportType === 'ECRC_CALL_HISTORY') ||
    (reportType === 'CALL_SUMMARY') ||
    (reportType === 'DEVICE_USERS_DEVICES' && isviewUsers)  ||
    (reportType === 'PIDF_LO' && isviewUsers)  ||
    (reportType === 'HELD_DEVICE' && isviewHeldDevices)  ||
    (reportType === 'BUILDING' && isviewLocations)  ||
    (reportType === 'LOCATION' && isviewLocations)  ||
    (reportType === 'CER_LOCATION' && isviewLocations) ||
    (reportType === 'HELD_DEVICES_NO_LOCATION' && isviewHeldDevices)  ||
    (reportType === 'HELD_PLUS_DEVICES_NO_LOCATION' && isviewHeldDevices)  ||
    (reportType === 'MYE911_USERS_NO_LOCATION' && isviewUsers)  ||
    (reportType === 'NETWORK_ELEMENTS_NO_LOCATION' && isviewNetworkDiscovery)  ||
    (reportType === 'BSSID' && isviewNetworkDiscovery)  ||
    (reportType === 'LLDP_CHASSIS' && isviewNetworkDiscovery)  ||
    (reportType === 'LLDP_PORT' && isviewNetworkDiscovery)  ||
    (reportType === 'IP_RANGE' && isviewNetworkDiscovery)  ||
    (reportType === 'MAC_ADDRESS' && isviewNetworkDiscovery)  ||
    (reportType === 'ENHANCED_NOTIFICATION_LICENSES')  ||
    (reportType === 'LOCATION_LICENSES' )  ||
    (reportType === 'USER_LICENSES') ||
    (reportType === 'EXPIRING_LICENSES');
}

const ScheduledReportsListTable = props => {
    const buildTableColumns = [
        {
            Header: "Report Schedule Name",
            sortable: true,
            id: 'name',
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: "Report Type",
            id: 'reportType',
            minWidth: 105,
            Cell: row => <div title={convertReportType(row.original.reportType)}>{convertReportType(row.original.reportType)}</div>
        },
        {
            Header: "Scheduling Information",
            id: 'scheduling-information',
            Cell: row => <div title={cronstrue.toString(row.original.schedule)}>{cronstrue.toString(row.original.schedule)}</div>
        },
        {
            Header: "Recipients",
            id: 'recipients',
            Cell: row => (
                row.original.recipientList.map((item, index) => <div key={index} title={item.emailRecipient}>{item.emailRecipient}{row.original.recipientList.length-1 === index ? '' : ','}</div>)
            )
        },
        {
            Header: "Status",
            id: 'status',
            width: 124,
             Cell: row => {
                if (row.original.paused) {
                    return <Tooltip 
                    title="This scheduled report is currently paused. If you would like it to resume, please select the 'Resume' action menu item."
                    >
                    <Chip label="Paused" style={{width: 100, backgroundColor:'#FFEB3B', color: 'black'}} />
                </Tooltip>
                } else {
                    if(row.original.status === 'NEW' || row.original.status === 'ACTIVE')
                        return <Tooltip 
                        title="This scheduled report was successfully created and will run at the next scheduled run time."
                        >
                            <Chip label="New" style={{width: 100, backgroundColor:'#038325', color: 'white'}} />
                        </Tooltip>
                    else if (row.original.status=== 'COMPLETE')
                        return <Tooltip 
                        title="The scheduled report executed successfully during the last scheduled run time."
                        >
                            <Chip label="Completed" style={{width: 100, backgroundColor:'#038325', color: 'white'}} />
                        </Tooltip>
                    else if (row.original.status === "ERROR")
                        return <Tooltip 
                                title="The system encountered an issue while processing the scheduled report and it could not complete. If the issue persists, please contact RedSky Support."
                            >
                                <Chip label="Error" style={{width: 100, backgroundColor: '#b10240', color: "white"}} />
                            </Tooltip>
                    else if (row.original.status === "WAITING" || row.original.status === "QUERYING" || row.original.status === "WRITING")
                        return <Tooltip 
                        title="This scheduled report is currently in the process of running. Please check back later."
                        >
                            <Chip label="In Progress" style={{width: 100, backgroundColor:'#1b546a', color: 'white'}} />
                        </Tooltip>
                }   
            }
        },
        {
            Header: "Details",
            id: 'details',
            maxWidth: 84,
            Cell: row => <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ScheduledReportsDetailBox row={row} />
                </div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => {
                return (
                    displayEditMenu(row.original.reportType) ? (
                 <ScheduledReportsEditMenu 
                    row={row} 
                    setCurrentModal={props.setCurrentModal} 
                    pageSize={props.pageSize} 
                    page={props.page}
                    currentSortColumn={props.currentSortColumn} />
                 ) : null
            )}
        }
    ];
    
    const {
        loading, scheduledReports, fetchScheduledReportsList, scheduledReportsPageCount, setCurrentModal
    } = props;
    let scheduledReportsArray = [];

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    if (scheduledReports) {
        scheduledReportsArray = scheduledReports.toArray();
    }

    useEffect(() => {
        if (scheduledReportsArray && scheduledReportsArray.length === 0 && props.page > 0 && scheduledReportsPageCount < props.page+1) {
            props.setPage(props.page-1);
        }
    }, [scheduledReportsArray && scheduledReportsArray.length === 0 && props.page > 0 && scheduledReportsPageCount < props.page+1]);

    // do initial api call, and any time sorting column changes, or pagination changes
    useEffect(() => {
        fetchScheduledReportsList(props.page+1, props.pageSize, props.currentSortColumn.id, props.currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: props.page+1, 
            pageSize: props.pageSize, 
            sortBy: props.currentSortColumn.id, 
            direction: props.currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [props.currentSortColumn, props.pageSize, props.page]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);
    
    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight scheduled-report-table"
            showPaginationBottom={true}
            data={scheduledReportsArray}
            minRows={scheduledReportsArray && scheduledReportsArray.length > 0 ? 0 : 5}
            page={props.page}
            pages={scheduledReportsPageCount <= 0 ? 1 : scheduledReportsPageCount}
            columns={buildTableColumns}
            LoadingComponent={ReactTableLoadingComponent}
            sorted={props.sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...props.sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    props.setCurrentSortColumn(currentSortColumn);
                    props.setSortColumns(sortedArray);
                })
                
            }}
            onPageSizeChange={(pageSize) => {
                props.setPage(0);
                props.setPageSize(pageSize);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setCurrentPage(pageIndex + 1);
                props.setPage(pageIndex);
                resetScrollInsideTable();
            }}
            defaultPageSize={25}
            manual
            scheduledReports={scheduledReports}
            setCurrentModal={setCurrentModal}
            loading={loading}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'scheduled-report-row-' + rowInfo.index,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

ScheduledReportsListTable.propTypes = {
    scheduledReports: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
    scheduledReportsPageCount: makeSelectScheduledReportsPageCount(),
    scheduledReports: makeSelectScheduledReportsList(),
    loading: makeSelectScheduledReportsListLoading()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchScheduledReportsList: (page, pageSize, sortBy, sortDirection) => dispatch(ScheduledReportsActions.getScheduledReportsRequest(page, pageSize, sortBy, sortDirection)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledReportsListTable);