import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HasAccess from '../../components/HasAccess/HasAccess';

import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSagas';

import reducer from '../../reducers/pstn';
import { types as AppTypes } from '../../reducers/app';
import { currentCompanyLevel } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import saga from '../../sagas/pstn';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as AppActions } from '../../reducers/app';
import { actions as PstnAction, types as pstnTypes } from '../../reducers/pstn';

import PstnTable from '../../containers/PstnTable/PstnTable';

function PstnPage(props) {
    function modalHandleOpen() {
        props.setCurrentModal(pstnTypes.ADD_PSTN);
    }

    useEffect(() => {
        props.setPageTitle('Call Routing - PSTN');
        props.setPageTitleId('call-routing-pstn-header');
    }, []);

    return (
        <div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                    <HasAccess
                        permissions={["ADD_CALL_ROUTING"]}
                    >
                        {props.currentCompanyLevel !== AppTypes.CURRENT_COMPANY_LEVEL_SYSTEM && <Button className="button" id="btnAddPstn" variant="contained" color="primary" onClick={modalHandleOpen} >
                            Add DID
                        </Button>
                        }
                    </HasAccess>
                </Grid>
            </Grid>
            <br/>
            <PstnTable setCurrentModal={props.setCurrentModal} 
                            fetchPstn={props.fetchPstn} />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel()
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchPstn: () => dispatch(PstnAction.getPstnRequest()),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'pstn', reducer });
const withSaga = injectSaga({ key: 'pstn', saga });
const withConnect = connect(mapStateToProps,mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(PstnPage);