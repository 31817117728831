import React, { useState, useEffect } from "react";

import { compose } from 'redux';

import { createStructuredSelector } from "reselect";
import { actions as AppActions, cirrusVersion } from "../../reducers/app";
import { connect } from "react-redux";
import { selectUser } from "../../reducers/auth";
import moment from 'moment';

function MyAccount(props) {
	const currentYear = moment().year();

	useEffect(() => {
        props.setPageTitle('About');
        props.setPageTitleId('about-header');
    }, []);

	return (
		<div>
			<p>RedSky helps organizations of all types capture, manage and deliver the detailed location information necessary to provide effective 911 emergency response.</p>
			<p>RedSky has been successfully delivering E911 software solutions to large enterprises and government facilities since 1999. More than 250 customers, including 45 of the Fortune 500, rely on RedSky solutions to automatically manage and deliver accurate E911 location information.</p>
			<p>Version: {props.cirrusVersion ? props.cirrusVersion : 'latest'}</p>
			<p>RedSky Technologies, Inc. Copyright © 1998-{currentYear}. RedSky Technologies, Inc. All rights reserved.</p>
		</div>
	);
}


const mapStateToProps = () => createStructuredSelector({
	cirrusVersion: cirrusVersion(),
});

const mapDispatchToProps = dispatch => {
	return {
		setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
		setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
	}
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
) (MyAccount);
