import React, { useState, useEffect, useContext, useRef } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {  
    modalErrorDisplay, 
    modalErrorMessage, actions as AppActions } from '../../reducers/app';
import { userCompanyId, selectUserCompany } from '../../reducers/auth';
import errorHandler from '../../utils/errorHandler';

import ModalErrorMessage from '../../components/ModalWrapper/ModalErrorMessage';
import EmergencyCallAssistForm from './emergencyCallAssistForm';
import EmergencyCallAssistAPI from '../../apis/emergencyCallAssistApi';
import { LocationContext } from '../../components/Contexts/LocationContext';
import { ECAValidation } from './Validation';
import EmergencyCallAssistModal from './emergencyCallAssistModal';

const styles = theme => ({
    modal: {
        width: '500px',
        height: '230px',
        position: 'absolute',
        top: '300px',
        left: 'calc(50% + 160px)',
        transform: 'translate(-50%)'
    },
    btn: {
        marginTop: '10px'
    },
    error: {
        color: 'green'
    }
});

function EmergencyCallAssistPage(props) {
    const { modalLoading, 
            classes, 
            modalErrorDisplay, 
            modalErrorMessage 
        } = props;

    const requiredInfo = props.company.toJS().requireLocationInfo;

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [geoCordsAreRequired, setGeoCordsAreRequired] = useState(true);
    const [addressIsRequired, setAddressIsRequired] = useState(true);
    const [addressAndGeoCoordsToggle, setAddressAndGeoCoordsToggle] = useState('address');
    const [locationOrTelURI, setLocationOrTelURI] = useState('');
    const [formLoading, setFormLoading] = useState(false);
    const [modalState, setModalState] = useState('');
    const [copyToClipboardFailed, setCopyToClipboardFailed] = useState(false);

    const { multipleAddressList,
            setMultipleAddressList,
            addressIsAmbiguous,
            setAddressIsAmbiguous,
            addressIsAlternate,
            setAddressIsAlternate
        } = useContext(LocationContext);

    const formRef = useRef();
    
    function handleClose(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        props.setPageTitle('Emergency Call Assist');
        props.setPageTitleId('emergency-call-assist-header');

        return () => {
            setAddressIsAlternate(false);
            setAddressIsAmbiguous(false);
            props.setModalErrorClear();
        }
    }, []);

    useEffect(() => {
        if (locationOrTelURI) {
            if (locationOrTelURI.includes('tel:')) {
                const formattedPhoneNumber = locationOrTelURI.replace(/\D+/g, '')
                    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                setLocationOrTelURI(formattedPhoneNumber);
            }
        }
    }, [locationOrTelURI]);


    function submitEmergencyCallAssistForm(values) {
        setFormLoading(true);
        EmergencyCallAssistAPI.emergencyCallAssist(values, addressIsAmbiguous, addressIsAlternate).then(response => {
            const locationURI = response.data.locationRef.locationURI;
            const telURI = response.data.locationRef.telURI;
            const locationOrTel = locationURI ? locationURI : telURI;

            if (response.data.ambiguousAddress) {
                setModalState('ambiguous');
                setLocationOrTelURI(locationOrTel);
                setFormLoading(false);
                setAddressIsAmbiguous(true);
                setMultipleAddressList(response.data.suggestedAddress);
            }
            else if (!response.data.provisionedAddress) {
                setModalState('badAddress');
                setLocationOrTelURI(locationOrTel);
                setFormLoading(false);
            } 
            else {
                // Success
                setModalState('success');
                setLocationOrTelURI(locationOrTel);
                setFormLoading(false);
                setAddressIsAmbiguous(false);
                setAddressIsAlternate(false);
                props.setModalErrorClear();
            }
        }).catch(error => {
            setFormLoading(false);
            props.setModalError(errorHandler(error));
        });
    }

    return (
        <div className="emergency-call-assist">
            <br/>
            <EmergencyCallAssistModal 
                modalState={modalState} 
                setModalState={setModalState}
                locationOrTelURI={locationOrTelURI}
                setLocationOrTelURI={setLocationOrTelURI}
                resetForm={formRef.current && formRef.current.resetForm}
                setCopyToClipboardFailed={setCopyToClipboardFailed}
                setSnackbarOpen={setSnackbarOpen}
                setFieldValue={formRef.current && formRef.current.setFieldValue}
                copyToClipboardFailed={copyToClipboardFailed}
                handleClose={handleClose}
                snackbarOpen={snackbarOpen}
            />
            {modalErrorDisplay && <ModalErrorMessage modalErrorMessage={modalErrorMessage}/> }
            {formLoading && <LinearProgress /> }
            <Formik
                innerRef={formRef}
                initialValues={{ 
                    houseNumber: '',
                    houseNumberExtension: '',
                    prefixDirection: '',
                    street: '',
                    streetType: '',
                    postDirection: '',
                    city: '',
                    stateProvince: '',
                    zip: '',
                    country: 'US',
                    address: '',
                    name: '',
                    info: '',
                    multipleAddressSelect: '',
                    latitude: '',
                    longitude: '',
                    callbackNumber: '',
                    callType: 'EMERGENCY',
                    routeType: 'PSTN',
                    agentInfo: ''
                }}
                validationSchema={ECAValidation(addressIsAlternate, addressIsAmbiguous, formRef.current && formRef.current.values.multipleAddressSelect, geoCordsAreRequired, addressIsRequired, requiredInfo)}
                onSubmit={(values) => {
                    values.companyId = props.companyId;

                    if (typeof values.multipleAddressSelect === 'string' && values.multipleAddressSelect.length >= 1) {
                        values.address = JSON.parse(values.multipleAddressSelect);
                    }
                    submitEmergencyCallAssistForm(values);
                    values.address = JSON.parse(values.multipleAddressSelect).normalizedAddress;
                }}
            >
                {formikProps => (
                    <EmergencyCallAssistForm
                        {...formikProps} 
                        {...props}
                        geoCordsAreRequired={geoCordsAreRequired}
                        setGeoCordsAreRequired={setGeoCordsAreRequired}
                        addressIsRequired={addressIsRequired}
                        setAddressIsRequired={setAddressIsRequired}
                        multipleAddressList={multipleAddressList}
                        addressIsAmbiguous={addressIsAmbiguous}
                        addressIsAlternate={addressIsAlternate}
                        setAddressIsAlternate={setAddressIsAlternate}
                        addressAndGeoCoordsToggle={addressAndGeoCoordsToggle}
                        setAddressAndGeoCoordsToggle={setAddressAndGeoCoordsToggle}
                        companyId={props.companyId} 
                        requiredInfo={requiredInfo}
                        classes={classes} 
                        modalLoading={modalLoading} 
                    />
                )}
            </Formik>
        </div> 
    );
}

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
    company: selectUserCompany(),
    modalErrorDisplay: modalErrorDisplay(),
    modalErrorMessage: modalErrorMessage(),
});

const mapDispatchToProps = dispatch => {
    return {
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        setModalError: error => dispatch(AppActions.setModalError(error)),
        setModalErrorClear: () => dispatch(AppActions.setModalErrorClear())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(EmergencyCallAssistPage);