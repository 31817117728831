import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import AddressManagementAPI from '../apis/addressManagementApi';
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as AddressManagementActions,
		 types as AddressManagementTypes } from '../reducers/addressManagement';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';

function* getAddressManagementList(action) {
	try {
		const response = yield call(AddressManagementAPI.getAddressManagement, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction);
		
		yield put(AddressManagementActions.getAddressManagementSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(AddressManagementActions.getAddressManagementError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addressManagementRescan(action) {
	try {
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(AddressManagementAPI.addressManagementRescan, action.data);
		yield put(AppActions.setModalClose());
		yield put(AddressManagementActions.getAddressManagementRequest(tableProps.page, tableProps.pageSize, tableProps.searchTerm, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootAddressManagementSaga() {
	yield all([
		yield takeLatest(AddressManagementTypes.GET_ADDRESS_MANAGEMENT_REQUEST, getAddressManagementList),
		yield takeLatest(AddressManagementTypes.ADDRESS_MANAGEMENT_RESCAN, addressManagementRescan)
	]);
}

export default rootAddressManagementSaga;
