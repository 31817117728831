import { createMuiTheme, createStyles } from '@material-ui/core/styles';

/**
 * Used to update the color of the input label for error messages
 * and the bottom border lines
 */
export const Five9Theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    overrides: {
        MuiInputLabel: createStyles({
            root: {
                '&$error': {
                    color: 'rgba(255, 41, 52, 1.0)'
                }
            }
        }),
        MuiInput: createStyles({
            root: {
                '&$underline:after': {
                    borderBottomColor: 'rgba(255, 41, 52, 1.0)'
                }
            }
        }),
        MuiButton: createStyles({
            root: {
                '&$containedSecondary': {
                    background: 'rgba(255, 41, 52, 1.0)'
                },
                '&$disabled$containedPrimary': {
                    background: 'rgba(0, 0, 0, 0.12)'
                }
            }
        }),
    }
});