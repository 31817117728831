import React, { useEffect, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions, selectAddressIsAlternate } from '../../../reducers/locations';
import { selectUserCompany } from '../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import SimpleTreeTypeFormCER from './forms/SimpleTreeTypeFormCER';
import { LocationValidation } from './Validations';


const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    icon: {
        position: 'absolute',
        right: '19px',
        bottom: '82px'
    },
    location: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    },
    autoCompleteOptions: {
        width: '355px'
    },
    paper: {
        position: 'absolute',
        zIndex: 9999,
        marginTop: theme.spacing.unit,
    }
});

 const EditLocationCERForm = props => {
         return <SimpleTreeTypeFormCER {...props}/>
}

const EditLocationCERModal = props => {
    const { modalLoading, classes, modalData } = props;
    const requiredInfo = props.company.toJS().requireLocationInfo;
    const locationTreeType = props.company.toJS().locationTreeType;
    const [overrideOrganizationNameToggle, setOverrideOrganizationNameToggle] = useState(getInitialOrgNameOverrideState(modalData));
    const [orgName, setOrgName] = useState(getInitialOrgNameState(modalData));

    useEffect(() => {
        return () => {
            props.addressIsAlternate(false);
        }
    }, []);

    function getInitialOrgNameState(modalData){
        if(modalData.orgNameOverriddenByBuilding){
            return null;
        }
        else {
           return modalData.orgNameOverride;
        }
    }

    function getInitialOrgNameOverrideState(modalData){
        if(modalData.orgNameOverriddenByBuilding){
            return false;

        }
        else if(!modalData.orgNameOverriddenByBuilding && modalData.orgNameOverride == null){
            return false;
        }
        else{
            return true;
        }
    }

    function displayLatitude(modalData) {
        if (modalData.geoCoords && modalData.geoCoords.latitude) {
            return modalData.geoCoords.latitude;
        } else if (modalData.address && modalData.address.latitude) {
            return modalData.address.latitude;
        } else {
            return '';
        }
    }
    
    function displayLongitude(modalData) {
        if (modalData.geoCoords && modalData.geoCoords.longitude) {
            return modalData.geoCoords.longitude;
        } else if (modalData.address && modalData.address.longitude) {
            return modalData.address.longitude;
        } else {
            return '';
        }
    }

    return(
        <Formik
            initialValues={{ 
                houseNumber: modalData.address.houseNumber ?  modalData.address.houseNumber : '',
                houseNumberExtension: modalData.address.houseNumberExtension ? modalData.address.houseNumberExtension : '',
                prefixDirection: modalData.address.prefixDirectional ? modalData.address.prefixDirectional : '',
                streetType: modalData.address.streetType ? modalData.address.streetType : '',
                postDirection: modalData.address.postDirectional ? modalData.address.postDirectional : '',
                street: modalData.address.streetName,
                city: modalData.address.city,
                stateProvince: modalData.address.state,
                zip: modalData.address.zipCode,
                country: modalData.address.country === 'USA' || modalData.address.country === 'US' ? 'US' : 'CA',
                supplemental: modalData.supplementalData ? modalData.supplementalData : '',
                name: modalData.name,
                phoneNumber: modalData.elin ? modalData.elin.phoneNumber : '',
                linkName: modalData.htmlLinkName,
                linkUrl: modalData.htmlLinkUrl,
                address: modalData.address.normalizedAddress,
                latitude: displayLatitude(modalData),
                longitude: displayLongitude(modalData),
                info: modalData.info,
                multipleAddressSelect: '',
                overrideOrganizationName: orgName ? orgName : '',
                alternateId: modalData.alternateId ? modalData.alternateId : '',
                callbackNumber: modalData.callbackNumber ? modalData.callbackNumber : ''
            }}
            enableReinitialize={true}
            validate= { (values) => {
                // Only do this if you need to access the value from the form to make something required
                const validationSchema =  LocationValidation(props.isAlternate, requiredInfo, locationTreeType, props.locationAlternateIdEnabled, values);
                try {
                    validateYupSchema(values, validationSchema, true, {});
                } catch (err) {
                    return yupToFormErrors(err);
                }
                return {}
            }}
            onSubmit = {
                (values) => {
                    if (values.multipleAddressSelect) {
                        values.multipleAddressSelect = JSON.parse(values.multipleAddressSelect);
                    }
                    
                    values.id = modalData.id;
                    values.parentId = modalData.parentId;
                    if(!overrideOrganizationNameToggle) {
                        values.overrideOrganizationName = undefined;
                    }
                     values.fullAddress = values.address;
                     if (values.address) {
                        if (modalData.address != null && (values.address === modalData.address.normalizedAddress)) {
                           props.addressIsAlternate(true);
                        }
                    }
                    props.submitEditLocationForm(values, props.modalData.origin);
                }
            }
            render={formikProps => <EditLocationCERForm 
                                        {...formikProps} 
                                        {...props}
                                        classes={classes} 
                                        modalLoading={modalLoading} 
                                        overrideOrganizationNameToggle={overrideOrganizationNameToggle}  
                                        setOverrideOrganizationNameToggle={setOverrideOrganizationNameToggle}	
                                        modalErrorOptions={props.modalErrorOptions}		
                                        />
            }
        />
    );
}

const mapStateToProps = ()  => createStructuredSelector({
    company: selectUserCompany(),
    isAlternate: selectAddressIsAlternate()
});

const mapDispatchToProps = dispatch => {
    return {
        submitEditLocationForm: (location, origin) => dispatch(actions.editLocationRequest(location, null, origin)),
        addressIsAlternate: (flag) => dispatch(actions.addressIsAlternate(flag))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(EditLocationCERModal);