import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import AddressManagementEditMenu from '../../containers/AddressManagementEditMenu/AddressManagementEditMenu';
import { createStructuredSelector } from 'reselect';
import { actions as AppActions, selectPageSize } from '../../reducers/app';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';
import moment from 'moment';
import { currentCompanyOrgType, orgTypes } from '../../reducers/auth';

import { makeSelectAddressManagementList, makeSelectAddressManagementListLoading, makeSelectAddressManagementPageCount, actions as AddressManagementActions } from '../../reducers/addressManagement';

function AddressManagementTable(props) {
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isRescan = user && user.permissions.includes('RESCAN_ADDRESS');

    const { addressManagementFromApi, 
            loading,
            addressManagementPageCount,
            fetchAddressManagementList } = props;

    function formatRevalidationTime(revalidationTime) {
        return revalidationTime ? moment(revalidationTime).format('MM/DD/YYYY hh:mm:ss A') : '';
    }
    
    const columns = [];
    
    columns.push({
        Header: "Normalized Address",
        id: "normalizedAddress",
        Cell: row => <div title={row.original.normalizedAddress}>{row.original.normalizedAddress}</div>
    });
    columns.push({
        Header: "Default Lat/Long",
        id: "defaultLatLong",
        Cell: row => <div title={`${row.original.latitude}, ${row.original.longitude}`}>{`${row.original.latitude}, ${row.original.longitude}`}</div>
    });
    columns.push({
        Header: "Backend Provider",
        id: "backendProvider",
        Cell: row => <div title={row.original.backendProvider}>{row.original.backendProvider}</div>
    });
    columns.push({
        Header: "Usage Count",
        id: "usageCount",
        minWidth: 75,
        Cell: row => <div title={row.original.usageCount}>{row.original.usageCount}</div>
    });
    columns.push({
        Header: "Last Rescan Date",
        id: "revalidationTime",
        sortable: true,
        Cell: row => <div title={formatRevalidationTime(row.original.revalidationTime)}>{formatRevalidationTime(row.original.revalidationTime)}</div>
    });
    columns.push({
        Header: "Last Rescan Status",
        id: "lastRescanStatus",
        Cell: row => <div title={row.original.revalidationStatus}>{row.original.revalidationStatus}</div>
    });
    columns.push({
        Header: '',
        width: 65,
        id: 'edit-menu',
        Cell: row => (
            isRescan ? <AddressManagementEditMenu row={row}/> : null
        )
    });

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }
    
    let addressManagement = addressManagementFromApi;
    if(addressManagement)
        addressManagement = addressManagement.toArray();
    
        useEffect(() => {
            if (addressManagement && addressManagement.length === 0 && props.page > 0 && addressManagementPageCount < props.page+1) {
              props.setPage(props.page-1)
            }
        }, [addressManagement && addressManagement.length === 0 && props.page > 0 && addressManagementPageCount < props.page+1]);
          
        useEffect( () => {
            props.setPageSize(25);
        }, []);
        
        useEffect( () => {
            props.fetchAddressManagement(props.page + 1, props.pageSize, props.searchValue, props.currentSortColumn.id, props.currentSortColumn.desc ? 'DESC': 'ASC');
            props.setTableProps({
            page: props.page+1,
            pageSize: props.pageSize,
            sortBy: props.currentSortColumn.id,
            direction: props.currentSortColumn.desc ? 'DESC': 'ASC'
        });
        }, [props.currentSortColumn, props.pageSize, props.page]);
          

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <div>
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight"
                showPaginationBottom={true}
                data={addressManagement}
                page={props.page}
                pages={addressManagementPageCount <= 0 ? 1 : addressManagementPageCount}
                minRows={addressManagement && addressManagement.length > 0 ? 0 : 5}
                columns={columns}
                loading={loading}
                sorted={props.sortColumns}
                onSortedChange={(newSorted, column, shiftKey) => {
                    let sortedArray = [...props.sortColumns];
                    let currentSortColumn;
                    sortedArray.forEach( (columnObject, index) => {
                        if(column.id === columnObject.id) {
                            // state needs updated object reference, doesn't detect nested fields
                            currentSortColumn = {...columnObject};
                            currentSortColumn.desc = !currentSortColumn.desc;
                            sortedArray[index] = currentSortColumn;
                        }
                        else {
                            let resetColumn = {...columnObject};
                            resetColumn.desc = false;
                            sortedArray[index] = resetColumn;
                        }
                        props.setCurrentSortColumn(currentSortColumn);
                        props.setSortColumns(sortedArray);
                    })
          
                }}
                onPageSizeChange={pageSize => {
                    props.setPage(0);
                    props.setPageSize(pageSize);
                    resetScrollInsideTable();
                }}
                onPageChange={(pageIndex) => {
                    props.setCurrentPage(pageIndex + 1);
                    props.setPage(pageIndex);
                    resetScrollInsideTable();
                }}
                defaultPageSize={25}
                manual
                LoadingComponent={ReactTableLoadingComponent}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'addressManagement-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                } />
        </div>
    );

}

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({
        addressManagementFromApi: makeSelectAddressManagementList(),
        loading: makeSelectAddressManagementListLoading(),
        addressManagementPageCount: makeSelectAddressManagementPageCount(),
        pageSize: selectPageSize(),
        currentCompanyOrgType: currentCompanyOrgType()
    });
}

const mapDispatchToProps = dispatch => {
    return {
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressManagementTable);
    