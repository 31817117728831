import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HasAccess from "../../components/HasAccess/HasAccess";

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BssidListTable from '../../containers/BssidListTable/bssidListTable';

import { actions as BssidActions, makeSelectBssidList, makeSelectBssidListLoading } from '../../reducers/networkDiscovery';

import { types as bssidTypes } from '../../reducers/networkDiscovery';
import { actions as AppActions } from '../../reducers/app';

function BssidList(props) {
    const [page, setPage] = useState(0);
    
    function modalHandleOpen() {
        props.setCurrentModal(bssidTypes.ADD_BSSID);
    }

        return (
            <div>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <HasAccess
                        permissions={["ADD_NETWORK_DISCOVERY"]}
                    >
                        <Button className="button" id="btnAddBuilding" variant="contained" color="primary" onClick={modalHandleOpen}>
                            Add BSSID Mapping
                        </Button> 
                    </HasAccess>
                </Grid>
                <br />
                <BssidListTable 
                    bssidList={props.bssidList} 
                    setCurrentModal={props.setCurrentModal} 
                    loading={props.loading} 
                    setPage={setPage}
                    page={page}
                    />
            </div>    
        );
}

BssidList.propTypes = {
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    bssidList: makeSelectBssidList(),
    loading: makeSelectBssidListLoading()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchBssidList: (page, pageSize) => dispatch(BssidActions.getBssidRequest(page, pageSize)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BssidList);
