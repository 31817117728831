import React, { useEffect, useRef } from "react";
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import "react-table/react-table.css";
import UserEditMenu from '../../containers/UserEditMenu/userEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import { createStructuredSelector } from "reselect";
import { ReactComponent as HealthAndSafetyIcon } from '../../assets/health_and_safety.svg';
import { selectUserId } from '../../reducers/auth';
import moment from "moment";
import './usersTable.css';

import { connect } from "react-redux";
import {
  makeSelectUsersList,
  makeSelectUsersListLoading,
  actions as UserActions
} from '../../reducers/users';

import { userCompanyId } from '../../reducers/auth';

import { makeSelectUsersPageCount } from "../../reducers/users";
import { actions as AppActions } from '../../reducers/app';

const UsersTable = props => {
  const usersTableColumns = [
    {
      Header: <div id="administrators-email">Email</div>,
      id: "email",
      sortable: true,
      Cell: row => <div title={row.original.email}>{row.original.email}</div>

    },
    {
      Header: <div id="administrators-first-name">First Name</div>,
      id: "first-name",
      Cell: row => <div title={row.original.firstName}>{row.original.firstName}</div>
    },
    {
      Header: <div id="administrators-last-name">Last Name</div>,
      id: "last-name",
      Cell: row => <div title={row.original.lastName}>{row.original.lastName}</div>
    },
    {
      Header: <div id="administrators-role">Role</div>,
      id: "role",
      minWidth: 150,
      Cell: row => <div title={row.original.role.displayName}>{row.original.role.displayName}
                    {row.original.eonUser === true ? <HealthAndSafetyIcon title="EON User" className="eonIcon"/> : ""}
                   </div>
    },
    {
      Header: <div id="administrators-locked">Locked / Deactivated</div>,
      id: "locked",
      Cell: row => <div title={row.original.locked}>{row.original.locked}</div>
    },
    {
      Header: <div id="administrators-last-login-time">Last Login Time</div>,
      id: "last-login-time",
      Cell: row => <div title={formatLastAccess(row.original.lastLogin)}>{formatLastAccess(row.original.lastLogin)}</div>
    },
    {
      Header: "",
      width: 65,
      id: "edit-menu",
      Cell: row => (
        <UserEditMenu row={row}
                      setCurrentModal={props.setCurrentModal}
                      userId={props.userId}
                      user={props.user}
                      checkCustomPermission={props.checkCustomPermission}
                      pageSize={props.pageSize}
                      page={props.page}
                      currentSortColumn={props.currentSortColumn} />
      )
    }
  ];

  function formatLastAccess(callTime) {
    return callTime ? moment(callTime).format("MM/DD/YYYY hh:mm:ss A") : "";
  }

  const { loading, users, usersPageCount, fetchUsersList} = props;
  const update = useRef(true);
  let usersArray = [];

  if (users) {
    usersArray = users.toArray();
}

useEffect(() => {
  if (usersArray && usersArray.length === 0 && props.page > 0 && usersPageCount < props.page+1) {
    props.setPage(props.page-1)
  }
}, [usersArray && usersArray.length === 0 && props.page > 0 && usersPageCount < props.page+1]);

  useEffect( () => {
    props.setPageSize(25);
    props.setCurrentPage(1);
  }, []);

  useEffect( () => {
    fetchUsersList(props.page + 1, props.pageSize, props.searchValue, props.currentSortColumn.id, props.currentSortColumn.desc ? 'DESC': 'ASC');
    props.setTableProps({
      page: props.page+1,
      pageSize: props.pageSize,
      sortBy: props.currentSortColumn.id,
      direction: props.currentSortColumn.desc ? 'DESC': 'ASC'
  });
  }, [props.currentSortColumn, props.pageSize, props.page]);

  function resetScrollInsideTable() {
    let tableBody = document.querySelector('.rt-tbody');
    tableBody.scrollTop = 0;
  }

  useEffect(() => {
    const tableBody = document.querySelector('.rt-tbody');
    const table = document.querySelector('.ReactTable');

    if (tableBody.scrollHeight > tableBody.clientHeight) {
        table.classList.add('scrollable');
    } else {
        table.classList.remove('scrollable');
    }
}, [loading]);

  return (
    <ReactTable
      resizable={false}
      sortable={false}
      className="-striped -highlight"
      showPaginationBottom={true}
      data={usersArray}
      minRows={usersArray && usersArray.length > 0 ? 0 : 5}
      page={props.page}
      pages={usersPageCount <= 0 ? 1 : usersPageCount}
      columns={usersTableColumns}
      LoadingComponent={ReactTableLoadingComponent}
      sorted={props.sortColumns}
      onSortedChange={(newSorted, column, shiftKey) => {
          let sortedArray = [...props.sortColumns];
          let currentSortColumn;
          sortedArray.forEach( (columnObject, index) => {
              if(column.id === columnObject.id) {
                  // state needs updated object reference, doesn't detect nested fields
                  currentSortColumn = {...columnObject};
                  currentSortColumn.desc = !currentSortColumn.desc;
                  sortedArray[index] = currentSortColumn;
              }
              else {
                  let resetColumn = {...columnObject};
                  resetColumn.desc = false;
                  sortedArray[index] = resetColumn;
              }
              props.setCurrentSortColumn(currentSortColumn);
              props.setSortColumns(sortedArray);
          })

      }}
      onPageSizeChange={pageSize => {
        props.setPage(0);
        props.setPageSize(pageSize);
        resetScrollInsideTable();
      }}
      onPageChange={(pageIndex) => {
          props.setCurrentPage(pageIndex + 1);
          props.setPage(pageIndex);
          resetScrollInsideTable();
      }}
      defaultPageSize={25}
      manual
      users={users}
      loading={loading}
      getTrProps={
        (state, rowInfo, column) => {
            if(rowInfo) {
                return {
                    id: 'administrators-row-' + rowInfo.index,
                };
            } else {
              return {};
            }
        }
      }
    />
  );
};

UsersTable.propTypes = {
  users: ImmutablePropTypes.list,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  users: makeSelectUsersList(),
  loading: makeSelectUsersListLoading(),
  companyId: userCompanyId(),
  userId: selectUserId(),
  usersPageCount: makeSelectUsersPageCount()
});

const mapDispatchToProps = dispatch => {
  return {
      fetchUsersList: (page, pageSize, searchValue, sortBy, sortDirection) => dispatch(UserActions.getUsersRequest(page, pageSize, searchValue, sortBy, sortDirection)),
      setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
      setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
      setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
