import * as Yup from "yup";

export const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    paper: {
        position: 'absolute',
        zIndex: 999,
        marginTop: theme.spacing.unit,
    },
    label: {
        padding: '0 10px 0 0'
    }
});

export const validationSchema = (props, identificationTypeOption, deviceUserIds, formikValues) => {
    return (
        Yup.object({
            alternateId: (formikValues && formikValues.enableMyE911Functionality) && (deviceUserIds.length === 0) && (props.alternateDeviceUserIdEnabled && identificationTypeOption === 'DEVICE_USER_ID') ? 
                Yup.string("Enter a phone number")
                .trim()
                .required("At least one Device User ID must be added to the list below.")
                .max(50, "Device User ID has a max limit of 50 characters.")
                .nullable()
                : 
                Yup.string("Enter a phone number")
                .trim()
                .max(50, "Device User ID has a max limit of 50 characters.")
                .nullable(),
            phoneNumber: (formikValues && formikValues.enableMyE911Functionality) && (!props.alternateDeviceUserIdEnabled || (props.alternateDeviceUserIdEnabled && identificationTypeOption === 'PHONE_NUMBER')) ?
                Yup.string("Enter a phone number")
                    .trim()
                    .required("Phone Number is a required field.")
                    .nullable() : '',
            callbackNumber: (formikValues && formikValues.enableMyE911Functionality) && ((props.alternateDeviceUserIdEnabled && identificationTypeOption === 'DEVICE_USER_ID')) ?
                Yup.string("Enter a phone number")
                    .trim()
                    .required("Callback Number is a required field.")
                    .nullable() : '',
            email: formikValues && formikValues.enableMyE911Functionality ?
                    Yup.string("Enter an email address")
                        .trim()
                        .max(150, "Email has a max limit of 150 characters.")
                        .required("Email address is a required field.")
                        .nullable()
                    :
                    Yup.string("Enter an email address")
                        .trim()
                        .max(150, "Email has a max limit of 150 characters.")
                        .nullable(),
            heldUserId: formikValues && formikValues.enableHeldPlusFunctionality ? 
                    Yup.string("Enter a HELD+ User ID")
                        .trim()
                        .max(100, "HELD+ User ID has a max limit of 100 characters.")
                        .required("HELD+ User ID is a required field.")
                        .nullable()
                    :
                    Yup.string("Enter a HELD+ User ID")
                        .trim()
                        .max(100, "HELD+ User ID has a max limit of 100 characters.")
                        .nullable(),
            firstName: Yup.string("Enter first name")
                    .trim()
                    .max(64, "First name has a max limit of 64 characters.")
                    .nullable(),
            lastName: Yup.string("Enter last name")
                    .trim()
                    .max(64, "Last name has a max limit of 64 characters.")
                    .nullable()
        })
        .test(
            'alternateId',
            null,
            (obj) => {
                if ((formikValues && formikValues.enableMyE911Functionality) && identificationTypeOption === 'DEVICE_USER_ID' && deviceUserIds.length <= 0) {
                    return new Yup.ValidationError(
                        'At least one Device User ID must be added to the list below.',
                        null,
                        'alternateId'
                    )
                } else {
                    return true;
                }
            }
        )
    )
}