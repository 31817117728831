import React, { useState } from 'react';
import { LocationContext } from '../Contexts/LocationContext';

const LocationProvider = ({ children }) => {
  const [multipleAddressList, setMultipleAddressList] = useState([]);
  const [addressIsAmbiguous, setAddressIsAmbiguous] = useState(false);
  const [addressIsAlternate, setAddressIsAlternate] = useState(false);

  return (
    <LocationContext.Provider 
      value={{ 
          multipleAddressList,
          setMultipleAddressList,
          addressIsAmbiguous,
          setAddressIsAmbiguous,
          addressIsAlternate,
          setAddressIsAlternate
      }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
