import React, { useCallback, useState, useEffect } from 'react';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import LocationsAPI from '../../apis/locationsApi';
import { debounce } from 'lodash';

/**
 * AutoComplete component that will grab list of MSAG valid addresses from Cirrus-Geography Service
 * Only inteded to use this component within Formik component and pass in necessary callbacks,
 */
export default function AddressAutoComplete(props) {
    const { values, 
            setFieldValue, 
            setFieldTouched,
            classes,
            errors,
            modalLoading,
            touched,
            companyId } = props;

    const [suggestions, setSuggestions] = useState([]);

    const loadOptions = (inputValue) => {  
        setFieldValue('address', inputValue); // update formik address value
        setFieldValue('latitude', '');
        setFieldValue('longitude', '');
        setFieldValue('houseNumber', '');
        setFieldValue('houseNumberExtension', '');
        setFieldValue('prefixDirection', '');
        setFieldValue('street', '');
        setFieldValue('streetType', '');
        setFieldValue('postDirection', '');
        setFieldValue('city', '');
        setFieldValue('stateProvince', '');
        setFieldValue('zip', '');
        setFieldValue('country', 'US');
        searchApiCall(inputValue);
    }

    const searchApiCall = useCallback(
        debounce(inputValue => {
            if(inputValue && inputValue.length > 0 && inputValue.trim() !== "")
            {
                LocationsAPI.autoComplete(inputValue, companyId).then(response =>  {
                    let options = response.data.content.map(erlTO => {
                        return {
                            label: erlTO.address.normalizedAddress,
                            value: erlTO.address.normalizedAddress
                        }
                    });
                    setSuggestions(options);
                });
            }
        }, 500)
    , []); 

    const handleItemToString = (item) => {
        return item ? item.value : '';
    }
    
    const handleStateChange = changes => {
        if (changes.hasOwnProperty('selectedItem')) {
            setFieldValue('address', changes.selectedItem.value)
        } else if (changes.hasOwnProperty('inputValue')) {
            setFieldValue('address', changes.inputValue);
        }
    }

    const handleOuterClick = (state) => {
        // Set downshift state to the updated formik value from handleOnBlur
        state.setState({
            inputValue: values.address
        })
    }

    const handleOnBlur = (e) => {
        console.log('blur');
        setFieldValue('address', e.target.value);
        setFieldTouched('address', true, true);
    }

    const stateReducer = (state, changes) => {
        //Override Downshift state to prevent on blur resets
        switch (changes.type) {
            case Downshift.stateChangeTypes.blurInput:
                return {
                    inputValue: values.address,
                    isOpen: false,
                }
            default:
                return changes
        }
    }

    useEffect(() => {
        // Remove Unnecessary Aria attributes
        const domElms = document.querySelectorAll('div[role="combobox"]');

        if (domElms.length > 0) {
            domElms.forEach(elm => {
                elm.removeAttribute('aria-labelledby');
            });
        }
    }, []);

    return (
        <Downshift  id="address-autocomplete"
                    onInputValueChange={loadOptions}
                    itemToString={handleItemToString }
                    onStateChange={handleStateChange}
                    onOuterClick={handleOuterClick}
                    onBlur={() => {
                        handleOnBlur();
                    }}
                    stateReducer={stateReducer}
                    initialInputValue={values.address}>
                {({
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        isOpen,
                    }) => (
                        <div>
                            <TextField
                                id="address"
                                label={(values.latitude && values.longitude) ? 'Address' : '*Address'}
                                name="address"
                                autoComplete={false}
                                value={values.address}
                                className={classes.autoCompleteOptions}
                                {...getInputProps({
                                    "aria-labelledby": null
                                })}
                                disabled={modalLoading}
                                error={(touched.address && Boolean(errors.address)) || (errors.address && Boolean(errors.address.includes('max')))}
                                style={{ width: '100%' }}/>
                                <span id="address-helper-text" className="error-prompt">{(touched.address && Boolean(errors.address)) || (errors.address && Boolean(errors.address.includes('max'))) ? errors.address : ''}</span>
                            <div {...getMenuProps({
                                "aria-labelledby": null
                            })}>
                                {isOpen ? (
                                    <Paper className={classes.paper} square>
                                        {suggestions.map((suggestion, index) =>
                                            <MenuItem {...getItemProps({item:suggestion, index, key:index})} component="div"  >
                                                {suggestion.value}
                                            </MenuItem>
                                        )}
                                    </Paper>
                                ) : null}
                            </div>
                        </div>
                )}                
        </Downshift>
    );
}