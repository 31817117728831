import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../../components/ReactTableLoadingComponent';

import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import ImportingAPI from '../../../apis/importingApi';
import { Button } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import { CSVLink, CSVDownload } from "react-csv";
import './ImportingErrorDetails.css';
import ModalErrorMessage from '../../../components/ModalWrapper/ModalErrorMessage';


const ImportingErrorDetailsModal = props => {
    const [importingArray, setImportingArray] = useState([]);
    const [errorFile, setErrorFile] = useState('');

    const buildTableColumns = [
        {
            Header: "Row",
            id: 'row',
            Cell: row => <div title={row.original.recordData}>{row.original.recordNumber}</div>
        },
        {
            Header: "Failed Row Data",
            id: 'data',
            Cell: row => <div title={row.original.recordData}>{row.original.recordData}</div>
        },
        {
            Header: "Reason",
            id: 'reason',
            Cell: row => <div title={row.original.errorMessage}>{row.original.errorMessage}</div>
        }
    ];

    useEffect(() => {
        ImportingAPI.getImportingErrorDetails(props.modalData.id).then(response => {
            setImportingArray(response.data);
        });

        ImportingAPI.getErrorFile(props.modalData.id).then(response => {
            setErrorFile(response.data);
        });
    }, []);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [props.modalLoading]);
    

    function isImportSystemError() {
        return (props.modalData && 
            props.modalData.status === 'ERROR' && 
            props.modalData.errorMessage && 
            props.modalData.errorMessage.length > 0)
    }

	return(
        <div>
            {isImportSystemError() && <ModalErrorMessage modalErrorMessage={'The system encountered an issue with processing the import, resulting in an error.'}/> }
            <br/>
            <div container direction="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ flex: 1}}>
                    {importingArray.length > 25 ? 
                    <p style={{width: '75%', margin: '-0.5%'}}><b>Please note:</b> Only the first 25 failed records will show - please download to view the full set.</p> : ''}
                </div>
                <div >
                    {errorFile &&
                        <Button 
                            id="import-download-button" 
                            className="button" 
                            variant="contained" 
                            color="primary"
                            onKeyDown={e => {
                                if ((e.key === ' ' || e.key === 'Enter')) {
                                    e.preventDefault();
    
                                    const downloadElm = document.querySelectorAll('.csv-download-link')[0];
    
                                    if (downloadElm) {
                                        downloadElm.click();
                                    }
                                }
                            }}
                        >
                            <CSVLink 
                                tabIndex={-1}
                                className='csv-download-link'
                                style={{
                                    color: '#fff',
                                    textDecoration: 'none'
                                }}
                                filename={"importErrors.csv"}
                                data={errorFile}>Download</CSVLink>
                        </Button>
                    }
                </div>
            </div>
            <br/>
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight error-table"
                showPaginationBottom={false}
                data={importingArray}
                minRows={importingArray && importingArray.length > 0 ? 0 : 5}
                columns={buildTableColumns}
                LoadingComponent={ReactTableLoadingComponent}
                defaultPageSize={25}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'import-error-details-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                }
            />
        </div>
    );
}

export default compose(
    ModalWrapper
)(ImportingErrorDetailsModal);