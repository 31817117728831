import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { orgHasLicense } from '../../reducers/app';
import {Input, InputLabel, MenuItem, Select} from "@material-ui/core";
import { currentCompanyOrgType, orgTypes } from '../../reducers/auth';


class RoleSelect extends Component {
	render() {
		const {
			companyId,
			value,
			roleList,
			handleChange,
			modalLoading,
			orgHasLicense
		} = this.props;

		return(
			<div>
				<InputLabel shrink={true} htmlFor="role-input" required={true}>Role</InputLabel>
				<Select
					id="role-input"
					name="roleName"
					value={value}
					onChange={handleChange}
					disabled={this.props.disabled}
					input={<Input name="role"/>}
					fullWidth={true}>
					{
						roleList.map(role => (
							!orgHasLicense && role.name === 'EON_USER' ?
								<MenuItem key={role.name} value={role.name} disabled={true}>
									<em>{role.displayName}</em>
								</MenuItem>
								:
								<MenuItem key={role.name} value={role.name}>
									<em>{role.displayName}</em>
								</MenuItem>
						))
					}
				</Select>
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
    orgHasLicense: orgHasLicense(),
	currentCompanyOrgType: currentCompanyOrgType()
});

export default connect(mapStateToProps)(RoleSelect);
