import React from 'react';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { Five9Theme } from '../../five9/Five9Theme';
import { store } from '../../index';
import { createMuiTheme, createStyles } from '@material-ui/core/styles';
import { DefaultTheme } from './defaultTheme/defaultTheme';

// Look for any specific theme set in the cirrus.info file
export default function Theme(props) {
    if(sessionStorage.getItem('skin') === 'five9') {
        return <MuiThemeProvider theme={Five9Theme}>{props.children}</MuiThemeProvider> // set five9 theme
    }
    else {
        return <MuiThemeProvider theme={DefaultTheme}>{props.children}</MuiThemeProvider>;  // set default theme
    }
}