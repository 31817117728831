import * as Yup from "yup";

function validationSchema(monitorTrunk) {
    return Yup.object({
        name: Yup.string("Enter a name")
                .trim()
                .required("Name is a required field.")
                .max(50, "Name has a max limit of 50 characters."),
        host: Yup.string("Enter a host")
                .trim()
                .required("Host is a required field.")
                .max(255, "Host has a max limit of 255 characters."),
        transport: Yup.string("Choose a transport")
                .required("Transport is a required field."),
        portNumber: monitorTrunk && 
            Yup.string("Enter a portNumber")
                .trim()
                .nullable()
                .required("Port Number is a required field.")
                .max(5, "Port Number has a max limit of 5 characters."),
    })
}

export default validationSchema;