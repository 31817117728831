import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLicenseStats, selectManagingCompany } from '../../reducers/manageLicenses';
import CircularProgress from '@material-ui/core/CircularProgress';
import './ManageLicensesStatsTable.css'
import {  orgTypes, selectUser, selectOriginalLoginCompany } from '../../reducers/auth';

function ManageLicensesStatsTable(props) {
    const { fetchLicenseStatusData, companyId, stats, managingCompany, licenseInfo, company, emergencyCallAssistEnabled} = props;
    let statsArray;
    let companyData;
    
    let numOfCERLocationsCount;
    let numOfCorporateLocationsCount;
    let numOfHeldDevicesCount;
    let numOfMyE911UsersCount;
    let numOHeldPlusUsersCount;
    let numOfDeviceUsersCount;

    let e911MangerElinCount;
    let enhancedNotificationCount;
    let locationCount;
    let userCount;
    let flexLocationCount;
    let jointFlexSKUCount;
    let standardCount;
    let flexUserCount;
    let multipleCount;
    let singleCount;
    let emergencyCallAssistCount;
    let emergencyCallAssistInUseCount;

    useEffect(() => {
        if (fetchLicenseStatusData) {
            fetchLicenseStatusData(companyId);
        }
    }, []);

    if (licenseInfo) {
        statsArray = licenseInfo;
    } else {
        statsArray = stats.toJS();
    }

    if (Object.keys(managingCompany.toJS()).length === 0) {
        companyData = company ? company.toJS() : '';
    } else {
        companyData = managingCompany.toJS();
    }

    if (Array.isArray(statsArray)) {
        statsArray.forEach(item => {
            if (item.type === 'numOfCERLocations') numOfCERLocationsCount = item.count;
            if (item.type === 'numOfCorporateLocations') numOfCorporateLocationsCount = item.count;
            if (item.type === 'numOfHeldDevices') numOfHeldDevicesCount = item.count;
            if (item.type === 'numOfMyE911Users') numOfMyE911UsersCount = item.count;
            if (item.type === 'numOfDeviceUsers') numOfDeviceUsersCount = item.count;
            if (item.type === 'numOHeldPlusUsers') numOHeldPlusUsersCount = item.count;
            if (item.type === 'E911 Manager ELIN') e911MangerElinCount = item.count;
            if (item.type === 'Enhanced Notification') enhancedNotificationCount = item.count;
            if (item.type === 'Location') locationCount = item.count;
            if (item.type === 'User') userCount = item.count;
            if (item.type === 'FlexLocation') flexLocationCount = item.count;
            if (item.type === 'Joint Flex SKU') jointFlexSKUCount = item.count;
            if (item.type === 'Standard') standardCount = item.count;
            if (item.type === 'FlexUser') flexUserCount = item.count;
            if (item.type === 'Multiple') multipleCount = item.count;
            if (item.type === 'Single') singleCount = item.count;
            if (item.type === 'Emergency Call Assist') emergencyCallAssistCount = item.count;
            if (item.type === 'Emergency Call Assist InUse') emergencyCallAssistInUseCount = item.count;
        })
    }
    
    return (
        <div className="stats-table-wrapper">
            {(statsArray && statsArray.length === undefined) || (licenseInfo && licenseInfo.length === 0) ?
                <div>
                    <CircularProgress style={{ color: '#1b536a' }}/>
                    <table className="stats-table" style={{ opacity: 0.4 }}>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Count</th>
                                <th>In Use</th>
                                <th>Remaining</th>
                                <th>Sub-Type</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                            </tr>
                            <tr>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                            </tr>
                            <tr>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                                <td>##</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            :
                <div>
                    {!companyData.usageBasedLicensing ? 
                        <table className="stats-table">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Count</th>
                                    <th>In Use</th>
                                    <th>Remaining</th>
                                    <th>Sub-Type</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyData.managerDataSync && 
                                    <tr>
                                        <td>E911 Manager ELIN</td>
                                        <td>{e911MangerElinCount}</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                    </tr>
                                }
                                {emergencyCallAssistEnabled &&
                                <tr>
                                    <td>Emergency Call Assist</td>
                                    <td>{emergencyCallAssistCount ? emergencyCallAssistCount : 0}</td>
                                    <td>{emergencyCallAssistInUseCount ? emergencyCallAssistInUseCount : 0}</td>
                                    {Number.isInteger(Number(emergencyCallAssistCount)) && Number.isInteger(Number(emergencyCallAssistInUseCount)) ?
                                        <td>{Number(emergencyCallAssistCount) - Number(emergencyCallAssistInUseCount)}</td>
                                    :
                                        <td>N/A</td>
                                    }
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                                }
                                <tr>
                                    <td>Enhanced Notification</td>
                                    <td>{enhancedNotificationCount}</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Location</td>
                                    <td>{locationCount ? locationCount : 0}</td>
                                    <td>{numOfCERLocationsCount + numOfCorporateLocationsCount}</td>
                                    {props.locationLicenseThreshold && <td style={{color: 'rgb(230, 0, 0)'}}>{Number(locationCount) - Number(numOfCERLocationsCount) - Number(numOfCorporateLocationsCount)}</td>}
                                    {!props.locationLicenseThreshold && <td>{Number(locationCount) - Number(numOfCERLocationsCount) - Number(numOfCorporateLocationsCount)}</td>}
                                    <td className="wrapper-td">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Flex</td>
                                                </tr>
                                                <tr>
                                                    <td>Joint Flex SKU</td>
                                                </tr>
                                                <tr>
                                                    <td>Standard</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="wrapper-td">
                                        <table>
                                            <tbody>
                                                <tr>
                                                <td>{flexLocationCount}</td>
                                                </tr>
                                                <tr>
                                                    <td>{jointFlexSKUCount}</td>
                                                </tr>
                                                <tr>
                                                    <td>{standardCount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>User</td>
                                    <td>{userCount}</td>
                                    <td>{numOfDeviceUsersCount}</td>
                                    {props.userLicenseThreshold && <td style={{color: 'rgb(230, 0, 0)'}}>{Number(userCount) - Number(numOfDeviceUsersCount)}</td>}
                                    {!props.userLicenseThreshold && <td>{Number(userCount) - Number(numOfDeviceUsersCount)}</td>}
                                    <td className="wrapper-td">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Flex</td>
                                                </tr>
                                                <tr>
                                                    <td>Multiple</td>
                                                </tr>
                                                <tr>
                                                    <td>Single</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="wrapper-td">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{flexUserCount}</td>
                                                </tr>
                                                <tr>
                                                    <td>{multipleCount}</td>
                                                </tr>
                                                <tr>
                                                    <td>{singleCount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        :
                        <table className="stats-table" style={{ width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyData.managerDataSync && 
                                    <tr>
                                        <td>E911 Manager ELIN</td>
                                        <td>{e911MangerElinCount}</td>
                                    </tr>
                                }
                                {emergencyCallAssistEnabled && 
                                <tr>
                                    <td>Emergency Call Assist</td>
                                    <td>{emergencyCallAssistCount}</td>
                                </tr>
                                }
                                <tr>
                                    <td>Enhanced Notification</td>
                                    <td>{enhancedNotificationCount}</td>
                                </tr>

                                {!(props.originalLoginCompany.toJS().orgType === orgTypes.SERVICE_PROVIDER) && !companyData.usageBasedLicensing &&
                                    <tr>
                                        <td>Location</td>
                                        <td>{locationCount}</td>
                                    </tr>
                                }
                                {!(props.originalLoginCompany.toJS().orgType === orgTypes.SERVICE_PROVIDER) && !companyData.usageBasedLicensing && 
                                    <tr>
                                        <td>User</td>
                                        <td>{userCount}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    }
                </div>
            }
        </div> 
    );
}

const mapStateToProps = createStructuredSelector({
    stats: selectLicenseStats(),
    managingCompany: selectManagingCompany(),
    user: selectUser(),
    originalLoginCompany: selectOriginalLoginCompany()
});

export default connect(mapStateToProps)(ManageLicensesStatsTable);