import React, { useState, useEffect } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import CompanyFeaturesApi from '../../../apis/companyFeaturesApi';
import { createStructuredSelector } from "reselect";
import { selectUserCompany, currentCompanyLevel } from "../../../reducers/auth";
import { actions as OrganizationManagementActions } from "../../../reducers/organizationManagement";
import { types as AppTypes, 
        selectCleanUpInactiveDevicesEnabled, 
        selectCleanUpInactiveHeldEnabled, 
        selectCleanUpUserPurgeEnabled, 
        selectDaysInactiveUsersSelect,
        selectDaysInactiveHeldSelect,
    } from "../../../reducers/app";
import { connect } from "react-redux";
import { Formik } from 'formik';
import Switch from '@material-ui/core/Switch';

import Grid from '@material-ui/core/Grid';

const daysInactiveUsersData = [
    {
        label: '30',
        value: 'USER_DEVICE_PURGE_30'
    },
    {
        label: '60',
        value: 'USER_DEVICE_PURGE_60'
    },
    {
        label: '90',
        value: 'USER_DEVICE_PURGE_90'
    },
    {
        label: '180',
        value: 'USER_DEVICE_PURGE_180'
    },
    {
        label: '365',
        value: 'USER_DEVICE_PURGE_365'
    }
];

const daysInactiveHeldData = [
    {
        label: '30',
        value: 'HELD_DEVICE_PURGE_30'
    },
    {
        label: '60',
        value: 'HELD_DEVICE_PURGE_60'
    },
    {
        label: '90',
        value: 'HELD_DEVICE_PURGE_90'
    },
    {
        label: '180',
        value: 'HELD_DEVICE_PURGE_180'
    },
    {
        label: '365',
        value: 'HELD_DEVICE_PURGE_365'
    }
];

const removeAllUsersFeatures =  ["USER_DEVICE_PURGE_30", "USER_DEVICE_PURGE_60", "USER_DEVICE_PURGE_90", "USER_DEVICE_PURGE_180", "USER_DEVICE_PURGE_365"];
const removeAllHeldFeatures =  ["HELD_DEVICE_PURGE_30", "HELD_DEVICE_PURGE_60", "HELD_DEVICE_PURGE_90", "HELD_DEVICE_PURGE_180", "HELD_DEVICE_PURGE_365"];

function CleanUpInactiveDevicesFeatureForm(formikProps) {
    const {
        handleChange,
        values,
        submitStatus,
        dataLoading,
        handleAddFeature,
        handleRemoveFeatures,
        setFieldValue,
        props,
        disableInput,
        disableHELD,
        disableDeviceUser,
        isViewDeviceUsers,
        isViewHeldDevices
    } = formikProps;

    const handleInactiveUserDeviceChange = () => {
        setFieldValue('cleanUpInactiveUserDevicesSwitch', !values.cleanUpInactiveUserDevicesSwitch);

        if (!values.cleanUpInactiveUserDevicesSwitch) {
            handleAddFeature('USER_DEVICE_PURGE_90')
            setFieldValue('daysInactiveUsersSelect', 'USER_DEVICE_PURGE_90');
        } else {
            handleRemoveFeatures('USER_PURGE');
            handleRemoveFeatures(removeAllUsersFeatures);
        }
    }
    
    const handleInactiveHeldDeviceChange = () => {
        setFieldValue('cleanUpInactiveHeldDevicesSwitch', !values.cleanUpInactiveHeldDevicesSwitch);

        if (!values.cleanUpInactiveHeldDevicesSwitch) {
            handleAddFeature('HELD_DEVICE_PURGE_90')
            setFieldValue('daysInactiveHeldSelect', 'HELD_DEVICE_PURGE_90');
        } else {
            handleRemoveFeatures(removeAllHeldFeatures);
        }
    }
    
    const handleInactiveUserChange = () => {
        setFieldValue('deleteInactiveUsersSwitch', !values.deleteInactiveUsersSwitch);

        if (!values.deleteInactiveUsersSwitch) {
            handleAddFeature('USER_PURGE');
        } else {
            handleRemoveFeatures('USER_PURGE');
        }
    }
    
    const handleDaysInactiveUsersSelectChange = (event) => {
        handleRemoveFeatures(values.daysInactiveUsersSelect);
        handleAddFeature(event.target.value);

        setFieldValue('daysInactiveUsersSelect', event.target.value);
    }
    
    const handleDaysInactiveHeldSelectChange = (event) => {
        handleRemoveFeatures(values.daysInactiveHeldSelect);
        handleAddFeature(event.target.value);

        setFieldValue('daysInactiveHeldSelect', event.target.value);
    }

    useEffect(() => {
        setFieldValue('cleanUpInactiveUserDevicesSwitch', props.cleanUpInactiveDevicesEnabled);
    }, [props.cleanUpInactiveDevicesEnabled]);
    
    useEffect(() => {
        setFieldValue('cleanUpInactiveHeldDevicesSwitch', props.cleanUpInactiveHeldEnabled);
    }, [props.cleanUpInactiveHeldEnabled]);
    
    useEffect(() => {
        setFieldValue('deleteInactiveUsersSwitch', props.cleanUpUserPurgeEnabled);
    }, [props.cleanUpUserPurgeEnabled]);
    
    useEffect(() => {
        setFieldValue('daysInactiveUsersSelect', props.daysInactiveUsersSelect);
    }, [props.daysInactiveUsersSelect]);
    
    useEffect(() => {
        setFieldValue('daysInactiveHeldSelect', props.daysInactiveHeldSelect);
    }, [props.daysInactiveHeldSelect]);

    return (
        <div style={{ textAlign: 'right' }}>
            {dataLoading && <LinearProgress/>}
            {isViewDeviceUsers && 
                <Grid container justify="center" direction="row" alignItems="flex-start" style={{margin: '20px 20px 80px'}}>
                    <Grid item>
                        <label htmlFor="cleanUpInactiveUserDevicesSwitch">Clean Up Inactive MyE911 / HELD+ Devices</label>
                        <br/>
                        <br/>
                        <Grid container justify="center" direction="row" alignItems="flex-end">
                            <Grid item xs={12} sm={6}>
                                <div style={{ display: 'flex', width: 240, position: 'relative', right: -50 }}>
                                    {values.cleanUpInactiveUserDevicesSwitch && 
                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                    <label htmlFor="daysInactiveUsersSelect" style={{ display: 'inline-block', marginBottom: 20 }}>Days Inactive:</label>
                                                    <Select
                                                        style={{ display: 'inline-block', marginBottom: 30 }}
                                                        id="daysInactiveUsersSelect"
                                                        name="daysInactiveUsersSelect"
                                                        value={values.daysInactiveUsersSelect}
                                                        onChange={handleDaysInactiveUsersSelectChange}
                                                        disabled={dataLoading || !disableInput || !disableDeviceUser}
                                                        fullWidth={false}
                                                    >
                                                        { 
                                                            daysInactiveUsersData.map((numberOfDays, index) => {
                                                                return <MenuItem key={index} value={numberOfDays.value}>{numberOfDays.label}</MenuItem>
                                                            }
                                                            )
                                                        }
                                                    </Select>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex', alignItems: 'end' }}>
                                                        <label htmlFor="deleteInactiveUsersSwitch">Delete Inactive Non-MyE911 Users</label>
                                                        <Tooltip 
                                                            title="This option allows for users, only using HELD+ devices, to be automatically deleted when they no longer have any active HELD+ devices."
                                                            placement="top"
                                                        >
                                                            <span tabIndex={disableInput ? 0 : -1} style={{ display: 'inline-block', padding: '0 0 0 5px', verticalAlign: 'middle' }}>
                                                                <Info style={{ color: '#000' }} />
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <Switch
                                                        id="deleteInactiveUsersSwitch"
                                                        name="deleteInactiveUsersSwitch"
                                                        color="primary"
                                                        checked={values.deleteInactiveUsersSwitch}
                                                        onChange={handleInactiveUserChange}
                                                        disabled={dataLoading || !disableInput || !disableDeviceUser}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ margin: '-12px 0 0 20px' }}>
                        <Switch
                            id="cleanUpInactiveUserDevicesSwitch"
                            name="cleanUpInactiveUserDevicesSwitch"
                            color="primary"
                            checked={values.cleanUpInactiveUserDevicesSwitch}
                            onChange={handleInactiveUserDeviceChange}
                            disabled={dataLoading || !disableInput || !disableDeviceUser} />
                    </Grid>
                </Grid>
            }
            {isViewHeldDevices &&
                <Grid container justify="center" direction="row" alignItems="flex-start" style={{marginLeft: 60}}>
                    <Grid item>
                        <label htmlFor="cleanUpInactiveHeldDevicesSwitch">Clean Up Inactive HELD Devices</label>
                        <br/>
                        <br/>
                        {values.cleanUpInactiveHeldDevicesSwitch && 
                        <Grid container justify="center" direction="row" alignItems="flex-end">
                            <div style={{ display: 'flex', width: 200, alignItems: 'center', position: 'relative', right: -50 }}>
                                <label htmlFor="daysInactiveHeldSelect" style={{ display: 'inline-block', marginBottom: 20 }}>Days Inactive:</label>
                                <Select
                                        style={{ display: 'inline-block', margin: '0 0 30px 30px' }}
                                        id="daysInactiveHeldSelect"
                                        name="daysInactiveHeldSelect"
                                        value={values.daysInactiveHeldSelect}
                                        onChange={handleDaysInactiveHeldSelectChange}
                                        disabled={dataLoading || !disableInput || !disableHELD}
                                        fullWidth={false}
                                    >
                                        { 
                                            daysInactiveHeldData.map((numberOfDays, index) => 
                                                <MenuItem key={index} value={numberOfDays.value}>{numberOfDays.label}</MenuItem>
                                            )
                                        }
                                    </Select>
                            </div>
                        </Grid>
                        }
                    </Grid>
                    <Grid item style={{ margin: '-12px 0 0 20px' }}>
                        <Switch
                            id="cleanUpInactiveHeldDevicesSwitch"
                            name="cleanUpInactiveHeldDevicesSwitch"
                            color="primary"
                            checked={values.cleanUpInactiveHeldDevicesSwitch}
                            onChange={handleInactiveHeldDeviceChange}
                            disabled={dataLoading || !disableInput || !disableHELD}
                        />
                    </Grid>
                </Grid>
            }
            {submitStatus === 'failure' && 
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 700 }}>
                <ErrorOutlineIcon style={{ color: '#f4473a', marginRight: 10, fontSize: 30 }}/>
                <div style={{ color: '#f4473a' }}>ERROR! We are not able to save your preference at this time. Please try again later.</div>
            </div>
            }
        </div>
    );
}

function CleanUpInactiveDevicesFeature(props) {
    const [submitStatus, setSubmitStatus] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const company = props.company.toJS();

    const handleAddFeature = enumValue => {
            setDataLoading(true);

            CompanyFeaturesApi.addCompanyFeatures(company.id, enumValue).then(response => {
                props.getOrganizationFeatures();
                setSubmitStatus('success');
                setDataLoading(false);
            }).catch(error => {
                console.log(error)
                setSubmitStatus('failure');
                setDataLoading(false);
        });
    }
    
    const handleRemoveFeatures = valuesArray => {
            setDataLoading(true);

            CompanyFeaturesApi.removeCompanyFeatures(company.id, valuesArray).then(response => {
                props.getOrganizationFeatures();
                setSubmitStatus('success');
                setDataLoading(false);
            }).catch(error => {
                console.log(error)
                setSubmitStatus('failure');
                setDataLoading(false);
        });
    }

    return (
        <Formik
            initialValues={{ 
                cleanUpInactiveUserDevicesSwitch: false,
                daysInactiveUsersSelect: 'USER_DEVICE_PURGE_90',
                deleteInactiveUsersSwitch: false,
                daysInactiveHeldSelect: 'HELD_DEVICE_PURGE_90',
                cleanUpInactiveHeldDevicesSwitch: false


            }}
            onSubmit = {
                (values) => {
                    
                }
            }
            render={formikProps => <CleanUpInactiveDevicesFeatureForm 
                                        isViewDeviceUsers={props.isViewDeviceUsers}
                                        isViewHeldDevices={props.isViewHeldDevices}
                                        disableHELD={props.disableHELD}
                                        disableDeviceUser={props.disableDeviceUser}
                                        disableInput={props.disableInput}
                                        handleRemoveFeatures={handleRemoveFeatures}
                                        handleAddFeature={handleAddFeature}
                                        submitStatus={submitStatus}
                                        dataLoading={dataLoading}
                                        props={props}
                                        {...formikProps}
                                    />}
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    company: selectUserCompany(),
    cleanUpInactiveDevicesEnabled: selectCleanUpInactiveDevicesEnabled(),
    cleanUpInactiveHeldEnabled: selectCleanUpInactiveHeldEnabled(),
    cleanUpUserPurgeEnabled: selectCleanUpUserPurgeEnabled(),
    daysInactiveUsersSelect: selectDaysInactiveUsersSelect(),
    daysInactiveHeldSelect: selectDaysInactiveHeldSelect()
});

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationFeatures: () => dispatch(OrganizationManagementActions.getOrganizationFeatures())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CleanUpInactiveDevicesFeature);