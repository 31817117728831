import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import CallHistoryAPI from '../apis/callHistoryApi';
import { userCompanyId } from '../reducers/auth';
import { actions as AppActions } from '../reducers/app';
import { actions as CallHistoryActions,
	types as CallHistoryTypes } from '../reducers/callHistory';
import pageErrorHandler from '../utils/pageErrorHandler';

function* getCallHistoryList(action) {
	try {	
		const companyId = yield select(userCompanyId());
		let response;
		if (!companyId) {
			response = yield call(CallHistoryAPI.getCallHistory, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction);
		} else {
			response = yield call(CallHistoryAPI.getCallHistoryByCompanyId, companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction);
		}
		yield put(CallHistoryActions.getCallHistorySuccess(response.data, response.headers['x-pagination-count']));	
	} catch (error) {
		yield put(CallHistoryActions.getCallHistoryError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}


function* rootLocationsSaga() {
	yield all([
		yield takeLatest(CallHistoryTypes.GET_CALL_HISTORY_REQUEST, getCallHistoryList),
	]);
}

export default rootLocationsSaga;
