import React, { useState, useEffect, useCallback } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HasAccess from "../../components/HasAccess/HasAccess";

import { connect } from "react-redux";
import { compose } from "redux";

import injectReducer from '../../utils/injectReducer';
import reducer from '../../reducers/eonUsers';
import injectSaga from '../../utils/injectSagas';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';
import saga from '../../sagas/eonUsers';

import { types } from '../../reducers/eonUsers';
import { actions as appActions, selectPageSize } from '../../reducers/app';

import { createStructuredSelector } from "reselect";
import EonUsersTable from "../EonUsersTable/eonUsersTable";

import { actions as UserActions } from '../../reducers/eonUsers';
import { debounce } from 'lodash';

const initialSortColumns = [
  {
      id: 'email',
      desc:false
  }
]

const EonUsersPage = props => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [sortColumns, setSortColumns] = useState(initialSortColumns);
  const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

  function modalHandleOpen() {
    props.setCurrentModal(types.ADD_EON_USER);
  }

  function handleInputSearchChange(e) {
    const inputValue = e.target.value;
    const isMatch = searchFieldRegex(inputValue);
    
    if (isMatch) {
      setSearchValue(inputValue);
      searchApiCall(
        inputValue, 
        props.pageSize, 
        currentSortColumn.id, 
        currentSortColumn.desc ? 'DESC': 'ASC'
      );
    }
  }

  const searchApiCall = useCallback(
    debounce((inputValue, pageSize, sortBy, sortDirection) => {
      if (inputValue.length > 0) {
        setPage(0);
        props.fetchEonUsersList(1, pageSize, inputValue, sortBy, sortDirection);
      } else if (inputValue.length === 0) {
        setPage(0);
        props.fetchEonUsersList(1, pageSize, "", sortBy, sortDirection);
      }
    }, 500)
, []);

  useEffect(() => {
    props.setPageTitle('EON Users');
    props.setPageTitleId('eon-users-header');
  }, []);

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
          <Grid item>
            <SearchField 
              value={searchValue}
              onChange={handleInputSearchChange}
            />
          </Grid>
          {searchValue ? '' :
            <Grid item>
              <HasAccess
                permissions={["ADD_EON_USERS"]}
              >      
                <Button
                  className="button"
                  id="add-eon-users-button"
                  variant="contained"
                  color="primary"
                  onClick={modalHandleOpen}
                >
                  Add Eon User
                </Button>
              </HasAccess>
            </Grid>
          }
      </Grid>
      <br />

      <EonUsersTable
        fetchEonUsersList={props.fetchEonUsersList}
        setCurrentModal={props.setCurrentModal}
        page={page}
        setPage={setPage}
        pageSize={props.pageSize}
        sortColumns={sortColumns}
        setSortColumns={setSortColumns}
        currentSortColumn={currentSortColumn}
        setCurrentSortColumn={setCurrentSortColumn}
        searchValue={searchValue}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentModal: currentModal => dispatch(appActions.setCurrentModal(currentModal)),
    fetchEonUsersList: (page, pageSize, searchTerm, sortBy, sortDirection) => dispatch(UserActions.getEonUsersRequest(page, pageSize, searchTerm, sortBy, sortDirection)),
    setPageTitle: data => dispatch(appActions.setPageTitle(data)),
    setPageTitleId: data => dispatch(appActions.setPageTitleId(data))
  };
};

const mapStateToProps = createStructuredSelector({
  pageSize: selectPageSize()
});

const withReducer = injectReducer({ key: "eonUsers", reducer });
const withSaga = injectSaga({ key: "eonUsers", saga });
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withReducer,
  withSaga,
  withConnect
)(EonUsersPage);
