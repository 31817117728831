import {axiosInstance} from './api';

/* Cirrus Admin-Service /locations Controller */
const ALERTS_ENDPOINT = 'admin-service/alertSubscription';
const CARRIER_TYPE_ENDPOINT = 'admin-service/alert-carrier-type';
const ALERT_TYPE_ENDPOINT = 'admin-service/alert-type';
const ALERT_TEMPLATES_ENDPOINT = 'admin-service/alert-template';
const ALERT_TEMPLATES_ENDPOINT_BY_COMPANY = ALERT_TEMPLATES_ENDPOINT + '/company/';
const ALERT_TEMPLATES_PREVIEW_ENDPOINT = ALERT_TEMPLATES_ENDPOINT + '/preview/';
const ALERT_TEMPLATES_COPY_ENDPOINT = ALERT_TEMPLATES_ENDPOINT + '/copy/';
const ALERT_TEMPLATES_TAGS_ENDPOINT = ALERT_TEMPLATES_ENDPOINT + '/tags';
const SEND_EMAIL_ENDPOINT = 'admin-service/alert/';

export default class AlertsApi {
    static getAlertsSubscriptionsList(companyId, page, pageSize, sortBy, direction) {
        if(companyId) {
            return axiosInstance.get(`${ALERTS_ENDPOINT}/company/${companyId}`, {
                params: {
                    page,
                    pageSize,
                    sortBy,
                    direction
                }
            });
        }
        return axiosInstance.get(`${ALERTS_ENDPOINT}/system`, {
            params: {
                page,
                pageSize,
                sortBy,
                direction
            }
        });
    }
    
    static getAlertsSubscriptionsWithFilters(subId, page, pageSize) {
        return axiosInstance.get(`${ALERTS_ENDPOINT}/${subId}/filters`, {
            params: {
                page,
                pageSize
            }
        })
    }
    
    static addAlertSubscriptionsList(alertSubscription) {
        const payload = {
            alertTypeTO: {
                name: alertSubscription.alertType.key
            },
            companyId: alertSubscription.parentId,
            timezone: alertSubscription.timeZoneType,
            emailRecipients: alertSubscription.emailList.map(item => ({ email: item.email })),
            name: alertSubscription.name,
            smsRecipients: alertSubscription.smsList.map(item => ({ phoneNumber: item.phoneNumber })),
            templateId: alertSubscription.template.id,
            userRecipients: alertSubscription.userList.map(item => ({ userProfileId: item.userProfileId })),
            informacastRecipients: alertSubscription.informacastList.map(item => item.id ),
            allCorporateLocations: !alertSubscription.enableFiltering ? true : (alertSubscription.allCorporateLocations ? alertSubscription.allCorporateLocations : false),
            allPersonalLocations: !alertSubscription.enableFiltering ? true : (alertSubscription.allPersonalLocations ? alertSubscription.allPersonalLocations : false)
        };
        
        return axiosInstance.post(ALERTS_ENDPOINT, payload);
    }

    static addAlertSubscriptionsListWithFilters(filtersArray, subscriptionId) {
        const payload = filtersArray;

        return axiosInstance.post(`${ALERTS_ENDPOINT}/${subscriptionId}/filters`, payload);
    }
    
    static deleteAlertSubscriptionsListWithFilters(subscriptionId) {
        return axiosInstance.delete(`${ALERTS_ENDPOINT}/${subscriptionId}/filters`);
    }
    
    static editAlertSubscriptionsList(alertSubscription) {
        const payload = {
            id: alertSubscription.id,
            alertTypeTO: {
                name: alertSubscription.alertType.key
            },
            companyId: alertSubscription.parentId,
            timezone: alertSubscription.timeZoneType,
            emailRecipients: alertSubscription.emailList.map(item => ({ email: item.email })),
            name: alertSubscription.name,
            smsRecipients: alertSubscription.smsList.map(item => ({ phoneNumber: item.phoneNumber.replace(/[() -]/g, '') })),
            templateId: alertSubscription.template.id,
            userRecipients: alertSubscription.userList.map(item => ({ userProfileId: item.userProfileId })),
            informacastRecipients: alertSubscription.informacastList.map(item => item.id ),
            allCorporateLocations: !alertSubscription.enableFiltering ? true : (alertSubscription.allCorporateLocations ? alertSubscription.allCorporateLocations : false),
            allPersonalLocations: !alertSubscription.enableFiltering ? true : (alertSubscription.allPersonalLocations ? alertSubscription.allPersonalLocations : false)
        };

        return axiosInstance.put(ALERTS_ENDPOINT, payload);
    }
    
    static deleteAlertSubscription(id) {
        return axiosInstance.delete(`${ALERTS_ENDPOINT}/${id}`);
    }

    static testAlertSubscription(alertSubscriptionId) {
        return axiosInstance.get(`${ALERTS_ENDPOINT}/testAlert/${alertSubscriptionId}`);
    }

    static getAlertsTemplatesList(companyId, alertType, page, pageSize, sortBy, direction) {
        const params = {
            params: {
                page,
                pageSize,
                sortBy,
                direction,
                alertType
            }
        }
        if(companyId){
            return axiosInstance.get(ALERT_TEMPLATES_ENDPOINT_BY_COMPANY + companyId, params);
        }
        return axiosInstance.get(`${ALERT_TEMPLATES_ENDPOINT}/system`, params);
    }
    
    static deleteAlertsTemplate(id, replacement) {
        const params = {
            params: {
                replacement
            }
        }
        return axiosInstance.delete(`${ALERT_TEMPLATES_ENDPOINT}/${id}`, params);
    }
    
    static getCarrierTypeList() {
        return axiosInstance.get(CARRIER_TYPE_ENDPOINT);
    }

    static getAlertTypes(filter) {
        const params = {
            params: {
                filter
            }
        }
        return axiosInstance.get(ALERT_TYPE_ENDPOINT, params);
    }
    
    static getAlertTemplateById(id) {
        return axiosInstance.get(`${ALERT_TEMPLATES_ENDPOINT}/${id}`);
    }
    
    static previewAlertTemplate(id) {
        return axiosInstance.get(ALERT_TEMPLATES_PREVIEW_ENDPOINT + id);
    }
    
    static copyAlertTemplate(template) {
        const payload = {
            templateId: template.templateId,
            companyId: template.parentId,
            name: template.name
        };
        return axiosInstance.post(ALERT_TEMPLATES_COPY_ENDPOINT, payload);
    }
    
    static getAlertTemplateTags(alertType) {
        return axiosInstance.get(`${ALERT_TEMPLATES_TAGS_ENDPOINT}?alertType=${alertType}`)
    }
    
    static addAlertTemplate(template) {
        const payload = {
            alertType: {
                name: template.alertType
            },
            companyId: template.parentId,
            name: template.name,
            subject: template.subject,
            content: template.content
        };
        return axiosInstance.post(ALERT_TEMPLATES_ENDPOINT, payload);
    }
    
    static editAlertTemplate(template) {
        const payload = {
            id: template.id,
            alertType: {
                name: template.alertType
            },
            companyId: template.companyId,
            name: template.name,
            subject: template.subject,
            content: template.content,
            userDefined: template.userDefined
        };
        return axiosInstance.put(ALERT_TEMPLATES_ENDPOINT, payload);
    }

    static sendEmail(alertType, recipient){
        return axiosInstance.post(`${SEND_EMAIL_ENDPOINT}?alertType=${alertType}&recipient=${recipient}`);
    }
}