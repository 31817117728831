import * as Yup from "yup";

export const validationSchema = Yup.object({
    name: Yup.string("Enter a name")
        .trim()
        .required("Name is a required field.")
        .max(50, "Name has a max limit of 50 characters."),
    managerDataSyncKey: Yup.string()
        .trim()
        .nullable()
        .max(256, "Manager Data Sync Key has a max limit of 256 characters."),
    description: Yup.string()
        .trim()
        .max(255, "Description has a max limit of 255 characters."),
    externalOrgId: Yup.string()
        .trim()
        .nullable()
        .max(100, "External Organization ID/Key has a max limit of 100 characters."),  
});

export const styles = theme => ({
    btn: {
        marginTop: '10px'
    }
});