import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ModalWrapper from '../../../../components/ModalWrapper';
import './Details.css';
import NumberFormatter from '../../../../components/NumberFormatter/NumberFormatter';
import { createStructuredSelector } from 'reselect';
import { selectLocationAlternateIdEnabled, selectHorizonPrimeEnabled } from '../../../../reducers/app';
import { originalCompanyOrgType } from '../../../../reducers/auth';

function LocationDetails(props) {
    const {
        name,
        address,
        normalizedGeoCoords,
        info,
        orgNameOverride,
        orgNameOverriddenByBuilding,
        userRole,
        htmlLinkUrl,
        htmlLinkName,
        elin,
        simple,
        alternateId,
        callbackNumber,
        horizonPrimeEnabled
    } = props.modalData;
    
    let phoneNumber;
    if(elin) {
        phoneNumber = elin.phoneNumber;
    }

    return(
        <div>
            <ul className="details-list">
                <li><b>Location Name: </b>{name}</li>
                {!simple ? <li><b>Location Information: </b>{info}</li> : ''}
                {orgNameOverriddenByBuilding ? <li><b>Organization Name Override (From Building): </b>{orgNameOverride}</li> : ''}
                {!simple && !orgNameOverriddenByBuilding ? <li><b> Organization Name Override: </b>{orgNameOverride}</li> : ''}
                {simple ? <li><b>Location Information: </b>{info}</li> : ''}
                <li><b>Phone Number: </b>{!isNaN(phoneNumber) ? <NumberFormatter number={phoneNumber} /> : phoneNumber}</li>
                {props.locationAlternateIdEnabled ? <li><b>Alternate ID: </b>{alternateId}</li> : ''}
                {props.locationAlternateIdEnabled ? <li><b>Callback Number: </b>{!isNaN(callbackNumber) ? <NumberFormatter number={callbackNumber} /> : callbackNumber} </li> : ''}
                {simple ? <li><b>Address: </b>{address ? address.normalizedAddress : ''}</li> : ''}
                {simple ? <li><b>Geodetic Coordinates: </b>{normalizedGeoCoords ? normalizedGeoCoords.latitude + ", " + normalizedGeoCoords.longitude : ''}</li> : '' }
                {simple ? <li><b> Organization Name Override: </b>{orgNameOverride}</li> : ''}
                {simple ? <li><b>HTML Link URL: </b>{htmlLinkUrl}</li> : ''}
                {simple ?<li><b>HTML Link Name: </b>{htmlLinkName}</li> : ''}
                {simple ?  <li><b>Status: </b>{address ? address.msagValid : ''}</li> : ''}
                {simple && props.originalCompanyOrgType === 'SYSTEM' ? <li><b>Backend Provider: </b>{address ? address.backendProvider : ''}</li> : ''}
                {props.horizonPrimeEnabled ? <li><b>PSAP URI: </b> {address ? address.psapUri : ''}</li> : ''}
            </ul>
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled(),
    horizonPrimeEnabled: selectHorizonPrimeEnabled(),
    originalCompanyOrgType: originalCompanyOrgType()
});

export default compose(ModalWrapper, connect(mapStateToProps))(LocationDetails);