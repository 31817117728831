import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PortEditMenu from '../../containers/PortEditMenu/portEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import { actions as PortActions, selectPortByParentId, portsLoading } from '../../reducers/networkDiscovery';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";


const initialSortColumns = [
    {
        id: 'location',
        desc:false
    }
]
let currentColumnInput = initialSortColumns;
function PortTable(props) {
    const { parentId } = props;

    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isEdit = user && user.permissions.includes('EDIT_NETWORK_DISCOVERY');
	const isDelete = user && user.permissions.includes('DELETE_NETWORK_DISCOVERY');
    const isView = isEdit || isDelete;

    function getGeoCoordsNetDisco(row) {
        if ((row.original.erl) && (row.original.erl.geoCoords) && (row.original.erl.geoCoords.latitude) && (row.original.erl.geoCoords.longitude)) {
            return <div title={`${row.original.erl.geoCoords.latitude} , ${row.original.erl.geoCoords.longitude}`}>{`${row.original.erl.geoCoords.latitude} , ${row.original.erl.geoCoords.longitude}`}</div>;
        } else if ((row.original.erl) && (row.original.erl.address) && (row.original.erl.address.latitude) && (row.original.erl.address.longitude)) {
            return <div title={`${row.original.erl.address.latitude} , ${row.original.erl.address.longitude}`}>{`${row.original.erl.address.latitude} , ${row.original.erl.address.longitude}`}</div>;   
        } else {
            return <div></div>;
        }
    }

    const buildTableColumns = [
        {
            Header: "Description",
            id: "description",
            sortable: false,
            Cell: row => <div title={row.original.description}>{row.original.description}</div>
        },
        {
            Header: "Identifier",
            id: "identifier",
            sortable: false,
            Cell: row => <div title={row.original.portId}>{row.original.portId}</div>
        },
        {
            Header: "Location",
            id: "location",
            sortable: true,
            Cell: row => <div title={`${row.original.erl ? row.original.erl.name: 'Unknown'} ${(row.original.erl && row.original.erl.address) ? row.original.erl.address.normalizedAddress : ''}`}>{row.original.erl ? row.original.erl.name: 'Unknown'}<br/>{(row.original.erl && row.original.erl.address) ? row.original.erl.address.normalizedAddress : ''}</div>
        },
        {
            Header: <div id="location-coordinates">Geodetic Coordinates</div>,
            id: "coordinates",
            Cell: row => getGeoCoordsNetDisco(row)
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            sortable: false,
            Cell: row => (
                isView ? <PortEditMenu row={row} setCurrentModal={props.setCurrentModal} sortBy={currentSortColumn}/> : null
            )
        }
    ];

    const {
        loading, portList, getPortRequest, 
    } = props;
    let portListArray = [];

    if (portList) {
        portListArray = portList.toArray();
    }

    useEffect( () => {
        getPortRequest(props.lldpId, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
    }, [currentSortColumn, props.pageSize, props.page]);


    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);
    
    return (
        <ReactTable
            resizable={false}
            sortable={true}
            manual
            className="-striped -highlight"
            showPaginationBottom={false}
            data={portListArray}
            minRows={portListArray && portListArray.length > 0 ? 0 : 5}
            pageSize={portListArray ? portListArray.length : null}
            columns={buildTableColumns}
            loading = {loading}
            LoadingComponent={ReactTableLoadingComponent}
            sortColumns={sortColumns}
            setSortColumns={setSortColumns}
            currentSortColumn={currentSortColumn}
            setCurrentSortColumn={setCurrentSortColumn} 
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                    currentColumnInput = [
                        {
                            id: 'location',
                            desc: currentSortColumn.desc
                        }
                    ];
                })
                
            }}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo && !rowInfo.original.erl) {
                            return {
                                id: 'port-row-' + rowInfo.index,
                                style: {
                                    background: 'rgba(255, 70, 71, 0.45)',
                                    opacity: '.8',
                                    border: '1px solid red'
                                }
                            };
                        }
                        else {
                            return {
                                id: rowInfo ? 'port-row-' + rowInfo.index : '',
                            };
                        }
                    }
            }
        />
    );
}

PortTable.propTypes = {
    portList: ImmutablePropTypes.list,
    loading: PropTypes.bool,
    setCurrentModal: PropTypes.func.isRequired
};

export function currentColumn() {
	return {
		data: currentColumnInput
	}
}

const mapDispatchToProps = dispatch => {
    return {
        getPortRequest: (lldpId, sortBy, direction) => dispatch(PortActions.getPortRequest(lldpId, sortBy, direction)),
    }
}

export default connect(null, mapDispatchToProps)(PortTable);

