import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import DeviceUsersEditMenu from '../../containers/DeviceUsersEditMenu/deviceUsersEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import LocationDetailBox from './LocationDetailBox';
import moment from 'moment';
import { connect } from 'react-redux';
import { makeSelectDeviceUsersPageCount, makeSelectDeviceUsersList, makeSelectDeviceUsersListLoading, types } from '../../reducers/deviceUsers';
import { createStructuredSelector } from 'reselect';
import DeviceUsersTabs from '../../containers/DeviceUsersTabs/deviceUsersTabs';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';
import { selectAlternateIdEnabled, actions as AppActions } from '../../reducers/app';
import { actions as DeviceUserActions } from '../../reducers/deviceUsers';

function AlternateDeviceUserIdRow(props) {

    function openAlternateDisplayDetails() {
        props.setCurrentModal(types.ALTERNATE_ID_DETAILS, {
            
            row: row.original
        });
    }

    const { row } = props;
    if(row && row.original.phoneNumber) {
        return (<div title={row.original.phoneNumber.phoneNumber}>{
            !isNaN(row.original.phoneNumber.phoneNumber) ? 
            <NumberFormatter number={row.original.phoneNumber.phoneNumber} /> : 
            row.original.phoneNumber.phoneNumber}
        </div>);
    }
    let displayAltId = '';
    let max = false;
    row.original.alternateIdList.every(
        altIdObj => {
            displayAltId += altIdObj.alternateId + ", ";
            if(displayAltId.length >= 50) {
                displayAltId = displayAltId.substr(0,50);
                max = true;
                return false;
            }
            return true;
        }
    )
    if(!max) {
        displayAltId = displayAltId.substr(0, displayAltId.length - 2);
    }
    return max ? (<div>
        <div title={displayAltId}>{displayAltId}</div> <span id={`more-link-${row.rowId}`} className="more-link" onClick={openAlternateDisplayDetails}>...more</span></div>) : <div title={displayAltId}>{displayAltId}</div>;
}

function PhoneNumber(props) {
    const { row } = props;
    let phoneNumber;
    if(row.original.phoneNumber) {
        phoneNumber = row.original.phoneNumber.phoneNumber;
    } else {
        phoneNumber = '';
    }
    return <div title={phoneNumber}> {!isNaN(phoneNumber) ? <NumberFormatter number={phoneNumber} /> : phoneNumber}</div>;
}

function DeviceUsersTable(props) {
    const {
        loading, 
        deviceUsers, 
        fetchDeviceUsersList, 
        deviceUsersPageCount, 
        currentSortColumn, 
        setCurrentSortColumn, 
        sortColumns, 
        setSortColumns,
        page,
        setPage
    } = props;

    const columns = [
        {
            Header: <div id="users-email">Email / HELD+ User ID</div>,
            id: "email",
            sortable: true,
            Cell: row => (row.original.email && row.original.heldUserId) || row.original.email ? 
                        <div title={row.original.email}>{row.original.email}</div> :
                        <div title={row.original.heldUserId}>{row.original.heldUserId}</div>
        }];

    if(props.alternateDeviceUserIdEnabled) {
        columns.push({
            Header: <div id="users-phone-number">Phone Number / Device User IDs</div>,
            id: "phone-number",
            Cell: row => <AlternateDeviceUserIdRow setCurrentModal={props.setCurrentModal} row={row}/>
        })
    }
    else {
        columns.push({
            Header: <div id="users-phone-number">Phone Number</div>,
            id: "phone-number",
            Cell: row => <PhoneNumber row={row}/>
        });
    }

    columns.push(
        {
            Header: <div id="users-first-name">First Name</div>,
            id: "first-name",
            Cell: row => <div title={row.original.firstName}>{row.original.firstName}</div>
        },
        {
            Header: <div id="users-last-name">Last Name</div>,
            id: "last-name",
            Cell: row => <div title={row.original.lastName}>{row.original.lastName}</div>
        },
        {
            Header: <div id="users-current-location">Current Location</div>,
            id: "current-location",
            minWidth: 150,
            Cell: row => {
                return <LocationDetailBox 
                            row={row}
                            location={row.original.currentEmergencyResponseLocation}
                            discoveryType={row.original.discoveryType}
                            discoveryValue={row.original.discoveryValue}
                            locationSetMethod={row.original.locationSetMethod}
                            locationSetTime={row.original.locationSetTime}
                        />;
            }
        },
        {
            Header: <div id="users-last-access-time">Last Access Time(User)</div>,
            id: "last-access-time",
            minWidth: 250,
            Cell: row => <div title={formatLastAccess(row.original.lastAccess)}>{formatLastAccess(row.original.lastAccess)}</div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                <DeviceUsersEditMenu row={row} setCurrentModal={props.setCurrentModal} />
            )
        }
    );

    function formatLastAccess(lastAccess) {
        return lastAccess ?  moment(lastAccess).format('MM/DD/YYYY hh:mm:ss A') : '';
    } 

    let deviceUsersArray = [];

    if (deviceUsers) {
        deviceUsersArray = deviceUsers.toArray();
    }

    useEffect(() => {
        if (deviceUsersArray && deviceUsersArray.length === 0 && page > 0 && deviceUsersPageCount < page+1) {
            setPage(page-1)
        }
    }, [deviceUsersArray && deviceUsersArray.length === 0 && page > 0 && deviceUsersPageCount < page+1]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    function handleKeyDown(e, rowInfo) {
        if ((e.key === ' ' || e.key === 'Enter') && document.activeElement.tagName !== 'BUTTON') {
            e.preventDefault();

            const expandElm = document.querySelectorAll('.rt-expandable')[rowInfo.index];

            if (expandElm) {
                expandElm.click();
            }
        }
    }

    useEffect( () => {
        fetchDeviceUsersList(props.page+1, props.pageSize, props.searchValue, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: props.page+1, 
            pageSize: props.pageSize, 
            sortBy: currentSortColumn.id, 
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, props.pageSize, props.page]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight device-users"
            showPaginationBottom={true}
            data={deviceUsersArray}
            minRows={deviceUsersArray && deviceUsersArray.length > 0 ? 0 : 5}
            pages={deviceUsersPageCount <= 0 ? 1 : deviceUsersPageCount}
            page={props.page}
            defaultPageSize={25}
            columns={columns}
            manual
            loading={loading}
            onPageSizeChange={(pageSize) => {
                props.setPageSize(pageSize)
                props.setPage(0);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setCurrentPage(pageIndex + 1);
                props.setPage(pageIndex);
                resetScrollInsideTable();
            }}
            LoadingComponent={ReactTableLoadingComponent}
            
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
            }}
            SubComponent={
                row => {
                    return(
                        <div style={{padding: "20px" }}>
                            <DeviceUsersTabs row={row} {...props} />
                        </div>
                    )
                }
            }
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        rowInfo.rowId = 'device-users-row-' + rowInfo.original.id;

                        if(rowInfo && rowInfo.original.devices.length === 0) {
                            return {
                                id: rowInfo.rowId,
                                style: {
                                    background: 'rgba(118, 111, 111, 0.25)',
                                    border: '1px solid grey'
                                },
                                tabIndex: 0,
                                onKeyDown: e => {handleKeyDown(e, rowInfo)}
                            };
                        } else if(rowInfo && rowInfo.original.email && !rowInfo.original.currentEmergencyResponseLocation) {
                            return {
                                id: rowInfo.rowId,
                                style: {
                                    background: 'rgba(255, 70, 71, 0.45)',
                                    border: '1px solid red'
                                },
                                tabIndex: 0,
                                onKeyDown: e => {handleKeyDown(e, rowInfo)}
                            };
                        } else {
                            return {
                                id: rowInfo.rowId,
                                tabIndex: 0,
                                onKeyDown: e => {handleKeyDown(e, rowInfo)}
                            };
                        }
                    } else {
                        return {};
                    }
                }
            }
        />
    );
}

const mapStateToProps = createStructuredSelector({
    deviceUsers: makeSelectDeviceUsersList(),
    deviceUsersPageCount: makeSelectDeviceUsersPageCount(),
    loading: makeSelectDeviceUsersListLoading(),
    alternateDeviceUserIdEnabled: selectAlternateIdEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchDeviceUsersList: (page, pageSize, searchTerm, sortBy, direction) => dispatch(DeviceUserActions.getDeviceUsersRequest(page, pageSize, searchTerm, sortBy, direction)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceUsersTable);

