import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as DeviceUsersActions, makeSelectDeviceUsersDevicesList, makeSelectDeviceUsersDevicesListLoading } from '../../reducers/deviceUsers';
import { createStructuredSelector } from 'reselect';
import DeviceUsersDevicesListTable from '../../containers/DeviceUsersDevicesListTable/deviceUsersDevicesListTable';

class DeviceUsersDevicesList extends Component {
    render() {
        return <DeviceUsersDevicesListTable 
                    deviceUserId={this.props.deviceUserId} 
                    devices={this.props.devices} 
                    loading={this.props.loading} 
                    setCurrentModal={this.props.setCurrentModal}
                    currentDeviceId={this.props.currentDeviceId}
                    parentRowUserData={this.props.parentRowUserData}/>
    }
}

DeviceUsersDevicesList.propTypes = {
    deviceUserId: PropTypes.string.isRequired,
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({       
        devices: makeSelectDeviceUsersDevicesList(ownProps.deviceUserId),
        loading: makeSelectDeviceUsersDevicesListLoading(ownProps.deviceUserId)
    });
}

export default connect(mapStateToProps)(DeviceUsersDevicesList);