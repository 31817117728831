import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { Formik } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions, makeSelectMultipleAddressList, selectAddressIsAmbiguous, selectAddressIsAlternate } from '../../../reducers/locations';
import { selectUserCompany } from '../../../reducers/auth';
import AddressAndGeoComponent from '../../../components/AddressAndGeoComponent/AddressAndGeoComponent';
import { BuildingValidation } from './Validations';

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    autoCompleteOptions: {
        width: '355px'
    },
    paper: {
        position: 'absolute',
        zIndex: 999,
        marginTop: theme.spacing.unit,
    }
});

const AddBuildingForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        setFieldValue,
        setFieldTouched,
        multipleAddresses,
        modalErrorOptions,
        isAmbiguous,
        setAddressIsAlternate,
        isAlternate,
        overrideOrganizationNameToggle,
        setOverrideOrganizationNameToggle,
        classes } = props;

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    function handleOverrideOrganizationNameToggle() {
        setOverrideOrganizationNameToggle(!overrideOrganizationNameToggle);
    }

    function handleClear() {
        if (modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) {
            modalErrorOptions.backendFieldError.name = '';
        }
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <TextField
                id="name"
                label="*Building Name"
                name="name"
                type="name"
                onChange={(e) => {
                    handleChange(e);
                    handleClear();
                }}
                value={values.name}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) || (modalErrorOptions && !!modalErrorOptions.backendFieldError && !!modalErrorOptions.backendFieldError.name)} />
                {(modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) && 
                    <span id="name-helper-text" className="error-prompt">{modalErrorOptions.backendFieldError.name}</span>
                }
                <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <br/>
            <AddressAndGeoComponent
                {...props}
                addressIsAlternate={isAlternate}
                setAddressIsAlternate={setAddressIsAlternate}
                addressIsAmbiguous={isAmbiguous}
                multipleAddressList={multipleAddresses}
            />
            <TextField
                id="supplemental"
                label="Supplemental Information"
                name="supplemental"
                onChange={handleChange}
                value={values.supplemental}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.supplemental && Boolean(errors.supplemental)) || (errors.supplemental && Boolean(errors.supplemental.includes('max')))}/>
                <span id="supplemental-helper-text" className="error-prompt">{(touched.supplemental && Boolean(errors.supplemental)) || (errors.supplemental && Boolean(errors.supplemental.includes('max'))) ? errors.supplemental : ''}</span>
            <br/>
            <br/>
            <label htmlFor="overrideOrganizationNameToggle">Override Organization Name</label>
            <Switch
                id="overrideOrganizationNameToggle"
                name="overrideOrganizationNameToggle" /* important! this is how handleChange finds the field to update */
                color="primary"
                checked={overrideOrganizationNameToggle}
                onChange={handleOverrideOrganizationNameToggle}
                value='overrideOrganizationNameToggle'
                disabled={modalLoading}
            /> 
            {
                overrideOrganizationNameToggle && 
                <div>
                    <TextField
                        id="overrideOrganizationName"
                        label="Organization Name"
                        name="overrideOrganizationName"
                        onChange={handleChange}
                        onKeyDown={e => {
                            if (e.key === '\\') {
                                e.preventDefault();
                            }
                        }}
                        onPaste={e => {
                            const str = e.clipboardData.getData('Text');
                            const newStr = str.replace("\\", '');
                            if (str !== newStr) {
                                e.preventDefault()
                            }
                        }}
                        value={values.overrideOrganizationName}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={(touched.overrideOrganizationName && Boolean(errors.overrideOrganizationName)) || (errors.overrideOrganizationName && Boolean(errors.overrideOrganizationName.includes('max')))}/>
                    <span id="overrideOrganizationName-helper-text" className="error-prompt">{(touched.overrideOrganizationName && Boolean(errors.overrideOrganizationName)) || (errors.overrideOrganizationName && Boolean(errors.overrideOrganizationName.includes('max'))) ? errors.overrideOrganizationName : ''}</span>
                </div>
            }
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}
const AddBuildingModal = props => {
    const { modalLoading, classes, autoComplete, autoCompleteOptions, multipleAddresses, modalErrorMessage, isAmbiguous, setAddressIsAmbiguous, setAddressIsAlternate, isAlternate } = props;

    const [overrideOrganizationNameToggle, setOverrideOrganizationNameToggle] = useState(false);
    const [geoCordsAreRequired, setGeoCordsAreRequired] = useState(true);
    const [addressIsRequired, setAddressIsRequired] = useState(true);
    const [addressAndGeoCoordsToggle, setAddressAndGeoCoordsToggle] = useState('address');

    const formRef = useRef();

    useEffect(() => {
        return () => {
            setAddressIsAmbiguous(false);
            setAddressIsAlternate(false);
        }
    }, []);

	return(
        <Formik
            innerRef={formRef}
            initialValues={{ 
                name: '',
                address: '',
                houseNumber: '',
                houseNumberExtension: '',
                prefixDirection: '',
                streetType: '',
                postDirection: '',
                street: '',
                city: '',
                stateProvince: '',
                zip: '',
                country: 'US',
                latitude: '',
                longitude: '',
                supplemental: '',
                overrideOrganizationName: '',
                multipleAddressSelect: ''
            }}
            validationSchema={BuildingValidation(isAlternate, isAmbiguous, formRef.current && formRef.current.values.multipleAddressSelect, geoCordsAreRequired, addressIsRequired)}
            onSubmit = {
                (values) => {
                    if (values.multipleAddressSelect) {
                        values.address = JSON.parse(values.multipleAddressSelect);
                    }
                    if(!overrideOrganizationNameToggle) {
                        values.overrideOrganizationName=undefined
                    }
                    props.submitAddBuildingForm(values);   
                }
            }
			render={formikProps => <AddBuildingForm 
                                        {...formikProps}
                                        addressAndGeoCoordsToggle={addressAndGeoCoordsToggle}
                                        setAddressAndGeoCoordsToggle={setAddressAndGeoCoordsToggle}
                                        addressIsRequired={addressIsRequired}
                                        setAddressIsRequired={setAddressIsRequired}
                                        geoCordsAreRequired={geoCordsAreRequired}
                                        setGeoCordsAreRequired={setGeoCordsAreRequired}
                                        modalErrorOptions={props.modalErrorOptions}
										autoCompleteOptions={autoCompleteOptions}
										autoComplete={autoComplete} 
										classes={classes} 
                                        modalLoading={modalLoading}
                                        modalErrorMessage={modalErrorMessage}
                                        multipleAddresses={multipleAddresses}
                                        isAmbiguous={isAmbiguous}
                                        setAddressIsAlternate={setAddressIsAlternate}
                                        isAlternate={isAlternate}
                                        overrideOrganizationNameToggle={overrideOrganizationNameToggle}
                                        setOverrideOrganizationNameToggle={setOverrideOrganizationNameToggle}
                                        company={props.company}
										 />}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    multipleAddresses: makeSelectMultipleAddressList(),
    isAmbiguous: selectAddressIsAmbiguous(),
    isAlternate: selectAddressIsAlternate(),
    company: selectUserCompany()
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddBuildingForm: (building) => dispatch(actions.addBuildingRequest(building)),
        setAddressIsAmbiguous: (flag) => dispatch(actions.addressIsAmbiguous(flag)),
        setAddressIsAlternate: (flag) => dispatch(actions.addressIsAlternate(flag))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddBuildingModal);