import React from 'react';
import './licenseWarningBanner.css';

function LicenseWarningBanner(props) { 
    return (
        <div className="warning-banner">
        YOUR ORGANIZATION IS APPROACHING OR CURRENTLY IS OVER ITS USER AND/OR LOCATION LICENSING LIMIT. 
        PLEASE SEE BELOW.
        </div>
    );
}

export default (LicenseWarningBanner);