import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actions as LicenseInfoActions, makeSelectLicenseInfoList, makeSelectLicenseInfoListLoading } from '../../../reducers/homepage';
import { selectUserCompany } from '../../../reducers/auth';
import { selectEmergencyCallAssistEnabled } from '../../../reducers/app';
import ManageLicensesStatsTable from '../../../containers/ManageLicensesStatsTable/ManageLicensesStatsTable';
import LicenseWarningBanner from './licenseWarningBanner';

function LicenseInfoSection(props) { 

    // true if licenses exceeds threshold
    const usageBasedLicensing = props.company.toJS().usageBasedLicensing;
    const [userLicenseThreshold, setUserLicenseThreshold] = useState(false);
    const [locationLicenseThreshold, setLocationLicenseThreshold] = useState(false);

    useEffect(() => {
        props.fetchLicenseInfoList();
    }, [props.company.toJS().id]);

    useEffect(() => {
        if (!usageBasedLicensing) {
            const licenseInfoObject = props.licenseInfo.toJS();
            const map = buildLicenseInfoMap(licenseInfoObject);
            
            const userLicenses = map.get("User");
            const numOfDeviceUsersCount = map.get("numOfDeviceUsers");
            if(numOfDeviceUsersCount > 0 && 
                (userLicenses == 0 || calculateRemainingPercent(userLicenses, numOfDeviceUsersCount) <= 5)) {
                setUserLicenseThreshold(true);
            }
            else {
                setUserLicenseThreshold(false);
            }

            const locationLicenses = map.get("Location");
            const numOfCERLocationsCount = map.get("numOfCERLocations");
            const numOfCorporateLocationsCount = map.get("numOfCorporateLocations");
            const totalLocationInUse = numOfCERLocationsCount + numOfCorporateLocationsCount;
            
            if(totalLocationInUse > 0 && 
                (locationLicenses == 0 || calculateRemainingPercent(locationLicenses, totalLocationInUse) <= 5)) {
                setLocationLicenseThreshold(true);
            }
            else {
                setLocationLicenseThreshold(false);
            }
        }
    }, [props.licenseInfo])

    function buildLicenseInfoMap(licenseInfo) {
        const map = new Map();
        licenseInfo.map((item) => { 
            map.set(item.type, item.count);
        });
        return map;
    }

    function calculateRemainingPercent(total, inUse) {
        return ((total - inUse) / total) * 100;
    }

    return (
        <div>
            <h2 id="license-information-header">License Information</h2>
            { (userLicenseThreshold || locationLicenseThreshold) && <LicenseWarningBanner/>}
            <div>
                <ManageLicensesStatsTable
                    licenseInfo={props.licenseInfo.toJS()}
                    company={props.company}
                    userLicenseThreshold={userLicenseThreshold} 
                    locationLicenseThreshold={locationLicenseThreshold}
                    emergencyCallAssistEnabled={props.companyFeatures}
                />
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    licenseInfo: makeSelectLicenseInfoList(),
    loading: makeSelectLicenseInfoListLoading(),
    company: selectUserCompany(),
    companyFeatures: selectEmergencyCallAssistEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchLicenseInfoList: () => dispatch(LicenseInfoActions.getLicenseInfoRequest()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseInfoSection);