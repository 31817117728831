import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import HomepageAPI from '../apis/homepageApi';
import CallHistoryAPI from '../apis/callHistoryApi';
import EventsAPI from '../apis/eventsApi';
import LicensesAPI from '../apis/licensingServiceApi';
import { userCompanyId } from '../reducers/auth';
import { actions as HomepageActions,
		 types as HomepageTypes } from '../reducers/homepage';
import { actions as AppActions } from '../reducers/app';
import pageErrorHandler from '../utils/pageErrorHandler';
import convertLicenseCountResponse from '../utils/licenseTypeUtil';

function* getCallHistoryList(action) {
	try {
		const companyId = yield select(userCompanyId());
		let response;
		if (!companyId) {
			response = yield call(CallHistoryAPI.getCallHistory, action.page, action.pageSize);
		} else {
			response = yield call(CallHistoryAPI.getCallHistoryByCompanyId, companyId, action.page, action.pageSize);
		}
		yield put(HomepageActions.getCallHistorySuccess(response.data, response.headers['x-pagination-count']));	
	} catch (error) {
		yield put(HomepageActions.getCallHistoryError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getCompanySummaryList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(HomepageAPI.getCompanySummary, companyId);
		yield put(HomepageActions.getCompanySummarySuccess(response.data));
	} catch (error) {
		yield put(HomepageActions.getCompanySummaryError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getEventsList(action) {
	try {
		const companyId = yield select(userCompanyId());
		let response;
		if (!companyId) {
			response = yield call(EventsAPI.getEvents, action.page, action.pageSize, action.startDate, action.endDate);
		} else {
			response = yield call(EventsAPI.getEventsByCompanyId, companyId, action.page, action.pageSize, action.startDate, action.endDate);
		}
		yield put(HomepageActions.getEventsSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(HomepageActions.getEventsError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getImportStatusList(action) {
	try {
		const companyId = yield select(userCompanyId());
		let response;
		if (companyId) {
			response = yield call(HomepageAPI.getImportingByCompanyId, companyId);
			yield put(HomepageActions.getImportStatusSuccess(response.data));
		} else {
			response = yield call(HomepageAPI.getImporting);
			yield put(HomepageActions.getImportStatusSuccess(response));
		}
	} catch (error) {
		yield put(HomepageActions.getImportStatusError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getLicenseInfoList() {
	try {
		const companyId = yield select(userCompanyId());

		const response1 = yield call(LicensesAPI.getLicenseCount, companyId);
		const response2 = yield call(LicensesAPI.getLicenseCountLocation, companyId);
		const response3 = yield call(LicensesAPI.getLicenseCountUser, companyId);
		const response4 = yield call(HomepageAPI.getCompanySummary, companyId);

		response2.data.FlexLocation = response2.data.Flex;
		response3.data.FlexUser = response3.data.Flex;

		const combinedArray = [...convertLicenseCountResponse(response1.data), ...convertLicenseCountResponse(response2.data), ...convertLicenseCountResponse(response3.data), ...convertLicenseCountResponse(response4.data)];

		yield put(HomepageActions.getLicenseInfoSuccess(combinedArray));
	} catch (error) {
		yield put(HomepageActions.getLicenseInfoError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getRsrcList(action) {
	try {
		const response = yield call(HomepageAPI.getRsrc, action.page, action.pageSize);
		yield put(HomepageActions.getRsrcSuccess(response.data));
	} catch (error) {
		yield put(HomepageActions.getRsrcError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getSystemSummaryList(action) {
	try {
		const response = yield call(HomepageAPI.getSystemSummary);
		yield put(HomepageActions.getSystemSummarySuccess(response.data));
	} catch (error) {
		yield put(HomepageActions.getSystemSummaryError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* rootLocationsSaga() {
	yield all([
		yield takeLatest(HomepageTypes.GET_CALL_HISTORY_REQUEST, getCallHistoryList),
		yield takeLatest(HomepageTypes.GET_COMPANY_SUMMARY_REQUEST, getCompanySummaryList),
		yield takeLatest(HomepageTypes.GET_EVENTS_REQUEST, getEventsList),
		yield takeLatest(HomepageTypes.GET_IMPORT_STATUS_REQUEST, getImportStatusList),
		yield takeLatest(HomepageTypes.GET_LICENSE_INFO_REQUEST, getLicenseInfoList),
		yield takeLatest(HomepageTypes.GET_RSRC_REQUEST, getRsrcList),
		yield takeLatest(HomepageTypes.GET_SYSTEM_SUMMARY_REQUEST, getSystemSummaryList)
	]);
}

export default rootLocationsSaga;
