/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';

export const LOCATIONS = 'LOCATIONS/'; 
export const types = {
    ADD_BUILDING: LOCATIONS + 'ADD_BUILDING',
    ADD_MULTIPLE_ADDRESSES: LOCATIONS + 'ADD_MULTIPLE_ADDRESSES',
    GET_BUILDINGS_REQUEST: LOCATIONS + 'GET_BUILDINGS_REQUEST',
    GET_BUILDINGS_SUCCESS: LOCATIONS + 'GET_BUILDINGS_SUCCESS',
    ADD_LOCATION: LOCATIONS + 'ADD_LOCATION',
    GET_LOCATIONS_REQUEST: LOCATIONS + 'GET_LOCATIONS',
    GET_LOCATIONS_SUCCESS: LOCATIONS + 'GET_LOCATIONS_SUCCESS',
    GET_LOCATIONS_ERROR: LOCATIONS + 'GET_LOCATIONS_ERROR',
    DELETE_LOCATION: LOCATIONS + 'DELETE_LOCATION',
    DELETE_LOCATION_CER: LOCATIONS + 'DELETE_LOCATION_CER',
    EDIT_BUILDING: LOCATIONS + 'EDIT_BUILDING',
    EDIT_BUILDING_SUCCESS: LOCATIONS + 'EDIT_BUILDING_SUCCESS',
    DELETE_BUILDING: LOCATIONS + 'DELETE_BUILDING',
    DELETE_BUILDING_SUCCESS: LOCATIONS + 'DELETE_BUILDING_SUCCESS',
    GET_LOCATION_USER_COUNT: LOCATIONS + 'GET_LOCATION_USER_COUNT',
    GET_LOCATION_USER_COUNT_SUCCESS: LOCATIONS + 'GET_LOCATION_USER_COUNT_SUCCESS',
    GET_LOCATION_COUNT: LOCATIONS + 'GET_LOCATION_COUNT',
    GET_LOCATION_COUNT_SUCCESS: LOCATIONS + 'GET_LOCATION_COUNT_SUCCESS',
    EDIT_LOCATION: LOCATIONS + 'EDIT_LOCATION',
    EDIT_LOCATION_CER: LOCATIONS + 'EDIT_LOCATION_CER',
    GET_BUILDINGS_ERROR: LOCATIONS + 'GET_BUILDINGS_ERROR',
    ADDRESS_IS_AMBIGUOUS: LOCATIONS + 'ADDRESS_IS_AMBIGUOUS',
    ALTERNATE_ADDRESS_VIEW: LOCATIONS + 'ALTERNATE_ADDRESS_VIEW',
    RESCAN_ADDRESS_BUILDING: LOCATIONS + 'RESCAN_ADDRESS_BUILDING',
    RESCAN_ADDRESS_LOCATION: LOCATIONS + 'RESCAN_ADDRESS_LOCATION',
    BUILDING_DETAILS: LOCATIONS + 'BUILDING_DETAILS',
    LOCATION_DETAILS: LOCATIONS + 'LOCATION_DETAILS',
    CLEAR_LOCATIONS_STATE: LOCATIONS + 'CLEAR_STATE'
};

export const initialState = fromJS({
    buildingsList: List(),
    buildingsLoading: false,
    buildingsPageCount: -1,
    locationsList: Map(),
    deleteLocationUserCount: -1,
    locationCount: -1,
    locationPageCount: {},
    multipleAddressList: List(),
    addressIsAmbiguous: false,
    addressIsAlternate: false
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_BUILDINGS_REQUEST:
            return state.set('buildingsLoading', true);
        case types.ADD_MULTIPLE_ADDRESSES:
            return state.set('multipleAddressList', action.addresses);
        case types.GET_BUILDINGS_SUCCESS:
            return state.set('buildingsLoading', false)
                        .set('buildingsList', List(action.data))
                        .set('buildingsPageCount', action.buildingsPageCount)
        case types.GET_BUILDINGS_ERROR:
            return state.set('buildingsLoading', false)
        case types.GET_LOCATIONS_REQUEST:
            return state.setIn(['locationsList', action.organizationUnitId, 'loading'], true);
		case types.GET_LOCATIONS_SUCCESS:
            return state.setIn(['locationsList', action.organizationUnitId, 'list'], List(action.locationsList))
                        .setIn(['locationsList', action.organizationUnitId, 'loading'], false)
                        .setIn(['locationPageCount', action.organizationUnitId], action.pageCount)
        case types.GET_LOCATIONS_ERROR:
            return state.setIn(['locationsList', action.organizationUnitId, 'loading'], false)	
        case types.GET_LOCATION_USER_COUNT_SUCCESS:
            return state.set('deleteLocationUserCount', action.data);
        case types.GET_LOCATION_COUNT_SUCCESS:
            return state.set('locationCount', action.data);
        case types.ADDRESS_IS_AMBIGUOUS:
            return state.set('addressIsAmbiguous', action.data);
        case types.ALTERNATE_ADDRESS_VIEW:
            return state.set('addressIsAlternate', action.data);
        case types.CLEAR_LOCATIONS_STATE:
            return state.set('buildingsList', List())
                        .set('locationsList', Map())
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    addBuildingRequest: (building) => ({ 
            type: types.ADD_BUILDING, 
            data: building
    }),
    multipleAddressRequest: (addresses) => ({ 
            type: types.ADD_MULTIPLE_ADDRESSES, 
            addresses
    }),
    editBuildingRequest: (building) => ({
        type: types.EDIT_BUILDING,
        data: building
    }),
    deleteBuildingRequest: (id, parentId, origin) => ({
        type: types.DELETE_BUILDING,
        data: id,
        parentId,
        origin
    }),
    deleteBuildingSuccess: () => ({
        type: types.DELETE_BUILDING_SUCCESS
    }),
    rescanAddressBuildingRequest: (addressId) => ({
        type: types.RESCAN_ADDRESS_BUILDING,
        data: addressId
    }),
    rescanAddressLocationRequest: (addressId) => ({
        type: types.RESCAN_ADDRESS_LOCATION,
        data: addressId
    }),
    getBuildingsRequest: (page, pageSize, searchTerm, sortBy, direction, origin) => ({
        type: types.GET_BUILDINGS_REQUEST,
        page,
        pageSize,
        searchTerm,
        sortBy,
        direction,
        origin
    }),
    getBuildingsSuccess: (buildingList, buildingsPageCount) => ({
        type: types.GET_BUILDINGS_SUCCESS,
        data: buildingList,
        buildingsPageCount
    }),
    getBuildingsError: () => ({
        type: types.GET_BUILDINGS_ERROR
    }),
    addLocationRequest: (location) => ({ 
        type: types.ADD_LOCATION, 
        data: location
    }),
    getLocationsRequest: (organizationUnitId, origin, page, pageSize, searchTerm, sortBy, direction) => ({
        type: types.GET_LOCATIONS_REQUEST,
        organizationUnitId,
        origin,
        page,
        pageSize,
        searchTerm,
        sortBy,
        direction,
    }),
    getLocationsSuccess: (locationsList, organizationUnitId, pageCount) => ({
        type: types.GET_LOCATIONS_SUCCESS,
        locationsList,
        organizationUnitId,
        pageCount
    }),
    deleteLocationRequest: (id, parentId, origin, buildingFlag) => ({
        type: types.DELETE_LOCATION,
        data: id,
        parentId,
        origin,
        buildingFlag
    }),
    getLocationsError: (buildingId) => ({
        type: types.GET_LOCATIONS_ERROR,
        buildingId
    }),
    getLocationUserCount: (locationId) => ({ 
        type: types.GET_LOCATION_USER_COUNT, 
        data: locationId
    }),
    getLocationUserCountSuccess: (userCount) => ({ 
        type: types.GET_LOCATION_USER_COUNT_SUCCESS, 
        data: userCount
    }),
    getLocationCount: (organizationUnit) => ({ 
        type: types.GET_LOCATION_COUNT, 
        data: organizationUnit
    }),
    getLocationCountSuccess: (locationCount) => ({ 
        type: types.GET_LOCATION_COUNT_SUCCESS, 
        data: locationCount
    }),
    editLocationRequest: (location, buildingFlag, origin) => ({
        type: types.EDIT_LOCATION,
        data: location,
        buildingFlag,
        origin
    }),
    addressIsAmbiguous: (flag) => ({
        type: types.ADDRESS_IS_AMBIGUOUS,
        data: flag
    }),
    addressIsAlternate: (flag) => ({
        type: types.ALTERNATE_ADDRESS_VIEW,
        data: flag
    }),
    clearLocationsState: () => ({
        type: types.CLEAR_LOCATIONS_STATE
    })
};

// Selectors
const selectLocations = state => state.get('locations', initialState);

// Location page General selectors
export const makeSelectBuildingsPageCount = () =>
    createSelector(selectLocations, buildingsState => buildingsState.get('buildingsPageCount'));

// E911 Anywhere Locations selectors
export const makeSelectBuildingsList = () =>
    createSelector(selectLocations, buildingsState => buildingsState.get('buildingsList'));

export const makeSelectBuildingsListLoading = () =>
    createSelector(selectLocations, buildingsState => buildingsState.get('buildingsLoading'));

export const selectLocationsList =
	createSelector(selectLocations, locationsState => locationsState.get('locationsList'));

export const selectLocationsByParentId = (parentId) => 
	createSelector(selectLocationsList, (locationsList) => locationsList.getIn([parentId, 'list']));

export const locationsLoading = (parentId) =>
	createSelector(selectLocationsList, (locationsList) => locationsList.getIn([parentId, 'loading']));

// Modal Selectors
export const makeSelectMultipleAddressList = () =>
    createSelector(selectLocations, buildingsState => buildingsState.get('multipleAddressList'));

export const selectDeleteLocationUserCount = () =>
    createSelector(selectLocations, locationsState => locationsState.get('deleteLocationUserCount'));

export const selectLocationCount = () =>
	createSelector(selectLocations, locationsState => locationsState.get('locationCount'));

export const selectAddressIsAmbiguous = () =>
	createSelector(selectLocations, locationsState => locationsState.get('addressIsAmbiguous'));

export const selectAddressIsAlternate = () =>
	createSelector(selectLocations, locationsState => locationsState.get('addressIsAlternate'));

export const makeSelectLocationPageCount = () =>
    createSelector(selectLocations, locationsState => locationsState.get('locationPageCount'));