import { axiosInstance } from './api';
/* Cirrus Admin-Service /locations Controller */
const REPORTS_ENDPOINT = 'admin-service/reports';

export default class ReportsAPI {
    static generateReport(report) {
        const payload = {
            companyId: report.companyId,
            subtenants: report.subtenants,
            start: report.start,
            end: report.end,
            reportType: report.reportType,
            timezone: report.timeZoneType,
            locationOrigin: report.locationOrigin
        };
        return axiosInstance.post(REPORTS_ENDPOINT, payload);
    }
    
    static scheduleReport(report) {
        const payload = {
            companyId: report.companyId,
            subtenants: report.hasSubtenants,
            dataRange: report.dataRange,
            emailRecipients: report.emailList.map(item => ( item.emailRecipient )),
            emailSubject: report.emailSubject,
            name: report.scheduledReportName,
            reportType: report.reportType,
            scheduleType: 'CRON',
            schedule: report.cronValue,
            timezone: report.timeZoneType,
            locationOrigin: report.locationOrigin
        };
        return axiosInstance.post(REPORTS_ENDPOINT, payload);
    }
    
    static editScheduleReport(report) {
        const payload = {
            id: report.id,
            companyId: report.companyId,
            subtenants: report.hasSubtenants,
            dataRange: report.dataRange,
            emailRecipients: report.emailList.map(item => ( item.emailRecipient )),
            emailSubject: report.emailSubject,
            name: report.scheduledReportName,
            reportType: report.reportType,
            scheduleType: 'CRON',
            paused: report.paused,
            schedule: report.cronValue,
            timezone: report.timeZoneType,
            locationOrigin: report.locationOrigin
        };
        return axiosInstance.put(REPORTS_ENDPOINT, payload);
    }

    static pauseScheduleReport(report) {
        const payload = {
            id: report.id,
            companyId: report.orgId,
            subtenants: report.subtenants,
            dataRange: report.dataRange,
            emailRecipients: report.recipientList.map(item => ( item.emailRecipient )),
            emailSubject: report.emailSubject,
            name: report.name,
            reportType: report.reportType,
            scheduleType: 'CRON',
            paused: !report.paused,
            schedule: report.schedule,
            timezone: report.timezone
        };
        return axiosInstance.put(REPORTS_ENDPOINT, payload);
    }

    static getReportsById(id) {
        return axiosInstance.get(`${REPORTS_ENDPOINT}/${id}`);
    }
    
    static getReportsByCompanyId(companyId, page, pageSize, sortBy, direction) {
        return axiosInstance.get(`${REPORTS_ENDPOINT}/company/${companyId}?scheduleType=CRON`, {
            params: {
               page,
               pageSize,
               sortBy,
               direction
            }
        });
    }

    static getAllReports(page, pageSize, sortBy, direction) {
        return axiosInstance.get(`${REPORTS_ENDPOINT}/system?scheduleType=CRON`, {
            params: {
               page,
               pageSize,
               sortBy,
               direction
            }
        });
    }

    static deleteScheduleReport(id) {
        return axiosInstance.delete(`${REPORTS_ENDPOINT}/${id}`);
    }

    static getNextRunTime(cronExpr) {
        return axiosInstance.get(`${REPORTS_ENDPOINT}/future-execs?cron=${cronExpr}&count=1`);
    }

    static listFutureExecutions(cronExpr, timestamp, count) {
        return axiosInstance.get(`${REPORTS_ENDPOINT}/future-execs?cron=${cronExpr}${timestamp ? `&timestamp=${timestamp}` : ''}${count ? `&count=${count}` : ''}`);
    }
}