/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';

export const NETWORK_DISCOVERY = 'NETWORK_DISCOVERY/'; 
export const types = {
    ADD_MAC_ADDRESS: NETWORK_DISCOVERY + 'ADD_MAC_ADDRESS',
    EDIT_MAC_ADDRESS: NETWORK_DISCOVERY + 'EDIT_MAC_ADDRESS',
    DELETE_MAC_ADDRESS: NETWORK_DISCOVERY + 'DELETE_MAC_ADDRESS',
    GET_MAC_ADDRESS_REQUEST: NETWORK_DISCOVERY + 'GET_MAC_ADDRESS_REQUEST',
    GET_MAC_ADDRESS_ERROR: NETWORK_DISCOVERY + 'GET_MAC_ADDRESS_ERROR',
    GET_MAC_ADDRESS_SUCCESS: NETWORK_DISCOVERY + 'GET_MAC_ADDRESS_SUCCESS',
    ADD_BSSID: NETWORK_DISCOVERY + 'ADD_BSSID',
    EDIT_BSSID: NETWORK_DISCOVERY + 'EDIT_BSSID',
    DELETE_BSSID: NETWORK_DISCOVERY + 'DELETE_BSSID',
    GET_BSSID_REQUEST: NETWORK_DISCOVERY + 'GET_BSSID_REQUEST',
    GET_BSSID_ERROR: NETWORK_DISCOVERY + 'GET_BSSID_ERROR',
    GET_BSSID_SUCCESS: NETWORK_DISCOVERY + 'GET_BSSID_SUCCESS',
    ADD_IP_RANGE: NETWORK_DISCOVERY + 'ADD_IP_RANGE',
    EDIT_IP_RANGE: NETWORK_DISCOVERY + 'EDIT_IP_RANGE',
    DELETE_IP_RANGE: NETWORK_DISCOVERY + 'DELETE_IP_RANGE',
    GET_IP_RANGE_REQUEST: NETWORK_DISCOVERY + 'GET_IP_RANGE_REQUEST',
    GET_IP_RANGE_ERROR: NETWORK_DISCOVERY + 'GET_IP_RANGE_ERROR',
    GET_IP_RANGE_SUCCESS: NETWORK_DISCOVERY + 'GET_IP_RANGE_SUCCESS',
    ADD_LLDP: NETWORK_DISCOVERY + 'ADD_LLDP',
    EDIT_LLDP: NETWORK_DISCOVERY + 'EDIT_LLDP',
    DELETE_LLDP: NETWORK_DISCOVERY + 'DELETE_LLDP',
    GET_LLDP_REQUEST: NETWORK_DISCOVERY + 'GET_LLDP_REQUEST',
    GET_LLDP_ERROR: NETWORK_DISCOVERY + 'GET_LLDP_ERROR',
    GET_LLDP_SUCCESS: NETWORK_DISCOVERY + 'GET_LLDP_SUCCESS',
    ADD_PORT: NETWORK_DISCOVERY + 'ADD_PORT',
    EDIT_PORT: NETWORK_DISCOVERY + 'EDIT_PORT',
    DELETE_PORT: NETWORK_DISCOVERY + 'DELETE_PORT',
    GET_PORT_REQUEST: NETWORK_DISCOVERY + 'GET_PORT_REQUEST',
    GET_PORT_ERROR: NETWORK_DISCOVERY + 'GET_PORT_ERROR',
    GET_PORT_SUCCESS: NETWORK_DISCOVERY + 'GET_PORT_SUCCESS',
    ADD_MULTIPLE_ADDRESSES: NETWORK_DISCOVERY + 'ADD_MULTIPLE_ADDRESSES',
    ADDRESS_IS_AMBIGUOUS: NETWORK_DISCOVERY + 'ADDRESS_IS_AMBIGUOUS',
    ALTERNATE_ADDRESS_VIEW: NETWORK_DISCOVERY + 'ALTERNATE_ADDRESS_VIEW',
};

export const initialState = fromJS({
    macAddressList: List(),
    macAddressLoading: false,
    bssidList: List(),
    bssidLoading: false,
    ipRangeList: List(),
    ipRangeLoading: false,
    lldpList: List(),
    lldpLoading: false,
    portList: Map(),
    portLoading: false,
    multipleAddressList: List(),
    addressIsAmbiguousNetDisco: false,
    addressIsAlternateNetDisco: false
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_MAC_ADDRESS_REQUEST:
            return state.set('macAddressLoading', true);
        case types.GET_MAC_ADDRESS_ERROR:
            return state.set('macAddressLoading', false);
        case types.GET_MAC_ADDRESS_SUCCESS:
            return state.set('macAddressLoading', false)
                        .set('macAddressList', List(action.data))
                        .set('macAddressPageCount', action.pageCount);
        case types.GET_BSSID_REQUEST:  
            return state.set('bssidLoading', true);
        case types.GET_BSSID_ERROR:
            return state.set('bssidLoading', false);
        case types.GET_BSSID_SUCCESS:
            return state.set('bssidLoading', false)
                        .set('bssidList', List(action.data))
                        .set('bssidPageCount', action.pageCount);
        case types.GET_IP_RANGE_REQUEST:
            return state.set('ipRangeLoading', true);
        case types.GET_IP_RANGE_ERROR:
            return state.set('ipRangeLoading', false);
        case types.GET_IP_RANGE_SUCCESS:
            return state.set('ipRangeLoading', false)
                        .set('ipRangeList', List(action.data))
                        .set('ipRangePageCount', action.pageCount);
        case types.GET_LLDP_REQUEST:
            return state.set('lldpLoading', true);
        case types.GET_LLDP_ERROR:
            return state.set('lldpLoading', false);
        case types.GET_LLDP_SUCCESS:
            return state.set('lldpLoading', false)
                        .set('lldpList', List(action.data))
                        .set('lldpPageCount', action.pageCount);
        case types.GET_PORT_REQUEST:
            return state.setIn(['portList', action.lldpId, 'loading'], true);
        case types.GET_PORT_ERROR:
            return state.setIn(['portList', action.lldpId, 'loading'], false);
		case types.GET_PORT_SUCCESS:
            return state.setIn(['portList', action.lldpId, 'list'], List(action.data))
                        .setIn(['portList', action.lldpId, 'loading'], false);
        case types.ADD_MULTIPLE_ADDRESSES:
            return state.set('multipleAddressList', action.addresses);
        case types.ADDRESS_IS_AMBIGUOUS:
            return state.set('addressIsAmbiguousNetDisco', action.data);
        case types.ALTERNATE_ADDRESS_VIEW:
            return state.set('addressIsAlternateNetDisco', action.data);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    addMacAddressRequest: (macAddress) => ({
            type: types.ADD_MAC_ADDRESS, 
            data: macAddress
    }),
    editMacAddressRequest: (macAddress) => ({ 
            type: types.EDIT_MAC_ADDRESS, 
            data: macAddress
    }),
    getMacAddressRequest: (page, pageSize, companyId, sortBy, direction) => ({
        type: types.GET_MAC_ADDRESS_REQUEST,
        page,
        pageSize,
        companyId,
        sortBy,
        direction
    }),
    getMacAddressError: () => ({
        type: types.GET_MAC_ADDRESS_ERROR,
    }),
    getMacAddressSuccess: (macAddressList, pageCount) => ({
        type: types.GET_MAC_ADDRESS_SUCCESS,
        data: macAddressList,
        pageCount
    }),
    deleteMacAddressRequest: (id, parentId) => ({
        type: types.DELETE_MAC_ADDRESS,
        data: id,
        parentId
    }),
    addBssidRequest: (bssid) => ({
            type: types.ADD_BSSID, 
            data: bssid
    }),
    editBssidRequest: (bssid) => ({ 
            type: types.EDIT_BSSID, 
            data: bssid
    }),
    getBssidRequest: (page, pageSize, companyId, sortBy, direction) => ({
        type: types.GET_BSSID_REQUEST,
        page,
        pageSize,
        companyId,
        sortBy,
        direction
    }),
    getBssidError: () => ({
        type: types.GET_BSSID_ERROR
    }),
    getBssidSuccess: (bssidList, pageCount) => ({
        type: types.GET_BSSID_SUCCESS,
        data: bssidList,
        pageCount
    }),
    deleteBssidRequest: (id, parentId) => ({
        type: types.DELETE_BSSID,
        data: id,
        parentId
    }),
    addIpRangeRequest: (ipRange) => ({
            type: types.ADD_IP_RANGE, 
            data: ipRange
    }),
    editIpRangeRequest: (ipRange) => ({ 
            type: types.EDIT_IP_RANGE, 
            data: ipRange
    }),
    getIpRangeRequest: (page, pageSize, companyId, sortBy, direction, isPublic, searchTerm) => ({
        type: types.GET_IP_RANGE_REQUEST,
        page,
        pageSize,
        companyId,
        sortBy,
        direction,
        isPublic,
        searchTerm
    }),
    getIpRangeError: () => ({
        type: types.GET_IP_RANGE_ERROR
    }),
    getIpRangeSuccess: (ipRangeList, pageCount) => ({
        type: types.GET_IP_RANGE_SUCCESS,
        data: ipRangeList,
        pageCount
    }),
    deleteIpRangeRequest: (id, parentId) => ({
        type: types.DELETE_IP_RANGE,
        data: id,
        parentId
    }),
    addLldpRequest: (lldp) => ({
            type: types.ADD_LLDP, 
            data: lldp
    }),
    editLldpRequest: (lldp) => ({ 
            type: types.EDIT_LLDP, 
            data: lldp
    }),
    getLldpRequest: (page, pageSize, companyId, sortBy, direction) => ({
        type: types.GET_LLDP_REQUEST,
        page,
        pageSize,
        companyId,
        sortBy,
        direction
    }),
    getLldpError: () => ({
        type: types.GET_LLDP_ERROR,
    }),
    getLldpSuccess: (lldpList, pageCount) => ({
        type: types.GET_LLDP_SUCCESS,
        data: lldpList,
        pageCount
    }),
    deleteLldpRequest: (id, parentId) => ({
        type: types.DELETE_LLDP,
        data: id,
        parentId
    }),
    addPortRequest: (port) => ({
            type: types.ADD_PORT, 
            data: port
    }),
    editPortRequest: (port) => ({ 
            type: types.EDIT_PORT, 
            data: port
    }),
    getPortRequest: (lldpId, sortBy, direction) => ({
        type: types.GET_PORT_REQUEST,
        lldpId,
        sortBy,
        direction
    }),
    getPortError: (lldpId) => ({
        type: types.GET_PORT_ERROR,
        lldpId
    }),
    getPortSuccess: (portList, lldpId) => ({
        type: types.GET_PORT_SUCCESS,
        data: portList,
        lldpId
    }),
    deletePortRequest: (id, parentId, sortBy) => ({
        type: types.DELETE_PORT,
        data: id,
        parentId,
        sortBy
    }),
    multipleAddressRequestNetworkDiscovery: (addresses) => ({ 
        type: types.ADD_MULTIPLE_ADDRESSES, 
        addresses
    }),
    addressIsAmbiguousNetDisco: (flag) => ({
        type: types.ADDRESS_IS_AMBIGUOUS,
        data: flag
    }),
    addressIsAlternateNetDisco: (flag) => ({
        type: types.ALTERNATE_ADDRESS_VIEW,
        data: flag
    }),
};

// Selectors
const selectNetworkDiscovery = state => state.get('networkDiscovery', initialState);

export const makeSelectMacAddressList = () =>
    createSelector(selectNetworkDiscovery, macAddressState => macAddressState.get('macAddressList'));

export const makeSelectMacAddressListLoading = () =>
    createSelector(selectNetworkDiscovery, macAddressState => macAddressState.get('macAddressLoading'));

export const makeSelectBssidList = () =>
    createSelector(selectNetworkDiscovery, bssidState => bssidState.get('bssidList'));

export const makeSelectBssidListLoading = () =>
    createSelector(selectNetworkDiscovery, bssidState => bssidState.get('bssidLoading'));

export const makeSelectIpRangeList = () =>
    createSelector(selectNetworkDiscovery, ipRangeState => ipRangeState.get('ipRangeList'));

export const makeSelectIpRangeListLoading = () =>
    createSelector(selectNetworkDiscovery, ipRangeState => ipRangeState.get('ipRangeLoading'));
    
export const makeSelectLldpList = () =>
    createSelector(selectNetworkDiscovery, lldpState => lldpState.get('lldpList'));

export const makeSelectLldpListLoading = () =>
    createSelector(selectNetworkDiscovery, lldpState => lldpState.get('lldpLoading'));
    
export const makeSelectPortList = 
    createSelector(selectNetworkDiscovery, portState => portState.get('portList'));

export const selectPortByParentId = (parentId) => 
	createSelector(makeSelectPortList, (portList) => portList.getIn([parentId, 'list']));

export const portsLoading = (parentId) =>
	createSelector(makeSelectPortList, (portList) => portList.getIn([parentId, 'loading']));

export const makeSelectMultipleAddressListNetworkDiscovery = () =>
    createSelector(selectNetworkDiscovery, buildingsState => buildingsState.get('multipleAddressList'));

export const makeSelectMacAddressPageCount = () =>
    createSelector(selectNetworkDiscovery, macAddressState => macAddressState.get("macAddressPageCount"));

export const makeSelectBssidPageCount = () =>
    createSelector(selectNetworkDiscovery, bssidState => bssidState.get("bssidPageCount"));

export const makeSelectLldpPageCount = () =>
    createSelector(selectNetworkDiscovery, lldpState => lldpState.get("lldpPageCount"));

export const makeSelectIpRangePageCount = () =>
    createSelector(selectNetworkDiscovery, ipRangeState => ipRangeState.get("ipRangePageCount"));

export const selectAddressIsAmbiguousNetDisco = () =>
	createSelector(selectNetworkDiscovery, locationsState => locationsState.get('addressIsAmbiguousNetDisco'));

export const selectAddressIsAlternateNetDisco = () =>
	createSelector(selectNetworkDiscovery, locationsState => locationsState.get('addressIsAlternateNetDisco'));
