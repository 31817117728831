/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */

import { fromJS, List } from "immutable";
import { createSelector } from "reselect";

export const USERS = "USERS/";
export const types = {
  GET_USERS: USERS + "GET_USERS",
  GET_USERS_SUCCESS: USERS + "GET_USERS_SUCCESS",
  GET_USERS_ERROR: USERS + "GET_USERS_ERROR",
  CHECK_SINGLE_SIGNON: USERS + "CHECK_SINGLE_SIGNON",
  CHECK_SINGLE_SIGNON_SUCCESS: USERS + "CHECK_SINGLE_SIGNON_SUCCESS",
  CHECK_SINGLE_SIGNON_ERROR: USERS + "CHECK_SINGLE_SIGNON_ERROR",
  ADD_USER: USERS + "ADD_USER",
  ADD_USER_SUCCESS: USERS + "ADD_USER_SUCCESS",
  ADD_USER_ERROR: USERS + "ADD_USER_ERROR",
  EDIT_USER: USERS + "EDIT_USER",
  EDIT_USER_SUCCESS: USERS + "EDIT_USER_SUCCESS",
  DELETE_USER: USERS + "DELETE_USER",
  DELETE_USER_SUCCESS: USERS + "DELETE_USER_SUCCESS",
  GET_COMPANY_LIST: USERS + "GET_COMPANY_LIST",
  GET_COMPANY_LIST_SUCCESS: USERS + "GET_COMPANY_LIST_SUCCESS",
  RESEND_USER_WELCOME_EMAIL_REQUEST: USERS + 'RESEND_USER_WELCOME_EMAIL_REQUEST'
};

export const initialState = fromJS({
  usersList: List(),
  checkSSO: false,
  companyList: List(),
  usersListLoading: false
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS:
      return state.set("usersListLoading", true);
    case types.GET_USERS_SUCCESS:
      return state
        .set("usersList", List(action.users))
        .set("usersListLoading", false)
        .set("usersPageCount", action.pageCount);
    case types.GET_USERS_ERROR:
      return state.set("usersListLoading", false);
    case types.CHECK_SINGLE_SIGNON:
      return state.set("checkStatusLoading", true);
    case types.CHECK_SINGLE_SIGNON_SUCCESS:
      return state.set("checkSSO", action.data)
    case types.CHECK_SINGLE_SIGNON_ERROR:
      return state.set("checkStatusLoading", false);
  default:
      return state;
  }
};

// Action Creators
export const actions = {
  getUsersRequest: (page, pageSize, searchTerm, sortBy, sortDirection) => ({
    type: types.GET_USERS,
    page,
    pageSize,
    searchTerm,
    sortBy,
    sortDirection
  }),
  usersLoaded: (users, pageCount) => ({
    type: types.GET_USERS_SUCCESS,
    users,
    pageCount
  }),
  usersLoadingError: () => ({
    type: types.GET_USERS_ERROR,
  }),
  checkSingleSignOn: (companyId) => ({
    type: types.CHECK_SINGLE_SIGNON,
    companyId
  }),
  checkSingleSignOnLoaded: (data) => ({
    type: types.CHECK_SINGLE_SIGNON_SUCCESS,
    data
  }),
  checkSingleSignOnError: () => ({
    type: types.CHECK_SINGLE_SIGNON_ERROR,
  }),
  addUserRequest: user => ({
    type: types.ADD_USER,
    data: user
  }),
  addUsersSuccess: () => ({
    type: types.ADD_USER_SUCCESS
  }),
  addUserError: error => ({
    type: types.ADD_USER_ERROR,
    error: error.data
  }),
  editUserRequest: (user, row) => ({
    type: types.EDIT_USER,
    data: user,
    row: row
  }),
  deleteUserRequest: id => ({
    type: types.DELETE_USER,
    data: id
  }),
  deleteUserSuccess: () => ({
    type: types.DELETE_USER_SUCCESS
  }),
  resendUserWelcomeEmail: (user) => ({
    type: types.RESEND_USER_WELCOME_EMAIL_REQUEST,
    recipient: user.id,
    alertType: 'ORG_ADMIN_WELCOME_EMAIL'
  })
};

// Selectors
const selectUsers = state => state.get("users", initialState);

export const makeSelectUsersList = () =>
  createSelector(
    selectUsers,
    usersState => usersState.get("usersList")
  );

export const makeSelectUsersPageCount = () =>
  createSelector(
    selectUsers,
    userState => userState.get("usersPageCount")
  );

export const makeSelectUsersListLoading = () =>
  createSelector(
    selectUsers,
    usersState => usersState.get("usersListLoading")
  );

  export const makeCheckSsoSettings = () =>
  createSelector(
    selectUsers,
    usersState => usersState.get("checkSSO")
  );
