import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/locations';
import { connect } from 'react-redux';
import { selectUserCompany } from '../../../reducers/auth';
import { createStructuredSelector } from 'reselect';

class RescanAddressModal extends Component {

    handleSubmit = () => {
        if (this.props.company.toJS().locationTreeType === 'BUILDING') {
            this.props.rescanAddressBuildingRequest(this.props.modalData.address.id);
        } else {
            this.props.rescanAddressLocationRequest(this.props.modalData.address.id);
        }
    }

    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                        Rescanning this address will change the backend provider for all buildings in the system that use this address.
                        </p>
                    </Grid>
                    <Grid item >
                        <Button className="button"
                                variant="contained" 
                                color="primary" 
                                onClick={this.handleSubmit}>
                            OK
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    company: selectUserCompany(),
});


const mapDispatchToProps = dispatch => {
    return {
        rescanAddressBuildingRequest: (addressId) => dispatch(actions.rescanAddressBuildingRequest(addressId)),
        rescanAddressLocationRequest: (addressId) => dispatch(actions.rescanAddressLocationRequest(addressId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper
)(RescanAddressModal);