import { store } from '../index';
import { logout } from '../reducers/auth';

/* AUTH CONSTANTS */
export const AUTH_ACCESS_TOKEN = 'auth.tokens.accessToken';
export const AUTH_ACCESS_TOKEN_EXP = 'auth.tokens.accessTokenExp';
export const AUTH_REFRESH_TOKEN = 'auth.tokens.refreshToken';
export const AUTH_REFRESH_TOKEN_EXP = 'auth.tokens.refreshTokenExp';
export const AUTH_USER = 'auth.user';
export const CURRENT_COMPANY_LEVEL = 'auth.currentCompanyLevel';
const CURRENT_ORG_TYPE = 'auth.currentOrgType';
const ORIGINAL_ORG_TYPE = 'auth.originalOrgType';
export const RS_ORG = 'rsOrg';
const ORIGINAL_COMPANY = 'auth.originalLoginCompany';
export const USAGE_BASED_LICENSING = 'usageBasedLicensing';
const __permifyUser = '__permifyUser';
const googleApiKey = 'googleApiKey';

/**
 * Subscribes to redux middleware and will keep redux store synced with local storage
 * by continously checking for changes in the store (ie, refreshToken updates access token)
 */
export function setAuthState(state) {
  sessionStorage.setItem(AUTH_USER, JSON.stringify(state.getIn(['auth', 'user'])));
  sessionStorage.setItem(CURRENT_COMPANY_LEVEL, state.getIn(['auth', 'currentCompanyLevel']));
  sessionStorage.setItem(CURRENT_ORG_TYPE, state.getIn(['auth', 'currentOrgType']));
  sessionStorage.setItem(ORIGINAL_ORG_TYPE, state.getIn(['auth', 'originalOrgType']));
  sessionStorage.setItem(RS_ORG, state.getIn(['auth', 'rsOrg']));
  sessionStorage.setItem(ORIGINAL_COMPANY, JSON.stringify(state.getIn(['auth', 'originalLoginCompany'])));
}

/**
 * Gets token and user data from sessionStorage for intial state
 * of application. Keeps a user 'logged in' after a page refresh
 */
export function getAuthState() {
  return { 
    auth: {
      tokens: {
        accessToken: sessionStorage.getItem(AUTH_ACCESS_TOKEN),
        accessTokenExp: sessionStorage.getItem(AUTH_ACCESS_TOKEN_EXP),
        refreshToken: sessionStorage.getItem(AUTH_REFRESH_TOKEN),
        refreshTokenExp: sessionStorage.getItem(AUTH_REFRESH_TOKEN_EXP)
      },
      user: JSON.parse(sessionStorage.getItem(AUTH_USER)),
      currentCompanyLevel: sessionStorage.getItem(CURRENT_COMPANY_LEVEL),
      rsOrg: sessionStorage.getItem(RS_ORG),
      currentOrgType: sessionStorage.getItem(CURRENT_ORG_TYPE),
      originalOrgType: sessionStorage.getItem(ORIGINAL_ORG_TYPE),
      originalLoginCompany: sessionStorage.getItem(ORIGINAL_COMPANY) === 'undefined' ? null : JSON.parse(sessionStorage.getItem(ORIGINAL_COMPANY))
    }
  }
}

/**
 * Force logout the user, reset tokens
 */
export function clearLocalStorage() {
  sessionStorage.removeItem(AUTH_ACCESS_TOKEN);
  sessionStorage.removeItem(AUTH_ACCESS_TOKEN_EXP);
  sessionStorage.removeItem(AUTH_REFRESH_TOKEN);
  sessionStorage.removeItem(AUTH_REFRESH_TOKEN_EXP);
  sessionStorage.removeItem(AUTH_USER);
  sessionStorage.removeItem(CURRENT_COMPANY_LEVEL);
  sessionStorage.removeItem(RS_ORG);
  sessionStorage.removeItem(CURRENT_ORG_TYPE)
  sessionStorage.removeItem(ORIGINAL_ORG_TYPE)
  sessionStorage.removeItem(ORIGINAL_COMPANY);
  sessionStorage.removeItem(USAGE_BASED_LICENSING);
  sessionStorage.removeItem(__permifyUser);
  sessionStorage.removeItem(googleApiKey);
}

/**
 * Set tokens from refresh api call
 */
export function setRefreshTokens(data) {
  sessionStorage.setItem(AUTH_ACCESS_TOKEN, data.accessToken)
  sessionStorage.setItem(AUTH_ACCESS_TOKEN_EXP, data.claims.exp)
}

/**
 * Set tokens at login 
 */
export function setLoginTokens(result) {
  const {accessToken, refreshTokenInfo, claims} = result.data;
  sessionStorage.setItem(AUTH_ACCESS_TOKEN, accessToken);
  sessionStorage.setItem(AUTH_ACCESS_TOKEN_EXP, claims.exp);
  sessionStorage.setItem(AUTH_REFRESH_TOKEN, refreshTokenInfo.id);
  sessionStorage.setItem(AUTH_REFRESH_TOKEN_EXP, refreshTokenInfo.exp);
}

export function getRefreshToken() {
  return sessionStorage.getItem(AUTH_REFRESH_TOKEN);
}

export function getRefreshTokenExp() {
  return sessionStorage.getItem(AUTH_REFRESH_TOKEN_EXP);
}

export function safeLogout(logoutMessage = '') {
  sessionStorage.setItem('logout','true'); // broadcast logout to multiple tabs by storing message
  sessionStorage.removeItem('logout'); // remove from sessionStorage, listeners only look at event
  clearLocalStorage();
  store.dispatch(logout(logoutMessage));
}