import Homepage from './homepage';
import OrganizationsPage from '../../containers/OrganizationsPage/';
import UsersPage from '../../containers/UsersPage/';
import EonUsersPage from '../../containers/EonUsersPage/';
import MyAccount from '../../containers/MyAccount';
import LocationsPage from '../../containers/LocationsPage/LocationsPage';
import LocationsPageCER from '../../containers/LocationsPageCER/LocationsPageCER';
import NetworkDiscoveryPage from '../../containers/NetworkDiscoveryPage/NetworkDiscoveryPage';
import DeviceUsersPage from '../../containers/DeviceUsersPage/deviceUsersPage';
import AlertsPage from '../../containers/AlertsPage/AlertsPage';
import CallHistoryPage from '../../containers/CallHistoryPage/callHistoryPage';
import EventsPage from '../../containers/EventsPage/eventsPage.js';
import DownloadsPage from '../../containers/DownloadsPage/downloadsPage';
import TestCallGenerator from '../../containers/TestCallGeneratorPage/testCallGeneratorPage';
import AboutPage from '../../containers/AboutPage';
import ReportsPage from '../../containers/ReportsPage/ReportsPage';
import ScheduledReportsPage from '../../containers/ScheduledReportsPage/scheduledReportsPage';
import PstnPage from '../../containers/PstnPage/PstnPage';
import AddressManagementPage from '../../containers/AddressManagementPage/AddressManagementPage';
import SipPage from '../../containers/SipPage/SipPage';
import CallMonitoringPage from '../../containers/CallMonitoringPage/CallMonitoringPage';
import ManageLicensesPage from '../../containers/ManageLicensesPage/ManageLicensesPage';
import HeldDevicesPage from '../../containers/HeldDevicesPage/heldDevicesPage';
import ImportingPage from '../../containers/ImportingPage/importingPage';
import OrganizationManagementPage from '../../containers/OrganizationManagementPage/OrganizationManagementPage';
import NotificationIntegrationsPage from '../../containers/NotificationIntegrationsPage';
import PidfloDevicesPage from '../../containers/PidfloDevicesPage/pidfloDevicesPage';
import EmergencyCallAssistPage from '../../containers/EmergencyCallAssistPage/emergencyCallAssistPage';
import APIAccessPage from '../APIAccessKeyPage/index.js';
import CompanyAPI from '../../apis/companyApi';
import { orgTypes } from '../../reducers/auth';
import { USAGE_BASED_LICENSING } from '../../utils/authLocalStorageSync.js';

export const routeAndNavData = [
    {
        path: '/',
        component: Homepage,
    },
    {
        path: '/organizations',
        component: OrganizationsPage,
        parentNavItem: 'Administration',
        title: 'Organizations',
        id: 'organizations-nav-item',
        orgType: [orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM, orgTypes.SERVICE_PROVIDER, orgTypes.RESELLER],
        permission: 'CUSTOM_VIEW_ORGANIZATIONS'
    },
    {
        path: '/organization-management',
        component: OrganizationManagementPage,
        parentNavItem: 'Administration',
        title: 'Organization Management',
        id: 'organization-management-nav-item'
    },
    {
        path: '/notification-integrations',
        component: NotificationIntegrationsPage,
        parentNavItem: 'Administration',
        title: 'Notification Integrations',
        id: 'notification-integrations-nav-item',
        informacastEnabled: true,
        permission: 'VIEW_NOTIFICATION_INTEGRATIONS'
    },
    {
        path: '/api-access-keys',
        component: APIAccessPage,
        parentNavItem: 'Administration',
        title: 'API Access Keys',
        id: 'api-access-nav-item',
        orgType: [orgTypes.SERVICE_PROVIDER, orgTypes.RESELLER, orgTypes.CUSTOMER],
        permission: 'CUSTOM_VIEW_API'
    },
    {
        path: '/administrators',
        component: UsersPage,
        parentNavItem: 'Administration',
        title: 'Administrators',
        id: 'administrators-nav-item',
        permission: 'CUSTOM_VIEW_ADMIN'
    },
    {
        path: '/alerts',
        component: AlertsPage,
        parentNavItem: 'Configuration',
        title: 'Alerts & Emails',
        id: 'alerts-nav-item',
        permission: 'VIEW_ALERT_SUBSCRIPTIONS'
    },
    {
        path: '/locations',
        component: LocationsPage,
        parentNavItem: 'Configuration',
        title: 'E911 Locations',
        id: 'locations-nav-item',
        orgType: orgTypes.CUSTOMER,
        permission: 'VIEW_LOCATIONS'
    },
    {
        path: '/locations-cer',
        component: LocationsPageCER,
        parentNavItem: 'Configuration',
        title: 'CER Locations',
        id: 'locations-cer-nav-item',
        orgType: orgTypes.CUSTOMER,
        cerEnabled: true,
        permission: 'VIEW_LOCATIONS'
    },
    {
        path: '/users',
        component: DeviceUsersPage,
        parentNavItem: 'Configuration',
        title: 'Users',
        id: 'users-nav-item',
        orgType: orgTypes.CUSTOMER,
        permission: 'VIEW_DEVICE_USERS'
    },
    {
        path: '/pidflo-devices',
        component: PidfloDevicesPage,
        parentNavItem: 'Configuration',
        title: 'PIDF-LO Devices',
        id: 'pidflo-devices-nav-item',
        orgType: orgTypes.CUSTOMER,
        permission: 'VIEW_PIDFLO_DEVICES'

    },
    {
        path: '/network-discovery',
        component: NetworkDiscoveryPage,
        parentNavItem: 'Configuration',
        title: 'Network Discovery',
        id: 'network-discovery-nav-item',
        orgType: orgTypes.CUSTOMER,
        permission: 'VIEW_NETWORK_DISCOVERY'
    },
    {
        path: '/call-routing',
        component: SipPage,
        parentNavItem: 'Configuration',
        title: 'Call Routing - SIP',
        id: 'call-routing-nav-item',
        permission: 'VIEW_CALL_ROUTING'
    },
    {
        path: '/call-routing-pstn',
        component: PstnPage,
        parentNavItem: 'Configuration',
        title: 'Call Routing - PSTN',
        id: 'call-routing-pstn-nav-item',
        permission: 'VIEW_CALL_ROUTING'
    },
    {
        path: '/call-monitoring',
        component: CallMonitoringPage,
        parentNavItem: 'Configuration',
        title: 'Call Monitoring',
        id: 'call-monitoring-nav-item',
        orgType: orgTypes.CUSTOMER,
        permission: 'VIEW_CALL_MONITORING'
    },
    {
        path: '/eon-users',
        component: EonUsersPage,
        parentNavItem: 'Configuration',
        title: 'EON Users',
        id: 'eon-users-nav-item',
        orgType: orgTypes.CUSTOMER,
        enhancedNotifications: true,
        permission: 'VIEW_EON_USERS'
    },
    {
        path: '/address-management',
        component: AddressManagementPage,
        parentNavItem: 'Configuration',
        title: 'Address Management',
        id: 'address-management-nav-item',
        permission: 'VIEW_ADDRESS',
        orgType: orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM,
    },
    {
        path: '/test-call-generator',
        component: TestCallGenerator,
        parentNavItem: 'Monitoring',
        title: 'Test Call Generator',
        id: 'test-call-generator-nav-item',
        orgType: orgTypes.CUSTOMER,
        permission: 'ADD_TEST_CALL_GENERATOR'
    },
    {
        path: '/call-history',
        component: CallHistoryPage,
        parentNavItem: 'Monitoring',
        title: 'Call History',
        id: 'call-history-nav-item',
        permission: 'VIEW_CALL_HISTORY'
    },
    {
        path: '/events',
        component: EventsPage,
        parentNavItem: 'Monitoring',
        title: 'Events',
        id: 'events-nav-item',
        permission: 'VIEW_EVENTS'
    },
    {
        path: '/held-devices',
        component: HeldDevicesPage,
        parentNavItem: 'Monitoring',
        title: 'HELD Devices',
        id: 'held-devices-nav-item',
        orgType: orgTypes.CUSTOMER,
        permission: 'VIEW_HELD_DEVICES'
    },
    {
        path: '/create-reports',
        component: ReportsPage,
        parentNavItem: 'Reports',
        title: 'Create Reports',
        id: 'create-reports-nav-item'
    },
    {
        path: '/scheduled-reports',
        component: ScheduledReportsPage,
        parentNavItem: 'Reports',
        title: 'Scheduled Reports',
        id: 'scheduled-reports-nav-item'
    },
    {
        path: '/myAccount',
        component: MyAccount,
    },
    {
        path: '/downloads',
        component: DownloadsPage,
    },
    {
        path: '/about',
        component: AboutPage,
    },
    {
        path: '/emergency-call-assist',
        component: EmergencyCallAssistPage,
        permission: 'ADD_CALL_ASSIST',
        orgType: orgTypes.CUSTOMER,
        emergencyCallAssistEnabled: true
    },
    {// Custom validation in routeAndNav check
        path: '/manage-licenses/:id',
        component: ManageLicensesPage
    },
    {
        path: '/importing',
        component: ImportingPage,
        orgType: orgTypes.CUSTOMER
    }
];

export function checkCustomPermission(permission, authUser) {
    const match = permission.match(/CUSTOM_(.*)_ADMIN/);
    const apiMatch = permission.match(/CUSTOM_(.*)_API/)
    const organizationMatch = permission.match(/CUSTOM_(.*)_ORGANIZATIONS/)
    const rsOrg = sessionStorage.getItem('rsOrg');

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
    let usageBasedLicense = JSON.parse(sessionStorage.getItem(USAGE_BASED_LICENSING));
    if (JSON.parse(sessionStorage.getItem(USAGE_BASED_LICENSING) === null) && user.role.level !== 'SYSTEM') {
        if (authUser.toJS().company !== undefined && (authUser.toJS().company.id !== null || authUser.toJS().company.id !== undefined)) {
            CompanyAPI.getOrganizationById(authUser.toJS().company.id).then(response => {
                sessionStorage.setItem(USAGE_BASED_LICENSING, response.data.usageBasedLicensing);
            });
        }
    }

    if (match) {
        const originalOrgId = !rsOrg || rsOrg === 'undefined' ? null : rsOrg;
        return (user && user.permissions.includes(`${match[1]}_ADMINS_SAME_ORG`) && authUser.toJS().company.id === originalOrgId) || 
                (user && user.permissions.includes(`${match[1]}_ADMINS_SUB_ORG`) && authUser.toJS().company.id !== originalOrgId);
    }
    else if (apiMatch) {
        const originalOrgId = !rsOrg || rsOrg === 'undefined' ? null : rsOrg;
        return (user && user.permissions.includes(`${apiMatch[1]}_API_KEYS`) && authUser.toJS().company.id === originalOrgId)
    }
    else if (organizationMatch) {
        if (organizationMatch[1] === 'VIEW') {
            return (user.permissions.includes(`${organizationMatch[1]}_ORGANIZATIONS`));
        }
        if (user.role.level === 'SYSTEM')
            return (user.permissions.includes(`${organizationMatch[1]}_ORGANIZATIONS`));
        else
            return ((user.role.level === 'RESELLER' || user.role.level === 'SERVICE_PROVIDER') &&
                     user.permissions.includes(`${organizationMatch[1]}_ORGANIZATIONS`) && usageBasedLicense)
    }
    return false;
}

export function routeAndNavCheck(item, props) {
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));

    // Exceptions to the return statement below 
    if (item.path === '/manage-licenses/:id') {
        return !(props.originalCompanyOrgType !== 'SYSTEM' || 
            (props.currentCompanyOrgType === orgTypes.SERVICE_PROVIDER && 
            props.company.toJS().usageBasedLicensing));
    }

    return (item.permission !== undefined && (!checkCustomPermission(item.permission, props.user) && (user && !user.permissions.includes(item.permission)))) ||
    (item.role !== undefined && user && !user.roles.includes(item.role)) || 
    (item.orgType !== undefined && 
        ((Array.isArray(item.orgType) && !item.orgType.includes(props.currentCompanyOrgType)) || 
        (!Array.isArray(item.orgType) && item.orgType !== props.currentCompanyOrgType))) ||
    (item.informacastEnabled !== undefined && !props.informaCastEnabled) ||
    (item.cerEnabled !== undefined && !props.cerEnabled) || 
    (item.emergencyCallAssistEnabled !== undefined && !props.emergencyCallAssistEnabled) || 
    (item.enhancedNotifications !== undefined && !props.orgHasLicense);
}