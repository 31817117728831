import React from 'react';
import ReactTable from 'react-table';
import Delete from '@material-ui/icons/Delete';
import Email from '@material-ui/icons/Email';
import 'react-table/react-table.css';
import './ScheduledReportsRecipientListTable.css';

function ScheduledReportsRecipientListTable(props) {
    const buildTableColumns = [
        {
            Header: "",
            id: "icons",
            width: 65,
            resizable: false,
            Cell: row => {
                if (row.original.email || row.original.emailRecipient) {
                    return <Email/>;
                }
            }
        },
        {
            Header: "Recipients",
            id: "recipients",
            resizable: false,
            Cell: row => {
                if (row.original.email) {
                    return <div title={row.original.email}>{row.original.email}</div>;
                } else if (row.original.emailRecipient) {
                    return <div title={row.original.emailRecipient}>{row.original.emailRecipient}</div>;
                }
            }
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            resizable: false,
            Cell: row => displayDeleteButton(row)
        }
    ];

    const displayDeleteButton = (row) => {
        if(row.original.listType === 'EMAIL_LIST' || row.row.email || row.original.email || row.original.emailRecipient)
            return <Delete id={`delete-button-${row.index}`} onClick={() => props.emailListDeleteHandler(row)} row={row} />;
    }

    const combinedList = [
        ...props.emailList
    ];
    
    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight scheduled-report-recipient-table"
            showPaginationBottom={false}
            minRows={combinedList && combinedList.length > 0 ? 0 : 3}
            data={combinedList}
            pageSize={combinedList ? combinedList.length : 1}
            columns={buildTableColumns}
            style={{margin: '40px 0px 20px'}}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'scheduled-report-recipient-row-' + rowInfo.index,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

export default (ScheduledReportsRecipientListTable);