import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import './LogoutModal.css'

function LogoutConfirmationModal(props) {
    const handleClose = (value) => {
        props.setOpenConfirmation(false);
        window.history.replaceState(null, '', window.location.pathname);
    };

    return (
        <Dialog 
            onClose={handleClose} 
            open={props.openConfirmation}
            disableEscapeKeyDown
            disableBackdropClick
            className="logout-confirmation-modal"
        >
            <DialogTitle id="logout-modal-title">Session Timeout</DialogTitle>
            <p>You have been logged out due to 15 minutes of inactivity.</p>
            <div className="logout-modal-wrapper">
                <Button className="button" variant="contained" color="primary" onClick={handleClose}>
                    Ok
                </Button>
            </div>
        </Dialog>
    );
}

export default LogoutConfirmationModal;