import React from 'react';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';

function CallMonitoringNumbersTable(props) {
    return (
        <div className="recipient-list-wrapper">
            {
                props.numbersList.map((item, index) => {
                    return <div key={index} className="sms" >{!isNaN(item) ? <NumberFormatter number={item} /> : item}</div>;
                })
            }
        </div>
    );
}

export default (CallMonitoringNumbersTable);