import { axiosInstance } from './api';
/* Cirrus Admin-Service /company Controller */
const USER_PROFILE_ENDPOINT = 'admin-service/userProfile/';

export default class ProfileApi {
	static edit(user) {
		const payload = {
			id: user.id,
			roleName: user.role.name,
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			password: user.password,
			companyId: user.companyId,
			ssoType: user.ssoType
		};

		return axiosInstance.put(USER_PROFILE_ENDPOINT, payload);
	}
}
