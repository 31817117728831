import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { compose } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/organizations';
import { connect } from 'react-redux';
import './addEditOrganizationModal.css';
import { actions as AppActions } from '../../../reducers/app';
import { currentCompanyOrgType, orgTypes, userCompanyId, selectUsageBasedLicensing } from '../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import OrganizationForm from './shared/organizationForm';
import CompanyAPI from '../../../apis/companyApi';
import CompanyFeaturesAPI from '../../../apis/companyFeaturesApi';
import { validationSchema, styles } from './shared/sharedData';
import { selectAlternateIdEnabled, selectLocationAlternateIdEnabled, selectEnforceDeviceUidUniquenessEnabled, selectEmergencyCallAssistEnabled } from '../../../reducers/app';
import { select } from 'redux-saga/effects';

const AddEditOrganizationModal = props => {
    const { modalLoading, classes, modalErrorOptions, currentCompanyOrgType, alternateDeviceUserIdEnabled, enforceDeviceUidUniquenessEnabled, locationAlternateIdEnabled, emergencyCallAssistEnabled, companyId, usageBasedLicensing, modalData } = props;

    const [companyFeatures, setCompanyFeatures] = useState([]);
    const [companyFeaturesDataLoaded, setCompanyFeaturesDataLoaded] = useState(false);
    const [company, setManagerDataSync] = useState([]);
    const [options, setOptions] = useState([]);
    const [organizationByOrgType, setOrganizationByOrgType] = useState(null);

    useEffect(() => {
        if (modalData) {
            // This is needed to get current edit org company features
            CompanyFeaturesAPI.getCompanyFeatures(props.modalData.id).then(response => {
                setCompanyFeatures(response.data);
                setCompanyFeaturesDataLoaded(true);
            })

            CompanyAPI.getOrganizationById(props.modalData.id).then(response => {
                if (response.data) {
                    setManagerDataSync(response.data);
                    setCompanyFeaturesDataLoaded(true)
                }
            });   
        } else {
            setCompanyFeaturesDataLoaded(true);
        }
    }, [])

    return (
        companyFeaturesDataLoaded ?
            <Formik
                initialValues={ !modalData ?
                    { 
                        name: '', 
                        description: '',
                        licenseTypes: 'SUBSCRIPTION',
                        requireLocationInfo: false,
                        callRecordingEnabled: true,
                        cerEnabled:false,
                        overrideDestinationToggle: false,
                        overrideDestination: 'TEST',
                        alternateDeviceUserID: alternateDeviceUserIdEnabled,
                        enforceDeviceUidUniqueness: true,
                        informaCastEnabled: false,
                        managerDataSync: false,
                        horizonPrimeEnabled: false,
                        managerDataSyncKey: '',
                        alternateLocationId: locationAlternateIdEnabled,
                        emergencyCallAssist: emergencyCallAssistEnabled,
                        orgType: currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM ? 'SERVICE_PROVIDER' : 'CUSTOMER',
                        usageBasedLicensing: currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM ? true : usageBasedLicensing,
                        externalOrgId: '',
                        locationTreeType: 'BUILDING'
                    } :
                    {
                        name: (props.modalData) ? props.modalData.name : '',
                        description: (props.modalData) ? props.modalData.description : '',
                        licenseTypes: (props.modalData) ? props.modalData.licenseModel : '',
                        subtenantEnabled: props.modalData.subtenantEnabled,
                        parentId: props.modalData.parentId,
                        requireLocationInfo: props.modalData.requireLocationInfo,
                        callRecordingEnabled: props.modalData.callRecordingEnabled,
                        cerEnabled: companyFeatures.includes('CER'),
                        overrideDestinationToggle: props.modalData.overrideDestination ? true : false,
                        overrideDestination: props.modalData.overrideDestination,
                        alternateDeviceUserID: companyFeatures.includes('ALT_DEVICE_USER_ID'),
                        enforceDeviceUidUniqueness: !companyFeatures.includes('DEVICE_USER_ID_UNIQUENESS'),
                        informaCastEnabled: companyFeatures.includes('INFORMACAST'),
                        managerDataSync: props.modalData.managerDataSync,
                        horizonPrimeEnabled: companyFeatures.includes('HORIZON_PRIME'),
                        managerDataSyncKey: (props.modalData) ? props.modalData.managerDataSyncKey : '',
                        alternateLocationId: companyFeatures.includes('ALT_LOCATION_ID'),
                        emergencyCallAssist: companyFeatures.includes('EMERGENCY_CALL_ASSIST'),
                        orgType: props.modalData.orgType,
                        usageBasedLicensing: props.modalData.usageBasedLicensing,
                        externalOrgId: props.modalData.externalOrgId,
                        locationTreeType: props.modalData.locationTreeType

                    }
                }
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit = {
                    (values) => {
                        if (!values.overrideDestinationToggle) {
                            values.overrideDestination = null;
                        }

                        if (organizationByOrgType && organizationByOrgType.id) {
                            values.parentId = organizationByOrgType.id;
                        } else {
                            values.parentId = companyId;
                        }
           
                        if (!modalData) {
                            props.submitAddOrganizationForm(values);  
                        } else {
                            props.submitEditOrganizationForm(values, props.modalData);
                        }
                    }
                }
                render={props => <OrganizationForm setOptions={setOptions} 
                                                    options={options} 
                                                    organizationByOrgType={organizationByOrgType} 
                                                    setOrganizationByOrgType={setOrganizationByOrgType} 
                                                    companyFeatures={companyFeatures} 
                                                    modalData={modalData} 
                                                    currentCompanyOrgType={currentCompanyOrgType} 
                                                    company={company} 
                                                    modalErrorOptions={modalErrorOptions} 
                                                    modalLoading={modalLoading} 
                                                    classes={classes} 
                                                    {...props} />}
            /> :
            <div><LinearProgress/></div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyOrgType: currentCompanyOrgType(),
    alternateDeviceUserIdEnabled: selectAlternateIdEnabled(),
    enforceDeviceUidUniquenessEnabled: selectEnforceDeviceUidUniquenessEnabled(),
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled(),
    emergencyCallAssistEnabled: selectEmergencyCallAssistEnabled(),
    companyId: userCompanyId(),
    usageBasedLicensing: selectUsageBasedLicensing()
});

const mapDispatchToProps = dispatch => {
    return {
        setModalErrorOptions: (error) => dispatch(AppActions.setModalErrorOptions(error)),
        submitAddOrganizationForm: (organization) => dispatch(actions.addOrganizationRequest(organization)),
        submitEditOrganizationForm: (organization, row) => dispatch(actions.editOrganizationRequest(organization, row))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddEditOrganizationModal);