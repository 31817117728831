import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import { compose } from "redux";
import HasAccess from "../../components/HasAccess/HasAccess";

import injectReducer from '../../utils/injectReducer';
import reducer from '../../reducers/notificationIntegrations';
import injectSaga from '../../utils/injectSagas';
import saga from '../../sagas/notificationIntegrations';

import { types } from '../../reducers/notificationIntegrations';
import { actions as appActions, selectPageSize } from '../../reducers/app';

import { createStructuredSelector } from "reselect";
import NotificationIntegrationsTable from "../NotificationIntegrationsTable/notificationIntegrationsTable";

import { actions as NotificationIntegrationsActions, selectInformacastConfigured,
  makeSelectNotificationIntegrationsListLoading } from '../../reducers/notificationIntegrations';

import ModalErrorMessage from '../../components/ModalWrapper/ModalErrorMessage';

function InformacastErrorMessage(props) {
  return (
    <div style={{ color: 'red', marginBottom: 20 }}>
      The InformaCast 911 Alerting feature has been enabled but not configured. 
      Configuration must be complete before InformaCast 911 Alert Entities can be provisioned.
    </div>
  )
}

const NotificationIntegrationsPage = props => {
  function modalHandleOpen() {
    props.setCurrentModal(types.ADD_INFORMACAST_ENTITY);
  }

  useEffect(() => {
    props.setPageTitle('Notification Integrations');
    props.setPageTitleId('notification-integrations-header');
  }, []);

  useEffect(() => {
    props.fetchNotificationIntegrationsList();
  }, []);

  return (
    <div>
      <div>
        {!props.notificationIntegrationsListLoading && !props.informacastConfigured ? <InformacastErrorMessage/> : '' }
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center">
          <Grid item>
            <HasAccess
                permissions={["ADD_NOTIFICATION_INTEGRATIONS"]}
            >
            <Button
              className="button"
              id="add-notification-integrations-button"
              variant="contained"
              color="primary"
              onClick={modalHandleOpen}
              disabled={!props.notificationIntegrationsListLoading && !props.informacastConfigured}>
                Add Informacast 911 Alert Entity
              </Button>
            </HasAccess>
          </Grid>
        </Grid>
        <br />

        <NotificationIntegrationsTable
          fetchNotificationIntegrationsList={props.fetchNotificationIntegrationsList}
          setCurrentModal={props.setCurrentModal}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentModal: currentModal => dispatch(appActions.setCurrentModal(currentModal)),
    fetchNotificationIntegrationsList: () => dispatch(NotificationIntegrationsActions.getNotificationIntegrationsRequest()),
    setPageTitle: data => dispatch(appActions.setPageTitle(data)),
    setPageTitleId: data => dispatch(appActions.setPageTitleId(data))
  };
};

const mapStateToProps = createStructuredSelector({
  pageSize: selectPageSize(),
  informacastConfigured: selectInformacastConfigured(),
  notificationIntegrationsListLoading: makeSelectNotificationIntegrationsListLoading()
});

const withReducer = injectReducer({ key: "notificationIntegrations", reducer });
const withSaga = injectSaga({ key: "notificationIntegrations", saga });
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withReducer,
  withSaga,
  withConnect
)(NotificationIntegrationsPage);
