import { axiosInstance } from './api';
/* Cirrus Admin-Service /call-monitoring Controller */
const CALL_MONITORING_ENDPOINT = 'admin-service/callMonitoring';

export default class CallMonitoringAPI {
    static addCallMonitoring(callMonitoring) {
        const payload = {
           name: callMonitoring.name,
           allBuildings: callMonitoring.allBuildings,
           buildingId: callMonitoring.buildings.id,
           organizationId: callMonitoring.parentId,
           phoneNumbers: callMonitoring.phoneNumberList
        };
        return axiosInstance.post(CALL_MONITORING_ENDPOINT, payload);
    }

    static editCallMonitoring(callMonitoring) {
        const payload = {
            id: callMonitoring.id,
            name: callMonitoring.name,
            allBuildings: callMonitoring.allBuildings,
            buildingId: callMonitoring.buildings.filterOrgId ? callMonitoring.buildings.filterOrgId : callMonitoring.buildings.id,
            phoneNumbers: callMonitoring.phoneNumberList
        };
        return axiosInstance.put(CALL_MONITORING_ENDPOINT, payload);
    }
    
    static getCallMonitoring(companyId, page, pageSize, sortBy, direction) {
        return axiosInstance.get(`${CALL_MONITORING_ENDPOINT}/${companyId}`, {
            params: {
                page,
                pageSize,
                sortBy,
                direction
            }
        });
    }

    static deleteCallMonitoring(id) {
        return axiosInstance.delete(`${CALL_MONITORING_ENDPOINT}/${id}`);
    }

    static addCallMonitoringFilters(filtersArray, callMonitoringId) {
        const payload = filtersArray;
        
        return axiosInstance.post(`${CALL_MONITORING_ENDPOINT}/${callMonitoringId}/filters`, payload);
    }

    static deleteCallMonitoringFilters(id){
        return axiosInstance.delete(`${CALL_MONITORING_ENDPOINT}/${id}/filters`);
    }

    static getCallMonitoringFilters(id){
        return axiosInstance.get(`${CALL_MONITORING_ENDPOINT}/${id}/filters`);
    }

}