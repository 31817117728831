import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';

import { actions } from '../../reducers/testCallGenerator';
import { connect } from 'react-redux';
import injectSaga from '../../utils/injectSagas';
import saga from '../../sagas/testCallGenerator';

import {  
    selectCerEnabled,
    modalErrorDisplay, 
    modalErrorMessage, actions as AppActions } from '../../reducers/app';
import { userCompanyId, selectUserCompany } from '../../reducers/auth';

import { createStructuredSelector } from 'reselect';
import ModalErrorMessage from '../../components/ModalWrapper/ModalErrorMessage';
import BuildingAndLocationSingleComponent from '../../components/BuildingAndLocationSingleComponent/BuildingAndLocationSingleComponent';

const styles = theme => ({
    modal: {
        width: '500px',
        height: '230px',
        position: 'absolute',
        top: '300px',
        left: 'calc(50% + 160px)',
        transform: 'translate(-50%)'
    },
    btn: {
        marginTop: '10px'
    },
    error: {
        color: 'green'
    }
});

const TestCallGeneratorForm = props => {
    const { setLocationTypeIsCer,
            handleSubmit, 
            modalLoading,
            classes } = props;

    return (
        <form className={classes.modal} onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <BuildingAndLocationSingleComponent 
                setLocationTypeIsCer={setLocationTypeIsCer}
                {...props}
            />
            <br/>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className={classes.btn}
                        color="primary" 
                        variant="contained">Send Test</Button>
            </Grid>
        </form>
    );
}

function TestCallGeneratorPage(props) {
    const { modalLoading, classes, modalErrorDisplay, modalErrorMessage } = props;
    const [open, setOpen] = useState(false);
    const [locationTypeIsCer, setLocationTypeIsCer] = useState(false);
    
    const validationSchema =Yup.object().shape({
        location: Yup.object().shape({
            value: Yup.string("Enter a location")
            .trim()
            .nullable()
            .required("Location is a required field.")
        }),
        building: props.company.toJS().locationTreeType === 'SIMPLE' || locationTypeIsCer ?
        Yup.object().shape({
           value :  Yup.string("Select Building")
            .trim()
            .nullable()
        })
        :
        Yup.object().shape({
            value: Yup.string("Select Building")
            .trim()
            .nullable()
            .required("Building is a required field.")
        }),
        locationType: props.cerEnabled ? Yup.string("Enter a location")
        .trim()
        .nullable()
        .required("Location Type is a required field.") : ''
    })
    
    function handleClose(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    useEffect(() => {
        props.setPageTitle('Test Call Generator');
        props.setPageTitleId('test-call-generator-header');
    }, []);

    return (
        <div>
            <br/>
            {modalErrorDisplay && <ModalErrorMessage modalErrorMessage={modalErrorMessage}/> }
            <Formik
                initialValues={{ 
                    building: {},
                    location: {},
                    locationType: props.cerEnabled ? '' : 'E911_LOCATIONS'
                }}
                validationSchema={validationSchema}
                onSubmit = {
                    (values) => {
                        setOpen(true);
                        values.parentId = props.companyId;
                        props.submitTestCallGeneratorForm(values);
                    }
                }
                render={formikProps => <TestCallGeneratorForm setLocationTypeIsCer={setLocationTypeIsCer} companyId={props.companyId} classes={classes} modalLoading={modalLoading} {...formikProps} {...props} />}
            />
            <Snackbar
              className="snackbar-popup"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              message={<div>
                  <span id="client-snackbar" className={classes.message}>
                      <CheckCircleIcon className={"classes.icon classes.iconVariant"} />
                      Test Call Sent
                  </span>
              </div>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />    
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
    company: selectUserCompany(),
    cerEnabled: selectCerEnabled(),
    modalErrorDisplay: modalErrorDisplay(),
    modalErrorMessage: modalErrorMessage(),
});

const mapDispatchToProps = dispatch => {
    return {
        submitTestCallGeneratorForm: (testCall) => dispatch(actions.testCallGeneratorRequest(testCall)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
}

const withSaga = injectSaga({ key: 'testCallGenerator', saga });

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withSaga
)(TestCallGeneratorPage);