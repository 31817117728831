import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import LldpEditMenu from '../../containers/LldpEditMenu/lldpEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import PortList from '../../containers/PortList/portList';
import { makeSelectLldpPageCount, actions as LldpActions } from "../../reducers/networkDiscovery";
import { createStructuredSelector } from "reselect";
import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { connect } from "react-redux";

const initialSortColumns = [
    {
        id: 'location',
        desc:false
    }
]
function LldpTable(props) {
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isAdd = user && user.permissions.includes('ADD_NETWORK_DISCOVERY');
	const isEdit = user && user.permissions.includes('EDIT_NETWORK_DISCOVERY');
	const isDelete = user && user.permissions.includes('DELETE_NETWORK_DISCOVERY');
    const isView = isAdd || isEdit || isDelete;
    
    function getGeoCoordsNetDisco(row) {
        if ((row.original.erl) && (row.original.erl.normalizedGeoCoords) && (row.original.erl.normalizedGeoCoords.latitude) && (row.original.erl.normalizedGeoCoords.longitude)) {
            return <div title={`${row.original.erl.normalizedGeoCoords.latitude} , ${row.original.erl.normalizedGeoCoords.longitude}`}>{`${row.original.erl.normalizedGeoCoords.latitude} , ${row.original.erl.normalizedGeoCoords.longitude}`}</div>;
        } else {
            return <div></div>;
        }
    }

    const buildTableColumns = [
        {
            Header: "Description",
            id: "description",
            Cell: row => <div title={row.original.description}>{row.original.description}</div>
        },
        {
            Header: "Identifier",
            id: "identifier",
            Cell: row => <div title={row.original.chassisId}>{row.original.chassisId}</div>
        },
        {
            Header: "Location",
            id: "location",
            sortable: true,
            Cell: row => <div title={`${row.original.erl ? row.original.erl.name: 'Unknown'} ${(row.original.erl && row.original.erl.address) ? row.original.erl.address.normalizedAddress : ''}`}>{row.original.erl ? row.original.erl.name: 'Unknown'}<br/>{(row.original.erl && row.original.erl.address) ? row.original.erl.address.normalizedAddress : ''}</div>
        },
        {
            Header: <div id="location-coordinates">Geodetic Coordinates</div>,
            id: "coordinates",
            Cell: row => getGeoCoordsNetDisco(row)
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                isView ? <LldpEditMenu row={row} setCurrentModal={props.setCurrentModal} /> : null
            )
        }
    ];


    const {
        loading, lldpList, lldpPageCount, getLldpListRequest
    } = props;
    let lldpListArray = [];

    if (lldpList) {
        lldpListArray = lldpList.toArray();
    }

    useEffect(() => {
        if (lldpListArray && lldpListArray.length === 0 && props.page > 0 && lldpPageCount < props.page+1) {
            props.setPage(props.page-1)
        }
    }, [lldpListArray && lldpListArray.length === 0 && props.page > 0 && lldpPageCount < props.page+1]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    useEffect( () => {
        if (props.pageSize) {
            getLldpListRequest(props.page+1, props.pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        }
        props.setTableProps({
            page: props.page+1, 
            pageSize: props.pageSize, 
            sortBy: currentSortColumn.id, 
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, props.pageSize, props.page]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight"
            showPaginationBottom={true}
            data={lldpListArray}
            page={props.page}
            minRows={lldpListArray && lldpListArray.length > 0 ? 0 : 5}
            pages={lldpPageCount <= 0 ? 1 : lldpPageCount}
            defaultPageSize={25}
            columns={buildTableColumns}
            loading = {loading}
            LoadingComponent={ReactTableLoadingComponent}
            manual
            
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
                
            }}
            onFetchData={(state, instance) => {
                props.setPageSize(state.pageSize);
                props.setCurrentPage(state.page+1);
                resetScrollInsideTable();
            }}
            onPageSizeChange={pageSize => {
                props.setPageSize(pageSize);
                props.setPage(0);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setCurrentPage(pageIndex + 1);
                props.setPage(pageIndex);
                resetScrollInsideTable();
            }}
            SubComponent={
                row => {
                    return(
                        <div style={{padding: "20px" }}>
                            <b>Port Mapping</b>
                            <PortList lldpId={row.original.id} setCurrentModal={props.setCurrentModal} />
                        </div>
                    )
                }
            }
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                            return {
                                id: 'lldp-row-' + rowInfo.index,
                                style: !rowInfo.original.erl ? {
                                    background: 'rgba(255, 70, 71, 0.45)',
                                    opacity: '.8',
                                    border: '1px solid red'
                                } : {},
                                tabIndex: 0,
                                onKeyDown: e => {
                                    if ((e.key === ' ' || e.key === 'Enter') && document.activeElement.tagName !== 'BUTTON') {
                                        e.preventDefault();

                                        const expandElm = document.querySelectorAll('.rt-expandable')[rowInfo.index];

                                        if (expandElm) {
                                            expandElm.click();
                                        }
                                    }
                                }
                            };
                        }
                        else {
                            return {};
                        }
                    }
            }
        />
    );
}

LldpTable.propTypes = {
    lldpList: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired,
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    lldpPageCount: makeSelectLldpPageCount(),
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return {
        getLldpListRequest: (page, pageSize, sortBy, direction) => dispatch(LldpActions.getLldpRequest(page,pageSize, null, sortBy, direction)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LldpTable);
