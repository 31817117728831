import React, { useState, useEffect, useCallback } from 'react';
import Place from '@material-ui/icons/Place';
import GoogleMapReact from 'google-map-react';
import { geocode, RequestType, setKey } from "react-geocode";
import { debounce } from 'lodash';

function GoogleMapsComponent({ values, addressIsAlternate }) {
    const [geoCoordinates, setGeoCoordinates] = useState(null);
    const googleApiKey = sessionStorage.getItem('googleApiKey');

    useEffect(() => {
        setKey(googleApiKey);

        setMapsLocation(values, addressIsAlternate);

        return () => {
            setMapsLocation.cancel();
        };    
    }, [values, addressIsAlternate]);

    const setMapsLocation = useCallback(
        debounce((values, addressIsAlternate) => {
            const address = (!addressIsAlternate) ? 
                            values.address : 
                            !values.houseNumber || !values.street || !values.city || !values.stateProvince || !values.zip || !values.country ? '' :
                            `${values.houseNumber} ${values.houseNumberExtension} ${values.prefixDirection} ${values.street} ${values.streetType} ${values.postDirection} ${values.city} ${values.stateProvince} ${values.zip} ${values.country}`;

            if (!address) {
                if ((!isNaN(values.latitude) && !isNaN(values.longitude)) && 
                    values.latitude.toString().length > 0 && values.longitude.toString().length > 0) {
                    setGeoCoordinates({
                        lat: Number(values.latitude),
                        lng: Number(values.longitude)
                    });   
                } else {
                    setGeoCoordinates(null);
                }
            } else {
                geocode(RequestType.ADDRESS, address)
                    .then((response) => {
                        setGeoCoordinates(response.results[0].geometry.location);
                    })
                    .catch((error) => {
                        console.error(error);
                        setGeoCoordinates(null);
                    });
            }
        }, 500)
    , []);

    const defaultProps = {
        center: {
          lat: geoCoordinates ? geoCoordinates.lat : 0,
          lng: geoCoordinates ? geoCoordinates.lng : 0
        },
        zoom: 14
    };

    const LocationPin = () => (
        <div>
            <Place
                style={{ color:'#ea4336'}}
                fontSize='large'
            />
        </div>
    );

    return (
        <>
            {geoCoordinates &&
                <div style={{ height: '300px', width: '100%', marginBottom: 20 }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleApiKey }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    center={defaultProps.center}
                    zoom={defaultProps.zoom}
                >
                    <LocationPin
                        lat={defaultProps.center.lat}
                        lng={defaultProps.center.lng}
                    />
                </GoogleMapReact>
            </div>}
        </>
    );
}

export default GoogleMapsComponent;