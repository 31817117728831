import { axiosInstance } from './api';
/* Cirrus Admin-Service /sip Controller */
const SIP_ENDPOINT = 'admin-service/sipPeer';

export default class SipAPI {
    static addSip(sip, companyId) {
        const payload = {
           name: sip.name,
           host: sip.host,
           organizationId: companyId,
           skype: sip.skype,
           overrideDestination: sip.overrideDestination,
           transport: sip.transport,
           disableRelayCenter: sip.disableRelayCenter ? sip.disableRelayCenter : null,
           port: sip.monitorTrunk ? sip.portNumber : null,
           monitorTrunk: sip.monitorTrunk
        };
        return axiosInstance.post(SIP_ENDPOINT, payload);
    }
    
    static editSip(sip) {
        const payload = {
           name: sip.name,
           host: sip.host,
           id: sip.id,
           skype: sip.skype,
           overrideDestination: sip.overrideDestination ? sip.overrideDestination : null,
           transport: sip.transport,
           disableRelayCenter: sip.disableRelayCenter ? sip.disableRelayCenter : null,
           port: sip.monitorTrunk ? sip.portNumber : null,
           monitorTrunk: sip.monitorTrunk
        };
        return axiosInstance.put(SIP_ENDPOINT, payload);
    }

    static getSipById(companyId, page, pageSize, sortBy, direction) {
        return axiosInstance.get(`${SIP_ENDPOINT}/${companyId}`, {
            params: {
                page, 
                pageSize,
                sortBy,
                direction
            }
        });
    }

    static getSip(page, pageSize, sortBy, direction) {
        return axiosInstance.get(SIP_ENDPOINT, {
            params: {
                page, 
                pageSize,
                sortBy,
                direction
            }
        });
    }
    
    static deleteSip(sipId) {
        return axiosInstance.delete(`${SIP_ENDPOINT}/${sipId}`);
    }

    static getSipPeerDestinationTypes() {
        return axiosInstance.get(`${SIP_ENDPOINT}/destinationTypes`);
    }
}