import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import handleCopyToClipboard from '../../utils/handleCopyToClipboard';

function EmergencyCallAssistModal(props) {
    const { 
        modalState,
        setModalState,
        locationOrTelURI,
        setLocationOrTelURI,
        resetForm,
        setCopyToClipboardFailed,
        setSnackbarOpen,
        setFieldValue,
        copyToClipboardFailed,
        handleClose,
        snackbarOpen
    } = props;

    return (
        <div>
            <Dialog
                open={Boolean(modalState)}
                className='modal'
                style={{ backgroundColor: 'transparent !important' }}
            >
                <DialogTitle id="dialog-title" >
                    {modalState === 'ambiguous' ?
                        <div style={{ color: '#fff' }}>
                            Address Ambiguous
                        </div> :
                    modalState === 'badAddress' ?
                        <div style={{ color: '#fff' }}>
                            No address found
                        </div> :
                    modalState === 'success' ?
                        <div style={{ color: '#fff' }}>
                            Submission Successful
                        </div>
                        : ''
                    }
                </DialogTitle>
                <DialogContent>
                    {modalState === 'ambiguous' ?
                        <div>
                            <p style={{ color: 'rgb(230, 0, 0)' }}>We found one or more address, please
                            select the correct one.</p>
                            <p>If you unable to validate the address, here is the phone number (SIP URL) to
                            contact the Emergency Relay Center.</p>
                            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{locationOrTelURI}</p>
                        </div> :
                    modalState === 'badAddress' ?
                        <div>
                            <p style={{ color: 'rgb(230, 0, 0)' }}>We cannot validate the address. Please
                            verify that you have entered it correctly.</p>
                            <p>If you unable to validate the address, here is the number (SIP URL) to contact
                            the Emergency Relay Center.</p>
                            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{locationOrTelURI}</p>
                        </div> :
                    modalState === 'success' ?
                        <div>
                            <p>Here is the number (SIP URL) to contact the PSAP.</p>
                            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{locationOrTelURI}</p>
                        </div>
                        : ''
                    }
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button id="formReturnButton"
                            onClick={() => {
                                setModalState('');
                            }}
                            color="primary" 
                            variant="contained"
                        >
                            Return to Form
                        </Button>
                        <Button id="copyToClipboardButton"
                            onClick={() => {
                                handleCopyToClipboard(locationOrTelURI, setSnackbarOpen, setCopyToClipboardFailed);
                            }}
                            color="primary" 
                            variant="contained"
                        >
                            Copy to Clipboard
                        </Button>
                        <Button id="resetFormButton"
                            onClick={() => {
                                window.location.reload();
                                setModalState('');
                                //     resetForm();
                                //     setFieldValue('address', '');
                            }}
                            color="primary" 
                            variant="contained"
                        >
                            Reset Form
                        </Button>
                    </div>
                    <Snackbar
                        className={`snackbar-popup ${copyToClipboardFailed && 'error'}`}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message={<div>
                            {copyToClipboardFailed ? 
                                <span id="client-snackbar">
                                    The code/ID was not copied to your clipboard. Please copy manually:<br/>
                                    {locationOrTelURI}
                                </span>
                                :
                                <span id="client-snackbar">
                                    <CheckCircleIcon className={"classes.icon classes.iconVariant"} />
                                    The code/ID has been copied to your clipboard
                                </span>
                            }
                        </div>}
                        action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                        ]}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EmergencyCallAssistModal;