import { LOGIN } from '../reducers/auth';
import { actions } from '../reducers/app';
import { config } from '../apis/api';
import { setLoginTokens } from '../utils/authLocalStorageSync';
import Axios from 'axios';
// hook into redux middleware and look at every action for certain properties
// that'll tell us whether the action is a request to api or other
export default function clientMiddleware(client) {
    const errorMessage = 'An error has occurred. Please try again later.';

    return ({ dispatch, getState }) => next => (action) => {
        if (typeof action === 'function') {
            return action(dispatch, getState); // not sure what this does
        }

        const {
            promise, types, ...rest
        } = action;

        if (!promise) { // doesn't contain a promise for api request
            if(action.type === "@@router/LOCATION_CHANGE") {
                if(getState().toJS().global.modal.open) {
                    dispatch(actions.setModalClose());
                }
            }
            return next(action); // skip refresh token stuff and continue to dispatch the action
        }

        // set the tokens for the axios request from local storage
        client.defaults.headers.common['Authorization'] = (getState().auth || {}).access_token;

        const [REQUEST, SUCCESS, FAILURE] = types;
        next({...rest, type: REQUEST}); // dispatch request action
        if(action.type === LOGIN) {
            // create a cloned axios instance to not accidentally use interceptors
            const loginClient = Axios.create(config);
            let actionPromise = promise(loginClient)
            .then(result => {
                // disallow eon users
                if(result.data.userProfileTO && result.data.userProfileTO.role && result.data.userProfileTO.role.name === 'EON_USER') {
                    const errors = { // mimic api error
                        response : {
                            status: 400,
                            data: {
                                title : 'EON user access is disabled.'
                            }
                        }
                    };
                    return next({...rest, errors, type: FAILURE});
                }
                else {
                    setLoginTokens(result);
                    next({...rest, result, type: SUCCESS});
                }
            })
            .catch(errors=> {
                if (!errors.response) {
                    errors.response = {
                        data: {
                            message: errorMessage
                        }
                    }
                } else if(errors.response.status >= 500) {
                    errors.response.data.message = errorMessage;
                }
                return next({...rest, errors, type: FAILURE})

            }).catch(error => {
                next({...rest, error, type: 'FAILURE'})
            });
            return actionPromise;
        }
    }
}