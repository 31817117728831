import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { safeLogout } from '../../../utils/authLocalStorageSync';
import './LogoutModal.css'

function LogoutModal(props) {
    const handleClose = (value) => {
        props.setOpen(false);
    };

    function handleLogoutForced() {
        props.setOpen(false);
        safeLogout();
        window.location.href = '/';
    }

    function handleLogout() {
        props.setOpen(false);
        safeLogout();
        window.location.href = '/login?inactivityLogout';
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            handleLogout();
            return '';
        } else {
            return <strong>{minutes}:{zeroPad(seconds)}</strong>;
        }
    };

    return (
        <Dialog 
            onClose={handleClose} 
            open={props.open}
            disableEscapeKeyDown
            disableBackdropClick
            className="logout-modal"
        >
            <DialogTitle id="logout-modal-title" style={{ background: sessionStorage.getItem('skin') === 'five9' ? 'rgba(36, 76, 222, 1.0)' : '#1b536a' }}>Session Inactivity</DialogTitle>
            <p>You will be logged out in {
                <Countdown  
                    date={Date.now() + 300000} 
                    renderer={renderer}
                />
            } minutes due to inactivity, unless you choose to stay logged in.</p>
            <div className="logout-modal-wrapper">
                <Button className="button" variant="contained" color="primary" onClick={handleLogoutForced}>
                    Log Out Now
                </Button>
                <Button className="button" variant="contained" color="primary" onClick={handleClose}>
                    Stay Logged In
                </Button>
            </div>
        </Dialog>
    );
}

export default LogoutModal;