import React, { useEffect } from 'react';
import ReactTableLoadingComponent from "../../components/ReactTableLoadingComponent";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { actions as AppActions } from '../../reducers/app';
import moment from 'moment';
import ManageLicensesEditMenu from '../../containers/ManageLicensesEditMenu/ManageLicensesEditMenu';
import { selectLicensesList, selectLicensesLoading, makeSelectManageLicensesPageCount } from '../../reducers/manageLicenses';
import ReactTable from "react-table";

function ManageLicensesTable(props) {
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
    const isDelete = user && user.permissions.includes('DELETE_LICENSING');

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [props.loading]);

    function formatLastAccess(callTime) {
        return callTime ?  moment(callTime).format('MM/DD/YYYY') : '';
    }

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }  

    let licensesArray = [];
    if (props.licenses) {
        licensesArray = props.licenses.toArray();
    }

    useEffect(() => {
        if (licensesArray && licensesArray.length === 0 && props.page > 0 && props.manageLicensesPageCount < props.page+1) {
            props.setPageSize(props.page-1)
        }
    }, [licensesArray && licensesArray.length === 0 && props.page > 0 && props.manageLicensesPageCount < props.page+1]);

    var columns = [];
    if(props.usageBasedLicensing) {
        columns = [
            {
                Header: "License Type",
                id: 'type',
                Cell: row => <div title={row.original.type}>{row.original.type}</div>
            },
            {
                Header: "Number of Licenses",
                id: 'count',
                Cell: row => <div title={row.original.count}>{row.original.count}</div>
            },
            {
                Header: '',
                width: 65,
                id: 'edit-menu',
                Cell: row => 
                {
                    if(row.original.type === 'Enhanced Notification') {
                        return isDelete ? <ManageLicensesEditMenu row={row} licenseModel={props.licenseModel} companyId={props.companyId}/> : null;
                    }
                }
            }
        ];
    } else {
        columns = [
            {
                Header: "Organization License Model",
                id: 'model',
                Cell: row => <div title={props.licenseModel === 'RIGHT_TO_USE' ? 'Perpetual' : props.licenseModel}>{props.licenseModel === 'RIGHT_TO_USE' ? 'Perpetual' : props.licenseModel}</div>
            },
            {
                Header: "License Type",
                id: 'type',
                Cell: row => <div title={row.original.type}>{row.original.type}</div>
            },
            {
                Header: "Number of Licenses",
                id: 'count',
                Cell: row => <div title={row.original.count}>{row.original.count}</div>
            },
            {
                Header: "Expiration Date",
                id: 'expires',
                Cell: row => <div title={formatLastAccess(row.original.expires)}>{formatLastAccess(row.original.expires)}</div>
            },
            {
                Header: '',
                width: 65,
                id: 'edit-menu',
                Cell: row => (
                    isDelete ? <ManageLicensesEditMenu row={row} licenseModel={props.licenseModel} companyId={props.companyId}/> : null
                )
            }
        ];
    }

    return <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight"
                showPaginationBottom={true}
                data={licensesArray}
                minRows={licensesArray && licensesArray.length > 0 ? 0 : 5}
                columns={columns}
                loading={props.loading}
                LoadingComponent={ReactTableLoadingComponent}
                manual
                onFetchData={(state, instance) => {
                    props.fetchLicensesData(props.companyId, state.page + 1, state.pageSize)
                    props.setPageSize(state.pageSize);
                    props.setCurrentPage(state.page+1);
                    resetScrollInsideTable();
                }}
                pages={props.manageLicensesPageCount <= 0 ? 1 : props.manageLicensesPageCount}
                page={props.page}
                defaultPageSize={25}
                onPageSizeChange={(pageSize) => {
                    props.setPageSize(pageSize);
                    resetScrollInsideTable();
                }}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'manage-licenses-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                }
            />
}


const mapStateToProps = createStructuredSelector({
    licenses: selectLicensesList(),
    loading: selectLicensesLoading(),
    manageLicensesPageCount: makeSelectManageLicensesPageCount()
});

const mapDispatchToProps = dispatch => {
    return {
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLicensesTable);
