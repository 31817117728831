/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const SIP = 'SIP/'; 
export const types = {
    ADD_SIP: SIP + 'ADD_SIP',
    GET_SIP_REQUEST: SIP + 'GET_SIP_REQUEST',
    GET_SIP_ERROR: SIP + 'GET_SIP_ERROR',
    GET_SIP_SUCCESS: SIP + 'GET_SIP_SUCCESS',
    EDIT_SIP: SIP + 'EDIT_SIP',
    DELETE_SIP: SIP + 'DELETE_SIP',
};

export const initialState = fromJS({
    sipList: List(),
    sipLoading: false,
    sipListPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_SIP_REQUEST:
            return state.set('sipLoading', true);
        case types.GET_SIP_ERROR:
            return state.set('sipLoading', false);
        case types.GET_SIP_SUCCESS:
            return state.set('sipLoading', false)
                        .set('sipList', List(action.data))
                        .set('sipListPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    addSipRequest: (sip) => ({ 
        type: types.ADD_SIP, 
        data: sip
    }),
    editSipRequest: (sip) => ({
        type: types.EDIT_SIP,
        data: sip
    }),
    deleteSipRequest: (id) => ({
        type: types.DELETE_SIP,
        data: id
    }),
    getSipRequest: (page, pageSize, sortBy, direction) => ({
        type: types.GET_SIP_REQUEST,
        page, 
        pageSize,
        sortBy,
        direction
    }),
    getSipError: () => ({
        type: types.GET_SIP_ERROR
    }),
    getSipSuccess: (sipList, pageCount) => ({
        type: types.GET_SIP_SUCCESS,
        data: sipList,
        pageCount
    })
};

// Selectors
const selectSip = state => state.get('sip', initialState);

export const makeSelectSipList = () =>
    createSelector(selectSip, sipState => sipState.get('sipList'));

export const makeSelectSipListLoading = () =>
    createSelector(selectSip, sipState => sipState.get('sipLoading'));

export const makeSelectSipPageCount = () =>
createSelector(selectSip, sipState => sipState.get('sipListPageCount'));