import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import CallMonitoringAPI from '../apis/callMonitoringApi';
import BuildingAPI from '../apis/buildingsApi';
import { actions as AppActions, selectPageSize, selectCurrentPage, selectTableProps } from '../reducers/app';
import { actions as CallMonitoringActions,
		 types as CallMonitoringTypes } from '../reducers/callMonitoring';
import { userCompanyId, selectUserCompany } from '../reducers/auth';
import pageErrorHandler from '../utils/pageErrorHandler';
import errorHandler from '../utils/errorHandler';


function* getCallMonitoringList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const company = yield select(selectUserCompany());
		const response = yield call(CallMonitoringAPI.getCallMonitoring, companyId, action.page, action.pageSize, action.sortBy, action.direction);
		
		if(company.toJS().locationTreeType !== "SIMPLE"){
			for (const callMonitor of response.data) {
				if (callMonitor.filterOrgType !== 'ORGANIZATION') {
					const buildingResponse = yield call(BuildingAPI.getBuildingById, callMonitor.orgId);
					
					callMonitor.buildingName = buildingResponse.data.name;
				} else {
					callMonitor.buildingName = 'All Buildings';
				}
			}
		}
		for (const iteratorObject of response.data){
			if (iteratorObject.hasFilters) {
				const response = yield call(CallMonitoringAPI.getCallMonitoringFilters ,iteratorObject.id);
				iteratorObject.filterData = response.data;
			}
		}
		yield put(CallMonitoringActions.getCallMonitoringSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(CallMonitoringActions.getCallMonitoringError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addCallMonitoring(action) {
	try {
		const companyId = yield select(userCompanyId());
		const company = yield select(selectUserCompany());
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		const response = yield call(CallMonitoringAPI.addCallMonitoring, action.data, companyId);
		if(company.toJS().locationTreeType === "SIMPLE" || action.data.locationType === "CER_LOCATIONS"){
			if(!action.data.allLocations && action.data.locations.length > 0){
				const tempArray = [];

				for (const iteratorObject of action.data.locations){
					const tempObject = {};
					tempObject.callMonitorId = response.data.id;
					tempObject.filterType = action.data.locationType === "CER_LOCATIONS"  ? "CER_LOCATION" : action.data.locationType === "E911_LOCATIONS" ? "LOCATION" : "LOCATION";
					tempObject.filterOrgId = iteratorObject.id;
					tempArray.push(tempObject);
				}
				yield call(CallMonitoringAPI.addCallMonitoringFilters, tempArray, response.data.id);
			}
		}
		else{
			if (!action.data.allBuildings && action.data.buildings.length > 0){
				const tempArray = [];

				for (const iteratorObject of action.data.buildings){
					const tempObject = {};
					tempObject.callMonitorId = response.data.id;
					tempObject.filterType = action.data.locationType === "CER_LOCATIONS"  ? "CER_LOCATION" : action.data.locationType === "E911_LOCATIONS" ? "BUILDING" : "BUILDING";
					tempObject.filterOrgId = iteratorObject.id;
					tempArray.push(tempObject);
				}
				yield call(CallMonitoringAPI.addCallMonitoringFilters, tempArray, response.data.id);
			}
		}

		yield put(AppActions.setModalClose());
		yield put(CallMonitoringActions.getCallMonitoringRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editCallMonitoring(action) {
	try {
		const company = yield select(selectUserCompany());
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		const response = yield call(CallMonitoringAPI.editCallMonitoring, action.data);
		if(company.toJS().locationTreeType === "SIMPLE" || action.data.locationType === "CER_LOCATIONS"){
			if (action.data.locations.filterData !== undefined && !action.data.allLocations && action.data.locations.filterData.length > 0){
				const tempArray = [];
				for (const iteratorObject of action.data.locations.filterData){
					const tempObject = {};
					tempObject.callMonitorId = response.data.id;
					tempObject.filterType = action.data.locationType === "CER_LOCATIONS"  ? "CER_LOCATION" : action.data.locationType === "E911_LOCATIONS" ? "LOCATION" : "LOCATION";
					tempObject.filterOrgId = iteratorObject.id;
					tempArray.push(tempObject);
				}
				yield call(CallMonitoringAPI.addCallMonitoringFilters, tempArray, response.data.id);
			}
			else if(!action.data.allLocations && action.data.locations.length > 0){
				const tempArray = [];
				for (const iteratorObject of action.data.locations){
					const tempObject = {};
					tempObject.callMonitorId = response.data.id;
					tempObject.filterType = action.data.locationType === "CER_LOCATIONS"  ? "CER_LOCATION" : action.data.locationType === "E911_LOCATIONS" ? "LOCATION" : "LOCATION";
					tempObject.filterOrgId = iteratorObject.id;
					tempArray.push(tempObject);
				}
				yield call(CallMonitoringAPI.addCallMonitoringFilters, tempArray, response.data.id);
			} else {
				yield call(CallMonitoringAPI.deleteCallMonitoringFilters, response.data.id);
			}
		}
		else{
			if (action.data.buildings.filterData !== undefined && !action.data.allBuildings && action.data.buildings.filterData.length > 0){
				const tempArray = [];
				for (const iteratorObject of action.data.buildings.filterData){
					const tempObject = {};
					tempObject.callMonitorId = response.data.id;
					tempObject.filterType = action.data.locationType === "CER_LOCATIONS"  ? "CER_LOCATION" : action.data.locationType === "E911_LOCATIONS" ? "BUILDING" : "BUILDING";
					tempObject.filterOrgId = iteratorObject.id;
					tempArray.push(tempObject);
				}
				yield call(CallMonitoringAPI.addCallMonitoringFilters, tempArray, response.data.id);
			}
			else if(!action.data.allBuildings && action.data.buildings.length > 0){
				const tempArray = [];
				for (const iteratorObject of action.data.buildings){
					const tempObject = {};
					tempObject.callMonitorId = response.data.id;
					tempObject.filterType = action.data.locationType === "CER_LOCATIONS"  ? "CER_LOCATION" : action.data.locationType === "E911_LOCATIONS" ? "BUILDING" : "BUILDING";
					tempObject.filterOrgId = iteratorObject.id;
					tempArray.push(tempObject);
				}
				yield call(CallMonitoringAPI.addCallMonitoringFilters, tempArray, response.data.id);
			} else {
				yield call(CallMonitoringAPI.deleteCallMonitoringFilters, response.data.id);
			}
		}

		yield put(AppActions.setModalClose());
		yield put(CallMonitoringActions.getCallMonitoringRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteCallMonitoring(action) {
	try {
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(CallMonitoringAPI.deleteCallMonitoringFilters, action.data);
		yield call(CallMonitoringAPI.deleteCallMonitoring, action.data);
		yield put(AppActions.setModalClose());
		yield put(CallMonitoringActions.getCallMonitoringRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootCallMonitoringSaga() {
	yield all([
		yield takeLatest(CallMonitoringTypes.GET_CALL_MONITORING_REQUEST, getCallMonitoringList),
		yield takeLatest(CallMonitoringTypes.ADD_CALL_MONITORING, addCallMonitoring),
		yield takeLatest(CallMonitoringTypes.EDIT_CALL_MONITORING, editCallMonitoring),
		yield takeLatest(CallMonitoringTypes.DELETE_CALL_MONITORING, deleteCallMonitoring)
	]);
}

export default rootCallMonitoringSaga;
