import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import { createStructuredSelector } from 'reselect';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';
import { types, makeSelectCallMonitoringPageCount, makeSelectCallMonitoringList, makeSelectCallMonitoringListLoading, actions as CallMonitoringActions } from '../../reducers/callMonitoring';
import { actions as AppActions } from '../../reducers/app';
import { userCompanyId, selectUserCompany } from '../../reducers/auth';
import './CallMonitoringTable.css';
import CallMonitoringEditMenu from '../../containers/CallMonitoringEditMenu/callMonitoringEditMenu';

const initialSortColumns = [
    {
        id: 'name',
        desc:false
    }
]

function CallMonitoringTable(props) {

    const [pageSize, setPageSize] = useState(25);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isEdit = user.permissions.includes('EDIT_CALL_MONITORING');
	const isDelete = user.permissions.includes('DELETE_CALL_MONITORING');
    const isView = isEdit || isDelete;

    const { callMonitoringFromApi, 
            loading, company, fetchCallMonitoringList, callMonitoringPageCount } = props;

    const columns = [
        {
            Header: "Name",
            id: "name",
            sortable: true,
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        }
    ];
    
    if(company.toJS().locationTreeType === 'SIMPLE') {
        columns.push(
            {
                Header: "Location(s)",
                id: "locations",
                Cell: row => {
                    if(row.original.filterData){
                        return (
                            row.original.filterData && row.original.filterData.map((item, index) => (
                                <span title='locationFilterData' key={index}>{item.ouName}{row.original.filterData.length -1 > index ? ", " : ""} </span>   
                        ))
                        )
                    }
                    else
                    {
                        return (<span title='All' >All Locations</span>   )
                    }
                }
            }
        );
    }
    else {
        columns.push(
            {
                Header: "Building(s) / Location(s)",
                id: "buildings",
                Cell: row => {
                    if(row.original.filterData){
                        return (
                            row.original.filterData && row.original.filterData.map((item, index) => (
                                <span title='buildingFilterData' key={index}>{item.ouName}{row.original.filterData.length -1 > index ? ", " : ""} </span>   
                        ))
                        )
                    }
                    else
                    {
                        return (<span title='All' >All Buildings / Locations</span>   )
                    }
                }
            }
        );
    }
    columns.push(
        {
            Header: "Phone Number(s)",
            id: "phone-number",
            Cell: row => {
                const numbersArray = row.original.phoneNumbers.split(',');
                
                function handleClick() {
                    props.setCurrentModal(types.CALL_MONITORING_NUMBERS, {
                        numbersArray
                    });
                }

                if (numbersArray.join().length >= 40) {
                    return <div className="numbers-wrapper">{
                        numbersArray.map((item, i) => {
                            if (numbersArray.length === i + 1) {
                                return <span key={i} title={item}><NumberFormatter number={item} /></span>;
                            } else {
                                return <span key={i} title={item}><NumberFormatter number={item} />, </span>;
                            }
                        })
                    }
                        <span className={`more-link more-link-${row.index}`} onClick={() => handleClick()}>...more</span>
                    </div>;
                } else {
                    return <div className="numbers-wrapper">{
                        numbersArray.map((item, i) => {
                            if (numbersArray.length === i + 1) {
                                return <span key={i} title={item}><NumberFormatter number={item} /></span>;
                            } else {
                                return <span key={i} title={item}><NumberFormatter number={item} />, </span>;
                            }
                        })
                    }</div>;
                }
            }
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                isView ? <CallMonitoringEditMenu row={row} setCurrentModal={props.setCurrentModal} /> : null
            )
        }
    );
    
    useEffect( () => {
        fetchCallMonitoringList(pageIndex +1, pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: pageIndex+1, 
            pageSize: pageSize, 
            sortBy: currentSortColumn.id, 
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, pageSize, pageIndex]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }
    
    let callMonitoring = callMonitoringFromApi;
    if(callMonitoring) {
        callMonitoring = callMonitoring.toArray();
    }

    useEffect(() => {
        if (callMonitoring && callMonitoring.length === 0 && pageIndex > 0 && callMonitoringPageCount < pageIndex+1) {
            setPageIndex(pageIndex-1)
        }
    }, [callMonitoring && callMonitoring.length === 0 && pageIndex > 0 && callMonitoringPageCount < pageIndex+1]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);
    
    return (
        <div>
            <ReactTable
                resizable={false}
                sortable={false} 
                className="-striped -highlight"
                showPaginationBottom={true}
                data={callMonitoring}
                pages={callMonitoringPageCount <= 0 ? 1 : callMonitoringPageCount}
                minRows={callMonitoring && callMonitoring.length > 0 ? 0 : 5}
                columns={columns}
                loading={loading}
                defaultPageSize={25}
                manual
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                currentSortColumn={currentSortColumn}
                setCurrentSortColumn={setCurrentSortColumn} 
                sorted={sortColumns}
                onSortedChange={(newSorted, column, shiftKey) => {
                    let sortedArray = [...sortColumns];
                    let currentSortColumn;
                    sortedArray.forEach( (columnObject, index) => {                    
                        if(column.id === columnObject.id) {
                            // state needs updated object reference, doesn't detect nested fields
                            currentSortColumn = {...columnObject};
                            currentSortColumn.desc = !currentSortColumn.desc;
                            sortedArray[index] = currentSortColumn;
                        }
                        else {
                            let resetColumn = {...columnObject};
                            resetColumn.desc = true;
                            sortedArray[index] = resetColumn;
                        }
                        setCurrentSortColumn(currentSortColumn);
                        setSortColumns(sortedArray);
                    })
                }}
                onFetchData={(state, instance) => {
                    resetScrollInsideTable();
                }}
                onPageSizeChange={(pageSize) => {
                    setPageIndex(0);
                    setPageSize(pageSize);
                    resetScrollInsideTable();
                }}
                onPageChange={(pageIndex) => {
                    setPageIndex(pageIndex);
                    resetScrollInsideTable();
                }}
                LoadingComponent={ReactTableLoadingComponent} 
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'call-monitoring-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                }
                />
        </div>
    );

}

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({
        callMonitoringFromApi: makeSelectCallMonitoringList(),
        loading: makeSelectCallMonitoringListLoading(),
        company: selectUserCompany(),
        callMonitoringPageCount: makeSelectCallMonitoringPageCount(),

    });
}

const mapDispatchToProps = dispatch => {
    return { 
        fetchCallMonitoringList: (pageIndex, pageSize, sortBy, direction) => dispatch(CallMonitoringActions.getCallMonitoringRequest(pageIndex,pageSize, sortBy, direction)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setPageIndex: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CallMonitoringTable);
    