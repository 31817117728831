import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';

import reducer from '../../reducers/alerts';
import { actions as AppActions } from '../../reducers/app';
import saga from '../../sagas/alerts';

import { compose } from 'redux';
import { connect } from 'react-redux';
import AlertsSubscriptions from '../../containers/AlertsSubscriptions/AlertsSubscriptions';
import AlertsTemplates from '../../containers/AlertsTemplates/AlertsTemplates';

const styles = theme => ({
  tabs: {
    justifyContent: 'center'
  },
});

function AlertSubscriptionsPage(props) {
    const storedAlertsTab = parseInt(sessionStorage.getItem('alertsTab'));

    const TABS_CONST = {
        SUBSCRIPTIONS: 'Alert Subscriptions',
        TEMPLATES: 'Alert & Email Templates'
    }

    const [currentTab, setTab] = useState(storedAlertsTab ? storedAlertsTab : 0);

    function handleChange(event, value) {
        setTab(value);
    }

    useEffect(() => {
        props.setPageTitle('Alerts & Emails');
        props.setPageTitleId('alerts-header');
    }, []);

    useEffect(() => {
        switch (currentTab) {
            case 0:
                return sessionStorage.setItem('alertsTab', 0);
            case 1:
                return sessionStorage.setItem('alertsTab', 1);
            default:
                break;
        }
    }, [currentTab]);

    return (
        <div>
            <AppBar position="static" color="default">
                <Tabs className="tabs-bar"
                    value={currentTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="fullWidth"
                >
                    <Tab id="alert-subscription-header" key={0} label={TABS_CONST.SUBSCRIPTIONS}/>
                    <Tab id="alert-template-header" key={1} label={TABS_CONST.TEMPLATES}/>
                </Tabs>
            </AppBar>
            <br />
            {currentTab === 0 && <AlertsSubscriptions setCurrentModal={props.setCurrentModal}/>}
            {currentTab === 1 && <AlertsTemplates setCurrentModal={props.setCurrentModal}/>}
            <br/>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'alerts', reducer });
const withSaga = injectSaga({ key: 'alerts', saga });
const withConnect = connect(null, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withStyles(styles),
    withConnect
)(AlertSubscriptionsPage);