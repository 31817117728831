import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Select, InputLabel, Input, MenuItem, Switch } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import SipAPI from '../../../../apis/sipApi';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const SipForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit, 
            modalLoading,
            setMonitorTrunk,
            disableRedskyRelayCenterOption,
            setDisableRedskyRelayCenterOption,
            modalData,
            setFieldValue,
            setErrors,
            setTouched,
            classes } = props;

    const [overrideDestinationTypes, setOverrideDestinationTypes] = useState([]);

    useEffect(() => {
        SipAPI.getSipPeerDestinationTypes().then(response => {
            setOverrideDestinationTypes(response.data);
        });
    }, []);

    useEffect(() => {
        if (values.monitorTrunk) {
            setMonitorTrunk(true);
        } else {
            setMonitorTrunk(false);
        }
    }, [values.monitorTrunk]);

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    function handleDisableRedskyRelayCenterOptionChange(e) {
       values.disableRelayCenter = e.target.value;
       if(e.target.value){
        setDisableRedskyRelayCenterOption(e.target.value);
       }
       else{
           setDisableRedskyRelayCenterOption(null);
       }
        // reset form data on toggle
        setErrors({});
        setTouched({});
    }

    function handleToggle(e){
        if(values.disableRelayCenter == 'TEST' || values.disableRelayCenter == 'REJECTED_ERC'){
           values.disableRelayCenter = null;
        }
        else{
           values.disableRelayCenter = 'TEST';
        }
        
        setErrors({});
        setTouched({});
   }

    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <TextField
                id="name"
                label="*Name"
                
                name="name"
                type="name"
                onChange={handleChange}
                value={values.name}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/> 
                <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <br/>
            <TextField
                id="host"
                label="*Host"
                
                name="host"
                type="host"
                onChange={handleChange}
                value={values.host}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.host && Boolean(errors.host)) || (errors.host && Boolean(errors.host.includes('max')))}/> 
                <span id="host-helper-text" className="error-prompt">{(touched.host && Boolean(errors.host)) || (errors.host && Boolean(errors.host.includes('max'))) ? errors.host : ''}</span>
            <br/>
            <br/>
            <InputLabel shrink={true} htmlFor="transport-label" error={touched.transport && Boolean(errors.transport)}>*Transport</InputLabel>
            <Select
                id="transport"
                name="transport"
                value={values.transport}
                onChange={handleChange}
                disabled={modalLoading}
                input={<Input name="transport" id="transport-label"/>}
                fullWidth={true}
                error={touched.transport && Boolean(errors.transport)}>
                <MenuItem value="UDP">UDP</MenuItem>
                <MenuItem value="TCP">TCP</MenuItem>
                <MenuItem value="TLS">TLS</MenuItem>
            </Select>
            <span id="transport-helper-text" className="error-prompt">{touched.transport ? errors.transport : ""}</span>
            <br/>
            <br/>
            <InputLabel shrink={true} htmlFor="monitorTrunk-label" error={touched.monitorTrunk && Boolean(errors.monitorTrunk)}>Monitor Trunk</InputLabel>
            <Switch
                id="monitorTrunk"
                name="monitorTrunk"
                checked={values.monitorTrunk}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            {values.monitorTrunk &&
                <div>
                    <TextField
                        id="portNumber"
                        label="*Port Number"
                        name="portNumber"
                        type="number"
                        onChange={handleChange}
                        value={values.portNumber}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={(touched.portNumber && Boolean(errors.portNumber)) || (errors.portNumber && Boolean(errors.portNumber.includes('max')))}
                        onKeyDown={(e) => {
                            const key = e.keyCode;
                            if (key == 32 || key == 69 || key == 190) {
                                e.preventDefault();
                            }
                        }}
                        onPaste={(e) => {
                            const str = e.clipboardData.getData('Text');
                                const newStr = str.replace(/ |e/g, '');
                                if (str !== newStr) {
                                    e.preventDefault()
                                }
                        }}
                        /> 
                        <span id="portNumber-helper-text" className="error-prompt">{(touched.portNumber && Boolean(errors.portNumber)) || (errors.portNumber && Boolean(errors.portNumber.includes('max'))) ? errors.portNumber : ''}</span>
                </div>
            }
            <br/>
            <InputLabel shrink={true} htmlFor="skype-label" error={touched.skype && Boolean(errors.skype)}>Skype</InputLabel>
            <Switch
                name="skype"
                checked={values.skype}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <br/>
            <InputLabel shrink={true} htmlFor="overrideDestinationToggle-label" error={touched.overrideDestinationToggle && Boolean(errors.overrideDestinationToggle)}>Override Destination</InputLabel>
            <Switch
                id="overrideDestinationToggle"
                name="overrideDestinationToggle"
                checked={values.overrideDestinationToggle}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            { values.overrideDestinationToggle &&
                <Select
                    id="overrideDestination"
                    name="overrideDestination"
                    value={values.overrideDestination}
                    onChange={handleChange}
                    disabled={modalLoading}
                    input={<Input name="overrideDestination" id="overrideDestination-label"/>}
                    fullWidth={true}
                    error={touched.overrideDestination && Boolean(errors.overrideDestination)}>
                    {overrideDestinationTypes.map((item, index) => (
						<MenuItem key={index} value={item.type}>
							<em>{item.displayName}</em>
						</MenuItem>	
					))}
                </Select>
            }
            <br/>
            <div style={{display:'flex', alignItems: 'center'}}>
            <InputLabel htmlFor="disableRelayCenter-label" error={touched.disableRelayCenter && Boolean(errors.disableRelayCenter)}>Disable RedSky Relay Center</InputLabel>
            <Switch
                id="disableRelayCenter"
                name="disableRelayCenter"
                value={values.disableRelayCenter ? values.disableRelayCenter : ''}
                checked={(values.disableRelayCenter != '' &&  values.disableRelayCenter != null)}
                onChange={handleToggle}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            { values.disableRelayCenter &&
                 <RadioGroup value={disableRedskyRelayCenterOption} onChange={handleDisableRedskyRelayCenterOptionChange} row>
                     <FormControlLabel value={"TEST"} control={<Radio color="primary" />} label="Test Player" />
                     <FormControlLabel value={"REJECTED_ERC"} control={<Radio color="primary" />} label="Reject Calls"/>
                 </RadioGroup>
            }
            </div>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

export default SipForm;