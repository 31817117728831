import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import EventsAPI from '../apis/eventsApi';
import { userCompanyId } from '../reducers/auth';
import { actions as AppActions } from '../reducers/app';
import { actions as EventsActions,
		 types as EventsTypes } from '../reducers/events';
import pageErrorHandler from '../utils/pageErrorHandler';

function* getEventsList(action) {
	
	try {
		const companyId = yield select(userCompanyId());
		let response;
		if (!companyId) {
			response = yield call(EventsAPI.getEvents, action.page, action.pageSize, action.startDate, action.endDate, action.eventType, action.userRole, action.sortBy, action.sortDirection);
		} else {
			response = yield call(EventsAPI.getEventsByCompanyId, companyId, action.page, action.pageSize, action.startDate, action.endDate, action.eventType, action.userRole, action.sortBy, action.sortDirection);
		}
		yield put(EventsActions.getEventsSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(EventsActions.getEventsError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* rootLocationsSaga() {
	yield all([
		yield takeLatest(EventsTypes.GET_EVENTS_REQUEST, getEventsList)
	]);
}

export default rootLocationsSaga;
