import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import ReportsAPI from '../apis/reportsApi';
import { userCompanyId } from '../reducers/auth';
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as ScheduledReportsActions,
		 types as ScheduledReportsTypes } from '../reducers/scheduledReports';
import pageErrorHandler from '../utils/pageErrorHandler';
import errorHandler from '../utils/errorHandler';

function* getScheduledReportsList(action) {
	try {
		const companyId = yield select(userCompanyId());
		let response;
		if (companyId) {
			response = yield call(ReportsAPI.getReportsByCompanyId, companyId, action.page, action.pageSize, action.sortBy, action.sortDirection);
		} else {
			response = yield call(ReportsAPI.getAllReports, action.page, action.pageSize, action.sortBy, action.sortDirection);
		}

		yield put(ScheduledReportsActions.getScheduledReportsSuccess(response.data, response.headers['x-pagination-count']));	
	} catch (error) {
		yield put(ScheduledReportsActions.getScheduledReportsError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* editScheduledReport(action) {
	try {
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(ReportsAPI.editScheduleReport, action.data);
		yield put(AppActions.setModalClose());
		yield put(
			ScheduledReportsActions.getScheduledReportsRequest(
				tableProps.page, 
				tableProps.pageSize, 
				tableProps.sortBy,
				tableProps.direction
			)
		);
	} catch (error) {
		console.log(error);
		let options = {};

		// first check field line errors
		if(error.response.data.type === "http://cirrus.redskytech.com/errors/location/badRequest") {
			options.backendFieldError = {
			 name: 'The scheduled report name provided already exists.'
			};
		  }

		// will set the red error box message
		if(!options.backendFieldError) {
			options.displayTopErrorMessage = undefined;
			options.topErrorMessage = error.response.data.title;
		}

		error.response.data.errorOptions = options;
		yield put(AppActions.setModalErrorOptions(errorHandler(error)));
	}
}

function* pauseScheduledReport(action) {
	try {
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(ReportsAPI.pauseScheduleReport, action.data);
		yield put(AppActions.setModalClose());
		yield put(
			ScheduledReportsActions.getScheduledReportsRequest(
				tableProps.page, 
				tableProps.pageSize, 
				tableProps.sortBy,
				tableProps.direction)
		);
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteScheduledReport(action) {
	try {
		const tableProps = yield select(selectTableProps());

	  yield put(AppActions.setModalLoading());
	  yield call(ReportsAPI.deleteScheduleReport, action.data);
	  yield put(AppActions.setModalClose());
	  yield put(
		ScheduledReportsActions.getScheduledReportsRequest(
			tableProps.page, 
			tableProps.pageSize, 
			tableProps.sortBy,
			tableProps.direction
		)
	);
	} catch (error) {
	  console.log(error);
	  yield put(AppActions.setModalError(errorHandler(error)));
	}
  }


function* rootLocationsSaga() {
	yield all([
		yield takeLatest(ScheduledReportsTypes.GET_SCHEDULED_REPORTS_REQUEST, getScheduledReportsList),
		yield takeLatest(ScheduledReportsTypes.EDIT_SCHEDULED_REPORT, editScheduledReport),
		yield takeLatest(ScheduledReportsTypes.PAUSE_SCHEDULED_REPORT, pauseScheduledReport),
		yield takeLatest(ScheduledReportsTypes.DELETE_SCHEDULED_REPORT, deleteScheduledReport),
	]);
}

export default rootLocationsSaga;
