import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import SearchField  from '../../utils/searchField';

import reducer, { actions as LocationsAction, types as locationsTypes, makeSelectBuildingsList } from '../../reducers/locations';
import saga from '../../sagas/locations';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { selectUserCompany } from '../../reducers/auth';

import { createStructuredSelector } from 'reselect';

import { debounce } from 'lodash';

import LocationsTable from '../../containers/LocationsTable/LocationsTable';

const initialSortColumns = [
    {
        id: 'name',
        desc:false
    }
]

function LocationsPage(props) {
    const [searchValue, setSearchValue] = useState('');
    const [isSearchData, setIsSearchData] = useState(false);
    const [expandedList, setExpandedList] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const isSimple = props.company.toJS().locationTreeType === "SIMPLE";
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    useEffect(() => {
        props.setPageTitle('CER Locations');
        props.setPageTitleId('cer-locations-header');
    }, []);
    
    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const inputValue1 = inputValue.replace ( /[^A-Za-z0-9.,?@&;:!+#'~* _-]/g, '' );

            setSearchValue(inputValue1);
            searchApiCall(
                inputValue1, 
                pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC'
            );
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, direction) => {
            if (inputValue.length > 0) {
                setPage(0);
                props.getLocationsRequest(props.company.toJS().id, 'CER', 1, pageSize, inputValue, sortBy, direction);
                setIsSearchData(true);
            } else if(inputValue.length === 0) {
                setPage(0);
                props.getLocationsRequest(props.company.toJS().id, 'CER', 1, pageSize, inputValue, sortBy, direction);
                setIsSearchData(false);
            }

        }, 500)
    , []);

    return (
        <div>
            <Grid container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item style={{ marginRight: 'auto'}}>
                    <SearchField 
                        value={searchValue}
                        onChange={handleInputSearchChange}
                    />
                </Grid>
            </Grid>
            <br/>
            <LocationsTable  
                {...props}
                cerTable={true}
                company={props.company.toJS()}
                setCurrentModal={props.setCurrentModal} 
                searchTerm={searchValue}
                fetchBuildings={props.fetchBuildings}
                isSearchData={isSearchData}
                expandedList={expandedList} 
                setExpandedList={setExpandedList}
                pageSize={pageSize}
                setPageSize={setPageSize}
                page={page}
                setPage={setPage} 
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                currentSortColumn={currentSortColumn}
                setCurrentSortColumn={setCurrentSortColumn} 
                searchValue={searchValue}
                organizationUnitId={props.company.toJS().id}
                 />
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    buildings: makeSelectBuildingsList(),
    company: selectUserCompany()
    
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchBuildings: (page, pageSize, searchTerm) => dispatch(LocationsAction.getBuildingsRequest(page, pageSize, searchTerm)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        getLocationsRequest: (organizationUnitId, origin, page, pageSize, searchTerm, sortBy, direction) => dispatch(LocationsAction.getLocationsRequest(organizationUnitId, origin, page, pageSize, searchTerm, sortBy, direction)),
    }
};

const withReducer = injectReducer({ key: 'locations', reducer });
const withSaga = injectSaga({ key: 'locations', saga });
const withConnect = connect(mapStateToProps,mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(LocationsPage);