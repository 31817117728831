import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Select, InputLabel, Input, MenuItem, Switch } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from 'react-number-format';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/pstn';
import { connect } from 'react-redux';
import PstnAPI from '../../../apis/pstnApi';

const validationSchema = Yup.object({
    name: Yup.string("Enter a name")
            .trim()
            .required("Name is a required field.")
            .max(50, "Name has a max limit of 50 characters."),
    did: Yup.string("Enter a DID")
            .required("DID is a required field.")
})

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    icon: {
        position: 'absolute',
        right: '19px',
        bottom: '82px'
    },
    pstn: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    }
});

const AddPstnForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit, 
            modalLoading,
            setErrors,
            modalData,
            setTouched,
            classes } = props;

    const [overrideDestinationTypes, setOverrideDestinationTypes] = useState([]);
    const [disableRedskyRelayCenterOption, setDisableRedskyRelayCenterOption] = useState(modalData ? getInitialDisableRedskyRelayCenterOption(modalData) : 'TEST');

    useEffect(() => {
        PstnAPI.getPstnPeerDestinationTypes().then(response => {
            setOverrideDestinationTypes(response.data);
        });
    }, []);

    function getInitialDisableRedskyRelayCenterOption(modalData) {
        if(modalData.disableRedskyRelayCenterOption == 'TEST') {
            return 'TEST';
        } else {
            return '';
        }
    }

    const handleOnChange = (values) => {
        if (values.value) {
            props.setFieldValue('did', values.value);
        } else {
            props.setFieldValue('did', '');
        }
        props.setFieldTouched('did', true);
    }

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    function handleDisableRedskyRelayCenterOptionChange(e) {
        values.disableRelayCenter = e.target.value;
        if(e.target.value){
         setDisableRedskyRelayCenterOption(e.target.value);
        }
        else{
            setDisableRedskyRelayCenterOption(null);
        }
         // reset form data on toggle
         setErrors({});
         setTouched({});
     }
        
    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <TextField
                id="name"
                label="*Name"
                
                name="name"
                type="name"
                onChange={handleChange}
                value={values.name}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/> 
                <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <br/>
            <NumberFormat 
                autoComplete="no"
                id="did"
                label="*DID"
                
                name="did"
                type="tel"
                onValueChange={handleOnChange}
                value={values.did}
                onBlur={handleBlur}
                disabled={modalLoading}
                className={classes.field}
                fullWidth={true}
                error={touched.did && Boolean(errors.did)}
                format="(###) ###-####" 
                customInput={TextField}
                mask="_"/>
                <span id="did-helper-text" className="error-prompt">{touched.did ? errors.did : ""}</span>
            <br/>
            <br/>
            <InputLabel shrink={true} htmlFor="overrideDestinationToggle-label" error={touched.overrideDestinationToggle && Boolean(errors.overrideDestinationToggle)}>Override Destination</InputLabel>
            <Switch
                id="overrideDestinationToggle"
                name="overrideDestinationToggle"
                checked={values.overrideDestinationToggle}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            { values.overrideDestinationToggle &&
                <Select
                    id="overrideDestination"
                    name="overrideDestination"
                    value={values.overrideDestination}
                    onChange={handleChange}
                    disabled={modalLoading}
                    input={<Input name="overrideDestination" id="overrideDestination-label"/>}
                    fullWidth={true}
                    error={touched.overrideDestination && Boolean(errors.overrideDestination)}>
                    {overrideDestinationTypes.map((item, index) => (
						<MenuItem key={index} value={item.type}>
							<em>{item.displayName}</em>
						</MenuItem>	
					))}
                </Select>
            }
            <br/>
            <div style={{display:'flex', alignItems: 'center'}}>
            <InputLabel htmlFor="disableRelayCenter-label" error={touched.disableRelayCenter && Boolean(errors.disableRelayCenter)}>Disable RedSky Relay Center</InputLabel>
            <Switch
                id="disableRelayCenter"
                name="disableRelayCenter"
                checked={(values.disableRelayCenter != '' &&  values.disableRelayCenter != null)}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
             { values.disableRelayCenter &&
                 <RadioGroup value={disableRedskyRelayCenterOption} onChange={handleDisableRedskyRelayCenterOptionChange} row>
                     <FormControlLabel value={"TEST"} control={<Radio color="primary" />} label="Test Player" />
                     <FormControlLabel value={"REJECTED_ERC"} control={<Radio color="primary" />} label="Reject Calls"/>
                 </RadioGroup>
            }
            </div>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

const AddPstnModal = props => {
    const { modalLoading, classes } = props;

    return(
        <Formik
            initialValues={{ 
                name: '',
                did: '',
                overrideDestinationToggle: false,
                overrideDestination: 'TEST',
                disableRelayCenter: null
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    if (!values.overrideDestinationToggle) {
                        values.overrideDestination = null;
                    }

                    props.submitAddPstnForm(values);
                }
            }
            render={renderProps => <AddPstnForm classes={classes} modalLoading={modalLoading} {...renderProps} />}
        />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitAddPstnForm: (pstn) => dispatch(actions.addPstnRequest(pstn))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddPstnModal);