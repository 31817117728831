import React, { useEffect } from 'react';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';

import reducer from '../../reducers/events';
import { actions as AppActions } from '../../reducers/app';
import saga from '../../sagas/events';

import { compose } from 'redux';
import { connect } from 'react-redux';

import EventsList from '../../containers/EventsList/eventsList';

function EventsPage(props) {
    useEffect(() => {
        props.setPageTitle('Events');
        props.setPageTitleId('events-header');
    }, []);

    return (
        <div>
            <EventsList />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return { 
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'events', reducer });
const withSaga = injectSaga({ key: 'events', saga });
const withConnect = connect(null, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(EventsPage);