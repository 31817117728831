import { axiosInstance } from './api';
import moment from 'moment';
import 'moment-timezone';

/* Cirrus Admin-Service /locations Controller */
const EVENTS_ENDPOINT = 'events-service/events';
const EVENT_TYPES_ENDPOINT = 'events-service/events/types';

const zone_name =  moment.tz.guess();
const timezone = moment.tz(zone_name).zoneAbbr(); 

export default class EventsAPI {
    static getEventsByCompanyId(companyId, page, pageSize, startDate, endDate, eventType, userRole, sortBy, direction) {
        startDate = startDate ? startDate.format("ddd MMM D hh:mm:ss a YYYY") + ' ' + zone_name : moment().format("ddd MMM D hh:mm:ss a YYYY") + ' ' + zone_name;
        endDate = endDate ? endDate.format("ddd MMM D hh:mm:ss a YYYY") + ' ' + zone_name : moment().format("ddd MMM D hh:mm:ss a YYYY") + ' ' + zone_name;

        //convert to UTC
        startDate = moment.tz(startDate, "ddd MMM D hh:mm:ss a YYYY z", zone_name).utc().format("ddd MMM D hh:mm:ss a YYYY z");
        endDate = moment.tz(endDate, "ddd MMM D hh:mm:ss a YYYY z", zone_name).utc().format("ddd MMM D hh:mm:ss a YYYY z");

         return axiosInstance.get(`${EVENTS_ENDPOINT}/${companyId}`, 
         {
             params: {
                page,
                pageSize,
                startDate,
                endDate,
                eventType: eventType === undefined || eventType.length === 0 ? eventType : eventType.join(), 
                userRole: userRole === undefined || userRole.length === 0 ? userRole : userRole.join(),
                sortBy,
                direction
            }
         }
     )
    }
    static getEvents(page, pageSize, startDate, endDate, eventType, userRole, sortBy, direction) {
        startDate = startDate ? startDate.format("ddd MMM D hh:mm:ss a YYYY") + ' ' + zone_name : moment().format("ddd MMM D hh:mm:ss a YYYY") + ' ' + zone_name;
        endDate = endDate ? endDate.format("ddd MMM D hh:mm:ss a YYYY") + ' ' + zone_name : moment().format("ddd MMM D hh:mm:ss a YYYY") + ' ' + zone_name;

        //convert to UTC
        startDate = moment.tz(startDate, "ddd MMM D hh:mm:ss a YYYY z", zone_name).utc().format("ddd MMM D hh:mm:ss a YYYY z");
        endDate = moment.tz(endDate, "ddd MMM D hh:mm:ss a YYYY z", zone_name).utc().format("ddd MMM D hh:mm:ss a YYYY z");

         return axiosInstance.get(`${EVENTS_ENDPOINT}`, 
         {
             params: {
                page,
                pageSize,
                startDate,
                endDate,
                eventType: eventType === undefined || eventType.length === 0 ? eventType : eventType.join(), 
                userRole: userRole === undefined || userRole.length === 0 ? userRole : userRole.join(),
                sortBy,
                direction
            }
         }
     )
    }
    static getEventTypes() {
        return axiosInstance.get(EVENT_TYPES_ENDPOINT);
    }
}