import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ScheduledReportsRecipientListTable from '../../../../containers/ScheduledReportsRecipientListTable/ScheduledReportsRecipientListTable';

export default function EmailInformation(props) {

    const {
        values,
        handleChange,
        touched,
        modalLoading,
        errors,
        handleBlur,
        emailList,
        setFieldValue,
        setFieldTouched,
        setEmailList
    } = props;

    const handleEmailSubjectChange = (values) => {
        if (values.target.value) {
            setFieldValue('emailSubject', values.target.value);
        } else {
            setFieldValue('emailSubject', '');
        }
        setFieldTouched('emailSubject', true);
    }

    const handleEmail = () => { 
        if (values.email && (!errors.email || errors.email.includes('At least one recipient must be added to the list.')) && !emailList.find(item => item.emailRecipient === values.email)) {            
            setEmailList([
                ...emailList,
                {
                    emailRecipient: values.email
                }
            ]);
        
            values.email = '';

            const emailField = document.querySelector('#email');
            emailField.focus();
            setTimeout(() => {
                emailField.blur();
            }, 100);
        }
    }

    const emailListDeleteHandler = row => {
        setEmailList(
            emailList.filter(item => item.emailRecipient !== row.original.emailRecipient)
        );
    }

    return (
        <div>
            <div className="email-information">
                <div>
                    <TextField
                        id="emailSubject"
                        label="*Email Subject Line"
                        type="name"
                        onChange={handleEmailSubjectChange}
                        value={values.emailSubject}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={(touched.emailSubject && Boolean(errors.emailSubject)) || (errors.emailSubject && Boolean(errors.emailSubject.includes('max')))}/>
                        <span id="emailSubject-helper-text" className="error-prompt">{(touched.emailSubject && Boolean(errors.emailSubject)) || (errors.emailSubject && Boolean(errors.emailSubject.includes('max'))) ? errors.emailSubject : ''}</span>
                    <TextField
                        id="email"
                        autoComplete="off"
                        label={emailList.length <= 0 ? '*Email Address' : 'Email Address'}
                        type="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                        error={(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')))}/>
                        <span id="email-helper-text" className="error-prompt">{(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))) ? errors.email : ''}</span>
                </div>
                <Button style={{ margin: '0px 0 7px 20px' }}
                        id="subscription-add-email-button"
                        disabled={modalLoading} 
                        className="button"
                        color="primary" 
                        onClick={handleEmail}
                        variant="contained">Add</Button>
            </div>
            <ScheduledReportsRecipientListTable
                emailList={emailList}
                emailListDeleteHandler={emailListDeleteHandler} />
        </div>
    )
}