import React, { useState, useEffect, useCallback } from 'react';
import { Map } from 'immutable';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';

import reducer from '../../reducers/organizations';
import saga from '../../sagas/organizations';
import { checkCustomPermission } from '../../containers/App/routeAndNavData';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { types as OrganizationsTypes, actions as organizationsActions, makeSelectOrganizationsList } from '../../reducers/organizations';
import { actions as AppActions, selectPageSize } from '../../reducers/app';

import OrganizationsTable from '../../containers/OrganizationsTable/organizationsTable';
import SubtenantsTable from '../../containers/SubtenantsTable/subtenantsTable';
import { selectUser, selectUserRole, selectUserCompany, currentCompanyOrgType, orgTypes, changeSubtenant, selectOriginalLoginCompany } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import { debounce } from 'lodash';

const initialSortColumns = [
    {
        id: 'name',
        desc:false
    }
]

function OrganizationsPage(props) {
    const [searchValue, setSearchValue] = useState('');
    const [searchData, setSearchData] = useState(false);
    const [searchFromTopLevel, setSearchFromTopLevel] = useState(false);
    const [page, setPage] = useState(0); 
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    useEffect(() => {
        props.setPageTitle('Organizations');
        props.setPageTitleId('organization-header');
        
        return () => {
            // clean up global state
            props.setSearchTerm('');
        }
    }, []);

    function handleInputSearchChange(event) {
        const inputValue = event.target.value;
        const isMatch = searchFieldRegex(inputValue);
        if(isMatch) {
            setSearchValue(inputValue);
            searchApiCall(
                inputValue, 
                props.pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC'
            );
        }
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, sortDirection) => {
            props.setSearchTerm(inputValue);
            if (inputValue.length > 0) {
                setPage(0);
                if(props.currentCompanyOrgType === orgTypes.SERVICE_PROVIDER || props.currentCompanyOrgType === orgTypes.RESELLER) {
                    props.getSubtenants(props.company.get('id'), 1, pageSize, inputValue, sortBy, sortDirection);
                    setSearchData(false)
                    setSearchFromTopLevel(true);
                } else if(props.currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM) {
                    props.getOrganizations(null, 1, pageSize, inputValue, sortBy, sortDirection);
                    setSearchData(true)
                } else {
                    setSearchData(false)   
                }
            } else if (inputValue.length === 0) {
                setPage(0);
                if(props.currentCompanyOrgType === orgTypes.SERVICE_PROVIDER || props.currentCompanyOrgType === orgTypes.RESELLER) {
                    props.getSubtenants(props.company.get('id'), 1, pageSize, '', sortBy, sortDirection);
                } else if(props.currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM) {
                    props.getOrganizations(null, 1, pageSize, '', sortBy, sortDirection);
                }
                setSearchData(false)
                setSearchFromTopLevel(false);
            }
        }, 500)
    , []);

    function modalHandleOpen() {
        props.setCurrentModal(OrganizationsTypes.ADD_ORGANIZATION);
    }

    function displayOrganizationsList() {
        if(props.currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM) {
            return <OrganizationsTable 
                        companyId={company.get('id')} 
                        setCurrentModal={props.setCurrentModal} 
                        getOrganizations={props.getOrganizations}
                        searchData={searchData}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                        pageSize={props.pageSize}
                        page={page}
                        setPage={setPage}
                        sortColumns={sortColumns}
                        setSortColumns={setSortColumns}
                        currentSortColumn={currentSortColumn}
                        setCurrentSortColumn={setCurrentSortColumn}
                        checkCustomPermission={checkCustomPermission} 
                        user={props.user}
                        />
        }
        else {
            if(searchData) {
                setSearchData(false);
                setSearchValue('');
            }
            return <SubtenantsTable 
                        parentId={company.get('id')} 
                        setCurrentModal={props.setCurrentModal}
                        subtenantsFromSearch={searchData}
                        searchFromTopLevel={searchFromTopLevel}
                        searchValue={searchValue}
                        subtenantPageSize={props.pageSize}
                        pageSize={props.pageSize}
                        page={page}
                        setPage={setPage}
                        sortColumns={sortColumns}
                        setSortColumns={setSortColumns}
                        currentSortColumn={currentSortColumn}
                        setCurrentSortColumn={setCurrentSortColumn}
                        checkCustomPermission={checkCustomPermission}
                        user={props.user} />
        }
    }
    
    let company = props.company;
    let role = props.userRole;

    if(!company)
        company = Map()
    if(!role)
        role = Map();
    
    return (
        <div>
            <Grid container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item style={{ marginRight: 'auto' }}>
                    { props.currentCompanyOrgType !== orgTypes.CUSTOMER &&
                        <SearchField 
                            value={searchValue}
                            onChange={handleInputSearchChange}
                        />
                    }
                </Grid>
                
                <Grid item>
                    {
                        !searchData && 
                        !searchFromTopLevel && 
                        checkCustomPermission('CUSTOM_ADD_ADMIN', props.user) &&
                            <Button className="button" id="add-organization-button" variant="contained" color="primary" onClick={modalHandleOpen}>Add Organization</Button> 
                    }
                </Grid>
            </Grid>
            <br/>
            { displayOrganizationsList() }
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        changeSubtenant: (company) => dispatch(changeSubtenant(company)),
        getOrganizations: (parentId, page, pageSize, searchTerm, sortBy, sortDirection) => dispatch(organizationsActions.getOrganizationsByParentId(parentId, page, pageSize, searchTerm, sortBy, sortDirection)),
        getSubtenants: (parentId, page, pageSize, searchTerm, sortBy, sortDirection) => dispatch(organizationsActions.getSubtenantsRequest(parentId, page, pageSize, searchTerm, sortBy, sortDirection)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        setSearchTerm: searchTerm => dispatch(AppActions.setSearchTerm(searchTerm))
    }
};

const mapStateToProps = () => createStructuredSelector({
    company: selectUserCompany(),
    userRole: selectUserRole(),
    organizations: makeSelectOrganizationsList(),
    pageSize: selectPageSize(),
    currentCompanyOrgType: currentCompanyOrgType(),
    userLoginCompany: selectOriginalLoginCompany(),
    user: selectUser()
});

const withReducer = injectReducer({ key: 'organizations', reducer });
const withSaga = injectSaga({ key: 'organizations', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(OrganizationsPage);