import React, { useState } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import CompanyFeaturesApi from '../../../apis/companyFeaturesApi';
import { createStructuredSelector } from "reselect";
import { selectUserCompany, currentCompanyLevel } from '../../../reducers/auth';
import { actions as OrganizationManagementActions } from '../../../reducers/organizationManagement';
import { types as AppTypes, selectUserWelcomeEmailEnabled } from '../../../reducers/app';
import { connect } from "react-redux";
import Switch from '@material-ui/core/Switch';

import Grid from '@material-ui/core/Grid';

function UserWelcomeEmailFeature(props) {
    
    const [submitStatus, setSubmitStatus] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const company = props.company.toJS();

    const handleChange = () => {
        setDataLoading(true);
        (props.userWelcomeEmailEnabled ? 
            CompanyFeaturesApi.removeCompanyFeatures(company.id, 'USER_WELCOME_EMAIL') : 
            CompanyFeaturesApi.addCompanyFeatures(company.id, 'USER_WELCOME_EMAIL')
        ).then(response => {
            setSubmitStatus('success');
            setDataLoading(false);
            props.getOrganizationFeatures();
        }).catch(error => {
            console.log(error)
            setSubmitStatus('failure');
            setDataLoading(false);
        });
    }

    return (
         <div>
            {dataLoading && <LinearProgress/>}
            <Grid container justify="center" direction="row" alignItems="center" style={{margin: '20px'}}>
                {dataLoading && <LinearProgress/>}
                <Grid item xs={12} sm={3}>
                    <label htmlFor="userWelcomeEmailSwitch">Send Auto-Generated Welcome Emails to Organization Administrators</label>
                </Grid>
                <Grid item xs={12} sm={1}>
                    <Switch
                        id="userWelcomeEmailSwitch"
                        name="userWelcomeEmailSwitch"
                        color="primary"
                        checked={props.userWelcomeEmailEnabled}
                        onChange={handleChange}
                        disabled={dataLoading || !props.disableInput}/>
                </Grid>
            </Grid>
            {submitStatus === 'failure' && 
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 680 }}>
                <ErrorOutlineIcon style={{ color: '#f4473a', marginRight: 10, fontSize: 30 }}/>
                <div style={{ color: '#f4473a' }}>ERROR! We were not able to set Auto-Generated Welcome Email to Users feature. Please try again later.</div>
            </div>
            }
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    company: selectUserCompany(),
    userWelcomeEmailEnabled: selectUserWelcomeEmailEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationFeatures: () => dispatch(OrganizationManagementActions.getOrganizationFeatures())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserWelcomeEmailFeature);