export default function convertLicenseCountResponse(data) {
    const licenseNames = Object.getOwnPropertyNames(data);
    const licenseCounts = Object.values(data);
    const licenseCountArray = [];
    for(let i = 0; i < licenseNames.length; i++) {
        licenseCountArray.push({
            type: licenseNames[i],
            count: licenseCounts[i]
        })
    }
    return licenseCountArray;
}