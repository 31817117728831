import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { axiosInstance } from '../apis/api';
import UsersAPI from '../apis/usersApi';
import { AUTH_ACCESS_TOKEN, AUTH_REFRESH_TOKEN, AUTH_REFRESH_TOKEN_EXP } from '../utils/authLocalStorageSync';

export const LOGIN = 'auth/LOGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/LOGIN_FAIL';
const FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD';
export const LOGOUT = 'auth/LOGOUT';
export const CHANGE_SUBTENANT = 'auth/CHANGE_SUBTENANT';
const CURRENT_COMPANY_LEVEL_SYSTEM = 'SYSTEM';
const CURRENT_COMPANY_LEVEL_TOP_LEVEL = 'TOP_LEVEL';
const CURRENT_COMPANY_LEVEL_SUBTENANT = 'SUBTENANT';
export const SET_TOKENS = 'auth/SET_TOKENS';
const IS_REFRESHING = 'auth/IS_REFRESHING';
const SET_REFRESHING_CALL = 'auth/SET_REFRESHING_CALL';
const UPDATE_USER = 'auth/UPDATE_USER';

export const orgTypes = {
	CURRENT_COMPANY_ORG_TYPE_SYSTEM : 'SYSTEM',
	SERVICE_PROVIDER: 'SERVICE_PROVIDER',
	RESELLER: 'RESELLER',
	CUSTOMER: 'CUSTOMER'
};

// Helper functions
// TODO: Eventually deprecate this logic for org type function
function resolveCurrentCompanyLevel(company) {
	let level = '';
	if(company !== null && company && company.id) {
		if(company.parentId) {
			level = CURRENT_COMPANY_LEVEL_SUBTENANT
		}
		else if(company.name === 'System') {
			level = CURRENT_COMPANY_LEVEL_SYSTEM
		}
		else {
			level = CURRENT_COMPANY_LEVEL_TOP_LEVEL
		}
	} else {
		level = CURRENT_COMPANY_LEVEL_SYSTEM
	}
	return level;
}

function resolveCurrentCompanyOrgType(company) {
	return company !== null && company && company.orgType ? company.orgType : orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM;
}

const initialState = fromJS({
	tokens: {
		accessToken: null,
		refreshToken: null,
		accessTokenExp: null,
		refreshTokenExp: null
	},
	user: null,
	currentCompanyLevel: null,
	rsOrg: ''
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
	switch (action.type) {
		case IS_REFRESHING: 
			return state.set('isRefreshing', true)
		case SET_TOKENS:
			return	state.set('isRefreshing', false)
		case SET_REFRESHING_CALL:
			return state.set('refreshingCall', action.refreshingCall)
		case LOGIN:
			return state.set('loggingIn', true)
						.set('loginErrorMessage', null);
		case LOGIN_SUCCESS:
			const {userProfileTO, claims} = action.result.data;
		 	let currentLevel = resolveCurrentCompanyLevel(userProfileTO.company);
			let orgType = resolveCurrentCompanyOrgType(userProfileTO.company);
			if(currentLevel === CURRENT_COMPANY_LEVEL_SYSTEM) {
				userProfileTO.company = {
					subtenantEnabled: true
				};
			}
			return state.set('loggingIn', false)
						.set('user', fromJS(userProfileTO))
						.set('currentCompanyLevel', currentLevel)
						.set('loginErrorMessage', null)
						.set('rsOrg', claims.rsOrg)
						.set('currentOrgType', orgType)
						.set('originalOrgType', orgType)
						.set('originalLoginCompany', fromJS(userProfileTO.company));
		case UPDATE_USER:
			let currentCompanyLevel = resolveCurrentCompanyLevel(action.data.company);
			if(currentCompanyLevel === CURRENT_COMPANY_LEVEL_SYSTEM) {
				action.data.company = {
					subtenantEnabled: true
				};
			}
			
			return state.set('user', fromJS(action.data))
		case LOGIN_FAIL:
			const {title} = action.errors.response.data;
			return state.set('loggingIn', false)
						.set('loginErrorMessage', title ? title : 'The server is having issues. Please try again later.')
						.set('user', null)
		case LOGOUT:
			return state.set('tokens', 
							fromJS({ 
								accessToken: null, 
								refreshToken: null, 
								accessTokenExp: null, 
								refreshTokenExp: null 
							}))
						.set('user', null)
						.set('currentCompanyLevel', null)
						.set("loginErrorMessage", action.errorMessage ? action.errorMessage : null)
						.set('isRefreshing', false)
						.set('originalLoginCompany', null);
		case FORGOT_PASSWORD:
			return state.set('loginErrorMessage', null);
		case CHANGE_SUBTENANT:
			currentLevel = resolveCurrentCompanyLevel(action.data);
			orgType = resolveCurrentCompanyOrgType(action.data);
			if(currentLevel === CURRENT_COMPANY_LEVEL_SYSTEM) {
				action.data.subtenantEnabled = true;
			}
			return state.setIn(['user', 'company'], fromJS(action.data))
				.set('currentCompanyLevel', currentLevel)
				.set('currentOrgType', orgType);
		default:
			return state;
	}
}

export function isRefreshing() {
	return {
		type: IS_REFRESHING
	}
}

export function setRefreshingCall(refreshingCall) {
	return {
		type: SET_REFRESHING_CALL,
		refreshingCall
	}
}

export function setTokens(data) {
	return {
		type: SET_TOKENS,
		data
	}
}

export function login(credentials) {
	return {
		type: LOGIN,
		types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
		promise: (client) => client.post('auth-service/login', credentials)
	};
}

export function ssoExchangeLogin(exToken) {
	return {
		type: LOGIN,
		types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
		promise: async (client) => {
			try {
				const exTokenResponse = client.post('auth-service/token/exchange', JSON.stringify(exToken), {
					headers: { 'Content-Type': 'application/json' }
				});
				const exTokenResults = await exTokenResponse;

				sessionStorage.setItem(AUTH_ACCESS_TOKEN, exTokenResults.data.accessToken);
				sessionStorage.setItem(AUTH_REFRESH_TOKEN, exTokenResults.data.refreshTokenInfo.id);
				sessionStorage.setItem(AUTH_REFRESH_TOKEN_EXP, exTokenResults.data.refreshTokenInfo.exp);
	
				const userResults = await UsersAPI.getUserById(exTokenResults.data.claims.sub);
	
				exTokenResults.data.userProfileTO = userResults.data;

				return exTokenResults;
			} catch (error) {
				console.log(error);
			}
		}
	};
}

export function updateUser(data) {
	return {
		type: UPDATE_USER,
		data
	}
}

export function logout(errorMessage) {
	return {
		type: LOGOUT,
		errorMessage
	}
}
export function forgotPassword() {
	return {
		type: FORGOT_PASSWORD
	}
}

export function changeSubtenant(company) {
	return {
		type: CHANGE_SUBTENANT,
		data: company
	}
}

// Selectors
const selectAuth = state => state.get('auth', initialState);

export const selectUser = () =>
	createSelector(selectAuth, selectUser => selectUser.get('user'));

export const selectUserId = () =>
	createSelector(userSelector, userState => userState.get('id'));

export const selectUserRole = () =>
	createSelector(selectAuth, userState => userState.getIn(['user', 'role']));

export const selectUserCompany = () =>
	createSelector(selectAuth, userState => userState.getIn(['user', 'company']));

export const selectDataSyncEnabled = () =>
	createSelector(selectAuth, userState => userState.getIn(['user', 'company', 'managerDataSync']));

export const userSelector = 
	createSelector(selectAuth, selectUser => selectUser.get('user'));

export const userCompanyId = () =>
	createSelector(userSelector, userState => userState.getIn(['company', 'id']));
	
export const selectUsageBasedLicensing = () =>
	createSelector(userSelector, userState => userState.getIn(['company', 'usageBasedLicensing']));

export const selectAccessToken = () =>
	createSelector(selectAuth, selectAccessToken => selectAccessToken.getIn(['tokens', 'accessToken']));

export const selectRefreshTokenExp = () =>
	createSelector(selectAuth, selectAccessToken => selectAccessToken.getIn(['tokens', 'refreshTokenExp']));

export const selectLoginLoading = () =>
	createSelector(selectAuth, selectLoginLoading => selectLoginLoading.get('loggingIn'));

export const selectLoginErrorMessage = () =>
	createSelector(selectAuth, selectLoginErrorMessage => selectLoginErrorMessage.get('loginErrorMessage'));

export const currentCompanyLevel = () =>
	createSelector(selectAuth, currentCompanyLevel => currentCompanyLevel.get('currentCompanyLevel'));

export const currentCompanyOrgType = () =>
	createSelector(selectAuth, currentCompanyOrgType => currentCompanyOrgType.get('currentOrgType'));
	
export const originalCompanyOrgType = () =>
	createSelector(selectAuth, originalCompanyOrgType => originalCompanyOrgType.get('originalOrgType'));	

export const selectOriginalLoginCompany = () =>
	createSelector(selectAuth, originalLoginCompany => originalLoginCompany.get('originalLoginCompany'));
