import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectUserCompany } from '../../../reducers/auth';
import FileCopy  from '@material-ui/icons/FileCopy';
import Visibility  from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReactTable from 'react-table';
import ReactTableLoadingComponent from '../../../components/ReactTableLoadingComponent';
import { withStyles } from '@material-ui/core/styles';
import HeldApi from '../../../apis/heldDevicesApi';
import 'react-table/react-table.css';
import './IDsAndAccessCodesSection.css';
import { selectCerEnabled } from '../../../reducers/app';
import handleCopyToClipboard from '../../../utils/handleCopyToClipboard';

const styles = theme => ({
    close: {
        padding: theme.spacing.unit / 2,
    },
});

function IDsAndAccessCodesSection(props) {
    const [companySecretsData, setCompanySecretsData] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [viewHiddenText, setViewHiddenText] = useState(false);
    const [copyToClipboardFailed, setCopyToClipboardFailed] = useState(false);
    const [rowIdValues, setRowIdValue] = useState(null);
    
    const handleViewClick = () => {
        const table = document.querySelector('.codes-table');
        table.classList.toggle('visible');
        setViewHiddenText(!viewHiddenText);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
    };

    const buildTableColumns = [
        {
            Header: <div id="name">Name</div>,
            id: "name",
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: <div id="value">Value</div>,
            id: "value",
            minWidth: 110,
            Cell: row => (
                row.original.name === 'HELD+ Secret Key' ? 
                    <input aria-label="HELD+ Secret Key" readOnly title={row.original.value} className="hidden-key" value={row.original.value ? row.original.value : ''} type={viewHiddenText ? 'text' : 'password'}/>
                :
                    <div title={row.original.value}>{row.original.value}</div>
            )
        },
        {
            Header: "Copy/View",
            id: "copy",
            Cell: row => (
                <div style={{position: 'relative', overflow: 'hidden', display: 'flex', justifyContent: 'center'}}>
                    {row.original.name === 'HELD+ Secret Key' ? 
                        <Button id={`ids-and-access-codes-view-button-${row.index}`} aria-label="View Key" className="view-button" onClick={() => handleViewClick()}>
                            <Visibility style={{color: '#1b536a'}} />
                        </Button>
                        : ''   
                    }
                    <Button id={`ids-and-access-codes-copy-button-${row.index}`} aria-label="Copy Key" className="copy-button" onClick={() => {
                        setRowIdValue(row.original.value);
                        handleCopyToClipboard(row.original.value, setSnackbarOpen, setCopyToClipboardFailed);
                    }}>
                        <FileCopy style={{color: '#1b536a'}} />
                    </Button>
                </div>
            )
        }
    ];

    const {
        classes, cerEnabled
    } = props;

    const { sppAccessCode,
        id,
        locationTreeType } = props.company.toJS();

    useEffect(() => {
        setLoadingData(true);
        setCompanySecretsData(null);

        HeldApi.getHeldData(id).then(response => {
            setLoadingData(false);
            setCompanySecretsData(response.data);
        }).catch(error => {
            setLoadingData(false);
            console.log(error);
        });
    }, [id]);
    
    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loadingData]);

    let rowData = [];
    if (locationTreeType === 'SIMPLE') {
        rowData.push({
            name: 'Self-Provisioning Access Code',
            value: sppAccessCode
        });
    }
    if(companySecretsData) {
        rowData.push(
            {
                name: 'HELD Company ID',
                value: companySecretsData.heldToken
            },
            {
                name: 'HELD+ Secret Key',
                value: companySecretsData.heldPlusSecret
            }
        );
        if(cerEnabled) {
            rowData.push({
                name: 'CER Account ID',
                value: companySecretsData.cerAccountId
            })
        }
    }
    rowData.push(
        {
            name: 'Organization ID',
            value: id
        }
    )

    return (
        <div>
            <h2>IDs and Access Codes</h2>
            <div>
                <Snackbar
                    className={`snackbar-popup ${copyToClipboardFailed && 'error'}`}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={<div>
                        {copyToClipboardFailed ? 
                            <span id="client-snackbar">
                                The code/ID was not copied to your clipboard. Please copy manually:<br/>
                                {rowIdValues}
                            </span>
                            :
                            <span id="client-snackbar">
                                <CheckCircleIcon className={"classes.icon classes.iconVariant"} />
                                The code/ID has been copied to your clipboard
                            </span>
                        }
                    </div>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                <ReactTable
                    resizable={false}
                    sortable={false}
                    className="-striped -highlight codes-table"
                    showPaginationBottom={false}
                    data={rowData}
                    minRows={5}
                    columns={buildTableColumns}
                    defaultPageSize={5}
                    loading = {loadingData}
                    LoadingComponent={ReactTableLoadingComponent}
                    getTrProps={
                        (state, rowInfo, column) => {
                            if(rowInfo) {
                                return {
                                    id: 'codes-row-' + rowInfo.index,
                                };
                            } else {
                              return {};
                            }
                        }
                    }
                />
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    cerEnabled: selectCerEnabled(),
    company: selectUserCompany()
});

export default compose(
    connect(mapStateToProps, null),
    withStyles(styles),
) (IDsAndAccessCodesSection);