import { zip } from "lodash";
import * as Yup from "yup";

import { zipRegex } from "../../utils/zipRegex";


export function ECAValidation(addressIsAlternate, addressIsAmbiguous, multipleAddressSelect, geoCordsAreRequired, addressIsRequired, requiredInfo) {
    return Yup.object({
    routeType: Yup.string("Enter route type")
                .trim()
                .required("Route Type is a required field."),
    name: Yup.string("Enter a name")
            .trim()
            .max(255, "Name has a max limit of 255 characters."),
    agentInfo: Yup.string("Enter agent info")
            .trim()
            .max(60, "Agent info has a max limit of 60 characters."),
    callbackNumber: Yup.string("Enter a phone number")
                    .trim()
                    .required("Callback Number is a required field.")
                    .matches( /^[0-9]{10}$/, 'Callback Number must be a 10 digit number' ),
    address: (addressIsAlternate || addressIsRequired) ?
            Yup.string("Enter an address")
            .trim()
            :
            Yup.string("Enter an address")
            .trim()
            .required("Address is a required field."),
    houseNumber: (addressIsAlternate && !addressIsRequired) ?
            Yup.string("Enter a street address")
            .trim()
            .required("House Number is a required field.")
            .max(10, "House Number has a max limit of 10 characters.")
            .matches( /^[^#]+$/, 'Number sign character is not allowed')
            :
            Yup.string("Enter a street address")
            .trim(),
    houseNumberExtension:
            Yup.string("Enter a street address")
            .trim()
            .max(4, "House Number Extension has a max limit of 4 characters."),
    prefixDirection: 
            Yup.string("Enter a prefix direction")
            .trim(),
    street: (addressIsAlternate && !addressIsRequired) ?
            Yup.string("Enter a street address")
            .trim()
            .required("Street Name is a required field.")
            .max(60, "Street Name has a max limit of 60 characters.")
            :
            Yup.string("Enter a street address")
            .trim(),
    streetType: Yup.string("Enter a street type")
            .trim().max(4, "Street Type has a max limit of 4 characters."),
    postDirection: Yup.string("Enter a post direction").trim(),
    city: (addressIsAlternate && !addressIsRequired) ?
            Yup.string("Enter a city")
            .trim()
            .required("City is a required field.")
            .max(32, "City has a max limit of 32 characters.")
            :
            Yup.string("Enter a city")
            .trim(),
    stateProvince: (addressIsAlternate && !addressIsRequired) ?
            Yup.string("Select a state/province")
            .trim()
            .required("State/Province is a required field.")
            :
            Yup.string("Select a state/province")
            .trim(),
    zip: (addressIsAlternate && !addressIsRequired) ?
            Yup.string("Enter a ZIP/Postal Code")
            .trim()
            .when("country", {
                    is: "US",
                    then: Yup.string().max(5, "ZIP/Postal Code has a max limit of 5 characters.")
            })
            .when("country", {
                    is: "CA",
                    then: Yup.string().max(7, "ZIP/Postal Code has a max limit of 7 characters.")
            })
            .required('ZIP/Postal code is a required field.')
            .transform(value => value.toUpperCase())
            .when('country', (country, schema) => {
                    return schema.matches(
                            zipRegex[country],
                            `Not a valid ZIP/Postal Code.`
                    );
            })
            :
            Yup.string("Enter a ZIP/Postal Code")
            .trim(),
    country: (addressIsAlternate && !addressIsRequired) ? 
            Yup.string("Select a country")
            .trim()
            .required("Country is a required field.")
            :
            Yup.string("Select a country")
            .trim(),
    latitude: geoCordsAreRequired ?
            Yup.string("Enter a latitude")
                    .trim()
                    .matches(/^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/, 'Enter a latitude with a max precision of 7 digits') :
            Yup.string("Enter a latitude")
                    .trim()
                    .matches(/^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/, 'Enter a latitude with a max precision of 7 digits')
                    .required("Latitude is a required field."),
    longitude: geoCordsAreRequired ?
            Yup.string("Enter a longitude")
                    .trim()
                    .matches(/^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/, 'Enter a longitude with a max precision of 7 digits') :
            Yup.string("Enter a longitude")
                    .trim()
                    .matches(/^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/, 'Enter a longitude with a max precision of 7 digits')
                    .required("Longitude is a required field."),
    info: requiredInfo ?
            Yup.string("Enter location information")
                    .trim()
                    .max(60, "Location info has a max limit of 60 characters.")
                    .required("Location info is a required field.")
                    :
            Yup.string("Enter location information")
                    .trim()
                    .nullable()
                    .max(60, "Location info has a max limit of 60 characters."),
    multipleAddressSelect: addressIsAmbiguous && multipleAddressSelect === "" ?
            Yup.string()
            .required()
            :
            Yup.string()
});
}