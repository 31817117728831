import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HasAccess from '../../components/HasAccess/HasAccess';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';

import reducer from '../../reducers/sip';
import { types as AppTypes } from '../../reducers/app';
import { currentCompanyLevel } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import saga from '../../sagas/sip';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as AppActions } from '../../reducers/app';
import { actions as SipAction, types as sipTypes } from '../../reducers/sip';

import SipTable from '../../containers/SipTable/SipTable';

function SipPage(props) {
    function modalHandleOpen() {
        props.setCurrentModal(sipTypes.ADD_SIP);
    }

    useEffect(() => {
        props.setPageTitle('Call Routing');
        props.setPageTitleId('call-routing-header');
    }, []);

    return (
        <div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                    <HasAccess
                        permissions={["ADD_CALL_ROUTING"]}
                    >
                        <Button className="button" id="btnAddSip" variant="contained" color="primary" onClick={modalHandleOpen} >
                            Add SIP Peer
                        </Button>
                    </HasAccess>
                </Grid>
            </Grid>
            <br/>
            <SipTable 
                setCurrentModal={props.setCurrentModal}
            />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel()
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'sip', reducer });
const withSaga = injectSaga({ key: 'sip', saga });
const withConnect = connect(mapStateToProps,mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(SipPage);