import React, { useState, useEffect } from 'react';
import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import { safeLogout } from '../../../utils/authLocalStorageSync';

function MyAccountModal() {

    function logout() {
        safeLogout();
    }
    
    setTimeout(() => {
        logout();
    }, 8000);

    return(
        <div style={{ marginTop: 20 }}>
            Your Account has been updated. You will now be logged out and will need to log back in with these new credentials.
        </div>
    );
}


export default compose(
    ModalWrapper,
)(MyAccountModal);