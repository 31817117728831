import React, { useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions, makeSelectCERErrorResponseType } from '../../../reducers/organizationManagement';
import { connect } from 'react-redux';
import CERServerForm from './shared/CERServerForm';
import './shared/CERServer.css';

const validationSchema = Yup.object({
    ipAddress: Yup.string("Enter an IP Address")
            .trim()
            .required("IP Address is a required field.")
            .max(50, "IP Address has a max limit of 50 characters."),
    description: Yup.string("Enter a Description")
            .trim()
            .max(50, "Description has a max limit of 50 characters.")
})

const AddCERServerModal = props => {
    const { modalLoading, classes, cerErrorResponseType, cerErrorResponseTypeAction } = props;

    useEffect(() => {
        return () => {
            cerErrorResponseTypeAction('');
        }
    }, [])

	return(
        <Formik
            initialValues={{ 
                ipAddress: '',
                description: ''
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    props.submitAddCERServerForm(values);
                }
            }
			render={formikProps => <CERServerForm
                                        classes={classes} 
                                        cerErrorResponseType={cerErrorResponseType}
                                        modalLoading={modalLoading}
										{...formikProps} />}
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    cerErrorResponseType: makeSelectCERErrorResponseType()
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddCERServerForm: (data) => dispatch(actions.addCERServersRequest(data)),
        cerErrorResponseTypeAction: (data) => dispatch(actions.cerErrorResponseType(data))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper
)(AddCERServerModal);