import React, { useEffect } from 'react';
import DownloadsList from '../../containers/DownloadsList/downloadsList';
import { actions as AppActions } from '../../reducers/app';
import { connect } from 'react-redux';

function DownloadsPage(props) {
    useEffect(() => {
        props.setPageTitle('Client Installer Downloads');
        props.setPageTitleId('client-installer-downloads-header');
    }, []);

    return (
        <div>
            <DownloadsList />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return { 
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

export default connect(null, mapDispatchToProps)(DownloadsPage);