import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from "@material-ui/core/styles";

import { Formik } from 'formik';
import { compose } from 'redux';
import * as Yup from "yup";

import ModalWrapper from '../../../components/ModalWrapper';
import { connect } from 'react-redux';
import { selectUserCompany, currentCompanyLevel } from '../../../reducers/auth';
import { selectCerEnabled } from '../../../reducers/app';
import { createStructuredSelector } from 'reselect';
import { actions as ScheduledReportsActions } from '../../../reducers/scheduledReports';
import CronWidget from './shared/CronWidget';
import ReportDataComponent from './shared/ReportDataComponent';
import EmailInformation from './shared/EmailInformation';
import LocationType from './shared/LocationType';
import BuildingType from './shared/BuildingType';
import './EditScheduleReport.css';
import { setInitialCronTableStructureFromExpression  } from '../../../utils/cronUtils';

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    icon: {
        position: 'absolute',
        left: '50px',
        top: '-4px'
    },
    sip: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    }
});


const EditScheduleReportForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit, 
            modalLoading,
            modalData,
            classes,
            hasSubtenants,
            setHasSubtenants,
            emailList,
            setEmailList,
            setFieldValue,
            setFieldTouched,
            cronTableDataStructure,
            setCronTableDataStructure,
            company,
            currentCompanyLevel,
            modalErrorOptions
        } = props;

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    function handleClear() {
        if (modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) {
            modalErrorOptions.backendFieldError.name = '';
        }
    }

    return (
        <form className="schedule-report-form" onSubmit={handleSubmit} noValidate="novalidate">
            {(modalLoading) && <LinearProgress/>}
            <TextField
                autoComplete="off"
                id="scheduledReportName"
                label="*Scheduled Report Name"
                name="scheduledReportName"
                type="name"
                onChange={(e) => {
                    handleChange(e);
                    handleClear();
                }}
                value={values.scheduledReportName}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.scheduledReportName && Boolean(errors.scheduledReportName)) || (errors.scheduledReportName && Boolean(errors.scheduledReportName.includes('max'))) || (modalErrorOptions && !!modalErrorOptions.backendFieldError && !!modalErrorOptions.backendFieldError.name)} />
                {(modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) && 
                    <span id="scheduledReportName-backend-helper-text" className="error-prompt">{modalErrorOptions.backendFieldError.name}</span>
                }
                <span id="scheduledReportName-helper-text" className="error-prompt">{(touched.scheduledReportName && Boolean(errors.scheduledReportName)) || (errors.scheduledReportName && Boolean(errors.scheduledReportName.includes('max'))) ? errors.scheduledReportName : ''}</span>
            <br/>
            <h2>Scheduling Information</h2>
            <CronWidget 
                cronTableDataStructure={cronTableDataStructure} 
                setCronTableDataStructure={setCronTableDataStructure}
                classes={classes}
                errors={errors} 
                touched={touched}/>
            {!!document.querySelector('.timezone-info') || !!document.querySelector('.date-range-info') || !!document.querySelector('.location-type') || !!document.querySelector('.building-type') ?
                <h2>Report Data</h2>
                : ''
            }
            <BuildingType 
                values={values}
                errors={errors}
                handleChange={handleChange}
                modalData={modalData}
                fullWidth={true}
            />
            <LocationType 
                values={values}
                errors={errors}
                handleChange={handleChange}
                modalData={modalData}
                fullWidth={true}
            />
            <ReportDataComponent 
                touched={touched}
                values={values}
                classes={classes}
                errors={errors}
                modalData={modalData}
                handleChange={handleChange}
                hasSubtenants={hasSubtenants}
                company={company}
                currentCompanyLevel={currentCompanyLevel}
                setHasSubtenants={setHasSubtenants} />
            <h2>Email Information</h2>
            <EmailInformation
                values={values}
                touched={touched}
                classes={classes}
                errors={errors}
                modalData={modalData}
                emailList={emailList}
                setEmailList={setEmailList}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                handleChange={handleChange}
                handleBlur={handleBlur}/>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Schedule Report</Button>
            </Grid>
        </form>
    );
}

const EditScheduleReportModal = props => {
    const { modalLoading, classes, modalData, company, setModalError, currentCompanyLevel, setModalClose, setModalConfirm, editScheduledReport, modalErrorOptions } = props;
    
    const [hasSubtenants, setHasSubtenants] = useState(modalData.subtenants);
    const [emailList, setEmailList] = useState(modalData.recipientList);
    const [isLoading, setIsLoading] = useState(false);
    const [cronTableDataStructure, setCronTableDataStructure]  = useState(setInitialCronTableStructureFromExpression(props.modalData.schedule));

    const modalDataTypes = 
                modalData.reportType === 'CALL_SUMMARY' || 
                modalData.reportType === 'LOCATION_LICENSES' ||
                modalData.reportType === 'USER_LICENSES' ||
                modalData.reportType === 'ENHANCED_NOTIFICATION_LICENSES' ||
                modalData.reportType === 'BUILDING' || 
                modalData.reportType === 'LOCATION' || 
                modalData.reportType === 'BSSID' || 
                modalData.reportType === 'LLDP_CHASSIS' || 
                modalData.reportType === 'LLDP_PORT' || 
                modalData.reportType === 'MAC_ADDRESS' || 
                modalData.reportType === 'IP_RANGE' ||
                modalData.reportType === 'EXPIRING_LICENSES';

    const validationSchema = () => {
        return Yup.object().shape({
            scheduledReportName: Yup.string("Enter a scheduled report name")
                    .trim()
                    .required("Scheduled Report Name is a required field.")
                    .max(50, "Scheduled Report Name has a max limit of 50 characters."),
            dataRange: Yup.string("Choose Date Range")
                    .trim(),
            timeZoneType: 
                modalDataTypes ?
                    Yup.string("Choose Time Zone")
                        .trim()
                    :
                    Yup.string("Choose Time Zone")
                        .trim()
                        .required("Time Zone is a required field."),
            emailSubject: Yup.string("Enter an email subject")
                    .trim()
                    .required("Email Subject Line is a required field.")
                    .max(50, "Email Subject Line has a max limit of 50 characters."),
            email: 
                emailList.length <= 0 ?
                    Yup.string("Enter an email")
                        .trim()
                        .email('The email address provided is not properly formatted.')
                        .required("At least one recipient must be added to the list.")
                    :
                    Yup.string("Enter an email")
                        .trim()
                        .email('The email address provided is not properly formatted.'),
            e911AnywhereLocations: Yup.boolean(),
            cerLocations: Yup.boolean(),
            e911AnywhereBuildings: Yup.boolean(),
            e911ManagerBuildings: Yup.boolean(),
            e911ManagerLocations: Yup.boolean(),
        })
        .test(
            props.cerEnabled ? 'locationTypeCERTest' : (modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') && props.company.toJS().managerDataSync ? 'buildingTypeTest' : (modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION') && props.company.toJS().managerDataSync ? 'locationTypeTest' : '',
            null,
            (obj) => {
                if (props.cerEnabled) {
                    if (obj.e911AnywhereLocations || obj.cerLocations) {
                        return true;
                    }
                } else if (props.company.toJS().managerDataSync) {
                    if ((modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING')) {
                        if (obj.e911AnywhereBuildings || obj.e911ManagerBuildings) {
                            return true;
                        }
                    } else if ((modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION')) {
                        if (obj.e911AnywhereLocations || obj.e911ManagerLocations) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    if (obj.e911AnywhereBuildings || obj.e911AnywhereLocations) {
                        return true;
                    }
                }
        
                return new Yup.ValidationError(
                    (modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') && props.company.toJS().managerDataSync ? 'At least one building type must be selected.' : 'At least one location type must be selected.',
                    null,
                    props.cerEnabled ? 'locationTypeCER' : (modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') && props.company.toJS().managerDataSync ? 'buildingType' : (modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION') && props.company.toJS().managerDataSync ? 'locationType' : ''
                );
            }
        )
        .test(
            'email',
            null,
            (obj) => {
                if (emailList.length <= 0) {
                    return new Yup.ValidationError(
                        'At least one recipient must be added to the list.',
                        null,
                        'email'
                    )
                } else {
                    return true;
                }
            }
        );
    }

    function locationTypeCER(values) {
        if (values.e911AnywhereLocations && values.cerLocations) return '';
        if (values.e911AnywhereLocations) return 'default';
        if (values.cerLocations) return 'CER';
    }

    function buildingType(values) {
        if (values.e911AnywhereBuildings && values.e911ManagerBuildings) return '';
        if (values.e911AnywhereBuildings) return 'default';
        if (values.e911ManagerBuildings) return 'MANAGER_DATA_SYNC';
    }

    function locationType(values) {
        if (values.e911AnywhereLocations && values.e911ManagerLocations) return '';
        if (values.e911AnywhereLocations) return 'default';
        if (values.e911ManagerLocations) return 'MANAGER_DATA_SYNC';
    }

    return(
        <Formik
            initialValues={{
                scheduledReportName: modalData.name,
                dataRange: modalData.dataRange ? modalData.dataRange : '',
                timeZoneType: modalData.timezone,
                emailSubject: modalData.emailSubject,
                email: '',
                e911AnywhereLocations: modalData.locationOrigin === 'default' || modalData.locationOrigin === '' || !modalData.locationOrigin ? true : false,
                cerLocations: modalData.locationOrigin === 'CER' || modalData.locationOrigin === '' ? true : false,
                e911AnywhereBuildings: modalData.locationOrigin === 'default' || modalData.locationOrigin === '' || !modalData.locationOrigin ? true : false,
                e911ManagerBuildings: modalData.locationOrigin === 'MANAGER_DATA_SYNC' ? true : false,
                e911ManagerLocations: modalData.locationOrigin === 'MANAGER_DATA_SYNC' ? true : false
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    if (props.cerEnabled) {
                        values.locationOrigin = locationTypeCER(values);
                    } else {
                        if ((modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') && props.company.toJS().managerDataSync) {
                            values.locationOrigin = buildingType(values);
                        } else if ((modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION') && props.company.toJS().managerDataSync) {
                            values.locationOrigin = locationType(values);
                        }
                    }

                    values.cronValue = cronTableDataStructure.cronValue;
                    values.hasSubtenants = hasSubtenants;
                    values.emailList = emailList;
                    values.companyId = company.toJS().id;
                    values.reportType = modalData.reportType;
                    values.id = modalData.id;
                    values.paused = modalData.paused;

                    editScheduledReport(values, props.modalData.page, props.modalData.pageSize, props.modalData.currentSortColumn);
                }
            }
            render={
                renderProps => <EditScheduleReportForm 
                                    modalErrorOptions={modalErrorOptions}
                                    cronTableDataStructure={cronTableDataStructure}
                                    setCronTableDataStructure={setCronTableDataStructure}
                                    currentCompanyLevel={currentCompanyLevel} 
                                    company={company} 
                                    isLoading={isLoading} 
                                    setModalConfirm={setModalConfirm} 
                                    emailList={emailList} 
                                    setEmailList={setEmailList} 
                                    hasSubtenants={hasSubtenants} 
                                    setHasSubtenants={setHasSubtenants} 
                                    classes={classes} 
                                    modalLoading={modalLoading} 
                                    modalData={modalData} 
                                    {...renderProps} />
            }
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    company: selectUserCompany(),
    currentCompanyLevel: currentCompanyLevel(),
    cerEnabled: selectCerEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        editScheduledReport: (formData, page, pageSize, currentSortColumn) => dispatch(ScheduledReportsActions.editScheduledReport(formData, page, pageSize, currentSortColumn))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(EditScheduleReportModal);