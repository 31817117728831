import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalWrapper from '../../../../components/ModalWrapper';
import { actions as AlertsActions, makeSelectPreviewAlertTemplate } from '../../../../reducers/alerts';
import './PreviewAlertsTemplates.css';
import LinearProgress from '@material-ui/core/LinearProgress';


class PreviewAlertsTemplatesModal extends Component {
    createSubjectMarkup = () => {
        return {__html: this.props.previewAlertTemplate.subject};
    }
    
    createContentMarkup = () => {
        return {__html: this.props.previewAlertTemplate.content};
    }
    
    componentDidMount() {
        this.props.getPreviewAlertTemplate(this.props.modalData.id);
    }

    componentWillMount() {
        this.props.previewAlertTemplateSuccess('');
    }
    
    render() {
        const { name, subject, content } = this.props.previewAlertTemplate;
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item style={{ width: '100%' }}>
                        {name && <p><strong>Name: </strong><span id="template-preview-name">{name}</span></p>}
                        {subject && <div><strong>Subject line: </strong><div id="template-preview-subject" dangerouslySetInnerHTML={this.createSubjectMarkup()} /></div>}<br/>
                        {content && <div><strong>Body: </strong><div id="template-preview-body" className="template-content" dangerouslySetInnerHTML={this.createContentMarkup()} /></div>}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    previewAlertTemplate: makeSelectPreviewAlertTemplate(),
});

const mapDispatchToProps = dispatch => {
	return {
        getPreviewAlertTemplate: (id) => dispatch(AlertsActions.previewAlertTemplate(id)),
        previewAlertTemplateSuccess: (template) => dispatch(AlertsActions.previewAlertTemplateSuccess(template))
	}
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper
)(PreviewAlertsTemplatesModal);