import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actions as RsrcActions, makeSelectRsrcList, makeSelectRsrcListLoading } from '../../../reducers/homepage';
import { selectUserCompany } from '../../../reducers/auth';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../../components/ReactTableLoadingComponent';
import moment from 'moment';
import NumberFormatter from '../../../components/NumberFormatter/NumberFormatter';

function PhoneNumberAltId(props) {
    const { row } = props;
    if(row.original.phoneNumber) {
        return (
            <div title={row.original.phoneNumber}>{!isNaN(row.original.phoneNumber) ? <NumberFormatter number={row.original.phoneNumber} /> : row.original.phoneNumber}</div>   
        );
    }
    else if(row.original.locationAlternateId) {
        return <div title={row.original.locationAlternateId}>{row.original.locationAlternateId}</div>;
    }
    else if(row.original.deviceUserId) {
        return <div title={row.original.deviceUserId}>{row.original.deviceUserId}</div>;
    }
    return '';
}

function RsrcSection(props) {
    const buildTableColumns = [
        {
            Header: <div id="redsky-relay-center-time">Time</div>,
            id: "time",
            Cell: row => <div title={formatLastAccess(row.original.callTime)}>{formatLastAccess(row.original.callTime)}</div>
        },
        {
            Header: <div id="redsky-relay-center-phone-number">Phone Number / ID</div>,
            id: 'elin',
            Cell: row => <PhoneNumberAltId row={row}/>
        },
        {
            Header: <div id="redsky-relay-center-location">Location</div>,
            id: "location",
            Cell: row => <div title={row.original.address && `${row.original.address}, ${row.original.city}, ${row.original.state} ${row.original.zip}`}>{row.original.address && `${row.original.address}, ${row.original.city}, ${row.original.state} ${row.original.zip}`}</div>
        }
    ];

    function formatLastAccess(lastAccess) {
        return lastAccess ?  moment(lastAccess).format('MM/DD/YYYY hh:mm:ss A') : '';
    }

    useEffect(() => {
        fetchRsrcList(1, 5);
    }, [props.company.toJS().id]);

    const {
        loading, rsrc, fetchRsrcList
    } = props;
    let rsrcArray = [];

    if (rsrc) {
        rsrcArray = rsrc.toArray();
    }

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <div>
            <h2><Link id="redsky-relay-center-header" to="/call-history">RedSky Relay Center</Link></h2>
            <div>
                <ReactTable
                    resizable={false}
                    sortable={false}
                    className="-striped -highlight"
                    showPaginationBottom={false}
                    data={rsrcArray}
                    minRows={5}
                    columns={buildTableColumns}
                    defaultPageSize={5}
                    manual
                    loading = {loading}
                    onFetchData={(state, instance) => {
                        fetchRsrcList();
                    }}
                    LoadingComponent={ReactTableLoadingComponent}
                    getTrProps={
                        (state, rowInfo, column) => {
                            if(rowInfo) {
                                return {
                                    id: 'rsrc-row-' + rowInfo.index,
                                };
                            } else {
                              return {};
                            }
                        }
                    }
                />
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    rsrc: makeSelectRsrcList(),
    loading: makeSelectRsrcListLoading(),
    company: selectUserCompany()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchRsrcList: (page, pageSize) => dispatch(RsrcActions.getRsrcRequest(page, pageSize))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RsrcSection);