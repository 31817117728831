/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const EVENTS = 'EVENTS/'; 
export const types = {
    GET_EVENTS_REQUEST: EVENTS + 'GET_EVENTS_REQUEST',
    GET_EVENTS_ERROR: EVENTS + 'GET_EVENTS_ERROR',
    GET_EVENTS_SUCCESS: EVENTS + 'GET_EVENTS_SUCCESS',
    EVENTS_DESCRIPTION: EVENTS + 'EVENTS_DESCRIPTION'
};

export const initialState = fromJS({
    eventsList: List(),
    eventsLoading: false,
    eventsListPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_EVENTS_REQUEST:
            return state.set('eventsLoading', true);
        case types.GET_EVENTS_ERROR:
            return state.set('eventsLoading', false);
        case types.GET_EVENTS_SUCCESS:
            return state.set('eventsLoading', false)
                        .set('eventsList', List(action.data))
                        .set('eventsListPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    getEventsRequest: (page, pageSize, startDate, endDate, eventType, userRole, sortBy, sortDirection) => ({
        type: types.GET_EVENTS_REQUEST,
        page,
        pageSize,
        startDate,
        endDate,
        eventType, 
        userRole,
        sortBy,
        sortDirection
    }),
    getEventsError: () => ({
        type: types.GET_EVENTS_ERROR,
    }),
    getEventsSuccess: (eventsList, pageCount) => ({
        type: types.GET_EVENTS_SUCCESS,
        data: eventsList,
        pageCount
    })
};

// Selectors
const selectEvents = state => state.get('events', initialState);

export const makeSelectEventsList = () =>
    createSelector(selectEvents, eventsState => eventsState.get('eventsList'));

export const makeSelectEventsListLoading = () =>
    createSelector(selectEvents, eventsState => eventsState.get('eventsLoading'));

export const makeSelectEventsPageCount = () =>
    createSelector(selectEvents, eventsState => eventsState.get('eventsListPageCount'));
