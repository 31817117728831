import React, { useState, useEffect } from 'react';
import PropTypes, { array } from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import CloudDownload  from '@material-ui/icons/CloudDownload';
import FileCopy  from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import './downloadsListTable.css';
import moment from 'moment';

const styles = theme => ({
    close: {
        padding: theme.spacing.unit / 2,
    },
});

function DownloadsListTable(props) {
    const { loading, classes, downloads } = props;
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleClick = (row) => {
        const textField = document.createElement('textarea');
        textField.innerText = row.original.downloadLink;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setSnackbarOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
    };
    
    const buildTableColumns = [
        {
            Header: "Client",
            id: "client",
            Cell: row => <div title={row.original.clientName}>{row.original.clientName}</div>
        },
        {
            Header: "Version",
            id: "version",
            Cell: row => <div title={row.original.clientVersion}>{row.original.clientVersion}</div>
        },
        {
            Header: "Release Date",
            id: "releaseDate",
            Cell: row => <div style={{textAlign: 'center'}} title={row.original.releaseDate}>{moment(row.original.releaseDate).format("MMMM Do YYYY")}</div>
        },
        {
            Header: "OS",
            id: "os",
            Cell: row => <div title={row.original.osName}>{row.original.osName}</div>
        },
        {
            Header: "Size",
            id: "size",
            Cell: row => <div title={row.original.size}>{row.original.size}</div>
        },
        {
            Header: "Download",
            id: "download",
            Cell: row => (
                <a tabIndex={-1} style={{display: 'table', margin: '0 auto', textAlign: 'center'}} href={row.original.downloadLink}>
                    <Button aria-label="Download Link" id={`download-button-${row.index}`} className="client-installer-download-button">
                        <CloudDownload style={{color: '#1b536a'}} />
                    </Button>
                </a>
            )
        },
        {
            Header: "Copy Link",
            id: "link",
            Cell: row => (
                <div style={{position: 'relative'}}>
                    <Button aria-label="Copy Link" id={`copy-button-${row.index}`} className="client-installer-copy-button" style={{display: 'block', margin: '0 auto'}} onClick={() => handleClick(row)}>
                        <FileCopy style={{color: '#1b536a'}} />
                    </Button>
                </div>
            )
        }
    ];

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);
        
    return (
        <div>
            <Snackbar
                className="snackbar-popup"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                message={<div>
                    <span id="client-snackbar" className={classes.message}>
                        <CheckCircleIcon className={"classes.icon classes.iconVariant"} />
                        The link has been copied to your clipboard
                    </span>
                </div>}
                action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>,
                ]}
            />
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight"
                showPaginationBottom={false}
                data={downloads}
                minRows={downloads && downloads.length > 0 ? 0 : 5}
                pageSize={downloads ? downloads.length : null}
                columns={buildTableColumns}
                loading={loading}
                LoadingComponent={ReactTableLoadingComponent}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'downloads-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                }
            />
        </div>
    );

}

DownloadsListTable.propTypes = {
    downloads: array.isRequired,
    loading: PropTypes.bool.isRequired
};

export default withStyles(styles)(DownloadsListTable);


