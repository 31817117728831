import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { userCompanyId } from '../../../reducers/auth';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions, selectInformacastErrorResponse } from '../../../reducers/notificationIntegrations';
import { connect } from 'react-redux';
import InformaCastEntityForm from './shared/InformaCastEntityForm';
import './shared/InformaCastEntity.css';

const validationSchema = Yup.object({
    informaCastName: Yup.string("Enter an IP Address")
            .trim()
            .required("InformaCast 911 Alert Entity Name is a required field.")
            .max(50, "InformaCast 911 Alert Entity Name has a max limit of 50 characters."),
    informaCastTemplate: Yup.string()
            .required("InformaCast Template is a required field."),
    informaCastDistList: Yup.string()
            .required("InformaCast Distribution Lists is a required field.")
})

const AddEditInformaCastEntityModal = props => {
    const { modalLoading, modalData, classes, informacastErrorResponse, informacastErrorResponseAction, modalErrorOptions } = props;

    useEffect(() => {
        return () => {
            informacastErrorResponseAction('');
        }
    }, [])

	return(
        <Formik
            initialValues={{ 
                informaCastName: modalData ? modalData.name : '',
                informaCastTemplate: '',
                informaCastDistList: ''
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    values.name = values.informaCastName; 
                    values.distributionLists = values.informaCastDistList;
                    values.templateId = values.informaCastTemplate.id;

                    if (modalData) {
                        values.id = modalData.id;
                        values.companyId = modalData.companyId;
                        props.submitEditInformaCastEntityForm(values);
                    } else {
                        values.companyId = props.companyId;
                        props.submitAddInformaCastEntityForm(values);
                    }
                }
            }
			render={formikProps => <InformaCastEntityForm
                                        classes={classes} 
                                        informacastErrorResponse={informacastErrorResponse}
                                        modalLoading={modalLoading}
                                        modalErrorOptions={modalErrorOptions}
                                        modalData={modalData}
										{...formikProps} />}
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    informacastErrorResponse: selectInformacastErrorResponse(),
    companyId: userCompanyId()
});

const mapDispatchToProps = dispatch => {
    return {
        submitEditInformaCastEntityForm: (data) => dispatch(actions.editInformaCastEntityRequest(data)),
        submitAddInformaCastEntityForm: (data) => dispatch(actions.addInformaCastEntityRequest(data)),
        informacastErrorResponseAction: (data) => dispatch(actions.informacastErrorResponse(data))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper
)(AddEditInformaCastEntityModal);