import { call, put, takeLatest, take, all, select, takeEvery } from 'redux-saga/effects';
import ImportingAPI from '../apis/importingApi';
import { userCompanyId, selectUserId } from '../reducers/auth';
import { actions as AppActions } from '../reducers/app';
import { actions as ImportingActions,
		 types as ImportingTypes } from '../reducers/importing';
import pageErrorHandler from '../utils/pageErrorHandler';
import errorHandler from '../utils/errorHandler';
import Axios from 'axios';

import { channel } from 'redux-saga';
const downloadFileChannel = channel();

function* getImportingList(action) {
	try {
		const companyId = yield select(userCompanyId());
		let response = yield call(ImportingAPI.getImportingByCompanyId, companyId, action.page, action.pageSize, action.sortBy, action.direction);
		yield put(ImportingActions.getImportingSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(ImportingActions.getImportingError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addImport(action) {
	let direction = action.sort.desc ? 'DESC': 'ASC'
	let pageIndex = action.sort.page ? action.sort.page : 0
	let pageSize = action.sort.pageSize ? action.sort.pageSize : 25
	let sortBy = action.sort.sortBy ? action.sort.sortBy : 'created'
	try {
		const companyId = yield select(userCompanyId());
		const userId = yield select(selectUserId());
		yield put(AppActions.setModalLoading());
		let response = yield call(ImportingAPI.getS3ImportLink, companyId, action.data.importType, userId);

		if(response.status === 200) {
			Axios.put(response.data, action.data.file, {
				headers: {
					'content-type': 'binary/octet-stream',
					'x-amz-meta-companyId': companyId,
					'x-amz-meta-importType': action.data.importType,
					'x-amz-meta-userId': userId,
				},
				onUploadProgress: progressEvent => {
					let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
					downloadFileChannel.put(ImportingActions.setUploadingProgress(percentCompleted));
				}
			}).then(
				success => {
					downloadFileChannel.put(ImportingActions.addImportRequestSuccess());
					downloadFileChannel.put(AppActions.setModalClose());
					setTimeout(() => {
						downloadFileChannel.put(ImportingActions.getImportingRequest(pageIndex, pageSize, sortBy, direction));
					}, 1000);
				}, 
				error => {
					downloadFileChannel.put(AppActions.setModalError(errorHandler(error)));	
					downloadFileChannel.put(ImportingActions.addImportRequestError(error));
				}
			)
		}
	}
	catch (error) {
		yield put(AppActions.setModalError(errorHandler(error)));
		console.log(error)
	}

}

function* deleteImport(action) {
	let direction = action.data.sort.desc ? 'DESC': 'ASC'
	let pageIndex = action.data.sort.page ? action.data.sort.page : 0
	let pageSize = action.data.sort.pageSize ? action.data.sort.pageSize : 25
	let sortBy = action.data.sort.sortBy ? action.data.sort.sortBy : 'created'
	try {
	  yield put(AppActions.setModalLoading());
	  yield call(ImportingAPI.deleteImport, action.data.id);
	  yield put(AppActions.setModalClose());
	  yield put(ImportingActions.getImportingRequest(pageIndex, pageSize, sortBy, direction));
	} catch (error) {
	  console.log(error);
	  yield put(AppActions.setModalError(errorHandler(error)));
	}
  }

export function* watchDownloadFileChannel(action) {
	  yield put(action)
  }

function* rootLocationsSaga() {
	yield all([
		yield takeLatest(ImportingTypes.GET_IMPORTING_REQUEST, getImportingList),
		yield takeLatest(ImportingTypes.ADD_IMPORT_REQUEST, addImport),
		yield takeLatest(ImportingTypes.DELETE_IMPORTING, deleteImport),
		yield takeLatest(downloadFileChannel, watchDownloadFileChannel)
	]);
}

export default rootLocationsSaga;
