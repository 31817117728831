import React from 'react';
import Button from '@material-ui/core/Button';
import Assignment from '@material-ui/icons/Assignment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as AppActions } from '../../reducers/app';
import { types } from '../../reducers/heldDevices';

class HeldDevicesDetailBox extends React.Component {
  handleClick = event => {
    this.props.setCurrentModal(types.HELD_DEVICES_DETAILS, this.props.row.original);
  };
  
  render() {
    return (
        <div>
            <Button
                id={`held-devices-details-info-button-${this.props.row.index}`}
                aria-haspopup="true"
                color="primary"
                onClick={this.handleClick}
                title="Detailed Info"
            >
                <Assignment />
            </Button>
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default compose(
    connect(null, mapDispatchToProps),
)(HeldDevicesDetailBox);