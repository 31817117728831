import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from 'react-number-format';
import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/pidfloDevices';
import { connect } from 'react-redux';

const validationSchema = Yup.object({
    description: Yup.string("Enter a description")
            .trim()
            .max(255, "Description has a max limit of 255 characters."),
    phoneNumber: Yup.string("Enter a Phone Number")
            .required("Phone Number is a required field.")
})

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    icon: {
        position: 'absolute',
        right: '19px',
        bottom: '82px'
    },
    pidfloDevices: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    }
});

const AddEditPidfloDevicesForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit, 
            modalLoading,
            classes } = props;

    const handleOnChange = (values) => {
        if (values.value) {
            props.setFieldValue('phoneNumber', values.value);
        } else {
            props.setFieldValue('phoneNumber', '');
        }
        props.setFieldTouched('phoneNumber', true);
    }

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }
        
    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <NumberFormat 
                autoComplete="no"
                id="phoneNumber"
                label="*Phone Number"
                name="phoneNumber"
                type="tel"
                onValueChange={handleOnChange}
                value={values.phoneNumber}
                onBlur={handleBlur}
                disabled={modalLoading}
                className={classes.field}
                fullWidth={true}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                format="(###) ###-####" 
                customInput={TextField}
                mask="_"/>
                <span id="phoneNumber-helper-text" className="error-prompt">{touched.phoneNumber ? errors.phoneNumber : ""}</span>
            <br/>
            <br/>
            <TextField
                id="description"
                label="Description"
                name="description"
                rows={5}
                multiline={true}
                variant= "outlined"
                onChange={handleChange}
                value={values.description}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max')))}/> 
                <span id="description-helper-text" className="error-prompt">{(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max'))) ? errors.description : ''}</span>
            <br/>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

const AddEditPidfloDevicesModal = props => {
    const { modalLoading, classes, modalData} = props;

    return(
        <Formik
            initialValues={{ 
                phoneNumber: modalData ? modalData.phoneNumber : '',
                description: modalData ? modalData.description : '',
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    if (modalData) {
                        values.id = modalData.id;
                        props.submitEditPidfloDevicesForm(values);
                    } else {
                        props.submitAddPidfloDevicesForm(values);
                    }
                }
            }
            render={renderProps => <AddEditPidfloDevicesForm classes={classes} modalLoading={modalLoading} {...renderProps} />}
        />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitEditPidfloDevicesForm: (pidfloDevices) => dispatch(actions.editPidfloDevicesRequest(pidfloDevices)),
        submitAddPidfloDevicesForm: (pidfloDevices) => dispatch(actions.addPidfloDevicesRequest(pidfloDevices))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddEditPidfloDevicesModal);