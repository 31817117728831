 import { axiosInstance } from './api';
 // import axios from 'axios';
/* Cirrus Admin-Service /locations Controller */
const CALL_HISTORY_ENDPOINT = 'admin-service/callHistory';

export default class CallHistoryAPI {
    static getCallHistoryByCompanyId(companyId, page, pageSize, searchTerm, sortBy, direction) {
         return axiosInstance.get(`${CALL_HISTORY_ENDPOINT}/company/${companyId}`, {
             params: {
                page,
                pageSize,
                searchTerm,
                sortBy,
                direction
            }
         })
    }
    static getCallHistory(page, pageSize, searchTerm, sortBy, direction) {
        return axiosInstance.get(`${CALL_HISTORY_ENDPOINT}`, {
            params: {
               page,
               pageSize,
               searchTerm,
               sortBy,
               direction
           }
        })
    }
    static getCallHistoryPreSignedLink(callId, id, email, role) {
        return axiosInstance.get(`${CALL_HISTORY_ENDPOINT}/callId/${callId}/callRecordingLink`)
    }
}