/**
 * index.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code. Do not touch or edit.
 */
// Needed for redux-saga es6 generator support
import '@babel/polyfill';
// Needed for .fetch() support on IE 11
import 'whatwg-fetch';
// Import all the third party stuff
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import history from './utils/history';

// Import root app
import App from './containers/App';

// Import store configuration helper
import configureStore from './configureStore';
import { ConnectedRouter } from 'connected-react-router/immutable';

import Startup from './components/Startup/Startup';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

import  Theme from './components/Theme/Theme';
import  ConditionalStyling from './components/ConditionalStyling/ConditionalStyling';

/* Create redux store with history */
const initialState = {};
export const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');


// polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

if (window.location.href.includes('five9')) {
    sessionStorage.setItem('skin', 'five9');
} else if (window.location.href.includes('default')) {
    sessionStorage.setItem('skin', 'default');
}

ReactDOM.render(
        <Provider store={store}>
            <Startup>
                <ConnectedRouter history={history}>
                    <ErrorBoundary>
                        <ConditionalStyling>
                            <Theme>
                                <App/>
                            </Theme>
                        </ConditionalStyling>
                    </ErrorBoundary>
                </ConnectedRouter>
            </Startup>
        </Provider>,
    MOUNT_NODE
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
