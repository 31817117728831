import React from 'react';
import ReactTable from 'react-table';
import Delete from '@material-ui/icons/Delete';
import 'react-table/react-table.css';

function DeviceUserIdsListTable(props) {
    const buildTableColumns = [
        {
            Header: "Device User IDs",
            id: "device-user-ids",
            resizable: false,
            Cell: row => <div title={row.original.alternateId}>{row.original.alternateId}</div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            resizable: false,
            Cell: row => displayDeleteButton(row)
        }
    ];

    const displayDeleteButton = (row) => {
        if(row.original.alternateId)
            return <Delete id={`device-user-ids-delete-button-${row.index}`} onClick={() => props.deviceUserIdsDeleteHandler(row)} row={row} />;
    }

    const combinedList = [
        ...props.deviceUserIds
    ];
    
    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight scheduled-report-recipient-table"
            showPaginationBottom={false}
            minRows={combinedList && combinedList.length > 0 ? 0 : 3}
            data={combinedList}
            pageSize={combinedList ? combinedList.length : 1}
            columns={buildTableColumns}
            style={{margin: '40px 0px 20px'}}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'device-user-ids-row-' + rowInfo.index,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

export default (DeviceUserIdsListTable);