import React from 'react';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';

const EditAlertsSubscriptionsModal = props => {
    const { modalData } = props;

	return (
        <div style={{ padding: '30px 0px 10px', whiteSpace: 'pre-wrap' }}>
            <span>{modalData.row.description}</span>
        </div>
    );
}

export default compose(
    ModalWrapper
)(EditAlertsSubscriptionsModal);