import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { types as OrganizationTypes } from '../../reducers/organizations';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from '../../utils/history';
import handleCopyToClipboard from '../../utils/handleCopyToClipboard';
import { currentCompanyLevel, selectUserCompany, selectUserRole, currentCompanyOrgType, orgTypes, changeSubtenant, selectOriginalLoginCompany, selectUser } from '../../reducers/auth';

function OrganizationEditMenu (props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [options, setOptions] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [copyToClipboardFailed, setCopyToClipboardFailed] = useState(false);

	const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isEdit = props.checkCustomPermission('CUSTOM_EDIT_ORGANIZATIONS', props.user);
	const isDelete = props.checkCustomPermission('CUSTOM_DELETE_ORGANIZATIONS', props.user);
	const isView = user && user.permissions.includes('VIEW_LICENSING');

	const ITEM_HEIGHT = 48;
	
	const optionTypes = {
		EDIT: 'Edit',
		DELETE: 'Delete',
		ADD_SUBTENANT: 'Add Sub-Tenant',
		MANAGE_LICENSES: 'Manage Licenses',
		PASSWORD_POLICY: 'Password Policy',
		CHANGE_TO_ORGANIZATION: 'Change To Organization',
		CALL_ROUTING: 'Call Routing - SIP',
		COPY_UUID: 'Copy UUID to Clipboard'
	};

	const buildOptionsList = () => {
		const userRole = props.userRole.get('name');
		const currentOrgType = props.currentCompanyOrgType;
		const isServiceProvider = props.userLoginCompany && props.userLoginCompany.get('orgType') === orgTypes.SERVICE_PROVIDER;
		const isCustomerOrgType = props.row.original.orgType === orgTypes.CUSTOMER;
		const isUsageBasedLicensing = props.userLoginCompany.toJS().usageBasedLicensing;
	
		let optionsList = [];
	
		const commonOptions = [
			optionTypes.COPY_UUID,
			optionTypes.CHANGE_TO_ORGANIZATION
		];
	
		if (isDelete) {
			commonOptions.unshift(optionTypes.DELETE);
		}
	
		if (isEdit) {
			commonOptions.unshift(optionTypes.EDIT);
		}
	
		if (currentOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM) {
			optionsList = [
				...commonOptions,
				optionTypes.CALL_ROUTING
			];
		} else if (currentOrgType === orgTypes.SERVICE_PROVIDER || currentOrgType === orgTypes.RESELLER) {
			optionsList = commonOptions;
		}
	
		if (isCustomerOrgType && (isView || (isServiceProvider && isUsageBasedLicensing))) {
			optionsList = [
				...commonOptions,
				optionTypes.MANAGE_LICENSES
			];
		}
	
		return optionsList;
	};
	
	

	const handleClick = event => {
		let optionsList = buildOptionsList();
		setAnchorEl(event.currentTarget);
		setOptions(optionsList);
	}

	const handleClose = () => {
		setAnchorEl(null);
	}

	const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

		setSnackbarOpen(false);
    };

	const handleMenuItemClick = (option) => {
		handleClose();
		switch(option) {
			case optionTypes.EDIT:
				props.setCurrentModal(OrganizationTypes.EDIT_ORGANIZATION, props.row.original)
				break;
			case optionTypes.DELETE:
				props.setCurrentModal(OrganizationTypes.DELETE_ORGANIZATION, props.row.original)
				break;
			case optionTypes.ADD_SUBTENANT:
				props.setCurrentModal(OrganizationTypes.ADD_SUBTENANT, {
					companyId: props.row.original.id
				});
				break;
			case optionTypes.CHANGE_TO_SUBTENANT:
				props.changeSubtenant(props.row.original);
				history.push({
					pathname: '/'
				});
				break;
			case optionTypes.COPY_UUID:
				handleCopyToClipboard(props.row.original.id, setSnackbarOpen, setCopyToClipboardFailed);
				break;
			case optionTypes.CHANGE_TO_ORGANIZATION:
				props.changeSubtenant(props.row.original);
				history.push({
					pathname: '/'
				});
				break;
			case optionTypes.MANAGE_LICENSES:
				history.push({
					pathname: '/manage-licenses/' + props.row.original.id,
					search: '?licenseModel=' + props.row.original.licenseModel
				});
				break;
			case optionTypes.CALL_ROUTING:
				props.changeSubtenant(props.row.original);
				history.push({
					pathname: '/call-routing'
				});
				break;
			default:
				return null;
		}
	}
	
	const open = Boolean(anchorEl);

	return (
		<div>
			<IconButton
				id={`action-menu-button-${props.row.rowId}`}
				aria-label="More"
				aria-haspopup="true"
				onClick={handleClick} >
				<MoreVertIcon />
			</IconButton>
			<Menu
				id={`action-menu-${props.row.rowId}`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
					maxHeight: ITEM_HEIGHT * 6.5,
					minWidth: 200,
					},
				}}>
			{options.map((option, index) => (
				<MenuItem id={`item-${props.row.rowId}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} onClick={event => handleMenuItemClick(option, event)}>
				{option}
				</MenuItem>
			))}
			</Menu>
			<Snackbar
				className={`snackbar-popup ${copyToClipboardFailed && 'error'}`}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				message={<div>
					{copyToClipboardFailed ? 
						<span id="client-snackbar">
							The code/ID was not copied to your clipboard. Please copy manually:<br/>
							{props.row.original.id}
						</span>
						:
						<span id="client-snackbar">
							<CheckCircleIcon className={"classes.icon classes.iconVariant"} />
							The code/ID has been copied to your clipboard
						</span>
					}
				</div>}
				action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={handleSnackbarClose}
				>
					<CloseIcon />
				</IconButton>,
				]}
			/>
		</div>
	);
}

const mapStateToProps = () => createStructuredSelector({
	currentCompanyLevel: currentCompanyLevel(),
	userRole: selectUserRole(),
	currentCompanyOrgType: currentCompanyOrgType(),
	userCompany: selectUserCompany(),
	userLoginCompany: selectOriginalLoginCompany()
});

const mapDispatchToProps = dispatch => {
    return {
		changeSubtenant: (company) => dispatch(changeSubtenant(company)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationEditMenu);