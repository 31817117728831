import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { Tooltip } from '@material-ui/core';
import { selectUserCompany } from '../../../../reducers/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

function LocationType(props) {
    const {
        values,
        errors,
        handleChange,
        modalData,
        fullWidth,
        centered,
        company
    } = props;


    return (
        (modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION') && company.toJS().managerDataSync ?
        <div>
            <FormControl className="location-type" style={{ width: fullWidth ? '100%' : '77%', maxWidth: 480,  display: 'block', margin: centered ? '0 auto' : '0' }} component="fieldset">
                <FormLabel style={{ color: errors.locationType ? 'red' : 'initial' }} component="legend">*Location Types:</FormLabel>
                <FormGroup style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <FormControlLabel
                        htmlFor="e911AnywhereLocations"
                        control={<Checkbox id="e911AnywhereLocations" color="primary" checked={values.e911AnywhereLocations} onChange={handleChange} name="e911AnywhereLocations" style={{ color: errors.locationType && 'red' }} />}
                        label={<span style={{ color: errors.locationType && 'red' }}>E911 Anywhere® Locations</span>}
                    />
                    <FormControlLabel
                        htmlFor="e911ManagerLocations"
                        style={{ marginLeft: 20 }}
                        control={
                            <div>
                                <Checkbox id="e911ManagerLocations" color="primary" checked={values.e911ManagerLocations} onChange={handleChange} name="e911ManagerLocations" style={{ color: errors.locationType && 'red' }} />
                                {values.e911ManagerLocations &&
                                    <Tooltip 
                                        title="Attempting to import an edited Location Report output file, containing E911 Manager® Locations, will result in those records failing during import."
                                    >
                                        <NotificationImportantIcon className="bell-icon" style={{ color: '#c31925' }} />
                                    </Tooltip>
                                }
                            </div>
                        }
                        label={<span style={{ color: errors.locationType && 'red' }}>E911 Manager® Locations</span>}
                    />
                </FormGroup>
            </FormControl>
            <span id="locationType-helper-text" className="error-prompt" style={{ width: fullWidth ? '100%' : '60%', margin: '0 auto' }}>{errors.locationType}</span>
        </div>
        : ''
    );
}

const mapStateToProps = createStructuredSelector({
    company: selectUserCompany()
});


const withConnect = connect(mapStateToProps, null);

export default compose(withConnect(LocationType));