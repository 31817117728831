/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const REPORTS = 'REPORTS/'; 
export const types = {
    GENERATE_REPORT: REPORTS + 'GENERATE_REPORT',
    SCHEDULE_REPORT: REPORTS + 'SCHEDULE_REPORT'
};

export const initialState = fromJS({
    
});

export default (state = initialState, action) => {
	switch (action.type) {
        // case types.GET_ALERT_SUBSCRIPTIONS_REQUEST:
        //     return state.set('alertSubscriptionsLoading', true);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    // addAlertSubscriptionRequest: (alertSubscription) => ({ 
    //         type: types.ADD_ALERT_SUBSCRIPTION, 
    //         data: alertSubscription
    // })
};

// Selectors
// const selectAlertSubscriptions = state => state.get('reports', initialState);

// export const makeSelectAlertSubscriptionsList = () =>
//     createSelector(selectAlertSubscriptions, alertSubscriptionsState => alertSubscriptionsState.get('alertSubscriptionsList'));