import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Business from '@material-ui/icons/Business';
import Home from '@material-ui/icons/Home';
import moment from 'moment';

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2
  },
});

class LocationDetailBox extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  formatLastAccess(callTime) {
    return callTime ?  moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        {this.props.location ? 
          <div>
            <Button
              id={`detail-box-button-${this.props.row.rowId}`}
              aria-haspopup="true"
              color="primary"
              onClick={this.handleClick}
              style={{
                textAlign: 'left'
              }}
            >
            { this.props.location.personal ? 
                <div><Home style={{ marginBottom: '-5px' }}/>{this.props.location.name}</div> : 
                <div><Business style={{ marginBottom: '-5px' }}/>{this.props.location.name}</div> 
            }
            </Button>
          <Popover
            id={`detail-box-${this.props.row.rowId}`}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.typography}>
              <Typography variant="h5">Location Details</Typography>
              { this.props.location.name ? <Typography><b>Name: </b>{this.props.location.name}</Typography> : '' }
              { this.props.location.info ? <Typography><b>Info: </b>{this.props.location.info}</Typography> : '' }
              { this.props.location.address || this.props.location.normalizedGeoCoords ? <Typography><b>Address / Geodetic Coordinates: </b></Typography> : '' }
              { this.props.location.address && this.props.location.address.normalizedAddress ? <Typography>{this.props.location.address.normalizedAddress}</Typography> : '' }
              { this.props.location.normalizedGeoCoords && this.props.location.normalizedGeoCoords.latitude && this.props.location.normalizedGeoCoords.longitude ? <Typography>{this.props.location.normalizedGeoCoords.latitude}, {this.props.location.normalizedGeoCoords.longitude}</Typography> : '' }
              { !this.props.locationSetMethod ? '' :
                this.props.locationSetMethod === 'MANUALLY' ?
                  <span style={{ color: 'rgb(97, 95, 87)', fontSize: '14px' }}>Manually Set<br/></span> : 
                this.props.locationSetMethod === 'NETWORK_DISCOVERY' ? 
                  <span style={{ color: 'rgb(97, 95, 87)', fontSize: '14px' }}>Network Discovery<br/></span> : ''}
              { this.props.discoveryType ?  <span style={{ color: 'rgb(97, 95, 87)', fontSize: '14px' }}> {this.props.discoveryType === 'STICKY_IP' ? 'Sticky IP Mapping' : this.props.discoveryType === 'GATEWAY_MAC_ADDRESS' ? 'Gateway MAC Address' : this.props.discoveryType}: </span> : ''} 
              { this.props.discoveryValue ? <span style={{ color: 'rgb(97, 95, 87)', fontSize: '14px' }}>{this.props.discoveryValue}<br/></span> : ''}
              { this.props.locationSetTime ?  <span style={{ color: 'rgb(97, 95, 87)', fontSize: '14px' }}> {this.formatLastAccess(this.props.locationSetTime)}</span> : ''}
            </div>
          </Popover> </div> : '' }
      </div>
    );
  }
}

LocationDetailBox.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default withStyles(styles)(LocationDetailBox);