import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import SipAPI from '../apis/sipApi';
import CompanyAPI  from '../apis/companyApi'; 
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as SipActions,
		 types as SipTypes } from '../reducers/sip';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';

function* getSipList(action) {
	try {
		const companyId = yield select(userCompanyId());
		let response;
		if (companyId) {
			response = yield call(SipAPI.getSipById, companyId, action.page, action.pageSize, action.sortBy, action.direction);

			for (const org of response.data) {
				const companyResponse = yield call(CompanyAPI.getOrganizationById, org.callRouteInfo.organizationId);
				org.name = companyResponse.data.name;
			}
		} else {
			response = yield call(SipAPI.getSip, action.page, action.pageSize, action.sortBy, action.direction);

			for (const org of response.data) {
				if (org.callRouteInfo.organizationId) {
					const companyResponse = yield call(CompanyAPI.getOrganizationById, org.callRouteInfo.organizationId);
					org.name = companyResponse.data.name;	
				}
			}
		}
		
		yield put(SipActions.getSipSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(SipActions.getSipError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addSip(action) {
	try {
		const tableProps = yield select(selectTableProps());
		
		const companyId = yield select(userCompanyId());
		yield put(AppActions.setModalLoading());
		yield call(SipAPI.addSip, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(SipActions.getSipRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editSip(action) {
	try {
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(SipAPI.editSip, action.data);
		yield put(AppActions.setModalClose());
		yield put(SipActions.getSipRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteSip(action) {
	try {
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(SipAPI.deleteSip, action.data);
		yield put(AppActions.setModalClose());
		yield put(SipActions.getSipRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootSipSaga() {
	yield all([
		yield takeLatest(SipTypes.GET_SIP_REQUEST, getSipList),
		yield takeLatest(SipTypes.ADD_SIP, addSip),
		yield takeLatest(SipTypes.EDIT_SIP, editSip),
		yield takeLatest(SipTypes.DELETE_SIP, deleteSip)
	]);
}

export default rootSipSaga;
