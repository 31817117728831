import React from 'react';
import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';

import CallMonitoringNumbersTable from '../../../containers/CallMonitoringNumbersTable/CallMonitoringNumbersTable';

const CallMonitoringNumbers = props => {
    const { modalData } = props;

	return (
        <CallMonitoringNumbersTable numbersList={modalData.numbersArray} />
    );
}

export default compose(
    ModalWrapper
)(CallMonitoringNumbers);