import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import HeldDevicesAPI from '../apis/heldDevicesApi';
import { userCompanyId } from '../reducers/auth';
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as HeldDevicesActions,
		 types as HeldDevicesTypes } from '../reducers/heldDevices';
import pageErrorHandler from '../utils/pageErrorHandler';
import errorHandler from '../utils/errorHandler';

function* getHeldDevicesList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(HeldDevicesAPI.getHeldDevicesByCompanyId, companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction);
		yield put(HeldDevicesActions.getHeldDevicesSuccess(response.data, response.headers['x-pagination-count']));	
	} catch (error) {
		yield put(HeldDevicesActions.getHeldDevicesError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* deleteHeldDevice(action) {
	try {
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(HeldDevicesAPI.deleteHeldDevice, action.data);
		yield put(AppActions.setModalClose());
		yield put(HeldDevicesActions.getHeldDevicesRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}


function* rootLocationsSaga() {
	yield all([
		yield takeLatest(HeldDevicesTypes.GET_HELD_DEVICES_REQUEST, getHeldDevicesList),
		yield takeLatest(HeldDevicesTypes.DELETE_HELD_DEVICE, deleteHeldDevice),
	]);
}

export default rootLocationsSaga;
