import React, { useState, useEffect } from 'react';
import { currentCompanyLevel, selectUserCompany, currentCompanyOrgType, orgTypes } from '../../reducers/auth';
import { types as AppTypes, selectCerEnabled } from '../../reducers/app';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import ReportsEditMenu from '../../containers/ReportsEditMenu/ReportsEditMenu';
import './ReportsListTable.css';
import { number } from 'prop-types';

function ReportsListTable(props) {

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
    const isviewCallHistory = user && user.permissions.includes('VIEW_CALL_HISTORY');
    const isviewUsers = user && user.permissions.includes('VIEW_DEVICE_USERS');
    const isviewHeldDevices = user && user.permissions.includes('VIEW_HELD_DEVICES');
    const isviewLicensing = user && user.permissions.includes('VIEW_LICENSING');
    const isviewNetworkDiscovery = user && user.permissions.includes('VIEW_NETWORK_DISCOVERY');
    const isviewLocations = user && user.permissions.includes('VIEW_LOCATIONS');

    const buildTableColumns = [
        {
            Header: <div style={{ padding: 10 }}></div>,
            id: "name",
            Cell: row => <div title={row.original.name}><strong>{row.original.name}</strong></div>
        },
    ];
    
    const buildNestedTableColumns = [
        {
            id: "type-name",
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                 <ReportsEditMenu row={row} setCurrentModal={props.setCurrentModal} />
            )
        }
    ];
    const { loading } = props;
    const [expandedList, setExpandedList] = useState([false]);

    let reportsSectionHeaders = [
        {
            name: 'Call Reports'
        }
    ];
    if(isviewUsers) {
        reportsSectionHeaders.push(
            {name: 'Device/User Reports'},
        );
    }

    if(isviewLocations) {
        reportsSectionHeaders.push(
            {name: 'Location Reports'}
        );
    }

    if(isviewHeldDevices || isviewUsers && props.currentCompanyOrgType === orgTypes.CUSTOMER) {
        reportsSectionHeaders.push(
            {name: 'Recent Issues'}
        );
    }

    if(isviewNetworkDiscovery && props.currentCompanyOrgType === orgTypes.CUSTOMER) {
        reportsSectionHeaders.push(
            {name: 'Network Element Reports'}
        );
    }
    
    if(isviewLicensing && props.currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM) {
        reportsSectionHeaders.push({name: 'Licensing Reports'});
    }

    const callReportsData = [
        {
            name: 'Emergency Call Details',
            type: 'CALL_HISTORY'
        },
        {
            name: 'Emergency Call Totals',
            type: 'CALL_SUMMARY'
        },
        {
            name: 'ERC Call Details',
            type: 'ECRC_CALL_HISTORY'
        },
    ];

    const licensingReportsData = [
        {
            name: 'Location Licensing',
            type: 'LOCATION_LICENSES'
        },
        {
            name: 'User Licensing',
            type: 'USER_LICENSES'
        },
        {
            name: 'Enhanced Notification Licensing',
            type: 'ENHANCED_NOTIFICATION_LICENSES'
        },
        {
            name: 'Expiring Licenses',
            type: 'EXPIRING_LICENSES'
        }
    ]
    
    const deviceUserReportsData = [
        {
            name: 'Device Users',
            type: 'DEVICE_USERS'
        },
        {
            name: 'HELD Devices',
            type: 'HELD_DEVICE'
        },
        {
            name: 'PIDF-LO Devices',
            type: 'PIDF_LO'
        }
    ];

    const buildingsLocationReportsData = [
        {
            name: 'Building',
            type: 'BUILDING'
        }
    ];
    
    const locationReportsData = [
        {
            name: 'E911 Location',
            type: 'LOCATION'
        }
    ];

    const cerLocationReportsData = [
        {
            name: 'CER Location',
            type: 'CER_LOCATION'
        }
    ];

    const networkElementReportsData = [
        {
            name: 'BSSID',
            type: 'BSSID'
        },
        {
            name: 'LLDP Chassis',
            type: 'LLDP_CHASSIS'
        },
        {
            name: 'LLDP Port',
            type: 'LLDP_PORT'
        },
        {
            name: 'MAC Address',
            type: 'MAC_ADDRESS'
        },
        {
            name: 'Private IP Ranges',
            type: 'IP_RANGE'
        },
    ];

    const recentIssueReportsData = [
        {
            name: 'HELD Devices without a Location',
            type: 'HELD_DEVICES_NO_LOCATION'
        },
        {
            name: 'HELD+ Devices without a Location',
            type: 'HELD_PLUS_DEVICES_NO_LOCATION'
        }
    ];

    if(isviewUsers) {
        recentIssueReportsData.push({
            name: 'MyE911 Users without a Location',
            type: 'MYE911_USERS_NO_LOCATION'
    });
    }

    if(isviewNetworkDiscovery) {
        recentIssueReportsData.push({
            name: 'Network Elements without a Location',
            type: 'NETWORK_ELEMENTS_NO_LOCATION'
    });
    }

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    

    /**
     * 
     * This function will take the current report category row,
     * and return the reports related to the given category.
     * 
     * @param {*} Report Category row 
     * @returns reports for given category as a list
     */
    function getReportRows(row) {
        let data = [];

        switch(row.original.name) {
            case 'Call Reports':
                data = callReportsData;
                break;
            case 'Licensing Reports':
                data = licensingReportsData;
                break;
            case 'Device/User Reports':
                data.push(
                    {
                        name: 'Device Users',
                        type: 'DEVICE_USERS'
                    },
                    {
                        name: 'Device Users Devices',
                        type: 'DEVICE_USERS_DEVICES'
                    },
                    {
                        name: 'PIDF-LO Devices',
                        type: 'PIDF_LO'
                    }
                );

                if (props.currentCompanyOrgType === orgTypes.CUSTOMER) {
                    data.push(
                        {
                            name: 'HELD Devices',
                            type: 'HELD_DEVICE'
                        }
                    );
                }
                break;
            case 'Location Reports':
                data.push(...locationReportsData);
                
                if (props.cerEnabled === true){
                    data.push(...cerLocationReportsData);
                } 
                if (props.currentCompanyOrgType !== orgTypes.CUSTOMER || props.selectUserCompany.toJS().locationTreeType === 'BUILDING') {
                    data.push(...buildingsLocationReportsData);
                }
                break;
            case 'Network Element Reports':
                data = networkElementReportsData;
                break;
            case 'Recent Issues':
                data = recentIssueReportsData;
                break;
            default:
                data = null;
        }

        return data.sort((a, b)=> (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0);
    }

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight reports-table"
            showPaginationBottom={false}
            data={reportsSectionHeaders}
            minRows={0}
            columns={buildTableColumns}
            loading = {loading}
            LoadingComponent={ReactTableLoadingComponent}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'reports-row-' + rowInfo.index,
                            tabIndex: 0,
                            style: { cursor: 'pointer' },
                            onClick: e => {
                                const expandElm = document.querySelectorAll('.rt-expandable')[rowInfo.index];
                                if (expandElm) {
                                    expandElm.click();
                                }
                            },
                            onKeyDown: e => {
                                if ((e.key === ' ' || e.key === 'Enter') && document.activeElement.tagName !== 'BUTTON') {
                                    e.preventDefault();

                                    const expandElm = document.querySelectorAll('.rt-expandable')[rowInfo.index];

                                    if (expandElm) {
                                        expandElm.click();
                                    }
                                }
                            },
                            'show-arrow': 1 // 1 - true
                        };
                    } else {
                      return {};
                    }
                }
            }
            expanded={expandedList}
            onExpandedChange ={(newExpanded, index, event) => {
                setExpandedList(newExpanded)
            }}
            SubComponent= {
                row => {
                    return(
                        <ReactTable
                            style={{ marginLeft: 50 }}
                            resizable={false}
                            sortable={false}
                            className="-striped -highlight nested-reports-table"
                            showPaginationBottom={false}
                            data={getReportRows(row)}
                            columns={buildNestedTableColumns}
                            minRows={0}
                            getTrProps={
                                (state, rowInfo, column) => {
                                    if(rowInfo) {
                                        return {
                                            id: 'nested-reports-row-' + rowInfo.index,
                                        };
                                    } else {
                                      return {
                                      };
                                    }
                                }
                            }
                        />
                    )
                }
            }
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    selectUserCompany: selectUserCompany(),
    currentCompanyOrgType: currentCompanyOrgType(),
    cerEnabled: selectCerEnabled()
});

export default connect(mapStateToProps)(ReportsListTable);
