import React from 'react';
import Error from '@material-ui/icons/Error'; 
import { withStyles } from '@material-ui/core/styles';
import './ModalErrorMessage.css';

const styles = theme => ({
    errMsg: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '3px',
    }
});

const ModalErrorMessage = props => {
    const {
        classes,
        modalErrorMessage,
        modalErrorOptions
    } = props;

    if (modalErrorOptions === undefined) {
        return (
            <div id="modal-error-message" className={classes.errMsg}>
                <Error style={{ verticalAlign: 'bottom', fontSize: 20 }}/>
                    <span>{modalErrorMessage}</span>
            </div>
        );
    } else {
        return (
            <div id="modal-error-message" className={classes.errMsg}>
                <Error style={{ verticalAlign: 'bottom', fontSize: 20 }}/>
                    <span>{modalErrorOptions && modalErrorOptions.topErrorMessage}</span>
            </div>
        );
    }
}

export default withStyles(styles)(ModalErrorMessage);