import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { Tooltip } from '@material-ui/core';
import { selectUserCompany } from '../../../../reducers/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

function BuildingType(props) {
    const {
        values,
        errors,
        handleChange,
        modalData,
        fullWidth,
        centered,
        company
    } = props;

    return (
        (modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') && company.toJS().managerDataSync ?
        <div>
            <FormControl className="building-type" style={{ width: fullWidth ? '100%' : '77%', maxWidth: 490,  display: 'block', margin: centered ? '0 auto' : '0' }} component="fieldset">
                <FormLabel style={{ color: errors.buildingType ? 'red' : 'initial' }} component="legend">*Building Types:</FormLabel>
                <FormGroup style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <FormControlLabel
                        htmlFor="e911AnywhereBuildings"
                        control={<Checkbox id="e911AnywhereBuildings" color="primary" checked={values.e911AnywhereBuildings} onChange={handleChange} name="e911AnywhereBuildings" style={{ color: errors.buildingType && 'red' }} />}
                        label={<span style={{ color: errors.buildingType && 'red' }}>E911 Anywhere® Buildings</span>}
                    />
                    <FormControlLabel
                        htmlFor="e911ManagerBuildings"
                        style={{ marginLeft: 20 }}
                        control={
                            <div>
                                <Checkbox id="e911ManagerBuildings" color="primary" checked={values.e911ManagerBuildings} onChange={handleChange} name="e911ManagerBuildings" style={{ color: errors.buildingType && 'red' }} />
                                {values.e911ManagerBuildings &&
                                    <Tooltip 
                                        title="Attempting to import an edited Building Report output file, containing E911 Manager® Buildings, will result in those records failing during import."
                                    >
                                        <NotificationImportantIcon className="bell-icon" style={{ color: '#c31925' }} />
                                    </Tooltip>
                                }
                            </div>
                        }
                        label={<span style={{ color: errors.buildingType && 'red' }}>E911 Manager® Buildings</span>}
                    />
                </FormGroup>
            </FormControl>
            <span id="buildingType-helper-text" className="error-prompt" style={{ width: fullWidth ? '100%' : '60%', margin: '0 auto' }}>{errors.buildingType}</span>
        </div>
        : ''
    );
}

const mapStateToProps = createStructuredSelector({
    company: selectUserCompany()
});


const withConnect = connect(mapStateToProps, null);

export default compose(withConnect(BuildingType));