import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import moment from 'moment';
import cronstrue from 'cronstrue';
import convertReportType from '../../../utils/convertReportType';
import convertDateRange from '../../../utils/convertDateRange';
import ReportsAPI from '../../../apis/reportsApi';


const DetailsReportsModal = props => {
    function formatTime(timeValue) {
        return timeValue ?  moment(timeValue).format('MM/DD/YYYY hh:mm:ss A') : '';
    }

    function formatTimeWithTimeZone(timeValue, tz = "US/Central") {
        return timeValue ? moment.tz(timeValue, tz).format('MM/DD/YYYY hh:mm:ss A') : '';
    }
    
    const { 
        name,
        reportType,
        status,
        dataRange,
        emailSubject,
        recipientList,
        lastExecuted,
        created,
        modifiedBy,
        modified,
        paused,
        schedule,
    } = props.modalData;

        
    const [nextRunTime, setNextRunTime] = useState([]);

    useEffect(() => {
        ReportsAPI.getNextRunTime(schedule).then(response => {
            setNextRunTime(formatTimeWithTimeZone(response.data[0]));
        });
    }, []);

        return(
            <div>
                <ul className="details-list">
                    <li><b>Report Schedule Name: </b>{name}</li>
                    <li><b>Report Type: </b>{convertReportType(reportType)}</li>
                    <li><b>Scheduling Information: </b>{cronstrue.toString(schedule)}</li>
                    <li><b>Status: </b>{paused ? 'Paused' :
                                                 status==='NEW' || status ==='ACTIVE' ? 'NEW' : 
                                                 status ==='COMPLETE' ? 'Completed' :
                                                 status ==='ERROR' ? 'Error' :
                                                 status === "WAITING" || status === "QUERYING" || status==='WRITING' ? 'In Progress'
                                                 : ''
                                                 
        }        
        </li>
                    {reportType === 'CALL_HISTORY' ||
                    reportType === 'CALL_SUMMARY' ||
                    reportType === 'ECRC_CALL_HISTORY' ||
                    reportType === 'EXPIRING_LICENSES' ? 
                    <li><b>Date Range: </b>{convertDateRange(dataRange)}</li>
                    : ''
                    }
                    <li><b>Email Subject Line: </b>{emailSubject}</li>
                    <li><b>Recipients: </b>{
                        recipientList.map((item, index) => <span key={index}>{item.emailRecipient}{recipientList.length - 1 === index ? '' : ','} </span>)
                        }</li>
                    <li><b>Last Run Time: </b>{formatTime(lastExecuted)}</li>
                    <li><b>Next Run Time: </b>{nextRunTime}</li>
                    <li><b>Created On: </b>{formatTime(created)}</li>
                    <li><b>Last Updated By: </b>{modifiedBy}</li>
                    <li><b>Last Updated On: </b>{formatTime(modified)}</li>
                </ul>
            </div>
        );
}

export default compose(
    ModalWrapper,
)(DetailsReportsModal);