/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const CER_SERVERS = 'CER_SERVERS/';
export const INTEGRATION_CRED = 'INTEGRATION_CRED/';
export const MASS_NOTIFICATION_TEMPLATES = 'MASS_NOTIFICATION_TEMPLATES/'  
export const FEATURES = 'FEATURES/'; 
export const SSO = 'SSO/';
export const types = {
    //CER
    ADD_CER_SERVERS: CER_SERVERS + 'ADD_CER_SERVERS',
    GET_CER_SERVERS_REQUEST: CER_SERVERS + 'GET_CER_SERVERS_REQUEST',
    GET_CER_SERVERS_SUCCESS: CER_SERVERS + 'GET_CER_SERVERS_SUCCESS',
    EDIT_CER_SERVERS: CER_SERVERS + 'EDIT_CER_SERVERS',
    DELETE_CER_SERVERS: CER_SERVERS + 'DELETE_CER_SERVERS',
    CER_SERVERS_NUMBERS: CER_SERVERS + 'CER_SERVERS_NUMBERS',
    GET_CER_SERVERS_ERROR: CER_SERVERS + 'GET_CER_SERVERS_ERROR',
    CER_ERROR_RESPONSE_TYPE: CER_SERVERS + 'CER_ERROR_RESPONSE_TYPE',
    //ORG FEATURES
    GET_ORGANIZATION_FEATURES: FEATURES + 'GET_ORGANIZATION_FEATURES',
    //INTERGATION CREDENTIALS
    GET_INTEGRATION_CRED_REQUEST: INTEGRATION_CRED + 'GET_INTEGRATION_CRED_REQUEST',
    GET_INTEGRATION_CRED_SUCCESS: INTEGRATION_CRED + 'GET_INTEGRATION_CRED_SUCCESS',
    GET_INTEGRATION_CRED_LIST_REQUEST: INTEGRATION_CRED + 'GET_INTEGRATION_CRED_LIST_REQUEST',
    GET_INTEGRATION_CRED_LIST_SUCCESS: INTEGRATION_CRED + 'GET_INTEGRATION_CRED_LIST_SUCCESS',
    ADD_INTEGRATION_CRED: INTEGRATION_CRED + 'ADD_INTEGRATION_CRED',
    DELETE_INTEGRATION_CRED: INTEGRATION_CRED + 'DELETE_INTEGRATION_CRED',
    GET_INTEGRATION_CRED_ERROR: INTEGRATION_CRED + 'GET_INTEGRATION_CRED_ERROR',
    INTEGRATION_CRED_RESPONSE_TYPE: INTEGRATION_CRED + 'INTEGRATION_CRED_RESPONSE_TYPE',
    //MASS NOTIFIATION TEMPLATES
    GET_MASS_NOTIFICATION_TEMPLATES_REQUEST: MASS_NOTIFICATION_TEMPLATES + 'GET_MASS_NOTIFICATION_TEMPLATES_REQUEST',
    GET_MASS_NOTIFICATION_TEMPLATES_SUCCESS: MASS_NOTIFICATION_TEMPLATES + 'GET_MASS_NOTIFICATION_TEMPLATES_SUCCESS',
    GET_MASS_NOTIFICATION_TEMPLATES_ERROR: MASS_NOTIFICATION_TEMPLATES + 'GET_MASS_NOTIFICATION_TEMPLATES_ERROR',
    MASS_NOTIFICATION_TEMPLATES_RESPONSE_TYPE: MASS_NOTIFICATION_TEMPLATES + 'MASS_NOTIFICATION_TEMPLATES_RESPONSE_TYPE',
    //SINGLE SIGN ON TEMPLATES
    GET_SINGLE_SIGNON_CRED: SSO + 'GET_SINGLE_SIGNON_CRED',
    GET_SINGLE_SIGNON_SUCCESS: SSO + 'GET_SINGLE_SIGNON_SUCCESS',
    GET_SINGLE_SIGNON_ERROR: SSO + 'GET_SINGLE_SIGNON_ERROR',
    DELETE_SINGLE_SIGNON_CRED: SSO + 'DELETE_SINGLE_SIGNON_CRED'
};

export const initialState = fromJS({
    //CER
    cerServersList: List(),
    cerServersLoading: false,
    cerErrorResponseType: '',
    //INTERGATION CREDENTIALS
    integrationCredential: {},
    integrationCredentialsList: List(),
    integrationCredLoading: false,
    integrationErrorResponseType: '',
    //MASS NOTIFICATION
    massNotificationTemplatesList: List(),
    massNotificationLoading: false,
    massNotificationErrorResponseType: '',
    //SINGLE SIGN ON CREDENTIALS
    SsoMetaData: {},
    SsoLoading: false,
    SsoErrorResponseType: ''
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
	switch (action.type) {
        //CER
        case types.GET_CER_SERVERS_REQUEST:
            return state.set('cerServersLoading', true);
        case types.GET_CER_SERVERS_ERROR:
            return state.set('cerServersLoading', false);
        case types.GET_CER_SERVERS_SUCCESS:
            return state.set('cerServersLoading', false)
                        .set('cerServersList', List(action.data));
        case types.CER_ERROR_RESPONSE_TYPE:
            return state.set('cerErrorResponseType', action.data);
        //INTERGATION CREDENTIALS
        case types.GET_INTEGRATION_CRED_REQUEST:
            return state.set('integrationCredLoading', true);
        case types.GET_INTEGRATION_CRED_LIST_REQUEST:
            return state.set('integrationCredLoading', true);    
        case types.GET_INTEGRATION_CRED_ERROR:
            return state.set('integrationCredLoading', false);
        case types.GET_INTEGRATION_CRED_SUCCESS:
            return state.set('integrationCredLoading', false)
                        .set('integrationCredential', (action.data));
        case types.GET_INTEGRATION_CRED_LIST_SUCCESS:
            return state.set('integrationCredLoading', false)
                        .set('integrationCredentialsList', List(action.data));                
        case types.INTEGRATION_CRED_RESPONSE_TYPE:
            return state.set('integrationErrorResponseType', action.data);
        //MASS NOTIFIATION TEMPLATES
        case types.GET_MASS_NOTIFICATION_TEMPLATES_REQUEST:
            return state.set('massNotificationLoading', true);
        case types.GET_MASS_NOTIFICATION_TEMPLATES_ERROR:
            return state.set('massNotificationLoading', false);
        case types.GET_MASS_NOTIFICATION_TEMPLATES_SUCCESS:
            return state.set('massNotificationLoading', false)
                        .set('massNotificationTemplatesList', List(action.data));
        case types.MASS_NOTIFICATION_TEMPLATES_RESPONSE_TYPE:
            return state.set('massNotificationErrorResponseType', action.data);
        //SINGLE SIGN ON CREDENTIALS
        case types.GET_SINGLE_SIGNON_CRED:
            return state.set('SsoLoading', true);
        case types.GET_SINGLE_SIGNON_SUCCESS:
            return state.set('SsoLoading', false)
                        .set('SsoMetaData', action.data);
        case types.GET_SINGLE_SIGNON_ERROR:
            return state.set('SsoLoading', false);
        default:
            return state;    
}
}

// Action Creators
export const actions = {
    //CER
    addCERServersRequest: (cerServers) => ({ 
        type: types.ADD_CER_SERVERS, 
        data: cerServers
    }),
    getCERServersRequest: () => ({
        type: types.GET_CER_SERVERS_REQUEST
    }),
    getCERServersSuccess: (cerServersList) => ({
        type: types.GET_CER_SERVERS_SUCCESS,
        data: cerServersList
    }),
    editCERServersRequest: (cerServers) => ({
        type: types.EDIT_CER_SERVERS,
        data: cerServers
    }),
    deleteCERServersRequest: (id) => ({
        type: types.DELETE_CER_SERVERS,
        data: id
    }),
    getCERServersError: () => ({
        type: types.GET_CER_SERVERS_ERROR
    }),
    cerErrorResponseType: (type) => ({
        type: types.CER_ERROR_RESPONSE_TYPE,
        data: type
    }),
    //ORG FEATURES
    getOrganizationFeatures: () => ({
        type: types.GET_ORGANIZATION_FEATURES
    }),
    //INTEGRATION CREDENTIALS
    getIntegrationCredRequest: (companyId, name) => ({
        type: types.GET_INTEGRATION_CRED_REQUEST,
        companyId: companyId,
        name: name
    }),
    getIntegrationCredListRequest: (companyId) => ({
        type: types.GET_INTEGRATION_CRED_LIST_REQUEST,
        companyId: companyId
    }),
    getIntegrationCredSuccess: (integrationCredential) => ({
        type: types.GET_INTEGRATION_CRED_SUCCESS,
        data: integrationCredential
    }),
    getIntegrationCredListSuccess: (integrationCredentialsList) => ({
        type: types.GET_INTEGRATION_CRED_LIST_SUCCESS,
        data: integrationCredentialsList
    }),
    addIntegrationCred: (integrationCredentials, credType, companyId) => ({
        type: types.ADD_INTEGRATION_CRED,
        data: integrationCredentials,
        credType: credType,
        companyId: companyId
    }),
    deleteIntegrationCred: (id) => ({
        type: types.DELETE_INTEGRATION_CRED,
        data: id
    }),
    getIntegrationCredError: () => ({
        type: types.GET_INTEGRATION_CRED_ERROR
    }),
    integrationCredErrorResponseType: (type) => ({
        type: types.INTEGRATION_CRED_RESPONSE_TYPE,
        data: type
    }),
    //MASS NOTIFIATION
    getMassNotificationTemplatesRequest: (id) => ({
        type: types.GET_MASS_NOTIFICATION_TEMPLATES_REQUEST,
        data: id
    }),
    getMassNotificationTemplateSuccess: (massNotificationTemplatesList) => ({
        type: types.GET_MASS_NOTIFICATION_TEMPLATES_SUCCESS,
        data: massNotificationTemplatesList
    }),
    getMassNotificationTemplateError: () => ({
        type: types.GET_MASS_NOTIFICATION_TEMPLATES_ERROR
    }),
    massNotificationTemplateErrorResponseType: (type) => ({
        type: types.MASS_NOTIFICATION_TEMPLATES_RESPONSE_TYPE,
        data: type
    }),
    //SINGLE SIGN ON CREDENTIALS
    getSsoCredentials: (companyId) => ({
        type: types.GET_SINGLE_SIGNON_CRED,
        data: companyId
    }),
    getSsoCredentialsSuccess: (SsoMetaData) => ({
        type: types.GET_SINGLE_SIGNON_SUCCESS,
        data: SsoMetaData
    }),
    getSsoCredentialsError: () => ({
        type: types.GET_SINGLE_SIGNON_ERROR
    }),
    deleteSsoCredentials: (companyId) => ({
        type: types.DELETE_SINGLE_SIGNON_CRED,
        data: companyId
    }),
};

// Selectors
const selectCERServers = state => state.get('organizationManagement', initialState);
const selectIntegrationCred = state => state.get('organizationManagement', initialState);
const selectMassNotification = state => state.get('organizationManagement', initialState);
const selectSingleSignOn = state => state.get('organizationManagement', initialState)
//CER
export const makeSelectCERServersList = () =>
    createSelector(selectCERServers, cerServersState => cerServersState.get('cerServersList'));

export const makeSelectCERServersListLoading = () =>
    createSelector(selectCERServers, cerServersState => cerServersState.get('cerServersLoading'));

export const makeSelectCERErrorResponseType = () =>
    createSelector(selectCERServers, cerServersState => cerServersState.get('cerErrorResponseType'));
//INTERGRATION CREDENTIALS
export const makeSelectIntegrationCred = () =>
    createSelector(selectIntegrationCred, integrationCredState => integrationCredState.get('integrationCredential'));

export const makeSelectIntegrationCredList = () =>
    createSelector(selectIntegrationCred, integrationCredState => integrationCredState.get('integrationCredentialsList'));

export const makeSelectIntegrationCredListLoading = () =>
    createSelector(selectIntegrationCred, integrationCredState => integrationCredState.get('integrationCredLoading'));

export const makeSelectIntegrationCredErrorResponseType = () =>
    createSelector(selectIntegrationCred, integrationCredState => integrationCredState.get('integrationCredErrorResponseType'));
//MASS NOTIFIATION
export const makeSelectMassNotificationList = () =>
    createSelector(selectMassNotification, massNotificationState => massNotificationState.get('massNotificationTemplatesList'));

export const makeSelectMassNotificationListLoading = () =>
    createSelector(selectMassNotification, massNotificationState => massNotificationState.get('massNotificationLoading'));

export const makeSelectMassNotificationErrorResponseType = () =>
    createSelector(selectMassNotification, massNotificationState => massNotificationState.get('massNotificationErrorResponseType'));
//SINGLE SIGN ON CREDENTIALS
export const makeSelectCompanySsoSettings = () =>
    createSelector(selectSingleSignOn, SsoState => SsoState.get('SsoMetaData'));

export const makeSelectSsoLoading = () =>
    createSelector(selectSingleSignOn, SsoState => SsoState.get('SsoLoading'));

export const makeSelectSsoErrorResponseType = () =>
    createSelector(selectSingleSignOn, SsoState => SsoState.get('SsoErrorResponseType'));