import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';

import ModalWrapper from '../../../../components/ModalWrapper';
import { actions } from '../../../../reducers/alerts';
import { connect } from 'react-redux';

const validationSchema = Yup.object({
    name: Yup.string("Enter a name")
            .trim()
            .max(100, "Name has a max limit of 100 characters.")
            .required("Name is a required field.")
})

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    paper: {
        position: 'absolute',
        zIndex: 999,
        marginTop: theme.spacing.unit,
    }
});

const CopyAlertsTemplatesForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        classes } = props;

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <TextField
                id="name"
                label="*New Name"
                name="name"
                onChange={handleChange}
                value={values.name}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/>
                <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

const CopyAlertsTemplatesModal = props => {
    const { modalLoading, classes, setModalClose } = props;
	return(
        <Formik
            initialValues={{ 
                name: ''
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    values.templateId = props.modalData.id;
                    props.submitCopyAlertsTemplatesForm(values);
                }
            }
			render={formikProps => <CopyAlertsTemplatesForm 
										classes={classes} 
										modalLoading={modalLoading}
										{...formikProps} />}
        />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitCopyAlertsTemplatesForm: (template) => dispatch(actions.copyAlertTemplate(template))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(CopyAlertsTemplatesModal);