import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';

import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSagas';

import reducer from '../../reducers/addressManagement';
import { types as AppTypes } from '../../reducers/app';
import { currentCompanyLevel } from '../../reducers/auth';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';
import saga from '../../sagas/addressManagement';

import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { actions as AddressManagementAction, types as addressManagementTypes } from '../../reducers/addressManagement';

import AddressManagementTable from '../AddressManagementTable/AddressManagementTable';

const initialSortColumns = [
    {
        id: 'revalidationTime',
        desc:false
    }
];

function AddressManagementPage(props) {
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const isMatch = searchFieldRegex(inputValue);
        
        if (isMatch) {
          setSearchValue(inputValue);
          searchApiCall(
            inputValue, 
            props.pageSize, 
            currentSortColumn.id, 
            currentSortColumn.desc ? 'DESC': 'ASC'
          );
        }
    }
    
    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, sortDirection) => {
          if (inputValue.length > 0) {
            setPage(0);
            props.fetchAddressManagement(1, pageSize, inputValue, sortBy, sortDirection);
          } else if (inputValue.length === 0) {
            setPage(0);
            props.fetchAddressManagement(1, pageSize, "", sortBy, sortDirection);
          }
        }, 500)
    , []);

    useEffect(() => {
        props.setPageTitle('Address Management');
        props.setPageTitleId('address-management-header');
    }, []);

    return (
        <div>
            <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item>
                    <SearchField 
                        value={searchValue}
                        onChange={handleInputSearchChange}
                    />
                </Grid>
            </Grid>
            <br/>
            <AddressManagementTable 
                setCurrentModal={props.setCurrentModal} 
                fetchAddressManagement={props.fetchAddressManagement} 
                page={page}
                setPage={setPage}
                pageSize={props.pageSize}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                currentSortColumn={currentSortColumn}
                setCurrentSortColumn={setCurrentSortColumn}
                searchValue={searchValue}
            />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchAddressManagement: (page, pageSize, searchTerm, sortBy, sortDirection) => dispatch(AddressManagementAction.getAddressManagementRequest(page, pageSize, searchTerm, sortBy, sortDirection)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'addressManagement', reducer });
const withSaga = injectSaga({ key: 'addressManagement', saga });
const withConnect = connect(mapStateToProps,mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(AddressManagementPage);