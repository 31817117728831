import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/deviceUsers';
import { actions as AppActions } from '../../../reducers/app';
import { connect } from 'react-redux';

class DeleteDeviceUserModal extends Component {
    componentDidMount() {
        this.props.setTableProps({
            sortBy: 'email'
        });
    }

    handleDelete = () => {
        this.props.submitDeleteDeviceUser(this.props.modalData.id);
    }

    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like to delete the following user:
                        </p>
                    </Grid>
                    <Grid item >
                        {(this.props.modalData.email) && <div id="deviceUserEmail">Email: <b>{this.props.modalData.email}</b></div>}
                        {(this.props.modalData.firstName || this.props.modalData.lastName) && <div id="deviceUserName">Name: <b>{this.props.modalData.firstName} {this.props.modalData.lastName}</b></div>}
                        {(this.props.modalData.heldUserId) && <div id="deviceUserName">HELD+ User ID: <b>{this.props.modalData.heldUserId}</b></div>}
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteDeviceUser: (deviceUserId) => dispatch(actions.deleteDeviceUserRequest(deviceUserId)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteDeviceUserModal);