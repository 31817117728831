import { axiosInstance } from './api';
/* Cirrus Admin-Service /ApiKeyController Controller */
const API_KEY_COMPANY_ENDPOINT = 'admin-service/company';
const API_KEY_ENDPOINT = 'admin-service/api-keys';
const API_KEY_SYSTEM_ENDPOINT = API_KEY_ENDPOINT + '/system';

export default class ApiAccessApi {

    static addApiKey(apiKey) {
        const payload = {
           email: apiKey.email,
           description: apiKey.description,
           role: apiKey.roleName,
        };

        if (apiKey.companyId) {
            payload.companyId = apiKey.companyId;
            return axiosInstance.post(API_KEY_ENDPOINT, payload);
        } else if (apiKey.roleName === "REDSKY_ADMINISTRATOR") {
            return axiosInstance.post(API_KEY_SYSTEM_ENDPOINT, payload)
        }
    }

    static editApiKey(apiKey) {
        const payload = {
            id: apiKey.id,
            description: apiKey.description,
            role: apiKey.roleName,
        };
        return axiosInstance.put(API_KEY_ENDPOINT, payload);
    }

    static deleteApiKey(apiKeyId) {
        return axiosInstance.delete(`${API_KEY_ENDPOINT}/${apiKeyId}`);
    }

    static getApiKeys(companyId, page, pageSize, searchTerm, sortBy, direction) {
        if (companyId) {
            return axiosInstance.get(`${API_KEY_COMPANY_ENDPOINT}/${companyId}/api-keys`, {
                params: {
                    page,
                    pageSize,
                    searchTerm,
                    sortBy,
                    direction
                }
            })
        } else {
            return axiosInstance.get(API_KEY_SYSTEM_ENDPOINT, {
                params: {
                    page,
                    pageSize,
                    searchTerm,
                    sortBy,
                    direction
                }
            })
        }
    }

    static enableApiKey(apiKeyId) {
        return axiosInstance.post(`${API_KEY_ENDPOINT}/${apiKeyId}/enable`);
    }

    static disableApiKey(apiKeyId) {
        return axiosInstance.post(`${API_KEY_ENDPOINT}/${apiKeyId}/disable`);
    }

}