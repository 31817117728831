import React from 'react';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import './Details.css';
function AlternateIdBox(props) {
    const {
        alternateIdList
    } = props.modalData.row;
    
    return(
        <div>
            <ul className="details-list">
                { 
                    alternateIdList.map((altIdObj, index) => {
                        return <li key={index}>{altIdObj.alternateId}</li>
                    })
                }
            </ul>
        </div>
    );
}

export default compose(
    ModalWrapper
)(AlternateIdBox);