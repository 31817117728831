import * as Yup from "yup";
import { types as NetworkDiscoveryTypes } from '../../../../reducers/networkDiscovery';
import { isEmpty } from 'lodash';

export const styles = theme => ({
    btn: {
        marginTop: '10px'
    }
});

export const validationSchema = (props, isPublicIpRange, isLocations, formValues) => {
    return (
        Yup.object().shape({
            description: Yup.string("Enter a description")
                    .trim()
                    .max(255, "Description has a max limit of 255 characters."),
            macAddress: (NetworkDiscoveryTypes.ADD_MAC_ADDRESS === props.currentModal) || (NetworkDiscoveryTypes.EDIT_MAC_ADDRESS === props.currentModal) ?
                Yup.string("Enter a MAC address")
                    .trim()
                    .required("MAC address is a required field.") : '',
            bssid: (NetworkDiscoveryTypes.ADD_BSSID === props.currentModal) || (NetworkDiscoveryTypes.EDIT_BSSID === props.currentModal) ?
                Yup.string("Enter a BSSID")
                    .trim()
                    .required("BSSID is a required field.") : '',
            ipAddressLow: (NetworkDiscoveryTypes.ADD_IP_RANGE === props.currentModal) || (NetworkDiscoveryTypes.EDIT_IP_RANGE === props.currentModal) ?
                Yup.string("Enter Range Start")
                    .trim()
                    .required("Range Start is a required field.") : '',
            ipAddressHigh: (NetworkDiscoveryTypes.ADD_IP_RANGE === props.currentModal) || (NetworkDiscoveryTypes.EDIT_IP_RANGE === props.currentModal) ?
                Yup.string("Enter Range End")
                    .trim()
                    .required("Range End is a required field.") : '',
            chassisId: (NetworkDiscoveryTypes.ADD_LLDP === props.currentModal) || (NetworkDiscoveryTypes.EDIT_LLDP === props.currentModal) ?
                Yup.string("Enter a Chassis ID")
                    .trim()
                    .required("Chassis ID is a required field.") : '',
            portId: (NetworkDiscoveryTypes.ADD_PORT === props.currentModal) || (NetworkDiscoveryTypes.EDIT_PORT === props.currentModal) ?
                Yup.string("Enter a Port ID")
                    .trim()
                    .required("Port ID is a required field.") : '',
            building: isPublicIpRange || isLocations ? '' :
                Yup.object().shape({
                 value: Yup.string("Enter a building")
                 .trim()
                 .required("Building is a required field.")
                 .nullable(),
                }),
            location: isPublicIpRange || (!isLocations && isEmpty(formValues && formValues.building)) ? '' :
                Yup.object().shape({
                    value: Yup.string("Enter a location")
                    .trim()
                    .required("Location is a required field.")
                    .nullable(),
                }),
            locationType: (props.cerEnabled && !isPublicIpRange) ?
                Yup.string("Enter a location")
                    .trim()
                    .required("Location Type is a required field.") : ''
        })
    );
}