import React, { useEffect, useState } from 'react';
import { loadResources } from '../../apis/api';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function Startup(props) {
    const [cirrusInfoLoaded, setCirrusInfoLoaded] = useState(false);

    useEffect( () => { 
        loadResources(setCirrusInfoLoaded);
    }, []);

    return cirrusInfoLoaded ? props.children : <div><LinearProgress /><p>Loading Resources...</p></div>;
}

