import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import HasAccess from '../../components/HasAccess/HasAccess';

import CallHistorySection from '../../containers/Homepage/HomepageCallHistorySection/callHistorySection';
import RsrcSection from '../../containers/Homepage/HomepageRsrcSection/rsrcSection';
import EventsSection from '../../containers/Homepage/HomepageEventsSection/eventsSection';
import ImportStatusSection from '../../containers/Homepage/HomepageImportStatusSection/importStatusSection';
import LicenseInfoSection from '../../containers/Homepage/HomepageLicenseInfoSection/licenseInfoSection';
import IssuesSection from '../../containers/Homepage/HomepageIssuesSection/issuesSection';
import CompanySummarySection from '../../containers/Homepage/HomepageCompanySummarySection/companySummarySection';
import SystemSummarySection from '../../containers/Homepage/HomepageSystemSummarySection/systemSummarySection';
import IDsAndAccessCodesSection from '../../containers/Homepage/HomepageIDsAndAccessCodesSection/IDsAndAccessCodesSection';
import { types as AppTypes, actions as AppActions } from '../../reducers/app';
import { currentCompanyLevel, selectUserCompany, currentCompanyOrgType, orgTypes } from '../../reducers/auth';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSagas';
import reducer from '../../reducers/homepage';
import saga from '../../sagas/homepage';
import './homepage.css';

const Homepage = props => {
    useEffect(() => {
        props.setPageTitle('Dashboard');
        props.setPageTitleId('dashboard-header');
        
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 300);

        props.setPageSize(25);
    }, []);

    return (
            props.currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM ? 
            <div>
                <div className="homepage-wrapper full-width">
                    <SystemSummarySection />
                </div>
                <div className="homepage-wrapper">
                    <HasAccess
                        permissions={["VIEW_CALL_HISTORY"]}
                    >
                        <CallHistorySection />
                    </HasAccess>
                    <RsrcSection />
                    <HasAccess
                        permissions={["VIEW_EVENTS"]}
                    >
                        <EventsSection />
                    </HasAccess>
                </div>
            </div>
            :
            <div>
                <div className="homepage-wrapper full-width">
                    <CompanySummarySection />
                    <IssuesSection />
                </div>
                {props.currentCompanyOrgType === orgTypes.CUSTOMER && 
                    <div>
                        <HasAccess
                            permissions={["VIEW_LICENSING"]}
                        >
                            <LicenseInfoSection />
                        </HasAccess>
                    </div>
                }
                <div className="homepage-wrapper">
                    <HasAccess
                        permissions={["VIEW_CALL_HISTORY"]}
                    >
                        <CallHistorySection />
                    </HasAccess>
                    <HasAccess
                        permissions={["VIEW_EVENTS"]}
                    >
                        <EventsSection />
                    </HasAccess>
                    <ImportStatusSection />
                    <HasAccess
                        permissions={["VIEW_SECRETS"]}
                    >
                        <IDsAndAccessCodesSection/>
                    </HasAccess>
                </div>
            </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    company: selectUserCompany(),
    currentCompanyOrgType: currentCompanyOrgType()
});

const mapDispatchToProps = dispatch => {
    return { 
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize))
    }
};

const withReducer = injectReducer({ key: 'homepage', reducer });
const withSaga = injectSaga({ key: 'homepage', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(Homepage);