import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import CallHistoryDetailBox from './callHistoryDetailBox';
import CallHistoryCallRecordingBox from './callHistoryCallRecordingBox';
import CallHistorySipInvite from './callHistorySipInvite';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    actions as CallHistoryActions,
    makeSelectCallHistoryPageCount,
    makeSelectCallHistoryList,
    makeSelectCallHistoryListLoading
} from '../../reducers/callHistory';
import { actions as AppActions, types as AppTypes, selectAlternateIdEnabled, selectLocationAlternateIdEnabled } from '../../reducers/app';
import { createStructuredSelector } from 'reselect';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';
import { currentCompanyLevel } from '../../reducers/auth';
import { TrendingUp } from '@material-ui/icons';

function AlternateDeviceUserIdRow(props) {
    const { row } = props;
    if(row && row.original.callingParty) {
        return (<div title={row.original.callingParty.callingParty}>{
            !isNaN(row.original.callingParty.callingParty) ? 
            <NumberFormatter number={row.original.callingParty.callingParty} /> : 
            row.original.callingParty}
        </div>);
    }
    else if(row && row.original.locationAlternateId) {
        return <div title={row.original.locationAlternateId}>{row.original.locationAlternateId}</div>;
    }
    return (<div title={row.original.deviceUserId}>{row.original.deviceUserId}</div>);
}

const CallHistoryListTable = props => {
    const {
        loading, 
        callHistory, 
        fetchCallHistoryList, 
        callHistoryPageCount, 
        setCurrentModal, 
        searchTerm,
        sortColumns,
        setSortColumns,
        currentSortColumn, 
        setCurrentSortColumn
    } = props;

    let buildTableColumns = [];
    props.currentCompanyLevel === AppTypes.CURRENT_COMPANY_LEVEL_SYSTEM ?
     buildTableColumns = [
        {
            Header: "Call Time",
            id: 'callTime',
            sortable: true,
            Cell: row => <div title={formatLastAccess(row.original.callTime)}>{formatLastAccess(row.original.callTime)}</div>
        },
        {
            Header: "Organization Name",
            id: 'orgName',
            Cell: row => <div title={row.original.companyName}>{row.original.companyName}</div>
        },
        {
            Header: <div id="Email">Email / HELD+ User ID</div>,
            id: 'email',
            Cell: row => row.original.email && row.original.heldUserId ? 
                <div title={row.original.email + '\n' + row.original.heldUserId}>{row.original.email}<br/><br/>{row.original.heldUserId}</div> :
                row.original.email ?
                <div title={row.original.email}>{row.original.email}</div> :
                row.original.heldUserId ?
                <div title={row.original.heldUserId}>{row.original.heldUserId}</div> : ''
    }]
    : 
    buildTableColumns = [
        {
            Header: "Call Time",
            id: 'callTime',
            sortable: true,
            Cell: row => <div title={formatLastAccess(row.original.callTime)}>{formatLastAccess(row.original.callTime)}</div>
        },
        {
            Header: <div id="Email">Email / HELD+ User ID</div>,
            id: 'email',
            Cell: row => row.original.email && row.original.heldUserId ? 
                <div title={row.original.email + '\n' + row.original.heldUserId}>{row.original.email}<br/><br/>{row.original.heldUserId}</div> :
                row.original.email ?
                <div title={row.original.email}>{row.original.email}</div> :
                row.original.heldUserId ?
                <div title={row.original.heldUserId}>{row.original.heldUserId}</div> : ''
    }];

               
    if(props.currentCompanyLevel === AppTypes.CURRENT_COMPANY_LEVEL_SYSTEM || props.alternateDeviceUserIdEnabled || props.locationAlternateIdEnabled) {
        buildTableColumns.push({
            Header: <div id="Phone-Number">Phone Number / ID</div>,
            width: 170,
            id: "phone-number",
            Cell: row => <AlternateDeviceUserIdRow row={row}/>
            
        });
    }
    else {
        buildTableColumns.push({
            Header: <div id="Phone-Number">Phone Number</div>,
            id: "phone-number",
            Cell: row => <div title={row.original.callingParty}>{!isNaN(row.original.callingParty) ? <NumberFormatter number={row.original.callingParty} /> : row.original.callingParty}</div>
        });
    }
    
    buildTableColumns.push(
        {
            Header: "Location",
            id: 'location',
            Cell: row => row.original.address ? <div title={`${row.original.address} ${row.original.locationInfo} ${row.original.city} ${row.original.state} ${row.original.zip}`}>{row.original.address} {row.original.locationInfo} {row.original.city} {row.original.state} {row.original.zip}</div>
            :
            <div title={`${row.original.latitude} ${row.original.longitude} `}>{row.original.latitude}, {row.original.longitude}</div>
        },
        {
            Header: "Reason",
            id: 'reason',
            Cell: row => <div title={row.original.reason}>{row.original.reason}</div>
        },
        {
            Header: "Details",
            id: 'details',
            Cell: row => <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CallHistoryDetailBox row={row} />
                {row.original.callRecordingLink ? 
                    <CallHistoryCallRecordingBox row={row}/>
                : ''}
                {row.original.sipInvite ? 
                    <CallHistorySipInvite row={row}/>
                : ''}
            </div>
        }
    );

    function formatLastAccess(callTime) {
        return callTime ?  moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
    }
    
    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }
    
    let callHistoryArray = [];

    if (callHistory) {
        callHistoryArray = callHistory.toArray();
    }

    useEffect( () => {
        fetchCallHistoryList(props.page+1, props.pageSize, searchTerm, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
    }, [currentSortColumn, props.pageSize, props.page]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight"
            showPaginationBottom={true}
            data={callHistoryArray}
            minRows={callHistoryArray && callHistoryArray.length > 0 ? 0 : 5}
            pages={callHistoryPageCount <= 0 ? 1 : callHistoryPageCount}
            page={props.page}
            columns={buildTableColumns}
            LoadingComponent={ReactTableLoadingComponent}
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                      if(column.id === columnObject.id) {
                         // state needs updated object reference, doesn't detect nested fields
                         currentSortColumn = {...columnObject};
                         currentSortColumn.desc = !currentSortColumn.desc;
                         sortedArray[index] = currentSortColumn;
                    }
                    else {
                         let resetColumn = {...columnObject};
                         resetColumn.desc = true;
                         sortedArray[index] = resetColumn;
                    }
                     setCurrentSortColumn(currentSortColumn);
                     setSortColumns(sortedArray);
                })
            }}
            onFetchData={(state, instance) => {
                resetScrollInsideTable();
             }}
            onPageSizeChange={(pageSize) => {
                props.setPage(0);
                props.setPageSize(pageSize);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setPage(pageIndex);
                resetScrollInsideTable();
            }}
            defaultPageSize={25}
            manual
            callHistory={callHistory}
            setCurrentModal={setCurrentModal}
            loading={loading}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'call-history-row-' + rowInfo.index,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

CallHistoryListTable.propTypes = {
    callHistory: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
    callHistoryPageCount: makeSelectCallHistoryPageCount(),
    callHistory: makeSelectCallHistoryList(),
    loading: makeSelectCallHistoryListLoading(),
    currentCompanyLevel: currentCompanyLevel(),
    alternateDeviceUserIdEnabled: selectAlternateIdEnabled(),
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchCallHistoryList: (page, pageSize, searchTerm, sortBy, direction) => dispatch(CallHistoryActions.getCallHistoryRequest(page, pageSize, searchTerm, sortBy, direction)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CallHistoryListTable);