import React, { useEffect, useRef } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import "react-table/react-table.css";
import ApiAccessKeyMenu from "../../containers/ApiAccessKeyMenu/apiAccessKeyMenu";
import ReactTableLoadingComponent from "../../components/ReactTableLoadingComponent";
import { createStructuredSelector } from "reselect";
import { selectUserId } from "../../reducers/auth";
import moment from "moment";
import "./apiAccessKeyTable.css";
import { connect } from "react-redux";
import {
  makeSelectApiAccessKeyList,
  ApiAccessKeyLoading,
  makeSelectApiAccessKeyPageCount,
  actions as ApiAccessKeyActions,
} from "../../reducers/apiAccessKey";
import { userCompanyId } from "../../reducers/auth";
import { actions as AppActions } from "../../reducers/app";

const ApiAccessKeyTable = (props) => {
  const usersTableColumns = [
    {
      Header: <div id="api-access-key-enabled">Enabled</div>,
      id: "enabled",
      Cell: (row) => (
        <div title={row.original.enabled ? "True" : "False"}>{row.original.enabled ? "True" : "False"}</div>
      ),
    },
    {
      Header: <div id="api-access-key-description">Description</div>,
      id: "description",
      sortable: true,
      Cell: (row) => <div title={row.original.description}>{row.original.description}</div>,
    },
    {
      Header: <div id="api-access-key-masked-api-key">Masked API Key</div>,
      id: "masked-api-key",
      Cell: (row) => {
        const paddedApiKey = row.original.maskedApiKey + `*`.repeat(20);
        return (
            <div title={paddedApiKey}>{paddedApiKey}</div>
        );
      },
    },
    {
      Header: <div id="api-access-key-created">Date Created</div>,
      id: "created",
      Cell: (row) => (
        <div title={row.original.role.createdAt}>{formatLastAccess(row.original.createdAt)}</div>
      ),
    },
    {
      Header: <div id="api-access-key-access-time">Last Known Access Time</div>,
      id: "access-time",
      Cell: (row) => (
        <div title={row.original.lastAccessedAt}>{formatLastAccess(row.original.lastAccessedAt)}</div>
      ),
    },
    {
      Header: "",
      width: 65,
      id: "edit-menu",
      Cell: (row) => (
        <ApiAccessKeyMenu
          row={row}
        />
      ),
    },
  ];

  function formatLastAccess(callTime) {
    return callTime ? moment(callTime).format("MM/DD/YYYY hh:mm:ss A") : "";
  }

  const {
    loading,
    apiAccessKeyList,
    apiAccessTablePageCount,
    fetchApiAccessKeyList,
    companyId
  } = props;
  const update = useRef(true);
  let apiAccessKeyArray = [];

  if (apiAccessKeyList) {
    apiAccessKeyArray = apiAccessKeyList.toArray();
  }

  useEffect(() => {
    if (apiAccessKeyArray && apiAccessKeyArray.length === 0 && props.page > 0 &&
        apiAccessTablePageCount < props.page + 1) {
        props.setPage(props.page - 1);
    }
  }, [apiAccessKeyArray, apiAccessTablePageCount, props]);

  useEffect(() => {
    props.setPageSize(25);
    props.setCurrentPage(1);
  }, []);

  useEffect(() => {
    let companyApiId;
    if (companyId === undefined || companyId == null)
      companyApiId = 0;
    else 
      companyApiId = companyId
      
    fetchApiAccessKeyList(companyApiId, props.page + 1, props.pageSize,
      props.searchValue, props.currentSortColumn.id, props.currentSortColumn.desc ? "DESC" : "ASC"
    );
    props.setTableProps({
      page: props.page + 1,
      pageSize: props.pageSize,
      sortBy: props.currentSortColumn.id,
      direction: props.currentSortColumn.desc ? "DESC" : "ASC",
    });
  }, [props.currentSortColumn, props.pageSize, props.page]);

  function resetScrollInsideTable() {
    let tableBody = document.querySelector(".rt-tbody");
    tableBody.scrollTop = 0;
  }

  useEffect(() => {
    const tableBody = document.querySelector(".rt-tbody");
    const table = document.querySelector(".ReactTable");

    if (tableBody.scrollHeight > tableBody.clientHeight) {
      table.classList.add("scrollable");
    } else {
      table.classList.remove("scrollable");
    }
  }, [loading]);

  return (
    <ReactTable
      resizable={false}
      sortable={false}
      className="-striped -highlight"
      showPaginationBottom={true}
      data={apiAccessKeyArray}
      minRows={apiAccessKeyArray && apiAccessKeyArray.length > 0 ? 0 : 5}
      page={props.page}
      pages={apiAccessTablePageCount <= 0 ? 1 : apiAccessTablePageCount}
      columns={usersTableColumns}
      LoadingComponent={ReactTableLoadingComponent}
      sorted={props.sortColumns}
      onSortedChange={(newSorted, column, shiftKey) => {
        let sortedArray = [...props.sortColumns];
        let currentSortColumn;
        sortedArray.forEach((columnObject, index) => {
          if (column.id === columnObject.id) {
            // state needs updated object reference, doesn't detect nested fields
            currentSortColumn = { ...columnObject };
            currentSortColumn.desc = !currentSortColumn.desc;
            sortedArray[index] = currentSortColumn;
          } else {
            let resetColumn = { ...columnObject };
            resetColumn.desc = false;
            sortedArray[index] = resetColumn;
          }
          props.setCurrentSortColumn(currentSortColumn);
          props.setSortColumns(sortedArray);
        });
      }}
      onPageSizeChange={(pageSize) => {
        props.setPage(0);
        props.setPageSize(pageSize);
        resetScrollInsideTable();
      }}
      onPageChange={(pageIndex) => {
        props.setCurrentPage(pageIndex + 1);
        props.setPage(pageIndex);
        resetScrollInsideTable();
      }}
      defaultPageSize={25}
      manual
      apiAccessKeyList={apiAccessKeyList}
      loading={loading}
      getTrProps={(state, rowInfo, column) => {
        if (rowInfo) {
          return {
            id: "api-access-key-row-" + rowInfo.index,
          };
        } else {
          return {};
        }
      }}
    />
  );
};

ApiAccessKeyTable.propTypes = {
  apiAccessKeyList: ImmutablePropTypes.list,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  apiAccessKeyList: makeSelectApiAccessKeyList(),
  loading: ApiAccessKeyLoading(),
  companyId: userCompanyId(),
  userId: selectUserId(),
  apiAccessTablePageCount: makeSelectApiAccessKeyPageCount(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApiAccessKeyList: (companyId, page, pageSize, searchValue, sortBy, direction) =>
      dispatch(
        ApiAccessKeyActions.getApiAccessKeyRequest(
          companyId,
          page,
          pageSize,
          searchValue,
          sortBy,
          direction
        )
      ),
    setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
    setCurrentPage: (currentPage) =>
      dispatch(AppActions.setCurrentPage(currentPage)),
    setTableProps: (tableProps) =>
      dispatch(AppActions.setTableProps(tableProps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiAccessKeyTable);
