import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';
import HasAccess from '../../components/HasAccess/HasAccess';

import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSagas';
import reducer, { actions as PidfloDevicesActions } from '../../reducers/pidfloDevices';
import saga from '../../sagas/pidfloDevices';
import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { types as pidfloDevicesTypes } from '../../reducers/pidfloDevices';
import PidfloDevicesTable from '../../containers/PidfloDevicesTable/pidfloDevicesTable';
import searchFieldAllowParenthesesRegex  from '../../utils/searchFieldAllowParenthesesRegex';
import SearchField  from '../../utils/searchField';

const initialSortColumns = [
    {
        id: 'phoneNumber',
        desc: false
    }
]
function PidfloDevicesPage(props){
    const [searchValue, setSearchValue] = useState('');
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    const [pageIndex, setPageIndex] = useState(0);

    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const isMatch = searchFieldAllowParenthesesRegex(inputValue);

        if (isMatch) {
            setSearchValue(inputValue);
            searchApiCall(
                inputValue, 
                props.pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC'    
            );
        }
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, direction) => {
            if (inputValue.length > 0) {
                setPageIndex(0);
                props.fetchPidfloDevicesList(1, pageSize, inputValue, sortBy, direction);
            } else if(inputValue.length === 0){
                setPageIndex(0);
                props.fetchPidfloDevicesList(1, pageSize, '', sortBy, direction)
            }
        }, 500)
    , []);

    function modalHandleOpen() {
        props.setCurrentModal(pidfloDevicesTypes.ADD_PIDFLO_DEVICES);
    }

    useEffect(() => {
        props.setPageTitle('PIDF-LO Devices');
        props.setPageTitleId('pidflo-devices-header');
    }, []);

    return (
        <div className="pidflo-devices-section">
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    <SearchField 
                        value={searchValue}
                        onChange={handleInputSearchChange}
                    />
                </Grid>
                <Grid item>
                    <HasAccess
                        permissions={["ADD_PIDFLO_DEVICES"]}
                    >
                        {   searchValue.length === 0 &&
                            <Button className="button" id="btnAddPidfloDevices" variant="contained" color="primary" onClick={modalHandleOpen} >
                                Add PIDF-LO Device
                            </Button>
                        }
                    </HasAccess>
                </Grid>
            </Grid>
            <br/>
            <PidfloDevicesTable 
                setCurrentModal={props.setCurrentModal}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns} 
                currentSortColumn={currentSortColumn}
                setCurrentSortColumn={setCurrentSortColumn}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                fetchPidfloDevicesList={props.fetchPidfloDevicesList}
                pageSize={props.pageSize}
             />
        </div>
    );
}

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({
        pageSize: selectPageSize(),
    });
}

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        fetchPidfloDevicesList: (page, pageSize, searchTerm, sortBy, direction) => dispatch(PidfloDevicesActions.getPidfloDevicesRequest(page, pageSize, searchTerm, sortBy, direction)),
    }
};

const withReducer = injectReducer({ key: 'pidfloDevices', reducer });
const withSaga = injectSaga({ key: 'pidfloDevices', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(PidfloDevicesPage);