/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const HELD_DEVICES = 'HELD_DEVICES/'; 
export const types = {
    GET_HELD_DEVICES_REQUEST: HELD_DEVICES + 'GET_HELD_DEVICES_REQUEST',
    GET_HELD_DEVICES_SUCCESS: HELD_DEVICES + 'GET_HELD_DEVICES_SUCCESS',
    HELD_DEVICES_DETAILS: HELD_DEVICES + 'HELD_DEVICES_DETAILS',
    HELD_DEVICES_RECORDING: HELD_DEVICES + 'HELD_DEVICES_RECORDING',
    HELD_DEVICES_SIP_INVITE: HELD_DEVICES + 'HELD_DEVICES_SIP_INVITE',
    GET_HELD_DEVICES_ERROR: HELD_DEVICES + 'GET_HELD_DEVICES_ERROR',
    DELETE_HELD_DEVICE: HELD_DEVICES + 'DELETE_HELD_DEVICE',
    HELD_DEVICES_DETAILS: HELD_DEVICES + 'HELD_DEVICES_DETAILS',
    HELD_DEVICES_HELD_REQUEST: HELD_DEVICES + 'HELD_DEVICES_HELD_REQUEST'
};

export const initialState = fromJS({
    heldDevicesList: List(),
    heldDevicesLoading: false,
    heldDevicesListPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_HELD_DEVICES_REQUEST:
            return state.set('heldDevicesLoading', true);
        case types.GET_HELD_DEVICES_ERROR:
            return state.set('heldDevicesLoading', false);
        case types.GET_HELD_DEVICES_SUCCESS:
            return state.set('heldDevicesLoading', false)
                        .set('heldDevicesList', List(action.data))
                        .set('heldDevicesListPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    getHeldDevicesRequest: (page, pageSize, searchTerm, sortBy, direction) => ({
        type: types.GET_HELD_DEVICES_REQUEST,
        page,
        pageSize,
        searchTerm,
        sortBy,
        direction
    }),
    getHeldDevicesSuccess: (heldDevicesList, pageCount) => ({
        type: types.GET_HELD_DEVICES_SUCCESS,
        data: heldDevicesList,
        pageCount
    }),
    getHeldDevicesError: () => ({
        type: types.GET_HELD_DEVICES_ERROR
    }),
    deleteHeldDeviceRequest: (id) => ({
        type: types.DELETE_HELD_DEVICE,
        data: id
    }),
};

// Selectors
const selectHeldDevices = state => state.get('heldDevices', initialState);

export const makeSelectHeldDevicesList = () =>
    createSelector(selectHeldDevices, heldDevicesState => heldDevicesState.get('heldDevicesList'));

export const makeSelectHeldDevicesListLoading = () =>
    createSelector(selectHeldDevices, heldDevicesState => heldDevicesState.get('heldDevicesLoading'));

export const makeSelectHeldDevicesPageCount = () =>
    createSelector(selectHeldDevices, heldDevicesState => heldDevicesState.get('heldDevicesListPageCount'));