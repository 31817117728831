import React, { Component } from 'react';
import Button from '@material-ui/core/Button'; 
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions as ManageLicenseActions } from '../../../reducers/manageLicenses';
import { connect } from 'react-redux';


class DeleteLicenseModal extends Component {
    handleDelete = () => {
        this.props.submitDeleteLicense(this.props.modalData.license.id, this.props.modalData.companyId);
    }
    
    render() {
        const { modalLoading, licenseModel, modalData, companyId } = this.props;

        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like to delete this license.
                        </p>
                        <p>
                            License Model: <b id="license-model-value">{modalData.licenseModel === 'RIGHT_TO_USE' ? 'Perpetual' : modalData.licenseModel}</b><br/>
                            License Type: <b id="license-type-value">{modalData.license.type}</b>
                        </p>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteLicense: (id, companyId) => dispatch(ManageLicenseActions.deleteLicenseRequest(id, companyId))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper
)(DeleteLicenseModal);