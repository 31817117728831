import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Select, MenuItem, Tooltip, Input } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Info from '@material-ui/icons/Info';
import cronstrue from 'cronstrue';
import ReactTable from 'react-table';
import ReportsAPI from '../../../../apis/reportsApi';
import moment from 'moment';
import ReactTableLoadingComponent from '../../../../components/ReactTableLoadingComponent';

import { HOURS_ARRAY, MINUTES_ARRAY, DAY_OF_MONTH_TOGGLE, generateCronExpression, convertDayTogglesToDayArray } from '../../../../utils/cronUtils';

export default function CronWidget(props) {

    const {
        classes,
        modalLoading,
        touched,
        errors,
        cronTableDataStructure,
        setCronTableDataStructure
    } = props;

    const [displayTable, setDisplayTable] = useState(false);
    const [cronTableData, setCronTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    function handleDisplayTable() {
        setDisplayTable(!displayTable);
        setTableLoading(true);

        ReportsAPI.listFutureExecutions(cronTableDataStructure.cronValue).then(response => {
            const cronTableArray = [];

            response.data.forEach(item => {
                cronTableArray.push({ item });
            });

            setTableLoading(false);
            setCronTableData(cronTableArray);
        }).catch(error => {
            setTableLoading(false);
            console.log(error);
        });
    }

    const handleDayOfMonthRadioToggleChange = (event) => {
        setDisplayTable(false);
        
        const arr = cronTableDataStructure.specificDayArrayToggles;
        arr.forEach(item => {
            item.toggle = false;
        });
        setCronTableDataStructure(arr);

        setCronTableDataStructure(
            {...cronTableDataStructure, 
                dayOfMonthToggle:'specificDay',
                cronValue
            }
        );

        const dayOfMonthToggleValue = event.target.value
        const cronValue = generateCronExpression(
            0,
            cronTableDataStructure.minute,
            cronTableDataStructure.hour,
            dayOfMonthToggleValue,
            '*', 
            convertDayTogglesToDayArray(cronTableDataStructure.specificDayArrayToggles),
            '*' 
        );
        setCronTableDataStructure(
            {...cronTableDataStructure, 
                dayOfMonthToggle:dayOfMonthToggleValue,
                cronValue
            }
        );
    };

    const handleSpecificDayCheckBoxChange = (event) => {
        setDisplayTable(false);

        let specificDayArrayToggles = [...cronTableDataStructure.specificDayArrayToggles];
        specificDayArrayToggles[event.target.name] = {...specificDayArrayToggles[event.target.name], toggle:event.target.checked};
        
        const cronValue = generateCronExpression(
            0, 
            cronTableDataStructure.minute, 
            cronTableDataStructure.hour, 
            cronTableDataStructure.dayOfMonthToggle, 
            '*', 
            convertDayTogglesToDayArray(specificDayArrayToggles),
            '*' 
        );
        setCronTableDataStructure(
            {...cronTableDataStructure, 
                specificDayArrayToggles,
                dayOfMonthToggle:'specificDay',
                cronValue
            }
        );
    };

    const handleHourChange = (event) => {
        setDisplayTable(false);

        const hourValue = event.target.value;
        const cronValue = generateCronExpression(
            0, 
            cronTableDataStructure.minute, 
            hourValue, 
            cronTableDataStructure.dayOfMonthToggle, 
            '*', 
            convertDayTogglesToDayArray(cronTableDataStructure.specificDayArrayToggles),
            '*' 
        );
        setCronTableDataStructure(
            {...cronTableDataStructure, 
                hour:hourValue,
                cronValue
            }
        );
    }
    
    const handleMinuteChange = (event) => {
        setDisplayTable(false);

        const minuteValue = event.target.value
        const cronValue = generateCronExpression(
            0, 
            minuteValue, 
            cronTableDataStructure.hour, 
            cronTableDataStructure.dayOfMonthToggle, 
            '*', 
            convertDayTogglesToDayArray(cronTableDataStructure.specificDayArrayToggles),
            '*' 
        );
        setCronTableDataStructure(
            {...cronTableDataStructure, 
                minute:minuteValue,
                cronValue
            }
        );
    }

    const buildTableColumns = [
        {
            Header: "Run",
            id: "run",
            width: 65,
            Cell: row => {
                return <div>{row.index + 1}</div>;
            }
        },
        {
            Header: "Date",
            id: "date",
            Cell: row => {
                return <div>{moment(row.original.item).format('MM/DD/YYYY')}</div>
            }
        },
        {
            Header: "Preferred Time",
            id: "time",
            Cell: row => {
                return <div>{moment(row.original.item).format('hh:mm:ss A')}{moment.tz(row.original.item,'US/Central').isDST() ? ' CDT' : ' CST'}</div>;
            }
        }
    ];

    return (
        <div className="scheduling-section">
            <FormControl component="fieldset">
                <RadioGroup value={cronTableDataStructure.dayOfMonthToggle} onChange={handleDayOfMonthRadioToggleChange}>
                    <FormControlLabel htmlFor="specificDay" value={DAY_OF_MONTH_TOGGLE.SPECIFIC_DAY} control={<Radio color="primary" id="specificDay" />} label="Run the report on specific days" />
                    <FormControlLabel htmlFor="firstDay" value={DAY_OF_MONTH_TOGGLE.FIRST_DAY} control={<Radio color="primary" id="firstDay" tabIndex={-1} />} label={<span>Run the report on the <b>first</b> day of every month</span>} />
                    <FormControlLabel htmlFor="lastDay" value={DAY_OF_MONTH_TOGGLE.LAST_DAY} control={<Radio color="primary" id="lastDay" tabIndex={-1} />} label={<span>Run the report on the <b>last</b> day of every month</span>} />
                </RadioGroup>
            </FormControl>
            <div style={{ display: 'flex', position: 'absolute', top: 47}}>
                <div style={{ marginLeft: 47}}>
                { 
                    cronTableDataStructure.specificDayArrayToggles.map((day, index) => {
                        return (
                            <FormControlLabel
                                htmlFor={day.key}
                                key={index}
                                control={
                                <Checkbox
                                    id={day.key}
                                    key={index}
                                    checked={day.toggle}
                                    onChange={handleSpecificDayCheckBoxChange}
                                    name={''+index}
                                    color="primary"
                                />
                                }
                                label={day.key}
                            />
                    )})
                }
                </div>
            </div>
            <div className="time-select">
                <span>Preferred Start Time</span>
                <Tooltip classes={{
                            tooltip: classes.tooltip
                        }}
                        title="This will define the approximate time of day your report will run in Central Time."
                    >
                        <span style={{ margin: '0 10px' }} tabIndex={0}><Info /></span>
                </Tooltip>
                <Select
                    id="hourSelect"
                    name="hourSelect"
                    value={cronTableDataStructure.hour}
                    onChange={handleHourChange}
                    disabled={modalLoading}
                    input={<Input name="hourSelect" id="hourSelect-label"/>}
                    inputProps={{
                        "aria-label": "Hour Select"
                    }}
                    fullWidth={true}
                    error={touched.hourSelect && Boolean(errors.hourSelect)}>
                        {
                            HOURS_ARRAY.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                        }
                </Select>
                <Select
                    id="minuteSelect"
                    name="minuteSelect"
                    value={cronTableDataStructure.minute}
                    onChange={handleMinuteChange}
                    disabled={modalLoading}
                    input={<Input name="minuteSelect" id="minuteSelect-label"/>}
                    fullWidth={true}
                    inputProps={{
                        "aria-label": "Minute Select"
                    }}
                    error={touched.minuteSelect && Boolean(errors.minuteSelect)}>
                        {
                            MINUTES_ARRAY.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                        }
                </Select>
            </div>
            <Button style={{  margin: '20px 0 -4px -10px', width: 'calc(100% + 20px)', display: 'flex', justifyContent: 'space-between' }}
                    id="cron-output-text-button"
                    disabled={false} 
                    className="button"
                    color="primary" 
                    onClick={handleDisplayTable}
                    variant="contained">
                        <span style={{ 
                            marginRight: 10,
                            fontSize: 20, 
                            lineHeight: 1,
                            transform: displayTable ? 'rotate(90deg)' : 'rotate(0deg)'
                            }}>&#x25B6;</span>
                        {cronstrue.toString(cronTableDataStructure.cronValue)}
                        <Tooltip classes={{
                            tooltip: classes.tooltip
                        }}
                                title="Click within this area to preview the next five scheduled runs for the report."
                            >
                                <span style={{ margin: '0 0 0 10px', color: '#fff' }} tabIndex={0}><Info /></span>
                        </Tooltip>
            </Button>
            {displayTable &&
                <ReactTable
                    resizable={false}
                    sortable={false}
                    className="-striped -highlight cron-table"
                    showPaginationBottom={false}
                    minRows={5}
                    data={cronTableData}
                    pageSize={5}
                    columns={buildTableColumns}
                    loading={tableLoading}
                    LoadingComponent={ReactTableLoadingComponent}
                    style={{margin: '5px 0px 10px', textAlign: 'center'}}
                />
            }
        </div>
    );
}