import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../../components/ReactTableLoadingComponent';
import CloudDownload  from '@material-ui/icons/CloudDownload';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { selectUserCompany } from '../../../reducers/auth';
import { selectAlternateIdEnabled } from '../../../reducers/app';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const TemplateFilesModal = props => {
    const { loading, altIdEnabled } = props;

    const buildTableColumns = [
        {
            Header: "Template Name",
            id: "name",
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: "Download",
            id: "download",
            Cell: row => (
                <div style={{ textAlign: 'center'}}>
                    <a href={row.original.link}>
                        <Button id={`template-download-link-${row.index}`}>
                            <CloudDownload style={{color: '#1b536a'}} />
                        </Button>
                    </a>
                </div>
            )
        }
    ];
    
    function templateFilesData() {
        const userCompany = props.userCompany.toJS().locationTreeType;

        const user = JSON.parse(sessionStorage.getItem('__permifyUser'));

        const isNetworkDiscovery = user && (user.permissions.includes('ADD_NETWORK_DISCOVERY') || user.permissions.includes('EDIT_NETWORK_DISCOVERY'));

        const isLocation = user && (user.permissions.includes('ADD_LOCATIONS') || user.permissions.includes('EDIT_LOCATIONS'));

        const isDeviceUsers = user && (user.permissions.includes('ADD_DEVICE_USERS') || user.permissions.includes('EDIT_DEVICE_USERS'));

        const isPidfloDevices = user && (user.permissions.includes('ADD_PIDFLO_DEVICES') || user.permissions.includes('EDIT_PIDFLO_DEVICES'));

        const data = [];

        /* BUILDING & LOCATION */
        if (userCompany === 'BUILDING') {
            data.push(
                {
                    name: 'Sample and Reference',
                    link: 'https://resources.e911cloud.com/templates/Sample%20and%20Reference%20%28Building%20Org%20Type%29.xlsx'
                }, 
            )
        }
        if (userCompany === 'BUILDING' && isNetworkDiscovery) {
            data.push(
                {
                    name: 'BSSID Assignment',
                    link: 'https://resources.e911cloud.com/templates/BSSID%20Assignment%20%28Building%20Org%20Type%29.csv'
                }
            )
        } 
        if (userCompany === 'BUILDING' && isLocation) {
            data.push(
                {
                    name: 'Buildings',
                    link: 'https://resources.e911cloud.com/templates/Buildings%20%28Building%20Org%20Type%29.csv'
                }
            )
        }
        if (userCompany === 'BUILDING' && isDeviceUsers) {
            data.push(
                {
                    name: 'Device Users',
                    link: 'https://resources.e911cloud.com/templates/Device%20Users.csv'
                }
            )
        }
        if (userCompany === 'BUILDING' && isNetworkDiscovery) {
            data.push(
                {
                    name: 'LLDP Chassis Assignment',
                    link: 'https://resources.e911cloud.com/templates/LLDP%20Chassis%20Assignment%20(Building%20Org%20Type).csv'
                },
                {
                    name: 'LLDP Port Assignment',
                    link: 'https://resources.e911cloud.com/templates/LLDP%20Port%20Assignment%20(Building%20Org%20Type).csv'
                },
            )
        } 
        if (userCompany === 'BUILDING' && isLocation) {
            data.push(
                {
                    name: 'Locations',
                    link: 'https://resources.e911cloud.com/templates/Locations%20%28Building%20Org%20Type%29.csv'
                }
            )
        }
        if (userCompany === 'BUILDING' && isNetworkDiscovery) {
            data.push(
                {
                    name: 'MAC Assignment',
                    link: 'https://resources.e911cloud.com/templates/MAC%20Assignment%20%28Building%20Org%20Type%29.csv'
                },
            )
        } 
        if (userCompany === 'BUILDING' && isPidfloDevices) {
            data.push(
                {
                    name: 'PIDF-LO Devices',
                    link: 'https://resources.e911cloud.com/templates/PIDF-LO%20Devices.csv'
                }
            )
        }
        if (userCompany === 'BUILDING' && isNetworkDiscovery) {
            data.push(
                {
                    name: 'Private IP Range Assignment',
                    link: 'https://resources.e911cloud.com/templates/Private%20IP%20Range%20Assignment%20%28Building%20Org%20Type%29.csv'
                },
                {
                    name: 'Public IP Range Assignment',
                    link: 'https://resources.e911cloud.com/templates/Public%20IP%20Range%20Assignment.csv'
                }
            )
        } 
        /* LOCATION ONLY */
        if (userCompany === 'SIMPLE') {
            data.push(
                {
                    name: 'Sample and Reference',
                    link: 'https://resources.e911cloud.com/templates/Sample%20and%20Reference%20%28Location%20Only%20Org%20Type%29.xlsx'
                }   
            )
        }
        if (userCompany === 'SIMPLE' && isNetworkDiscovery) {
            data.push(
                {
                    name: 'BSSID Assignment',
                    link: 'https://resources.e911cloud.com/templates/BSSID%20Assignment%20%28Location%20Only%20Org%20Type%29.csv'
                }
            )
        }
        if (userCompany === 'SIMPLE' && isDeviceUsers) {
            data.push(
                {
                    name: 'Device Users',
                    link: 'https://resources.e911cloud.com/templates/Device%20Users.csv'
                }
            )
        }
        if (userCompany === 'SIMPLE' && isNetworkDiscovery) {
            data.push(
                {
                    name: 'LLDP Chassis Assignment',
                    link: 'https://resources.e911cloud.com/templates/LLDP%20Chassis%20Assignment%20(Location%20Only%20Org%20Type).csv'
                },
                {
                    name: 'LLDP Port Assignment',
                    link: 'https://resources.e911cloud.com/templates/LLDP%20Port%20Assignment%20(Location%20Only%20Org%20Type).csv'
                },
            )
        }
        if (userCompany === 'SIMPLE' && isLocation) {
            data.push(
                {
                    name: 'Locations',
                    link: 'https://resources.e911cloud.com/templates/Locations%20%28Location%20Only%20Org%20Type%29.csv'
                }
            )
        }
        if (userCompany === 'SIMPLE' && isNetworkDiscovery) {
            data.push(
                {
                    name: 'MAC Assignment',
                    link: 'https://resources.e911cloud.com/templates/MAC%20Assignment%20%28Location%20Only%20Org%20Type%29.csv'
                }
            )
        }
        if (userCompany === 'SIMPLE' && isPidfloDevices) {
            data.push(
                {
                    name: 'PIDF-LO Devices',
                    link: 'https://resources.e911cloud.com/templates/PIDF-LO%20Devices.csv'
                }
            )
        }
        if (userCompany === 'SIMPLE' && isNetworkDiscovery) {
            data.push(
                {
                    name: 'Private IP Range Assignment',
                    link: 'https://resources.e911cloud.com/templates/Private%20IP%20Range%20Assignment%20%28Location%20Only%20Org%20Type%29.csv'
                },
                {
                    name: 'Public IP Range Assignment',
                    link: 'https://resources.e911cloud.com/templates/Public%20IP%20Range%20Assignment.csv'
                }
            )
        }
        return data;
    };

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <div>
            <br/>
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight"
                showPaginationBottom={false}
                data={templateFilesData()}
                minRows={0}
                columns={buildTableColumns}
                loading={loading}
                LoadingComponent={ReactTableLoadingComponent}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'template-files-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                }
            />
        </div>
    );
}

const mapStateToProps = ()  => createStructuredSelector({
    userCompany: selectUserCompany(),
    altIdEnabled: selectAlternateIdEnabled()
});

export default compose(
    connect(mapStateToProps),
    ModalWrapper
)(TemplateFilesModal);