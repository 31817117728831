import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import SipEditMenu from '../../containers/SipEditMenu/SipEditMenu';
import { createStructuredSelector } from 'reselect';
import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { currentCompanyOrgType, orgTypes } from '../../reducers/auth';

import { makeSelectSipList, makeSelectSipListLoading, makeSelectSipPageCount, actions as SipActions } from '../../reducers/sip';

const initialSortColumns = [
    {
        id: 'name',
        desc:false
    }
]

function SipTable(props) {
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    const [pageIndex, setPageIndex] = useState(0);

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isEdit = user && user.permissions.includes('EDIT_CALL_ROUTING');
	const isDelete = user && user.permissions.includes('DELETE_CALL_ROUTING');
    const isView = isEdit || isDelete;

    const { sipFromApi, 
            loading,
            sipPageCount,
            fetchSipList } = props;

    const columns = [];

        props.currentCompanyOrgType !== orgTypes.CUSTOMER &&
        columns.push({
            Header: "Organization",
            id: "description",
            Cell: row => <div title={row.original.name}>{row.original.name ? row.original.name : '***System***'}</div>
        });
        columns.push({
            Header: "Name",
            id: "name",
            sortable: true,
            Cell: row => <div title={row.original.callRouteInfo.name}>{row.original.callRouteInfo.name}</div>
        });
       
        columns.push({
            Header: "Override Destination",
            id: "overrideDestination",
            Cell: row => <div title={row.original.callRouteInfo.overrideDestination}>{row.original.callRouteInfo.overrideDestination}</div>
        });
        columns.push({
            Header: "RS Relay Center Status",
            id: "disableRelayCenter",
            Cell: row => <div title={(row.original.callRouteInfo.disableRelayCenter == null) ? 'Enabled' : 'Disabled'}>{(row.original.callRouteInfo.disableRelayCenter == null) ? 'Enabled' : 'Disabled'}</div>
        });
        columns.push({
            Header: "Transport",
            id: "transport",
            Cell: row => <div title={row.original.transport}>{row.original.transport}</div>
        });
        columns.push({
            Header: "Host",
            id: "host-address",
            Cell: row => <div title={row.original.host}>{row.original.host}</div>
        });
        columns.push({
            Header: '',
            width: 65,
            id: 'edit-menu',
            Cell: row => (
                isView ? <SipEditMenu row={row}/> : null
            )
        });

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }
    
    let sip = sipFromApi;
    if(sip)
        sip = sip.toArray();

    useEffect(() => {
        if (sip && sip.length === 0 && pageIndex > 0 && sipPageCount < pageIndex+1) {
            setPageIndex(pageIndex-1)
        }
    }, [sip && sip.length === 0 && pageIndex > 0 && sipPageCount < pageIndex+1]);
    
    useEffect( () => {
        if (props.pageSize) {
            fetchSipList(pageIndex+1, props.pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
            props.setTableProps({
                page: pageIndex+1, 
                pageSize: props.pageSize, 
                sortBy: currentSortColumn.id, 
                direction: currentSortColumn.desc ? 'DESC': 'ASC'
            });
        }
    }, [currentSortColumn, props.pageSize, pageIndex]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <div>
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight"
                showPaginationBottom={true}
                data={sip}
                page={pageIndex}
                pages={sipPageCount <= 0 ? 1 : sipPageCount}
                minRows={sip && sip.length > 0 ? 0 : 5}
                columns={columns}
                loading={loading}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                currentSortColumn={currentSortColumn}
                setCurrentSortColumn={setCurrentSortColumn} 
                sorted={sortColumns}
                onSortedChange={(newSorted, column, shiftKey) => {
                    let sortedArray = [...sortColumns];
                    let currentSortColumn;
                    sortedArray.forEach( (columnObject, index) => {                    
                        if(column.id === columnObject.id) {
                            // state needs updated object reference, doesn't detect nested fields
                            currentSortColumn = {...columnObject};
                            currentSortColumn.desc = !currentSortColumn.desc;
                            sortedArray[index] = currentSortColumn;
                        }
                        else {
                            let resetColumn = {...columnObject};
                            resetColumn.desc = true;
                            sortedArray[index] = resetColumn;
                        }
                        setCurrentSortColumn(currentSortColumn);
                        setSortColumns(sortedArray);
                    })
                }}
                defaultPageSize={25}
                manual
                onFetchData={(state, instance) => {
                    props.setPageSize(state.pageSize);
                    props.setCurrentPage(state.page+1);
                    resetScrollInsideTable();
                }}
                onPageSizeChange={(pageSize) => {
                    props.setPageSize(pageSize);
                    setPageIndex(0);
                    resetScrollInsideTable();
                }}
                onPageChange={(pageIndex) => {
                    setPageIndex(pageIndex);
                    resetScrollInsideTable();
                }}
                LoadingComponent={ReactTableLoadingComponent}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'sip-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                } />
        </div>
    );

}

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({
        sipFromApi: makeSelectSipList(),
        loading: makeSelectSipListLoading(),
        sipPageCount: makeSelectSipPageCount(),
        pageSize: selectPageSize(),
        currentCompanyOrgType: currentCompanyOrgType()
    });
}

const mapDispatchToProps = dispatch => {
    return { 
        fetchSipList: (page, pageSize, sortBy, direction) => dispatch(SipActions.getSipRequest(page, pageSize, sortBy, direction)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SipTable);
    