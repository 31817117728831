/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
export const TEST_CALL_GENERATOR = 'TEST_CALL_GENERATOR/'; 
export const types = {
    TEST_CALL_GENERATOR_REQUEST: TEST_CALL_GENERATOR + 'TEST_CALL_GENERATOR_REQUEST'
};

// Action Creators
export const actions = {
    testCallGeneratorRequest: (testCall) => ({
            type: types.TEST_CALL_GENERATOR_REQUEST, 
            data: testCall
    })
};