import React, { Component } from 'react';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import moment from 'moment';
import './DetailsHeldDevices.css';

class DetailsHeldDevicesModal extends Component {
    formatLastAccess(callTime) {
        return callTime ?  moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
    }
    render() {
        const {
            mac,
            nai,
            erl,
            discoveryType,
            ipv4,
            ipv6,
            bssid,
            decodedChassisId,
            decodedPortId,
            lastAccess,
            deviceType,
            identifier,
            userId
        } = this.props.modalData;

        return(
            <div>
                <ul className="details-list">
                    <li><b>DeviceID: </b>{deviceType === 'HELD_PLUS' ? identifier : mac}</li>
                    <li><b>NAI: </b>{deviceType === 'HELD_PLUS' ? userId : nai}</li>
                    {erl && erl.address && erl.address.normalizedAddress && (
                        <li><b>Location: </b>{erl.address.normalizedAddress}</li>
                    )}
                    {erl && erl.normalizedGeoCoords && (
                        <li><b>Geodetic Coordinates: </b>{erl.normalizedGeoCoords.latitude + ", " + erl.normalizedGeoCoords.longitude}</li>
                    )}
                    <li><b>Discovery Method: </b>{discoveryType}</li>
                    <li><b>MAC Address: </b>{mac}</li>
                    <li><b>IP Address: </b>{ipv4}</li>
                    <li><b>IPv6 Address: </b>{ipv6}</li>
                    <li><b>BSSID: </b>{bssid}</li>
                    <li><b>Chassis ID: </b>{decodedChassisId}</li>
                    <li><b>PortID: </b>{decodedPortId}</li>
                    <li><b>Last Access Time: </b>{this.formatLastAccess(lastAccess)}</li>
                </ul>
            </div>
        );
    }
}

export default compose(
    ModalWrapper,
)(DetailsHeldDevicesModal);