import { axiosInstance } from "./api";
/* Cirrus Admin-Service /company Controller */
const ALERT_INTEGRATION_ENDPOINT = "admin-service/alert-integration";

export default class NotificationIntegrationsAPI {
  static getNotificationIntegrationsList(id) {
    return axiosInstance.get(`${ALERT_INTEGRATION_ENDPOINT}/informacast/company/${id}`);
  }
  
  static getNotificationIntegrationsDistributionList(id) {
    return axiosInstance.get(`${ALERT_INTEGRATION_ENDPOINT}/informacast/distributionLists/company/${id}`);
  }
  
  static getNotificationIntegrationsTemplates(id) {
    return axiosInstance.get(`${ALERT_INTEGRATION_ENDPOINT}/informacast/templates/company/${id}`);
  }

  static addInformaCastEntity(entity) {
    const payload = {
      name: entity.name,
      companyId: entity.companyId,
      distributionLists: entity.distributionLists.map(item => item.id),
      templateId: entity.templateId
    };

    return axiosInstance.post(`${ALERT_INTEGRATION_ENDPOINT}/informacast`, payload);
  }
  
  static editInformaCastEntity(entity) {
    const payload = {
      name: entity.name,
      companyId: entity.companyId,
      distributionLists: entity.distributionLists.map(item => item.id),
      templateId: entity.informaCastTemplate.id,
      id: entity.id
    };

    return axiosInstance.put(`${ALERT_INTEGRATION_ENDPOINT}/informacast`, payload);
  }

  static deleteInformaCastEntity(id) {
    return axiosInstance.delete(`${ALERT_INTEGRATION_ENDPOINT}/informacast/${id}`);
  }
}
