import React, { useState, useEffect, useContext, useRef } from 'react';
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';


import { userCompanyId, selectUserCompany } from '../../../../reducers/auth';
import { actions as NetworkDiscoveryActions } from '../../../../reducers/networkDiscovery';
import LocationsAPI from '../../../../apis/locationsApi';
import SimpleTreeTypeForm from '../../BuildingModals/forms/SimpleTreeTypeForm';
import ModalErrorMessage from '../../../../components/ModalWrapper/ModalErrorMessage';
import { LocationContext } from '../../../../components/Contexts/LocationContext';
import { zipRegex } from '../../../../utils/zipRegex';

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    icon: {
        position: 'absolute',
        right: '19px',
        bottom: '82px'
    },
    location: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    },
    autoCompleteOptions: {
        width: '355px'
    },
    paper: {
        position: 'absolute',
        zIndex: 9999,
        marginTop: theme.spacing.unit,
    }
});

const AddLocationForm = props => {
    const handleOnChange = (values) => {
        if (values.value) {
            props.setFieldValue('phoneNumber', values.value);
        } else {
            props.setFieldValue('phoneNumber', '');
        }
        props.setFieldTouched('phoneNumber', true);
    }
     
    return <SimpleTreeTypeForm {...props} handleOnChange={handleOnChange}/>
} 

const AddLocationModal = props => {
    const [errorDisplay, setErrorDisplay] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [modalLoading, setModalLoading] = useState(false);
    const [geoCordsAreRequired, setGeoCordsAreRequired] = useState(true);
    const [addressIsRequired, setAddressIsRequired] = useState(true);
    const [addressAndGeoCoordsToggle, setAddressAndGeoCoordsToggle] = useState('address');

    const formRef = useRef();

    const { multipleAddressList,
        setMultipleAddressList,
        addressIsAmbiguous,
        setAddressIsAmbiguous,
        addressIsAlternate,
        setAddressIsAlternate
    } = useContext(LocationContext);

    function errorHandler(error) {
        if (error.response && error.response.status >= 500) {
            setErrorMessage('The server is having issues. Please try again later.');
        } else if (!error.response) {
            setErrorMessage('The server is having issues. Please try again later.');
        } else {
            setErrorMessage(error.response.data.title);
        }
    }

    const handleFormSubmit = values => {
        if (addressIsAmbiguous) {
            setModalLoading(true);
            LocationsAPI.createLocationParsed(values).then(response => {
                // props.setAddLocationTabData(response.data.erlTO);
                setErrorDisplay(false);
                setModalLoading(false);
                props.setTabValue(0);
            }).catch(error => {
                setModalLoading(false);
                setErrorDisplay(true);
                errorHandler(error);
            });
        } 
        else if (addressIsAlternate) {
            setModalLoading(true);
            LocationsAPI.createLocationAlternate(values).then(response => {
                // props.setAddLocationTabData(response.data.erlTO);
                setErrorDisplay(false);
                setModalLoading(false);
                props.setTabValue(0);
            }).catch(error => {
                setModalLoading(false);
                setErrorDisplay(true);
                errorHandler(error);
            });
        }
        else {
            setModalLoading(true);
            LocationsAPI.addLocation(values).then(response => {
                if (response.data.ambiguous) {
                    setAddressIsAmbiguous(true);
                    setErrorDisplay(true);
                    setModalLoading(false);
                    setErrorMessage('Multiple addresses found. Please select the appropriate one.');
                    setMultipleAddressList(response.data.addressList);
                } else {
                    // props.setAddLocationTabData(response.data.erlTO);
                    setErrorDisplay(false);
                    setModalLoading(false);
                    props.setTabValue(0);
                }
            }).catch(error => {
                setModalLoading(false);
                setErrorDisplay(true);
                errorHandler(error);
            });
        }
    }

    useEffect(() => {
        return () => {
            setAddressIsAmbiguous(false);
            setAddressIsAlternate(false);
        }
    }, []);

    const validationSchema = Yup.object({
        name: Yup.string("Enter a name")
                .trim()
                .required("Name is a required field.")
                .max(255, "Name has a max limit of 255 characters."),
        phoneNumber: Yup.string("Enter a Phone Number")
                .matches( /^[0-9]{10}$/, 'Phone Number must be a 10 digit number' )
                .trim(),
        address: (addressIsAlternate || addressIsRequired) ?
                Yup.string("Enter an address")
                .trim()
                :
                Yup.string("Enter an address")
                .trim()
                .required("Address is a required field."),
        houseNumber: (addressIsAlternate && !addressIsRequired) ?
                Yup.string("Enter a street address")
                .trim()
                .required("House Number is a required field.")
                .max(10, "House Number has a max limit of 10 characters.")
                .matches( /^[^#]+$/, 'Number sign character is not allowed')
                :
                Yup.string("Enter a street address")
                .trim(),
		houseNumberExtension:
                Yup.string("Enter a street address")
                .trim()
                .max(4, "House Number has a max limit of 4 characters."),
        prefixDirection: 
                Yup.string("Enter a prefix direction")
                .trim(),
        street: (addressIsAlternate && !addressIsRequired) ?
                Yup.string("Enter a street address")
                .trim()
                .required("Street Name is a required field.")
                .max(60, "Street Name has a max limit of 60 characters.")
                :
                Yup.string("Enter a street address")
                .trim(),
        streetType: Yup.string("Enter a street type")
                .trim().max(4, "Street Type has a max limit of 4 characters."),
        postDirection: Yup.string("Enter a post direction").trim(),
        city: (addressIsAlternate && !addressIsRequired) ?
                Yup.string("Enter a city")
                .trim()
                .required("City is a required field.")
                .max(32, "City has a max limit of 32 characters.")
                :
                Yup.string("Enter a city")
                .trim(),
        stateProvince: (addressIsAlternate && !addressIsRequired) ?
                Yup.string("Select a state/province")
                .trim()
                .required("State/Province is a required field.")
                :
                Yup.string("Select a state/province")
                .trim(),
        zip: (addressIsAlternate && !addressIsRequired) ?
                Yup.string("Enter a ZIP/Postal Code")
                .trim()
                .when("country", {
                        is: "US",
                        then: Yup.string().max(5, "ZIP/Postal Code has a max limit of 5 characters.")
                })
                .when("country", {
                        is: "CA",
                        then: Yup.string().max(7, "ZIP/Postal Code has a max limit of 7 characters.")
                })
                .required('ZIP/Postal code is a required field.')
                .transform(value => value.toUpperCase())
                .when('country', (country, schema) => {
                        return schema.matches(
                                zipRegex[country],
                                `Not a valid ZIP/Postal Code.`
                        );
                })
                :
                Yup.string("Enter a ZIP/Postal Code")
                .trim(),
        country: (addressIsAlternate && !addressIsRequired) ? 
                Yup.string("Select a country")
                .trim()
                .required("Country is a required field.")
                :
                Yup.string("Select a country")
                .trim(),
        latitude: geoCordsAreRequired ?
                Yup.string("Enter a latitude")
                        .trim()
                        .matches(/^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/, 'Enter a latitude with a max precision of 7 digits') :
                Yup.string("Enter a latitude")
                        .trim()
                        .matches(/^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/, 'Enter a latitude with a max precision of 7 digits')
                        .required("Latitude is a required field."),
        longitude: geoCordsAreRequired ?
                Yup.string("Enter a longitude")
                        .trim()
                        .matches(/^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/, 'Enter a longitude with a max precision of 7 digits') :
                Yup.string("Enter a longitude")
                        .trim()
                        .matches(/^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/, 'Enter a longitude with a max precision of 7 digits')
                        .required("Longitude is a required field."),
        info: props.company.toJS().requireLocationInfo ?
            Yup.string("Enter location information")
                .trim()
                .required("Info is a required field.")
                .max(60, "Location info has a max limit of 60 characters.")
                :
            Yup.string("Enter location information")
                .trim()
                .max(60, "Location info has a max limit of 60 characters."),
        multipleAddressSelect: formRef.current && formRef.current.values.multipleAddressSelect === "" && addressIsAmbiguous ?
                Yup.string()
                .required()
                :
                Yup.string()
    })
    
    return(
        <div>
            {errorDisplay && <ModalErrorMessage modalErrorMessage={errorMessage}/> }
            {modalLoading && <LinearProgress/>}
            <Formik
                innerRef={formRef}
                initialValues={{
                    houseNumber: '',
                    houseNumberExtension: '',
                    prefixDirection: '',
                    street: '',
                    streetType: '',
                    postDirection: '',
                    city: '',
                    stateProvince: '',
                    zip: '', 
                    name: '',
                    phoneNumber: '',
                    info: '',
                    address: '',
                    latitude: '',
                    longitude: '',
                    supplemental: '',
                    multipleAddressSelect: '',
                    country: 'US'
                }}
                validationSchema={validationSchema}
                onSubmit = {
                    (values) => {
                        values.simple = true;
                        values.organizationUnitId = props.companyId;

                        if (typeof values.multipleAddressSelect === 'string' && values.multipleAddressSelect.length >= 1) {
                            values.multipleAddressSelect = JSON.parse(values.multipleAddressSelect);
                        }
                        handleFormSubmit(values);
                    }
                }
                render={renderProps => <AddLocationForm 
                    {...props} 
                    {...renderProps}
                    addressAndGeoCoordsToggle={addressAndGeoCoordsToggle}
                    setAddressAndGeoCoordsToggle={setAddressAndGeoCoordsToggle}
                    addressIsRequired={addressIsRequired}
                    setAddressIsRequired={setAddressIsRequired}
                    geoCordsAreRequired={geoCordsAreRequired}
                    setGeoCordsAreRequired={setGeoCordsAreRequired} 
                    addressIsAlternate={addressIsAlternate}
                    addressIsAmbiguous={addressIsAmbiguous} 
                    setAddressIsAlternate={setAddressIsAlternate}
                    multipleAddressList={multipleAddressList}
                    errorMessage={errorMessage} 
                     />}
            />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
    company: selectUserCompany()
});

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(AddLocationModal);