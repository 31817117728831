import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import history from '../../../utils/history';

import './SecurityCodeSuccessModal.css'

export default function EmailNotFoundModal(props) {
    const handleClose = (value) => {
        props.setEmailNotFoundModal(false);
        history.push('/');
    };

    return (
        <Dialog 
            onClose={handleClose} 
            open={props.openEmailNotFound}
            disableEscapeKeyDown
            disableBackdropClick
            className="logout-confirmation-modal"
        >
            <DialogTitle id="logout-modal-title">Invalid Email</DialogTitle> 
            <p>The email address that was provided is no longer valid. Please contact Support. You will now be taken back to the Login page.</p>
            <div className="logout-modal-wrapper">
                <Button className="button" variant="contained" color="primary" onClick={handleClose}>
                    Close
                </Button>
            </div>
        </Dialog>
    );
}