import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import AlertsAPI from '../../../../apis/alertsApi';
import { actions as AppActions } from '../../../../reducers/app';
import { actions as AlertActions, types as AlertTypes, makeSelectAlertTemplatesList } from '../../../../reducers/alerts';
import { currentCompanyLevel } from '../../../../reducers/auth';
import { createStructuredSelector } from 'reselect';

import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';

import ModalWrapper from '../../../../components/ModalWrapper';
import { connect } from 'react-redux';

const validationSchema=Yup.object().shape({
    alertType: Yup.object().shape({
      key: Yup.string("Select a alert type").required("Alert type is a required field."),
      value: Yup.string("Select a alert type").required("Alert type is a required field."),
    })
})

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    paper: {
        position: 'absolute',
        zIndex: 999,
        marginTop: theme.spacing.unit,
    }
});

const SelectAlertsTemplatesForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        currentLevel,
        classes } = props;
    
    const [alertTypes, setAlertTypes] = useState([]);
    
    useEffect(() => {
        AlertsAPI.getAlertTypes(currentLevel !== 'SYSTEM' ? 'ORGANIZATION' : undefined).then(
            response => {
                // response from api is Java Map<String,String> -> JS Object of Keys
                let alertTypeList = [];
                for(var key in response.data) {
                    const value = response.data[key];
                    alertTypeList.push({ 
                        key, value
                    });
                }
                setAlertTypes(alertTypeList);
            }
        )
    }, []);

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            <div style={{ marginTop: '20px' }}>
                <InputLabel shrink={true} htmlFor="alert-label" error={touched.alertType && Boolean(errors.alertType ? errors.alertType.value :  "")}>*Alert/Email Type</InputLabel>
                <Select
                    onBlur={handleBlur}
                    id="alertType"
                    name="alertType"
                    value={values.alertType ? values.alertType : ''}
                    onChange={handleChange}
                    disabled={modalLoading}
                    input={<Input name="alert" id="alert-label"/>}
                    fullWidth={true}
                    error={touched.alertType && Boolean(errors.alertType)}>
                    {   
                        alertTypes.map( (alertType, index) => 
                            <MenuItem key={index} value={alertType}>{alertType.value}</MenuItem>)
                    }
                </Select>
                <span id="alertType-helper-text" className="error-prompt">{touched.alertType ? errors.alertType ? errors.alertType.value :  "" : ""}</span>
            </div>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

const SelectAlertsTemplatesModal = props => {
    const { modalLoading, classes } = props;

	return(
        <Formik
            initialValues={{ 
                alertType: {}
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    if((values.alertType.key === 'MYE911_USER_WELCOME_EMAIL' 
                        && props.alertTemplatesList.some(at => at.alertType.name === 'MYE911_USER_WELCOME_EMAIL' && !at.standard)) || (values.alertType.key === 'ORG_ADMIN_WELCOME_EMAIL' 
                        && props.alertTemplatesList.some(at => at.alertType.name === 'ORG_ADMIN_WELCOME_EMAIL' && !at.standard))
                    ){
                        props.setCurrentModal(AlertTypes.EMAIL_TEMPLATE_ERROR, values.alertType.value);
                    }else{
                        props.submitSelectAlertType(values.alertType);
                        props.setCurrentModal(AlertTypes.ADD_ALERT_TEMPLATE);
                    }
                }
            }
			render={formikProps => <SelectAlertsTemplatesForm 
										classes={classes} 
										modalLoading={modalLoading}
                                        currentLevel={props.currentLevel}
										{...formikProps} />}
        />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitSelectAlertType: (alertType) => dispatch(AlertActions.selectAlertType(alertType)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
    }
}

const mapStateToProps = createStructuredSelector({
    alertTemplatesList: makeSelectAlertTemplatesList(),
    currentLevel: currentCompanyLevel()
});

export default compose(
    connect(mapStateToProps,mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(SelectAlertsTemplatesModal);