import { call, put, takeLatest, all, select, takeEvery } from 'redux-saga/effects';
import LocationsAPI from '../apis/locationsApi';
import BuildingsAPI from '../apis/buildingsApi';
import { actions as AppActions, selectPageSize, selectCurrentPage, selectSearchTerm } from '../reducers/app';
import { actions as LocationsActions,
		 types as LocationsTypes, selectAddressIsAmbiguous, selectAddressIsAlternate } from '../reducers/locations';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';
import { selectCerEnabled, selectTableProps } from '../reducers/app';

function* getLocationsList(action) {
	try {
		const response = yield call(LocationsAPI.getLocations, action.organizationUnitId, action.page, action.pageSize, action.sortBy, action.direction, action.searchTerm, action.origin === 'MANAGER_DATA_SYNC' ? 'MANAGER_DATA_SYNC' : action.origin === 'CER' ? 'CER' : 'default');

		yield put(LocationsActions.getLocationsSuccess(response.data, action.organizationUnitId, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(LocationsActions.getLocationsError(action.orgUnitId));
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getBuildingsList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(BuildingsAPI.getBuildings, companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction, action.origin === 'MANAGER_DATA_SYNC' ? 'MANAGER_DATA_SYNC' : 'default');
		yield put(LocationsActions.getBuildingsSuccess(response.data, response.headers['x-pagination-count']));	
	} catch (error) {
		yield put(LocationsActions.getBuildingsError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addBuilding(action) {
	try {
		yield put(AppActions.setModalErrorClear());
		const tableProps = yield select(selectTableProps());
		const companyId = yield select(userCompanyId());
		const isAmbiguous = yield select(selectAddressIsAmbiguous());
		const isAlternate = yield select(selectAddressIsAlternate());
		
		yield put(AppActions.setModalLoading(true));
		if (action.data) {
			action.data.parentId = companyId;
		}
		if (!isAmbiguous) {
			if (isAlternate) {
				const response = yield call(BuildingsAPI.createBuildingAlternate, action.data);
				if (response.data.ambiguous) {
					yield put(LocationsActions.addressIsAmbiguous(true));
					yield put(LocationsActions.multipleAddressRequest(response.data.addressList));
					yield put(AppActions.setModalLoading(false));
				} else{
				yield put(AppActions.setModalClose());
				yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
				}
			} else {
				const response = yield call(BuildingsAPI.addBuilding, action.data);
				if (response.status === 204) {
					yield put(AppActions.setModalError('Invalid address. Please try updating your address.'));
				} else if (response.data.ambiguous) {
					yield put(LocationsActions.addressIsAmbiguous(true));
					yield put(LocationsActions.multipleAddressRequest(response.data.addressList));
					yield put(AppActions.setModalLoading(false));
				} else {
					yield put(AppActions.setModalClose());
					yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
				}
			}
		} else {
			yield call(BuildingsAPI.createBuildingParsed, action.data);
			yield put(LocationsActions.addressIsAmbiguous(false));
			yield put(AppActions.setModalClose());
			yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
		}
	} catch (error) {
		console.log(error);
		let options = {};
		// first check field line errors
		if(error && error.response && error.response.data.type === "http://cirrus.redskytech.com/errors/building/unique") {
		  options.backendFieldError = {
			name: error.response.data.title
		  };
		  error.response.data.errorOptions = options;
		  yield put(AppActions.setModalErrorOptions(errorHandler(error)));
		} else {
			yield put(AppActions.setModalError(errorHandler(error)));
		}
	}
}

function* editBuilding(action) {
	try {
		yield put(AppActions.setModalErrorClear());
		const tableProps = yield select(selectTableProps());
		const isAmbiguous = yield select(selectAddressIsAmbiguous());
		const isAlternate = yield select(selectAddressIsAlternate());
		yield put(AppActions.setModalLoading(true));
		if (!isAmbiguous) {
			if (isAlternate) {
				const response = yield call(BuildingsAPI.editBuildingAlternate, action.data);
				if (response.data.ambiguous) {
					yield put(LocationsActions.addressIsAmbiguous(true));
					yield put(LocationsActions.multipleAddressRequest(response.data.addressList));
					yield put(AppActions.setModalLoading(false));
				} else{
					yield put(AppActions.setModalClose());
					yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
				}
			}
			else {
				const response = yield call(BuildingsAPI.editBuilding, action.data);
				if (response.status === 204) {
					yield put(AppActions.setModalError('Invalid address. Please try updating your address.'));
				} else if (response.data.ambiguous) {
					yield put(LocationsActions.addressIsAmbiguous(true));
					yield put(LocationsActions.multipleAddressRequest(response.data.addressList));
					yield put(AppActions.setModalLoading(false));
				} else{
					yield put(AppActions.setModalClose());
					yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
				}
			}
		} else {
			yield call(BuildingsAPI.editBuildingParsed, action.data);
			yield put(LocationsActions.addressIsAmbiguous(false));
			yield put(AppActions.setModalClose());
			yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
		}
	} catch (error) {
		console.log(error);
		let options = {};
		// first check field line errors
		if(error && error.response && error.response.data.type === "http://cirrus.redskytech.com/errors/building/unique") {
		  options.backendFieldError = {
			name: error.response.data.title
		  };
		  error.response.data.errorOptions = options;
		  yield put(AppActions.setModalErrorOptions(errorHandler(error)));
		} else {
			yield put(AppActions.setModalError(errorHandler(error)));
		}
	}
}

function* deleteBuilding(action) {
	try {
		const searchTerm = yield select(selectSearchTerm());
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(BuildingsAPI.deleteBuilding, action.data);
		yield put(AppActions.setModalClose());
		
		if (searchTerm) {
			yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, searchTerm, tableProps.sortBy, tableProps.direction));
		} else {
			if (action.origin === 'MANAGER_DATA_SYNC') {
				yield put(LocationsActions.getDataSyncBuildingsRequest(tableProps.page, tableProps.pageSize));
			} else {
				yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
			}
		}
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* addLocation(action) {
	try {
		yield put(AppActions.setModalErrorClear());
		const isAmbiguous = yield select(selectAddressIsAmbiguous());
		const isAlternate = yield select(selectAddressIsAlternate());
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading(true));

		if (!isAmbiguous) {
			if (isAlternate) {
				const response = yield call(LocationsAPI.createLocationAlternate, action.data);
				if (response.data.ambiguous) {
					yield put(LocationsActions.addressIsAmbiguous(true));
					yield put(LocationsActions.multipleAddressRequest(response.data.addressList));
					yield put(AppActions.setModalLoading(false));
				}
				else {
					yield put(AppActions.setModalClose());
					yield put(LocationsActions.getLocationsRequest(action.data.organizationUnitId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
				}
			} else {
				let response = yield call(LocationsAPI.addLocation, action.data);
				if (action.isSimple && response.status === 204) {
					yield put(AppActions.setModalError('Invalid address. Please try updating your address.'));
				} else if (response.data.ambiguous) {
					yield put(LocationsActions.addressIsAmbiguous(true));
					//yield put(AppActions.setModalError('Multiple addresses found. Please select the appropriate one.', response.data.addressList));
					yield put(LocationsActions.multipleAddressRequest(response.data.addressList));
					yield put(AppActions.setModalLoading(false));

				} else {
					yield put(AppActions.setModalClose());
					yield put(LocationsActions.getLocationsRequest(action.data.organizationUnitId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
						
				}
			}
		} else {
			yield call(LocationsAPI.createLocationParsed, action.data);
			yield put(LocationsActions.addressIsAmbiguous(false));
			yield put(AppActions.setModalClose());
			yield put(LocationsActions.getLocationsRequest(action.data.organizationUnitId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
		}
	} catch (error) {
		console.log(error);
		let options = {};
		// first check field line errors
		if(error && error.response && error.response.data.type === "http://cirrus.redskytech.com/alternateId/nonUniqueAlternateId") {
		  options.backendFieldError = {
			alternateId: error.response.data.title
		  };
		  error.response.data.errorOptions = options;
		  yield put(AppActions.setModalErrorOptions(errorHandler(error)));
		} else {
			yield put(AppActions.setModalError(errorHandler(error)));
		}
	}
}

function* editLocation(action) {
	const companyId = yield select(userCompanyId());
	const tableProps = yield select(selectTableProps());

	try {
		yield put(AppActions.setModalErrorClear());
		const isAmbiguous = yield select(selectAddressIsAmbiguous());
		const isAlternate = yield select(selectAddressIsAlternate());
		yield put(AppActions.setModalLoading(true));

		if (!isAmbiguous) {
			if (isAlternate) {
				const response = yield call(LocationsAPI.editLocationAlternate, action.data);
				if (response.data.ambiguous) {
					yield put(LocationsActions.addressIsAmbiguous(true));
					yield put(LocationsActions.multipleAddressRequest(response.data.addressList));
					yield put(AppActions.setModalLoading(false));
				} 
				else {
					yield put(AppActions.setModalClose());
					yield put(LocationsActions.getLocationsRequest(action.buildingFlag || action.buildingFlag === undefined ? action.data.parentId : companyId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
				}
			} else {
				let response = yield call(LocationsAPI.editLocation, action.data);
				
				if (response.data.ambiguous) {
					yield put(LocationsActions.addressIsAmbiguous(true));
					yield put(LocationsActions.multipleAddressRequest(response.data.addressList));
					yield put(AppActions.setModalLoading(false));
				} else {
					yield put(AppActions.setModalClose());
					if (action.origin && action.origin === "CER")
						yield put(LocationsActions.getLocationsRequest(action.buildingFlag || action.buildingFlag === undefined ? action.data.parentId : companyId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
					else
						yield put(LocationsActions.getLocationsRequest(action.buildingFlag || action.buildingFlag === undefined ? action.data.parentId : companyId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
				}
			}
		} else {
			yield call(LocationsAPI.editLocationParsed, action.data);
			yield put(LocationsActions.addressIsAmbiguous(false));
			yield put(AppActions.setModalClose());
			yield put(LocationsActions.getLocationsRequest(action.buildingFlag || action.buildingFlag === undefined ? action.data.parentId : companyId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
		}
	} catch (error) {
		console.log(error);
		let options = {};
		// first check field line errors
		if(error.response.data.type === "http://cirrus.redskytech.com/alternateId/nonUniqueAlternateId") {
		  options.backendFieldError = {
			alternateId: error.response.data.title
		  };
		  error.response.data.errorOptions = options;
		  yield put(AppActions.setModalErrorOptions(errorHandler(error)));
		} else {
			yield put(AppActions.setModalError(errorHandler(error)));
		}
	}
}

function* deleteLocation(action) {
	try {
		const searchTerm = yield select(selectSearchTerm());
		const tableProps = yield select(selectTableProps());
		const companyId = yield select(userCompanyId());

		yield put(AppActions.setModalLoading());
		yield call(LocationsAPI.deleteLocation, action.data);
		yield put(AppActions.setModalClose());

		if (searchTerm) {
			if(action.origin === 'MANAGER_DATA_SYNC') {
				if (action.buildingFlag)
					yield put(LocationsActions.getDataSyncLocationsRequest(action.parentId))
				else
					yield put(LocationsActions.getLocationsRequest(companyId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction))
			}
			yield put(LocationsActions.getBuildingsRequest(tableProps.page, tableProps.pageSize, searchTerm, tableProps.sortBy, tableProps.direction));
		} else {
			if(action.origin === 'MANAGER_DATA_SYNC') {
				if (action.buildingFlag)
					yield put(LocationsActions.getDataSyncLocationsRequest(action.parentId))
				else
					yield put(LocationsActions.getLocationsRequest(companyId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction))
			}
			else {
				if (action.origin && action.origin === "CER")
					yield put(LocationsActions.getLocationsRequest(companyId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
				else	
					yield put(LocationsActions.getLocationsRequest(action.buildingFlag || action.buildingFlag === undefined ? action.parentId : companyId, action.origin, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
			}
		}
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rescanAddressBuilding(action) {
	try {
		const companyId = yield select(userCompanyId());

		yield put(AppActions.setModalLoading());
		yield call(BuildingsAPI.rescanAddress, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(LocationsActions.getBuildingsRequest());
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rescanAddressLocation(action) {
	try {
		const companyId = yield select(userCompanyId());

		yield put(AppActions.setModalLoading());
		yield call(BuildingsAPI.rescanAddress, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(LocationsActions.getLocationsRequest(companyId));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getLocationUserCount(action) {
	try {
		yield put(AppActions.setModalLoading());
		const response = yield call(LocationsAPI.getLocationUserCount, action.data);
		yield put(LocationsActions.getLocationUserCountSuccess(response.data));
		yield put(AppActions.setModalLoading());
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getLocationCount(action) {
	try {
		yield put(AppActions.setModalLoading());
		const response = yield call(LocationsAPI.getLocationCount, action.data);
		yield put(LocationsActions.getLocationCountSuccess(response.data));
		yield put(AppActions.setModalLoading());
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}


function* rootLocationsSaga() {
	yield all([
		yield takeEvery(LocationsTypes.GET_LOCATIONS_REQUEST, getLocationsList),
		yield takeEvery(LocationsTypes.GET_BUILDINGS_REQUEST, getBuildingsList),
		yield takeLatest(LocationsTypes.ADD_BUILDING, addBuilding),
		yield takeLatest(LocationsTypes.EDIT_BUILDING, editBuilding),
		yield takeLatest(LocationsTypes.DELETE_BUILDING, deleteBuilding),
		yield takeLatest(LocationsTypes.ADD_LOCATION, addLocation),
		yield takeLatest(LocationsTypes.DELETE_LOCATION, deleteLocation),
		yield takeLatest(LocationsTypes.RESCAN_ADDRESS_BUILDING, rescanAddressBuilding),
		yield takeLatest(LocationsTypes.RESCAN_ADDRESS_LOCATION, rescanAddressLocation),
		yield takeEvery(LocationsTypes.GET_LOCATION_USER_COUNT, getLocationUserCount),
		yield takeEvery(LocationsTypes.GET_LOCATION_COUNT, getLocationCount),
		yield takeLatest(LocationsTypes.EDIT_LOCATION, editLocation),
	]);
}

export default rootLocationsSaga;
