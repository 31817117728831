import React, { useState ,useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import moment from 'moment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import { actions as EventsActions, makeSelectEventsPageCount } from '../../reducers/events';
import { actions as AppActions, types as AppTypes } from '../../reducers/app';
import { types } from '../../reducers/events';
import EventsAPI from '../../apis/eventsApi';
import UsersAPI from '../../apis/usersApi';
import { createStructuredSelector } from 'reselect';
import { currentCompanyLevel, currentCompanyOrgType } from '../../reducers/auth';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './eventsListTable.css';
import eventsPage from '../EventsPage/eventsPage';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
};

const initialSortColumns = [
    {
        id: 'eventTime',
        desc:true
    }
]

function EventsListTable(props) {
    const [startDate, setStartDate] = useState(moment().subtract(3,'days').startOf('day'));
    const [endDate, setEndDate] = useState(moment().endOf('day'));
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectedEventType, setSelectedEventType] = useState([]);
    const [selectedRoleType, setSelectedRoleType] = useState([]);
    const [eventType, setEventType] = useState([]);
    const [roleType, setRoleType] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
  

    const handleEventChange = (event) => {
        setSelectedEventType(event.target.value);

        props.fetchEventsList(pageIndex, pageSize, startDate, endDate, event.target.value, selectedRoleType, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
    }
    
    const handleRoleChange = (event) => {
        setSelectedRoleType(event.target.value);

        props.fetchEventsList(pageIndex, pageSize, startDate, endDate, selectedEventType, event.target.value, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
    }
    

    const buildTableColumnsSystemLevel = [
        {
            Header: "Date/Time",
            id: "eventTime",
            sortable: true,
            Cell: row => <div title={formatLastAccess(row.original.eventTime)}>{formatLastAccess(row.original.eventTime)}</div>
        },
        {
            Header: "Type",
            id: "type",
            Cell: row => <div title={row.original.eventType.name}>{row.original.eventType.name}</div>
        },
        {
            Header: "Username",
            id: "username",
            Cell: row => <div title={row.original.userEmail}>{row.original.userEmail}</div>
        },
        {
            Header: "Organization",
            id: "organization",
            Cell: row => <div title={row.original.organizationName}>{row.original.organizationName}</div>
        },
        {
            Header: "Description",
            id: "description",
            Cell: row => {
                const handleClick = () => {
                    props.setCurrentModal(types.EVENTS_DESCRIPTION, {
                        row: row.original
                    });
                }
                
                if (row.original.description) {
                    return <div className="description-wrapper" title={row.original.description}>{row.original.description}{row.original.description.length >= 30 ? 
                                <span 
                                    tabIndex={0}
                                    className={`more-link more-link-${row.index}`} 
                                    onClick={() => handleClick()}
                                    onKeyDown={e => {
                                        if ((e.key === ' ' || e.key === 'Enter') && document.activeElement.tagName !== 'BUTTON') {
                                            e.preventDefault();
            
                                            const expandElm = document.querySelectorAll('.more-link')[row.index];
            
                                            if (expandElm) {
                                                expandElm.click();
                                            }
                                        }
                                    }}
                                    >
                                        ...more
                                </span> : ''}
                            </div> 
                } else {
                    return null;
                }
            }
        }
    ];
    
    const buildTableColumnsCompanyLevel = [
        {
            Header: "Date/Time",
            id: "eventTime",
            sortable: true,
            Cell: row => <div title={formatLastAccess(row.original.eventTime)}>{formatLastAccess(row.original.eventTime)}</div>
        },
        {
            Header: "Type",
            id: "type",
            Cell: row => <div title={row.original.eventType.name}>{row.original.eventType.name}</div>
        },
        {
            Header: "Username",
            id: "username",
            Cell: row => <div title={row.original.userEmail}>{row.original.userEmail}</div>
        },
        {
            Header: "Description",
            id: "description",
            Cell: row => {
                const handleClick = () => {
                    props.setCurrentModal(types.EVENTS_DESCRIPTION, {
                        row: row.original
                    });
                }
                
                if (row.original.description) {
                    return <div className="description-wrapper" title={row.original.description}>{row.original.description}{row.original.description.length >= 30 ? 
                                <span 
                                    tabIndex={0}
                                    className={`more-link more-link-${row.index}`} 
                                    onClick={() => handleClick()}
                                    onKeyDown={e => {
                                        if ((e.key === ' ' || e.key === 'Enter') && document.activeElement.tagName !== 'BUTTON') {
                                            e.preventDefault();
            
                                            const expandElm = document.querySelectorAll('.more-link')[row.index];
            
                                            if (expandElm) {
                                                expandElm.click();
                                            }
                                        }
                                    }}
                                    >
                                    ...more
                                </span> : ''}
                            </div> 
                } else {
                    return null;
                }
            }
        }
    ];

    function formatLastAccess(lastAccess) {
        return lastAccess ?  moment(lastAccess).format('MM/DD/YYYY hh:mm:ss A') : '';
    }

    useEffect(() => {
        EventsAPI.getEventTypes().then(response => {
            setEventType(response.data);
        });

        UsersAPI.getRoles(props.currentCompanyOrgType === "SYSTEM" ? "ALL" : props.currentCompanyOrgType).then(response => {
            setRoleType(response.data);
        });
    }, []);
    
    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    const {
        loading, events, fetchEventsList, eventsPageCount
    } = props;
    let eventsArray = [];

    if (events) {
        eventsArray = events.toArray();
    }

    useEffect(() => {
        const datePicker = document.querySelector('.DateRangePicker');
        const closeOnEsc = e => {
          if (e.key === 'Escape' || e.key === 'Backspace') {
            setFocusedInput(null);
          }
        }
    
        datePicker.addEventListener('keydown', closeOnEsc);
    
        return () => datePicker.removeEventListener('keydown', closeOnEsc);
    }, []);

    useEffect( () => {
        fetchEventsList(pageIndex+1, pageSize, startDate, endDate, selectedEventType, selectedRoleType, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
    }, [currentSortColumn, pageSize, pageIndex]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <div>
            <DateRangePicker
                readOnly={true}
                startDate={startDate}
                startDateId="start_date_id"
                endDate={endDate}
                endDateId="end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                    if (startDate) {
                        startDate.startOf('day')
                    }
                    if (endDate) {
                        endDate.endOf('day')
                    }

                    setStartDate(startDate);
                    setEndDate(endDate);
                    
                    fetchEventsList(pageIndex, pageSize, startDate, endDate, selectedEventType, selectedRoleType, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
                }}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} 
                isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, 'days'))}
            />
            <FormControl className="event-type">
                <InputLabel id="event-type-filter" htmlFor="select-multiple-chip">Event Type</InputLabel>
                <Select
                    multiple
                    value={selectedEventType}
                    onChange={handleEventChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className="chip-wrapper">
                        {selected.map(value => (
                            <Chip key={value} label={value} className="event-type-chip"/>
                        ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                    >
                    {eventType.map(event => (
                        <MenuItem key={event} value={event}>
                        {event}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className="role-type">
                <InputLabel id="role-type-filter" htmlFor="select-multiple-chip">Role</InputLabel>
                <Select
                    multiple
                    value={selectedRoleType}
                    onChange={handleRoleChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className="chip-wrapper">
                        {selected.map(value => (
                            <Chip key={value} label={value} className="role-type-chip"/>
                        ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                    >
                    {roleType.map(role => (
                        <MenuItem key={role.name} value={role.name}>
                        {role.displayName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight events"
                showPaginationBottom={true}
                data={eventsArray}
                minRows={eventsArray && eventsArray.length > 0 ? 0 : 5}
                pages={eventsPageCount <= 0 ? 1 : eventsPageCount}
                columns={props.currentCompanyLevel === AppTypes.CURRENT_COMPANY_LEVEL_SYSTEM ? buildTableColumnsSystemLevel : buildTableColumnsCompanyLevel}
                defaultPageSize={25}
                page= {pageIndex && eventsPageCount > 0 && pageIndex + 1 <= eventsPageCount ? pageIndex : 0 }
                manual
                loading = {loading}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                currentSortColumn={currentSortColumn}
                setCurrentSortColumn={setCurrentSortColumn} 
                sorted={sortColumns}
                onSortedChange={(newSorted, column, shiftKey) => {
                    let sortedArray = [...sortColumns];
                    let currentSortColumn;
                    sortedArray.forEach( (columnObject, index) => {                    
                        if(column.id === columnObject.id) {
                            // state needs updated object reference, doesn't detect nested fields
                            currentSortColumn = {...columnObject};
                            currentSortColumn.desc = !currentSortColumn.desc;
                            sortedArray[index] = currentSortColumn;
                        }
                        else {
                            let resetColumn = {...columnObject};
                            resetColumn.desc = true;
                            sortedArray[index] = resetColumn;
                        }
                        setCurrentSortColumn(currentSortColumn);
                        setSortColumns(sortedArray);
                    })
                }}
                onPageSizeChange={(pageSize) => {
                    setPageSize(pageSize);
                    setPageIndex(0);
                    resetScrollInsideTable();
                }}
                onPageChange={(pageIndex) => {
                    setPageIndex(pageIndex);
                    resetScrollInsideTable();
                }} 

                LoadingComponent={ReactTableLoadingComponent}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'events-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                }
            />
        </div>
    );
}

EventsListTable.propTypes = {
    events: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
    eventsPageCount: makeSelectEventsPageCount(),
    currentCompanyLevel: currentCompanyLevel(),
    currentCompanyOrgType: currentCompanyOrgType()
});


const mapDispatchToProps = dispatch => {
    return {
        fetchEventsList: (page, pageSize, startDate, endDate, eventType, userRole, sortBy, sortDirection) => dispatch(EventsActions.getEventsRequest(page, pageSize, startDate, endDate, eventType, userRole, sortBy, sortDirection)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsListTable);


