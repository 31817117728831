import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CompanyAPI from '../../../../../apis/companyApi';

function OrgSyncSelect(props) {
  const [parentOrgDataLoaded, setParentOrgDataLoaded] = useState(false);

    useEffect(() => {
      CompanyAPI.getOrganizationByOrgType(1, 500, null, null, 'name', 'ASC' ,
        props.orgTypesToSearch ? 
        props.orgTypesToSearch : ['SERVICE_PROVIDER', 'RESELLER'])
      .then(response => {
        response.data.forEach(obj => {   
          var orgType
          if(obj.orgType == 'SERVICE_PROVIDER'){
            orgType = 'Service Provider'
          }else if (obj.orgType == 'RESELLER'){
            orgType = 'Reseller / Business Partner'
          }else{
            orgType = ''
          }
          obj.value = obj.id;
          obj.label = obj.name + ' (' + orgType + ')';
        });
  
        setParentOrgDataLoaded(true);
        props.setOptions(response.data);
      }).catch(error => {
        setParentOrgDataLoaded(true);
        console.log(error);
      });
    }, []);

    function onChange(value) {
      props.setOrganizationByOrgType(value);
    }

    return (
      <Select
          placeholder=""
          className={props.error ? 'single-select error' : 'single-select'}
          inputId={props.id}
          isDisabled={!parentOrgDataLoaded}
          value={props.organizationByOrgType ? props.organizationByOrgType : props.options.find(o => o.id === props.modalData.parentId)}
          onChange={onChange}
          closeMenuOnSelect={props.closeMenuOnSelect}
          options={props.options}
          isMulti={props.isMulti}
          theme={(theme) => ({
            ...theme,
            colors: {
            ...theme.colors,
              primary25: 'rgba(0, 0, 0, 0.1)',
              primary: 'rgba(0, 0, 0, 0.2)',
            },
          })}  
      />
    );
}

export default OrgSyncSelect;