import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types } from '../../reducers/reports';

class ReportsEditMenu extends Component {
	optionsStandard =
		[
			'Run and Download Now',
			'Schedule Report'
		];

	state = {
		anchorEl: null,
	};

	ITEM_HEIGHT = 48;

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleMenuItemClick = (option) => {
		this.handleClose();
		switch (option) {
			case 'Run and Download Now':
				this.props.setCurrentModal(types.GENERATE_REPORT, this.props.row.original);
				break;
			case 'Schedule Report':
				this.props.setCurrentModal(types.SCHEDULE_REPORT, this.props.row.original);
				break;
			default:
				return null;
		}
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div>
				<IconButton
					id={`reports-action-menu-button-${this.props.row.index}`}
					aria-label="More"
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id={`reports-action-menu-${this.props.row.index}`}
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
					PaperProps={{
						style: {
							maxHeight: this.ITEM_HEIGHT * 6.5,
							minWidth: 200,
						},
					}}
				>
					{	this.optionsStandard.map((option, index) => (
							<MenuItem id={`reports-item-${this.props.row.index}-${index}`} key={option} selected={option === 'Pyxis'} onClick={event => this.handleMenuItemClick(option, event)}>
								{option}
							</MenuItem>
						))
					}
				</Menu>
			</div>
		);
	}
}

ReportsEditMenu.propTypes = {
	row: PropTypes.object,
	setCurrentModal: PropTypes.func
};


const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default connect(null, mapDispatchToProps)(ReportsEditMenu);
