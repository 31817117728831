import { axiosInstance } from './api';

/* Cirrus Admin-Service /networking-discovery Service */
const EMERGENCY_CALL_ASSIST_ENDPOINT = 'geography-service/call-assist-location';

export default class EmergencyCallAssistAPI {
    static emergencyCallAssist(ecaCall, addressIsAmbiguous, addressIsAlternate) {
        const hasAddress = ecaCall.address || 
                            ecaCall.houseNumber || 
                            ecaCall.houseNumberExtension ||
                            ecaCall.street ||
                            ecaCall.streetType ||
                            ecaCall.prefixDirection ||
                            ecaCall.postDirection ||
                            ecaCall.zip || 
                            ecaCall.city || 
                            ecaCall.stateProvince;

        const payload = addressIsAmbiguous || addressIsAlternate ? {
            name: ecaCall.name,
            info: ecaCall.info,
            organizationId: ecaCall.companyId,
            callbackNumber: ecaCall.callbackNumber,
            address: {
                parsedAddress: {
                    houseNumber: addressIsAmbiguous ? ecaCall.address.houseNumber : ecaCall.houseNumber,
                    houseNumberExtension: addressIsAmbiguous ? ecaCall.address.houseNumberExtension : ecaCall.houseNumberExtension,
                    streetName: addressIsAmbiguous ? ecaCall.address.streetName : ecaCall.street,
                    streetType: addressIsAmbiguous ? ecaCall.address.streetType : ecaCall.streetType,
                    postDirectional: addressIsAmbiguous ? ecaCall.address.postDirectional : ecaCall.postDirection,
                    prefixDirectional: addressIsAmbiguous ? ecaCall.address.prefixDirectional : ecaCall.prefixDirection,
                    zipCode: addressIsAmbiguous ? ecaCall.address.zipCode : ecaCall.zip,
                    city: addressIsAmbiguous ? ecaCall.address.city : ecaCall.city,
                    state: addressIsAmbiguous ? ecaCall.address.state : ecaCall.stateProvince,
                    country: addressIsAmbiguous ? ecaCall.address.country : ecaCall.country
                }
            },
            coordinates: hasAddress ? null : {
                latitude: ecaCall.latitude,
                longitude: ecaCall.longitude
            },    
            callSettings: {
                agentInfo: ecaCall.agentInfo,
                callType: ecaCall.callType,
                routeType: ecaCall.routeType
            }
        } : {
            name: ecaCall.name,
            info: ecaCall.info,
            organizationId: ecaCall.companyId,
            callbackNumber: ecaCall.callbackNumber,
            address: ecaCall.address.normalizedAddress ? {
                singleLineAddress: ecaCall.address.normalizedAddress
            } : {
                singleLineAddress: ecaCall.address
            },
            coordinates: hasAddress ? null : {
                latitude: ecaCall.latitude,
                longitude: ecaCall.longitude
            },    
            callSettings: {
                agentInfo: ecaCall.agentInfo,
                callType: ecaCall.callType,
                routeType: ecaCall.routeType
            }
        };

        return axiosInstance.post(EMERGENCY_CALL_ASSIST_ENDPOINT, payload);
    }
}
