import React, {useEffect} from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import AlertsTemplatesEditMenu from '../../containers/AlertsTemplatesEditMenu/alertsTemplatesEditMenu';
import {createStructuredSelector} from "reselect";
import {
    actions as AlertsActions,
    makeSelectAlertTemplatesList,
    makeSelectAlertTemplatesPageCount
} from "../../reducers/alerts";
import {actions as AppActions} from "../../reducers/app";
import {connect} from "react-redux";

function AlertsTemplatesListTable(props) {
    const {
        loading,
        sortColumns,
        setSortColumns,
        currentSortColumn,
        setCurrentSortColumn,
        fetchAlertsTemplatesList,
        page,
        setPage,
        pageSize,
        alertTemplatesList,
        alertTemplatesPageCount
    } = props;

    const buildTableColumns = [
        {
            Header: "Template Name",
            id: "name",
            sortable: true,
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: "Alert / Email Type",
            id: "alert-type",
            Cell: row => <div title={row.original.alertType.text}>{row.original.alertType.text}</div>
        },
        {
            Header: "Type",
            id: "type",
            Cell: row => (
                <div>
                    {row.original.standard ? <div title="Standard">Standard</div> : <div title="User-Defined">User-Defined</div>}
                </div>
            )
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                 <AlertsTemplatesEditMenu row={row} setCurrentModal={props.setCurrentModal} />
            )
        }
    ];

    let alertsTemplatesArray = [];
    
    if (alertTemplatesList) {
        alertsTemplatesArray = alertTemplatesList.toArray();
    }

    useEffect(() => {
        if (alertsTemplatesArray && alertsTemplatesArray.length === 0 && page > 0 && alertTemplatesPageCount < page+1) {
            setPage(page-1)
        }
    }, [alertsTemplatesArray && alertsTemplatesArray.length === 0 && page > 0 && alertTemplatesPageCount < page+1]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    useEffect( () => {
        fetchAlertsTemplatesList(props.page+1, props.pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: props.page+1,
            pageSize: props.pageSize,
            sortBy: currentSortColumn.id,
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, props.pageSize, props.page]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight"
            showPaginationBottom={true}
            data={alertsTemplatesArray}
            minRows={alertsTemplatesArray.length > 0 ? 0 : 5}
            pages={alertTemplatesPageCount <= 0 ? 1 : alertTemplatesPageCount}
            page={props.page}
            defaultPageSize={25}
            columns={buildTableColumns}
            manual
            loading = {loading}
            onFetchData={(state, instance) => {
                props.setPageSize(state.pageSize);
                props.setCurrentPage(state.page+1);
                resetScrollInsideTable();
            }}
            onPageSizeChange={(pageSize) => {
                props.setPageSize(pageSize)
                props.setPage(0);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setCurrentPage(pageIndex + 1);
                props.setPage(pageIndex);
                resetScrollInsideTable();
            }}
            LoadingComponent={ReactTableLoadingComponent}
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
            }}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo) {
                        return {
                            id: 'alerts-templates-row-' + rowInfo.index,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    alertTemplatesList: makeSelectAlertTemplatesList(),
    alertTemplatesPageCount: makeSelectAlertTemplatesPageCount(),
});

const mapDispatchToProps = dispatch => {
    return {
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchAlertsTemplatesList: (page, pageSize, sortBy, direction) => dispatch(AlertsActions.getAlertTemplatesRequest(page, pageSize, sortBy, direction)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsTemplatesListTable);