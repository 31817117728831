import React, { Component } from 'react';
import Button from '@material-ui/core/Button'; 
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/apiAccessKey';
import { connect } from 'react-redux';
import {createStructuredSelector} from "reselect";
import {userCompanyId} from "../../../reducers/auth";



class DeleteApiKeyModal extends Component {
    
    render(props) {
        const { modalLoading, companyId} = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                        Please confirm that you would like to delete the API Access Key:
                        </p>
                    </Grid>
                    <Grid item >
                    Description: <b id="apikeyDescription">{this.props.modalData.description}</b>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={(values) => {
                                    values.companyId = companyId;
                                    values.apiKey = this.props.modalData.id;
                                    this.props.submitDeleteApiKey(values);
                                }}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
});

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteApiKey: (values) => dispatch(actions.deleteApiAccessKeyRequest(values))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper
)(DeleteApiKeyModal);