import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSagas';

import reducer from '../../reducers/networkDiscovery';
import { actions as AppActions } from '../../reducers/app';
import saga from '../../sagas/networkDiscovery';

import { compose } from 'redux';
import { connect } from 'react-redux';

import MacList from '../../containers/MacList/macList';
import BssidList from '../../containers/BssidList/bssidList';
import IpRangeList from '../../containers/IpRangeList/ipRangeList';
import LldpList from '../../containers/LldpList/lldpList';

const styles = theme => ({
  tabs: {
    justifyContent: 'center',
    indicator: 'green'
  },
});

function NetworkDiscoveryPage(props) {
    const storedNetDiscoTab = parseInt(sessionStorage.getItem('networkDiscoveryTab'));
    
    const [networkDiscoveryTab, setNetworkDiscoveryTab] = useState(storedNetDiscoTab ? storedNetDiscoTab : 0);

    const handleChange = (event, value) => {
        setNetworkDiscoveryTab(value);
    };

    useEffect(() => {
        props.setPageTitle('Network Discovery');
        props.setPageTitleId('network-discovery-header');
    }, []);
    
    useEffect(() => {
        switch (networkDiscoveryTab) {
            case 0:
                return sessionStorage.setItem('networkDiscoveryTab', 0);
            case 1:
                return sessionStorage.setItem('networkDiscoveryTab', 1);
            case 2:
                return sessionStorage.setItem('networkDiscoveryTab', 2);
            case 3:
                return sessionStorage.setItem('networkDiscoveryTab', 3);
            default:
                break;
        }
    }, [networkDiscoveryTab]);
    
    return (
        <div>
            <AppBar position="static" color="default">
                <Tabs className="tabs-bar"
                    value={networkDiscoveryTab} 
                    onChange={handleChange} 
                    indicatorColor="primary"
                    variant="fullWidth"
                >
                    <Tab id="mac-header" label="MAC" />
                    <Tab id="lldp-header" label="LLDP" />
                    <Tab id="bssid-header" label="BSSID" />
                    <Tab id="ip-ranges-header" label="IP Ranges" />
                </Tabs>
            </AppBar>
            <br />
            {networkDiscoveryTab === 0 && <MacList setCurrentModal={props.setCurrentModal}/>}
            {networkDiscoveryTab === 1 && <LldpList setCurrentModal={props.setCurrentModal}/>}
            {networkDiscoveryTab === 2 && <BssidList setCurrentModal={props.setCurrentModal}/>}
            {networkDiscoveryTab === 3 && <IpRangeList setCurrentModal={props.setCurrentModal} isPublic={false}/>}
            <br/>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return { 
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'networkDiscovery', reducer });
const withSaga = injectSaga({ key: 'networkDiscovery', saga });
const withConnect = connect(null, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withStyles(styles),
    withConnect
)(NetworkDiscoveryPage);