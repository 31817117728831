import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import DeviceUsersAPI from '../apis/deviceUsersApi';
import { actions as AppActions, selectPageSize, selectCurrentPage, selectTableProps } from '../reducers/app';
import { userCompanyId } from '../reducers/auth';
import { actions as DeviceUsersActions,
		 types as DeviceUsersTypes } from '../reducers/deviceUsers';
import LocationsAPI from '../apis/locationsApi';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';
import AlertsAPI from '../apis/alertsApi';

function* getDeviceUsersList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(DeviceUsersAPI.getDeviceUsers, companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction);
		yield put(DeviceUsersActions.getDeviceUserSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(DeviceUsersActions.getDeviceUsersError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getDeviceUsersDevicesList(action) {
	try {
		const response = yield call(DeviceUsersAPI.getDeviceUsersDevices, action.deviceUserId, action.page, action.pageSize, action.sortBy, action.direction);
		yield put(DeviceUsersActions.getDeviceUserDevicesSuccess(response.data, action.deviceUserId, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(DeviceUsersActions.getDeviceUsersDevicesError(action.deviceUserId));
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* deleteDeviceUserDevices(action) {
	try {
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(DeviceUsersAPI.deleteDeviceUserDevices, action.data);
		yield put(AppActions.setModalClose());
		yield put(DeviceUsersActions.getDeviceUsersDevicesRequest(action.userId, tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getLocationsByDeviceUserId(action) {
	try {
		const response = yield call(LocationsAPI.getLocationsByDeviceUserId, action.deviceUserId, action.page, action.pageSize, action.sortBy, action.direction);
		yield put(DeviceUsersActions.getLocationsSuccess(response.data, action.deviceUserId, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(DeviceUsersActions.getLocationsError(action.deviceUserId));
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addDeviceUser(action) {
	try {
		const tableProps = yield select(selectTableProps());
		const companyId = yield select(userCompanyId());
		action.data.companyId = companyId;

		if (!action.data.enableHeldPlusFunctionality && !action.data.enableMyE911Functionality) {
			yield put(AppActions.setModalError('You must select one of the functionalities below and provide the required information before saving.'));
		} else {
			delete action.data.enableHeldPlusFunctionality;
			delete action.data.enableMyE911Functionality;
			
			yield put(AppActions.setModalLoading());
			yield call(DeviceUsersAPI.addDeviceUser, action.data);
			yield put(AppActions.setModalClose());
			yield put(DeviceUsersActions.getDeviceUsersRequest(tableProps.page, tableProps.pageSize, null, "email", tableProps.direction));
		}
	} catch (error) {
		console.log(error);
		let options = {};
		// first check field line errors
		if(error && error.response && error.response.data.type === "http://cirrus.redskytech.com/errors/deviceUser/phone/duplicate") {
		  options.backendFieldError = {
			name: 'The Phone Number entered is not unique. Please enter a new value and try again.'
		  };
		  error.response.data.errorOptions = options;
		  yield put(AppActions.setModalErrorOptions(errorHandler(error)));
		} else {
			yield put(AppActions.setModalError(errorHandler(error)));
		}
	}
}

function* editDeviceUser(action) {
	try {
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(DeviceUsersAPI.editDeviceUser, action.data);
		yield put(AppActions.setModalClose());
		yield put(DeviceUsersActions.getDeviceUsersRequest(tableProps.page, tableProps.pageSize, null, "email", tableProps.direction));
	} catch (error) {
		console.log(error);
		let options = {};
		// first check field line errors
		if(error && error.response && error.response.data.type === "http://cirrus.redskytech.com/errors/deviceUser/phone/duplicate") {
		  options.backendFieldError = {
			name: 'The Phone Number entered is not unique. Please enter a new value and try again.'
		  };
		  error.response.data.errorOptions = options;
		  yield put(AppActions.setModalErrorOptions(errorHandler(error)));
		} else {
			yield put(AppActions.setModalError(errorHandler(error)));
		}
	}
}

function* deleteDeviceUser(action) {
	try {
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(DeviceUsersAPI.deleteDeviceUser, action.data);
		yield put(AppActions.setModalClose());
		yield put(DeviceUsersActions.getDeviceUsersRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteLocation(action) {
	try { 
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(LocationsAPI.deleteLocation, action.locationId);
		yield put(AppActions.setModalClose());
		yield put(DeviceUsersActions.getLocationsRequest(action.deviceUserId, tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* resendMyE911UserWelcomEmail(action) {
	try{
		yield call(AlertsAPI.sendEmail, action.alertType, action.recipient);
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}


function* rootLocationsSaga() {
	yield all([
		yield takeLatest(DeviceUsersTypes.GET_LOCATIONS_REQUEST, getLocationsByDeviceUserId),
		yield takeLatest(DeviceUsersTypes.GET_DEVICE_USERS_DEVICES_REQUEST, getDeviceUsersDevicesList),
		yield takeLatest(DeviceUsersTypes.DELETE_DEVICE_USER_DEVICE, deleteDeviceUserDevices),
		yield takeLatest(DeviceUsersTypes.GET_DEVICE_USERS_REQUEST, getDeviceUsersList),
		yield takeLatest(DeviceUsersTypes.ADD_DEVICE_USER, addDeviceUser),
		yield takeLatest(DeviceUsersTypes.EDIT_DEVICE_USER, editDeviceUser),
		yield takeLatest(DeviceUsersTypes.DELETE_DEVICE_USER, deleteDeviceUser),
		yield takeLatest(DeviceUsersTypes.DELETE_LOCATION, deleteLocation),
		yield takeLatest(DeviceUsersTypes.RESEND_MYE911_USER_WELCOME_EMAIL_REQUEST, resendMyE911UserWelcomEmail)
	]);
}

export default rootLocationsSaga;
