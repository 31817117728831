import React, { useEffect } from 'react';
import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import reducer, { types as importTypes, actions as ImportingActions } from '../../reducers/importing';
import saga from '../../sagas/importing';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as AppActions } from '../../reducers/app';
import ImportingTable from '../../containers/ImportingTable/importingTable';
import { currentColumn } from '../../containers/ImportingTable/importingTable';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

function ImportingPage(props) {
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));

    const isAddNetworkDiscovery = user && user.permissions.includes('ADD_NETWORK_DISCOVERY');
    const isEditNetworkDiscovery = user && user.permissions.includes('EDIT_NETWORK_DISCOVERY');

    const isAddLocation = user && user.permissions.includes('ADD_LOCATIONS');
    const isEditLocation = user && user.permissions.includes('EDIT_LOCATIONS');

    const isAddDeviceUsers = user && user.permissions.includes('ADD_DEVICE_USERS');
    const isEditDeviceUsers = user && user.permissions.includes('EDIT_DEVICE_USERS');

    const isAddPidfloDevices = user && user.permissions.includes('ADD_PIDFLO_DEVICES');
    const isEditPidfloDevices = user && user.permissions.includes('EDIT_PIDFLO_DEVICES');

    const isVisible = isAddNetworkDiscovery || isEditNetworkDiscovery || isAddLocation || isEditLocation || isAddDeviceUsers || isEditDeviceUsers || isAddPidfloDevices || isEditPidfloDevices;

    useEffect(() => {
        let importPageInterval;
        if (props.history.location.pathname === '/importing') {
            importPageInterval = setInterval(() => {
                let direction = currentColumn().data[0].desc ? 'DESC': 'ASC'
                let pageIndex = currentColumn().data[0].page ? currentColumn().data[0].page : 0
                let pageSize = currentColumn().data[0].pageSize ? currentColumn().data[0].pageSize : 25
                let sortBy = currentColumn().data[0].sortBy ? currentColumn().data[0].sortBy : 'created'
                props.fetchImportingList(pageIndex, pageSize, sortBy, direction);
            }, 10000);
        }
        return () => { clearInterval(importPageInterval) };
    },[]);

    useEffect(() => {
        props.setPageTitle('Importing');
        props.setPageTitleId('importing-header');
    }, []);

    function handleImportModal() {
        props.setCurrentModal(importTypes.ADD_IMPORT, currentColumn().data[0]);
    }
    
    function handleTemplateFilesModal() {
        props.setCurrentModal(importTypes.TEMPLATE_FILES_IMPORTING);
    }

    return (
        <div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                {isVisible && <Button style={{ marginRight: '20px' }} className="button" id="template-files-button" variant="contained" color="primary" onClick={handleTemplateFilesModal}>Template Files</Button>}
                {isVisible && <Button className="button" id="add-import-button" variant="contained" color="primary" onClick={handleImportModal}>Add Import</Button>}
            </Grid>
            <br/>
            <ImportingTable setCurrentModal={props.setCurrentModal} />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return { 
        fetchImportingList: (pageIndex, pageSize, sortBy, direction) => dispatch(ImportingActions.getImportingRequest(pageIndex, pageSize, sortBy, direction)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'importing', reducer });
const withSaga = injectSaga({ key: 'importing', saga });
const withConnect = connect(null, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(ImportingPage);