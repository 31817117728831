export const states = [
    { value: 'AL' },
    { value: 'AK' },
    { value: 'AS' },
    { value: 'AZ' },
    { value: 'AR' },
    { value: 'CA' },
    { value: 'CO' },
    { value: 'CT' },
    { value: 'DE' },
    { value: 'DC' },
    { value: 'FM' },
    { value: 'FL' },
    { value: 'GA' },
    { value: 'GU' },
    { value: 'HI' },
    { value: 'ID' },
    { value: 'IL' },
    { value: 'IN' },
    { value: 'IA' },
    { value: 'KS' },
    { value: 'KY' },
    { value: 'LA' },
    { value: 'ME' },
    { value: 'MH' },
    { value: 'MD' },
    { value: 'MA' },
    { value: 'MI' },
    { value: 'MN' },
    { value: 'MS' },
    { value: 'MO' },
    { value: 'MT' },
    { value: 'NE' },
    { value: 'NV' },
    { value: 'NH' },
    { value: 'NJ' },
    { value: 'NM' },
    { value: 'NY' },
    { value: 'NC' },
    { value: 'ND' },
    { value: 'MP' },
    { value: 'OH' },
    { value: 'OK' },
    { value: 'OR' },
    { value: 'PW' },
    { value: 'PA' },
    { value: 'PR' },
    { value: 'RI' },
    { value: 'SC' },
    { value: 'SD' },
    { value: 'TN' },
    { value: 'TX' },
    { value: 'UT' },
    { value: 'VT' },
    { value: 'VI' },
    { value: 'VA' },
    { value: 'WA' },
    { value: 'WV' },
    { value: 'WI' },
    { value: 'WY' }
];

export const provinces = [
    { value: 'AB' },
    { value: 'BC' },
    { value: 'MB' },
    { value: 'NB' },
    { value: 'NL' },
    { value: 'NT' },
    { value: 'NS' },
    { value: 'NU' },
    { value: 'ON' },
    { value: 'PE' },
    { value: 'QC' },
    { value: 'SK' },
    { value: 'YT' }
];

export const countries = [
    { value: 'US' },
    { value: 'CA' }
];

export const direction = [
    { value: '' },
    { value: 'N' },
    { value: 'E' },
    { value: 'S' },
    { value: 'W' },
    { value: 'NE' },
    { value: 'NW' },
    { value: 'SE' },
    { value: 'SW' }
];