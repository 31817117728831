import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import IpRangeEditMenu from '../../containers/IpRangeEditMenu/ipRangeEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import { makeSelectIpRangePageCount, actions as IpRangeActions } from "../../reducers/networkDiscovery";
import { createStructuredSelector } from "reselect";
import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { connect } from "react-redux";
import {debounce} from "lodash";


const initialSortColumns = [
    {
        id: 'location',
        desc:false
    },
    {
        id: 'description',
        desc:false
    }
]

function IpRangeListTable(props) {
    const { searchValue } = props;
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    const [pageIndex, setPageIndex] = useState(0);

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isEdit = user && user.permissions.includes('EDIT_NETWORK_DISCOVERY');
	const isDelete = user && user.permissions.includes('DELETE_NETWORK_DISCOVERY');
    const isView = isEdit || isDelete;

    function getGeoCoordsNetDisco(row) {
        if ((row.original.location) && (row.original.location.normalizedGeoCoords) && (row.original.location.normalizedGeoCoords.latitude) && (row.original.location.normalizedGeoCoords.longitude)) {
            return <div title={`${row.original.location.normalizedGeoCoords.latitude} , ${row.original.location.normalizedGeoCoords.longitude}`}>{`${row.original.location.normalizedGeoCoords.latitude} , ${row.original.location.normalizedGeoCoords.longitude}`}</div>;
        } else {
            return <div></div>;
        }
    }

    const buildTableColumns = [
        {
            Header: "Range Start",
            id: "lower",
            Cell: row => <div title={row.original.ipAddressLow}>{row.original.ipAddressLow}</div>
        },
        {
            Header: "Range End",
            id: "upper",
            Cell: row => <div title={row.original.ipAddressHigh}>{row.original.ipAddressHigh}</div>
        },
        {
            Header: "Location",
            id: "location",
            sortable: true,
            Cell: row => <div title={`${row.original.location ? row.original.location.name : ''} ${(row.original.location && row.original.location.address) ? row.original.location.address.normalizedAddress : ''}`}>{row.original.location ? row.original.location.name : ''}<br/>{(row.original.location && row.original.location.address) ? row.original.location.address.normalizedAddress : ''}</div>
        },
        {
            Header: <div id="location-coordinates">Geodetic Coordinates</div>,
            id: "coordinates",
            Cell: row => getGeoCoordsNetDisco(row)
        },
        {
            Header: "Description",
            id: "descriptionPrivate",
            Cell: row => <div title={row.original.description}>{row.original.description}</div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                isView ? <IpRangeEditMenu row={row} setCurrentModal={props.setCurrentModal} /> : null
            )
        }
    ];
    
   const buildTableColumnsIsPublic = [
        {
            Header: "Range Start",
            id: "lower",
            Cell: row => <div title={row.original.ipAddressLow}>{row.original.ipAddressLow}</div>,
        },
        {
            Header: "Range End",
            id: "upper",
            Cell: row => <div title={row.original.ipAddressHigh}>{row.original.ipAddressHigh}</div>
        },
        {
            Header: "Description",
            id: "description",
            sortable: true,
            Cell: row => <div title={row.original.description}>{row.original.description}</div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                isView ? <IpRangeEditMenu row={row} setCurrentModal={props.setCurrentModal} isPublic={props.isPublic} /> : null
            )
        }
    ];

    const {
        loading, ipRangeList, ipRangePageCount, getIpRangeRequest
    } = props;
    let ipRangeListArray = [];

    if (ipRangeList) {
        ipRangeListArray = ipRangeList.toArray();
    }

    useEffect(() => {
        if (ipRangeListArray && ipRangeListArray.length === 0 && pageIndex > 0 && ipRangePageCount < pageIndex+1) {
            setPageIndex(pageIndex-1)
        }
    }, [ipRangeListArray && ipRangeListArray.length === 0 && pageIndex > 0 && ipRangePageCount < pageIndex+1]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    useEffect( () => {
        if (props.pageSize) {
            if (props.isPublic) {
                currentSortColumn.id = "description"
            } else {
                currentSortColumn.id = "location"
            }
            getIpRangeRequest(pageIndex+1, props.pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC', props.isPublic);
            props.setTableProps({
                page: pageIndex+1, 
                pageSize: props.pageSize, 
                sortBy: currentSortColumn.id, 
                direction: currentSortColumn.desc ? 'DESC': 'ASC'
            });
        }
    }, [currentSortColumn, props.pageSize, pageIndex, props.isPublic]);

    useEffect(() => {
        const debouncedGetIpRangeRequest = debounce(
            () => {
                if (searchValue.length > 0) {
                    setPageIndex(0);
                    getIpRangeRequest(pageIndex + 1, props.pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC' : 'ASC', props.isPublic, searchValue);
                    props.setTableProps({
                        page: pageIndex + 1,
                        pageSize: props.pageSize,
                        sortBy: currentSortColumn.id,
                        direction: currentSortColumn.desc ? 'DESC' : 'ASC',
                        searchValue: searchValue
                    });
                } else if (searchValue.length === 0) {
                    setPageIndex(0);
                    getIpRangeRequest(pageIndex + 1, props.pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC' : 'ASC', props.isPublic);
                    props.setTableProps({
                        page: pageIndex + 1,
                        pageSize: props.pageSize,
                        sortBy: currentSortColumn.id,
                        direction: currentSortColumn.desc ? 'DESC' : 'ASC'
                    });
                }
            },500);

        debouncedGetIpRangeRequest();

        return () => {
            debouncedGetIpRangeRequest.cancel();
        };
    }, [searchValue]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight"
            showPaginationBottom={true}
            data={ipRangeListArray}
            page={pageIndex}
            minRows={ipRangeListArray && ipRangeListArray.length > 0 ? 0 : 5}
            pages={ipRangePageCount <= 0 ? 1 : ipRangePageCount}
            columns={props.isPublic ? buildTableColumnsIsPublic : buildTableColumns}
            LoadingComponent={ReactTableLoadingComponent}

            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
                
            }}
            onPageSizeChange={pageSize => {
                props.setPageSize(pageSize);
                setPageIndex(0);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setCurrentPage(pageIndex + 1);
                setPageIndex(pageIndex);
                resetScrollInsideTable();
            }}
            defaultPageSize={25}
            manual
            ipRangeList={ipRangeList}
            loading={loading}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo && rowInfo.original.location && rowInfo.original.location.address && !rowInfo.original.location.address.normalizedAddress) {
                            return {
                                id: 'ipRange-row-' + rowInfo.index,
                                style: {
                                    background: 'rgba(255, 70, 71, 0.45)',
                                    opacity: '.8',
                                    border: '1px solid red'
                                }
                            };
                        }
                        else {
                            return {
                                id: rowInfo ? 'ipRange-row-' + rowInfo.index : '',
                            };
                        }
                    }
            }
        />
    );
}

IpRangeListTable.propTypes = {
    ipRangeList: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired,
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    ipRangePageCount: makeSelectIpRangePageCount(),
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return {
        getIpRangeRequest: (page, pageSize, sortBy, direction, isPublic, searchTerm) => dispatch(IpRangeActions.getIpRangeRequest(page, pageSize, null, sortBy, direction, isPublic, searchTerm)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IpRangeListTable);
