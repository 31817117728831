import React, { useState, useEffect, useCallback } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import { compose } from "redux";

import injectReducer from '../../utils/injectReducer';
import reducer from '../../reducers/users';
import injectSaga from '../../utils/injectSagas';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';
import saga from '../../sagas/users';
import { checkCustomPermission } from '../../containers/App/routeAndNavData';
import { selectUser } from '../../reducers/auth';

import { types } from '../../reducers/users';
import { actions as appActions, selectPageSize } from '../../reducers/app';

import { createStructuredSelector } from "reselect";
import UsersTable from "../UsersTable/usersTable";

import { actions as UserActions } from '../../reducers/users';
import { debounce } from 'lodash';

const initialSortColumns = [
  {
      id: 'email',
      desc:false
  }
]

const UsersPage = props => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [sortColumns, setSortColumns] = useState(initialSortColumns);
  const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

  function modalHandleOpen() {
    props.setCurrentModal(types.ADD_USER);
  }

  function handleInputSearchChange(e) {
    const inputValue = e.target.value;
    const isMatch = searchFieldRegex(inputValue);
    
    if (isMatch) {
      setSearchValue(inputValue);
      searchApiCall(
        inputValue, 
        props.pageSize, 
        currentSortColumn.id, 
        currentSortColumn.desc ? 'DESC': 'ASC'
      );
    }
  }

  const searchApiCall = useCallback(
    debounce((inputValue, pageSize, sortBy, sortDirection) => {
      if (inputValue.length > 0) {
        setPage(0);
        props.fetchUsersList(1, pageSize, inputValue, sortBy, sortDirection);
      } else if (inputValue.length === 0) {
        setPage(0);
        props.fetchUsersList(1, pageSize, "", sortBy, sortDirection);
      }
    }, 500)
, []);

  useEffect(() => {
    props.setPageTitle('Administrators');
    props.setPageTitleId('administrators-header');
  }, []);

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <SearchField 
            value={searchValue}
            onChange={handleInputSearchChange}
          />
        </Grid>

        {checkCustomPermission('CUSTOM_ADD_ADMIN', props.user) && (
          <Grid item>
              {   searchValue.length === 0 &&
                  <Button className="button" id="add-administrators-button" variant="contained" color="primary" onClick={modalHandleOpen}>Add Administrators</Button>
              }
          </Grid>
        )}
      </Grid>
      <br />

      <UsersTable
        fetchUsersList={props.fetchUsersList}
        setCurrentModal={props.setCurrentModal}
        page={page}
        setPage={setPage}
        pageSize={props.pageSize}
        sortColumns={sortColumns}
        setSortColumns={setSortColumns}
        currentSortColumn={currentSortColumn}
        setCurrentSortColumn={setCurrentSortColumn}
        searchValue={searchValue}
        user={props.user}
        checkCustomPermission={checkCustomPermission}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentModal: currentModal => dispatch(appActions.setCurrentModal(currentModal)),
    fetchUsersList: (page, pageSize, searchTerm, sortBy, sortDirection) => dispatch(UserActions.getUsersRequest(page, pageSize, searchTerm, sortBy, sortDirection)),
    setPageTitle: data => dispatch(appActions.setPageTitle(data)),
    setPageTitleId: data => dispatch(appActions.setPageTitleId(data))
  };
};

const mapStateToProps = createStructuredSelector({
  pageSize: selectPageSize(),
  user: selectUser()
});

const withReducer = injectReducer({ key: "users", reducer });
const withSaga = injectSaga({ key: "users", saga });
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withReducer,
  withSaga,
  withConnect
)(UsersPage);
