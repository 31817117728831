/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import {fromJS, List} from 'immutable';
import {createSelector} from 'reselect';

export const ALERTS = 'ALERTS/'; 
export const ALERT_SUBSCRIPTIONS = ALERTS + 'SUBSCRIPTIONS/';
export const ALERT_TEMPLATES = ALERTS + 'TEMPLATES/';
export const types = {
    ADD_ALERT_SUBSCRIPTION: ALERT_SUBSCRIPTIONS + 'ADD_ALERT_SUBSCRIPTION',
    ADD_ALERT_SUBSCRIPTION_REQUEST: ALERT_SUBSCRIPTIONS + 'ADD_ALERT_SUBSCRIPTION_REQUEST',
    ADD_ALERT_SUBSCRIPTION_SUCCESS: ALERT_SUBSCRIPTIONS + 'ADD_ALERT_SUBSCRIPTION_SUCCESS',
    GET_ALERT_SUBSCRIPTIONS_REQUEST: ALERT_SUBSCRIPTIONS + 'GET_ALERT_SUBSCRIPTIONS_REQUEST',
    GET_ALERT_SUBSCRIPTIONS_SUCCESS: ALERT_SUBSCRIPTIONS + 'GET_ALERT_SUBSCRIPTIONS_SUCCESS',
    GET_ALERT_SUBSCRIPTIONS_FILTERS_REQUEST: ALERT_SUBSCRIPTIONS + 'GET_ALERT_SUBSCRIPTIONS_FILTERS_REQUEST',
    GET_ALERT_SUBSCRIPTIONS_FILTERS_SUCCESS: ALERT_SUBSCRIPTIONS + 'GET_ALERT_SUBSCRIPTIONS_FILTERS_SUCCESS',
    GET_ALERT_SUBSCRIPTIONS_FILTERS_ERROR: ALERT_SUBSCRIPTIONS + 'GET_ALERT_SUBSCRIPTIONS_FILTERS_ERROR',
    EDIT_ALERT_SUBSCRIPTION: ALERT_SUBSCRIPTIONS + 'EDIT_ALERT_SUBSCRIPTION',
    EDIT_ALERT_SUBSCRIPTION_SUCCESS: ALERT_SUBSCRIPTIONS + 'EDIT_ALERT_SUBSCRIPTION_SUCCESS',
    DELETE_ALERT_SUBSCRIPTION: ALERT_SUBSCRIPTIONS + 'DELETE_ALERT_SUBSCRIPTION',
    DELETE_ALERT_SUBSCRIPTION_SUCCESS: ALERT_SUBSCRIPTIONS + 'DELETE_ALERT_SUBSCRIPTION_SUCCESS',
    TEST_ALERT_SUBSCRIPTION_REQUEST: ALERT_SUBSCRIPTIONS + 'SUBMIT_TEST_ALERT_SUBSCRIPTION_REQUEST',
    TEST_ALERT_SUBSCRIPTION_SUCCESS: ALERT_SUBSCRIPTIONS + 'SUBMIT_TEST_ALERT_SUBSCRIPTION_SUCCESS',
    ADD_ALERT_TEMPLATE: ALERT_TEMPLATES + 'ADD_ALERT_TEMPLATE',
    ADD_ALERT_TEMPLATE_REQUEST: ALERT_TEMPLATES + 'ADD_ALERT_TEMPLATE_REQUEST',
    ADD_ALERT_TEMPLATE_SUCCESS: ALERT_TEMPLATES + 'ADD_ALERT_TEMPLATE_SUCCESS',
    GET_ALERT_TEMPLATES_REQUEST: ALERT_TEMPLATES + 'GET_ALERT_TEMPLATES_REQUEST',
    GET_ALERT_TEMPLATES_SUCCESS: ALERT_TEMPLATES + 'GET_ALERT_TEMPLATES_SUCCESS',
    EDIT_ALERT_TEMPLATE: ALERT_TEMPLATES + 'EDIT_ALERT_TEMPLATE',
    EDIT_ALERT_TEMPLATE_SUCCESS: ALERT_TEMPLATES + 'EDIT_ALERT_TEMPLATE_SUCCESS',
    DELETE_ALERT_TEMPLATE: ALERT_TEMPLATES + 'DELETE_ALERT_TEMPLATE',
    DELETE_ALERT_TEMPLATE_SUCCESS: ALERT_TEMPLATES + 'DELETE_ALERT_TEMPLATE_SUCCESS',
    COPY_ALERT_TEMPLATE: ALERT_TEMPLATES + 'COPY_ALERT_TEMPLATE',
    SELECT_ALERT_TEMPLATE: ALERT_TEMPLATES + 'SELECT_ALERT_TEMPLATE',
    SELECT_ALERT_TYPE: ALERT_TEMPLATES + 'SELECT_ALERT_TYPE',
    PREVIEW_ALERT_TEMPLATE: ALERT_TEMPLATES + 'PREVIEW_ALERT_TEMPLATE',
    PREVIEW_ALERT_TEMPLATE_SUCCESS: ALERT_TEMPLATES + 'PREVIEW_ALERT_TEMPLATE_SUCCESS',
    ADD_TEMPLATE_FLAG: ALERT_TEMPLATES + 'ADD_TEMPLATE_FLAG',
    ALERT_SUBSCRIPTION_RECIPIENTS: ALERT_SUBSCRIPTIONS + 'ALERT_SUBSCRIPTION_RECIPIENTS',
    GET_SUBSCRIPTION_ERROR: ALERT_SUBSCRIPTIONS + 'GET_SUBSCRIPTION_ERROR',
    GET_TEMPLATE_ERROR: ALERT_TEMPLATES + 'GET_TEMPLATE_ERROR',
    EMAIL_TEMPLATE_ERROR: ALERT_TEMPLATES + 'EMAIL_TEMPLATE_ERROR'
};

export const initialState = fromJS({
    alertSubscriptionsList: List(),
    alertSubscriptionsListPageCount: -1,
    alertSubscriptionsLoading: false,
    testAlertSubscriptionLoading: false,
    alertTemplatesList: List(),
    alertTemplatesListPageCount: -1,
    alertTemplatesLoading: false,
    previewAlertTemplate: '',
    selectAlertType: null,
    alertsSubscriptionsFilterLoading: false,
    alertsSubscriptionsFilterList: List(),
    alertsSubscriptionsFilterPageCount: -1,
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_ALERT_SUBSCRIPTIONS_REQUEST:
            return state.set('alertSubscriptionsLoading', true);
        case types.GET_ALERT_SUBSCRIPTIONS_SUCCESS:
            return state.set('alertSubscriptionsLoading', false)
                        .set('alertSubscriptionsList', List(action.data))
                        .set('alertSubscriptionsListPageCount', action.pageCount);
        case types.GET_SUBSCRIPTION_ERROR:
            return state.set('alertSubscriptionsLoading', false)
        case types.GET_ALERT_SUBSCRIPTIONS_FILTERS_REQUEST:
            return state.set('alertsSubscriptionsFilterLoading', true);
        case types.GET_ALERT_SUBSCRIPTIONS_FILTERS_SUCCESS:
            return state.set('alertsSubscriptionsFilterLoading', false)
                .set('alertsSubscriptionsFilterList', List(action.data))
                .set('alertsSubscriptionsFilterPageCount', action.pageCount);
        case types.GET_ALERT_SUBSCRIPTIONS_FILTERS_ERROR:
            return state.set('alertsSubscriptionsFilterLoading', false)
        case types.TEST_ALERT_SUBSCRIPTION_REQUEST:
            return state.set('testAlertSubscriptionLoading', true);
        case types.TEST_ALERT_SUBSCRIPTION_SUCCESS:
            return state.set('testAlertSubscriptionLoading', false);
        case types.GET_ALERT_TEMPLATES_REQUEST:
            return state.set('alertTemplatesLoading', true);
        case types.GET_ALERT_TEMPLATES_SUCCESS:
            return state.set('alertTemplatesLoading', false)
                        .set('alertTemplatesList', List(action.data))
                        .set('alertTemplatesListPageCount', action.pageCount);
        case types.GET_TEMPLATE_ERROR:
            return state.set('alertTemplatesLoading', false)
        case types.PREVIEW_ALERT_TEMPLATE_SUCCESS:
            return state.set('previewAlertTemplate', action.data);
        case types.SELECT_ALERT_TYPE:
            return state.set('selectAlertType', action.data);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    addAlertSubscriptionRequest: (alertSubscription) => ({ 
            type: types.ADD_ALERT_SUBSCRIPTION, 
            data: alertSubscription
    }),
    editAlertSubscriptionRequest: (alertSubscription) => ({
        type: types.EDIT_ALERT_SUBSCRIPTION,
        data: alertSubscription
    }),
    deleteAlertSubscriptionRequest: (id, parentId) => ({
        type: types.DELETE_ALERT_SUBSCRIPTION,
        data: id,
        parentId
    }),
    getAlertSubscriptionsRequest: (page, pageSize, sortBy, direction) => ({
        type: types.GET_ALERT_SUBSCRIPTIONS_REQUEST,
        page,
        pageSize,
        sortBy,
        direction
    }),
    getAlertSubscriptionsError: () => ({
        type: types.GET_SUBSCRIPTION_ERROR
    }),
    getAlertSubscriptionsSuccess: (alertSubscriptionList, pageCount) => ({
        type: types.GET_ALERT_SUBSCRIPTIONS_SUCCESS,
        data: alertSubscriptionList,
        pageCount
    }),
    getAlertSubscriptionsFilterRequest: (subId, page, pageSize) => ({
        type: types.GET_ALERT_SUBSCRIPTIONS_FILTERS_REQUEST,
        subId,
        page,
        pageSize
    }),
    getAlertSubscriptionsFilterError: () => ({
        type: types.GET_ALERT_SUBSCRIPTIONS_FILTERS_ERROR
    }),
    getAlertSubscriptionsFilterSuccess: (alertSubscriptionFilterList, pageCount) => ({
        type: types.GET_ALERT_SUBSCRIPTIONS_FILTERS_SUCCESS,
        data: alertSubscriptionFilterList,
        pageCount
    }),
    testAlertSubscriptionRequest: (alertSubscriptionId) => ({
        type: types.TEST_ALERT_SUBSCRIPTION_REQUEST,
        data: alertSubscriptionId
    }),
    testAlertSubscriptionSuccess: () => ({
        type: types.TEST_ALERT_SUBSCRIPTION_SUCCESS
    }),
    addAlertTemplateRequest: (alertTemplate) => ({ 
            type: types.ADD_ALERT_TEMPLATE, 
            data: alertTemplate
    }),
    editAlertTemplateRequest: (alertTemplate) => ({
        type: types.EDIT_ALERT_TEMPLATE,
        data: alertTemplate
    }),
    deleteAlertTemplateRequest: (id, replacementId) => ({
        type: types.DELETE_ALERT_TEMPLATE,
        id,
        replacementId
    }),
    getAlertTemplatesRequest: (page, pageSize, sortBy, direction) => ({
        type: types.GET_ALERT_TEMPLATES_REQUEST,
        page,
        pageSize,
        sortBy,
        direction
    }),
    getAlertTemplatesSuccess: (alertTemplateList, pageCount) => ({
        type: types.GET_ALERT_TEMPLATES_SUCCESS,
        data: alertTemplateList,
        pageCount
    }),
    getAlertTemplatesError: () => ({
        type: types.GET_TEMPLATE_ERROR
    }),
    previewAlertTemplate: (id) => ({
        type: types.PREVIEW_ALERT_TEMPLATE,
        data: id
    }),
    previewAlertTemplateSuccess: (template) => ({
        type: types.PREVIEW_ALERT_TEMPLATE_SUCCESS,
        data: template
    }),
    copyAlertTemplate: (template) => ({
        type: types.COPY_ALERT_TEMPLATE,
        data: template
    }),
    selectAlertType: (alertType) => ({
        type: types.SELECT_ALERT_TYPE,
        data: alertType
    })
};

// Selectors
const selectAlertSubscriptions = state => state.get('alerts', initialState);

export const makeSelectAlertSubscriptionsList = () =>
    createSelector(selectAlertSubscriptions, alertSubscriptionsState => alertSubscriptionsState.get('alertSubscriptionsList'));

export const makeSelectAlertSubscriptionsListLoading = () =>
    createSelector(selectAlertSubscriptions, alertSubscriptionsState => alertSubscriptionsState.get('alertSubscriptionsLoading'));

export const makeSelectAlertSubscriptionsPageCount = () =>
    createSelector(selectAlertSubscriptions, alertSubscriptionsState => alertSubscriptionsState.get('alertSubscriptionsListPageCount'));

export const makeSelectAlertSubscriptionsFilterList = () =>
    createSelector(selectAlertSubscriptions, alertSubscriptionsState => alertSubscriptionsState.get('alertsSubscriptionsFilterList'));

export const makeSelectAlertSubscriptionsFilterListLoading = () =>
    createSelector(selectAlertSubscriptions, alertSubscriptionsState => alertSubscriptionsState.get('alertsSubscriptionsFilterPageCount'));

export const makeSelectAlertSubscriptionsFilterPageCount = () =>
    createSelector(selectAlertSubscriptions, alertSubscriptionsState => alertSubscriptionsState.get('alertSubscriptionsFilterListPageCount'));

export const makeSelectTestAlertSubscriptionLoading = () =>
    createSelector(selectAlertSubscriptions, alertSubscriptionsState => alertSubscriptionsState.get('testAlertSubscriptionLoading'));

export const makeSelectAlertTemplatesList = () =>
    createSelector(selectAlertSubscriptions, alertTemplatesState => alertTemplatesState.get('alertTemplatesList'));

export const makeSelectAlertTemplatesListLoading = () =>
    createSelector(selectAlertSubscriptions, alertTemplatesState => alertTemplatesState.get('alertTemplatesLoading'));

export const makeSelectAlertTemplatesPageCount = () =>
    createSelector(selectAlertSubscriptions, alertTemplatesState => alertTemplatesState.get('alertTemplatesListPageCount'));

export const makeSelectPreviewAlertTemplate = () =>
    createSelector(selectAlertSubscriptions, alertTemplatesState => alertTemplatesState.get('previewAlertTemplate'));

export const makeSelectAddTemplateFlag = () =>
    createSelector(selectAlertSubscriptions, alertTemplatesState => alertTemplatesState.get('addTemplateFlag'));

export const makeSelectAlertType = () =>
    createSelector(selectAlertSubscriptions, alertTypeState => alertTypeState.get('selectAlertType'));