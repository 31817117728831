import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    actions as AlertsActions,
    makeSelectAlertSubscriptionsList,
    makeSelectAlertSubscriptionsListLoading,
    makeSelectAlertSubscriptionsPageCount
} from '../../reducers/alerts';
import AlertsSubscriptionsListTable from '../../containers/AlertsSubscriptionsListTable/AlertsSubscriptionsListTable';
import {userCompanyId} from '../../reducers/auth';
import EonUsersAPI from '../../apis/eonUsersApi';
import NotificationIntegrationsAPI from '../../apis/notificationIntegrationsApi';
import {selectPageSize} from "../../reducers/app";

const initialSortColumns = [
    {
        id: 'name',
        desc: false
    }
];

function AlertSubscriptionsList(props) {
    const [userList, setUserList] = useState([]);
    const [informacastList, setInformacastList] = useState([]);
    const [page, setPage] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    
    useEffect(() => {
        EonUsersAPI.getAllEonUsers(props.companyId).then(
            (response) => {
                setUserList(response.data);
            }
        );
    }, []);

    useEffect(() => {
        if(props.companyId){
            NotificationIntegrationsAPI.getNotificationIntegrationsList(props.companyId).then(response => {
                setInformacastList(response.data);
            }).catch(error => {
                console.log(error);
            }) 
        }
    }, []);
    
    return <AlertsSubscriptionsListTable
        loading={props.loading}
        informacastList={informacastList}
        userList={userList}
        setUserList={setUserList}
        sortColumns={sortColumns}
        setSortColumns={setSortColumns}
        currentSortColumn={currentSortColumn}
        setCurrentSortColumn={setCurrentSortColumn}
        fetchAlertsSubscriptionsList={props.fetchAlertsSubscriptionsList}
        page={page}
        setPage={setPage}
        pageSize={props.pageSize}
    />
}

const mapStateToProps = createStructuredSelector({
    alertSubscriptionsList: makeSelectAlertSubscriptionsList(),
    alertSubscriptionsListPageCount: makeSelectAlertSubscriptionsPageCount(),
    loading: makeSelectAlertSubscriptionsListLoading(),
    companyId: userCompanyId(),
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchAlertsSubscriptionsList: (page, pageSize, sortBy, direction) => dispatch(AlertsActions.getAlertSubscriptionsRequest(page, pageSize, sortBy, direction))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertSubscriptionsList);