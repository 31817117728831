import React, { useState, useEffect } from 'react';
import { InputLabel, MenuItem, Select} from "@material-ui/core";
import ModalErrorMessage from '../../components/ModalWrapper/ModalErrorMessage';
import BuildingOrLocationAsyncSelect from '../BuildingOrLocationAsyncSelect/BuildingOrLocationAsyncSelect';
import LocationAsyncSelect from '../LocationAsyncSelect/LocationAsyncSelect';
import LocationsAPI from '../../apis/locationsApi';
import BuildingsAPI from '../../apis/buildingsApi';
import { func } from 'prop-types';

function BuildingAndLocationSingleComponent(props) {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        modalLoading,
        setFieldValue,
        setFieldTouched,
        locationEmpty,
        modalData,
        cerEnabled,
        isPublicIpRange,
        setLocationTypeIsCer,
        classes } = props;

    const [locationsLoaded, setLocationsLoaded] = useState(false);
    const [locationsList, setLocationsList] = useState([]);
    const [selectedBuildingId, setSelectedBuildingId] = useState(modalData && modalData.location ? modalData.location.parentId : modalData && modalData.erl ? modalData.erl.parentId  : null);
    const [locationSearchInput, setLocationSearchInput] = useState('');
    const [rerenderBuildingSelect, setRerenderBuildingSelect] = useState(true);

    const isLocations = props.company.toJS().locationTreeType === 'SIMPLE' || values.locationType == 'CER_LOCATIONS';

    function getLocationsByParentId(id, input) {
        setLocationSearchInput(input);
        const getLocPromise = input && props.company.toJS().locationTreeType === 'BUILDING' ?
        BuildingsAPI.getBuildings(id, 1, 100, input, 'name', 'ASC')
        :
        LocationsAPI.getLocations(id, 1, 100, 'name', 'ASC', input);

        const getLocResults = getLocPromise.then(
             (response) => {
                 setLocationsLoaded(true);
 
                 if (response.data) {
                     response.data.forEach(obj => {
                         obj.value = obj.id;
                         obj.label = obj.name;
                     });

                     setLocationsList(response.data);
                     return response.data;   
                 }
 
                 return null;
             }
         ).catch(error => {
             console.log(error);
         });

         return getLocResults;
    }

    const onSelectBuilding = (event) => {
        const selectedBuilding = event;
        if (isLocations) {
            setFieldValue('location', selectedBuilding)
            
        } else {
            setFieldValue('location', '')
            setFieldValue('building', selectedBuilding);
        }
        setLocationsLoaded(false);
        setLocationsList([]);
        getLocationsByParentId(selectedBuilding.id);
        setSelectedBuildingId(selectedBuilding.id);
    }

    const onSelectLocation = (event) => {
        setFieldValue('location', event)
    }

    const onSelectLocationType = (event) => {
        setFieldValue('locationType', event.target.value)
        setFieldValue('location', '');
        setFieldValue('building', '');
    }

    useEffect(() => {
        if (modalData && modalData.location && modalData.location.origin !== 'CER') {
            getLocationsByParentId(modalData.location.parentId);

        }
        else if (modalData && modalData.erl && modalData.erl.origin !== 'CER') {
            getLocationsByParentId(modalData.erl.parentId);
        }
    }, []);

    useEffect(() => {
        setLocationsList([]);
        if(!values.locationType){
            setFieldValue('location', '')
            setFieldValue('building', '')
        }
        if (setLocationTypeIsCer) {
            if (values.locationType === 'CER_LOCATIONS') {
                setLocationTypeIsCer(true);
            } else {
                setLocationTypeIsCer(false);
            }
        }

        setRerenderBuildingSelect(false);
        setTimeout(() => {
            setRerenderBuildingSelect(true);
        }, 500);
    }, [values.locationType]);
    
    return (
        <div>
            {(cerEnabled && !isPublicIpRange) && 
                <div>
                    <InputLabel shrink={true} htmlFor="locationType-label"
                        error={touched.locationType && Boolean(errors.locationType)}>*Location Type</InputLabel>
                    <Select
                            id="locationType"
                            name="locationType"
                            onChange={onSelectLocationType}
                            value={values.locationType ? values.locationType : ''}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            error={touched.locationType && Boolean(errors.locationType)}> 
                            <MenuItem value="CER_LOCATIONS"><em>CER Locations</em></MenuItem>
                            <MenuItem value="E911_LOCATIONS"><em>E911 Locations</em></MenuItem>
                    </Select>
                    <span id="locationType-helper-text" className="error-prompt">{touched.locationType ? errors.locationType : ""}</span>
                    <br/>
                </div>
            }
            {
            isPublicIpRange ? '' : 
            //!values.locationType ? '' :
                <div>
                    {rerenderBuildingSelect &&
                        <div>
                            <InputLabel shrink={true} 
                                htmlFor="building" 
                                onBlur={handleBlur}
                                error={isLocations ? touched.location && Boolean(errors.location ? errors.location.value : "") : touched.building && Boolean(errors.building ? errors.building.value : "")}>
                                {isLocations ? 
                                    '*Locations' 
                                    : 
                                    '*Buildings'
                                }
                            </InputLabel>
                            <BuildingOrLocationAsyncSelect
                                id="building"
                                onBlur={handleBlur}
                                cerToggle={values.locationType === 'CER_LOCATIONS'}
                                defaultValue={isLocations ? values.location : values.building}
                                error={isLocations ? touched.location && Boolean(errors.location ? errors.location.value : "") : touched.building && Boolean(errors.building ? errors.building.value : "")}
                                onChange={onSelectBuilding}
                                {...props}
                            />
                            <span id="building-helper-text" className="error-prompt">{
                                isLocations ?
                                    touched.location && errors.location ? errors.location.value : "" 
                                :
                                    touched.building && errors.building ? errors.building.value : "" 
                            }</span>
                            <br/>
                        </div>
                    }
                </div>
            }
            {((locationSearchInput && locationSearchInput.length > 0) || (locationsList.length > 0 && locationsLoaded && props.company.toJS().locationTreeType !== 'SIMPLE' && (values.locationType !== undefined && values.locationType !== 'CER_LOCATIONS'))) && 
                <div>
                    {rerenderBuildingSelect &&
                        <div>
                            <InputLabel shrink={true} 
                                htmlFor="location" 
                                error={(touched.location && Boolean(errors.location ? errors.location.value : ""))}>
                                *Location
                            </InputLabel>
                            <LocationAsyncSelect
                                id="location" 
                                cerToggle={values.locationType === 'CER_LOCATIONS'}
                                defaultValue={values.location}
                                onBlur={handleBlur}
                                onChange={onSelectLocation}
                                getLocationsByParentId={getLocationsByParentId}
                                setLocationSearchInput={setLocationSearchInput}
                                selectedBuildingId={selectedBuildingId}
                                error={(touched.location && Boolean(errors.location ? errors.location.value : ""))}
                                {...props}
                            />
                            <span id="location-helper-text" className="error-prompt">{touched.location && errors.location ? errors.location.value : ""}</span>
                            <br/>
                        </div>
                    }
                </div>
            }
            {
                (locationsList.length === 0 && (locationsLoaded) && (locationSearchInput === undefined || locationSearchInput.length === 0) && (!isLocations && values.building)) &&
                <ModalErrorMessage 
                    modalErrorMessage="No Locations available for currently selected building. Please add a location to this building."/>
            }
        </div>
    );
}

export default BuildingAndLocationSingleComponent;