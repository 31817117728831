import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Formik } from 'formik';
import * as Yup from "yup";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ModalErrorMessage from '../../components/ModalWrapper/ModalErrorMessage';
import history from '../../utils/history';
import BackgroundImage from '../../assets/background.jpg';
import HorizonLogo from '../../assets/horizon-logo.png';
import AnywhereLogo from '../../assets/anywhere-logo.png';
import Five9Favicon from '../../assets/five9-favicon.ico';
import DefaultFavicon from '../../assets/favicon.ico';
import DarkFavicon from '../../assets/favicon-dark.ico';
import SingleSignOnApi from '../../apis/singleSignOnApi';

import { selectLoginLoading, selectLoginErrorMessage, login, selectUser, ssoExchangeLogin, forgotPassword } from '../../reducers/auth';
import { actions as AppActions, cirrusEnvironment } from '../../reducers/app';
import { Link } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import { Helmet } from "react-helmet";

import './LoginPage.css';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    card: {
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        maxWidth: '30%'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
        
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    passwordField: {
        marginTop: theme.spacing.unit * 2,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: -12,
    },
    wrapper: {
        position: 'relative',
    },
    horizonLogo: {
        width: '150px'
    },
    anywhereLogo: {
        width: '200px'
    },
    subtitle: {
        color: '#4b4b4b',
        textAlign: 'center'
    },
    subtitleLeft: {
        color: '#4b4b4b',
        fontSize: '14px'
    },
    forgotPassword: {
        display: 'flex',
        justifyContent: 'center',
        
    }
});

const LoginPageForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            classes, 
            loginLoading,
            loginErrorMessage,
            checkIsSSO,
            ssoRequestLoading,
            setEmailValue } = props;

    useEffect(() => {
        setEmailValue(values.email);
    }, [values.email]);

    return (
        <form onSubmit={handleSubmit} className={classes.form} noValidate="novalidate">
            {loginErrorMessage && <ModalErrorMessage modalErrorMessage={loginErrorMessage}/>}
            { checkIsSSO && 
                <div>
                    <TextField
                        id="email"
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                        fullWidth={true}
                        autoComplete="email"
                        error={(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')))}/> 
                    <span id="email-helper-text" className="error-prompt">{(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))) ? errors.email : ''}</span>
                </div>
            }
            { !checkIsSSO &&
                <div>
                    <TextField
                        id="email"
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                        fullWidth={true}
                        autoComplete="off"
                        error={(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')))}/> 
                    <span id="email-helper-text" className="error-prompt">{(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))) ? errors.email : ''}</span>
                    <TextField
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        value={values.password}
                        onBlur={handleBlur}
                        fullWidth={true}
                        autoComplete="current-password"
                        error={(touched.password && Boolean(errors.password)) || (errors.password && Boolean(errors.password.includes('max')))}
                        className={classes.passwordField}/> 
                    <span id="password-helper-text" className="error-prompt">{(touched.password && Boolean(errors.password)) || (errors.password && Boolean(errors.password.includes('max'))) ? errors.password : ''}</span>
                </div>

            }
            <div className={classes.wrapper}>
                <Button
                    id="sign-in-submit-button"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={(loginLoading || ssoRequestLoading)}
                >
                Sign in
                </Button>
                {(loginLoading || ssoRequestLoading) && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </form>
    );
}

function LoginPage(props) {
    const { classes, loginLoading, loginErrorDisplay, loginErrorMessage, user, cirrusEnvironment, forgotPasswordAction } = props;
    const containerRef = React.createRef();
    let backgroundImageLoaded = false;
    const backgroundImageUrl = BackgroundImage;

    const [forgotPassword, setForgotPassword] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [checkIsSSO, setCheckIsSSO] = useState(true); // set to true by default for SSO check
    const [ssoRequestLoading, setSsoRequestLoading] = useState(false);
    const [emailValue, setEmailValue] = useState('');

    const updateBackgroundImage = (lastTry = false) => {
        if (!backgroundImageLoaded && containerRef.current) {
            //sets the css background-image
            containerRef.current.style.backgroundImage = `url(${backgroundImageUrl})`;
            backgroundImageLoaded = true;
        }

        if (lastTry) {
            backgroundImageLoaded = true;
        }
    };

    const lazyLoadBackgroundImage = () => {
        const img = new Image();
        img.onload = updateBackgroundImage();
        img.src = backgroundImageUrl;
    }

    useEffect(() => {
        if(user)
            history.push('/');
        else
            lazyLoadBackgroundImage();
    }, [user]);

    useEffect(() => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setIsDarkMode(true);
        }
    }, [history.location.pathname]);

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        if (event.matches) {
            setIsDarkMode(true);
        } else {
            setIsDarkMode(false);
        }
    });

    function clickLink(e) {
        e.preventDefault();
        setForgotPassword(true);
        forgotPasswordAction();
    }

    const validationSchema = Yup.object({
        email: Yup.string()
                .email('Please enter a valid email address')
                .max(100, "Email has a max limit of 100 characters.")
                .required('Email is a required field.'),
        password: !checkIsSSO ? Yup.string()
                .required('Password is a required field.') : ''
    })

    useEffect(() => {
        const EX_TOKEN_PARAM = 'exToken';
        const urlParams = new URLSearchParams(window.location.search);
        const exchangeToken = urlParams.get(EX_TOKEN_PARAM);
        
        if (exchangeToken) {
            props.submitSsoExchange(exchangeToken);
        }
    }, []);

    return (
        <div id="login-page" className={classes.main} ref={containerRef}>
            {sessionStorage.getItem('skin') === 'five9' ?
                <Helmet>
                    <title>Five9</title>
                    <link rel="shortcut icon" href={Five9Favicon}></link>
                </Helmet>
                : isDarkMode ?
                <Helmet>
                    <link rel="shortcut icon" href={DarkFavicon}></link>
                    <title>{document.querySelector('title').getAttribute('data-default') + ' - Log In'}</title>
                </Helmet>
                : 
                <Helmet>
                    <link rel="shortcut icon" href={DefaultFavicon}></link>
                    <title>{document.querySelector('title').getAttribute('data-default') + ' - Log In'}</title>
                </Helmet>
            }
            <span className="logo"></span>
            <Card className={classes.card}>
                <div>
                    {cirrusEnvironment === 'E911Anywhere' ?
                        <img src={AnywhereLogo} alt="E911 Anywhere Logo" className={classes.anywhereLogo} />
                    :
                        <img src={HorizonLogo} alt="Horizon Mobility Logo" className={classes.horizonLogo} />
                    }
                </div>
                
                { forgotPassword ? <ForgotPassword classes={classes} emailValue={emailValue}/> :
                <div>                
                    { cirrusEnvironment === 'E911Anywhere' ?
                        <p className={classes.subtitle}>
                            Welcome to E911 Anywhere<sup>&trade;</sup> Portal<br/>
                            Please sign in below
                        </p>:
                        <p className={classes.subtitle}>
                            Welcome to Horizon Mobility<sup>&trade;</sup> Portal<br/>
                            Please sign in below
                        </p>
                    }
                    <Formik
                        initialValues={{ 
                            email: '',
                            password: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit = {
                            (values) => {
                                if (!checkIsSSO) {
                                    props.submitLogin({username: values.email, password: values.password});
                                } else {
                                    setSsoRequestLoading(true);
                                    SingleSignOnApi.checkIsSso(values.email).then(response => {
                                        if (!response.data.ssoLoginStatus) {
                                            setSsoRequestLoading(false);
                                            setCheckIsSSO(false);
                                        } else {
                                            // redirect to sso page  
                                            window.location.replace(`${response.data.idpLoginUrl}`);
                                        }
                                    })
                                    .catch(error => {
                                        setCheckIsSSO(false);
                                        setSsoRequestLoading(false);
                                        console.log(error);
                                    });
                                }
                            }
                        }
                        render={props => <LoginPageForm 
                                            {...props}
                                            classes={classes}  
                                            loginLoading={loginLoading} 
                                            loginErrorDisplay={loginErrorDisplay} 
                                            loginErrorMessage={loginErrorMessage}
                                            checkIsSSO={checkIsSSO}
                                            ssoRequestLoading={ssoRequestLoading}
                                            setEmailValue={setEmailValue}/>}
                    />
                    <br/>                
                    { !checkIsSSO &&  <Link className={classes.forgotPassword} to="" onClick={clickLink}>Forgot/Reset Password?</Link> }
                </div>
                }
            </Card>
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    loginLoading: selectLoginLoading(),
    loginErrorMessage: selectLoginErrorMessage(),
    user: selectUser(),
    cirrusEnvironment: cirrusEnvironment(),
});

const mapDispatchToProps = dispatch => {
    return {
        submitLogin: (credentials) => dispatch(login(credentials)),
        submitSsoExchange: (exToken) => dispatch(ssoExchangeLogin(exToken)),
        forgotPasswordAction: () => dispatch(forgotPassword())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(LoginPage);