import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import HasAccess from '../../../components/HasAccess/HasAccess';
import { actions as SystemSummaryActions, makeSelectSystemSummaryObject, makeSelectSystemSummaryObjectLoading } from '../../../reducers/homepage';
import { selectUserCompany, selectUser } from '../../../reducers/auth';
import { checkCustomPermission } from '../../../containers/App/routeAndNavData';
import './systemSummaryListTable.css';

function SystemSummarySection(props) {
    useEffect(() => {
        props.fetchSystemSummaryList();
    }, [props.company.toJS().id]);

    const {
        loading, systemSummary, 
    } = props;
    let systemSummaryObject;
    
    if (systemSummary) {
        systemSummaryObject = systemSummary.toJS();
        
    }

    return (
        <div>
            <HasAccess
                permissions={["VIEW_ORGANIZATIONS", "VIEW_EON_USERS", "VIEW_DEVICE_USERS", "VIEW_LOCATIONS", "VIEW_ADMINS_SAME_ORG", "VIEW_ADMINS_SUB_ORG"]}
            >
                <h2>Summary</h2>
                <div className="system-summary-wrapper">
                    {systemSummaryObject ?
                        <ul>
                            <HasAccess
                                permissions={["VIEW_ORGANIZATIONS"]}
                            >
                                <li><span id="subtenants-total-header">Organizations</span> 
                                    <span id="subtenants-total-value">{systemSummaryObject.numOfCompanies}</span>
                                </li>
                                <li className="vertical-border"></li>
                            </HasAccess>
                            {checkCustomPermission('CUSTOM_VIEW_ADMIN', props.user) &&
                                <>
                                    <li><span id="administrators-total-header">Administrators</span> 
                                        <span id="administrators-total-value">{systemSummaryObject.numOfPortalUsers}</span>
                                    </li>
                                    <li className="vertical-border"></li>
                                </>
                            }
                            <HasAccess
                                permissions={["VIEW_DEVICE_USERS"]}
                            >
                                <li><span id="users-total-header">Users</span> 
                                    <span id="users-total-value">{systemSummaryObject.numOfMyE911Users}</span>
                                </li>
                                <li className="vertical-border"></li>
                            </HasAccess>
                            <HasAccess
                                permissions={["VIEW_EON_USERS"]}
                            >
                                <li><span id="eon-users-total-header">EON Users</span> 
                                    <span id="eon-users-total-value">{systemSummaryObject.numOfEonUsers}</span>
                                </li>
                                <li className="vertical-border"></li>
                            </HasAccess>
                            <HasAccess
                                permissions={["VIEW_LOCATIONS"]}
                            >
                                <li><span id="number-of-locations-header">No. of Locations</span> 
                                    <span id="number-of-locations-value">{systemSummaryObject.numOfLocations}</span>
                                </li>
                            </HasAccess>
                        </ul>
                        : ''
                    }
                </div>
            </HasAccess>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    systemSummary: makeSelectSystemSummaryObject(),
    loading: makeSelectSystemSummaryObjectLoading(),
    company: selectUserCompany(),
    user: selectUser()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchSystemSummaryList: () => dispatch(SystemSummaryActions.getSystemSummaryRequest())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemSummarySection);