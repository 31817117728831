import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import {  InputLabel, MenuItem, Switch } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import NumberFormat from 'react-number-format';

import CallMonitoringListTable from '../../../containers/CallMonitoringListTable/CallMonitoringListTable';

import BuildingOrLocationMultiComponent from '../../../components/BuildingOrLocationMultiComponent/BuildingOrLocationMultiComponent';

// Variables
let checkChange = false;
let checkArray = [];

function AddEditCallMonitoringForm(props) {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        phoneNumberList,
        setPhoneNumberList,
        setPhoneListIsEmpty,
        modalData,
        company,
        setSelectedBuildings,
        setSelectedLocations,
        setFieldValue,
        setFormikValues } = props;

    const [phoneNumber, setPhoneNumber] = useState(''); 
    const tempArray = [...phoneNumberList];

    useEffect(() => {
        if (values.locationType === 'CER_LOCATIONS' && company.locationTreeType === "BUILDING") {
            values.locBuildChange = true
        }
        else {
            values.locBuildChange = false
        }
      }, [values.locationType]);

    useEffect(() => {
        const form = document.querySelector('form');
        form.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                return false;
            }
        });
    }, []);

    useEffect(() => {
        if (modalData) {
            setSelectedBuildings(modalData.filterData);
            setSelectedLocations(modalData)
            checkChange = true;
            checkArray = modalData.filterData && modalData.filterData.length > 0 ? modalData.filterData : [];
        }
    }, []);

    useEffect(() => {
        setFormikValues(values);
    }, [values]);

    useEffect(() => {
        if (values.allBuildings) {
            setFieldValue('buildings', []);
            setFieldValue('locations', []);
        }
    }, [values.allBuildings]);

    useEffect(() => {
        setFieldValue('phoneNumber', '');
    }, [phoneNumberList]);

    const handlePhoneNumber = () => {
        if (phoneNumber && !phoneNumberList.find(item => item.phoneNumber === phoneNumber)) {
            tempArray.push({ 
                phoneNumber: phoneNumber
            });

            setPhoneNumberList([
                ...phoneNumberList,
                { 
                    phoneNumber: phoneNumber
                }
            ]);
            
            setPhoneNumber('');

            if (tempArray.length <= 0) {
                setPhoneListIsEmpty(true);
            } else {
                setPhoneListIsEmpty(false);
            }
        }

        if (phoneNumber) {
            setPhoneListIsEmpty(false);
        } else {
            setPhoneListIsEmpty(true);
        }
    }
    const phoneNumberListDeleteHandler = row => {
        setPhoneNumberList(
            phoneNumberList.filter(item => item.phoneNumber !== row.original.phoneNumber)
        );

        if (tempArray.length <= 1) {
            setPhoneListIsEmpty(true);
        } else {
            setPhoneListIsEmpty(false);
        }
    }

    const fieldTouched = () => {
        props.setModalConfirm(true);
    }

    const handleNumberChange = e => {
        setPhoneNumber(e.value);
      };

    const canBeSubmitted = () => {
        return phoneNumber.length === 10;
      }
    
    const isEnabled = canBeSubmitted();

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    if (modalData) {
        modalData.filterData && modalData.filterData.length > 0 && modalData.filterData.forEach(item => {
            item.name = item.ouName;
            item.id = item.filterOrgId;
        });
    }
    
    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <TextField
                id="name"
                label="*Name"
                
                name="name"
                type="name"
                onChange={handleChange}
                value={values.name}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/> 
                <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <br/>

            <BuildingOrLocationMultiComponent 
                {...props}
            />
            <br/>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div>
                    <NumberFormat 
                        autoComplete="no"
                        id="phoneNumber"
                        label="*Phone Number"
                        
                        name="phoneNumber"
                        onValueChange={handleNumberChange}
                        value={phoneNumber}
                        onBlur={fieldTouched}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                        format="(###) ###-####" 
                        customInput={TextField}
                        mask="_"/>
                    <span id="phoneNumber-helper-text" className="error-prompt">{touched.phoneNumber ? errors.phoneNumber : ""}</span>
                </div>
                <Button disabled={!isEnabled} 
                        onClick={handlePhoneNumber}
                        className="button"
                        color="primary" 
                        variant="contained"
                        style={{ margin: '30px 0 0 20px' }}>Add</Button>
            </div>
            <br/>
            <CallMonitoringListTable 
                phoneNumberList={phoneNumberList}  
                phoneNumberListDeleteHandler={phoneNumberListDeleteHandler} />
            <br/>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        style={{ background: '#1b546a' }}
                        type="submit"
                        onClick={handleScroll}
                        disabled={modalLoading}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

export default AddEditCallMonitoringForm;