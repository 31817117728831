import React, { useState, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";

import { Formik } from 'formik';
import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/sip';
import { connect } from 'react-redux';
import SipForm from './shared/SipForm';
import styles from './shared/stylesObject';
import validationSchema from './shared/validationSchema';

styles();

const EditSipModal = props => {
    const { modalLoading, classes, modalData } = props;
    const [monitorTrunk, setMonitorTrunk] = useState(false);
    const [disableRedskyRelayCenterOption, setDisableRedskyRelayCenterOption] = useState(modalData ? getInitialDisableRedskyRelayCenterOption(modalData) : 'TEST');

    const schema = validationSchema(monitorTrunk);

    function getInitialDisableRedskyRelayCenterOption(modalData) {
        if(modalData.callRouteInfo.disableRelayCenter === 'TEST' || modalData.callRouteInfo.disableRelayCenter === null)  {
            return 'TEST';
        } else {
            return 'REJECTED_ERC';
        }
    }

    return(
        <Formik
            initialValues={{ 
                name: modalData.callRouteInfo.name,
                host: modalData.host,
                transport: modalData.transport,
                overrideDestinationToggle: modalData.callRouteInfo.overrideDestination ? true : false,
                overrideDestination: modalData.callRouteInfo.overrideDestination ? modalData.callRouteInfo.overrideDestination : 'TEST',
                skype: modalData.skype,
                disableRelayCenter: modalData.callRouteInfo.disableRelayCenter,
                monitorTrunk: modalData.monitorTrunk,
                portNumber: modalData.port
            }}
            validationSchema={schema}
            onSubmit = {
                (values) => {
                    if (!values.overrideDestinationToggle) {
                        values.overrideDestination = null;
                    }
                    values.id = modalData.id;
                    props.submitEditSipForm(values);
                }
            }
            render={renderProps => <SipForm modalData={modalData} setMonitorTrunk={setMonitorTrunk} classes={classes} modalLoading={modalLoading} {...renderProps} setDisableRedskyRelayCenterOption= {setDisableRedskyRelayCenterOption} disableRedskyRelayCenterOption={disableRedskyRelayCenterOption} />}
        />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitEditSipForm: (sip) => dispatch(actions.editSipRequest(sip))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(EditSipModal);