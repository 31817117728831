import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/scheduledReports';
import { connect } from 'react-redux';

class DeleteScheduleReportModal extends Component {

    handleDelete = () => {
        this.props.submitDeleteScheduledReport(this.props.modalData.id, this.props.modalData.page, this.props.modalData.pageSize, this.props.modalData.currentSortColumn);
    }

    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like to delete the following scheduled report:
                        </p>
                    </Grid>
                    <Grid item >
                         <b id="reportName">{this.props.modalData.name}</b>
                    </Grid>
                    <br/>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteScheduledReport: (id, page, pageSize, currentSortColumn) => dispatch(actions.deleteScheduledReport(id, page, pageSize, currentSortColumn))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteScheduleReportModal);