export default function reactTableFindAndReplace(parentElement, attributeName, attributeLabel, formElement) {
    /* This adds the proper elements and attributes to the all the React Tables that have pagination
    in order to be 508 compliant */
    const elmsList = document.getElementsByClassName(parentElement);
    let index = 0;

    if (elmsList) {
        for (const elm of elmsList) {
            index++;

            const formElm = elm.querySelector(formElement);

            formElm.setAttribute('id', `${attributeName}_${index}`);
            formElm.setAttribute('name', `${attributeName}_${index}`);
            formElm.setAttribute('aria-label', attributeLabel);
        }
    }
};