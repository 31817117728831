import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { compose } from 'redux';

import ModalWrapper from '../../../../components/ModalWrapper';
import { actions, types as NetworkDiscoveryTypes } from '../../../../reducers/networkDiscovery';
import { connect } from 'react-redux';

import { userCompanyId, selectUserCompany } from '../../../../reducers/auth';
import { selectCerEnabled } from '../../../../reducers/app';
import { createStructuredSelector } from 'reselect';
import AddLocationTab from '../AddLocationTab/AddLocationTab';
import AddEditNetworkDiscoveryForm from './addEditNetworkDiscoveryForm';

import { validationSchema, styles } from './sharedData';

const AddEditNetworkDiscoveryModal = props => {
    const storedIsPublicIpRange = JSON.parse(sessionStorage.getItem('isPublicIpRange'));
    const { modalLoading, classes, modalData } = props;
    const [tabValue, setTabValue] = useState(0);
    const [addLocationTabData, setAddLocationTabData] = useState(null);
    const [selectedBuildings, setSelectedBuildings] = useState(null);
    const [modalTypeString, setModalTypeString] = useState('');
    const [networkDiscoveryType, setNetworkDiscoveryType] = useState('');
    const [isIpRange, setIsIpRange] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const isPublicIpRange = storedIsPublicIpRange && isIpRange;

    const isLocations = props.company.toJS().locationTreeType === 'SIMPLE' || (formValues && formValues.locationType == 'CER_LOCATIONS');

    const handleTabChange = (event, value) => {
        setTabValue(value)
    };

    useEffect(() => {
        switch (props.currentModal) {
            case NetworkDiscoveryTypes.ADD_MAC_ADDRESS:
                setModalTypeString('add-macAddress');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.ADD_MAC_ADDRESS);
                break;
            case NetworkDiscoveryTypes.EDIT_MAC_ADDRESS:
                setModalTypeString('edit-macAddress');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.EDIT_MAC_ADDRESS);
                break;
            case NetworkDiscoveryTypes.ADD_BSSID:
                setModalTypeString('add-bssid');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.ADD_BSSID);
                break;
            case NetworkDiscoveryTypes.EDIT_BSSID:
                setModalTypeString('edit-bssid');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.EDIT_BSSID);
                break;
            case NetworkDiscoveryTypes.ADD_IP_RANGE:
                setModalTypeString('add-ipRange');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.ADD_IP_RANGE);
                setIsIpRange(true);
                break;
            case NetworkDiscoveryTypes.EDIT_IP_RANGE:
                setModalTypeString('edit-ipRange');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.EDIT_IP_RANGE);
                setIsIpRange(true);
                break;
            case NetworkDiscoveryTypes.ADD_LLDP:
                setModalTypeString('add-lldp');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.ADD_LLDP);
                break;
            case NetworkDiscoveryTypes.EDIT_LLDP:
                setModalTypeString('edit-lldp');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.EDIT_LLDP);
                break;
            case NetworkDiscoveryTypes.ADD_PORT:
                setModalTypeString('add-port');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.ADD_PORT);
                break;
            case NetworkDiscoveryTypes.EDIT_PORT:
                setModalTypeString('edit-port');
                setNetworkDiscoveryType(NetworkDiscoveryTypes.EDIT_PORT);
                break;
            default:
                break;
        }
    }, []);

    function setInitialLocation() {
        if(addLocationTabData) {
            return  addLocationTabData;
        }
        else if(modalData && modalData.location) {
            modalData.location.locationData = {};

            modalData.location.locationData.label = modalData.location.name;
            modalData.location.locationData.value = modalData.location.id;
            
            return modalData.location.locationData;
        }
        else if(modalData && modalData.erl) {
            modalData.erl.locationData = {};

            modalData.erl.locationData.label = modalData.erl.name;
            modalData.erl.locationData.value = modalData.erl.id;

            return modalData.erl.locationData;
        }
        else {
            return null;
        }
    }
    
    function setInitialBuilding() {
       if(modalData && modalData.location) {
            modalData.location.buildingData = {};

            
            modalData.location.buildingData.label = modalData.location.addressEntityName;
            modalData.location.buildingData.value = modalData.location.id;
            
            return modalData.location.buildingData;
        }
        else if(modalData && modalData.erl) {
            modalData.erl.buildingData = {};

            modalData.erl.buildingData.label = modalData.erl.addressEntityName;
            modalData.erl.buildingData.value = modalData.erl.id;

            return modalData.erl.buildingData;
        }
        else {
            return null;
        }
    }

    function setInitialLocationType() {
        if(isPublicIpRange) 
            return '' 
        else if(modalData.location && modalData.location.origin === 'default' || addLocationTabData) 
            return 'E911_LOCATIONS'
        else if(modalData.location && modalData.location.origin === 'CER') 
            return 'CER_LOCATIONS'
        else if(modalData.erl && modalData.erl.origin === 'default')
            return 'E911_LOCATIONS'
        else if(modalData.erl && modalData.erl.origin === 'CER')
            return 'CER_LOCATIONS' 
        else if(modalData.location && modalData.location.origin === 'MANAGER_DATA_SYNC')
            return 'E911_LOCATIONS'    
        else if(modalData.erl && modalData.erl.origin === 'MANAGER_DATA_SYNC')
            return 'E911_LOCATIONS'       
        else 
            return ''
    }

    return(
        <div>
            {(props.company.toJS().locationTreeType === 'SIMPLE' && !isPublicIpRange) &&
                <div>
                    <br/>
                    <AppBar position="static" color="default">
                        <Tabs className="tabs-bar"
                            value={tabValue} 
                            onChange={handleTabChange} 
                            indicatorColor="primary"
                            variant="fullWidth"
                        >
                            <Tab id={`${modalTypeString}-select-location-tab`} label="Select Location" />
                            <Tab id={`${modalTypeString}-add-location-tab`} label="Add Location" />
                        </Tabs>
                    </AppBar>
                    <br />       
                </div>
            }
            {tabValue === 0 && 
                <Formik
                    initialValues={modalData && props.currentModal !== NetworkDiscoveryTypes.ADD_PORT ? 
                    {
                        description: modalData.description ? modalData.description : '', 
                        macAddress: modalData.macAddress,
                        bssid: modalData.bssid,
                        ipAddressLow: modalData.ipAddressLow,
                        ipAddressHigh: modalData.ipAddressHigh,
                        chassisId: modalData.chassisId,
                        portId: modalData.portId,
                        locationType: setInitialLocationType(),
                        building: setInitialBuilding(),
                        location: setInitialLocation()
                    } :
                    { 
                        description: '', 
                        macAddress: '',
                        bssid: '',
                        ipAddressLow: '',
                        ipAddressHigh: '',
                        chassisId: '',
                        portId: '',
                        locationType: props.cerEnabled ? '' : 'E911_LOCATIONS',
                        building: '',
                        location: addLocationTabData ? addLocationTabData : ''
                    }
                    }
                    validationSchema={validationSchema(props, isPublicIpRange, isLocations, formValues)}
                    onSubmit = {
                        (values) => {
                            if (!modalData) {
                                values.parentId = props.companyId;
                                switch (networkDiscoveryType) {
                                    case NetworkDiscoveryTypes.ADD_MAC_ADDRESS:
                                        return props.submitAddMacAddressForm(values);
                                    case NetworkDiscoveryTypes.ADD_BSSID:
                                        return props.submitAddBssidForm(values);
                                    case NetworkDiscoveryTypes.ADD_IP_RANGE:
                                        return props.submitAddIpRangeForm(values);
                                    case NetworkDiscoveryTypes.ADD_LLDP:
                                        return props.submitAddLldpForm(values);
                                    default:
                                        return null;
                                }
                            } else {
                                values.id = modalData.id;
                                if (props.modalData.sortBy){
                                    values.sortBy = props.modalData.sortBy
                                }
                                switch (networkDiscoveryType) {
                                    case NetworkDiscoveryTypes.EDIT_MAC_ADDRESS:
                                        return props.submitEditMacAddressForm(values);
                                    case NetworkDiscoveryTypes.EDIT_BSSID:
                                        return props.submitEditBssidForm(values);
                                    case NetworkDiscoveryTypes.EDIT_IP_RANGE:
                                        return props.submitEditIpRangeForm(values);
                                    case NetworkDiscoveryTypes.EDIT_LLDP:
                                        return props.submitEditLldpForm(values);
                                    case NetworkDiscoveryTypes.ADD_PORT: // had modal data from chassis row
                                        values.chassisId = modalData.id;
                                        return props.submitAddPortForm(values);
                                    case NetworkDiscoveryTypes.EDIT_PORT:
                                        values.networkSwitchId = modalData.networkSwitch.id;
                                        return props.submitEditPortForm(values);
                                    default:
                                        return null;
                                }
                            }
                        }
                    }
                    render={formikProps => <AddEditNetworkDiscoveryForm 
                                                setFormValues={setFormValues}
                                                isPublicIpRange={isPublicIpRange}
                                                networkDiscoveryType={networkDiscoveryType} 
                                                selectedBuildings={selectedBuildings}
                                                setSelectedBuildings={setSelectedBuildings}
                                                location={(modalData && modalData.location) ? modalData.location : (modalData && modalData.erl) ? modalData.erl : null}
                                                companyId={props.companyId} 
                                                company={props.company.toJS()}
                                                classes={classes} 
                                                modalData={modalData} 
                                                modalLoading={modalLoading} 
                                                {...formikProps} 
                                                {...props}/>}
                />
            }
            {tabValue === 1 && <AddLocationTab setTabValue={setTabValue} setAddLocationTabData={setAddLocationTabData} />}
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
    company: selectUserCompany(),
    cerEnabled: selectCerEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        submitAddMacAddressForm: (macAddress) => dispatch(actions.addMacAddressRequest(macAddress)),
        submitEditMacAddressForm: (macAddress) => dispatch(actions.editMacAddressRequest(macAddress)),
        submitAddBssidForm: (bssid) => dispatch(actions.addBssidRequest(bssid)),
        submitEditBssidForm: (bssid) => dispatch(actions.editBssidRequest(bssid)),
        submitAddIpRangeForm: (ipRange) => dispatch(actions.addIpRangeRequest(ipRange)),
        submitEditIpRangeForm: (ipRange) => dispatch(actions.editIpRangeRequest(ipRange)),
        submitAddLldpForm: (lldp) => dispatch(actions.addLldpRequest(lldp)),
        submitEditLldpForm: (lldp) => dispatch(actions.editLldpRequest(lldp)),
        submitAddPortForm: (port) => dispatch(actions.addPortRequest(port)),
        submitEditPortForm: (port) => dispatch(actions.editPortRequest(port))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddEditNetworkDiscoveryModal);