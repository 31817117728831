import React, { useState, useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Formik } from "formik";
import Grid from '@material-ui/core/Grid';
import OrganizationManagementAPI from '../../../apis/organizationManagementApi';
import { createStructuredSelector } from "reselect";
import { selectUserCompany, changeSubtenant } from '../../../reducers/auth';
import { connect } from "react-redux";
import * as Yup from "yup";
import { actions as AppActions } from '../../../reducers/app';
import CompanyAPI from '../../../apis/companyApi';
import { types } from '../../../reducers/organizationManagement';
import MassNotificationSyncSelect from '../../../containers/ModalConductor/OrganizationManagementModals/shared/MassNotificationSyncSelect.js';
import { actions as massNotificationTemplatesActions,    
        makeSelectMassNotificationList,
        makeSelectIntegrationCred,
        makeSelectMassNotificationListLoading
        } from '../../../reducers/organizationManagement';
import InputLabel from '@material-ui/core/InputLabel';

const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
const isEditAlertSubscriptions = user && user.permissions.includes('EDIT_ALERT_SUBSCRIPTIONS');

const validationSchema = Yup.object({
    accessKeyToken: Yup.string("Enter a Access Key ID")
            .trim()
            .max(255, "Access Key ID has a max limit of 255 characters."),
    secretAccessKeyToken: Yup.string("Enter a Secret Access Key Token")
            .trim()
            .max(255, "Secret Access Key has a max limit of 255 characters."),
    massNotificationOrgId: Yup.string("Enter a Incident Communications Organization ID")
            .trim()
            .max(255, "Organization ID has a max limit of 255 characters."),
})

const Form = props => {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
        handleSubmit,
        tokenSubmitStatus,
        dataLoading,
        isNotUnique,
        isInvalid,
        disableInput,
        massNotificationTemplates,
        setCurrentModal,
        company,
        currentTemplate,
        success,
        successSecondary,
        loading,
        deleteReset
	} = props;

    return (
        <div>
            {(massNotificationTemplates.length > 0 && successSecondary) && <LinearProgress /> ?
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 900 }}>
                        <CheckCircleOutlineIcon style={{ color: '#1aa68f', marginRight: 10, fontSize: 30 }} />
                        <div style={{ color: 'rgb(97, 95, 87)' }}>The API access information is saved and hidden for security. To reset the API access information, which will disable the integration, please click the 'RESET' button.</div>
                        <Grid container direction="row" justify="flex-end" alignItems="center" style={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: 100 }}>
                            {
                                <Button
                                    className="button"
                                    id="delete-mass-button"
                                    variant="contained"
                                    color="primary"
                                    disabled = {!isEditAlertSubscriptions ? true : false}
                                    onClick={() => setCurrentModal(types.DELETE_INTEGRATION_CRED, props)}>
                                    RESET
                                </Button>
                            }
                        </Grid>
                    </div>
                </div>
                :
                <div>
                    {loading ? <div><LinearProgress/></div>
                    :
                    <div>
                        {dataLoading && <LinearProgress />}
                        <form style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                            <div>
                                <div style={{ display: 'inline', float: 'left' }}>
                                    <TextField
                                        style={{ width: 350, marginRight: 20 }}
                                        id="accessKeyToken"
                                        label="*Access Key ID"
                                        name="accessKeyToken"
                                        onChange={handleChange}
                                        value={values.accessKeyToken}
                                        onBlur={handleBlur}
                                        fullWidth={true}
                                        disabled={!disableInput}
                                        error={isNotUnique || tokenSubmitStatus === 'failure' || isInvalid || (touched.accessKeyToken && Boolean(errors.accessKeyToken)) || (errors.accessKeyToken && Boolean(errors.accessKeyToken.includes('max')))} />
                                    <div style={{ paddingBottom: '1rem' }} id="everbridgeMassNotificationTokens-helper-text" className="error-prompt">{(touched.accessKeyToken && Boolean(errors.accessKeyToken)) || (errors.accessKeyToken && Boolean(errors.accessKeyToken.includes('max'))) ? errors.accessKeyToken : ''}</div>
                                </div>
                                <div style={{ display: 'inline', float: 'left' }}>
                                    <TextField
                                        style={{ width: 700, marginRight: 20 }}
                                        id="secretAccessKeyToken"
                                        label="*Secret Access Key"
                                        name="secretAccessKeyToken"
                                        onChange={handleChange}
                                        value={values.secretAccessKeyToken}
                                        onBlur={handleBlur}
                                        fullWidth={true}
                                        disabled={!disableInput}
                                        error={isNotUnique || tokenSubmitStatus === 'failure' || isInvalid || (touched.secretAccessKeyToken && Boolean(errors.secretAccessKeyToken)) || (errors.secretAccessKeyToken && Boolean(errors.secretAccessKeyToken.includes('max')))} />
                                    <div style={{ paddingBottom: '1rem' }} id="everbridgeMassNotificationTokens-helper-text" className="error-prompt">{(touched.secretAccessKeyToken && Boolean(errors.secretAccessKeyToken)) || (errors.secretAccessKeyToken && Boolean(errors.secretAccessKeyToken.includes('max'))) ? errors.secretAccessKeyToken : ''}</div>
                                </div>
                                <div style={{ display: 'inline', float: 'left' }}>
                                    <TextField
                                        style={{ width: 300, marginRight: 20 }}
                                        id="massNotificationOrgId"
                                        label="*Organization ID"
                                        name="massNotificationOrgId"
                                        onChange={handleChange}
                                        value={values.massNotificationOrgId}
                                        onBlur={handleBlur}
                                        fullWidth={true}
                                        disabled={!disableInput}
                                        error={isNotUnique || tokenSubmitStatus === 'failure' || isInvalid || (touched.notificationOrgId && Boolean(errors.massNotificationOrgId)) || (errors.massNotificationOrgId && Boolean(errors.massNotificationOrgId.includes('max')))} />
                                    <div style={{ paddingBottom: '1rem' }} id="everbridgeMassNotificationTokens-helper-text" className="error-prompt">{(touched.massNotificationOrgId && Boolean(errors.massNotificationOrgId)) || (errors.massNotificationOrgId && Boolean(errors.massNotificationOrgId.includes('max'))) ? errors.massNotificationOrgId : ''}</div>
                                </div>
                                {/* {isNotUnique && 
                                <div id="everbridgeMassNotificationTokens-duplicate-helper-text" className="error-prompt">The Everbridge Incident Communications access tokens provided already exists.</div>
                            } */}
                            </div>
                            <Button
                                style={{ minWidth: '8rem' }}
                                type="submit"
                                color="primary"
                                disabled={!disableInput ? true : values.accessKeyToken && values.secretAccessKeyToken && values.massNotificationOrgId ? false : true}
                                variant="contained">AUTHENTICATE</Button>
                        </form>
                        {(tokenSubmitStatus === 'success' && isInvalid === false) &&
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 1100 }}>
                                <CheckCircleOutlineIcon style={{ color: '#1aa68f', marginRight: 10, fontSize: 30 }} />
                                <div style={{ color: 'rgb(97, 95, 87)' }}>SUCCESS! We were able to successfully connect to Incident Communications! Your API access information has been saved. Now please select an Incident Template from the list below.</div>
                            </div>
                        }
                        {(tokenSubmitStatus === 'failure' || isInvalid === true) &&
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 1100 }}>
                                <ErrorOutlineIcon style={{ color: 'rgb(230, 0, 0)', marginRight: 10, fontSize: 30 }} />
                                <div style={{ color: 'rgb(230, 0, 0)' }}>ERROR! We were not able to connect to Incident Communications with the supplied API access keys and/or Organization ID; therefore, the API access information was not saved. Please re-enter the values and try again.</div>
                            </div>
                        }
                    </div>
                    }
                </div>
            }
            <br></br>
            {((massNotificationTemplates.length > 0 && deleteReset) || success) &&
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 700 }}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <InputLabel shrink={true} style={{ fontSize: 20 }}
                                htmlFor="massNotificationTemplates-label">
                                Select the Incident Template that will be used for emergency calls:
                            </InputLabel>
                            <MassNotificationSyncSelect
                                id="massNotificationTemplates"
                                isMulti={false}
                                company={company}
                                options={massNotificationTemplates}
                                {...props}
                            />
                            {/* <span id="massNotification-helper-text" className="error-prompt">{touched.massNotification ? errors.massNotification : ""}</span> */}
                        </Grid>
                    </div>
                </div>
            }
        </div>
	);
};

function MassNotificationCastForm(props) {
    const [tokenSubmitStatus, setTokenSubmitStatus] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const [isNotUnique, setIsNotUnique] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [parentName, setParentCompnay] = useState('');
    const [deleteCred, setDeleteCred] = useState(false);
    const company = props.company.toJS();
    const massNotificationTemplates = props.massNotificationTemplates.toJS();
    const currentTemplate = props.currentTemplate ? props.currentTemplate : [];
    const [success, setSuccess] = useState(false);
    const [successSecondary, setSuccessSecondary] = useState(true);
    const [deleteReset, setDeleteReset] = useState(true);

    const {
        getMassNotificationsTemplatesList,
        getIntegrationCred,
    } = props;

    useEffect(() => {
        CompanyAPI.getOrganizationById(company.parentId).then(response => {
            if (response.data) {
                setParentCompnay(response.data.name)
            }
        });
        getMassNotificationsTemplatesList(company.id)
        getIntegrationCred(company.id, "massNotificationTemplateId")
    }, []);

    useEffect(() => {
        if (massNotificationTemplates.length === 0){
            setDeleteCred(true)
        }
        else{
            setDeleteCred(false) 
        }  
    }, [massNotificationTemplates]);

    return (
        <div>
            <Formik
				initialValues={{
					accessKeyToken: company.accessToken && deleteCred ? company.accessToken : '',
                    secretAccessKeyToken: company.secretToken && deleteCred ? company.secretToken : '',
                    massNotificationOrgId: company.orgId && deleteCred ? company.orgId : ''
                }}
                validationSchema={validationSchema}
				onSubmit = {
					(values) => {
                        let integrationCredArray = [
                            { companyId: company.id, name: "massNotificationAccessKeyId", value: values.accessKeyToken },
                            { companyId: company.id, name: "massNotificationSecretAccessKey", value: values.secretAccessKeyToken },
                            { companyId: company.id, name: "massNotificationOrgId", value: values.massNotificationOrgId},
                        ];
                        setDataLoading(true);
                        OrganizationManagementAPI.addIntegrationCred(integrationCredArray).then(response => {
                            setTokenSubmitStatus('success');
                            setIsInvalid(false);
                            setIsNotUnique(false);
                            setDataLoading(false);

                            company.accessToken = values.accessKeyToken;
                            company.secretToken = values.secretAccessKeyToken;
                            company.orgId = values.massNotificationOrgId
                            props.changeSubtenant(company);
                            setSuccess(true)
                            setSuccessSecondary(false)
                        }).catch(error => {
                            if(error.response.data.type === 'http://cirrus.redskytech.com/errors/invalid/data'){
                                setIsInvalid(true);
                                setIsNotUnique(false);
                            }
                            else if (error.response.data.type === "http://cirrus.redskytech.com/errors/company/integrationCredentials/duplicate"){
                                setIsNotUnique(true);
                                setIsInvalid(false);
                            } else {
                                setTokenSubmitStatus('failure');
                            }
                            setDataLoading(false);
                        });
					}
				}
				render={formikProps => <Form enableReinitialize={true} disableInput={props.disableInput} isNotUnique={isNotUnique} isInvalid={isInvalid} 
                                             dataLoading={dataLoading} tokenSubmitStatus={tokenSubmitStatus} massNotificationTemplates={massNotificationTemplates} 
                                             company={company} currentTemplate={currentTemplate} setCurrentModal={props.setCurrentModal} success={success} 
                                             successSecondary={successSecondary} loading={props.loading} setSuccess={setSuccess} 
                                             setSuccessSecondary={setSuccessSecondary} deleteReset={deleteReset} setDeleteReset={setDeleteReset} {...formikProps}/>}
			/>
        </div>
    );
}

MassNotificationCastForm.propTypes = {
    massNotificationTemplates: ImmutablePropTypes.list,
    //integrationCredentials: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = () => createStructuredSelector({
	company: selectUserCompany(),
    massNotificationTemplates: makeSelectMassNotificationList(),
    currentTemplate: makeSelectIntegrationCred(),
    loading: makeSelectMassNotificationListLoading()
});

const mapDispatchToProps = dispatch => {
    return {
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        getMassNotificationsTemplatesList: (id) => dispatch(massNotificationTemplatesActions.getMassNotificationTemplatesRequest(id)),
        getIntegrationCred: (id, name) => dispatch(massNotificationTemplatesActions.getIntegrationCredRequest(id, name)),
        changeSubtenant: (company) => dispatch(changeSubtenant(company)),
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MassNotificationCastForm);