import { axiosInstance } from './api';
/* Cirrus Admin-Service /locations Controller */
const COMPANY_SUMMARY_ENDPOINT = '/admin-service/stats/company/';
const IMPORTING_ENDPOINT = 'admin-service/imports';
const RSRC_ENDPOINT = 'admin-service/callHistory';
const SYSTEM_SUMMARY_ENDPOINT = 'admin-service/stats/system/';

export default class HomepageAPI {
    static getCompanySummary(companyId, cancelTokenSource) {
        return axiosInstance.get(`${COMPANY_SUMMARY_ENDPOINT}${companyId}`, {
        });
    }

    static getImportingByCompanyId(companyId) {
        return axiosInstance.get(`${IMPORTING_ENDPOINT}/company/${companyId}`)
    }
    
    static getImporting() {
        return [
            {
                created: '12/05/70',
                importType: 'DEVICE_USER',
                status: 'PENDING'
            },
            {
                created: '12/05/70',
                importType: 'DEVICE_USER',
                status: 'PENDING'
            }
        ]
    }

    static getRsrc(page, pageSize) {
        return axiosInstance.get(`${RSRC_ENDPOINT}`, {
            params: {
               page,
               pageSize,
               relayCenter: 'ALL'
           }
        })
    }
    
    static getSystemSummary() {
            return axiosInstance.get(SYSTEM_SUMMARY_ENDPOINT);
    }
}