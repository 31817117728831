import { call, put, takeLatest, all, select} from 'redux-saga/effects';
import { actions as ManageLicensesActions,
		 types as ManageLicensesTypes } from '../reducers/manageLicenses';
import LicensingServiceApi from '../apis/licensingServiceApi';
import HomepageAPI from '../apis/homepageApi';
import { actions as AppActions, selectPageSize, selectCurrentPage } from '../reducers/app';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';
import convertLicenseCountResponse from '../utils/licenseTypeUtil';

function* getLicensesList(action) {
	try {
		let response = yield call(LicensingServiceApi.getLicensesByOrgId, action.companyId, action.page, action.pageSize);
		yield put(ManageLicensesActions.getLicensesSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(ManageLicensesActions.getLicensesError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addLicense(action) {
	try {
		const pageSize = yield select(selectPageSize());
		const currentPage = yield select(selectCurrentPage());
		yield put(AppActions.setModalLoading());
		yield call(LicensingServiceApi.addLicense, action.data);
		yield put(AppActions.setModalClose());
		yield put(ManageLicensesActions.getLicensesRequest(action.data.companyId, currentPage, pageSize));
		yield put(ManageLicensesActions.getLicenseStatusRequest(action.data.companyId));
	} catch (error) {
		console.log(error);
		if (error) {
			yield put(AppActions.setModalError(errorHandler(error)));
		}
	}
}

function* deleteLicense(action) {
	try {
		const pageSize = yield select(selectPageSize());
		const currentPage = yield select(selectCurrentPage());
		yield put(AppActions.setModalLoading());
		yield call(LicensingServiceApi.deleteLicense, action.data);
		yield put(AppActions.setModalClose());
		yield put(ManageLicensesActions.getLicensesRequest(action.companyId, currentPage, pageSize));
		yield put(ManageLicensesActions.getLicenseStatusRequest(action.companyId));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getLicensesStatus(action) {
	try {
		const response1 = yield call(LicensingServiceApi.getLicenseCount, action.companyId);
		const response2 = yield call(LicensingServiceApi.getLicenseCountLocation, action.companyId);
		const response3 = yield call(LicensingServiceApi.getLicenseCountUser, action.companyId);
		const response4 = yield call(HomepageAPI.getCompanySummary, action.companyId);
		
		response2.data.FlexLocation = response2.data.Flex;
		response3.data.FlexUser = response3.data.Flex;

		const combinedArray = [...convertLicenseCountResponse(response1.data), ...convertLicenseCountResponse(response2.data), ...convertLicenseCountResponse(response3.data), ...convertLicenseCountResponse(response4.data)];
		yield put(ManageLicensesActions.getLicenseStatusSuccess(combinedArray));
	} catch (error) {
		yield put(ManageLicensesActions.getLicenseStatusError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* rootSaga() {
	yield all([
		yield takeLatest(ManageLicensesTypes.GET_LICENSES_REQUEST, getLicensesList),
		yield takeLatest(ManageLicensesTypes.ADD_LICENSE, addLicense),
		yield takeLatest(ManageLicensesTypes.DELETE_LICENSE, deleteLicense),
		yield takeLatest(ManageLicensesTypes.GET_LICENSES_STATUS_REQUEST, getLicensesStatus)
	]);
}

export default rootSaga;
