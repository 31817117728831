import React, { Component } from 'react';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import moment from 'moment';
 
class DeviceUsersDeviceDetailsModal extends Component {
    discoveryMethodEnumType(type) {
        if (type === 'HELD_PLUS') {
			return this.discoveryTypeEnumDisplay(this.props.modalData.row.original.discoveryType);
        } else {
            return this.discoveryTypeEnumDisplay(this.props.modalData.parentRowUserData.discoveryType);
        }
    }
    discoveryTypeEnumDisplay(value) {
    	switch (value) {
    		case 'STICKY_IP':
            	return 'Sticky IP Mapping';
            case 'GATEWAY_MAC_ADDRESS':
                    return 'Gateway MAC Address';
            default:
            	return value;
    	}
    }

    formatLastAccess(callTime) {
        return callTime ?  moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
    }

    render() {
        const {
            name,
            mac,
            ipv4,
            ipv6,
            bssid,
            chassis,
            portId,
            gatewayMac,
            discoveryType,
            operatingSystem,
            model,
            type,
            heldIdentifier,
            lastAccessTime,
            erl,
            deviceDescription
        } = this.props.modalData.row.original;

        const {
            currentEmergencyResponseLocation
        } = this.props.modalData.parentRowUserData;

        return(
            <div>
                <ul className="details-list">
                    <li><b>Device Name: </b>{name === null ? heldIdentifier : name}</li>
                    <li><b>Discovery Method: </b>{this.discoveryMethodEnumType(type)}</li>
                    <li><b>MAC Address: </b>{mac}</li>
                    <li><b>IP Address: </b>{ipv4}</li>
                    <li><b>IPv6 Address: </b>{ipv6}</li>
                    <li><b>BSSID: </b>{bssid}</li>
                    <li><b>Chassis ID: </b>{chassis}</li>
                    <li><b>PortID: </b>{portId}</li>
                    <li><b>Gateway Mac Address: </b>{gatewayMac}</li>
                    <li><b>Operating System: </b>{operatingSystem}</li>
                    <li><b>Device Model: </b>{model}</li>
                    <li><b>Current Location Name: </b>{
                                    type === 'HELD_PLUS' && erl ? 
                                    erl.name : 
                                    type !== 'HELD_PLUS' && currentEmergencyResponseLocation ? currentEmergencyResponseLocation.name : ''}
                    </li>
                    <li><b>Current Location Address: </b>{
                                    type === 'HELD_PLUS' && erl && erl.address ? erl.address.normalizedAddress :
                                    type !== 'HELD_PLUS' && (currentEmergencyResponseLocation && currentEmergencyResponseLocation.address) ? currentEmergencyResponseLocation.address.normalizedAddress : ''}
                    </li>
                    <li><b>Geodetic Coordinates: </b> {
                                    type === 'HELD_PLUS' && erl && erl.normalizedGeoCoords ? erl.normalizedGeoCoords.latitude + ', ' + erl.normalizedGeoCoords.longitude :
                                    type !== 'HELD_PLUS' && currentEmergencyResponseLocation && currentEmergencyResponseLocation.normalizedGeoCoords ?
                                        currentEmergencyResponseLocation.normalizedGeoCoords.latitude + ', ' + currentEmergencyResponseLocation.normalizedGeoCoords.longitude : '' }
                    </li>
                    <li><b>Device Type: </b>{type === 'WEB' ? 'Web' : type === 'MOBILE' ? 'Mobile' : type === 'HELD_PLUS' ? 'HELD+' : 'Desktop'}</li>
                    <li><b>Device Description: </b>{ deviceDescription}</li>
                    <li><b>Last Access Time: </b>{this.formatLastAccess(lastAccessTime)}</li>
                </ul>
            </div>
        );
    }
}


export default compose(
    ModalWrapper,
)(DeviceUsersDeviceDetailsModal);