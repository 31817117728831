 import { axiosInstance } from './api';
/* Cirrus Admin-Service /locations Controller */
const DEVICE_USERS_ENDPOINT = 'admin-service/deviceUser';
const DEVICE_ENDPOINT = 'admin-service/device';
const ALT_ID_ENDPOINT = 'admin-service/deviceUser/alternateId/validate/';

export default class DeviceUserAPI {
    static getDeviceUsers(companyId, page, pageSize, searchTerm, sortBy, direction) {
         return axiosInstance.get(`${DEVICE_USERS_ENDPOINT}/company/${companyId}`, {
             params: {
                page,
                pageSize,
                searchTerm,
                sortBy,
                direction
            }
         })
    }
    
    static getDeviceUsersDevices(deviceUserId, page, pageSize, sortBy, direction) {
         return axiosInstance.get(`${DEVICE_ENDPOINT}?deviceUserId=${deviceUserId}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&direction=${direction}`)
    }
    
    static validateAlternateId(deviceUserId, companyId) {
         return axiosInstance.get(ALT_ID_ENDPOINT + deviceUserId + '?companyId=' + companyId)
    }
    
    static deleteDeviceUserDevices(deviceUserId) {
        return axiosInstance.delete(`${DEVICE_ENDPOINT}/${deviceUserId}`);
    }
    
    static addDeviceUser(deviceUser) {
        return axiosInstance.post(DEVICE_USERS_ENDPOINT, deviceUser);
    }
    
    static deleteDeviceUser(deviceUserId) {
        return axiosInstance.delete(`${DEVICE_USERS_ENDPOINT}/${deviceUserId}`);
    }
    
    static editDeviceUser(deviceUser) {
        return axiosInstance.put(DEVICE_USERS_ENDPOINT, deviceUser);
    }
}