import React, { useEffect, useState, useCallback } from 'react';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';

import reducer, { actions as HeldDevicesActions } from '../../reducers/heldDevices';
import saga from '../../sagas/heldDevices';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as AppActions, selectPageSize } from '../../reducers/app';
import { createStructuredSelector } from 'reselect';

import HeldDevicesTable from '../../containers/HeldDevicesTable/heldDevicesTable';
import { debounce } from 'lodash';

const initialSortColumns = [
    {
        id: 'location',
        desc:false
    }
];

function HeldDevicesPage(props) {
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    
    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const isMatch = searchFieldRegex(inputValue);

        if (isMatch) {
            setSearchValue(inputValue);
            searchApiCall(
                inputValue, 
                props.pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC'
            );
        }
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, direction) => {
            if (inputValue.length > 0) {
                setPage(0);
                props.fetchHeldDevicesList(1, pageSize, inputValue, sortBy, direction);
            } else if(inputValue.length === 0) {
                setPage(0);
                props.fetchHeldDevicesList(1, pageSize, '', sortBy, direction);
            }
        }, 500)
    , []);

    useEffect(() => {
        props.setPageTitle('HELD Devices');
        props.setPageTitleId('held-devices-header');
    }, []);

    return (
        <div>
             <SearchField 
                value={searchValue}
                onChange={handleInputSearchChange}
            />
            <br />
            <br />
            <HeldDevicesTable 
                        sortColumns={sortColumns}
                        setSortColumns={setSortColumns}
                        currentSortColumn={currentSortColumn}
                        setCurrentSortColumn={setCurrentSortColumn}
                        searchTerm={searchValue} 
                        page={page}
                        setPage={setPage} 
                        pageSize={props.pageSize}
                        />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return { 
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        fetchHeldDevicesList: (page, pageSize, searchTerm, sortBy, direction) => dispatch(HeldDevicesActions.getHeldDevicesRequest(page, pageSize, searchTerm, sortBy, direction))
    }
};

const withReducer = injectReducer({ key: 'heldDevices', reducer });
const withSaga = injectSaga({ key: 'heldDevices', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(HeldDevicesPage);