const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    icon: {
        position: 'absolute',
        right: '19px',
        bottom: '82px'
    },
    sip: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    }
});

export default styles;