/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */

import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

const API_ACCESS_KEY = 'API_ACCESS_KEY/';
export const types = {
        GET_API_ACCESS_KEY: API_ACCESS_KEY + 'GET_API_ACCESS_KEY',
        GET_API_ACCESS_KEY_SUCCESS: API_ACCESS_KEY + 'GET_API_ACCESS_KEY_SUCCESS',
        GET_API_ACCESS_KEY_ERROR: API_ACCESS_KEY + 'GET_API_ACCESS_KEY_ERROR',
        ADD_API_ACCESS_KEY: API_ACCESS_KEY + 'ADD_API_ACCESS_KEY',
        ADD_API_ACCESS_KEY_SUCCESS: API_ACCESS_KEY + 'ADD_API_ACCESS_KEY_SUCCESS',
        ADD_API_ACCESS_KEY_ERROR: API_ACCESS_KEY + 'ADD_API_ACCESS_KEY_ERROR',
        EDIT_API_ACCESS_KEY: API_ACCESS_KEY + 'EDIT_API_ACCESS_KEY',
        EDIT_API_ACCESS_KEY_SUCCESS: API_ACCESS_KEY + 'EDIT_API_ACCESS_KEY_SUCCESS',
        DELETE_API_KEY: API_ACCESS_KEY + 'DELETE_API_KEY',
        DELETE_API_KEY_SUCCESS: API_ACCESS_KEY + 'DELETE_API_KEY_SUCCESS'
}

export const initialState = fromJS({
        apiAccessKeyList: List(),
        apiAccessKeyLoading: false,
        apiAccessKeyListPageCount: -1
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
        switch (action.type) {
                case types.GET_API_ACCESS_KEY:
                        return state.set('apiAccessKeyLoading', true);
                case types.GET_API_ACCESS_KEY_SUCCESS:
                        return state.set('apiAccessKeyList', List(action.apiAccessKey))
                                .set('apiAccessKeyLoading', false)
                                .set('apiAccessKeyListPageCount', action.pageCount);
                case types.GET_API_ACCESS_KEY_ERROR:
                        return state.set('apiAccessKeyLoading', false);
                default:
                        return state;
        }
}

// Action creators
export const actions = {
        getApiAccessKeyRequest: (companyId, page, pageSize, searchTerm, sortBy, direction) => ({
                type: types.GET_API_ACCESS_KEY,
                companyId,
                page,
                pageSize,
                searchTerm,
                sortBy,
                direction
        }),
        apiAccessKeySuccess: (apiAccessKey, pageCount) => ({
                type: types.GET_API_ACCESS_KEY_SUCCESS,
                apiAccessKey,
                pageCount
        }),
        apiAccessKeyError: () => ({
                type: types.GET_API_ACCESS_KEY_ERROR
        }),
        addApiAccessKeyRequest: (apiAccessKey) => ({
                type: types.ADD_API_ACCESS_KEY,
                data: apiAccessKey
        }),
        addApiAccessKeySuccess: () => ({
                type: types.ADD_API_ACCESS_KEY_SUCCESS
        }),
        addApiAccessKeyError: (error) => ({
                type: types.ADD_API_ACCESS_KEY_ERROR,
                error: error.data
        }),
        editApiAccessKeyRequest: (apiAccessKey, row) => ({
                type: types.EDIT_API_ACCESS_KEY,
                data: apiAccessKey,
                row: row
        }),
        editApiAccessKeySuccess: () => ({
                type: types.EDIT_API_ACCESS_KEY_SUCCESS
        }),
        deleteApiAccessKeyRequest: (id, parentId) => ({
                type: types.DELETE_API_KEY,
                data: id,
                parentId: parentId
        }),
        deleteApiAccessKeySuccess: () => ({
                type: types.DELETE_API_KEY_SUCCESS
        })
}

// Selectors
const selectApiAccessKey = state => state.get('apiAccessKey', initialState);

export const ApiAccessKeyLoading = () =>
        createSelector(selectApiAccessKey, apiAccessKeyState=> apiAccessKeyState.get('apiAccessKeyLoading'));

export const makeSelectApiAccessKeyList = () =>
        createSelector(selectApiAccessKey, apiAccessKeyState => apiAccessKeyState.get('apiAccessKeyList'));

export const makeSelectApiAccessKeyPageCount = () =>
        createSelector(selectApiAccessKey, apiAccessKeyState => apiAccessKeyState.get('apiAccessKeyListPageCount'));