import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import download from 'downloadjs/download.js';
import './CallHistoryRecording.css';
import moment from 'moment';
import NumberFormatter from '../../../components/NumberFormatter/NumberFormatter';
import CallHistoryAPI from '../../../apis/callHistoryApi';

class CallHistoryRecordingModal extends Component {
    state = {
        duration: 0,
        recordingLink: '',
        isSafari: false
    }
    
    componentDidMount() {
        // Loads audio duration 
        const video = this.video
        video.onloadedmetadata = () => {
            const minutes = parseInt(video.duration / 60, 10);
            let seconds = parseInt(video.duration % 60);
            
            if (seconds < 10) {
                seconds = 0 + seconds.toString();
            }
            
            this.setState({
                duration: minutes+":"+seconds
            });
        }

        CallHistoryAPI.getCallHistoryPreSignedLink(this.props.modalData.callId).then(response => {
            this.setState({
                recordingLink: response.data
            });
        })

        this.setState({
            isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        });
    }
    
    // Downloads the audio file
    downloadAudio() {
        CallHistoryAPI.getCallHistoryPreSignedLink(this.props.modalData.callId).then(response => {
            fetch(response.data)
            .then(response => {
                response.blob().then(
                    blob => {
                        download(blob, "call.mp3" );
                    }
                );
            });
        })
    }
    
    formatLastAccess(callTime) {
        return callTime ?  moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
    }
    
    render() {
        return(
            <div className="call-history-recording">
                <div className="title">
                    <h3>{this.props.modalData.deviceUserId || this.props.modalData.locationAlternateId? 'Callback Number: ' : 'Phone Number: '}{
                    this.props.modalData.deviceUserId || this.props.modalData.locationAlternateId ? !isNaN(this.props.modalData.callbackNumber) ? <NumberFormatter number={this.props.modalData.callbackNumber} /> : this.props.modalData.callbackNumber : !isNaN(this.props.modalData.callingParty) ? <NumberFormatter number={this.props.modalData.callingParty} /> : this.props.modalData.callingParty}</h3>
                    <h3>Org Name: {this.props.modalData.companyName}</h3>
                    <h3>Call Time: {this.formatLastAccess(this.props.modalData.callTime)}</h3>
                    <h3>Duration: {this.state.duration}</h3>
                </div>
                <video style={{
                    width: '400px',
                    margin: '30px auto',
                    display: 'block',
                    height: this.state.isSafari ? '' : '40px'
                }} ref={video => { this.video = video }} controls type="audio/mp3" src={this.state.recordingLink} preload="auto">
                </video>
                <Grid container direction="row" justify="center" alignItems="center" style={{marginTop: '30px'}}>
                    <Button className="button" variant="contained" color="primary" onClick={() => this.downloadAudio()}>
                        Download
                    </Button> 
                </Grid>
            </div>
        );
    }
}

export default compose(
    ModalWrapper,
)(CallHistoryRecordingModal);