import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import BuildingsAPI from '../../apis/buildingsApi';
import LocationsAPI from '../../apis/locationsApi';
import { connect } from 'react-redux';
import { userCompanyId, selectUserCompany, selectDataSyncEnabled } from '../../reducers/auth';
import { selectCerEnabled } from '../../reducers/app';
import { createStructuredSelector } from 'reselect';
import './BuildingOrLocationAsyncSelect.css';
import { flatten } from 'lodash-es';

function BuildingOrLocationAsyncSelect(props) {
  const promiseOptions = inputValue => {
  if (props.cerToggle || props.company.toJS().locationTreeType === 'SIMPLE') {
    return LocationsAPI.getLocations(props.companyId, 1, 100, 'name', 'ASC', inputValue, props.cerToggle ? 'CER' : 'default').then(response => {
      response.data.forEach(obj => {
        obj.value = obj.id;
        obj.label = obj.name;
      });
      return response.data;
    }).catch(error => {
      console.log(error);
    });
  } else {
    if (props.dataSyncEnabled) {
      let managerBuildings = BuildingsAPI.getBuildingsNameSearch(props.companyId, 1, 100, inputValue, 'MANAGER_DATA_SYNC').then(response => {
        response.data.forEach(obj => {
          obj.value = obj.id;
          obj.label = obj.name;
        })
        return response.data;
      });
      let anywhereBuildings = BuildingsAPI.getBuildingsNameSearch(props.companyId, 1, 100, inputValue).then(response => {
        response.data.forEach(obj => {
          obj.value = obj.id;
          obj.label = obj.name;
        });
        return response.data;
      });
      return Promise.all([managerBuildings, anywhereBuildings]).then(array => 
        [...new Set([...flatten(array)].map(JSON.stringify))].map(JSON.parse))
    } else {
      return BuildingsAPI.getBuildingsNameSearch(props.companyId, 1, 100, inputValue, 'default').then(response => {
        response.data.forEach(obj => {
          obj.value = obj.id;
          obj.label = obj.name;
        });
        return response.data;
      }).catch(error => {
        console.log(error);
      });
    }
  }
	
  }

  function handleMultiSelectChange(selectedItems) { 
	if (props.setOrganizationByOrgType) {
      props.setOrganizationByOrgType(selectedItems);
    } else {
      props.setSelectedBuildings(selectedItems);
    }
  }

  useEffect(() => {
    if (Array.isArray(props.defaultValue)) {
      props.defaultValue.forEach(object => {
        object.label = object.name;
        object.value = object.id;
      });
    }
  }, []);

  return (
    <AsyncSelect
      onBlur={props.handleBlur ? props.handleBlur : null}
      placeholder=""
      inputId={props.id}
      className={props.error ? 'multi-select error' : 'multi-select'}
      isDisabled={props.isDisabled ? props.isDisabled : false}
      isMulti={props.isMulti ? props.isMulti : false}
      defaultOptions={true}
      defaultValue={props.defaultValue ? props.defaultValue : false}
      onChange={props.onChange ? props.onChange : handleMultiSelectChange}
      loadOptions={promiseOptions}
      closeMenuOnSelect={!props.isMulti}
      theme={(theme) => ({
        ...theme,
        colors: {
        ...theme.colors,
          primary25: 'rgba(0, 0, 0, 0.1)',
          primary: 'rgba(0, 0, 0, 0.2)',
        },
      })}
    />
  );
}

const mapStateToProps = () => createStructuredSelector({
	companyId: userCompanyId(),
	company: selectUserCompany(),
  	cerEnabled: selectCerEnabled(),
 	dataSyncEnabled: selectDataSyncEnabled(),
});

export default connect(mapStateToProps)(BuildingOrLocationAsyncSelect)