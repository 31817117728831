import React, { useEffect, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { Formik } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions, makeSelectMultipleAddressList, selectAddressIsAmbiguous, selectAddressIsAlternate } from '../../../reducers/locations';
import AddressAndGeoComponent from '../../../components/AddressAndGeoComponent/AddressAndGeoComponent';
import { BuildingValidation } from './Validations';
import { selectUserCompany } from '../../../reducers/auth';

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    autoCompleteOptions: {
        width: '355px'
    },
    paper: {
        position: 'absolute',
        zIndex: 999,
        marginTop: theme.spacing.unit,
    }
});
const EditBuildingForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit, 
            setFieldValue,
            setFieldTouched,
            modalLoading,
            multipleAddresses,
            modalErrorOptions,
            isAmbiguous,
            setAddressIsAlternate,
            isAlternate,
            overrideOrganizationNameToggle,
            setOverrideOrganizationNameToggle,
            classes } = props;

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }
    function handleOverrideOrganizationNameToggle() {
        setOverrideOrganizationNameToggle(!overrideOrganizationNameToggle);
    }

    function handleClear() {
        if (modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) {
            modalErrorOptions.backendFieldError.name = '';
        }
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
                <TextField
                    id="name"
                    label="*Building Name"
                    name="name"
                    type="name"
                    onChange={(e) => {
                        handleChange(e);
                        handleClear();
                    }}
                    value={values.name}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) || (modalErrorOptions && !!modalErrorOptions.backendFieldError && !!modalErrorOptions.backendFieldError.name)} />
                {(modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) && 
                    <span id="name-helper-text" className="error-prompt">{modalErrorOptions.backendFieldError.name}</span>
                }
                <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
                <br/>
                <AddressAndGeoComponent
                    {...props}
                    addressIsAlternate={isAlternate}
                    setAddressIsAlternate={setAddressIsAlternate}
                    addressIsAmbiguous={isAmbiguous}
                    multipleAddressList={multipleAddresses}
                />
                <TextField
                    id="supplemental"
                    label="Supplemental Information"
                    name="supplemental"
                    onChange={handleChange}
                    value={values.supplemental}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={(touched.supplemental && Boolean(errors.supplemental)) || (errors.supplemental && Boolean(errors.supplemental.includes('max')))}/>
                    <span id="supplemental-helper-text" className="error-prompt">{(touched.supplemental && Boolean(errors.supplemental)) || (errors.supplemental && Boolean(errors.supplemental.includes('max'))) ? errors.supplemental : ''}</span>
                <br/>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <label html="overrideOrganizationNameToggle">Override Organization Name</label>
                    <Switch
                        id="overrideOrganizationNameToggle"
                        name="overrideOrganizationNameToggle" /* important! this is how handleChange finds the field to update */
                        color="primary"
                        checked={overrideOrganizationNameToggle}
                        onChange={handleOverrideOrganizationNameToggle}
                        value='overrideOrganizationNameToggle'
                        disabled={modalLoading}/> 
                </Grid>       
                {
                    overrideOrganizationNameToggle && 
                    <div>
                        <TextField
                            id="overrideOrganizationName"
                            label="Organization Name"
                            name="overrideOrganizationName"
                            onChange={handleChange}
                            onKeyDown={e => {
                                if (e.key === '\\') {
                                    e.preventDefault();
                                }
                            }}
                            onPaste={e => {
                                const str = e.clipboardData.getData('Text');
                                const newStr = str.replace("\\", '');
                                if (str !== newStr) {
                                    e.preventDefault()
                                }
                            }}
                            value={values.overrideOrganizationName}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            error={(touched.overrideOrganizationName && Boolean(errors.overrideOrganizationName)) || (errors.overrideOrganizationName && Boolean(errors.overrideOrganizationName.includes('max')))}/>
                        <span id="overrideOrganizationName-helper-text" className="error-prompt">{(touched.overrideOrganizationName && Boolean(errors.overrideOrganizationName)) || (errors.overrideOrganizationName && Boolean(errors.overrideOrganizationName.includes('max'))) ? errors.overrideOrganizationName : ''}</span>
                    </div>
                }
            <br/>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

const EditBuildingModal = props => {
    const { modalLoading, classes, autoComplete, modalData, autoCompleteOptions, multipleAddresses, modalErrorMessage, isAmbiguous, setAddressIsAmbiguous, setAddressIsAlternate, isAlternate } = props;
    
    const [overrideOrganizationNameToggle, setOverrideOrganizationNameToggle] = useState(modalData.orgNameOverride ? true : false);
    const [geoCordsAreRequired, setGeoCordsAreRequired] = useState(true);
    const [addressIsRequired, setAddressIsRequired] = useState(true);
    const [addressAndGeoCoordsToggle, setAddressAndGeoCoordsToggle] = useState(modalData.address ? 'address' : 'geoCoords');

    const formRef = useRef();

    useEffect(() => {
        return () => {
            setAddressIsAmbiguous(false);
            setAddressIsAlternate(false);
        }
    }, []);

    function displayLatitude(modalData) {
        if (modalData.geoCoords && modalData.geoCoords.latitude) {
            return modalData.geoCoords.latitude;
        } else if (modalData.address && modalData.address.latitude) {
            return modalData.address.latitude;
        } else {
            return '';
        }
    }
    
    function displayLongitude(modalData) {
        if (modalData.geoCoords && modalData.geoCoords.longitude) {
            return modalData.geoCoords.longitude;
        } else if (modalData.address && modalData.address.longitude) {
            return modalData.address.longitude;
        } else {
            return '';
        }
    }

    return(
        <Formik
            innerRef={formRef}
            initialValues={{ 
                houseNumber: modalData.address && modalData.address.houseNumber ?  modalData.address.houseNumber : '',
                houseNumberExtension: modalData.address && modalData.address.houseNumberExtension ? modalData.address.houseNumberExtension : '',
                prefixDirection: modalData.address && modalData.address.prefixDirectional ? modalData.address.prefixDirectional : '',
                streetType: modalData.address && modalData.address.streetType ? modalData.address.streetType : '',
                postDirection: modalData.address && modalData.address.postDirectional ? modalData.address.postDirectional : '',
                street: modalData.address && modalData.address.streetName ? modalData.address.streetName : '',
                city: modalData.address && modalData.address.city ? modalData.address.city : '',
                stateProvince: modalData.address && modalData.address.state ? modalData.address.state : '',
                zip: modalData.address && modalData.address.zipCode ? modalData.address.zipCode : '',
                country: modalData.address && (modalData.address.country === 'USA' || modalData.address.country === 'US') ? 'US' : 'CA',
                name: modalData.name, 
                address: modalData.address && modalData.address.normalizedAddress ? modalData.address.normalizedAddress : '',
                latitude: modalData.address && modalData.address.normalizedAddress ? '' : displayLatitude(modalData),
                longitude: modalData.address && modalData.address.normalizedAddress ? '' : displayLongitude(modalData),
                supplemental: modalData.supplementalData ? modalData.supplementalData : '',
                overrideOrganizationName: modalData.orgNameOverride ? modalData.orgNameOverride : '',
                multipleAddressSelect: ''
            }}
            validationSchema={BuildingValidation(isAlternate, isAmbiguous, formRef.current && formRef.current.values.multipleAddressSelect, geoCordsAreRequired, addressIsRequired)}
            onSubmit = {
                (values) => {
                    if (values.multipleAddressSelect) {
                        values.address = JSON.parse(values.multipleAddressSelect);
                    }
                    
                    values.id = modalData.id;
                    values.parentId = modalData.parentId;
                    if(!overrideOrganizationNameToggle) {
                        values.overrideOrganizationName = undefined;
                    }
                    props.submitEditBuildingForm(values);
                }
            }
            render={formikProps => <EditBuildingForm 
                                                {...formikProps}
                                                addressAndGeoCoordsToggle={addressAndGeoCoordsToggle}
                                                setAddressAndGeoCoordsToggle={setAddressAndGeoCoordsToggle}
                                                addressIsRequired={addressIsRequired}
                                                setAddressIsRequired={setAddressIsRequired}
                                                geoCordsAreRequired={geoCordsAreRequired}
                                                setGeoCordsAreRequired={setGeoCordsAreRequired}
                                                setAddressIsAlternate={setAddressIsAlternate}
                                                modalErrorOptions={props.modalErrorOptions}
                                                autoCompleteOptions={autoCompleteOptions}
                                                autoComplete={autoComplete} 
                                                isAlternate={isAlternate} 
                                                classes={classes} 
                                                modalLoading={modalLoading} 
                                                modalErrorMessage={modalErrorMessage}
                                                multipleAddresses={multipleAddresses}
                                                isAmbiguous={isAmbiguous}
                                                overrideOrganizationNameToggle={overrideOrganizationNameToggle}  
                                                setOverrideOrganizationNameToggle={setOverrideOrganizationNameToggle}
                                                company={props.company}
                                                 />}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    multipleAddresses: makeSelectMultipleAddressList(),
    isAmbiguous: selectAddressIsAmbiguous(),
    isAlternate: selectAddressIsAlternate(),
    company: selectUserCompany()
});

const mapDispatchToProps = dispatch => {
    return {
        submitEditBuildingForm: (building) => dispatch(actions.editBuildingRequest(building)),
        setAddressIsAmbiguous: (flag) => dispatch(actions.addressIsAmbiguous(flag)),
        setAddressIsAlternate: (flag) => dispatch(actions.addressIsAlternate(flag))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(EditBuildingModal);