import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import PstnAPI from '../apis/pstnApi';
import CompanyAPI  from '../apis/companyApi'; 
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as PstnActions,
		 types as PstnTypes } from '../reducers/pstn';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';

function* getPstnList(action) {
	try {
		const companyId = yield select(userCompanyId());
		let response;
		if (companyId) {
			response = yield call(PstnAPI.getPstnById, companyId, action.page, action.pageSize, action.sortBy, action.direction);

			for (const org of response.data) {
				const companyResponse = yield call(CompanyAPI.getOrganizationById, org.callRouteInfo.organizationId);
				org.name = companyResponse.data.name;
			}
		} else {
			response = yield call(PstnAPI.getPstn, action.page, action.pageSize, action.sortBy, action.direction);

			for (const org of response.data) {
				if (org.callRouteInfo.organizationId) {
					const companyResponse = yield call(CompanyAPI.getOrganizationById, org.callRouteInfo.organizationId);
					org.name = companyResponse.data.name;	
				}
			}
		}
		
		yield put(PstnActions.getPstnSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(PstnActions.getPstnError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addPstn(action) {
	try {
		const tableProps = yield select(selectTableProps());
		
		const companyId = yield select(userCompanyId());
		yield put(AppActions.setModalLoading());
		yield call(PstnAPI.addPstn, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(PstnActions.getPstnRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editPstn(action) {
	try {
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(PstnAPI.editPstn, action.data);
		yield put(AppActions.setModalClose());
		yield put(PstnActions.getPstnRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deletePstn(action) {
	try {
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(PstnAPI.deletePstn, action.data);
		yield put(AppActions.setModalClose());
		yield put(PstnActions.getPstnRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootPstnSaga() {
	yield all([
		yield takeLatest(PstnTypes.GET_PSTN_REQUEST, getPstnList),
		yield takeLatest(PstnTypes.ADD_PSTN, addPstn),
		yield takeLatest(PstnTypes.EDIT_PSTN, editPstn),
		yield takeLatest(PstnTypes.DELETE_PSTN, deletePstn)
	]);
}

export default rootPstnSaga;
