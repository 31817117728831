import React from 'react';
import Button from '@material-ui/core/Button';
import DialerSip from '@material-ui/icons/DialerSip';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as AppActions } from '../../reducers/app';
import { types } from '../../reducers/callHistory';

class CallHistorySipInvite extends React.Component {
  handleClick = event => {
    this.props.setCurrentModal(types.CALL_HISTORY_SIP_INVITE, this.props.row.original);
  };
  
  render() {
    return (
        <div>
            <Button
                id={`call-history-sip-invite-button-${this.props.row.original.id}`}
                style={{ minWidth: 40 }}
                aria-haspopup="true"
                color="primary"
                onClick={this.handleClick}
                title="SIP Invite"
            >
                <DialerSip />
            </Button>
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default compose(
    connect(null, mapDispatchToProps),
)(CallHistorySipInvite);