/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */

import { fromJS, Map, List } from 'immutable';
import { createSelector } from 'reselect';

const ORGANIZATIONS = 'ORGANIZATIONS/';
export const types = {
        GET_ORGANIZATIONS: ORGANIZATIONS + 'GET_ORGANIZATIONS',
        GET_ORGANIZATIONS_SUCCESS: ORGANIZATIONS + 'GET_ORGANIZATIONS_SUCCESS',
        GET_ORGANIZATIONS_ERROR: ORGANIZATIONS + 'GET_ORGANIZATIONS_ERROR',
        GET_ORGANIZATIONS_BY_PARENT_ID: ORGANIZATIONS + 'GET_ORGANIZATIONS_BY_PARENT_ID',
        GET_ORGANIZATIONS_BY_PARENT_ID_SUCCESS: ORGANIZATIONS + 'GET_ORGANIZATIONS_BY_PARENT_ID_SUCCESS',
        GET_ORGANIZATIONS_BY_PARENT_ID_ERROR: ORGANIZATIONS + 'GET_ORGANIZATIONS_BY_PARENT_ID_ERROR',
        GET_SUBTENANTS_REQUEST: ORGANIZATIONS + 'GET_SUBTENANTS_REQUEST',
        GET_SUBTENANTS_SUCCESS: ORGANIZATIONS + 'GET_SUBTENANTS_SUCCESS',
        GET_SUBTENANTS_ERROR: ORGANIZATIONS + 'GET_SUBTENANTS_ERROR',
        GET_COMPANY_STATS: ORGANIZATIONS + 'GET_COMPANY_STATS',
        GET_LICENSING_STATS: ORGANIZATIONS + 'GET_LICENSING_STATS',
        ADD_ORGANIZATION: ORGANIZATIONS + 'ADD_ORGANIZATION',
        ADD_ORGANIZATION_SUCCESS: ORGANIZATIONS + 'ADD_ORGANIZATION_SUCCESS',
        ADD_ORGANIZATION_ERROR: ORGANIZATIONS + 'ADD_ORGANIZATION_ERROR',
        ADD_SUBTENANT: ORGANIZATIONS + 'ADD_SUBTENANT',
        ADD_SUBTENANT_SUCCESS: ORGANIZATIONS + 'ADD_ORGANIZATION_SUCCESS',
        EDIT_ORGANIZATION: ORGANIZATIONS + 'EDIT_ORGANIZATION',
        EDIT_ORGANIZATION_SUCCESS: ORGANIZATIONS + 'EDIT_ORGANIZATION_SUCCESS',
        DELETE_ORGANIZATION: ORGANIZATIONS + 'DELETE_ORGANIZATION',
        DELETE_ORGANIZATION_SUCCESS: ORGANIZATIONS + 'DELETE_ORGANIZATION_SUCCESS'
}

export const initialState = fromJS({
        organizationsList: List(),
        organizationsLoading: false,
        subtenantsList: Map(),
        organizationsListPageCount: -1
});

export default (state = initialState, action) => {
        switch (action.type) {
                case types.GET_ORGANIZATIONS_BY_PARENT_ID:
                case types.GET_ORGANIZATIONS:
                        return state.set('organizationsLoading', true);
                case types.GET_ORGANIZATIONS_BY_PARENT_ID_SUCCESS:
                case types.GET_ORGANIZATIONS_SUCCESS:
                        return state.set('organizationsList', List(action.organizations))
                                .set('organizationsLoading', false)
                                .set('organizationsListPageCount', action.pageCount);
                case types.GET_ORGANIZATIONS_BY_PARENT_ID_ERROR:
                case types.GET_ORGANIZATIONS_ERROR:
                        return state.set('organizationsLoading', false);
                case types.GET_SUBTENANTS_REQUEST:
                        return state.setIn(['subtenantsList', action.parentId, 'loading'], true);
                case types.GET_SUBTENANTS_SUCCESS:
                        return state.setIn(['subtenantsList', action.parentId, 'list'], List(action.organizations))
                                .setIn(['subtenantsList', action.parentId, 'loading'], false)
                                .setIn(['subtenantsList', action.parentId, 'pageCount'], action.pageCount);
                case types.GET_SUBTENANTS_ERROR:
                        return state.setIn(['subtenantsList', action.parentId, 'loading'], false);
                default:
                        return state;
        }
}

// Action creators
export const actions = {
        getOrganizationsRequest: (page, pageSize, searchTerm, sortBy, sortDirection) => ({
                type: types.GET_ORGANIZATIONS,
                page,
                pageSize,
                searchTerm,
                sortBy,
                sortDirection
        }),
        organizationsLoaded: (organizations, pageCount) => ({
                type: types.GET_ORGANIZATIONS_SUCCESS,
                organizations,
                pageCount
        }),
        organizationsLoadingError: () => ({
                type: types.GET_ORGANIZATIONS_ERROR
        }),
        getOrganizationsByParentId: (parentId, page, pageSize, searchTerm, sortBy, sortDirection) => ({
                type: types.GET_ORGANIZATIONS_BY_PARENT_ID,
                parentId,
                page,
                pageSize,
                searchTerm,
                sortBy, 
                sortDirection
        }),
        getOrganizationsByParentIdSuccess: (parentId, organizations, pageCount) => ({
                type: types.GET_ORGANIZATIONS_BY_PARENT_ID_SUCCESS,
                parentId,
                organizations,
                pageCount
        }),
        getOrganizationsByParentIdError: (error) => ({
                type: types.GET_ORGANIZATIONS_BY_PARENT_ID_ERROR,
                message: error
        }),
        getSubtenantsRequest: (parentId, page, pageSize, searchTerm, sortBy, sortDirection) => ({
                type: types.GET_SUBTENANTS_REQUEST,
                parentId,
                page,
                pageSize,
                searchTerm,
                sortBy,
                sortDirection
        }),
        getSubtenantsSuccess: (parentId, organizations, pageCount) => ({
                type: types.GET_SUBTENANTS_SUCCESS,
                parentId,
                organizations,
                pageCount
        }),
        getSubtenantsError: (parentId) => ({
                type: types.GET_SUBTENANTS_ERROR,
                parentId
        }),
        getCompanyStats: (orgId, orgLevel, parentId) => ({
                type: types.GET_COMPANY_STATS,
                orgId,
                orgLevel,
                parentId
        }),
        getLicensingStats: (orgId, parentId) => ({
                type: types.GET_LICENSING_STATS,
                orgId,
                parentId
        }),
        addOrganizationRequest: (organization) => ({
                type: types.ADD_ORGANIZATION,
                data: organization
        }),
        addOrganizationSuccess: () => ({
                type: types.ADD_ORGANIZATION_SUCCESS
        }),
        addOrganizationError: (error) => ({
                type: types.ADD_ORGANIZATION_ERROR,
                error: error.data
        }),
        editOrganizationRequest: (organization, row) => ({
                type: types.EDIT_ORGANIZATION,
                data: organization,
                row: row
        }),
        editOrganizationSuccess: () => ({
                type: types.EDIT_ORGANIZATION_SUCCESS
        }),
        deleteOrganizationRequest: (id, parentId) => ({
                type: types.DELETE_ORGANIZATION,
                data: id,
                parentId: parentId
        }),
        deleteOrganizationSuccess: () => ({
                type: types.DELETE_ORGANIZATION_SUCCESS
        }),
        updateCurrentCompanyLevel: (currentLevel) => ({
                type: types.CHANGE_CURRENT_LEVEL,
                data: currentLevel
        })
}

// Selectors
const selectOrganizations = state => state.get('organizations', initialState);

export const organizationsLoading = () =>
        createSelector(selectOrganizations, organizationsState => organizationsState.get('organizationsLoading'));

export const makeSelectOrganizationsList = () =>
        createSelector(selectOrganizations, organizationsState => organizationsState.get('organizationsList'));

export const makeSelectOrganizationsPageCount = () =>
        createSelector(selectOrganizations, organizationsState => organizationsState.get('organizationsListPageCount'));

export const selectSubtenantsList =
        createSelector(selectOrganizations, subtenantsState => subtenantsState.get('subtenantsList'));

export const selectSubtenantsByParentId = (parentId) =>
        createSelector(selectSubtenantsList, (subtenantsList) => subtenantsList.getIn([parentId, 'list']));

export const subtenantsLoading = (parentId) =>
        createSelector(selectSubtenantsList, (subtenantsList) => subtenantsList.getIn([parentId, 'loading']));

export const subtenantsPageCount = (parentId) =>
        createSelector(selectSubtenantsList, (subtenantsList) => subtenantsList.getIn([parentId, 'pageCount']));