import React, { useState, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";

import { Formik } from 'formik';
import { compose } from 'redux';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/sip';
import { connect } from 'react-redux';
import SipForm from './shared/SipForm';
import styles from './shared/stylesObject';
import validationSchema from './shared/validationSchema';

styles();

const AddSipModal = props => {
    const { modalLoading, modalData, classes } = props;
    const [monitorTrunk, setMonitorTrunk] = useState(false);
    const [disableRedskyRelayCenterOption, setDisableRedskyRelayCenterOption] = useState(modalData ? getInitialDisableRedskyRelayCenterOption(modalData) : 'TEST');


    const schema = validationSchema(monitorTrunk);


    function getInitialDisableRedskyRelayCenterOption(modalData) {
        if(modalData.disableRedskyRelayCenterOption == 'TEST') {
            return 'TEST';
        } else {
            return 'REJECTED_ERC';
        }
    }

    return(
        <Formik
            initialValues={{ 
                name: '',
                host: '',
                transport: 'UDP',
                overrideDestinationToggle: false,
                overrideDestination: 'TEST',
                skype: false,
                disableRelayCenter: null,
                monitorTrunk: false,
                portNumber: ''
            }}
            validationSchema={schema}
            onSubmit = {
                (values) => {
                    if (!values.overrideDestinationToggle) {
                        values.overrideDestination = null;
                    }

                    props.submitAddSipForm(values);
                }
            }
            render={renderProps => <SipForm setMonitorTrunk={setMonitorTrunk} classes={classes} modalLoading={modalLoading} {...renderProps} setDisableRedskyRelayCenterOption= {setDisableRedskyRelayCenterOption} disableRedskyRelayCenterOption={disableRedskyRelayCenterOption}/>}
        />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitAddSipForm: (sip) => dispatch(actions.addSipRequest(sip))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(AddSipModal);