import { axiosInstance } from './api';

/* Cirrus Admin-Service /networking-discovery Service */
const NETWORKING_SERVICE_ENDPOINT = 'networking-service/';

export default class NetworkDiscoveryAPI {
    static addMacAddress(macAddress, orgId) {
        const payload = {
            orgId,
            macAddress: macAddress.macAddress,
            description: macAddress.description,
            locationId: macAddress.location.id ? macAddress.location.id : macAddress.location.value
        };
        return axiosInstance.post(NETWORKING_SERVICE_ENDPOINT+'macAddress', payload);
    }

    static editMacAddress(macAddress, orgId) {
        const payload = {
            orgId,
            id: macAddress.id,
            macAddress: macAddress.macAddress,
            description: macAddress.description,
            locationId: macAddress.location.id ? macAddress.location.id : macAddress.location.value
        };
        return axiosInstance.put(NETWORKING_SERVICE_ENDPOINT+'macAddress', payload);
    }
    
    static getMacAddress(parentId, page, pageSize, sortBy, direction) {
        const params = {
            params: {
                page,
                pageSize,
                sortBy,
                direction
            }
        };

        return axiosInstance.get(NETWORKING_SERVICE_ENDPOINT+'macAddress/company/'+parentId, params);
    }
    
    static deleteMacAddress(macAddressId, companyId) {
        return axiosInstance.delete(NETWORKING_SERVICE_ENDPOINT+'macAddress/'+macAddressId + '?companyId=' + companyId);
    }
    
    static addBssid(bssid, orgId) {
        const payload = {
            orgId,
            bssid: bssid.bssid,
            description: bssid.description,
            locationId: bssid.location.id ? bssid.location.id : bssid.location.value
        };
        return axiosInstance.post(NETWORKING_SERVICE_ENDPOINT + 'bssid', payload);
    }

    static editBssid(bssid, orgId) {
        const payload = {
            orgId,
            id: bssid.id,
            bssid: bssid.bssid,
            description: bssid.description,
            locationId: bssid.location.id ? bssid.location.id : bssid.location.value
        };
        return axiosInstance.put(NETWORKING_SERVICE_ENDPOINT+'bssid', payload);
    }
    
    static getBssid(parentId, page, pageSize, sortBy, direction) {
        const params = {          
            params: {
                direction,
                page,
                pageSize,
                sortBy
            }
        }
        return axiosInstance.get(NETWORKING_SERVICE_ENDPOINT+'bssid/company/'+parentId, params);
    }
    
    static deleteBssid(bssidId, companyId) {
        return axiosInstance.delete(NETWORKING_SERVICE_ENDPOINT+'bssid/'+bssidId + '?companyId=' + companyId);
    }
        
    static addIpRange(ipRange, companyId) {
        const payload = {
            ipAddressLow: ipRange.ipAddressLow,
            ipAddressHigh: ipRange.ipAddressHigh,
            description: ipRange.description,
            locationId: ipRange.location.id ? ipRange.location.id : ipRange.location.value
        };
        return axiosInstance.post(NETWORKING_SERVICE_ENDPOINT+'ipRange?companyId=' + companyId, payload);
    }

    static editIpRange(ipRange, companyId) {
        const payload = {
            id: ipRange.id,
            ipAddressLow: ipRange.ipAddressLow,
            ipAddressHigh: ipRange.ipAddressHigh,
            description: ipRange.description,
            locationId: ipRange.location.id ? ipRange.location.id : ipRange.location.value
        };
        return axiosInstance.put(NETWORKING_SERVICE_ENDPOINT+'ipRange?companyId=' + companyId, payload);
    }
    
    static getIpRange(parentId, page, pageSize, sortBy, direction) {
        const params = {
            params: {
                page,
                pageSize,
                sortBy,
                direction
            }
        };

        return axiosInstance.get(NETWORKING_SERVICE_ENDPOINT+'ipRange/company/'+parentId, params);
    }
    
    static deleteIpRange(ipRangeId, companyId) {
        return axiosInstance.delete(NETWORKING_SERVICE_ENDPOINT+'ipRange/'+ipRangeId + '?companyId=' + companyId);
    }

    static addPublicIpRange(ipRange, companyId) {
        const payload = {
            companyId,
            ipAddressLow: ipRange.ipAddressLow,
            ipAddressHigh: ipRange.ipAddressHigh,
            description: ipRange.description
        };
        return axiosInstance.post(NETWORKING_SERVICE_ENDPOINT+'publicIpRange?companyId=' + companyId, payload);
    }

    static editPublicIpRange(ipRange, companyId) {
        const payload = {
            id: ipRange.id,
            ipAddressLow: ipRange.ipAddressLow,
            ipAddressHigh: ipRange.ipAddressHigh,
            description: ipRange.description
        };
        return axiosInstance.put(NETWORKING_SERVICE_ENDPOINT+'publicIpRange?companyId=' + companyId, payload);
    }
    
    static getPublicIpRange(parentId, page, pageSize, sortBy, direction, searchTerm) {
        const params = {
            params: {
                page,
                pageSize,
                sortBy,
                direction,
                searchTerm
            }
        };
        return axiosInstance.get(NETWORKING_SERVICE_ENDPOINT+'publicIpRange/company/'+parentId, params);
    }
    
    static deletePublicIpRange(ipRangeId, companyId) {
        return axiosInstance.delete(NETWORKING_SERVICE_ENDPOINT+'publicIpRange/'+ipRangeId + '?companyId=' + companyId);
    }
    
    static addLldp(lldp, companyId) {
        const payload = {
            companyId,
            chassisId: lldp.chassisId,
            description: lldp.description,
            locationId: lldp.location.id ? lldp.location.id : lldp.location.value
        };
        return axiosInstance.post(NETWORKING_SERVICE_ENDPOINT+'networkSwitch', payload);
    }

    static editLldp(lldp, companyId) {
        const payload = {
            companyId,
            id: lldp.id,
            chassisId: lldp.chassisId,
            description: lldp.description,
            locationId: lldp.location.id ? lldp.location.id : lldp.location.value
        };
        
        return axiosInstance.put(NETWORKING_SERVICE_ENDPOINT+'networkSwitch', payload);
    }
    
    static getLldp(parentId, page, pageSize, sortBy, direction) {
        const params = {
            params: {
                page,
                pageSize,
                sortBy,
                direction
            }
        };

        return axiosInstance.get(NETWORKING_SERVICE_ENDPOINT+'networkSwitch/company/'+parentId, params);
    }
    
    static deleteLldp(lldpId, companyId) {
        return axiosInstance.delete(NETWORKING_SERVICE_ENDPOINT+'networkSwitch/'+lldpId + '?companyId=' + companyId);
    }
    
    static addPort(port) {
        const payload = {
            networkSwitchId: port.chassisId,
            description: port.description,
            erlId: port.location.id ? port.location.id : port.location.value,
            portId: port.portId
        };
        return axiosInstance.post(NETWORKING_SERVICE_ENDPOINT+'networkSwitchPort', payload);
    }

    static editPort(port) {
        const payload = {
            id: port.id,
            networkSwitchId: port.networkSwitchId,
            description: port.description,
            erlId: port.location.id ? port.location.id : port.location.value,
            portId: port.portId
        };
        
        return axiosInstance.put(NETWORKING_SERVICE_ENDPOINT+'networkSwitchPort', payload);
    }
    
    static getPort(parentId, sortBy, direction) {
        return axiosInstance.get(NETWORKING_SERVICE_ENDPOINT+'networkSwitchPort/networkSwitch/'+parentId + `?sortBy=${sortBy}&direction=${direction}`);
    }
    
    static deletePort(portId, companyId) {
        return axiosInstance.delete(NETWORKING_SERVICE_ENDPOINT+'networkSwitchPort/'+portId + '?companyId=' + companyId);
    }
}