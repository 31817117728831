import { axiosInstance } from "./api";
/* Cirrus Admin-Service /company Controller */
const EON_USER_PROFILE_ENDPOINT = "admin-service/userProfile/";
const EON_USER_PROFILE_COMPANY_ENDPOINT = EON_USER_PROFILE_ENDPOINT + "company/";

export default class EonUsersAPI {
  static getEonUsersList(companyId, page, pageSize, searchTerm, sortBy, direction) {
    const params = {
      params: {
        roles: 'EON_USER',
        page,
        pageSize,
        searchTerm,
        sortBy,
        direction
      }
    };

    if (companyId) {
      return axiosInstance.get(
        EON_USER_PROFILE_COMPANY_ENDPOINT + companyId,
        params
      );
    }
    return axiosInstance.get(EON_USER_PROFILE_ENDPOINT, params);
  }

  static getAllEonUsers(companyId, searchTerm) {
    const params = {
      params: {
        eonUser: true,
        page: 1,
        pageSize: 100,
        searchTerm,
        sortBy: 'email',
        direction: 'ASC'
      }
    };

    return axiosInstance.get(EON_USER_PROFILE_COMPANY_ENDPOINT + companyId, params);
  }
  
  static getEonUserById(eonUserId) {
    return axiosInstance.get(EON_USER_PROFILE_ENDPOINT + eonUserId);
  }

  static addEonUser(eonUser) {
    const payload = {
      roleName: 'EON_USER',
      email: eonUser.email,
      firstName: eonUser.firstName,
      lastName: eonUser.lastName,
      companyId: eonUser.companyId,
      eonUser: true
    };

    return axiosInstance.post(EON_USER_PROFILE_ENDPOINT, payload);
  }

  static editEonUser(eonUser, row) {
    const payload = {
      roleName: 'EON_USER',
      id: row.id,
      email: eonUser.email,
      firstName: eonUser.firstName,
      lastName: eonUser.lastName,
      companyId: eonUser.companyId,
      eonUser: true
    };

    return axiosInstance.put(EON_USER_PROFILE_ENDPOINT, payload);
  }

  static deleteEonUser(id) {
    return axiosInstance.delete(EON_USER_PROFILE_ENDPOINT + id);
  }
}
