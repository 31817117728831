import { axiosInstance } from './api';

/* Cirrus Admin-Service /networking-discovery Service */
const TEST_CALL_GENERATOR_ENDPOINT = 'admin-service/callHistory/callTest';

export default class TestCallGeneratorAPI {
    static testCallGenerator(testCall) {
        const payload = {
            locationId: testCall.location.id ? testCall.location.id : testCall.location,
            companyId: testCall.parentId
        };
        return axiosInstance.post(TEST_CALL_GENERATOR_ENDPOINT, payload);
    }
}