import React from 'react';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';
import './RecipientListTable.css';

function RecipientListTable(props) {
    return (
        <div className="recipient-list-wrapper">
            {
                props.recipientsList.map((item, index) => {
                    if (item.email) {
                        return <div key={index} className="email">{item.email}</div>;
                    } else if (item.phoneNumber) {
                        return <div key={index} className="sms" >{!isNaN(item.phoneNumber) ? <NumberFormatter number={item.phoneNumber} /> : item.phoneNumber}</div>;
                    } else if (item.user) {
                        return <div key={index} className="user">{item.user}</div>;
                    } else if (item.name) {
                        return <div key={index} className="informacast">{item.name}</div>;
                    }
                    return null;
                })
            }
        </div>
    );
}

export default (RecipientListTable);