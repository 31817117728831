import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';

import { actions as AppActions,
         types as App, selectMyAccountFormSubmitted } from '../reducers/app';
import { updateUser } from '../reducers/auth';
import ProfileApi from '../apis/profileApi';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';

function* saveProfile(action) {
	try {
		const fromSubmitted = yield select(selectMyAccountFormSubmitted());
		const companyId = yield select(userCompanyId());
		action.data.companyId = companyId;
		const response = yield call(ProfileApi.edit, action.data);
		yield put(updateUser(response.data));
		yield put(AppActions.submitProfileSuccess(response));
		if (fromSubmitted) {
			yield put(AppActions.setCurrentModal(App.MY_ACCOUNT_MODAL));
		} else {
			yield put(push('/'));
		}
	} catch (error) {
		yield put(AppActions.submitProfileError(errorHandler(error)));
	}
}

function* rootAppSaga() {
	yield all([
		yield takeLatest(App.SAVE_PROFILE, saveProfile)
	]);
}

export default rootAppSaga;
