import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import NotificationIntegrationsAPI from '../apis/notificationIntegrationsApi';
import { actions as AppActions } from '../reducers/app';
import { actions as NotificationIntegrationsActions, types as NotificationIntegrations } from '../reducers/notificationIntegrations';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';
import CompanyApi from '../apis/companyApi';

function* getNotificationIntegrationsList(action) {
  try {
    const companyId = yield select(userCompanyId());
    const companyObjectResponse = yield call(CompanyApi.getOrganizationById, companyId);
    if(!companyObjectResponse || !companyObjectResponse.data.informacastToken) {
      yield put(NotificationIntegrationsActions.informacastConfigured(false));
      yield put(NotificationIntegrationsActions.notificationIntegrationsLoadingError());
    } 
    else {
      yield put(NotificationIntegrationsActions.informacastConfigured(true));
      const response = yield call(NotificationIntegrationsAPI.getNotificationIntegrationsList, companyId);
      yield put(
        NotificationIntegrationsActions.notificationIntegrationsLoaded(response.data)
      );
    }
  } catch (error) {
    yield put(NotificationIntegrationsActions.notificationIntegrationsLoadingError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);

  }
}

function* addInformaCastEntity(action) {
  try {
    yield put(AppActions.setModalLoading());
    yield call(NotificationIntegrationsAPI.addInformaCastEntity, action.data);
    yield put(AppActions.setModalClose());
    yield put(NotificationIntegrationsActions.getNotificationIntegrationsRequest());
  } catch (error) {
    console.log(error);
    let options = {};
    // first check field line errors
    if(error.response.data.type === "http://cirrus.redskytech.com/errors/informacast/uniqueName") {
      options.backendFieldError = {
       name: 'An InformaCast 911 Alert Entity with this name already exists.'
      };
    }

    // will set the red error box message
    if(!options.backendFieldError) {
      options.displayTopErrorMessage = true;
      options.topErrorMessage = error.response.data.title;
    }

    error.response.data.errorOptions = options;
    yield put(AppActions.setModalErrorOptions(errorHandler(error)));
  }
}

function* editInformaCastEntity(action) {
  try {
    yield put(AppActions.setModalLoading());
    yield call(NotificationIntegrationsAPI.editInformaCastEntity, action.data, action.row);
    yield put(AppActions.setModalClose());
    yield put(NotificationIntegrationsActions.getNotificationIntegrationsRequest());
  } catch (error) {
    console.log(error);
    let options = {};
    // first check field line errors
    if(error.response.data.type === "http://cirrus.redskytech.com/errors/informacast/uniqueName") {
      options.backendFieldError = {
       name: 'An InformaCast 911 Alert Entity with this name already exists.'
      };
    }

    // will set the red error box message
    if(!options.backendFieldError) {
      options.displayTopErrorMessage = true;
      options.topErrorMessage = error.response.data.title;
    }

    error.response.data.errorOptions = options;
    yield put(AppActions.setModalErrorOptions(errorHandler(error)));
  }
}

function* deleteInformaCastEntity(action) {
  try {
    yield put(AppActions.setModalLoading());
    yield call(NotificationIntegrationsAPI.deleteInformaCastEntity, action.data);
    yield put(AppActions.setModalClose());
    yield put(NotificationIntegrationsActions.getNotificationIntegrationsRequest());
  } catch (error) {
    console.log(error);
    yield put(AppActions.setModalError(errorHandler(error)));
  }
}

function* rootNotificationIntegrationsSaga() {
  yield all([
    yield takeLatest(NotificationIntegrations.GET_NOTIFICATION_INTEGRATIONS, getNotificationIntegrationsList),
    yield takeLatest(NotificationIntegrations.ADD_INFORMACAST_ENTITY, addInformaCastEntity),
    yield takeLatest(NotificationIntegrations.EDIT_INFORMACAST_ENTITY, editInformaCastEntity),
    yield takeLatest(NotificationIntegrations.DELETE_INFORMACAST_ENTITY, deleteInformaCastEntity)
  ]);
}

export default rootNotificationIntegrationsSaga;
