import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import { Formik } from 'formik';
import ModalWrapper from '../../../../components/ModalWrapper';
import { actions } from '../../../../reducers/alerts';
import { connect } from 'react-redux';
import AlertsAPI from '../../../../apis/alertsApi';
import setTemplateType from '../Subscriptions/util/SetTemplateType';
import './DeleteAlertsTemplates.css';

function DeleteAlertsTemplatesForm(props) {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        templateInUse,
        alertTemplates,
        modalData,
        setFieldValue,
        setFieldTouched } = props;
    return(
        <div>
            <form onSubmit={handleSubmit} autoComplete="off" className="subscription-form" noValidate="novalidate">
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    {templateInUse ? 
                        <Grid item >
                            <p>
                            <span style={{ color: 'rgb(177, 0, 12)', fontWeight: 'bold' }}>WARNING:</span> The following user-defined alert template cannot be deleted without first identifying a replacement, as there is currently at least one alert subscription that is using it:
                            </p>
                        </Grid>
                        :
                        <Grid item >
                            <p>
                            Please confirm that you would like to delete the following user-defined alert template:
                            </p>
                        </Grid>
                    }
                    <Grid item >
                        <b id="templateName">{modalData.name}</b>
                    </Grid>
                    <br/>
                    {templateInUse &&
                        <div>
                            <InputLabel style={{ marginRight: 'auto' }} shrink={true} htmlFor="template" error={touched.template && Boolean(errors.template)}>*Replacement Alert Template</InputLabel>
                            <Select
                                id="template"
                                name="template"
                                value={values.template}
                                onChange={handleChange}
                                disabled={modalLoading}
                                input={<Input style={{ width: '100%' }}/>}
                                fullWidth={true}
                                error={touched.template && Boolean(errors.template)}>
                                {   
                                    alertTemplates.map( (alertTemplate, index) => 
                                        <MenuItem key={index} value={alertTemplate}>{alertTemplate.name}</MenuItem>)
                                }            
                            </Select>
                            <br/>
                            <br/>
                        </div>
                    }
                    <Grid item style={{ textAlign: 'center' }}>
                        <Button 
                                id="deleteBtn"
                                variant="contained" 
                                type="submit"
                                disabled={!values.template && templateInUse}
                                color="secondary">
                                DELETE
                        </Button>
                    </Grid>
                    <br/>
                </Grid>
            </form>
        </div>
    );
}

function DeleteAlertsTemplatesModal(props) {
    const { modalLoading, modalData } = props;

    const [templateInUse, setTemplateInUse] = useState(false);
    const [alertTemplates, setAlertTemplates] = useState([]);

    useEffect(() => {
        AlertsAPI.getAlertTemplateById(props.modalData.id).then(response => {
            setTemplateInUse(response.data.inUse);
            if (response.data.inUse) {
                AlertsAPI.getAlertsTemplatesList(props.modalData.companyId, props.modalData.alertType.name).then(
                    (response) => {
                        setAlertTemplates(setTemplateType(response.data.filter(item => item.id !== props.modalData.id)));
                    }
                ).catch(error => {
                    console.log(error);
                });            
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);

    return(
        <Formik
            initialValues={{ 
                template: '',
            }}
            onSubmit = {
                (values) => {
                    if (values.template) {
                        props.submitDeleteAlertsTemplates(props.modalData.id, values.template.id);
                    } else {
                        props.submitDeleteAlertsTemplates(props.modalData.id);
                    }
                }
            }
            render={formikProps => <DeleteAlertsTemplatesForm 
                                        alertTemplates={alertTemplates}
                                        templateInUse={templateInUse}
                                        modalData={modalData}
                                        modalLoading={modalLoading}
                                        {...formikProps} />}
        />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteAlertsTemplates: (id, replacementId) => dispatch(actions.deleteAlertTemplateRequest(id, replacementId))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteAlertsTemplatesModal);