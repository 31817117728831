import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actions as AppActions, selectSuccessSnackbarQueue  } from '../../reducers/app';
import green from '@material-ui/core/colors/green';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
    success: {
        backgroundColor: green[600]
    },
    close: {
        padding: theme.spacing.unit / 2
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

function SuccessSnackbar(props) {
    const { classes } = props;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [note, setNote] = useState('');

    useEffect(() => {
        // process queue
        if (props.queue.size > 0) {
            setMessage(props.queue.first().message);
            setNote(props.queue.first().note);
            setOpen(true);
            props.shiftQueue();
        }
    }, [props.queue.size]);

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };
    
    return (
        <Snackbar
          className="snackbar-popup"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={<div>
              <span id="client-snackbar" className={classes.message}>
                  <CheckCircleIcon className={"classes.icon classes.iconVariant"} />
                  {message}
              </span>
              <i>{note}</i>
          </div>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />    
        );
}

const mapStateToProps = createStructuredSelector({
    queue: selectSuccessSnackbarQueue()
});

const mapDispatchToProps = dispatch => {
    return {
        shiftQueue: () => dispatch(AppActions.shiftSuccessSnackbarQueue())
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(SuccessSnackbar);