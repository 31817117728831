import React from 'react';

//STEP 1:
//create components using React.lazy
const Five9css = React.lazy(() => import('../../five9/Five9css'));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ConditionalStyling = ({ children }) => {
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(sessionStorage.getItem('skin') === 'five9') && <Five9css />}
      </React.Suspense>
      {children}
    </>
  )
}

export default ConditionalStyling;