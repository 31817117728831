import React, { useState, useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './MassNotificationSyncSelect.css';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { actions as massNotificationTemplatesActions,    
        makeSelectIntegrationCredErrorResponseType,
        makeSelectMassNotificationList,
  } from '../../../../reducers/organizationManagement';

  const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
const isEditAlertSubscriptions = user && user.permissions.includes('EDIT_ALERT_SUBSCRIPTIONS');

function MassNotificationSyncSelect(props) {
  const [selectedMNTemplate, setSelectedMNTemplate] = useState({});
  const [renderValue, setRender] = useState('');

  useEffect(() => {
    let currentValue = Array.from(props.massNotificationTemplates).filter(function(MNTemplate) {
      return MNTemplate.id == props.currentTemplate.value;
    });

    setSelectedMNTemplate({
      value: currentValue,
      label: currentValue[0] ? currentValue[0].name : null
    })
    props.getMassNotificationsTemplatesList(props.company.id)
  }, []);

  useEffect(() => {
    setRender('render')
  }, [selectedMNTemplate])

  const massNotificationTemplates = props.massNotificationTemplates.map((mnTemplate) => {
    return {
        value: mnTemplate,
        label: mnTemplate.name
      }
    },
  );

  function handleChange(input) {
    setSelectedMNTemplate({        
      value: input.value,
      label: input.value.name})
    props.submitMassNotificationTemplate(input.value, "MNTemplate", props.company.id);
  }

  return (
    <Select
        key={renderValue}
        placeholder=""
        className={props.error ? 'single-select error' : 'single-select'}
        isDisabled={!isEditAlertSubscriptions ? true : false}
        value={selectedMNTemplate}
        onChange={input => {
          handleChange(input)
        }}
        closeMenuOnSelect={true}
        options={massNotificationTemplates}
        theme={(theme) => ({
          ...theme,
          colors: {
          ...theme.colors,
            primary25: 'rgba(0, 0, 0, 0.1)',
            primary: 'rgba(0, 0, 0, 0.2)',
          },
        })}  
    />
  );
}

MassNotificationSyncSelect.propTypes = {
  massNotificationTemplates: ImmutablePropTypes.list,
  loading: PropTypes.bool.isRequired,
};


const mapStateToProps = () => createStructuredSelector({
  integrationCredErrorResponseType: makeSelectIntegrationCredErrorResponseType(),
  massNotificationTemplates: makeSelectMassNotificationList(),
});

const mapDispatchToProps = dispatch => {
  return {
      submitMassNotificationTemplate: (data, credType, companyId) => dispatch(massNotificationTemplatesActions.addIntegrationCred(data, credType, companyId)),
      integrationCredErrorResponseTypeAction: (data) => dispatch(massNotificationTemplatesActions.integrationCredErrorResponseType(data)),
      getMassNotificationsTemplatesList: (id) => dispatch(massNotificationTemplatesActions.getMassNotificationTemplatesRequest(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MassNotificationSyncSelect);