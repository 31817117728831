import React, { useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button';

import AddressAutoComplete from '../../components/AddressAutoComplete/AddressAutoComplete';
import AddressAlternate from '../../components/AddressAlternate/AddressAlternate';
import GoogleMapsComponent from '../../components/GoogleMapsComponent/GoogleMapsComponent';
import GeoCoordsComponent from '../../components/GeoCoordsComponent/GeoCoordsComponent';
import AddressGeoCoordsToggle from '../../components/AddressGeoCoordsToggle/AddressGeoCoordsToggle';

function AddressAndGeoComponent(props) {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        modalLoading,
        setFieldValue,
        setFieldTouched,
        multipleAddressList,
        addressIsAmbiguous,
        addressIsAlternate,
        setAddressIsAlternate,
        setGeoCordsAreRequired,
        geoCordsAreRequired,
        setAddressIsRequired,
        addressAndGeoCoordsToggle,
        setAddressAndGeoCoordsToggle,
        classes } = props;

    function handleRadioChange(event) {
        setFieldValue('multipleAddressSelect', event.target.value);
        setFieldTouched('multipleAddressSelect', true);
    }

    function removeDuplicateValues(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    function handleAlternateAddress() {
        setAddressIsAlternate(!addressIsAlternate);
    }

    useEffect(() => {
        if (setGeoCordsAreRequired) {
            setGeoCordsAreRequired(values && (values.address || values.houseNumber || values.houseNumberExtension || values.prefixDirection || values.street || values.city || values.stateProvince || values.zip));
        }

        if (setAddressIsRequired) {
            setAddressIsRequired(!!(values && (values.latitude && values.longitude)));
        }
    }, [values]);

    return (
        <div>
            <AddressGeoCoordsToggle
                {...props}
                addressAndGeoCoordsToggle={addressAndGeoCoordsToggle}
                setAddressAndGeoCoordsToggle={setAddressAndGeoCoordsToggle}
            />
            <br/>
            {addressAndGeoCoordsToggle === 'address' &&
                <div>
                    {addressIsAmbiguous ? 
                        <RadioGroup
                            aria-label="addresses"
                            name="addresses"
                            className={classes.group}
                            value={values.multipleAddressSelect}
                            onChange={handleRadioChange}
                        >
                            {removeDuplicateValues(multipleAddressList, 'normalizedAddress').map((address, index) => {
                                    if (address.normalizedAddress) {
                                    return <FormControlLabel key={index} value={JSON.stringify(address)} control={<Radio color="primary" />} label={address.normalizedAddress} />
                                } else {
                                    return null;
                                }
                                })
                            }
                            <span>{ values.multipleAddressSelect.length === 0 ? 
                                <div id="name-helper-text" className="error-prompt">{ multipleAddressList > 1 ?
                                'Multiple addresses found. Please select the appropriate one in order to proceed.' :
                                'We found this address. Please select it in order to proceed.'}
                                </div> : <div> </div> }
                            </span>
                        </RadioGroup>
                    : (addressIsAlternate) ? 
                        <AddressAlternate
                            {...props}
                            values={values}
                            errors={errors}
                            touched={touched}
                            modalLoading={modalLoading}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                        />
                        :
                        <AddressAutoComplete 
                            {...props}
                            values={values} 
                            setFieldValue={setFieldValue} 
                            setFieldTouched={setFieldTouched}
                            errors={errors}
                            classes={classes}
                            touched={touched}
                            modalLoading={modalLoading}
                            autoComplete="off"
                            companyId={props.company.toJS().id}
                        />
                    }

                    {addressIsAmbiguous ? '' :
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button 
                            onClick={handleAlternateAddress} 
                            style={{ margin: '5px 0 0 auto', color: '#1b536a', textTransform: 'capitalize', zIndex: 9 }} 
                            color="inherit">
                                Alternate address view
                        </Button>
                    </div>}
                </div>
            }
            {addressAndGeoCoordsToggle === 'geoCoords' &&
            <GeoCoordsComponent 
                    {...props}
                    geoCordsAreRequired={geoCordsAreRequired} 
                    values={values}
                    errors={errors}
                    touched={touched}
                    modalLoading={modalLoading}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                />
            }
            <br/>
            <GoogleMapsComponent 
                {...props} 
                values={values} 
                addressIsAlternate={addressIsAlternate} 
            />
        </div>
    );
}

export default AddressAndGeoComponent;