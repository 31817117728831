import React from 'react';

const HasAccess = ({ children, permissions }) => {
  const user = JSON.parse(sessionStorage.getItem('__permifyUser'));

  if (user && permissions.some(permission => user.permissions.includes(permission))) {
    return children;
  }
  return null;
};

export default HasAccess;
