import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';

class ShowHeldRequestMessageModal extends Component {
    render() {
        const { modalLoading, modalData } = this.props;

        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <pre>{modalData.heldRequestMessage}</pre>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default compose(
    ModalWrapper
)(ShowHeldRequestMessageModal);