/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const SCHEDULED_REPORTS = 'SCHEDULED_REPORTS/'; 
export const types = {
    GET_SCHEDULED_REPORTS_REQUEST: SCHEDULED_REPORTS + 'GET_SCHEDULED_REPORTS_REQUEST',
    GET_SCHEDULED_REPORTS_SUCCESS: SCHEDULED_REPORTS + 'GET_SCHEDULED_REPORTS_SUCCESS',
    SCHEDULED_REPORTS_DETAILS: SCHEDULED_REPORTS + 'SCHEDULED_REPORTS_DETAILS',
    SCHEDULED_REPORTS_RECORDING: SCHEDULED_REPORTS + 'SCHEDULED_REPORTS_RECORDING',
    SCHEDULED_REPORTS_SIP_INVITE: SCHEDULED_REPORTS + 'SCHEDULED_REPORTS_SIP_INVITE',
    GET_SCHEDULED_REPORTS_ERROR: SCHEDULED_REPORTS + 'GET_SCHEDULED_REPORTS_ERROR',
    SCHEDULED_REPORT: SCHEDULED_REPORTS + 'SCHEDULED_REPORT',
    EDIT_SCHEDULED_REPORT: SCHEDULED_REPORTS + 'EDIT_SCHEDULED_REPORT',
    DELETE_SCHEDULED_REPORT: SCHEDULED_REPORTS + 'DELETE_SCHEDULED_REPORT',
    PAUSE_SCHEDULED_REPORT: SCHEDULED_REPORTS + 'PAUSE_SCHEDULED_REPORT'
};

export const initialState = fromJS({
    scheduledReportsList: List(),
    scheduledReportsLoading: false,
    scheduledReportsListPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_SCHEDULED_REPORTS_REQUEST:
            return state.set('scheduledReportsLoading', true);
        case types.GET_SCHEDULED_REPORTS_ERROR:
            return state.set('scheduledReportsLoading', false);
        case types.GET_SCHEDULED_REPORTS_SUCCESS:
            return state.set('scheduledReportsLoading', false)
                        .set('scheduledReportsList', List(action.data))
                        .set('scheduledReportsListPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    getScheduledReportsRequest: (page, pageSize, sortBy, sortDirection) => ({
        type: types.GET_SCHEDULED_REPORTS_REQUEST,
        page,
        pageSize,
        sortBy,
        sortDirection
    }),
    getScheduledReportsSuccess: (scheduledReportsList, pageCount) => ({
        type: types.GET_SCHEDULED_REPORTS_SUCCESS,
        data: scheduledReportsList,
        pageCount
    }),
    getScheduledReportsError: () => ({
        type: types.GET_SCHEDULED_REPORTS_ERROR
    }),
    scheduledReport: (data) => ({
        type: types.SCHEDULED_REPORT,
        data
    }),
    editScheduledReport: (data, page, pageSize, currentSortColumn) => ({
        type: types.EDIT_SCHEDULED_REPORT,
        data,
        page,
        pageSize,
        currentSortColumn
    }),
    pauseScheduledReport: (data, page, pageSize, currentSortColumn) => ({
        type: types.PAUSE_SCHEDULED_REPORT,
        data,
        page,
        pageSize,
        currentSortColumn
    }),
    deleteScheduledReport: (id, page, pageSize, currentSortColumn) => ({
        type: types.DELETE_SCHEDULED_REPORT,
        data: id,
        page,
        pageSize,
        currentSortColumn
    }),
};

// Selectors
const selectScheduledReports = state => state.get('scheduledReports', initialState);

export const makeSelectScheduledReportsList = () =>
    createSelector(selectScheduledReports, scheduledReportsState => scheduledReportsState.get('scheduledReportsList'));

export const makeSelectScheduledReportsListLoading = () =>
    createSelector(selectScheduledReports, scheduledReportsState => scheduledReportsState.get('scheduledReportsLoading'));

export const makeSelectScheduledReportsPageCount = () =>
    createSelector(selectScheduledReports, scheduledReportsState => scheduledReportsState.get('scheduledReportsListPageCount'));