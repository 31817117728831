import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { types as NetworkDiscoveryTypes } from '../../../../reducers/networkDiscovery';
import BuildingAndLocationSingleComponent from '../../../../components/BuildingAndLocationSingleComponent/BuildingAndLocationSingleComponent';

const AddEditNetworkDiscoveryForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit, 
            modalLoading,
            setFormValues,
            classes } = props;

    useEffect(() => {
        setFormValues(values);
    }, [values]);

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    function networkDiscoveryTypeComponents() {
        switch (props.networkDiscoveryType) {
            case NetworkDiscoveryTypes.ADD_MAC_ADDRESS:
            case NetworkDiscoveryTypes.EDIT_MAC_ADDRESS:
                return (
                    <div>
                        <TextField
                            id="macAddress"
                            label="*MAC Address"
                            name="macAddress"
                            type="macAddress"
                            onChange={handleChange}
                            value={values.macAddress}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            error={(touched.macAddress && Boolean(errors.macAddress)) || (errors.macAddress && Boolean(errors.macAddress.includes('max')))}/> 
                            <span id="macAddress-helper-text" className="error-prompt">{(touched.macAddress && Boolean(errors.macAddress)) || (errors.macAddress && Boolean(errors.macAddress.includes('max'))) ? errors.macAddress : ''}</span>
                        <br/>
                    </div>
                );
            case NetworkDiscoveryTypes.ADD_BSSID:
            case NetworkDiscoveryTypes.EDIT_BSSID:
                return (
                    <div>
                        <TextField
                            id="bssid"
                            label="*BSSID"
                            name="bssid"
                            type="bssid"
                            onChange={handleChange}
                            value={values.bssid}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            error={(touched.bssid && Boolean(errors.bssid)) || (errors.bssid && Boolean(errors.bssid.includes('max')))}/> 
                            <span id="bssid-helper-text" className="error-prompt">{(touched.bssid && Boolean(errors.bssid)) || (errors.bssid && Boolean(errors.bssid.includes('max'))) ? errors.bssid : ''}</span>
                        <br/>
                    </div>
                );
            case NetworkDiscoveryTypes.ADD_IP_RANGE:
            case NetworkDiscoveryTypes.EDIT_IP_RANGE:
                return (
                    <div>
                        <TextField
                            id="ipAddressLow"
                            label="*Range Start"
                            name="ipAddressLow"
                            type="ipAddressLow"
                            onChange={handleChange}
                            value={values.ipAddressLow}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            error={(touched.ipAddressLow && Boolean(errors.ipAddressLow)) || (errors.ipAddressLow && Boolean(errors.ipAddressLow.includes('max')))}/> 
                            <span id="ipAddressLow-helper-text" className="error-prompt">{(touched.ipAddressLow && Boolean(errors.ipAddressLow)) || (errors.ipAddressLow && Boolean(errors.ipAddressLow.includes('max'))) ? errors.ipAddressLow : ''}</span>
                        <br/>
                        <TextField
                            id="ipAddressHigh"
                            label="*Range End"
                            name="ipAddressHigh"
                            type="ipAddressHigh"
                            onChange={handleChange}
                            value={values.ipAddressHigh}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            error={(touched.ipAddressHigh && Boolean(errors.ipAddressHigh)) || (errors.ipAddressHigh && Boolean(errors.ipAddressHigh.includes('max')))}/> 
                            <span id="ipAddressHigh-helper-text" className="error-prompt">{(touched.ipAddressHigh && Boolean(errors.ipAddressHigh)) || (errors.ipAddressHigh && Boolean(errors.ipAddressHigh.includes('max'))) ? errors.ipAddressHigh : ''}</span>
                        <br/>
                    </div>
                );
            case NetworkDiscoveryTypes.ADD_LLDP:
            case NetworkDiscoveryTypes.EDIT_LLDP:
                return (
                    <div>
                        <TextField
                            id="chassisId"
                            label="*Identifier"
                            name="chassisId"
                            type="chassisId"
                            onChange={handleChange}
                            value={values.chassisId}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            error={(touched.chassisId && Boolean(errors.chassisId)) || (errors.chassisId && Boolean(errors.chassisId.includes('max')))}/> 
                            <span id="chassisId-helper-text" className="error-prompt">{(touched.chassisId && Boolean(errors.chassisId)) || (errors.chassisId && Boolean(errors.chassisId.includes('max'))) ? errors.chassisId : ''}</span>
                        <br/>
                    </div>
                );
            case NetworkDiscoveryTypes.ADD_PORT:
            case NetworkDiscoveryTypes.EDIT_PORT:
                return (
                    <div>
                        <TextField
                            id="portId"
                            label="*Identifier"
                            name="portId"
                            type="portId"
                            onChange={handleChange}
                            value={values.portId}
                            onBlur={handleBlur}
                            disabled={modalLoading}
                            fullWidth={true}
                            error={(touched.portId && Boolean(errors.portId)) || (errors.portId && Boolean(errors.portId.includes('max')))}/> 
                            <span id="portId-helper-text" className="error-prompt">{(touched.portId && Boolean(errors.portId)) || (errors.portId && Boolean(errors.portId.includes('max'))) ? errors.portId : ''}</span>
                        <br/>
                    </div>
                );
            default:
                return '';
        }
    }

    return (
        <form className={classes.modal} onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            {networkDiscoveryTypeComponents()}
            <BuildingAndLocationSingleComponent 
                {...props}
            />
            <br/>
            <TextField
                id="description"
                label="Description"
                name="description"
                rows={5}
                multiline={true}
                variant= "outlined"
                onChange={handleChange}
                value={values.description}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max')))}/> 
                <span id="description-helper-text" className="error-prompt">{(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max'))) ? errors.description : ''}</span>
            <br/>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit"
                        disabled={modalLoading} 
                        className={classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

export default AddEditNetworkDiscoveryForm;