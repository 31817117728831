import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import AlertsAPI from '../apis/alertsApi';
import {actions as AppActions, selectTableProps} from '../reducers/app';
import { userCompanyId } from '../reducers/auth';
import { actions as AlertSubscriptionsActions,
		 types as AlertSubscriptionsTypes } from '../reducers/alerts';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';
import React from "react";

function* getAlertSubscriptionsList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(AlertsAPI.getAlertsSubscriptionsList, companyId, action.page, action.pageSize, action.sortBy, action.direction);
		for (const iteratorObject of response.data) {
			if (iteratorObject.hasFilters) {
				const defaultPageSize = 10;
				const response = yield call(AlertsAPI.getAlertsSubscriptionsWithFilters, iteratorObject.id, 1, defaultPageSize);
				iteratorObject.filterData = response.data;
				iteratorObject.currentPage = response.headers["x-pagination-page"];
				iteratorObject.numPages = response.headers["x-pagination-count"];
				iteratorObject.pageSize = response.headers["x-pagination-limit"];
			}
		}

		yield put(AlertSubscriptionsActions.getAlertSubscriptionsSuccess(response.data, response.headers["x-pagination-count"]));
	} catch (error) {
		yield put(AlertSubscriptionsActions.getAlertSubscriptionsError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getSubscribedLocationsList(action) {
	try {
		const response = yield call(AlertsAPI.getAlertsSubscriptionsWithFilters, action.subId, action.page, action.pageSize);
		yield put(AlertSubscriptionsActions.getAlertSubscriptionsFilterSuccess(response.data, response.headers["x-pagination-count"]));
	} catch (error) {
		yield put(AlertSubscriptionsActions.getAlertSubscriptionsFilterError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addAlertSubscriptionsList(action) {
	try {
		yield put(AppActions.setModalLoading());
		const response = yield call(AlertsAPI.addAlertSubscriptionsList, action.data);
		const tableProps = yield select(selectTableProps());
		
		if (!action.data.allCorporateLocations && action.data.buildings.length > 0) {
			const tempArray = [];

			for (const iteratorObject of action.data.buildings) {
				iteratorObject.filterType = iteratorObject.type;
				iteratorObject.ouId = iteratorObject.id;
				iteratorObject.subscriptionId = response.data.id;

				tempArray.push(iteratorObject);
				
			}
			yield call(AlertsAPI.addAlertSubscriptionsListWithFilters, tempArray, response.data.id);
		} else if (!action.data.allCorporateLocations && action.data.locations.length > 0) {
			const tempArray = [];

			for (const iteratorObject of action.data.locations) {
				iteratorObject.filterType = iteratorObject.type;
				iteratorObject.ouId = iteratorObject.id;
				iteratorObject.subscriptionId = response.data.id;

				tempArray.push(iteratorObject);
				
			}
			yield call(AlertsAPI.addAlertSubscriptionsListWithFilters, tempArray, response.data.id);
		}
		else {
			yield call(AlertsAPI.deleteAlertSubscriptionsListWithFilters, response.data.id);

		}

		yield put(AppActions.setModalClose());
		yield put(AlertSubscriptionsActions.getAlertSubscriptionsRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch(error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editAlertSubscriptionsList(action) {
	if (!action.data.enableFiltering) {
		action.data.locations = []
		action.data.buildings = []
	}
	try {
		yield put(AppActions.setModalLoading());
		const response = yield call(AlertsAPI.editAlertSubscriptionsList, action.data);
		const tableProps = yield select(selectTableProps());
		
		if (!action.data.allCorporateLocations && action.data.buildings.length > 0) {
			const tempArray = [];

			for (const iteratorObject of action.data.buildings) {
				const tempObj = {};

				tempObj.filterType = iteratorObject.filterType ? iteratorObject.filterType : iteratorObject.type;
				tempObj.ouId = iteratorObject.id;
				tempObj.subscriptionId = response.data.id;
				tempArray.push(tempObj);
			}
			yield call(AlertsAPI.addAlertSubscriptionsListWithFilters, tempArray, response.data.id);

		} else if (!action.data.allCorporateLocations && action.data.locations.length > 0) {
			const tempArray = [];

			for (const iteratorObject of action.data.locations) {
				const tempObj = {};

				tempObj.filterType = iteratorObject.filterType ? iteratorObject.filterType : iteratorObject.type;
				tempObj.ouId = iteratorObject.id;
				tempObj.subscriptionId = response.data.id;
				tempArray.push(tempObj);
			}
			yield call(AlertsAPI.addAlertSubscriptionsListWithFilters, tempArray, response.data.id);
		} else {
			yield call(AlertsAPI.deleteAlertSubscriptionsListWithFilters, response.data.id);
		}

		yield put(AppActions.setModalClose());
		yield put(AlertSubscriptionsActions.getAlertSubscriptionsRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));

	} catch(error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteAlertSubscription(action) {
	try {
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(AlertsAPI.deleteAlertSubscription, action.data);
		yield put(AppActions.setModalClose());
		yield put(AlertSubscriptionsActions.getAlertSubscriptionsRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* testAlertSubscription(action) {
	try {
		yield call(AlertsAPI.testAlertSubscription, action.data);
		yield put(AppActions.updateSuccessSnackbar("Test alert successfully sent!", "Please allow up to a few minutes for the alerts to be received"));
		yield put(AlertSubscriptionsActions.testAlertSubscriptionSuccess());
	} catch (error) {
			console.log(error);
	}
}

function* getAlertTemplatesList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(AlertsAPI.getAlertsTemplatesList, companyId, action.alertType, action.page, action.pageSize, action.sortBy, action.direction);
		yield put(AlertSubscriptionsActions.getAlertTemplatesSuccess(response.data, response.headers["x-pagination-count"]));
	} catch (error) {
		yield put(AlertSubscriptionsActions.getAlertTemplatesError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* deleteAlertTemplate(action) {
	try {
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(AlertsAPI.deleteAlertsTemplate, action.id, action.replacementId);
		yield put(AppActions.setModalClose());
		yield put(AlertSubscriptionsActions.getAlertTemplatesRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* previewAlertTemplate(action) {
	try {
		yield put(AppActions.setModalLoading());
		const response = yield call(AlertsAPI.previewAlertTemplate, action.data);
		yield put(AlertSubscriptionsActions.previewAlertTemplateSuccess(response.data));
		yield put(AppActions.setModalLoading());
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalLoading());
	}
}

function* copyAlertTemplate(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		action.data.parentId = companyId;
		yield put(AppActions.setModalLoading());
		yield call(AlertsAPI.copyAlertTemplate, action.data);
		yield put(AppActions.setModalClose());
		yield put(AlertSubscriptionsActions.getAlertTemplatesRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* addAlertTemplate(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		action.data.parentId = companyId;
		yield call(AlertsAPI.addAlertTemplate, action.data);
		yield put(AppActions.setModalClose());
		yield put(AlertSubscriptionsActions.getAlertTemplatesRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch(error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editAlertTemplate(action) {
	try {
		const tableProps = yield select(selectTableProps());
		yield call(AlertsAPI.editAlertTemplate, action.data);
		yield put(AppActions.setModalClose());
		yield put(AlertSubscriptionsActions.getAlertTemplatesRequest(tableProps.page, tableProps.pageSize, tableProps.sortBy, tableProps.direction));
	} catch(error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootAlertsSaga() {
	yield all([
		yield takeLatest(AlertSubscriptionsTypes.GET_ALERT_SUBSCRIPTIONS_REQUEST, getAlertSubscriptionsList),
		yield takeLatest(AlertSubscriptionsTypes.ADD_ALERT_SUBSCRIPTION, addAlertSubscriptionsList),
		yield takeLatest(AlertSubscriptionsTypes.EDIT_ALERT_SUBSCRIPTION, editAlertSubscriptionsList),
		yield takeLatest(AlertSubscriptionsTypes.DELETE_ALERT_SUBSCRIPTION, deleteAlertSubscription),
		yield takeLatest(AlertSubscriptionsTypes.GET_ALERT_SUBSCRIPTIONS_FILTERS_REQUEST, getSubscribedLocationsList),
		yield takeLatest(AlertSubscriptionsTypes.TEST_ALERT_SUBSCRIPTION_REQUEST, testAlertSubscription),
		yield takeLatest(AlertSubscriptionsTypes.GET_ALERT_TEMPLATES_REQUEST, getAlertTemplatesList),
		yield takeLatest(AlertSubscriptionsTypes.DELETE_ALERT_TEMPLATE, deleteAlertTemplate),
		yield takeLatest(AlertSubscriptionsTypes.PREVIEW_ALERT_TEMPLATE, previewAlertTemplate),
		yield takeLatest(AlertSubscriptionsTypes.COPY_ALERT_TEMPLATE, copyAlertTemplate),
		yield takeLatest(AlertSubscriptionsTypes.ADD_ALERT_TEMPLATE, addAlertTemplate),
		yield takeLatest(AlertSubscriptionsTypes.EDIT_ALERT_TEMPLATE, editAlertTemplate)
	]);
}

export default rootAlertsSaga;
