import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/addressManagement';
import { connect } from 'react-redux';

class AddressManagementRescan extends Component {
    handleSubmit = () => {
        if (this.props.modalData) {
            this.props.addressManagementRescan(this.props.modalData.id);
        }
    }

    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                        Rescanning this address will change the backend provider for all buildings in the system that use this address.
                        </p>
                    </Grid>
                    <Grid item >
                        <Button className="button"
                                variant="contained" 
                                color="primary" 
                                onClick={this.handleSubmit}>
                            OK
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addressManagementRescan: (addressId) => dispatch(actions.addressManagementRescan(addressId))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper
)(AddressManagementRescan);