import React, { useState, useEffect } from 'react';

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HasAccess from '../../components/HasAccess/HasAccess';

import { connect } from "react-redux";
import { compose } from "redux";

import injectReducer from "../../utils/injectReducer";
import injectSaga from "../../utils/injectSagas";
import history from '../../utils/history';
import saga from "../../sagas/manageLicenses";

import reducer from '../../reducers/manageLicenses';
import { actions as AppActions } from '../../reducers/app';
import {  selectUser, orgTypes, selectOriginalLoginCompany } from '../../reducers/auth';
import { actions as ManageLicensesActions, types as ManageLicensesTypes } from '../../reducers/manageLicenses';
import CompanyAPI from '../../apis/companyApi';
import { createStructuredSelector } from "reselect";
import ManageLicensesTable from '../ManageLicensesTable/ManageLicensesTable';
import ManageLicensesStatsTable from '../ManageLicensesStatsTable/ManageLicensesStatsTable';
import CompanyFeaturesAPI from '../../apis/companyFeaturesApi';

function ManageLicensesPage(props) {
    const [managingCompanyName, setManagingCompanyName] = useState('');
    const [currentCompanyOrgType, setCurrentCompanyOrgType] = useState('');
    const [page, setPage] = useState(0);
    const [usageBasedLicensing, setUsageBasedLicensing] = useState('');
    const [companyFeatures, setCompanyFeatures] = useState(null);
    const emergencyCallAssistEnabled = companyFeatures && companyFeatures.includes("EMERGENCY_CALL_ASSIST");


    useEffect(() => {
        props.setPageTitle('Manage Licenses');
        props.setPageTitleId('manage-licenses-header');

        CompanyAPI.getOrganizationById(companyId).then(response => {
            if (response.data) {
                setManagingCompanyName(response.data.name);
                props.managingCompany(response.data);
                setCurrentCompanyOrgType(response.data.orgType);
                setUsageBasedLicensing(response.data.usageBasedLicensing);
                CompanyFeaturesAPI.getCompanyFeatures(response.data.id).then(response => {
                    setCompanyFeatures(response.data);
                })
            
            }
        });
    }, []);

    const companyId = props.match.params.id;
    let params = new URLSearchParams(props.location.search);
    let licenseModel = params.get('licenseModel');
    
    if(!companyId || !licenseModel) {
        history.push('/organizations');
    }

    return (
        <div>
            {managingCompanyName && 
                <h2 id="manage-licenses-managing-company-header" style={{ marginTop: '-12px', fontWeight: 'normal' }}>for <span style={{ fontSize: '20px' }}>{managingCompanyName}</span></h2>
            }
            <ManageLicensesStatsTable fetchLicenseStatusData={props.fetchLicenseStatusData} companyId={companyId} emergencyCallAssistEnabled={emergencyCallAssistEnabled}/>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                {currentCompanyOrgType === 'CUSTOMER' &&
                <HasAccess
                    permissions={["ADD_LICENSING"]}
                >
                    <Button
                        className="button"
                        id="add-license-button"
                        variant="contained"
                        color="primary"
                        onClick={() => props.setCurrentModal(ManageLicensesTypes.ADD_LICENSE, companyId)}>
                        Add License
                    </Button>
                </HasAccess>
                }
            </Grid>
            <br/>
            <ManageLicensesTable fetchLicensesData={props.fetchLicensesData} companyId={companyId} licenseModel={licenseModel} usageBasedLicensing={usageBasedLicensing} companyFeatures={companyFeatures} setPage={setPage}/>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchLicensesData: (companyId, page, pageSize) => dispatch(ManageLicensesActions.getLicensesRequest(companyId, page, pageSize)),
        fetchLicenseStatusData: (companyId) => dispatch(ManageLicensesActions.getLicenseStatusRequest(companyId)),
        managingCompany: (company) => dispatch(ManageLicensesActions.getManagingCompany(company)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    };
};
  
const mapStateToProps = createStructuredSelector({
    user: selectUser(),
    originalLoginCompany: selectOriginalLoginCompany()
});

const withReducer = injectReducer({ key: "manageLicenses", reducer });
const withSaga = injectSaga({ key: "manageLicenses", saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withConnect,
  withSaga
)(ManageLicensesPage);