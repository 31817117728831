import axios from 'axios';
import { store } from '../index';
import { setTokens } from '../reducers/auth';
import { actions as AppActions } from '../reducers/app';
import { AUTH_ACCESS_TOKEN, 
         AUTH_REFRESH_TOKEN_EXP, 
         getRefreshToken, 
         setRefreshTokens,
         safeLogout
        } from '../utils/authLocalStorageSync';

const REFRESH_ENDPONT = 'auth-service/token/refresh';
export var source;
export var config = {
    headers: {
        'Cache-control': 'no-store',
        'Pragma': 'no-cache',
        'Expires': 0
    },
    timeout: 15000
};

/**
 * Axios instance used ONLY to make api calls to cirrus.
 * DO NOT use for different destinations.
 */
export const axiosInstance = axios.create(config);

export const loadResources = (setCirrusInfoLoaded) => { 
    if(process.env.NODE_ENV === 'development') {
        // set info object if needed
        setCirrusInfoLoaded(true);
    }
    else {
        axios.get('/cirrus.info').then(
            response => {
                const infoObject = {};
                response.data.split('\n').forEach(row => {
                    const temp = row.split('=');
                    infoObject[temp[0]] = temp[1];
                });
                
                const portalUrl = infoObject['cirrus.api.url'];
                const portalSkins = infoObject['cirrus.ui.skins'];
                const cirrusVersion = infoObject['cirrus.version'];
                const cirrusEnvironment = infoObject['cirrus.admin.ui.env'];
                
                store.dispatch(AppActions.cirrusSkins(portalSkins));
                store.dispatch(AppActions.cirrusVersion(cirrusVersion));
                store.dispatch(AppActions.cirrusEnvironment(cirrusEnvironment));
                
                if(process.env.NODE_ENV === 'production') {
                    config.baseURL = portalUrl;
                    axiosInstance.defaults.baseURL = portalUrl;
                }   
                setCirrusInfoLoaded(true);
            }
        );
    }
}

axiosInstance.interceptors.request.use(
    (config) => {
        const requestTimestamp = new Date().getTime();
        const accessToken = sessionStorage.getItem(AUTH_ACCESS_TOKEN);
        const refreshTokenExp = sessionStorage.getItem(AUTH_REFRESH_TOKEN_EXP);
        const authHeader = `Bearer ${accessToken}`;
        if (refreshTokenExp && !refreshTokenExp.includes("null") && requestTimestamp > refreshTokenExp) {
            // 12 hour session expired, force logout
            safeLogout();
        }
        config.headers.common['Authorization'] = authHeader;
        return config;            
    },
    error => {
        console.log(error);
    }
);

axiosInstance.interceptors.request.use(
    (config) => {
        const CancelToken = axios.CancelToken;
        source = CancelToken.source();
        config.cancelToken = source.token;
        return config;
    }
)

let failedQueue = [];
let isRefreshing = false;

const processQueue = (error, token = null) => {
    failedQueue.forEach(promise => {
        if(error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });
    failedQueue = [];
}

export const forgotPasswordErrorMessageTypes = [
    'http://cirrus.redskytech.com/errors/securityCode/invalid',
    'http://cirrus.redskytech.com/errors/user/notFound'
]

axiosInstance.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null
    const originalRequest = error.config;
    
    if(status === 401 && error.response.data && forgotPasswordErrorMessageTypes.includes(error.response.data.type)) {
        return Promise.reject(error.response);
    }

    if(status === 401 && error.response.data && error.response.data.detail && error.response.data.detail.includes('password was not valid')) {
        return Promise.reject(error);
    }

    if (status === 401 && error.response.data &&
        error.response.data.detail &&
        error.response.data.detail.includes('tokenId expired or invalid') &&
        isRefreshing) {
        safeLogout("Your session has expired. Please sign in again.");
        return Promise.reject(error);
    }

    if (status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({resolve, reject});
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }).catch(err => {
                return err
            });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        const refreshToken = getRefreshToken();
        const config = { headers: { 'Content-Type': 'application/json'}};
        return new Promise( (resolve, reject) =>
            axiosInstance.post(REFRESH_ENDPONT, JSON.stringify(refreshToken), config)
                .then( 
                    (response) => {
                        setRefreshTokens(response.data);
                        store.dispatch(setTokens(response.data));
                        processQueue(null, response.data.accessToken);
                        originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
                        resolve(axios(originalRequest));
                    },
                    (error) => {
                        const status = error.response ? error.response.status : null
                        if (status === 401) {
                            safeLogout("Your session has expired. Please sign in again.");
                            reject(error);
                        }
                        processQueue(error, null);
                        reject(error);
                    }
                ).then(() => { isRefreshing = false })
        );
    }
    return Promise.reject(error);
});