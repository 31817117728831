import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actions as CompanySummaryActions, makeSelectCompanySummaryObject, makeSelectCompanySummaryObjectLoading } from '../../../reducers/homepage';
import { selectUserCompany, currentCompanyOrgType, orgTypes } from '../../../reducers/auth';
import { Link } from 'react-router-dom';
import HasAccess from '../../../components/HasAccess/HasAccess';
import './issuesListTable.css';

function IssuesSection(props) {
    useEffect(() => {
        props.fetchCompanySummaryList();
    }, [props.company.toJS().id]);

    const {
        loading, companySummary, 
    } = props;
    let companySummaryObject;
    
    if (companySummary) {
        companySummaryObject = companySummary.toJS();
        
    }

    return (
        <HasAccess
            permissions={["VIEW_DEVICE_USERS", "VIEW_NETWORK_DISCOVERY", "VIEW_HELD_DEVICES"]}
        >
        <div>
            <h2 id="recent-issues-header">Recent Issues</h2>
            { props.currentCompanyOrgType === orgTypes.CUSTOMER &&
            <div className="summary-wrapper">
                {companySummaryObject ?
                    <ul>
                        <HasAccess
                            permissions={["VIEW_DEVICE_USERS"]}
                        >
                            <li>
                                <Link to="/users">
                                    <span className="header" id="mye911-users-without-locations-header">MyE911 Users <br />without a Location</span> 
                                    {companySummaryObject.numOfMyE911UsersWithoutLocations === 0 ? 
                                        <span id="mye911-users-without-locations-value" className="green-text">
                                            {companySummaryObject.numOfMyE911UsersWithoutLocations}
                                        </span> : 
                                        <span  id="mye911-users-without-locations-value">
                                            {companySummaryObject.numOfMyE911UsersWithoutLocations}
                                        </span>
                                    }
                                </Link>
                            </li>
                            <li className="vertical-border"></li>
                        </HasAccess>
                        <HasAccess
                            permissions={["VIEW_DEVICE_USERS"]}
                        >
                            <li>
                                <Link to="/users">
                                    <span className="header" id="held-plus-devices-without-locations-header">HELD+ Devices <br />without a Location</span> 
                                    {companySummaryObject.numOfHeldPlusDevicesWithoutLocations === 0 ? 
                                        <span id="held-plus-devices-without-locations-value" className="green-text">
                                            {companySummaryObject.numOfHeldPlusDevicesWithoutLocations}
                                        </span> : 
                                        <span id="held-plus-devices-without-locations-value">
                                            {companySummaryObject.numOfHeldPlusDevicesWithoutLocations}
                                        </span>
                                    }
                                </Link>
                            </li>
                            <li className="vertical-border"></li>
                        </HasAccess>
                        <HasAccess
                            permissions={["VIEW_NETWORK_DISCOVERY"]}
                        >
                            <li>
                                <Link to="/network-discovery">
                                    <span className="header" id="network-elements-without-locations-header">Network Elements<br />without a Location</span> 
                                    {companySummaryObject.numOfNetworkElementsWithoutLocation === 0 ? 
                                        <span id="network-elements-without-locations-value" className="green-text">
                                            {companySummaryObject.numOfNetworkElementsWithoutLocation}
                                        </span> : 
                                        <span id="network-elements-without-locations-value">
                                            {companySummaryObject.numOfNetworkElementsWithoutLocation}
                                        </span>
                                    }
                                </Link>
                            </li>
                            <li className="vertical-border"></li>
                        </HasAccess>
                        <HasAccess
                            permissions={["VIEW_HELD_DEVICES"]}
                        >
                            <li>
                                <Link to="/held-devices">
                                    <span className="header" id="invalid-locations-header">HELD Devices<br/>without a Location</span> 
                                    {companySummaryObject.numOfHeldDevicesWithoutLocations === 0 ? 
                                        <span id="invalid-locations-value" className="green-text">
                                            {companySummaryObject.numOfHeldDevicesWithoutLocations}
                                        </span> : 
                                        <span id="invalid-locations-value">
                                            {companySummaryObject.numOfHeldDevicesWithoutLocations}
                                        </span>
                                    }
                                </Link>
                            </li>
                        </HasAccess>
                    </ul>
                : ''
            }
            </div>
        }   
        {props.currentCompanyOrgType !== orgTypes.CUSTOMER &&
         <div className="summary-wrapper">
         {companySummaryObject ?
             <ul>
                <HasAccess
                    permissions={["VIEW_DEVICE_USERS"]}
                >
                    <li>
                        <span className="header" id="mye911-users-without-locations-header">MyE911 Users<br/>without a Location</span> 
                        {companySummaryObject.numOfMyE911UsersWithoutLocations === 0 ? 
                            <span id="mye911-users-without-locations-value" className="green-text">
                                {companySummaryObject.numOfMyE911UsersWithoutLocations}
                            </span> : 
                            <span  id="mye911-users-without-locations-value">
                                {companySummaryObject.numOfMyE911UsersWithoutLocations}
                            </span>
                        }
                    </li>
                    <li className="vertical-border"></li>
                </HasAccess>
                <HasAccess
                    permissions={["VIEW_DEVICE_USERS"]}
                >
                    <li>
                            <span className="header" id="held-plus-devices-without-locations-header">HELD+ Devices<br />without a Location</span> 
                            {companySummaryObject.numOfHeldPlusDevicesWithoutLocations === 0 ? 
                                <span id="held-plus-devices-without-locations-value" className="green-text">
                                    {companySummaryObject.numOfHeldPlusDevicesWithoutLocations}
                                </span> : 
                                <span id="held-plus-devices-without-locations-value">
                                    {companySummaryObject.numOfHeldPlusDevicesWithoutLocations}
                                </span>
                            }
                    </li>
                    <li className="vertical-border"></li>
                </HasAccess>
                <HasAccess
                    permissions={["VIEW_NETWORK_DISCOVERY"]}
                >
                    <li>
                            <span className="header" id="network-elements-without-locations-header">Network Elements<br />without a Location</span> 
                            {companySummaryObject.numOfNetworkElementsWithoutLocation === 0 ? 
                                <span id="network-elements-without-locations-value" className="green-text">
                                    {companySummaryObject.numOfNetworkElementsWithoutLocation}
                                </span> : 
                                <span id="network-elements-without-locations-value">
                                    {companySummaryObject.numOfNetworkElementsWithoutLocation}
                                </span>
                            }
                    </li>
                    <li className="vertical-border"></li>
                </HasAccess>
                <HasAccess
                    permissions={["VIEW_HELD_DEVICES"]}
                >
                    <li>
                            <span className="header" id="invalid-locations-header">HELD Devices<br/>without a Location</span> 
                            {companySummaryObject.numOfHeldDevicesWithoutLocations === 0 ? 
                                <span id="invalid-locations-value" className="green-text">
                                    {companySummaryObject.numOfHeldDevicesWithoutLocations}
                                </span> : 
                                <span id="invalid-locations-value">
                                    {companySummaryObject.numOfHeldDevicesWithoutLocations}
                                </span>
                            }
                    </li>
                </HasAccess>
             </ul>
         : ''
     }
     </div>
    }
        </div>
    </HasAccess>
    );
}

const mapStateToProps = createStructuredSelector({
    companySummary: makeSelectCompanySummaryObject(),
    loading: makeSelectCompanySummaryObjectLoading(),
    company: selectUserCompany(),
    currentCompanyOrgType: currentCompanyOrgType()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchCompanySummaryList: () => dispatch(CompanySummaryActions.getCompanySummaryRequest())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuesSection);