import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { states, provinces, countries, direction } from '../../utils/stateAndCountryData';

function AddressAlternate(props) {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        modalLoading } = props;

    useEffect(() => {
        const houseNumber = document.getElementById('houseNumber');

        if (houseNumber) {
            houseNumber.focus();
        }

    }, []);

    const handleClear = () => {
        setFieldValue('address', '');
        setFieldValue('latitude', '');
        setFieldValue('longitude', '');
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <div>
                    <TextField
                        id="houseNumber"
                        label={(values.latitude && values.longitude) ? 'House Number' : '*House Number'}
                        name="houseNumber"
                        type="houseNumber"
                        onChange={(e) => {
                            handleChange(e);
                            handleClear();
                        }}
                        value={values.houseNumber}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={false}
                        error={(touched.houseNumber && Boolean(errors.houseNumber)) || (errors.houseNumber && Boolean(errors.houseNumber.includes('max')))}/> 
                        <span id="houseNumber-helper-text" className="error-prompt">{(touched.houseNumber && Boolean(errors.houseNumber)) || (errors.houseNumber && Boolean(errors.houseNumber.includes('max'))) ? errors.houseNumber : ''}</span>
                </div>
                <div style={{ marginLeft: '50px' }}>
                    <TextField
                        style={{ width: '210px' }}
                        id="houseNumberExtension"
                        label="House Number Extension"
                        name="houseNumberExtension"
                        type="houseNumberExtension"
                        onChange={(e) => {
                            handleChange(e);
                            handleClear();
                        }}
                        value={values.houseNumberExtension}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={false}
                        error={(touched.houseNumberExtension && Boolean(errors.houseNumberExtension)) || (errors.houseNumberExtension && Boolean(errors.houseNumberExtension.includes('max')))}/> 
                        <span id="houseNumberExtension-helper-text" className="error-prompt">{(touched.houseNumberExtension && Boolean(errors.houseNumberExtension)) || (errors.houseNumberExtension && Boolean(errors.houseNumberExtension.includes('max'))) ? errors.houseNumberExtension : ''}</span>
                </div>
            </div>
            <br/>
            <InputLabel style={{ margin: '0 5px 0 0' }} shrink={true} htmlFor="prefix-direction">Prefix Direction</InputLabel>
            <Select
                style={{ width: '25%' }}
                id="prefix-direction"
                name="prefixDirection"
                value={values.prefixDirection}
                onChange={(e) => {
                    handleChange(e);
                    handleClear();
                }}
                disabled={modalLoading}
                fullWidth={false}
            >
                { 
                    direction.map((direction, index) => 
                        <MenuItem key={index} value={direction.value}>{direction.value}</MenuItem>
                    )
                }
            </Select>
            <br/>
            <div style={{ marginBottom: '20px' }}>
                <TextField
                    id="street"
                    label={(values.latitude && values.longitude) ? 'Street Name' : '*Street Name'}
                    name="street"
                    type="street"
                    onChange={(e) => {
                        handleChange(e);
                        handleClear();
                    }}
                    value={values.street}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={(touched.street && Boolean(errors.street)) || (errors.street && Boolean(errors.street.includes('max')))}/>
                    <span id="street-helper-text" className="error-prompt">{(touched.street && Boolean(errors.street)) || (errors.street && Boolean(errors.street.includes('max'))) ? errors.street : ''}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <div>
                    <TextField
                        style={{ width: '50%', paddingRight: '10%' }}
                        id="streetType"
                        label="Street Type"
                        name="streetType"
                        type="streetType"
                        onChange={(e) => {
                            handleChange(e);
                            handleClear();
                        }}
                        value={values.streetType}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={false}
                        error={(touched.streetType && Boolean(errors.streetType)) || (errors.streetType && Boolean(errors.streetType.includes('max')))}/>
                        <span id="streetType-helper-text" className="error-prompt">{(touched.streetType && Boolean(errors.streetType)) || (errors.streetType && Boolean(errors.streetType.includes('max'))) ? errors.streetType : ''}</span>
                </div>
                <div style={{ width: '50%' }}>
                <InputLabel style={{ margin: '0 5px 0 5px' }} shrink={true} htmlFor="post-direction">Post Direction</InputLabel>
                <Select
                    style={{ width: '64%' }}
                    id="post-direction"
                    name="postDirection"
                    value={values.postDirection}
                    onChange={(e) => {
                        handleChange(e);
                        handleClear();
                    }}
                    disabled={modalLoading}
                    fullWidth={false}
                >
                    { 
                        direction.map((direction, index) => 
                            <MenuItem key={index} value={direction.value}>{direction.value}</MenuItem>
                        )
                    }
                </Select>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <div style={{ width: '50%', paddingRight: '10%' }}>
                    <TextField
                        id="city"
                        label={(values.latitude && values.longitude) ? 'City' : '*City'}
                        name="city"
                        type="city"
                        onChange={(e) => {
                            handleChange(e);
                            handleClear();
                        }}
                        value={values.city}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={false}
                        error={(touched.city && Boolean(errors.city)) || (errors.city && Boolean(errors.city.includes('max')))}/> 
                        <span id="city-helper-text" className="error-prompt">{(touched.city && Boolean(errors.city)) || (errors.city && Boolean(errors.city.includes('max'))) ? errors.city : ''}</span>
                </div>
                <div style={{ width: '50%' }}>
                    <InputLabel style={{ margin: '0 5px 0 5px' }} shrink={true} htmlFor="state-province" error={touched.stateProvince && Boolean(errors.stateProvince)}>{(values.latitude && values.longitude) ? 'State/Province' : '*State/Province'}</InputLabel>
                    <Select
                        style={{ width: '60%' }}
                        id="state-province"
                        name="stateProvince"
                        value={values.stateProvince}
                        onChange={(e) => {
                            handleChange(e);
                            handleClear();
                        }}
                        disabled={modalLoading}
                        onBlur={handleBlur}
                        fullWidth={false}
                        error={touched.stateProvince && Boolean(errors.stateProvince)}
                    >
                        {values.country === 'US' ?
                            states.sort(function(a, b){
                                if(a.value < b.value) { return -1; }
                                if(a.value > b.value) { return 1; }
                                return 0;
                            }).map((state, index) => 
                                <MenuItem key={index} value={state.value}>{state.value}</MenuItem>
                            ) :
                            provinces.sort(function(a, b){
                                if(a.value < b.value) { return -1; }
                                if(a.value > b.value) { return 1; }
                                return 0;
                            }).map((state, index) => 
                                <MenuItem key={index} value={state.value}>{state.value}</MenuItem>
                            )
                        }
                    </Select>
                    <span id="stateProvince-helper-text" className="error-prompt">{touched.stateProvince ? errors.stateProvince : ""}</span>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <div style={{ width: '50%', paddingRight: '10%' }}>
                    <TextField
                        id="zip"
                        label={(values.latitude && values.longitude) ? 'Zip Code' : '*Zip Code'}
                        name="zip"
                        type="zip"
                        onChange={(e) => {
                            handleChange(e);
                            handleClear();
                        }}
                        value={values.zip}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={(touched.zip && Boolean(errors.zip)) || (errors.zip && Boolean(errors.zip.includes('max')))}/> 
                        <span id="zip-helper-text" className="error-prompt">{(touched.zip && Boolean(errors.zip)) || (errors.zip && Boolean(errors.zip.includes('max'))) ? errors.zip : ''}</span>
                </div>
                <div style={{ width: '50%' }}>
                    <InputLabel style={{ margin: '0 5px 0 5px' }} shrink={true} htmlFor="country" error={touched.country && Boolean(errors.country)}>{(values.latitude && values.longitude) ? 'Country' : '*Country'}</InputLabel>
                    <Select
                        style={{ width: '75%' }}
                        id="country"
                        name="country"
                        value={values.country}
                        onChange={(e) => {
                            handleChange(e);
                            handleClear();
                        }}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={touched.country && Boolean(errors.country)}
                    >
                        {
                            countries.map((country, index) => 
                                <MenuItem key={index} value={country.value}>{country.value}</MenuItem>
                            )
                        }
                    </Select>
                </div>
            </div>
        </div>
    );
}

export default AddressAlternate;