import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/callMonitoring';
import { connect } from 'react-redux';

class DeleteCallMonitoringModal extends Component {

    handleDelete = () => {
        this.props.submitDeleteCallMonitoring(this.props.modalData.id, this.props.modalData.parentId);
    }

    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like to delete the following item.
                        </p>
                    </Grid>
                    <Grid item >
                        Name: <b id="subscriptionName">{this.props.modalData.name}</b>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteCallMonitoring: (id, parentId) => dispatch(actions.deleteCallMonitoringRequest(id, parentId))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteCallMonitoringModal);