import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import OrganizationManagementAPI from '../apis/organizationManagementApi';
import { actions as AppActions } from '../reducers/app';
import { actions as OrganizationManagementActions,
		 types as OrganizationManagementTypes } from '../reducers/organizationManagement';
import { userCompanyId } from '../reducers/auth';
import pageErrorHandler from '../utils/pageErrorHandler';
import errorHandler from '../utils/errorHandler';
import CompanyFeaturesAPI from '../apis/companyFeaturesApi';
import SingleSignOnAPI from '../apis/singleSignOnApi';

function* getCERServersList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(OrganizationManagementAPI.getCERServers, companyId);
		
		yield put(OrganizationManagementActions.getCERServersSuccess(response.data));
	} catch (error) {
		yield put(OrganizationManagementActions.getCERServersError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addCERServers(action) {
	try {
		const companyId = yield select(userCompanyId());
		yield put(AppActions.setModalLoading());
		yield call(OrganizationManagementAPI.addCERServers, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(OrganizationManagementActions.getCERServersRequest());
	} catch (error) {
		console.log(error);
		yield put(OrganizationManagementActions.cerErrorResponseType(error.response.data.type));
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editCERServers(action) {
	try {
		yield put(AppActions.setModalLoading());
		yield call(OrganizationManagementAPI.editCERServers, action.data);
		yield put(AppActions.setModalClose());
		yield put(OrganizationManagementActions.getCERServersRequest());
	} catch (error) {
		console.log(error);
		yield put(OrganizationManagementActions.cerErrorResponseType(error.response.data.type));
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteCERServers(action) {
	try {
		yield put(AppActions.setModalLoading());
		yield call(OrganizationManagementAPI.deleteCERServers, action.data);
		yield put(AppActions.setModalClose());
		yield put(OrganizationManagementActions.getCERServersRequest());
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getOrganizationFeatures() {
	try {
		yield put(AppActions.setModalLoading());
		const companyId = yield select(userCompanyId());
		const response = yield call(CompanyFeaturesAPI.getCompanyFeatures, companyId);
		yield put(AppActions.setCompanyFeatures(response.data));
		yield put(AppActions.setModalClose());
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getIntegrationCred(action) {
	try {
		const response = yield call(OrganizationManagementAPI.getIntegrationCred, action.companyId, action.name);
		yield put(OrganizationManagementActions.getIntegrationCredSuccess(response.data));
	} catch (error) {
		if(error.response.data.title === "Integration Credentials ID is invalid" || error.response.data.title === "Integration Credential ID and Name is invalid"){
			yield put(OrganizationManagementActions.getIntegrationCredSuccess([]));
		}
		else {
			yield put(OrganizationManagementActions.getIntegrationCredError());
			yield put(AppActions.setPageError(pageErrorHandler(error)));
			console.log(error);
		}
	}
}

function* getIntegrationCredList(action) {
	try {
		const response = yield call(OrganizationManagementAPI.getIntegrationCredList, action.companyId);
		yield put(OrganizationManagementActions.getIntegrationCredSuccess(response.data));
	} catch (error) {
		if(error.response.data.title === "Integration Credentials ID is invalid" || error.response.data.title === "Integration Credential ID and Name is invalid"){
			yield put(OrganizationManagementActions.getIntegrationCredSuccess([]));
		}
		else {
			yield put(OrganizationManagementActions.getIntegrationCredError());
			yield put(AppActions.setPageError(pageErrorHandler(error)));
			console.log(error);
		}
	}
}

function* addIntegrationCred(action) {
	let integrationCredArray =[]
	try {
		yield put(AppActions.setModalLoading());
		if (action.credType === "MNTemplate"){
			integrationCredArray = [
				{ companyId: action.companyId, name: "massNotificationTemplateId", value: action.data.id }
			];
		} else {
			integrationCredArray = [
				{ companyId: action.companyId, name: "massNotificationAccessKeyId", value: action.data.accessKeyToken },
				{ companyId: action.companyId, name: "massNotificationSecretAccessKey", value: action.data.secretAccessKeyToken },
				{ companyId: action.companyId, name: "massNotificationOrgId", value: action.data.massNotificationOrgId},
			];
		}
		yield call(OrganizationManagementAPI.addIntegrationCred, integrationCredArray);
		//yield put(AppActions.setModalClose());
		yield put(OrganizationManagementActions.getIntegrationCredRequest(action.companyId, action.name ? action.name : "massNotificationTemplateId"));
	} catch (error) {
		console.log(error);
		yield put(OrganizationManagementActions.massNotificationTemplateErrorResponseType(error.response.data.type));
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteIntegrationCred(action) {
	try {
		yield put(AppActions.setModalLoading());
		yield call(OrganizationManagementAPI.deleteIntegrationCred, action.data);
		yield put(AppActions.setModalClose());
		yield put(OrganizationManagementActions.getIntegrationCredRequest(action.data, action.name ? action.name : "massNotificationTemplateId"));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getMassNotificationsTemplatesList(action) {
	try {
		const response = yield call(OrganizationManagementAPI.getMassNotificationsTemplates, action.data);
		yield put(OrganizationManagementActions.getMassNotificationTemplateSuccess(response.data));
	} catch (error) {
		if(error.response.data.title === "Incident Communications credentials validation failed" 
											|| error.response.data.title === "Incident Templates are not available" 
											|| error.response.data.title === "Integration Credentials ID is invalid" ){
			yield put(OrganizationManagementActions.getMassNotificationTemplateSuccess([]));
		}
		else {
			yield put(OrganizationManagementActions.getMassNotificationTemplateError());
			yield put(AppActions.setPageError(pageErrorHandler(error)));
			console.log(error);
		}
	}
}

function* getCompanySsoSettings(action) {
	try {
		const response = yield call(SingleSignOnAPI.getCompanySsoSettings, action.data);
		yield put(OrganizationManagementActions.getSsoCredentialsSuccess(response.data));
	} catch (error) {
		if(error.response.status === 400){
			yield put(OrganizationManagementActions.getSsoCredentialsSuccess({}));
		}
		else {
			yield put(OrganizationManagementActions.getSsoCredentialsError());
			yield put(AppActions.setPageError(pageErrorHandler(error)));
			console.log(error);
		}
	}
}

function* deleteSsoCred(action) {
	try {
		yield call(SingleSignOnAPI.deleteSsoSettings, action.data);
		yield put(OrganizationManagementActions.getSsoCredentials(action.data));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootOrganizationManagementSaga() {
	yield all([
		yield takeLatest(OrganizationManagementTypes.GET_CER_SERVERS_REQUEST, getCERServersList),
		yield takeLatest(OrganizationManagementTypes.ADD_CER_SERVERS, addCERServers),
		yield takeLatest(OrganizationManagementTypes.EDIT_CER_SERVERS, editCERServers),
		yield takeLatest(OrganizationManagementTypes.DELETE_CER_SERVERS, deleteCERServers),
		yield takeLatest(OrganizationManagementTypes.GET_ORGANIZATION_FEATURES, getOrganizationFeatures),
		yield takeLatest(OrganizationManagementTypes.GET_INTEGRATION_CRED_REQUEST, getIntegrationCred),
		yield takeLatest(OrganizationManagementTypes.GET_INTEGRATION_CRED_LIST_REQUEST, getIntegrationCredList),
		yield takeLatest(OrganizationManagementTypes.ADD_INTEGRATION_CRED, addIntegrationCred),
		yield takeLatest(OrganizationManagementTypes.DELETE_INTEGRATION_CRED, deleteIntegrationCred),
		yield takeLatest(OrganizationManagementTypes.GET_MASS_NOTIFICATION_TEMPLATES_REQUEST, getMassNotificationsTemplatesList),
		yield takeLatest(OrganizationManagementTypes.GET_SINGLE_SIGNON_CRED, getCompanySsoSettings),
		yield takeLatest(OrganizationManagementTypes.DELETE_SINGLE_SIGNON_CRED, deleteSsoCred)

	]);
}

export default rootOrganizationManagementSaga;
