import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatter = props => {
    const { number } = props;
    const cleanNumber = number && number.replace(/[() -]/g, '');
    
    if (cleanNumber) {
        const length = cleanNumber.length;

        if(length < 10) {
            return <span>{cleanNumber}</span>;
        }
        else if(length === 10) {
            return <NumberFormat value={cleanNumber} displayType={'text'} format="(###) ###-####" />;
        }
        else {
            return <span>{cleanNumber.toString().substring(0, length - 10)} <NumberFormat value={cleanNumber.toString().substring(length - 10)} displayType={'text'} format="(###) ###-####" /> </span>;
        }
    } else {
        return null;
    }

    
};

export default NumberFormatter;