import React, { Component } from 'react';
import Button from '@material-ui/core/Button'; 
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/pstn';
import { connect } from 'react-redux';


class DeletePstnModal extends Component {
    handleDelete = () => {
        this.props.submitDeletePstn(this.props.modalData.id);
    }
    
    render() {
        const { modalLoading } = this.props;
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like to delete the PSTN peer.
                        </p>
                    </Grid>
                    <Grid item >
                        <span>PSTN Name</span><br/>
                        <b id="pstnName">{this.props.modalData.callRouteInfo.name}</b>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeletePstn: (id) => dispatch(actions.deletePstnRequest(id))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper
)(DeletePstnModal);