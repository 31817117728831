/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const CALL_HISTORY = 'CALL_HISTORY/'; 
export const types = {
    GET_CALL_HISTORY_REQUEST: CALL_HISTORY + 'GET_CALL_HISTORY_REQUEST',
    GET_CALL_HISTORY_SUCCESS: CALL_HISTORY + 'GET_CALL_HISTORY_SUCCESS',
    CALL_HISTORY_DETAILS: CALL_HISTORY + 'CALL_HISTORY_DETAILS',
    CALL_HISTORY_RECORDING: CALL_HISTORY + 'CALL_HISTORY_RECORDING',
    CALL_HISTORY_SIP_INVITE: CALL_HISTORY + 'CALL_HISTORY_SIP_INVITE',
    GET_CALL_HISTORY_ERROR: CALL_HISTORY + 'GET_CALL_HISTORY_ERROR'
};

export const initialState = fromJS({
    callHistoryList: List(),
    callHistoryLoading: false,
    callHistoryListPageCount: -1,
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_CALL_HISTORY_REQUEST:
            return state.set('callHistoryLoading', true);
        case types.GET_CALL_HISTORY_ERROR:
            return state.set('callHistoryLoading', false);
        case types.GET_CALL_HISTORY_SUCCESS:
            return state.set('callHistoryLoading', false)
                        .set('callHistoryList', List(action.data))
                        .set('callHistoryListPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    getCallHistoryRequest: (page, pageSize, searchTerm, sortBy, direction) => ({
        type: types.GET_CALL_HISTORY_REQUEST,
        page,
        pageSize,
        searchTerm,
        sortBy,
        direction
    }),
    getCallHistorySuccess: (callHistoryList, pageCount) => ({
        type: types.GET_CALL_HISTORY_SUCCESS,
        data: callHistoryList,
        pageCount
    }),
    getCallHistoryError: () => ({
        type: types.GET_CALL_HISTORY_ERROR
    }),
};

// Selectors
const selectCallHistory = state => state.get('callHistory', initialState);

export const makeSelectCallHistoryList = () =>
    createSelector(selectCallHistory, callHistoryState => callHistoryState.get('callHistoryList'));

export const makeSelectCallHistoryListLoading = () =>
    createSelector(selectCallHistory, callHistoryState => callHistoryState.get('callHistoryLoading'));

export const makeSelectCallHistoryPageCount = () =>
    createSelector(selectCallHistory, callHistoryState => callHistoryState.get('callHistoryListPageCount'));