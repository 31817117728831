/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */

import { fromJS, List } from "immutable";
import { createSelector } from "reselect";

export const EON_USERS = "EON_USERS/";
export const types = {
  GET_EON_USERS: EON_USERS + "GET_EON_USERS",
  GET_EON_USERS_SUCCESS: EON_USERS + "GET_EON_USERS_SUCCESS",
  GET_EON_USERS_ERROR: EON_USERS + "GET_EON_USERS_ERROR",
  ADD_EON_USER: EON_USERS + "ADD_EON_USER",
  ADD_EON_USER_SUCCESS: EON_USERS + "ADD_EON_USER_SUCCESS",
  ADD_EON_USER_ERROR: EON_USERS + "ADD_EON_USER_ERROR",
  EDIT_EON_USER: EON_USERS + "EDIT_EON_USER",
  EDIT_EON_USER_SUCCESS: EON_USERS + "EDIT_EON_USER_SUCCESS",
  DELETE_EON_USER: EON_USERS + "DELETE_EON_USER",
  DELETE_EON_USER_SUCCESS: EON_USERS + "DELETE_EON_USER_SUCCESS"
};

export const initialState = fromJS({
  eonUsersList: List(),
  eonUsersListLoading: false
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EON_USERS:
      return state.set("eonUsersListLoading", true);
    case types.GET_EON_USERS_SUCCESS:
      return state
        .set("eonUsersList", List(action.eonUsers))
        .set("eonUsersListLoading", false)
        .set("eonUsersPageCount", action.pageCount);
    case types.GET_EON_USERS_ERROR:
      return state.set("eonUsersListLoading", false);
  default:
      return state;
  }
};

// Action Creators
export const actions = {
  getEonUsersRequest: (page, pageSize, searchTerm, sortBy, sortDirection) => ({
    type: types.GET_EON_USERS,
    page,
    pageSize,
    searchTerm,
    sortBy,
    sortDirection
  }),
  eonUsersLoaded: (eonUsers, pageCount) => ({
    type: types.GET_EON_USERS_SUCCESS,
    eonUsers,
    pageCount
  }),
  eonUsersLoadingError: () => ({
    type: types.GET_EON_USERS_ERROR,
  }),
  addEonUserRequest: eonUser => ({
    type: types.ADD_EON_USER,
    data: eonUser
  }),
  addEonUsersSuccess: () => ({
    type: types.ADD_EON_USER_SUCCESS
  }),
  addEonUserError: error => ({
    type: types.ADD_EON_USER_ERROR,
    error: error.data
  }),
  editEonUserRequest: (eonUser, row) => ({
    type: types.EDIT_EON_USER,
    data: eonUser,
    row: row
  }),
  deleteEonUserRequest: id => ({
    type: types.DELETE_EON_USER,
    data: id
  }),
  deleteEonUserSuccess: () => ({
    type: types.DELETE_EON_USER_SUCCESS
  })
};

// Selectors
const selectEonUsers = state => state.get("eonUsers", initialState);

export const makeSelectEonUsersList = () =>
  createSelector(
    selectEonUsers,
    eonUsersState => eonUsersState.get("eonUsersList")
  );

export const makeSelectEonUsersPageCount = () =>
  createSelector(
    selectEonUsers,
    eonUserState => eonUserState.get("eonUsersPageCount")
  );

export const makeSelectEonUsersListLoading = () =>
  createSelector(
    selectEonUsers,
    eonUsersState => eonUsersState.get("eonUsersListLoading")
  );
