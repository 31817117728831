import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as UsersActions } from '../../../reducers/users';
import ModalWrapper from '../../../components/ModalWrapper';

const DeleteUserModal = props => {
	const { modalLoading } = props;
	return(
		<div>
			{modalLoading && <LinearProgress/>}
			<Grid container 
				direction="column" 
				justify="center"
				alignItems="center"
				spacing={16}>
				<Grid item >
					<p>Please confirm that you would like to delete this User:</p>
				</Grid>
				<Grid item>
					<b>{props.modalData.email}</b>
				</Grid>
				<Grid item>
					<Button id="administrators-delete-button"
							variant="contained" 
							color="secondary" 
							onClick={() => props.submitDeleteUser(props.modalData)}>
						DELETE
					</Button>
				</Grid>
			</Grid>
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		submitDeleteUser: (id) => dispatch(UsersActions.deleteUserRequest(id))
	}
}

export default compose(
	connect(null, mapDispatchToProps),
	ModalWrapper
)(DeleteUserModal);
