import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import './SecurityCodeSuccessModal.css'

export default function SecurityCodeSuccessModal(props) {
    const handleClose = (value) => {
        props.setOpenSuccessModal(false);
    };

    return (
        <Dialog 
            onClose={handleClose} 
            open={props.openSuccessModal}
            disableEscapeKeyDown
            disableBackdropClick
            className="logout-confirmation-modal"
        >
            <div id="logout-modal-title"></div> 
            <p>The one-time security code has been sent!</p>
            <div className="logout-modal-wrapper">
                <Button className="button" variant="contained" color="primary" onClick={handleClose}>
                    Close
                </Button>
            </div>
        </Dialog>
    );
}