/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const ADDRESS_MANAGEMENT = 'ADDRESS_MANAGEMENT/';
export const types = {
    GET_ADDRESS_MANAGEMENT_REQUEST: ADDRESS_MANAGEMENT + 'GET_ADDRESS_MANAGEMENT_REQUEST',
    GET_ADDRESS_MANAGEMENT_ERROR: ADDRESS_MANAGEMENT + 'GET_ADDRESS_MANAGEMENT_ERROR',
    GET_ADDRESS_MANAGEMENT_SUCCESS: ADDRESS_MANAGEMENT + 'GET_ADDRESS_MANAGEMENT_SUCCESS',
    ADDRESS_MANAGEMENT_RESCAN: ADDRESS_MANAGEMENT + 'ADDRESS_MANAGEMENT_RESCAN'
};

export const initialState = fromJS({
    addressManagementList: List(),
    addressManagementLoading: false,
    addressManagementListPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_ADDRESS_MANAGEMENT_REQUEST:
            return state.set('addressManagementLoading', true);
        case types.GET_ADDRESS_MANAGEMENT_ERROR:
            return state.set('addressManagementLoading', false);
        case types.GET_ADDRESS_MANAGEMENT_SUCCESS:
            return state.set('addressManagementLoading', false)
                        .set('addressManagementList', List(action.data))
                        .set('addressManagementListPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    getAddressManagementRequest: (page, pageSize, searchTerm, sortBy, direction) => ({
        type: types.GET_ADDRESS_MANAGEMENT_REQUEST,
        page, 
        pageSize,
        searchTerm,
        sortBy,
        direction
    }),
    getAddressManagementError: () => ({
        type: types.GET_ADDRESS_MANAGEMENT_ERROR
    }),
    getAddressManagementSuccess: (addressManagementList, pageCount) => ({
        type: types.GET_ADDRESS_MANAGEMENT_SUCCESS,
        data: addressManagementList,
        pageCount
    }),
    addressManagementRescan: (addressId) => ({
        type: types.ADDRESS_MANAGEMENT_RESCAN,
        data: addressId
    }),
};

// Selectors
const selectAddressManagement = state => state.get('addressManagement', initialState);

export const makeSelectAddressManagementList = () =>
    createSelector(selectAddressManagement, addressManagementState => addressManagementState.get('addressManagementList'));

export const makeSelectAddressManagementListLoading = () =>
    createSelector(selectAddressManagement, addressManagementState => addressManagementState.get('addressManagementLoading'));

export const makeSelectAddressManagementPageCount = () =>
createSelector(selectAddressManagement, addressManagementState => addressManagementState.get('addressManagementListPageCount'));