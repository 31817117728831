import React, { Component } from 'react';
import Button from '@material-ui/core/Button'; 
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions, selectDeleteLocationUserCount } from '../../../reducers/locations';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';


class DeleteLocationModal extends Component {
    componentDidMount() {
        this.props.getLocationUserCount(this.props.modalData.id);
    }

    handleDelete = () => {
        if (this.props.modalData.origin === 'MANAGER_DATA_SYNC') {
            this.props.submitDeleteLocation(this.props.modalData.id, this.props.modalData.parentId, 'MANAGER_DATA_SYNC', this.props.modalData.buildingFlag);   
        } else {
            this.props.submitDeleteLocation(this.props.modalData.id, this.props.modalData.parentId, null, this.props.modalData.buildingFlag);
        }
    }
    
    render() {
        const { modalLoading } = this.props;

        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            The following Location has <b>{this.props.deleteLocationUserCount}</b> Device Users associated with it. Please confirm it should still be deleted.
                        </p>
                    </Grid>
                    <Grid item >
                        <b id="locationName">{this.props.modalData.name}</b>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}
                                disabled={modalLoading}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = ()  => createStructuredSelector({
    deleteLocationUserCount: selectDeleteLocationUserCount()
});

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteLocation: (id, parentId, origin, buildingFlag) => dispatch(actions.deleteLocationRequest(id, parentId, origin, buildingFlag)),
        getLocationUserCount: (locationId) => dispatch(actions.getLocationUserCount(locationId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper
)(DeleteLocationModal);