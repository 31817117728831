import React from 'react';
import { compose } from 'redux';

import ModalWrapper from '../../../../components/ModalWrapper';

import RecipientListTable from '../../../../containers/RecipientListTable/RecipientListTable';

const EditAlertsSubscriptionsModal = props => {
    const { modalData } = props;

    const getMatch = (combinedSortedList, recipients) => {
        const matches = [];
    
        for ( let i = 0; i < combinedSortedList.length; i++ ) {
            for ( let j = 0; j < recipients.length; j++ ) {
                if (recipients[j].email && combinedSortedList[i] === recipients[j].email ) {
                    matches.push( recipients[j] );
                } else if (recipients[j].phoneNumber && combinedSortedList[i] === recipients[j].phoneNumber ) {
                    matches.push( recipients[j] );
                } else if (recipients[j].user && combinedSortedList[i] === recipients[j].user ) {
                    matches.push( recipients[j] );
                } else if (recipients[j].name && combinedSortedList[i] === recipients[j].name ) {
                    matches.push( recipients[j] );
                }
            }
        }
        return matches;
    };
    
	return (
        <RecipientListTable 
            recipientsList={getMatch(modalData.combinedSortedList, modalData.recipients)}  />
    );
}

export default compose(
    ModalWrapper
)(EditAlertsSubscriptionsModal);