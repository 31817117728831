import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types } from '../../reducers/locations';
import { selectUserCompany, selectUserRole } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import handleCopyToClipboard from '../../utils/handleCopyToClipboard';

function LocationsEditMenu(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [copyToClipboardFailed, setCopyToClipboardFailed] = useState(false);

	const isSimple = props.company.toJS().locationTreeType === 'SIMPLE';
	const isDataSync = props.row.original.origin === 'MANAGER_DATA_SYNC' || props.dataSyncLocation;
	const isCER = props.row.original.origin === 'CER';

	const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isEdit = user && user.permissions.includes('EDIT_LOCATIONS');
	const isDelete = user && user.permissions.includes('DELETE_LOCATIONS');
	const isRescan = user && user.permissions.includes('RESCAN_ADDRESS');

	const options = [
		'Copy UUID to Clipboard'
	];

	if (isRescan && isSimple && !isDataSync && !isCER) options.unshift('Rescan Address');
	if (isDelete) options.unshift('Delete');
	if (isEdit && !isDataSync) options.unshift('Edit');
	
	const ITEM_HEIGHT = 48;
	const state = {
		anchorEl: null,
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

		setSnackbarOpen(false);
    };

	const handleMenuItemClick = (option) => {
		handleClose();
		switch (option) {
			case 'Edit':
				props.row.original.buildingFlag = props.buildingFlag;
				props.row.original.simple = props.simple
				if(props.row.original && props.row.original.origin === 'CER') {
					props.setCurrentModal(types.EDIT_LOCATION_CER, props.row.original);
				}
				else {
					props.setCurrentModal(types.EDIT_LOCATION, props.row.original);
				}
				break;
			case 'Delete':
				props.row.original.buildingFlag = props.buildingFlag;
				if(props.row.original && props.row.original.type === 'BUILDING') {
					props.setCurrentModal(types.DELETE_BUILDING, props.row.original)
				} 
				else if (props.row.original && props.row.original.origin === 'CER'){
					props.setCurrentModal(types.DELETE_LOCATION_CER, props.row.original)
				}
				else {
					props.setCurrentModal(types.DELETE_LOCATION, props.row.original);
				}
				break;
			case 'Rescan Address':
				props.setCurrentModal(types.RESCAN_ADDRESS_LOCATION, props.row.original);
				break;
			case 'Copy UUID to Clipboard':
				handleCopyToClipboard(props.row.original.id, setSnackbarOpen, setCopyToClipboardFailed);
				break;
			default:
				return null;
		}
	};

	const handleMenuItems = () => {
		return options.map((option, index) => (
			<MenuItem id={`item-${props.row.rowId}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => handleMenuItemClick(option, event)}>
				{option}
			</MenuItem>
		))
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<IconButton
				id={`action-menu-button-${props.row.rowId}`}
				aria-label="More"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id={`action-menu-${props.row.rowId}`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 6.5,
						minWidth: 200,
					},
				}}
			>
				{handleMenuItems()}
			</Menu>
			<Snackbar
				className={`snackbar-popup ${copyToClipboardFailed && 'error'}`}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				message={<div>
					{copyToClipboardFailed ? 
						<span id="client-snackbar">
							The code/ID was not copied to your clipboard. Please copy manually:<br/>
							{props.row.original.id}
						</span>
						:
						<span id="client-snackbar">
							<CheckCircleIcon className={"classes.icon classes.iconVariant"} />
							The code/ID has been copied to your clipboard
						</span>
					}
				</div>}
				action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={handleSnackbarClose}
				>
					<CloseIcon />
				</IconButton>,
				]}
			/>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
    company: selectUserCompany(),
    userRole: selectUserRole()
});


const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsEditMenu);
