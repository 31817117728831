import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Switch, Tooltip } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from "@material-ui/core/styles";
import { DayPickerRangeController, isInclusivelyAfterDay } from 'react-dates';
import download from 'downloadjs/download.js';
import TimeZone from '../../../components/TimeZone/TimeZone';
import LocationType from './shared/LocationType';
import BuildingType from './shared/BuildingType';

import { Formik } from 'formik';
import { compose } from 'redux';
import * as Yup from "yup";

import ModalWrapper from '../../../components/ModalWrapper';
import { selectCerEnabled } from '../../../reducers/app';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { selectUserCompany, currentCompanyLevel } from '../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import ReportsAPI from '../../../apis/reportsApi';
import './GenerateReport.css';


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    icon: {
        position: 'absolute',
        left: '50px',
        top: '-4px'
    },
    sip: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    }
});

const GenerateReportForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit, 
            modalLoading,
            modalData,
            classes,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            focusedInput,
            setFocusedInput,
            hasSubtenants,
            setHasSubtenants,
            isLoading,
            currentCompanyLevel,
            company
             } = props;

    const [value, setValue] = useState('TODAY');

    function handleOnChange(event) {
        setHasSubtenants(event.target.checked);
    }

    function handleDateChange (event) {
        const input = event.target.value;
        setValue(input);
        
        if (input === 'TODAY') {
            setStartDate(moment().startOf('day'));
            setEndDate(moment().endOf('day'));
        } else if (input === 'YESTERDAY') {
            setStartDate(moment().subtract(1,'days').startOf('day'));
            setEndDate(moment().subtract(1,'days').endOf('day'));
        } else if (input === 'THIS_WEEK') {
            setStartDate(moment().day('Sunday').startOf('day'));
            setEndDate(moment().endOf('day'));
        } else if (input === 'LAST_WEEK') {
            setStartDate(moment().subtract(1,'weeks').day('Sunday').startOf('day'));
            setEndDate(moment().subtract(1,'weeks').day('Sunday').add(6, 'days').endOf('day'));
        } else if (input === 'THIS_MONTH') {
            setStartDate(moment().startOf('month').startOf('day'));
            setEndDate(moment().endOf('day'));
        } else if (input === 'LAST_MONTH') {
            setStartDate(moment().subtract(1,'months').startOf('month').startOf('day'));
            setEndDate(moment().subtract(1,'months').endOf('month').endOf('day'));
        } else if (input === 'WITHIN_WEEK') {
            setStartDate(moment().subtract(1, 'weeks').startOf('day'));
            setEndDate(moment().add(1, 'weeks').endOf('day'));
        } else if (input === 'WITHIN_TWO_WEEKS') {
            setStartDate(moment().subtract(2, 'weeks').startOf('day'));
            setEndDate(moment().add(2, 'weeks').endOf('day'));
        } else if (input === 'WITHIN_MONTH') {
            setStartDate(moment().subtract(1, 'months').startOf('day'));
            setEndDate(moment().add(1, 'months').endOf('day'));
        } else if (input === 'WITHIN_YEAR') {
            setStartDate(moment().subtract(1, 'years').startOf('day'));
            setEndDate(moment().add(1, 'years').endOf('day'));
        } else if (input === 'CUSTOM') {
            setStartDate();
            setEndDate();
        }
    }

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    return (
        <form className="generate-report-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            <div>
                {(modalData.type === 'CER_LOCATION') ?
                    <Grid item >
                    <p>
                    <span style={{ color: 'red', fontWeight: 'bold' }}>WARNING:</span> Attempting to import an edited CER Location Report output file, containing CER Locations, will result in those CER records failing during import.
                    </p>
                     </Grid>
                    : ''}
            </div>
            {(modalLoading || isLoading) && <LinearProgress/>}
                <div>
                    {(modalData.type === 'CALL_HISTORY' || modalData.type === 'CALL_SUMMARY' || modalData.type === 'ECRC_CALL_HISTORY' ||  
                      modalData.reportType === 'LOCATION_LICENSES' || modalData.reportType === 'USER_LICENSES' || 
                      modalData.reportType === 'ENHANCED_NOTIFICATION_LICENSES') ?
                        <FormControl className="calendar-radio-buttons" component="fieldset">
                            <RadioGroup aria-label="calendar-select" value={value} onChange={handleDateChange}>
                                <FormControlLabel value="TODAY" control={<Radio color="primary" />} label="Today" />
                                <FormControlLabel value="YESTERDAY" control={<Radio color="primary" />} label="Yesterday" />
                                <FormControlLabel value="THIS_WEEK" control={<Radio color="primary" />} label="This Week" />
                                <FormControlLabel value="LAST_WEEK" control={<Radio color="primary" />} label="Last Week" />
                                <FormControlLabel value="THIS_MONTH" control={<Radio color="primary" />} label="This Month" />
                                <FormControlLabel value="LAST_MONTH" control={<Radio color="primary" />} label="Last Month" />
                                <FormControlLabel value="CUSTOM" control={<Radio color="primary" />} label="Custom" />
                            </RadioGroup>
                        </FormControl>
                        : (modalData.type === 'EXPIRING_LICENSES' || modalData.reportType === 'EXPIRING_LICENSES') ?
                            <FormControl className="calendar-radio-buttons" component="fieldset">
                                <RadioGroup style={{marginLeft: '-1.3em', paddingTop: '1.3em', width: '125%'}} aria-label="calendar-select" value={value} onChange={handleDateChange}>
                                    <FormControlLabel value="WITHIN_WEEK" control={<Radio color="primary" />} label="Within a Week" />
                                    <FormControlLabel value="WITHIN_TWO_WEEKS" control={<Radio color="primary" />} label="Within 2 Weeks" />
                                    <FormControlLabel value="WITHIN_MONTH" control={<Radio color="primary" />} label="Within a Month" />
                                    <FormControlLabel value="WITHIN_YEAR" control={<Radio color="primary" />} label="Within a Year" />
                                    <FormControlLabel value="CUSTOM" control={<Radio color="primary" />} label="Custom" />
                                </RadioGroup>
                            </FormControl> : ''
                    }
                    <br/>
                    <div className="calendar-wrapper">
                        {(modalData.type === 'CALL_HISTORY' || modalData.type === 'CALL_SUMMARY' || modalData.type === 'ECRC_CALL_HISTORY'|| 
                          modalData.reportType === 'LOCATION_LICENSES' || modalData.reportType === 'USER_LICENSES' || 
                          modalData.reportType === 'ENHANCED_NOTIFICATION_LICENSES' || modalData.type === 'EXPIRING_LICENSES') ?
                            <span style={{ display: 'block', marginBottom: 5 }}>Date Picker</span>
                            : ''
                        }
                        <div style={{ display: 'flex' }}>
                            {(modalData.type === 'CALL_HISTORY' || modalData.type === '' || modalData.type === 'ECRC_CALL_HISTORY' || modalData.type === 'EXPIRING_LICENSES') ?
                                <div>
                                    {startDate ? 
                                        <div className="date-view">
                                            {startDate ? moment(startDate._d.valueOf()).format("MMMM Do YYYY") : null} &#8594; {endDate ? moment(endDate._d.valueOf()).format("MMMM Do YYYY") : moment(startDate._d.valueOf()).endOf('day').format("MMMM Do YYYY")}
                                        </div>
                                    : <div className="date-view">Select date from calendar</div>
                                    }
                                </div>
                                : ''
                            }
                            {(modalData.type === 'CALL_HISTORY' || modalData.type === 'ECRC_CALL_HISTORY' || modalData.type === 'DEVICE_USERS' || 
                              modalData.type === 'DEVICE_USERS_DEVICES' ||modalData.type === 'HELD_DEVICE' || modalData.type === 'HELD_DEVICES_NO_LOCATION' || 
                              modalData.type === 'HELD_PLUS_DEVICES_NO_LOCATION' || modalData.type === 'MYE911_USERS_NO_LOCATION' || modalData.type === 'EXPIRING_LICENSES') ?
                                <div className="timezone-field" style={{ margin: (modalData.type === 'DEVICE_USERS' || modalData.type === 'DEVICE_USERS_DEVICES' || 
                                                                                  modalData.type === 'HELD_DEVICE'  || modalData.type === 'HELD_DEVICES_NO_LOCATION' || 
                                                                                  modalData.type === 'HELD_PLUS_DEVICES_NO_LOCATION' || 
                                                                                  modalData.type === 'MYE911_USERS_NO_LOCATION' || modalData.type === 'EXPIRING_LICENSES') && '10px auto' }}>
                                    <TimeZone 
                                        value={values.timeZoneType}
                                        onChange={handleChange}
                                        disabled={modalLoading}
                                        touched={touched}
                                        errors={errors}
                                        fieldRequired={true}
                                        displayCurrentTimeZone={false}
                                    />
                                    <Tooltip 
                                        title="This will define the time zone you will view your report in."
                                        classes={{
                                            tooltip: classes.tooltip
                                        }}
                                        style={{ marginLeft: 45 }}
                                    >
                                        <span className={classes.icon} tabIndex={0}><Info /></span>
                                    </Tooltip>
                                    <span id="timeZoneType-helper-text" className="error-prompt">{touched.timeZoneType ? errors.timeZoneType : ""}</span>
                                </div>
                                : ''
                            }
                        </div>
                        {(modalData.type === 'CALL_HISTORY' || modalData.type === 'CALL_SUMMARY' || modalData.type === 'ECRC_CALL_HISTORY' ||  
                          modalData.reportType === 'LOCATION_LICENSES' || modalData.reportType === 'USER_LICENSES' || 
                          modalData.reportType === 'ENHANCED_NOTIFICATION_LICENSES' || modalData.type === 'EXPIRING_LICENSES') ?
                            <DayPickerRangeController
                                numberOfMonths={2}
                                startDate={startDate}
                                endDate={endDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    setValue('CUSTOM');
                                    if (startDate) {
                                        startDate.startOf('day');
                                    }
                                    if (endDate) {
                                        endDate.endOf('day');
                                    }

                                    setStartDate(startDate);
                                    setEndDate(endDate);
                                }}
                                focusedInput={focusedInput}
                                initialVisibleMonth={() => moment().subtract(1, "M")}
                                onFocusChange={focusedInput => setFocusedInput(focusedInput || 'startDate')}
                                isOutsideRange={day => {
                                    if (modalData.type === 'EXPIRING_LICENSES' || modalData.reportType === 'EXPIRING_LICENSES') {
                                        return false;
                                    } else {
                                        return isInclusivelyAfterDay(day, moment().add(1, 'days'));

                                    }
                                }}
                            />
                            : ''
                        }
                    </div>
                </div>
            {(modalData.type === 'CALL_HISTORY' || modalData.type === 'CALL_SUMMARY' || modalData.type === 'ECRC_CALL_HISTORY' || 
              modalData.reportType === 'LOCATION_LICENSES' || modalData.reportType === 'USER_LICENSES' || 
              modalData.reportType === 'ENHANCED_NOTIFICATION_LICENSES' || modalData.type === 'EXPIRING_LICENSES') && (currentCompanyLevel === 'TOP_LEVEL' && company.toJS().subtenantEnabled) ?
                <div>
                    <label>Include Subtenants</label>
                    <Switch
                        id="subtenants-button"
                        checked={hasSubtenants}
                        onChange={handleOnChange}
                        color="primary"
                    />
                </div>
                : ''
            }
            <BuildingType 
                values={values}
                errors={errors}
                handleChange={handleChange}
                modalData={modalData}
                centered={true}
            />
            <LocationType 
                values={values}
                errors={errors}
                handleChange={handleChange}
                modalData={modalData}
                centered={true}
            />
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type="submit" 
                        disabled={modalLoading} 
                        className = {classes.btn}
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Generate Report</Button>
            </Grid>
        </form>
    );
}

const GenerateReportModal = props => {
    const { modalLoading, classes, modalData, company, setModalError, currentCompanyLevel, setModalClose } = props;
    
    const [startDate, setStartDate] = useState(moment().startOf('day'));
    const [endDate, setEndDate] = useState(moment().endOf('day'));
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [hasSubtenants, setHasSubtenants] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    function downloadLink(link, companyName, type, date) {
        const file = `${!companyName ? 'system' : companyName}-${type.toLowerCase()}_report-${date}.csv`.replace(/\s/g,'-');
        
        fetch(link)
            .then(response => {
                response.blob().then(
                    blob => {
                        download(blob, file);
                    }
                );
            });
    }
    
    const validationSchema = Yup
      .object({
        timeZoneType: modalData.type === 'CALL_HISTORY' || modalData.type === 'ECRC_CALL_HISTORY' || modalData.type === 'DEVICE_USERS' || 
                      modalData.type === 'DEVICE_USERS_DEVICES' || modalData.type === 'HELD_DEVICE'  || modalData.type === 'HELD_DEVICES_NO_LOCATION' || 
                      modalData.type === 'HELD_PLUS_DEVICES_NO_LOCATION' || modalData.type === 'MYE911_USERS_NO_LOCATION' || modalData.type === 'EXPIRING_LICENSES' ? 
        Yup.string("Choose Time Zone")
                .trim()
                .required("Time Zone is a required field.")
                :
                Yup.string("Choose Time Zone")
                .trim(),
        e911AnywhereLocations: Yup.boolean(),
        cerLocations: Yup.boolean(),
        e911AnywhereBuildings: Yup.boolean(),
        e911ManagerBuildings: Yup.boolean(),
        e911ManagerLocations: Yup.boolean(),
      })
        .test(
            (modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') && props.company.toJS().managerDataSync ? 'buildingTypeTest' : (modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION') && props.company.toJS().managerDataSync ? 'locationTypeTest' : '',
            null,
            (obj) => {
               if (props.company.toJS().managerDataSync) {
                    if ((modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING')) {
                        if (obj.e911AnywhereBuildings || obj.e911ManagerBuildings) {
                            return true;
                        }
                    } else if ((modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION')) {
                        if (obj.e911AnywhereLocations || obj.e911ManagerLocations) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    if (obj.e911AnywhereBuildings || obj.e911AnywhereLocations) {
                        return true;
                    }
                }

                return new Yup.ValidationError(
                    (modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') && props.company.toJS().managerDataSync ? 'At least one building type must be selected.' : 'At least one location type must be selected.',
                    null,
                    (modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') && props.company.toJS().managerDataSync ? 'buildingType' : (modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION') && props.company.toJS().managerDataSync ? 'locationType' : ''
                );
            }
        );

    function buildingType(values) {
        if (values.e911AnywhereBuildings && values.e911ManagerBuildings) return '';
        if (values.e911AnywhereBuildings) return 'default';
        if (values.e911ManagerBuildings) return 'MANAGER_DATA_SYNC';
    }
    
    function locationType(values) {
        if (values.e911AnywhereLocations && values.e911ManagerLocations) return '';
        if (values.e911AnywhereLocations) return 'default';
        if (values.e911ManagerLocations) return 'MANAGER_DATA_SYNC';
    }
   
    return(
        <Formik
            initialValues={{
                timeZoneType: moment.tz.guess(),
                e911AnywhereLocations: true,
                cerLocations: false,
                e911AnywhereBuildings: true,
                e911ManagerBuildings: false,
                e911ManagerLocations: false
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values) => {
                    if (modalData.type === 'BUILDING' || modalData.reportType === 'BUILDING') {
                        values.locationOrigin = buildingType(values);
                    } else if (modalData.type === 'LOCATION' || modalData.reportType === 'LOCATION') {
                        values.locationOrigin = locationType(values);
                    }

                    values.companyId = company.toJS().id;
                    if (modalData.type === 'CALL_SUMMARY') {
                        values.start = startDate.add(startDate.utcOffset(),'minutes').valueOf();
                        values.end = endDate ? endDate.add(endDate.utcOffset(),'minutes').valueOf() : moment(startDate).endOf('day').add(endDate.utcOffset(),'minutes').valueOf();
                    } else {
                        values.start = startDate.format('YYYY MM DD').replace(/\s/g,'-');
                        values.end = endDate ? endDate.format('YYYY MM DD').replace(/\s/g,'-') : moment(startDate).endOf('day').format('YYYY MM DD').replace(/\s/g,'-');
                    }

                    values.reportType = modalData.type;
                    if (currentCompanyLevel === 'SYSTEM') {
                        values.subtenants = true;
                    } else if ((currentCompanyLevel === 'TOP_LEVEL' && !company.toJS().subtenantEnabled) || currentCompanyLevel === 'SUBTENANT') {
                        values.subtenants = false;
                    } else {
                        values.subtenants = hasSubtenants;
                    }

                    setIsLoading(true);
                    ReportsAPI.generateReport(values).then(response => {
                        let counter = -1;

                        const interval = setInterval(() => {
                            ReportsAPI.getReportsById(response.data.id).then(response => {
                                if (response.data.status === 'NEW') {
                                    counter++;
                                    if (counter >= 100) response.data.status = 'ERROR';
                                } 

                                if (response.data.status === 'ERROR' || response.data.status === 'COMPLETE') {
                                    setIsLoading(false);
                                    clearInterval(interval);
                                    if (response.data.status === 'COMPLETE') {
                                        downloadLink(response.data.fileReferenceKey, company.toJS().name, modalData.name, response.data.created);
                                        setModalClose();
                                    } else {
                                        setModalError('We are currently experiencing a problem generating your report. Please try again shortly.');        
                                    }
                                }
                            }).catch(error => {
                                setIsLoading(false);
                                if (error.response) {
                                    setModalError(error.response.data.detail);
                                }
                            });
                        }, 1000);
                    }).catch(error => {
                        setIsLoading(false);
                        if (error.response) {
                            setModalError(error.response.data.detail);
                        }
                    });
                }
            }
            render={renderProps => <GenerateReportForm 
                                    company={company} 
                                    currentCompanyLevel={currentCompanyLevel} 
                                    modalData={modalData} isLoading={isLoading} 
                                    hasSubtenants={hasSubtenants} 
                                    setHasSubtenants={setHasSubtenants} 
                                    startDate={startDate} setStartDate={setStartDate} 
                                    endDate={endDate} setEndDate={setEndDate} 
                                    focusedInput={focusedInput} 
                                    setFocusedInput={setFocusedInput} 
                                    classes={classes} 
                                    modalLoading={modalLoading} 
                                    {...renderProps} />}
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    company: selectUserCompany(),
    currentCompanyLevel: currentCompanyLevel(),
    cerEnabled: selectCerEnabled()
});

export default compose(
    connect(mapStateToProps),
    ModalWrapper,
    withStyles(styles)
)(GenerateReportModal);