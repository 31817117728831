import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import ModalWrapper from '../../../components/ModalWrapper';
import { actions, selectAddressIsAlternate, selectAddressIsAmbiguous, makeSelectMultipleAddressList } from '../../../reducers/locations';
import { selectUserCompany } from '../../../reducers/auth';
import SimpleTreeTypeForm from './forms/SimpleTreeTypeForm';
import DefaultTreeTypeForm from './forms/DefaultTreeTypeForm';
import { LocationValidation } from './Validations';
import { selectLocationAlternateIdEnabled } from '../../../reducers/app';
import HomepageAPI from '../../../apis/homepageApi';
import LicensingServiceApi from '../../../apis/licensingServiceApi';

const styles = theme => ({
    btn: {
        marginTop: '10px'
    },
    icon: {
        position: 'absolute',
        right: '19px',
        bottom: '82px'
    },
    location: {
        width: '88%'
    },
    tooltip: {
      fontSize: '14px'
    },
    autoCompleteOptions: {
        width: '355px'
    },
    paper: {
        position: 'absolute',
        zIndex: 9999,
        marginTop: theme.spacing.unit,
    }
});
const EditLocationForm = props => {
    const handleOnChange = (values) => {
        if (values.value) {
            props.setFieldValue('phoneNumber', values.value);
        } else {
            props.setFieldValue('phoneNumber', '');
        }
        props.setFieldTouched('phoneNumber', true);
    }

    const handleCallbackNumberOnChange = (values) => {
        if (values.value) {
            props.setFieldValue('callbackNumber', values.value);
        } else {
            props.setFieldValue('callbackNumber', '');
        }
        props.setFieldTouched('callbackNumber', true);
    }

    if (props.modalData.simple)
        return <SimpleTreeTypeForm {...props} locationLicenseDeficit={props.locationLicenseDeficit} handleOnChange={handleOnChange} handleCallbackNumberOnChange={handleCallbackNumberOnChange}/>
    else
        return <DefaultTreeTypeForm {...props} locationLicenseDeficit={props.locationLicenseDeficit} handleOnChange={handleOnChange} handleCallbackNumberOnChange={handleCallbackNumberOnChange}/>
}

const EditLocationModal = props => {
    const { modalLoading, classes, modalData } = props;
    const companyId = props.company.toJS().id;
    const usageBasedLicensing = props.company.toJS().usageBasedLicensing;
    const requiredInfo = props.company.toJS().requireLocationInfo;
    const locationTreeType = props.company.toJS().locationTreeType;
    const [overrideOrganizationNameToggle, setOverrideOrganizationNameToggle] = useState(getInitialOrgNameOverrideState(modalData));
    const [phoneNumberToggle, setPhoneNumberToggle] = useState(getInitialPhoneToggleState(modalData));
    const [orgName, setOrgName] = useState(getInitialOrgNameState(modalData));
    const [locationLicenseDeficit, setLocationLicenseDeficit] = useState(false);
    const [geoCordsAreRequired, setGeoCordsAreRequired] = useState(true);
    const [addressIsRequired, setAddressIsRequired] = useState(true);
    const [addressAndGeoCoordsToggle, setAddressAndGeoCoordsToggle] = useState(modalData.address ? 'address' : 'geoCoords');

    function getInitialPhoneToggleState(modalData) {
        if(modalData.alternateId) {
            return 'LOCATION_ALTERNATE_ID';
        }
        else {
            return 'PHONE_NUMBER';
        }
    }

    function getInitialOrgNameState(modalData){
        if(modalData.orgNameOverriddenByBuilding){
            return null;
        }
        else {
           return modalData.orgNameOverride;
        }
    }

    function getInitialOrgNameOverrideState(modalData){
        if(modalData.orgNameOverriddenByBuilding){
            return false;

        }
        else if(!modalData.orgNameOverriddenByBuilding && modalData.orgNameOverride == null){
            return false;
        }
        else{
            return true;
        }
    }

    useEffect(() => {
        return () => {
            props.setAddressIsAmbiguous(false);
            props.setAddressIsAlternate(false);
        }
    }, []);

    useEffect(() => {
        if (!usageBasedLicensing && !modalData.alternateId && (!modalData.elin || !modalData.elin.phoneNumber)) {
            Promise.all([
                HomepageAPI.getCompanySummary(companyId).then(
                    (response) =>  response.data
                ).catch(error => {
                    console.log(error);
                }), 
                LicensingServiceApi.getLicenseCount(companyId).then(
                    (response) => response.data
                ).catch(error => {
                    console.log(error);
                })
            ]).then(stats => {
                const companyStats = stats[0];
                const licensingStats = stats[1];
                const numberOfLicensedLocations = !companyStats ? 0 : companyStats.numOfCERLocations + companyStats.numOfCorporateLocations;
                const numberOfLocationLicenses = !licensingStats ? 0 : licensingStats.Location;
                setLocationLicenseDeficit(numberOfLicensedLocations >= numberOfLocationLicenses);
            });
        }
    }, []);

    function displayLatitude(modalData) {
        if (modalData.geoCoords && modalData.geoCoords.latitude) {
            return modalData.geoCoords.latitude;
        } else if (modalData.address && modalData.address.latitude) {
            return modalData.address.latitude;
        } else {
            return '';
        }
    }
    
    function displayLongitude(modalData) {
        if (modalData.geoCoords && modalData.geoCoords.longitude) {
            return modalData.geoCoords.longitude;
        } else if (modalData.address && modalData.address.longitude) {
            return modalData.address.longitude;
        } else {
            return '';
        }
    }

    return(
        <Formik
            initialValues={{ 
                houseNumber: modalData.address && modalData.address.houseNumber ?  modalData.address.houseNumber : '',
                houseNumberExtension: modalData.address && modalData.address.houseNumberExtension ? modalData.address.houseNumberExtension : '',
                prefixDirection: modalData.address && modalData.address.prefixDirectional ? modalData.address.prefixDirectional : '',
                streetType: modalData.address && modalData.address.streetType ? modalData.address.streetType : '',
                postDirection: modalData.address && modalData.address.postDirectional ? modalData.address.postDirectional : '',
                street: modalData.address && modalData.address.streetName ? modalData.address.streetName : '',
                city: modalData.address && modalData.address.city ? modalData.address.city : '',
                stateProvince: modalData.address && modalData.address.state ? modalData.address.state : '',
                zip: modalData.address && modalData.address.zipCode ? modalData.address.zipCode : '',
                country: modalData.address && (modalData.address.country === 'USA' || modalData.address.country === 'US') ? 'US' : 'CA',
                supplemental: modalData.supplementalData ? modalData.supplementalData : '',
                name: modalData.name,
                phoneNumber: modalData.elin ? modalData.elin.phoneNumber : '',
                linkName: modalData.htmlLinkName,
                linkUrl: modalData.htmlLinkUrl,
                address: modalData.address && modalData.address.normalizedAddress ? modalData.address.normalizedAddress : '',
                latitude: modalData.address && modalData.address.normalizedAddress ? '' : displayLatitude(modalData),
                longitude: modalData.address && modalData.address.normalizedAddress ? '' : displayLongitude(modalData),
                info: modalData.info,
                multipleAddressSelect: '',
                overrideOrganizationName: orgName ? orgName : '',
                alternateId: modalData.alternateId ? modalData.alternateId : '',
                callbackNumber: modalData.callbackNumber ? modalData.callbackNumber : ''
            }}
            validate= { (values) => {
                // Only do this if you need to access the value from the form to make something required
                const validationSchema =  LocationValidation(props.isAlternate, requiredInfo, locationTreeType, props.locationAlternateIdEnabled, phoneNumberToggle, values, props.isAmbiguous, geoCordsAreRequired, addressIsRequired);
                try {
                    validateYupSchema(values, validationSchema, true, {});
                } catch (err) {
                    return yupToFormErrors(err);
                }
                return {}
            }}
            onSubmit = {
                (values) => {
                    if(props.modalData.simple) {
                        values.simple = true;
                        if (values.multipleAddressSelect) {
                            values.multipleAddressSelect = JSON.parse(values.multipleAddressSelect);
                        }
                    }
                    
                    values.id = modalData.id;
                    values.parentId = modalData.parentId;
                    if(!overrideOrganizationNameToggle) {
                        values.overrideOrganizationName = undefined;
                    }
                    values.fullAddress = values.address;
                    if (values.address) {
                        if (modalData.address != null && (values.address === modalData.address.normalizedAddress)) {
                           props.setAddressIsAlternate(true);
                        }
                    }
                    if(phoneNumberToggle === 'PHONE_NUMBER') {
                        values.alternateId = undefined;
                        values.callbackNumber = undefined;
                    }
                    else {
                        values.phoneNumber = undefined;
                    }
                    props.submitEditLocationForm(values, props.modalData.buildingFlag);
                }
            }
            render={formikProps => <EditLocationForm 
                                        {...formikProps} 
                                        {...props}
                                        addressAndGeoCoordsToggle={addressAndGeoCoordsToggle}
                                        setAddressAndGeoCoordsToggle={setAddressAndGeoCoordsToggle} 
                                        addressIsRequired={addressIsRequired}
                                        setAddressIsRequired={setAddressIsRequired}
                                        geoCordsAreRequired={geoCordsAreRequired}
                                        setGeoCordsAreRequired={setGeoCordsAreRequired}
                                        classes={classes} 
                                        modalLoading={modalLoading} 
                                        locationLicenseDeficit = {locationLicenseDeficit}
                                        overrideOrganizationNameToggle={overrideOrganizationNameToggle}  
                                        setOverrideOrganizationNameToggle={setOverrideOrganizationNameToggle}
                                        phoneNumberToggle={phoneNumberToggle}	
                                        setPhoneNumberToggle={setPhoneNumberToggle}	
                                        modalErrorOptions={props.modalErrorOptions}		
                                        multipleAddressList={props.multipleAddresses}
                                        />
            }
        />
    );
}

const mapStateToProps = ()  => createStructuredSelector({
    company: selectUserCompany(),
    multipleAddresses: makeSelectMultipleAddressList(),
    isAlternate: selectAddressIsAlternate(),
    isAmbiguous: selectAddressIsAmbiguous(),
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        submitEditLocationForm: (location, simple) => dispatch(actions.editLocationRequest(location, simple)),
        setAddressIsAmbiguous: (flag) => dispatch(actions.addressIsAmbiguous(flag)),
        setAddressIsAlternate: (flag) => dispatch(actions.addressIsAlternate(flag))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    ModalWrapper,
    withStyles(styles)
)(EditLocationModal);