import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Input, InputLabel, MenuItem } from "@material-ui/core";
import { Picky } from 'react-picky';
import NotificationIntegrationsAPI from '../../../../apis/notificationIntegrationsApi';
import { connect } from 'react-redux';
import { userCompanyId } from '../../../../reducers/auth';
import { actions as AppActions } from '../../../../reducers/app';
import { createStructuredSelector } from 'reselect';
import DistListSyncSelect from './DistListSyncSelect/DistListSyncSelect';

const InformaCastEntityForm = props => {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit, 
        modalLoading,
        setFieldValue,
        setFieldTouched,
        modalData,
        modalErrorOptions,
        setModalErrorOptions,
        classes } = props;

    const [distListData, setDistListData] = useState([]);
    const [informaCastTemplates, setInformaCastTemplate] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [distListFailed, setDistListFailed] = useState(false);
    const [tempListFailed, setTempListFailed] = useState(false);

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        NotificationIntegrationsAPI.getNotificationIntegrationsDistributionList(props.companyId).then(response => {
            if (modalData) {
                let modalDataFilter = modalData.distributionLists.filter( x => x.name !== '[Unknown Distribution List]');
                if (modalDataFilter)
                    modalDataFilter.forEach(item => {
                        item.value = item.name;
                        item.label = item.name;
                    })
                
                setFieldValue('informaCastDistList', modalDataFilter);
            }

            response.data.forEach(obj => {
                obj.value = obj.name;
                obj.label = obj.name;
            });
            setDistListData(response.data);
        }).catch(error => {
            console.log(error);
            setDistListFailed(true);
        });

        NotificationIntegrationsAPI.getNotificationIntegrationsTemplates(props.companyId).then(response => {
            if (modalData) {
                const findTemplate = response.data.find(template => template.id === modalData.templateId);
                if (findTemplate && findTemplate.name !== '[Unknown Template]'){
                    setFieldValue('informaCastTemplate', findTemplate);
                }
            }
            setInformaCastTemplate(response.data);
        }).catch(error => {
            console.log(error);
            setTempListFailed(true);
        });
    }, []);

    useEffect(() => {
            if (distListFailed || tempListFailed) {
                let options = {};

                if(!options.backendFieldError) {
                    options.displayTopErrorMessage = true;
                    options.topErrorMessage = 'The access token provided does not have permissions to view InformaCast distribution lists and/or message templates.';
                }

                setModalErrorOptions(options);
            }
    }, [distListFailed || tempListFailed]);

    function handleSelectDistList(value) {
        setFieldValue('informaCastDistList', value);
    }

    function handleClear() {
        if (modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) {
            modalErrorOptions.backendFieldError.name = '';
        }
    }

    return (
        <form className="informacast-entity-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <TextField
                id="informaCastName"
                label="*InformaCast 911 Alert Entity Name"
                name="informaCastName"
                type="informaCastName"
                onChange={(e) => {
                    handleChange(e);
                    handleClear();
                }}
                value={values.informaCastName}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.informaCastName && Boolean(errors.informaCastName)) || (errors.informaCastName && Boolean(errors.informaCastName.includes('max'))) || (modalErrorOptions && !!modalErrorOptions.backendFieldError && !!modalErrorOptions.backendFieldError.name)}/>
                {(modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) && 
                    <span id="informaCastName-backend-helper-text" className="error-prompt">{modalErrorOptions.backendFieldError.name}</span>
                }
                <span id="informaCastName-helper-text" className="error-prompt">{(touched.informaCastName && Boolean(errors.informaCastName)) || (errors.informaCastName && Boolean(errors.informaCastName.includes('max'))) ? errors.informaCastName : ''}</span>
            <br/>
            <br/>
            <div>
                <InputLabel id="dist-list-label" shrink={true} htmlFor="informaCast-dist-list" error={touched.informaCastDistList && errors.informaCastDistList}>*InformaCast Distribution Lists</InputLabel>
                <DistListSyncSelect
                    id="informaCast-dist-list"
                    options={distListData}
                    value={values.informaCastDistList}
                    error={touched.informaCastDistList && errors.informaCastDistList}
                    onChange={handleSelectDistList}
                    isMulti={true}
                    {...props}
                />
                <span id="informaCastDistributionlist-helper-text" className="error-prompt">{(touched.informaCastDistList && Boolean(errors.informaCastDistList)) || (errors.informaCastDistList && Boolean(errors.informaCastDistList.includes('max'))) ? errors.informaCastDistList : ''}</span>
            </div>
            <br/>
            <br/>
            <InputLabel shrink={true} htmlFor="informaCastTemplate" error={touched.informaCastTemplate && Boolean(errors.informaCastTemplate)}>*InformaCast Template</InputLabel>
            <Select
                id="informaCastTemplate"
                name="informaCastTemplate"
                value={values.informaCastTemplate}
                onChange={handleChange}
                disabled={modalLoading}
                input={<Input name="template"/>}
                fullWidth={true}
                error={touched.informaCastTemplate && Boolean(errors.informaCastTemplate)}>
                {   
                    informaCastTemplates.length > 0 ? informaCastTemplates.map( (template, index) => 
                        <MenuItem key={index} value={template}>{template.name}</MenuItem>)
                    :
                    <MenuItem disabled={true} value={null}>No options</MenuItem>
                }
            </Select>
            <span id="informaCastTemplate-helper-text" className="error-prompt">{touched.informaCastTemplate ? errors.informaCastTemplate : ""}</span>
            <br/>
            <br/>
            <br/>
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                        type={'submit'} 
                        disabled={modalLoading} 
                        onClick={handleScroll}
                        color="primary" 
                        variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

const mapStateToProps = () => createStructuredSelector({
	companyId: userCompanyId(),
});

const mapDispatchToProps = dispatch => {
    return {
        setModalErrorOptions: (error) => dispatch(AppActions.setModalErrorOptions(error))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(InformaCastEntityForm);