import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types, actions as AlertsActions } from '../../reducers/alerts';

class AlertsSubscriptionsEditMenu extends Component {
	options = [
		'Edit',
		'Delete'
	];
	
	ITEM_HEIGHT = 48;
	state = {
		anchorEl: null,
	};

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleMenuItemClick = (option) => {
		this.handleClose();
		switch (option) {
			case 'Edit':
				this.props.setCurrentModal(types.EDIT_ALERT_SUBSCRIPTION, this.props.row.original);
				break;
			case 'Delete':
				this.props.setCurrentModal(types.DELETE_ALERT_SUBSCRIPTION, this.props.row.original);
				break;
			default:
				return null;
		}
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div>
				<IconButton
					id={`alert-subscription-action-menu-button-${this.props.row.index}`}
					aria-label="More"
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id={`alert-subscription-action-menu-${this.props.row.index}`}
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
					PaperProps={{
						style: {
							maxHeight: this.ITEM_HEIGHT * 6.5,
							minWidth: 200,
						},
					}}
				>
					{this.options.map((option, index) => (
						<MenuItem id={`alert-subscription-item-${this.props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => this.handleMenuItemClick(option, event)}>
							{option}
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}
}

AlertsSubscriptionsEditMenu.propTypes = {
	row: PropTypes.object,
	setCurrentModal: PropTypes.func
};


const mapDispatchToProps = dispatch => {
  return {
			setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default connect(null, mapDispatchToProps)(AlertsSubscriptionsEditMenu);
