import React from 'react';
import { compose } from 'redux';
import ModalWrapper from '../../../../components/ModalWrapper';
import './Details.css';
import { selectHorizonPrimeEnabled } from '../../../../reducers/app';
import { originalCompanyOrgType } from '../../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

 
function BuildingDetails(props) {
    const {
        name,
        address,
        normalizedGeoCoords,
        supplementalData,
        orgNameOverride,
        userRole,
        addressEntityName,
        horizonPrimeEnabled
    } = props.modalData;
    return(
        <div>
            <ul className="details-list">
                <li><b>Building Name: </b>{addressEntityName !== undefined ?  addressEntityName : name}</li>
                <li><b>Address: </b>{address ? address.normalizedAddress : ''}</li>
                <li><b>Geodetic Coordinates: </b>{normalizedGeoCoords ? normalizedGeoCoords.latitude + ", " + normalizedGeoCoords.longitude : ''}</li>
                <li><b>Supplemental Information: </b>{supplementalData}</li>
                <li><b>Organization Name Override: </b>{orgNameOverride}</li>
                <li><b>Status: </b>{address ? address.msagValid : ''}</li>
                { props.originalCompanyOrgType === 'SYSTEM' ? <li><b>Backend Provider: </b>{address ? address.backendProvider : ''}</li> : '' }
                {props.horizonPrimeEnabled ? <li><b>PSAP URI: </b> {address ? address.psapUri : ''}</li> : ''}
            </ul>
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    horizonPrimeEnabled: selectHorizonPrimeEnabled(),
    originalCompanyOrgType: originalCompanyOrgType()
});


export default compose(ModalWrapper, connect(mapStateToProps))(BuildingDetails);