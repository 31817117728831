import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/deviceUsers';
import { connect } from 'react-redux';

function DeleteDeviceUserLocation(props){
    const { modalLoading } = props;
    const handleDelete = () => {
        props.submitDeleteDeviceUserLocation(props.modalData.location.id, props.modalData.location.deviceUserId);
    }

    return(
        <div>
            {modalLoading && <LinearProgress/>}
            <Grid container 
                    direction="column" 
                    justify="center"
                    alignItems="center"
                    spacing={16} >
                <Grid item >
                    <p>
                        Please confirm that you would like to delete the following personal location.
                    </p>
                </Grid>
                <Grid item >
                    <span>Location Name: </span>
                    <b id="locationName">{props.modalData.location.name}</b>
                </Grid>
                <Grid item >
                    <Button id="deleteBtn"
                            variant="contained" 
                            color="secondary" 
                            onClick={handleDelete}>
                        DELETE
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteDeviceUserLocation: (locationId, deviceUserId) => dispatch(actions.deleteLocationRequest(locationId, deviceUserId))
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    ModalWrapper
)(DeleteDeviceUserLocation);