import React from 'react';
import { Select, MenuItem, Tooltip, Input, InputLabel } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import TimeZone from '../../../../components/TimeZone/TimeZone';

export default function ReportDataComponent(props) {
    const {
        values,
        handleChange,
        touched,
        modalLoading,
        errors,
        modalData,
        classes,
        company
    } = props;

    return (
        <div className="report-data">
                {
                    modalData.reportType === 'CALL_HISTORY' || 
                    modalData.reportType === 'CALL_SUMMARY' || 
                    modalData.reportType === 'ECRC_CALL_HISTORY' ||
                    modalData.type === 'CALL_HISTORY' || 
                    modalData.type === 'CALL_SUMMARY' || 
                    modalData.type === 'ECRC_CALL_HISTORY' ?
                    <div className="date-range-info">
                        <InputLabel shrink={true} htmlFor="dataRange" error={touched.dataRange && Boolean(errors.dataRange)}>Date Range</InputLabel>
                        <Select
                            id="dataRange"
                            name="dataRange"
                            value={values.dataRange ? values.dataRange : 'LAST_WEEK'}
                            onChange={handleChange}
                            disabled={modalLoading}
                            input={<Input name="dataRange" id="dataRange-label"/>}
                            fullWidth={true}
                            error={touched.dataRange && Boolean(errors.dataRange)}>
                                <MenuItem value="TODAY">Today</MenuItem>
                                <MenuItem value="YESTERDAY">Yesterday</MenuItem>
                                <MenuItem value="THIS_WEEK">This Week</MenuItem>
                                <MenuItem value="LAST_WEEK">Last Week</MenuItem>
                                <MenuItem value="THIS_MONTH">This Month</MenuItem>
                                <MenuItem value="LAST_MONTH">Last Month</MenuItem>
                        </Select>
                        <span id="dataRange-helper-text" className="error-prompt">{touched.dataRange ? errors.dataRange : ""}</span>
                    </div>
                    : modalData.reportType === 'EXPIRING_LICENSES' || modalData.type === 'EXPIRING_LICENSES' ? 
                        <div className="date-range-info">
                            <InputLabel shrink={true} htmlFor="dataRange" error={touched.dataRange && Boolean(errors.dataRange)}>Date Range</InputLabel>
                            <Select
                                id="dataRange"
                                name="dataRange"
                                value={values.dataRange ? values.dataRange : 'WITHIN_WEEK'}
                                onChange={handleChange}
                                disabled={modalLoading}
                                input={<Input name="dataRange" id="dataRange-label"/>}
                                fullWidth={true}
                                error={touched.dataRange && Boolean(errors.dataRange)}>
                                    <MenuItem value="WITHIN_WEEK">Within a Week</MenuItem>
                                    <MenuItem value="WITHIN_TWO_WEEKS">Within 2 Weeks</MenuItem>
                                    <MenuItem value="WITHIN_MONTH">Within a Month</MenuItem>
                                    <MenuItem value="WITHIN_YEAR">Within a Year</MenuItem>
                            </Select>
                            <span id="dataRange-helper-text" className="error-prompt">{touched.dataRange ? errors.dataRange : ""}</span>
                    </div> : ''
                }
                {
                    modalData.reportType === 'DEVICE_USERS' || 
                    modalData.reportType === 'DEVICE_USERS_DEVICES' || 
                    modalData.reportType === 'CALL_HISTORY' || 
                    modalData.reportType === 'ECRC_CALL_HISTORY' || 
                    modalData.reportType === 'HELD_DEVICE' ||
                    modalData.reportType === 'HELD_DEVICES_NO_LOCATION' ||
                    modalData.reportType === 'MYE911_USERS_NO_LOCATION' ||
                    modalData.reportType === 'EXPIRING_LICENSES' ||
                    modalData.type === 'DEVICE_USERS' || 
                    modalData.type === 'DEVICE_USERS_DEVICES' || 
                    modalData.type === 'CALL_HISTORY' || 
                    modalData.type === 'ECRC_CALL_HISTORY' || 
                    modalData.type === 'HELD_DEVICE' ||
                    modalData.type === 'HELD_DEVICES_NO_LOCATION' ||
                    modalData.type === 'MYE911_USERS_NO_LOCATION' ||
                    modalData.type === 'EXPIRING_LICENSES' ?
                    <div className="timezone-info">
                        <TimeZone 
                            value={values.timeZoneType ? values.timeZoneType : ''}
                            onChange={handleChange}
                            disabled={modalLoading}
                            touched={touched}
                            errors={errors}
                            fieldRequired={true}
                            displayCurrentTimeZone={false}
                        />
                        <Tooltip 
                            title="This will define the time zone you will view your report in."
                            classes={{
                                tooltip: classes.tooltip
                            }}
                            style={{ marginLeft: '45px' }}
                        >
                            <span className={classes.icon} tabIndex={0}><Info /></span>
                        </Tooltip>
                        <span id="timeZoneType-helper-text" className="error-prompt">{touched.timeZoneType ? errors.timeZoneType : ""}</span>
                    </div>
                    : ''
                }
            </div>
    );
}