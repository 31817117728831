/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import globalReducer from './reducers/app';
import history from './utils/history';
import { types } from './reducers/app';
import auth from './reducers/auth';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(state, action, injectedReducers = {}) {
	switch(action.type){
		case types.LOGOUT:
			state = undefined;
			break;
		default:
	}

	const rootReducer = combineReducers({
		global: globalReducer,
		auth,
		...injectedReducers,
	});
	// Wrap the root reducer and return a new root reducer with router state
	const mergeWithRouterState = connectRouter(history);
	return mergeWithRouterState(rootReducer).apply(null, [state, action]);
}