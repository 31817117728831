import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/organizationManagement';
import { connect } from 'react-redux';
import { modalLoading } from '../../../reducers/app';

class DeleteMassNotifcationModal extends Component {

    handleDelete = () => {
        this.props.submitDeleteIntegrationCred(this.props.modalData.company.id);
        this.props.modalData.setDeleteReset(false)
        this.props.modalData.setSuccessSecondary(false)
    }

    render() {
        const { modalLoading} = this.props;   
        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            <b style={{color: "red"}}>WARNING:</b> You are about to reset the API access information you have entered, which will disable the Incident Communications integration for your organization. Are you sure you want to proceed?
                        </p>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteIntegrationCred: (id) => dispatch(actions.deleteIntegrationCred(id))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteMassNotifcationModal);