import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import PidfloDevicesEditMenu from '../../containers/PidfloDevicesEditMenu/pidfloDevicesEditMenu';
import { createStructuredSelector } from 'reselect';
import { actions as AppActions, selectPageSize } from '../../reducers/app';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';
import { currentCompanyOrgType, orgTypes } from '../../reducers/auth';
import { makeSelectPidfloDevicesList, makeSelectPidfloDevicesListLoading, makeSelectPidfloDevicesPageCount, actions as PidfloDevicesActions } from '../../reducers/pidfloDevices';

function PidfloDevicesTable(props) {
    const { pidfloDevicesFromApi, 
            loading,
            pidfloDevicesPageCount,
            fetchPidfloDevicesList,
            searchValue,
            setSearchValue,
            sortColumns,
            setSortColumns,
            currentSortColumn,
            setCurrentSortColumn,
            pageIndex,
            setPageIndex,
            pageSize
         } = props;

    const columns = [];

    columns.push({
            Header: "Phone Number",
            id: "phoneNumber",
            sortable: true,
            Cell: row => <div title={row.original.phoneNumber}>{!isNaN(row.original.phoneNumber) ? <NumberFormatter number={row.original.phoneNumber} /> : row.original.phoneNumber}</div>
        });
        columns.push({
            Header: "Description",
            id: "description",
            Cell: row => <div title={row.original.description}>{row.original.description}</div>
        });
        columns.push({
            Header: '',
            width: 65,
            id: 'edit-menu',
            Cell: row => (
                <PidfloDevicesEditMenu row={row}/>
            )
        });

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }
    
    let pidfloDevices = pidfloDevicesFromApi;
    if(pidfloDevices)
        pidfloDevices = pidfloDevices.toArray();
    
    useEffect(() => {
        if (pidfloDevices && pidfloDevices.length === 0 && pageIndex > 0 && pidfloDevicesPageCount < pageIndex+1) {
            setPageIndex(pageIndex-1)
        }
    }, [pidfloDevices && pidfloDevices.length === 0 && pageIndex > 0 && pidfloDevicesPageCount < pageIndex+1]);
    
    useEffect( () => {
        if (props.pageSize) {
            fetchPidfloDevicesList(pageIndex+1, pageSize, searchValue, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
            props.setTableProps({
                page: pageIndex+1, 
                pageSize: props.pageSize, 
                sortBy: currentSortColumn.id, 
                direction: currentSortColumn.desc ? 'DESC': 'ASC'
            });
        }
    }, [currentSortColumn, props.pageSize, pageIndex]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <div>
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight"
                showPaginationBottom={true}
                data={pidfloDevices}
                page={pageIndex}
                pages={pidfloDevicesPageCount <= 0 ? 1 : pidfloDevicesPageCount}
                minRows={pidfloDevices && pidfloDevices.length > 0 ? 0 : 5}
                columns={columns}
                loading={loading}
                sorted={sortColumns}
                onSortedChange={(newSorted, column, shiftKey) => {
                    let sortedArray = [...sortColumns];
                    let currentSortColumn;
                    sortedArray.forEach( (columnObject, index) => {                    
                        if(column.id === columnObject.id) {
                            // state needs updated object reference, doesn't detect nested fields
                            currentSortColumn = {...columnObject};
                            currentSortColumn.desc = !currentSortColumn.desc;
                            sortedArray[index] = currentSortColumn;
                        }
                        else {
                            let resetColumn = {...columnObject};
                            resetColumn.desc = true;
                            sortedArray[index] = resetColumn;
                        }
                        setCurrentSortColumn(currentSortColumn);
                        setSortColumns(sortedArray);
                    })
                }}
                defaultPageSize={25}
                manual
                onFetchData={(state, instance) => {
                    props.setPageSize(state.pageSize);
                    props.setCurrentPage(state.page+1);
                    resetScrollInsideTable();
                }}
                onPageSizeChange={(pageSize) => {
                    props.setPageSize(pageSize);
                    setPageIndex(0);
                    resetScrollInsideTable();
                }}
                onPageChange={(pageIndex) => {
                    setPageIndex(pageIndex);
                    resetScrollInsideTable();
                }}
                LoadingComponent={ReactTableLoadingComponent}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'pidfloDevices-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                } />
        </div>
    );

}

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({
        pidfloDevicesFromApi: makeSelectPidfloDevicesList(),
        loading: makeSelectPidfloDevicesListLoading(),
        pidfloDevicesPageCount: makeSelectPidfloDevicesPageCount(),
        currentCompanyOrgType: currentCompanyOrgType()
    });
}

const mapDispatchToProps = dispatch => {
    return {
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PidfloDevicesTable);
    