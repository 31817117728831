import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { pageErrorDisplay,
        pageErrorMessage } from '../../reducers/app';
import './pageErrorMessage.css';

function PageErrorMessage(props) {
    const { pageErrorDisplay, pageErrorMessage } = props;
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (pageErrorDisplay) {
            setSnackbarOpen(true);
        } else {
            setSnackbarOpen(false);
        }
    }, [pageErrorDisplay])

    return (
        <div>
            <Snackbar
                className="snackbar-popup error"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={10000}
                onClose={handleClose}
                message={<div>
                    <span id="client-snackbar">
                        {pageErrorMessage}
                    </span>
                </div>}
                action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>,
                ]}
            />
        </div>
    );

}

const mapStateToProps = createStructuredSelector({
    pageErrorDisplay: pageErrorDisplay(),
    pageErrorMessage: pageErrorMessage(),
});

export default connect(mapStateToProps)(PageErrorMessage);


