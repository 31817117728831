import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actions as ImportStatusActions, makeSelectImportStatusList, makeSelectImportStatusListLoading } from '../../../reducers/homepage';
import { selectUserCompany } from '../../../reducers/auth';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../../components/ReactTableLoadingComponent';
import moment from 'moment';
import { importTypes } from '../../../containers/ModalConductor/ImportsModals/AddImportModal';
import HasAccess from '../../../components/HasAccess/HasAccess';

function ImportStatusSection(props) {
    const buildTableColumns = [
        {
            Header: <div id="recent-import-status-created">Created</div>,
            id: "created",
            Cell: row => <div title={formatLastAccess(row.original.created)}>{formatLastAccess(row.original.created)}</div>
        },
        {
            Header: <div id="recent-import-status-type">Import Type</div>,
            id: "type",
            Cell: row => <div title={row.original.importType}>{getImportTypeMap(row.original.importType)}</div>
        },
        {
            Header: <div id="recent-import-status-status">Status</div>,
            id: "status",
            Cell: row => <div title={row.original.status}>{row.original.status}</div>
        }
    ];

    function formatLastAccess(lastAccess) {
        return lastAccess ?  moment(lastAccess).format('MM/DD/YYYY hh:mm:ss A') : '';
    }

    function getImportTypeMap(importType){
    const types = importTypes(props.company.toJS().locationTreeType, true);
     var map = new Map(); 
        for(var i = 0; i < types.length; i++){ 
            map.set(types[i].key, types[i].value); 
        } 
       var value =  map.get(importType);
        return value;
    }

    useEffect(() => {
        fetchImportStatusList();
    }, [props.company.toJS().id]);

    const {
        loading, importStatus, fetchImportStatusList
    } = props;
    let importStatusArray = [];

    if (importStatus) {
        importStatusArray = importStatus.toArray();
    }

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');
        if(table && tableBody){
            if (tableBody.scrollHeight > tableBody.clientHeight) {
                table.classList.add('scrollable');
            } else {
                table.classList.remove('scrollable');
            }    
    }
    }, [loading]);
    
    return (
        <div>
             <HasAccess
             permissions={["VIEW_DEVICE_USERS", "VIEW_NETWORK_DISCOVERY", "VIEW_LOCATIONS"]}      
        >  
            <h2 id="recent-import-status-header">Recent Import Status</h2>
            <div>
                <ReactTable
                    resizable={false}
                    sortable={false}
                    className="-striped -highlight"
                    showPaginationBottom={false}
                    data={importStatusArray.slice(0, 5)}
                    minRows={5}
                    columns={buildTableColumns}
                    defaultPageSize={5}
                    manual
                    loading = {loading}
                    onFetchData={(state, instance) => {
                        fetchImportStatusList();
                    }}
                    LoadingComponent={ReactTableLoadingComponent}
                    getTrProps={
                        (state, rowInfo, column) => {
                            if(rowInfo) {
                                return {
                                    id: 'import-status-row-' + rowInfo.index,
                                };
                            } else {
                              return {};
                            }
                        }
                    }
                />
            </div>
            </HasAccess>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    importStatus: makeSelectImportStatusList(),
    loading: makeSelectImportStatusListLoading(),
    company: selectUserCompany()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchImportStatusList: () => dispatch(ImportStatusActions.getImportStatusRequest())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportStatusSection);