import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HasAccess from '../../components/HasAccess/HasAccess';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types as AlertsTypes } from '../../reducers/alerts';
import AlertsSubscriptionsList from '../../containers/AlertsSubscriptionsList/AlertsSubscriptionsList';


function AlertsSubscriptions(props) {

    function modalHandleOpen() {
        props.setCurrentModal(AlertsTypes.ADD_ALERT_SUBSCRIPTION);
    }

    return (
        <div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <HasAccess
                    permissions={["ADD_ALERT_SUBSCRIPTIONS"]}
                >        
                    <Button className="button" id="btnAddBuilding" variant="contained" color="primary" onClick={modalHandleOpen}>
                        Add Subscription
                    </Button> 
                </HasAccess>
            </Grid>
            <br/>
            <AlertsSubscriptionsList />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal) => dispatch(AppActions.setCurrentModal(currentModal))
    }
};

export default connect(null,mapDispatchToProps)(AlertsSubscriptions);