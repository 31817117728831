import React, { useEffect, useRef } from "react";
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import "react-table/react-table.css";
import EonUserEditMenu from '../../containers/EonUserEditMenu/eonUserEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import { createStructuredSelector } from "reselect";
import moment from "moment";

import { connect } from "react-redux";
import {
  makeSelectEonUsersList,
  makeSelectEonUsersListLoading,
  actions as EonUserActions
} from '../../reducers/eonUsers';

import { makeSelectEonUsersPageCount } from "../../reducers/eonUsers";
import { actions as AppActions } from '../../reducers/app';

const EonUsersTable = props => {
  const eonUsersTableColumns = [
    {
      Header: <div id="eon-users-email">Email</div>,
      id: "email",
      sortable: true,
      Cell: row => <div title={row.original.email}>{row.original.email}</div>
    },
    {
      Header: <div id="eon-users-first-name">First Name</div>,
      id: "first-name",
      Cell: row => <div title={row.original.firstName}>{row.original.firstName}</div>
    },
    {
      Header: <div id="eon-users-last-name">Last Name</div>,
      id: "last-name",
      Cell: row => <div title={row.original.lastName}>{row.original.lastName}</div>
    },
    {
      Header: <div id="eon-users-last-time-logged-in">Last Time Logged In</div>,
      id: "last-time-logged-in",
      Cell: row => <div title={formatLastAccess(row.original.lastLogin)}>{formatLastAccess(row.original.lastLogin)}</div>
    },
    {
      Header: "",
      width: 65,
      id: "edit-menu",
      Cell: row => (
        <EonUserEditMenu row={row} 
                      setCurrentModal={props.setCurrentModal} 
                      eonUserId={props.eonUserId}
                      pageSize={props.pageSize} 
                      page={props.page}
                      currentSortColumn={props.currentSortColumn} />
      )
    }
  ];

  function formatLastAccess(callTime) {
    return callTime ? moment(callTime).format("MM/DD/YYYY hh:mm:ss A") : "";
  }

  const { loading, eonUsers, eonUsersPageCount, fetchEonUsersList} = props;
  const update = useRef(true);
  let eonUsersArray = [];

  if (eonUsers) {
    eonUsersArray = eonUsers.toArray();
}

useEffect(() => {
  if (eonUsersArray && eonUsersArray.length === 0 && props.page > 0 && eonUsersPageCount < props.page+1) {
    props.setPage(props.page-1)
  }
}, [eonUsersArray && eonUsersArray.length === 0 && props.page > 0 && eonUsersPageCount < props.page+1]);

  useEffect( () => {
    props.setPageSize(25);
    props.setCurrentPage(1);
  }, []);

  useEffect( () => {
    fetchEonUsersList(props.page + 1, props.pageSize, props.searchValue, props.currentSortColumn.id, props.currentSortColumn.desc ? 'DESC': 'ASC');
    props.setTableProps({
      page: props.page+1, 
      pageSize: props.pageSize, 
      sortBy: props.currentSortColumn.id, 
      direction: props.currentSortColumn.desc ? 'DESC': 'ASC'
  });
  }, [props.currentSortColumn, props.pageSize, props.page]);

  function resetScrollInsideTable() {
    let tableBody = document.querySelector('.rt-tbody');
    tableBody.scrollTop = 0;
  }

  useEffect(() => {
    const tableBody = document.querySelector('.rt-tbody');
    const table = document.querySelector('.ReactTable');

    if (tableBody.scrollHeight > tableBody.clientHeight) {
        table.classList.add('scrollable');
    } else {
        table.classList.remove('scrollable');
    }
}, [loading]);

  return (
    <ReactTable
      resizable={false}
      sortable={false}
      className="-striped -highlight"
      showPaginationBottom={true}
      data={eonUsersArray}
      minRows={eonUsersArray && eonUsersArray.length > 0 ? 0 : 5}
      page={props.page}
      pages={eonUsersPageCount <= 0 ? 1 : eonUsersPageCount}
      columns={eonUsersTableColumns}
      LoadingComponent={ReactTableLoadingComponent}
      sorted={props.sortColumns}
      onSortedChange={(newSorted, column, shiftKey) => {
          let sortedArray = [...props.sortColumns];
          let currentSortColumn;
          sortedArray.forEach( (columnObject, index) => {                    
              if(column.id === columnObject.id) {
                  // state needs updated object reference, doesn't detect nested fields
                  currentSortColumn = {...columnObject};
                  currentSortColumn.desc = !currentSortColumn.desc;
                  sortedArray[index] = currentSortColumn;
              }
              else {
                  let resetColumn = {...columnObject};
                  resetColumn.desc = false;
                  sortedArray[index] = resetColumn;
              }
              props.setCurrentSortColumn(currentSortColumn);
              props.setSortColumns(sortedArray);
          })
          
      }}
      onPageSizeChange={pageSize => {
        props.setPage(0);
        props.setPageSize(pageSize);
        resetScrollInsideTable();
      }}
      onPageChange={(pageIndex) => {
          props.setCurrentPage(pageIndex + 1);
          props.setPage(pageIndex);
          resetScrollInsideTable();
      }}
      defaultPageSize={25}
      manual
      eonUsers={eonUsers}
      loading={loading}
      getTrProps={
        (state, rowInfo, column) => {
            if(rowInfo) {
                return {
                    id: 'eon-users-row-' + rowInfo.index,
                };
            } else {
              return {};
            }
        }
      }
    />
  );
};

EonUsersTable.propTypes = {
  eonUsers: ImmutablePropTypes.list,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  eonUsers: makeSelectEonUsersList(),
  loading: makeSelectEonUsersListLoading(),
  eonUsersPageCount: makeSelectEonUsersPageCount()
});

const mapDispatchToProps = dispatch => {
  return {
      fetchEonUsersList: (page, pageSize, searchValue, sortBy, sortDirection) => dispatch(EonUserActions.getEonUsersRequest(page, pageSize, searchValue, sortBy, sortDirection)),
      setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
      setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
      setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EonUsersTable);
