import React, {useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    actions as AlertsActions,
    makeSelectAlertTemplatesList,
    makeSelectAlertTemplatesListLoading,
    makeSelectAlertTemplatesPageCount
} from '../../reducers/alerts';
import AlertsTemplatesListTable from '../../containers/AlertsTemplatesListTable/AlertsTemplatesListTable';
import {selectPageSize} from "../../reducers/app";

const initialSortColumns = [
    {
        id: 'name',
        desc: false
    }
];

function AlertTemplatesList(props) {
    const [page, setPage] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    return <AlertsTemplatesListTable
        alertsTemplates={props.alertTemplatesList}
        loading={props.loading}
        sortColumns={sortColumns}
        setSortColumns={setSortColumns}
        currentSortColumn={currentSortColumn}
        setCurrentSortColumn={setCurrentSortColumn}
        fetchAlertsTemplatesList={props.fetchAlertsTemplatesList}
        page={page}
        setPage={setPage}
        pageSize={props.pageSize}
    />
}

const mapStateToProps = createStructuredSelector({
    alertTemplatesList: makeSelectAlertTemplatesList(),
    alertTemplatesListPageCount: makeSelectAlertTemplatesPageCount(),
    loading: makeSelectAlertTemplatesListLoading(),
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchAlertsTemplatesList: (page, pageSize, sortBy, direction) => dispatch(AlertsActions.getAlertTemplatesRequest(page, pageSize, sortBy, direction))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertTemplatesList);