import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../reducers/auth';
import { getRefreshTokenExp, safeLogout } from '../../utils/authLocalStorageSync';


const AuthorizedRoute = ({ component: Component, ...rest}) => {
    const { authenticated, logout } = rest;

    function isAuthorized() {
        // if the user has been authenticated and logged in
        if(authenticated && authenticated !== 'null') {
            // if the user has an expired refresh token, then force a logout
            const refreshTokenExp = getRefreshTokenExp();
            if(Date.now() > new Date(Number.parseInt(refreshTokenExp))) {
                safeLogout("Your session has expired. Please sign in again.");
                return false;
            }
            return true;
        }
        return false;
    }
    return (
        <Route {...rest} render={
                props => {
                    return isAuthorized()
                    ? <Component {...props} /> : <Redirect to="/login" />
                }
        }/>
    );
}

const mapStateToProps = ()  => createStructuredSelector({
    authenticated: selectUser()
});

export default connect(mapStateToProps)(AuthorizedRoute);