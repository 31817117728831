const errorHandler = error => {
    if (error.response && error.response.data.errorOptions) {
        return error.response.data.errorOptions;
    } else if (error.response && error.response.status >= 500) {
        return 'The server is having issues. Please try again later.';
    } else if (error.response && error.response.data.title) {
        return error.response.data.title;
    } else {
        return 'The server is having issues. Please try again later.';
    }
}

export default errorHandler;