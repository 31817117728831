import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HasAccess from '../../components/HasAccess/HasAccess';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LldpListTable from '../../containers/LldpListTable/lldpListTable';

import { actions as LldpActions, makeSelectLldpList, makeSelectLldpListLoading } from '../../reducers/networkDiscovery';

import { types as lldpTypes } from '../../reducers/networkDiscovery';
import { actions as AppActions } from '../../reducers/app';

function LldpList(props) {
    const [page, setPage] = useState(0);
    
    function modalHandleOpen(){
        props.setCurrentModal(lldpTypes.ADD_LLDP);
    }

        return (
            <div>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <HasAccess
                        permissions={["ADD_NETWORK_DISCOVERY"]}
                    >
                        <Button className="button" id="btnAddBuilding" variant="contained" color="primary" onClick={modalHandleOpen}>
                            Add Chassis Mapping
                        </Button> 
                    </HasAccess>
                </Grid>
                <br />
                <LldpListTable lldpList={props.lldpList} setCurrentModal={props.setCurrentModal} loading={props.loading} page={page} setPage={setPage}/>
            </div>    
        );
}

LldpList.propTypes = {
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    lldpList: makeSelectLldpList(),
    loading: makeSelectLldpListLoading()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchLldpList: (page, pageSize) => dispatch(LldpActions.getLldpRequest(page, pageSize)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LldpList);
