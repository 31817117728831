import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import ReportsAPI from '../apis/reportsApi';
import { userCompanyId } from '../reducers/auth';
import { actions as AppActions } from '../reducers/app';
import { actions as ScheduledReportsActions,
		 types as ScheduledReportsTypes } from '../reducers/scheduledReports';
import pageErrorHandler from '../utils/pageErrorHandler';
import errorHandler from '../utils/errorHandler';

function* scheduledReport(action) {
	try {
		yield put(AppActions.setModalLoading());
		yield call(ReportsAPI.scheduleReport, action.data);
		yield put(AppActions.setModalClose());
	} catch (error) {
		console.log(error);
		let options = {};

		// first check field line errors
		if(error.response.data.type === "http://cirrus.redskytech.com/errors/location/badRequest") {
			options.backendFieldError = {
			 name: 'The scheduled report name provided already exists.'
			};
		  }

		// will set the red error box message
		if(!options.backendFieldError) {
			options.displayTopErrorMessage = undefined;
			options.topErrorMessage = error.response.data.title;
		}

		error.response.data.errorOptions = options;
		yield put(AppActions.setModalErrorOptions(errorHandler(error)));
	}
}

function* rootSaga() {
	yield all([
		yield takeLatest(ScheduledReportsTypes.SCHEDULED_REPORT, scheduledReport)
	]);
}

export default rootSaga;
