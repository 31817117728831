import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as CERServersActions } from '../../../reducers/organizationManagement';
import ModalWrapper from '../../../components/ModalWrapper';

const DeleteCERServerModal = props => {
	const { modalLoading, modalData } = props;

	return(
		<div>
			{modalLoading && <LinearProgress/>}
			<Grid container 
				direction="column" 
				justify="center"
				alignItems="center"
				spacing={16}>
				<Grid item >
					<p>Please confirm that you would like to delete the following CER Server IP Address:</p>
					<b style={{ textAlign: 'center', display: 'block' }}>{modalData.ipAddress}</b>
				</Grid>
				<Grid item>
					<Button variant="contained" 
							color="secondary" 
							onClick={() => props.submitDeleteCERServer(props.modalData.id)}>
						DELETE
					</Button>
				</Grid>
			</Grid>
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		submitDeleteCERServer: (id) => dispatch(CERServersActions.deleteCERServersRequest(id))
	}
}

export default compose(
	connect(null, mapDispatchToProps),
	ModalWrapper
)(DeleteCERServerModal);
