import React, { useState, useEffect, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HasAccess from '../../components/HasAccess/HasAccess';


import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';

import reducer from '../../reducers/apiAccessKey';
import saga from '../../sagas/apiAccessKey';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { types as ApiAccessKeyTypes, actions as apiAccessKeyActions, makeSelectApiAccessKeyList } from '../../reducers/apiAccessKey';
import { actions as AppActions, selectPageSize } from '../../reducers/app';

import {
    selectUserRole,
    selectUserCompany,
    currentCompanyOrgType,
    selectOriginalLoginCompany,
    userCompanyId
} from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import { debounce } from 'lodash';
import ApiAccessKeyTable from '../APIAccessKeyTable/apiAccessKeyTable';

const initialSortColumns = [
    {
        id: 'description',
        desc:false
    }
]

function APIAccessPage(props) {
    const [searchValue, setSearchValue] = useState('');
    const [searchData, setSearchData] = useState(false);
    const [page, setPage] = useState(0); 
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    useEffect(() => {
        props.setPageTitle('API Access Keys');
        props.setPageTitleId('api-access-header');
        
        return () => {
            // clean up global state
            props.setSearchTerm('');
        }
    }, []);

    function handleInputSearchChange(event) {
        const inputValue = event.target.value;
        const isMatch = searchFieldRegex(inputValue);
        if(isMatch) {
            setSearchValue(inputValue);
            searchApiCall(
                inputValue, 
                props.pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC'
            );
        }
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, direction) => {
            props.setSearchTerm(inputValue);
            if (inputValue.length > 0) {
                setPage(0);
                props.fetchApiAccessKeyList(props.companyId, 1, pageSize, inputValue, sortBy, direction)
            } else if (inputValue.length === 0) {
                setPage(0);
                props.fetchApiAccessKeyList(props.companyId, 1, pageSize, "", sortBy, direction)
            }
        }, 500)
    , []);

    function modalHandleOpen() {
        props.setCurrentModal(ApiAccessKeyTypes.ADD_API_ACCESS_KEY);
    }

    function displayAPIAccessList() {
        return <ApiAccessKeyTable 
                    setCurrentModal={props.setCurrentModal} 
                    fetchApiAccessKeyList={props.fetchApiAccessKeyList}
                    searchData={searchData}
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    pageSize={props.pageSize}
                    page={page}
                    setPage={setPage}
                    sortColumns={sortColumns}
                    setSortColumns={setSortColumns}
                    currentSortColumn={currentSortColumn}
                    setCurrentSortColumn={setCurrentSortColumn} 
                    />
    }
    
    return (
        <div>
            <Grid container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item style={{ marginRight: 'auto' }}>
                    {
                        <SearchField 
                            value={searchValue}
                            onChange={handleInputSearchChange}
                        />
                    }
                </Grid>
                <Grid item>
                    {   searchValue.length === 0 &&
                        //<HasAccess permissions={[]}> // Permissions logic needs to be added once finished
                        <Button className="button" id="generate-api-key-button" variant="contained" color="primary" onClick={modalHandleOpen}>Generate API Key</Button>
                        //</HasAccess>
                    }
                </Grid>
            </Grid>
            <br/>
            { displayAPIAccessList() }
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchApiAccessKeyList: (companyId, page, pageSize, searchTerm, sortBy, sortDirection) => 
            dispatch(apiAccessKeyActions.getApiAccessKeyRequest(companyId, page, pageSize, searchTerm, sortBy, sortDirection)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data)),
        setSearchTerm: searchTerm => dispatch(AppActions.setSearchTerm(searchTerm))
    }
};

const mapStateToProps = () => createStructuredSelector({
    companyId: userCompanyId(),
    company: selectUserCompany(),
    userRole: selectUserRole(),
    apiAccessKeyList: makeSelectApiAccessKeyList(),
    pageSize: selectPageSize(),
    currentCompanyOrgType: currentCompanyOrgType(),
    userLoginCompany: selectOriginalLoginCompany()
});

const withReducer = injectReducer({ key: 'apiAccessKey', reducer });
const withSaga = injectSaga({ key: 'apiAccessKey', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(APIAccessPage);