import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from "yup";

import ModalErrorMessage from '../../components/ModalWrapper/ModalErrorMessage';
import { Link } from 'react-router-dom';
import history from '../../utils/history';

import LoginAPI from '../../apis/loginApi';
import { forgotPasswordErrorMessageTypes } from '../../apis/api';

import SecurityCodeSuccessModal from './modals/SecurityCodeSuccessModal';
import EmailNotFoundModal from './modals/EmailNotFoundModal';

function VerificationForm(props) {
	const {
		classes, 
		emailSubmitted
	} = props;

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [openEmailNotFound, setEmailNotFoundModal] = useState(false);

	function clickLink(e) {
        e.preventDefault();
        LoginAPI.forgotEndpoint({email: emailSubmitted}).then(response => {
			setOpenSuccessModal(true);
		}, err => {
			// TODO
		});
    }

    return (
		<div>
			<p className={classes.subtitle}>
				Please enter the one-time security code that was sent to <b>{props.emailSubmitted}</b> in order to set your new password.
			</p>
			<Formik
				initialValues={{ 
					securityCode: '',
					password: '',
					confirmPassword: ''
				}}
				validate={(values) => {
					let validationSchema = Yup.object({
						securityCode: Yup.string()
								.required('Security Code is a required field.'),
						password: Yup.string("Enter password")
							.trim()
							.min(16, "Password must be at least 16 characters.")
							.max(64, "Password must be at most 64 characters.")
							.required('Password is a required field.')
							,
						confirmPassword: Yup.string("Confirm password").trim().oneOf([values.password], "Password and confirmation are not the same.")})
					try {
						validateYupSchema(values, validationSchema, true, {});
					} catch (err) {
						return yupToFormErrors(err);
					}
					return {}
				}}
				onSubmit = {
					(values) => {
						setLoading(true);
						LoginAPI.resetEndpoint({ 
							securityCode: values.securityCode,
							email: emailSubmitted,
							password: values.password
						}).then(
							response => {
								history.push('/');
							}, 
							err => {
								console.log('error', err.response);
								if(err.data && err.data.type && err.data.type === 'http://cirrus.redskytech.com/errors/user/notFound') {
									// set specific user not found error modal
									setEmailNotFoundModal(true);
								}
								else {
									setErrorMessage(err.data && err.data.type ? err.data : err.response.data);
								}
								setLoading(false);
							}
						);
					}
				}
				render={formikProps => {
					const { values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
					} = formikProps;

					return (
						<form onSubmit={handleSubmit}  noValidate="novalidate">
							{errorMessage && errorMessage.type !== 'http://cirrus.redskytech.com/errors/securityCode/invalid' && <ModalErrorMessage maxSize="50" modalErrorMessage={errorMessage.title}/>}
							<TextField
								id="securityCode"
								label="Security Code"
								name="securityCode"
								onChange={handleChange}
								value={values.securityCode}
								onBlur={handleBlur}
								fullWidth={true}
								autoComplete="off"
								error={
									(touched.securityCode && Boolean(errors.securityCode)) || 
									(errors.securityCode && Boolean(errors.securityCode.includes('max'))) || 
									(errorMessage && errorMessage.type == 'http://cirrus.redskytech.com/errors/securityCode/invalid')
								}/> 
							<span id="securityCode-helper-text" className="error-prompt">
								{(touched.securityCode && Boolean(errors.securityCode)) || (errors.securityCode && Boolean(errors.securityCode.includes('max'))) ? errors.securityCode : ''}
							</span>
							{ errorMessage && errorMessage.type === 'http://cirrus.redskytech.com/errors/securityCode/invalid' && 
								<span id="email-helper-text" style = {{
									color: 'red',
									display: 'block',
									fontSize: '12px',
									marginTop: '3px'
								  }}
								  >
									Invalid security code
								</span>
							}

							<TextField
								id="password"
								label="Password"
								name="password"
								type="password"
								onChange={handleChange}
								value={values.password}
								onBlur={handleBlur}
								fullWidth={true}
								autoComplete="off"
								error={
									(touched.password && Boolean(errors.password)) || 
									(errors.password && Boolean(errors.password.includes('max')))
								}
								disabled={values.securityCode.length == 0}
								className={classes.passwordField}/> 
								<span id="password-helper-text" className="error-prompt">{(touched.password && Boolean(errors.password)) || (errors.password && Boolean(errors.password.includes('max'))) ? errors.password : ''}</span>
							<br/>

							<TextField
								id="confirmPassword"
								label="Confirm Password"
								name="confirmPassword"
								type="password"
								autoComplete="off"
								onChange={handleChange}
								value={values.confirmPassword}
								onBlur={handleBlur}
								fullWidth={true}
								disabled={values.securityCode.length == 0}
								error={(touched.confirmPassword && Boolean(errors.confirmPassword)) || (errors.confirmPassword && Boolean(errors.confirmPassword.includes('max')))}/> 
							<span id="confirm-password-helper-text" className="error-prompt">
								{(touched.confirmPassword && Boolean(errors.confirmPassword)) || (errors.confirmPassword && Boolean(errors.confirmPassword.includes('max'))) ? errors.confirmPassword : ''}
							</span>

							
							{ values.securityCode.length > 0 && values.password.length > 0 && values.securityCode.length > 0 && values.confirmPassword.length > 0 &&
								<div className={classes.wrapper}>
									<Button
										id="sign-in-submit-button"
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
										disabled={loading}>
										Submit
									</Button>
									{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
								</div>
							}
							<br/>
							<div className={classes.subtitleLeft}>Didn't receive the security code? Check your Junk/Spam folder, or contact your administrator.</div>
							<div className={classes.subtitleLeft}>Still need another code? Click <Link to="/forgot" onClick={clickLink}>here.</Link> </div>
							<SecurityCodeSuccessModal openSuccessModal={openSuccessModal} setOpenSuccessModal={setOpenSuccessModal}/>
							<EmailNotFoundModal openEmailNotFound={openEmailNotFound} setEmailNotFoundModal={setEmailNotFoundModal}/>
						</form>
					);
				}}
			/>
		</div>
    );
}

function EmailForm(props) {
	const {
		classes, 
		setEmailSubmittedSuccess,
		setEmailSubmitted,
		emailValue
	} = props;

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	
    return (
		<div>
			<p className={classes.subtitle}>
				Welcome to Horizon Mobility<sup>&trade;</sup> Portal<br/>
				Please enter your email address to get started.
			</p>
			<Formik
				initialValues={{ 
					email: emailValue
				}}
				validationSchema={Yup.object({
					email: Yup.string()
							.email('Please enter a valid email address.')
							.max(100, "Email has a max limit of 100 characters.")
					})
				}
				onSubmit = {
					(values) => {
						setLoading(true);
						LoginAPI.forgotEndpoint({email: values.email}).then(response => {
							setEmailSubmittedSuccess(true);
							setEmailSubmitted(values.email);
							setLoading(false);
						}, err => {
							console.log('error', err);
							setErrorMessage(err.data);
							setLoading(false);
						});
						
					}
				}
				render={formikProps => {
					const { values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
					} = formikProps;

					return (
						<form onSubmit={handleSubmit} style={{width: '100%'}} noValidate="novalidate">
							{errorMessage && errorMessage.type != 'http://cirrus.redskytech.com/errors/user/notFound'  && <ModalErrorMessage modalErrorMessage={errorMessage.title}/>}
							<TextField
								id="email"
								label="Email"
								name="email"
								onChange={handleChange}
								value={values.email}
								onBlur={handleBlur}
								fullWidth={true}
								autoComplete="email"
								error={(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max')) || (errorMessage && forgotPasswordErrorMessageTypes.includes(errorMessage.type)))}/> 
							<span id="email-helper-text" className="error-prompt">
								{(touched.email && Boolean(errors.email)) || (errors.email && Boolean(errors.email.includes('max'))) ? errors.email : ''}
							</span>
							{ errorMessage && errorMessage.type === 'http://cirrus.redskytech.com/errors/user/notFound' && 
								<span id="email-helper-text" className="error-prompt">
									Email could not be found.
								</span>
							}
							
							{ values.email.length > 0 && 
								<div className={classes.wrapper}>
									<Button
										id="sign-in-submit-button"
										type="submit"
										fullWidth
										variant="contained"
										color="primary"

										className={classes.submit}
										disabled={loading}>
									Next
									</Button>
									{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
								</div>
							}
						</form>
					);
				}}
			/>
		</div>
    );
}

export default function ForgotPassword(props) {
    const [emailSubmittedSuccess, setEmailSubmittedSuccess] = useState(false);
	const [emailSubmitted, setEmailSubmitted] = useState(null);
    
    if(emailSubmittedSuccess) {
		return <VerificationForm setCurrentModal={props.setCurrentModal} classes={props.classes} setEmailSubmittedSuccess={setEmailSubmittedSuccess} emailSubmitted={emailSubmitted}/>
	}
	else {
		return <EmailForm classes={props.classes} setEmailSubmittedSuccess={setEmailSubmittedSuccess} setEmailSubmitted={setEmailSubmitted} emailValue={props.emailValue}/>
	}
}