import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectEventsList, makeSelectEventsListLoading } from '../../reducers/events';
import EventsListTable from '../../containers/EventsListTable/eventsListTable';

export class EventsList extends Component {
    render() {
        return <EventsListTable events={this.props.events} loading={this.props.loading}/>
    }
}

const mapStateToProps = createStructuredSelector({
    events: makeSelectEventsList(),
    loading: makeSelectEventsListLoading(),
});

export default connect(mapStateToProps)(EventsList);
