import React, { useState } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import CompanyFeaturesApi from '../../../apis/companyFeaturesApi';
import { createStructuredSelector } from "reselect";
import { selectUserCompany, currentCompanyLevel } from '../../../reducers/auth';
import { actions as OrganizationManagementActions } from '../../../reducers/organizationManagement';
import { types as AppTypes, selectBypassMSTeamsManualLocationVerificationEnabled } from '../../../reducers/app';
import { connect } from "react-redux";
import Switch from '@material-ui/core/Switch';
import Info from '@material-ui/icons/Info';
import { Tooltip } from "@material-ui/core";

import Grid from '@material-ui/core/Grid';

function BypassMSTeamsManualLocationVerification(props) {
    
    const [submitStatus, setSubmitStatus] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const company = props.company.toJS();

    const handleChange = () => {
        setDataLoading(true);
        (props.bypassMSTeamsManualLocationVerificationEnabled ? 
            CompanyFeaturesApi.removeCompanyFeatures(company.id, 'ALLOW_PIDFLO_METHOD_MANUAL') : 
            CompanyFeaturesApi.addCompanyFeatures(company.id, 'ALLOW_PIDFLO_METHOD_MANUAL')
        ).then(response => {
            setSubmitStatus('success');
            setDataLoading(false);
            props.getOrganizationFeatures();
        }).catch(error => {
            console.log(error)
            setSubmitStatus('failure');
            setDataLoading(false);
        });
    }

    return (
         <div>
            {dataLoading && <LinearProgress/>}
            <Grid container justify="center" direction="row" alignItems="center" style={{margin: '20px'}}>
                {dataLoading && <LinearProgress/>}
                <Tooltip 
                        id="ms-teams-calls-routing-tooltip"
                        title="Control whether MS Teams calls, which have a PIDF-LO Method of 'Manual', will be routed to a PSAP or an ERC."
                        placement="left">
                        <span style={{ color: 'black', padding: 15 }} tabIndex={props.disableInput ? 0 : -1}><Info /></span>
                </Tooltip>
                <Grid item xs={12} sm={3}>
                    <label htmlFor="BypassMSTeamsManualLocationVerificationSwitch">Allow MS Teams Manual Locations</label>
                </Grid>
                <Grid item xs={12} sm={1}>
                    <Switch
                        id="BypassMSTeamsManualLocationVerificationSwitch"
                        name="BypassMSTeamsManualLocationVerificationSwitch"
                        color="primary"
                        checked={props.bypassMSTeamsManualLocationVerificationEnabled}
                        onChange={handleChange}
                        disabled={dataLoading || !props.disableInput}/>
                </Grid>
            </Grid>
            {submitStatus === 'failure' && 
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 680 }}>
                <ErrorOutlineIcon style={{ color: '#f4473a', marginRight: 10, fontSize: 30 }}/>
                <div style={{ color: '#f4473a' }}>ERROR! We are not able to save your preference at this time. Please try again later.</div>
            </div>
            }
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    company: selectUserCompany(),
    bypassMSTeamsManualLocationVerificationEnabled: selectBypassMSTeamsManualLocationVerificationEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationFeatures: () => dispatch(OrganizationManagementActions.getOrganizationFeatures())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BypassMSTeamsManualLocationVerification);