import { axiosInstance } from './api';
/* Cirrus Admin-Service /pidfloDevices Controller */
const PIDFLO_DEVICES_ENDPOINT = 'admin-service/pidfloDevice';

export default class PidfloDevicesAPI {
    static addPidfloDevices(pidfloDevices, companyId) {
        const payload = {
            phoneNumber: pidfloDevices.phoneNumber,
            orgId: companyId,
            description: pidfloDevices.description
        };
        return axiosInstance.post(PIDFLO_DEVICES_ENDPOINT, payload);
    }
    
    static editPidfloDevices(pidfloDevices, companyId) {
        const payload = {
            id: pidfloDevices.id,
            phoneNumber: pidfloDevices.phoneNumber,
            orgId: companyId,
            description: pidfloDevices.description
        };
        return axiosInstance.put(PIDFLO_DEVICES_ENDPOINT, payload);
    }

    static getPidfloDevicesById(companyId, page, pageSize, sortBy, direction, searchTerm) {
        return axiosInstance.get(`${PIDFLO_DEVICES_ENDPOINT}/org/${companyId}`, {
            params: {
                page, 
                pageSize,
                sortBy,
                direction,
                searchTerm
            }
        });
    }
    
    static deletePidfloDevices(pidfloDevicesId, orgId) {
        return axiosInstance.delete(`${PIDFLO_DEVICES_ENDPOINT}/${pidfloDevicesId}?orgId=${orgId}`);
    }
}