function convertReportType(reportType) {
    if (reportType === 'CALL_HISTORY') return 'Emergency Call Details';
    else if (reportType === 'CALL_SUMMARY') return 'Emergency Call Totals';
    else if (reportType === 'ECRC_CALL_HISTORY') return 'ERC Call Details';
    else if (reportType === 'DEVICE_USERS') return 'Device Users';
    else if (reportType === 'DEVICE_USERS_DEVICES') return 'Device Users Devices';
    else if (reportType === 'HELD_DEVICE') return 'HELD Devices';
    else if (reportType === 'BUILDING') return 'Building';
    else if (reportType === 'LOCATION') return 'E911 Location';
    else if (reportType === 'BSSID') return 'BSSID';
    else if (reportType === 'LLDP_CHASSIS') return 'LLDP Chassis';
    else if (reportType === 'LLDP_PORT') return 'LLDP Port';
    else if (reportType === 'MAC_ADDRESS') return 'MAC Address';
    else if (reportType === 'IP_RANGE') return 'Private IP Ranges';
    else if (reportType === 'HELD_DEVICES_NO_LOCATION') return 'HELD Devices without a Location';
    else if (reportType === 'MYE911_USERS_NO_LOCATION') return 'MyE911 Users without a Location';
    else if (reportType === 'NETWORK_ELEMENTS_NO_LOCATION') return 'Network Elements without a Location';
    else if (reportType === 'LOCATION_LICENSES') return 'Location Licensing';
    else if (reportType === 'USER_LICENSES') return 'User Licensing';
    else if (reportType === 'ENHANCED_NOTIFICATION_LICENSES') return 'Enhanced Notification Licensing';
    else if (reportType === 'HELD_PLUS_DEVICES_NO_LOCATION') return 'HELD+ Devices without a Location';
    else if (reportType === 'CER_LOCATION') return 'CER Location';
    else if (reportType === 'PIDF_LO') return 'PIDF-LO Devices';
    else if (reportType === 'EXPIRING_LICENSES') return 'Expiring Licenses';
    else return '';
}

export default convertReportType;