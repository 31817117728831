import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types as AppTypes, selectHorizonPrimeEnabled } from '../../../reducers/app';
import { currentCompanyLevel } from '../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import ModalWrapper from '../../../components/ModalWrapper';
import moment from 'moment';
import './DetailsCallHistory.css';
import NumberFormatter from '../../../components/NumberFormatter/NumberFormatter';
import { selectAlternateIdEnabled, selectLocationAlternateIdEnabled } from '../../../reducers/app';
 
class DetailsCallHistoryModal extends Component {
   formatLastAccess(callTime) {
       return callTime ?  moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
   }

   getFormattedDestination(destination, includeTestCheck = false) {
    if (!destination || typeof destination !== 'string') {
      return '';
    }
  
    const upperDestination = destination.toUpperCase();
  
    if (upperDestination === 'HORIZONPRIME') {
      return 'Horizon Prime';
    }
  
    if (includeTestCheck && upperDestination.includes('TEST')) {
      return destination;
    }
  
    return includeTestCheck ? 'Standard' : destination;
  }

  getCustomCallDataFormatted(customCallData) {
    if (!customCallData || typeof customCallData !== 'string') {
        return '';
    }

    try {
        const customCallDataObj = JSON.parse(customCallData);
        if (typeof customCallDataObj !== 'object' || customCallDataObj === null) {
            return customCallData;
        }

        const formattedData = Object.entries(customCallDataObj)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
        return formattedData;
    } catch (error) {
        return customCallData;
    }
}


   
   render() {
       const {
           callTime,
           companyName,
           heldUserId,
           email,
           phoneNumber,
           heldDeviceIdentifier,
           address,
           city,
           state,
           zip,
           longitude,
           latitude,
           buildingName,
           locationName,
           locationInfo,
           supplementalData,
           intendedDestination,
           actualDestination,
           callerDisplayName,
           source,
           callingParty,
           calledParty,
           reason,
           deviceUserId,
           callbackNumber,
           orgNameOverride,
           locationAlternateId,
           customCallData
       } = this.props.modalData;

       return(
           <div>
               {
                   this.props.currentCompanyLevel === AppTypes.CURRENT_COMPANY_LEVEL_SYSTEM ?
                       <ul className="details-list">
                           <li><b>Call Time: </b>{this.formatLastAccess(callTime)}</li>
                           <li><b>Organization Name: </b>{companyName}</li>
                           <li><b>HELD+ User ID: </b>{heldUserId}</li>
                           <li><b>Email: </b>{email}</li>
                           <li><b>Address Line 1: </b>{address}</li>
                           <li><b>City: </b>{city}</li>
                           <li><b>State/Province: </b>{state}</li>
                           <li><b>Zip/Postal: </b>{zip}</li>
                           <li><b>Latitude: </b>{latitude}</li>
                           <li><b>Longitude: </b>{longitude}</li>
                           <li><b>Building Name: </b>{buildingName}</li>
                           <li><b>Location Name: </b>{locationName}</li>
                           <li><b>Location Info: </b>{locationInfo}</li>
                           <li><b>Supplemental Info: </b>{supplementalData}</li>
                           <li><b>Organization Name Override: </b>{orgNameOverride}</li>
                           <li><b>Intended Destination: </b>{this.getFormattedDestination(intendedDestination)}</li>
                           <li><b>Actual Destination: </b>{this.getFormattedDestination(actualDestination)}</li>
                           <li><b>Caller Display Name: </b>{callerDisplayName}</li>
                           <li><b>Call Source: </b>{source}</li>
                           <li><b>ELIN: </b>{!isNaN(phoneNumber) ? <NumberFormatter number={phoneNumber} /> : phoneNumber}</li>
                           <li><b>Device User ID: </b>{deviceUserId}</li>
                           <li><b>Alternate ID: </b>{locationAlternateId}</li>
                           <li><b>Calling Party: </b>{!isNaN(callingParty) ? <NumberFormatter number={callingParty} /> : callingParty}</li>
                           <li><b>Callback Number: </b>{!isNaN(callbackNumber) ? <NumberFormatter number={callbackNumber} /> : callbackNumber}</li>                           
                           <li><b>Called Party: </b>{!isNaN(calledParty) ? <NumberFormatter number={calledParty} /> : calledParty}</li>
                           <li><b>Reason: </b>{reason}</li>
                           <li><b>HELD Device Identifier: </b>{heldDeviceIdentifier}</li>
                           <li><b>Custom Call Data: </b>{this.getCustomCallDataFormatted(customCallData)}</li>
                       </ul>
                   :
                   <ul className="details-list">
                       <li><b>Call Time: </b>{this.formatLastAccess(callTime)}</li>
                       <li><b>Organization Name: </b>{companyName}</li>
                       <li><b>HELD+ User ID: </b>{heldUserId}</li>
                       <li><b>Email: </b>{email}</li>
                       <li><b>Address Line 1: </b>{address}</li>
                       <li><b>City: </b>{city}</li>
                       <li><b>State/Province: </b>{state}</li>
                       <li><b>Zip/Postal: </b>{zip}</li>
                       <li><b>Latitude: </b>{latitude}</li>
                       <li><b>Longitude: </b>{longitude}</li>
                       <li><b>Building Name: </b>{buildingName}</li>
                       <li><b>Location Name: </b>{locationName}</li>
                       <li><b>Location Info: </b>{locationInfo}</li>
                       <li><b>Supplemental Info: </b>{supplementalData}</li>
                       <li><b>Organization Name Override: </b>{orgNameOverride}</li>
                       {this.props.horizonPrimeEnabled && 
                            <li><b>Intended Destination: </b>{this.getFormattedDestination(intendedDestination, true)}</li>
                       }
                       <li><b>Caller Display Name: </b>{callerDisplayName}</li>
                       <li><b>Call Source: </b>{source}</li>
                       <li><b>ELIN: </b>{!isNaN(phoneNumber) ? <NumberFormatter number={phoneNumber} /> : phoneNumber}</li>
                       {this.props.alternateDeviceUserIdEnabled ?  <li><b>Device User ID: </b>{deviceUserId}</li> : ''}
                       {this.props.locationAlternateIdEnabled ? <li><b>Alternate ID: </b>{locationAlternateId}</li> : ''}
                       <li><b>Calling Party: </b>{!isNaN(callingParty) ? <NumberFormatter number={callingParty} /> : callingParty}</li>
                       {this.props.alternateDeviceUserIdEnabled || this.props.locationAlternateIdEnabled ? <li><b>Callback Number: </b>{!isNaN(callbackNumber) ? <NumberFormatter number={callbackNumber} /> : callbackNumber} </li> : ''}
                       <li><b>Called Party: </b>{!isNaN(calledParty) ? <NumberFormatter number={calledParty} /> : calledParty}</li>
                       <li><b>Reason: </b>{reason}</li>
                       <li><b>HELD Device Identifier: </b>{heldDeviceIdentifier}</li>
                       <li><b>Custom Call Data: </b>{this.getCustomCallDataFormatted(customCallData)}</li>
                   </ul>
               }   
           </div>
       );
   }
}

const mapStateToProps = () => createStructuredSelector({
    currentCompanyLevel: currentCompanyLevel(),
    alternateDeviceUserIdEnabled: selectAlternateIdEnabled(),
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled(),
    horizonPrimeEnabled: selectHorizonPrimeEnabled()
});

export default compose(
    ModalWrapper,
    connect(mapStateToProps)
)(DetailsCallHistoryModal);