import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types, makeSelectAlertTemplatesList } from '../../reducers/alerts';
import { createStructuredSelector } from 'reselect';

class AlertsTemplatesEditMenu extends Component {
	options = [];
	
	ITEM_HEIGHT = 48;
	state = {
		anchorEl: null,
	};

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	buildActionMenu = () => {
		const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
		const isAdd = user && user.permissions.includes('ADD_ALERT_TEMPLATES');
		const isEdit = user && user.permissions.includes('EDIT_ALERT_TEMPLATES');
		const isDelete = user && user.permissions.includes('DELETE_ALERT_TEMPLATES');

		this.options = [];
		if(isAdd && (this.props.row.original.standard || (this.props.row.original.alertType.name !== 'MYE911_USER_WELCOME_EMAIL' && this.props.row.original.alertType.name !== 'ORG_ADMIN_WELCOME_EMAIL'))){
			this.options.push('Copy');
		}
		this.options.push('Preview');
		if((!this.props.row.original.standard) && (isEdit || isDelete)){
			this.options.push('Edit');
			this.options.push('Delete');
		}
	}

	handleMenuItemClick = (option) => {
		this.handleClose();
		switch (option) {
			case 'Copy':
				if((this.props.row.original.alertType.name === 'MYE911_USER_WELCOME_EMAIL' 
					&& this.props.alertTemplatesList.some(at => at.alertType.name === 'MYE911_USER_WELCOME_EMAIL' && !at.standard)) || (this.props.row.original.alertType.name === 'ORG_ADMIN_WELCOME_EMAIL' 
					&& this.props.alertTemplatesList.some(at => at.alertType.name === 'ORG_ADMIN_WELCOME_EMAIL' && !at.standard))
				){
					this.props.setCurrentModal(types.EMAIL_TEMPLATE_ERROR, this.props.row.original.alertType.text);
				} else{
					this.props.setCurrentModal(types.COPY_ALERT_TEMPLATE, this.props.row.original);
				}
				break;
			case 'Preview':
				this.props.setCurrentModal(types.PREVIEW_ALERT_TEMPLATE, this.props.row.original);
				break;
			case 'Edit':
				this.props.setCurrentModal(types.EDIT_ALERT_TEMPLATE, this.props.row.original);
				break;
			case 'Delete':
				this.props.setCurrentModal(types.DELETE_ALERT_TEMPLATE, this.props.row.original);
				break;
			default:
				return null;
		}
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		this.buildActionMenu();

		return (
			<div>
				<IconButton
					id={`alert-templates-action-menu-button-${this.props.row.index}`}
					aria-label="More"
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id={`alert-templates-action-menu-${this.props.row.index}`}
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
					PaperProps={{
						style: {
							maxHeight: this.ITEM_HEIGHT * 6.5,
							minWidth: 200,
						},
					}}
				>
					{this.options.map((option, index) => (
							<MenuItem id={`alert-templates-item-${this.props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => this.handleMenuItemClick(option, event)}>
								{option}
							</MenuItem>
						))
					}
				</Menu>
			</div>
		);
	}
}

AlertsTemplatesEditMenu.propTypes = {
	row: PropTypes.object,
	setCurrentModal: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
	alertTemplatesList: makeSelectAlertTemplatesList()
});


const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsTemplatesEditMenu);
