import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actions as EventsActions, makeSelectEventsList, makeSelectEventsListLoading, makeSelectEventsPageCount } from '../../../reducers/homepage';
import { selectUserCompany } from '../../../reducers/auth';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../../components/ReactTableLoadingComponent';
import moment from 'moment';

function EventsSection(props) {
    const appState = {
        startDate: moment().subtract(3, 'days').startOf('day'),
        endDate: moment(),
        focusedInput: null
    };
    const buildTableColumns = [
        {
            Header: <div id="recent-events-time">Time</div>,
            id: "time",
            Cell: row => <div title={formatLastAccess(row.original.eventTime)}>{formatLastAccess(row.original.eventTime)}</div>
        },
        {
            Header: <div id="recent-events-type">Event Type</div>,
            id: "type",
            Cell: row => <div title={row.original.eventType.name}>{row.original.eventType.name}</div>
        },
        {
            Header: <div id="recent-events-username">Username</div>,
            id: "username",
            Cell: row => <div title={row.original.userEmail}>{row.original.userEmail}</div>
        }
    ];

    function formatLastAccess(lastAccess) {
        return lastAccess ?  moment(lastAccess).format('MM/DD/YYYY hh:mm:ss A') : '';
    }

    useEffect(() => {
        fetchEventsList(1, 5, appState.startDate, appState.endDate);
    }, [props.company.toJS().id]);

    const {
        loading, events, fetchEventsList, eventsPageCount
    } = props;
    let eventsArray = [];

    if (events) {
        eventsArray = events.toArray();
    }

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <div>
            <h2><Link id="recent-events-header" to="/events">Recent Events</Link></h2>
            <div>
                <ReactTable
                    resizable={false}
                    sortable={false}
                    className="-striped -highlight"
                    showPaginationBottom={false}
                    data={eventsArray}
                    minRows={5}
                    pages={eventsPageCount}
                    columns={buildTableColumns}
                    defaultPageSize={5}
                    manual
                    loading = {loading}
                    onFetchData={(state, instance) => {
                        fetchEventsList(state.page+1, state.pageSize, appState.startDate, appState.endDate);
                    }}
                    LoadingComponent={ReactTableLoadingComponent}
                    getTrProps={
                        (state, rowInfo, column) => {
                            if(rowInfo) {
                                return {
                                    id: 'events-section-row-' + rowInfo.index,
                                };
                            } else {
                              return {};
                            }
                        }
                    }
                />
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    events: makeSelectEventsList(),
    loading: makeSelectEventsListLoading(),
    eventsPageCount: makeSelectEventsPageCount(),
    company: selectUserCompany()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchEventsList: (page, pageSize, startDate, endDate) => dispatch(EventsActions.getEventsRequest(page, pageSize, startDate, endDate))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsSection);