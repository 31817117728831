/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';

export const MANAGE_LICENSES = 'MANAGE_LICENSES/'; 
export const types = {
    GET_LICENSES_REQUEST: MANAGE_LICENSES + 'GET_LICENSES_REQUEST',
    GET_LICENSES_ERROR: MANAGE_LICENSES + 'GET_LICENSES_ERROR',
    GET_LICENSES_SUCCESS: MANAGE_LICENSES + 'GET_LICENSES_SUCCESS',
    ADD_LICENSE: MANAGE_LICENSES + 'ADD_LICENSE',
    GET_LICENSES_STATUS_REQUEST: MANAGE_LICENSES + 'GET_LICENSES_STATUS_REQUEST',
    GET_LICENSES_STATUS_ERROR: MANAGE_LICENSES + 'GET_LICENSES_STATUS_ERROR',
    GET_LICENSES_STATUS_SUCCESS: MANAGE_LICENSES + 'GET_LICENSES_STATUS_SUCCESS',
    DELETE_LICENSE: MANAGE_LICENSES + 'DELETE_LICENSE',
    SET_COMPANY_ID: MANAGE_LICENSES + 'SET_COMPANY_ID',
    GET_MANAGING_COMPANY: MANAGE_LICENSES + 'GET_MANAGING_COMPANY'
};

export const initialState = fromJS({
    licensesList: List(),
    licensesLoading: false,
    licensesStats: {
       myE911Used: 0,
       myE911Total: 0,
       elinUsed: 0,
       elinTotal: 0 
    },
    managingCompany: Map()
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_LICENSES_REQUEST:
            return state.set('licensesLoading', true);
        case types.GET_LICENSES_ERROR:
            return state.set('licensesLoading', false);
        case types.GET_LICENSES_SUCCESS:
            return state.set('licensesLoading', false)
                        .set('licensesList', List(action.data))
                        .set('manageLicensesPageCount', Number(action.pageCount));
        case types.GET_LICENSES_STATUS_SUCCESS:
            return state.set('licensesStats', fromJS(action.data));
        case types.GET_MANAGING_COMPANY:
            return state.set('managingCompany', fromJS(action.data));
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    getLicensesRequest: (companyId, page, pageSize) => ({
        type: types.GET_LICENSES_REQUEST,
        companyId,
        page, 
        pageSize
    }),
    getLicensesError: () => ({
        type: types.GET_LICENSES_ERROR,
    }),
    getLicensesSuccess: (licensesList, pageCount) => ({
        type: types.GET_LICENSES_SUCCESS,
        data: licensesList,
        pageCount
    }),
    addLicenseRequest: (license) => ({ 
        type: types.ADD_LICENSE, 
        data: license
    }),
    getManagingCompany: (company) => ({ 
        type: types.GET_MANAGING_COMPANY, 
        data: company
    }),
    getLicenseStatusRequest: (companyId) => ({
        type: types.GET_LICENSES_STATUS_REQUEST,
        companyId
    }),
    getLicenseStatusError: () => ({
        type: types.GET_LICENSES_STATUS_ERROR
    }),
    getLicenseStatusSuccess: (licenseStats) => ({
        type: types.GET_LICENSES_STATUS_SUCCESS,
        data: licenseStats
    }),
    deleteLicenseRequest: (id, companyId) => ({
        type: types.DELETE_LICENSE,
        data: id,
        companyId,
    })
};

// Selectors
const selectLicenses = state => state.get('manageLicenses', initialState);

export const selectLicensesList = () =>
    createSelector(selectLicenses, licensesState => licensesState.get('licensesList'));

export const selectLicensesLoading = () =>
    createSelector(selectLicenses, licensesState => licensesState.get('licensesLoading'));

export const selectLicenseStats = () =>
    createSelector(selectLicenses, licensesState => licensesState.get('licensesStats'));

export const selectManagingCompany = () =>
    createSelector(selectLicenses, licensesState => licensesState.get('managingCompany'));

export const makeSelectManageLicensesPageCount = () =>
createSelector(selectLicenses, licensesState => licensesState.get('manageLicensesPageCount'));