const handleCopyToClipboard = (value, setSnackbarOpen, setCopyToClipboardFailed) => {
    // This applies to Internet Explorer or older browsers
    if (!navigator.clipboard){
        setTimeout(() => {
            // wait 0.5 seconds for action menu to close 
            const textField = document.createElement('textarea');
            textField.innerText = value;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            setSnackbarOpen(true);
            setCopyToClipboardFailed(false);
        }, 500);
    } else{
        navigator.clipboard.writeText(value).then(() => {
            setSnackbarOpen(true);
            setCopyToClipboardFailed(false);
        }).catch(() => {
            setSnackbarOpen(true);
            setCopyToClipboardFailed(true);
        });
    }    

};

export default handleCopyToClipboard;