import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import NotificationIntegrationsEditMenu from '../../containers/NotificationIntegrationsEditMenu/notificationIntegrationsEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import { createStructuredSelector } from "reselect";

import { connect } from "react-redux";
import {
  makeSelectNotificationIntegrationsList,
  makeSelectNotificationIntegrationsListLoading
} from '../../reducers/notificationIntegrations';

const initialSortColumns = [
  {
      id: 'name',
      desc:false
  }
]

const NotificationIntegrationsTable = props => {
  const [sortColumns, setSortColumns] = useState(initialSortColumns);
  const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

  const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
  const isEdit = user && user.permissions.includes('EDIT_NOTIFICATION_INTEGRATIONS');
  const isDelete = user && user.permissions.includes('DELETE_NOTIFICATION_INTEGRATIONS');

  const notificationIntegrationsTableColumns = [
    {
      Header: <div id="notification-integrations-name">InformaCast 911 Alert Entity Name</div>,
      id: "name",
      sortable: true,
      Cell: row => <div title={row.original.name}>{row.original.name}</div>,
    },
    {
      Header: <div id="notification-integrations-first-name">InformaCast Distribution List</div>,
      id: "first-name",
      Cell: row => (
        row.original.distributionLists.map((item, index) => <span key={index} title={item.name}>{item.name}{row.original.distributionLists.length-1 === index ? '' : ', '}</span>
        )
      )
    },
    {
      Header: <div id="notification-integrations-template-name">InformaCast Template</div>,
      id: "template-name",
      Cell: row => <div title={row.original.templateName}>{row.original.templateName}</div>
    },
    {
      Header: "",
      width: 65,
      id: "edit-menu",
      Cell: row => (
        (isEdit || isDelete) ? <NotificationIntegrationsEditMenu row={row} setCurrentModal={props.setCurrentModal} notificationIntegrationsId={props.notificationIntegrationsId} /> : null
      )
    }
  ];

  const { loading, notificationIntegrations } = props;
  const notificationIntegrationsArray = notificationIntegrations.toArray();

  useEffect(() => {
    const tableBody = document.querySelector('.rt-tbody');
    const table = document.querySelector('.ReactTable');

    if (tableBody.scrollHeight > tableBody.clientHeight) {
        table.classList.add('scrollable');
    } else {
        table.classList.remove('scrollable');
    }
}, [loading]);

  return (
    <ReactTable
      resizable={false}
      sortable={false}
      className="-striped -highlight"
      showPaginationBottom={false}
      data={notificationIntegrationsArray}
      minRows={notificationIntegrationsArray && notificationIntegrationsArray.length > 0 ? 0 : 5}
      pages={1}
      defaultPageSize={25}
      columns={notificationIntegrationsTableColumns}
      loading={loading}
      LoadingComponent={ReactTableLoadingComponent}
      sortColumns={sortColumns}
      setSortColumns={setSortColumns}
      currentSortColumn={currentSortColumn}
      setCurrentSortColumn={setCurrentSortColumn} 
      sorted={sortColumns}
      onSortedChange={(newSorted, column, shiftKey) => {
          let sortedArray = [...sortColumns];
          let currentSortColumn;
          sortedArray.forEach( (columnObject, index) => {                    
              if(column.id === columnObject.id) {
                  // state needs updated object reference, doesn't detect nested fields
                  currentSortColumn = {...columnObject};
                  currentSortColumn.desc = !currentSortColumn.desc;
                  sortedArray[index] = currentSortColumn;
              }
              else {
                  let resetColumn = {...columnObject};
                  resetColumn.desc = false;
                  sortedArray[index] = resetColumn;
              }
              setCurrentSortColumn(currentSortColumn);
              setSortColumns(sortedArray);
          })
      }}
      getTrProps={
          (state, rowInfo, column) => {
            if (rowInfo){
            }
              if(rowInfo && (rowInfo.original.templateName === '[Unknown Template]' || rowInfo.original.distributionLists.some(e => e.name === '[Unknown Distribution List]'))) {
                      return {
                          id: 'notification-integration-row-' + rowInfo.index,
                          style: {
                              background: 'rgba(255, 70, 71, 0.45)',
                              opacity: '.8',
                              border: '1px solid red'
                          }
                      };
                  }
                  else {
                    return {
                                id: rowInfo ? 'mac-row-' + rowInfo.index : '',
                    };
                  }
              }
            }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  notificationIntegrations: makeSelectNotificationIntegrationsList(),
  loading: makeSelectNotificationIntegrationsListLoading(),
});

export default connect(mapStateToProps)(NotificationIntegrationsTable);
