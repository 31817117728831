import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import { connect } from 'react-redux';
import {
    actions as HeldDevicesActions,
    makeSelectHeldDevicesPageCount,
    makeSelectHeldDevicesList,
    makeSelectHeldDevicesListLoading
} from '../../reducers/heldDevices';
import { actions as AppActions } from '../../reducers/app';
import { createStructuredSelector } from 'reselect';
import HeldDevicesEditMenu from '../../containers/HeldDevicesEditMenu/heldDevicesEditMenu';
import HeldDevicesDetailBox from './heldDevicesDetailBox';

const HeldDevicesListTable = props => {
    const {
        loading, 
        heldDevices, 
        fetchHeldDevicesList, 
        heldDevicesPageCount, 
        setCurrentModal, 
        searchTerm, 
        sortColumns, 
        setSortColumns, 
        currentSortColumn, 
        setCurrentSortColumn
    } = props;

    const buildTableColumns = [
        {
            Header: "Device ID",
            id: 'device-id',
            Cell: row => <div title={row.original.deviceType === 'HELD_PLUS' ? row.original.identifier : row.original.mac}>{
                row.original.deviceType === 'HELD_PLUS' ? row.original.identifier : row.original.mac
                }</div>
        },
        {
            Header: "Location",
            id: 'location',
            sortable: true,
            Cell: row => row.original.erl && row.original.erl.address && row.original.erl.address.normalizedAddress && (
                <div title={`${row.original.erl.name} ${row.original.erl.address.normalizedAddress}`}>
                    {row.original.erl.name}
                    <br/>
                    {row.original.erl.address.normalizedAddress}
                </div>
            )

        },
        {
            Header: "Discovery Method",
            id: 'discovery-method',
            Cell: row => <div title={row.original.discoveryType}>{row.original.discoveryType}</div>
        },
        {
            Header: "Network Connectivity",
            id: 'network-connectivity',
            Cell: row => (
                <div title={`${row.original.mac ? 'MAC_ADDRESS: ' + row.original.mac : ''}${row.original.ipv4 ? '\n' + 'IP: ' + row.original.ipv4 : ''}${row.original.bssid ? '\n' + 'BSSID: ' + row.original.bssid : ''}${row.original.decodedChassisId ? '\n' + 'CHASSIS_ID: ' + row.original.decodedChassisId : ''}${row.original.decodedPortId ? '\n' + 'PORT_ID: ' + row.original.decodedPortId : ''}`}>
                    {row.original.mac ? 'MAC_ADDRESS: ' + row.original.mac : ''}{row.original.mac && <br/>}
                    {row.original.ipv4 ? 'IP: ' + row.original.ipv4 : ''}{row.original.ipv4 && <br/>}
                    {row.original.ipv6 ? 'IPv6: ' + row.original.ipv6 : ''}{row.original.ipv6 && <br/>}
                    {row.original.bssid ? 'BSSID: ' + row.original.bssid : ''}{row.original.bssid && <br/>}
                    {row.original.decodedChassisId ? 'CHASSIS_ID: ' + row.original.decodedChassisId : ''}{row.original.decodedChassisId && <br/>}
                    {row.original.decodedPortId ? 'PORT_ID: ' + row.original.decodedPortId : ''}
                </div>
            )
        },
        {
            Header: "Details",
            id: 'details',
            Cell: row => <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <HeldDevicesDetailBox row={row} />
            </div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                <HeldDevicesEditMenu row={row} setCurrentModal={setCurrentModal}
                currentSortColumn={props.currentSortColumn} />
            )
        }
    ];

    useEffect( () => {
        fetchHeldDevicesList(props.page+1, props.pageSize, searchTerm, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: props.page+1, 
            pageSize: props.pageSize, 
            sortBy: currentSortColumn.id, 
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, props.pageSize, props.page]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }
    
    let heldDevicesArray = [];

    if (heldDevices) {
        heldDevicesArray = heldDevices.toArray();
    }

    useEffect(() => {
        if (heldDevicesArray && heldDevicesArray.length === 0 && props.page > 0 && heldDevicesPageCount < props.page+1) {
            props.setPage(props.page-1)
        }
    }, [heldDevicesArray && heldDevicesArray.length === 0 && props.page > 0 && heldDevicesPageCount < props.page+1]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight"
            showPaginationBottom={true}
            data={heldDevicesArray}
            minRows={heldDevicesArray && heldDevicesArray.length > 0 ? 0 : 5}
            pages={heldDevicesPageCount <= 0 ? 1 : heldDevicesPageCount}
            page={props.page}
            columns={buildTableColumns}
            LoadingComponent={ReactTableLoadingComponent}
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
            }}
            defaultPageSize={25}
            manual
            onPageSizeChange={(pageSize) => {
                props.setPage(0);
                props.setPageSize(pageSize);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                props.setPage(pageIndex);
                resetScrollInsideTable();
            }}
            heldDevices={heldDevices}
            setCurrentModal={setCurrentModal}
            loading={loading}
            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo && !rowInfo.original.erl) {
                        return {
                            id: 'held-devices-row-' + rowInfo.index,
                            style: {
                                background: 'rgba(255, 70, 71, 0.45)',
                                opacity: '.8',
                                border: '1px solid red'
                            }
                        };
                    } else {
                        return {
                            id: rowInfo ? 'held-devices-row-' + rowInfo.index : '',
                        };
                    }
                }
            }
        />
    );
}

HeldDevicesListTable.propTypes = {
    heldDevices: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
    heldDevicesPageCount: makeSelectHeldDevicesPageCount(),
    heldDevices: makeSelectHeldDevicesList(),
    loading: makeSelectHeldDevicesListLoading()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchHeldDevicesList: (page, pageSize, searchTerm, deviceType, sortBy, sortDirection) => dispatch(HeldDevicesActions.getHeldDevicesRequest(page, pageSize, searchTerm, deviceType, sortBy, sortDirection)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeldDevicesListTable);