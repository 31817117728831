 import { axiosInstance } from './api';
 // import axios from 'axios';
/* Cirrus Admin-Service /locations Controller */
const HELD_DEVICES_ENDPOINT = 'admin-service/held/org';
const HELD_DEVICE_ENDPOINT = 'admin-service/held';
const COMPANY_ENDPOINT = 'admin-service/company';
export default class HeldDevicesAPI {
    static getHeldDevicesByCompanyId(companyId, page, pageSize, searchTerm, sortBy, direction) 
    {   
         return axiosInstance.get(`${HELD_DEVICES_ENDPOINT}/${companyId}`, {
             params: {
                page,
                pageSize,
                searchTerm,
                type: 'HELD', 
                sortBy,
                direction
            }
         })
    }

    static deleteHeldDevice(id) {
        return axiosInstance.delete(`${HELD_DEVICE_ENDPOINT}/${id}`);
    }

    static getHeldData(companyId) {
        return axiosInstance.get(`${COMPANY_ENDPOINT}/${companyId}/secret`);
    }
}