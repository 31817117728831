import React, { useState } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import CompanyFeaturesApi from '../../../apis/companyFeaturesApi';
import { createStructuredSelector } from "reselect";
import { selectUserCompany } from '../../../reducers/auth';
import { actions as OrganizationManagementActions } from '../../../reducers/organizationManagement';
import { types as AppTypes, selectMyE911LastKnownLocationEnabled } from '../../../reducers/app';
import { connect } from "react-redux";
import Switch from '@material-ui/core/Switch';
import Info from '@material-ui/icons/Info';
import { Tooltip } from "@material-ui/core";

import Grid from '@material-ui/core/Grid';

function MyE911DisableInformationalNotificationsFeature(props){

    const [submitStatus, setSubmitStatus] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const company = props.company.toJS();

    const handleChange = () => {
        setDataLoading(true);
        (props.myE911LastKnownLocationEnabled ? 
            CompanyFeaturesApi.removeCompanyFeatures(company.id, 'MYE911_DISABLE_INFORMATIONAL_NOTIFICATIONS') :
            CompanyFeaturesApi.addCompanyFeatures(company.id, 'MYE911_DISABLE_INFORMATIONAL_NOTIFICATIONS')
        ).then(response => {
            setSubmitStatus('success');
            setDataLoading(false);
            props.getOrganizationFeatures();
        }).catch(error => {
            console.log(error)
            setSubmitStatus('failure');
            setDataLoading(false);
        });
    }

    return (
        <div>
           {dataLoading && <LinearProgress/>}
           <Grid container justify="center" direction="row" alignItems="center" style={{margin: '20px'}}>
               {dataLoading && <LinearProgress/>}
               <Tooltip 
                       title="Control whether Device Users will receive informational notifications from either Windows or mac OS MyE911 Clients."
                       placement="left">
                       <span style={{ color: 'black', padding: 15 }} tabIndex={props.disableInput ? 0 : -1}><Info /></span>
                   </Tooltip>
               <Grid item xs={12} sm={3}>
                   <label htmlFor="mye911LastKnownLocationSwitch">Disable Informational Notifications</label>
               </Grid>
               <Grid item xs={12} sm={1}>
                   <Switch
                       id="mye911LastKnownLocationSwitch"
                       name="mye911LastKnownLocationSwitch"
                       color="primary"
                       checked={props.myE911LastKnownLocationEnabled}
                       onChange={handleChange}
                       disabled={dataLoading || !props.disableInput}/>
               </Grid>
           </Grid>
           {submitStatus === 'failure' && 
           <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 680 }}>
               <ErrorOutlineIcon style={{ color: '#f4473a', marginRight: 10, fontSize: 30 }}/>
               <div style={{ color: '#f4473a' }}>ERROR! We are not able to save your preference at this time. Please try again later.</div>
           </div>
           }
       </div>
   );
    
}

const mapStateToProps = () => createStructuredSelector({
    company: selectUserCompany(),
    myE911LastKnownLocationEnabled: selectMyE911LastKnownLocationEnabled()
});

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationFeatures: () => dispatch(OrganizationManagementActions.getOrganizationFeatures())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MyE911DisableInformationalNotificationsFeature);