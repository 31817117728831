import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Info from '@material-ui/icons/Info';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import { connect } from 'react-redux';

import { selectUserCompany } from '../../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import { actions, makeSelectMultipleAddressList, selectAddressIsAmbiguous} from '../../../../reducers/locations';
import { makeSelectMultipleAddressListNetworkDiscovery } from '../../../../reducers/networkDiscovery';
import GoogleMapsComponent from '../../../../components/GoogleMapsComponent/GoogleMapsComponent';
import GeoCoordsComponent from '../../../../components/GeoCoordsComponent/GeoCoordsComponent';

function SimpleTreeTypeFormCER(props){
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        modalLoading,
        handleOnChange,
        overrideOrganizationNameToggle,
        setOverrideOrganizationNameToggle,
        locationAlternateIdEnabled,
        multipleAddressesNetworkDiscovery,
        phoneNumberToggle,
        setFieldValue,
        setFieldTouched,
        setPhoneNumberToggle,
        setErrors,
        setTouched,
        modalErrorOptions,
        geoCordsAreRequired,
        multipleAddresses,
        classes } = props;

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    const handleLatChange = (values) => {
        if (values.value) {
            props.setFieldValue('latitude', values.value);
        } else {
            props.setFieldValue('latitude', '');
        }
        props.setFieldTouched('latitude', true);
    }
    
    const handleLngChange = (values) => {
        if (values.value) {
            props.setFieldValue('longitude', values.value);
        } else {
            props.setFieldValue('longitude', '');
        }
        props.setFieldTouched('longitude', true);
    }

    function handleOverrideOrganizationNameToggle() {
        setOverrideOrganizationNameToggle(!overrideOrganizationNameToggle);
    }

    function handleLocationAlternateIdRadioButtonChange(e) {
        setPhoneNumberToggle(e.target.value);
        // reset form data on toggle
        setErrors({});
        setTouched({});
    }

    function handleRadioChange(event) {
        setFieldValue('multipleAddressSelect', event.target.value);
        setFieldTouched('multipleAddressSelect', true);
    }
    function removeDuplicateValues(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    return <form style={{ display: 'flex', flexDirection: 'column' }} className="simple-tree-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
        {modalLoading && <LinearProgress/>}
        <TextField
            id="name"
            label="*Location Name"
            name="name"
            type="name"
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
            disabled={true}
            fullWidth={true}
            error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/> 
            <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
        { (!locationAlternateIdEnabled || (locationAlternateIdEnabled && phoneNumberToggle === "PHONE_NUMBER")) &&
            <div>
                <NumberFormat 
                    autoComplete="no"
                    id="phoneNumber"
                    label="Phone Number"
                    name="phoneNumber"
                    type="text"
                    onValueChange={handleOnChange}
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    disabled={true}
                    className={classes.field}
                    fullWidth={true}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    format="(###) ###-####" 
                    customInput={TextField}
                    mask="_"/> 
                    <span id="phoneNumber-helper-text" className="error-prompt">{touched.phoneNumber ? errors.phoneNumber : ""}</span>
                <br/>
            </div>
        }
    
        {props.isAmbiguous  ? 
            <RadioGroup
                aria-label="addresses"
                name="addresses"
                className={classes.group}
                onChange={handleRadioChange}
            >
                {multipleAddressesNetworkDiscovery.length > 0 ?
                    removeDuplicateValues(multipleAddressesNetworkDiscovery, 'normalizedAddress').map((address, index) => {
                        if (address.normalizedAddress) {
                            return <FormControlLabel key={index} value={JSON.stringify(address)} control={<Radio key={index} color="primary" />} label={address.normalizedAddress} />
                        } else {
                            return null;
                        }
                    })
                :
                    removeDuplicateValues(multipleAddresses, 'normalizedAddress').map((address, index) => {
                        if (address.normalizedAddress) {
                        return <FormControlLabel key={index} value={JSON.stringify(address)} control={<Radio color="primary" />} label={address.normalizedAddress} />
                    } else {
                        return null;
                    }
                    })
                }
                <span>{ props.values.multipleAddressSelect === "" ? 
                    <div id="name-helper-text" className="error-prompt">{ multipleAddresses.length > 1 ?
                    'Multiple addresses found. Please select the appropriate one in order to proceed.' :
                    'We found this address. Please select it in order to proceed.'}
                    </div> : <div> </div> }
                </span>
            </RadioGroup>
            :

           <div>
                 <TextField
                    id="address"
                    label="*Address"
                    name="address"
                    autoComplete="no"
                    value={values.address}
                    disabled ={true}
                    style={{ width: '100%' }}/>              
            </div>
        }
            <br/>
                {/* <GeoCoordsComponent 
                        geoCordsAreRequired={geoCordsAreRequired} 
                        values={values}
                        disabled ={true}
                        errors={errors}
                        touched={touched}
                        modalLoading={modalLoading}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        {...props}
                    /> */}
                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', maxWidth: 630 }}>
            <div style={{ marginRight: 20 }}>
                <NumberFormat 
                    autoComplete="no"
                    id="latitude"
                    label={geoCordsAreRequired ? 'Latitude' : '*Latitude'}
                    name="latitude"
                    type="text"
                    onValueChange={handleLatChange}
                    value={values.latitude}
                    onBlur={handleBlur}
                    disabled={true}
                    fullWidth={true}
                    error={touched.latitude && Boolean(errors.latitude)}
                    customInput={TextField}
                    /> 
                    <span id="latitude-helper-text" className="error-prompt">{touched.latitude ? errors.latitude : ""}</span>
            </div>
            <div>
                <NumberFormat 
                    autoComplete="no"
                    id="longitude"
                    label={geoCordsAreRequired ? 'Longitude' : '*Longitude'}
                    name="longitude"
                    type="text"
                    onValueChange={handleLngChange}
                    value={values.longitude}
                    onBlur={handleBlur}
                    disabled={true}
                    fullWidth={true}
                    error={touched.longitude && Boolean(errors.longitude)}
                    customInput={TextField}
                    /> 
                    <span id="longitude-helper-text" className="error-prompt">{touched.longitude ? errors.longitude : ""}</span>
            </div>
        </div>

                    <br/>   
            <br/>
            <GoogleMapsComponent values={values} />
            <br/>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Tooltip style={{ position: 'static', zIndex: 1, marginLeft: 'auto', marginBottom: -50 }} title="Room/Floor/Apt/Ste" classes={{
                    tooltip: classes.tooltip
            }}>
                <span className={classes.icon} tabIndex={0}><Info /></span>
            </Tooltip> 
            <br/>
            <TextField
                id="info"
                label={props.company.toJS().requireLocationInfo ? "*Location Information" : "Location Information"}
                name="info"
                type="info"
                onChange={handleChange}
                className={classes.info}
                value={values.info ? values.info : ''}
                onBlur={handleBlur}
                disabled={true}
                fullWidth={true}
                />
                <span id="info-helper-text" className="error-prompt">{(touched.info && Boolean(errors.info)) || (errors.info && Boolean(errors.info.includes('max'))) ? errors.info : ''}</span>
        </div>


        <Grid container direction="row" justify="flex-start" alignItems="center">
            <label htmlFor="overrideOrganizationNameToggle">Override Organization Name</label>
            <Switch
                id="overrideOrganizationNameToggle"
                name="overrideOrganizationNameToggle" /* important! this is how handleChange finds the field to update */
                color="primary"
                checked={overrideOrganizationNameToggle}
                onChange={handleOverrideOrganizationNameToggle}
                value='overrideOrganizationNameToggle'
                disabled={modalLoading}/> 
        </Grid>       
        {
            overrideOrganizationNameToggle && 
            <div>
                <TextField
                    id="overrideOrganizationName"
                    label="Organization Name"
                    name="overrideOrganizationName"
                    onChange={handleChange}
                    value={values.overrideOrganizationName}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={(touched.overrideOrganizationName && Boolean(errors.overrideOrganizationName)) || (errors.overrideOrganizationName && Boolean(errors.overrideOrganizationName.includes('max')))}/>
                <span id="overrideOrganizationName-helper-text" className="error-prompt">{(touched.overrideOrganizationName && Boolean(errors.overrideOrganizationName)) || (errors.overrideOrganizationName && Boolean(errors.overrideOrganizationName.includes('max'))) ? errors.overrideOrganizationName : ''}</span>
            </div>
        }
        
        <Grid container direction="column" justify="center" alignItems="center">
            <Button id="saveBtn"
                    type="submit" 
                    disabled={modalLoading} 
                    className = {classes.btn}
                    onClick={handleScroll}
                    color="primary" 
                    variant="contained">Save</Button>
        </Grid>
    </form>
}

const mapStateToProps = () => createStructuredSelector({
    company: selectUserCompany(),
    multipleAddresses: makeSelectMultipleAddressList(),
    multipleAddressesNetworkDiscovery: makeSelectMultipleAddressListNetworkDiscovery(),
    isAmbiguous: selectAddressIsAmbiguous(),
});

const mapDispatchToProps = dispatch => {
    return {
        addressIsAlternate: (flag) => dispatch(actions.addressIsAlternate(flag))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleTreeTypeFormCER);