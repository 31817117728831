import React, { useEffect, useState } from 'react';
import Select from 'react-select';

function DistListSyncSelect(props) {
  return (
    <Select
        placeholder=""
        className={props.error ? 'single-select error' : 'single-select'}
        inputId={props.id}
        isDisabled={false}
        value={props.value}
        onChange={props.onChange}
        closeMenuOnSelect={false}
        options={props.options}
        isMulti={props.isMulti ? props.isMulti : false}
        theme={(theme) => ({
          ...theme,
          colors: {
          ...theme.colors,
            primary25: 'rgba(0, 0, 0, 0.1)',
            primary: 'rgba(0, 0, 0, 0.2)',
          },
        })}  
    />
  );
}

export default DistListSyncSelect;