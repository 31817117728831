import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HasAccess from '../../components/HasAccess/HasAccess';

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSagas';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';

import reducer, { types as deviceUsersTypes } from '../../reducers/deviceUsers';
import saga from '../../sagas/deviceUsers';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as AppActions, selectPageSize } from '../../reducers/app';

import DeviceUsersTable from '../../containers/DeviceUsersTable/DeviceUsersTable';
import { actions as DeviceUserActions } from '../../reducers/deviceUsers';
import { createStructuredSelector } from 'reselect';
import { debounce } from 'lodash';

const initialSortColumns = [
    {
        id: 'email',
        desc: false
    }
];

function DeviceUsersPage(props) {
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    
    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const isMatch = searchFieldRegex(inputValue);

        if (isMatch) {
            setSearchValue(inputValue);
            searchApiCall(
                inputValue, 
                props.pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC'    
            );
        }
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, direction) => {
            if (inputValue.length > 0) {
                setPage(0);
                props.fetchDeviceUsersList(1, pageSize, inputValue, sortBy, direction);
            } else if(inputValue.length === 0){
                setPage(0);
                props.fetchDeviceUsersList(1, pageSize, '', sortBy, direction)
            }
        }, 500)
    , []);


    function modalHandleOpen() {
        props.setCurrentModal(deviceUsersTypes.ADD_DEVICE_USER);
    }

    useEffect(() => {
        props.setPageTitle('Users');
        props.setPageTitleId('users-header');
    }, []);

    return (
        <div>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    <SearchField 
                        value={searchValue}
                        onChange={handleInputSearchChange}
                    />
                </Grid>
                <Grid item> 
                    <HasAccess
                        permissions={["ADD_DEVICE_USERS"]}
                    >
                        {   searchValue.length === 0 &&
                            <Button className="button" id="add-user-button" variant="contained" color="primary" onClick={modalHandleOpen}>
                                Add User
                            </Button>
                        }
                    </HasAccess>
                </Grid>
            </Grid>
            <br/>
            <DeviceUsersTable   
                                sortColumns={sortColumns}
                                setSortColumns={setSortColumns}
                                currentSortColumn={currentSortColumn}
                                setCurrentSortColumn={setCurrentSortColumn}
                                setCurrentModal={props.setCurrentModal}
                                searchValue={searchValue}
                                fetchDeviceUsersList={props.fetchDeviceUsersList}
                                page={page}
                                setPage={setPage}
                                pageSize={props.pageSize} />
        </div>
    );
}

const mapStateToProps = () => createStructuredSelector({
    pageSize: selectPageSize()
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchDeviceUsersList: (page, pageSize, searchTerm, sortBy, direction) => dispatch(DeviceUserActions.getDeviceUsersRequest(page, pageSize, searchTerm, sortBy, direction)),
        setPageTitle: data => dispatch(AppActions.setPageTitle(data)),
        setPageTitleId: data => dispatch(AppActions.setPageTitleId(data))
    }
};

const withReducer = injectReducer({ key: 'deviceUsers', reducer });
const withSaga = injectSaga({ key: 'deviceUsers', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect
)(DeviceUsersPage);