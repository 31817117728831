import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import { createStructuredSelector } from "reselect";
import { connect } from 'react-redux';
import { actions as AppActions, orgHasLicense } from '../../reducers/app';
import { currentCompanyOrgType, orgTypes } from '../../reducers/auth';
import { types } from '../../reducers/callHistory';
import HasAccess from '../../components/HasAccess/HasAccess';


class CallHistoryCallRecordingBox extends React.Component {
  handleClick = event => {
    this.props.setCurrentModal(types.CALL_HISTORY_RECORDING, this.props.row.original);
  };

  render() {
    return (
      <div>
        {this.props.row.original ? 
          <div>
            <HasAccess
                        permissions={["VIEW_CALL_RECORDINGS"]}
                    >
            <Button
              id={`call-history-recording-box-button-${this.props.row.index}`}
              style={{ minWidth: 40 }}
              aria-haspopup="true"
              color="primary"
              onClick={this.handleClick}
              title="Call Recording"
              disabled={this.props.currentCompanyOrgType === orgTypes.CUSTOMER && !this.props.orgHasLicense}
            >
                <PlayCircleOutline style={{
                  fill: this.props.currentCompanyOrgType !== orgTypes.CUSTOMER || this.props.orgHasLicense ? '' : 'grey'
                }}/>
            </Button>
            </HasAccess>
           </div> : '' }
      </div>
    );
  }
}

CallHistoryCallRecordingBox.propTypes = {
  callHistory: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  orgHasLicense: orgHasLicense(),
  currentCompanyOrgType: currentCompanyOrgType()
});

const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CallHistoryCallRecordingBox);