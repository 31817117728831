import * as Yup from "yup";

export function validate(buildValidationSchema) {
	return (values) => {
		const validationSchema = buildValidationSchema(values);

		try {
			validationSchema.validateSync(values, { abortEarly: false });
			return {};
		} catch (error) {
			return getErrorsFromValidationError(error)
		}
	}
}

export function validateEdit(buildEditValidationSchema) {
	return (values) => {
		const validationSchema = buildEditValidationSchema(values);

		try {
			validationSchema.validateSync(values, { abortEarly: false });
			return {};
		} catch (error) {
			return getErrorsFromValidationError(error)
		}
	}
}

export function buildValidationSchema(values) {
	return Yup.object({
		"email": Yup.string("Enter a valid Email")
			.trim()
			.email("Email is invalid")
			.required("Email is a required field.")
			.max(100, "Email has a max limit of 100 characters."),
		"roleName": Yup.string()
			.required('User Role is required'),
		"firstName": Yup.string()
			.trim()
			.max(64, "First Name has a max limit of 64 characters."),
		"lastName": Yup.string()
			.trim()
			.max(64, "Last Name has a max limit 64 characters."),
		});
}

export function buildEditValidationSchema(values) {
	return Yup.object({
		"email": Yup.string("Enter a valid Email")
			.trim()
			.email("Email is invalid")
			.required("Email is a required field.")
			.max(100, "Email has a max limit of 100 characters."),
		"firstName": Yup.string()
			.trim()
			.max(64, "First Name has a max limit of 64 characters."),
		"lastName": Yup.string()
			.trim()
			.max(64, "Last Name has a max limit of 64 characters."),
		});
}

function getErrorsFromValidationError(validationError) {
	const FIRST_ERROR = 0;

	return validationError.inner.reduce((errors, error) => {
		return {
			...errors,
			[error.path]: error.errors[FIRST_ERROR],
		}
	}, {});
}
