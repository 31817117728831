/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 */
import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';

export const PIDFLO_DEVICES = 'PIDFLO_DEVICES/'; 
export const types = {
    ADD_PIDFLO_DEVICES: PIDFLO_DEVICES + 'ADD_PIDFLO_DEVICES',
    GET_PIDFLO_DEVICES_REQUEST: PIDFLO_DEVICES + 'GET_PIDFLO_DEVICES_REQUEST',
    GET_PIDFLO_DEVICES_ERROR: PIDFLO_DEVICES + 'GET_PIDFLO_DEVICES_ERROR',
    GET_PIDFLO_DEVICES_SUCCESS: PIDFLO_DEVICES + 'GET_PIDFLO_DEVICES_SUCCESS',
    EDIT_PIDFLO_DEVICES: PIDFLO_DEVICES + 'EDIT_PIDFLO_DEVICES',
    DELETE_PIDFLO_DEVICES: PIDFLO_DEVICES + 'DELETE_PIDFLO_DEVICES',
};

export const initialState = fromJS({
    pidfloDevicesList: List(),
    pidfloDevicesLoading: false,
    pidfloDevicesListPageCount: -1
});

export default (state = initialState, action) => {
	switch (action.type) {
        case types.GET_PIDFLO_DEVICES_REQUEST:
            return state.set('pidfloDevicesLoading', true);
        case types.GET_PIDFLO_DEVICES_ERROR:
            return state.set('pidfloDevicesLoading', false);
        case types.GET_PIDFLO_DEVICES_SUCCESS:
            return state.set('pidfloDevicesLoading', false)
                        .set('pidfloDevicesList', List(action.data))
                        .set('pidfloDevicesListPageCount', action.pageCount);
        default:
			return state;
	}
}

// Action Creators
export const actions = {
    addPidfloDevicesRequest: (pidfloDevices) => ({ 
        type: types.ADD_PIDFLO_DEVICES, 
        data: pidfloDevices
    }),
    editPidfloDevicesRequest: (pidfloDevices) => ({
        type: types.EDIT_PIDFLO_DEVICES,
        data: pidfloDevices
    }),
    deletePidfloDevicesRequest: (id) => ({
        type: types.DELETE_PIDFLO_DEVICES,
        data: id
    }),
    getPidfloDevicesRequest: (page, pageSize, searchTerm, sortBy, direction) => ({
        type: types.GET_PIDFLO_DEVICES_REQUEST,
        page, 
        pageSize,
        searchTerm,
        sortBy,
        direction
    }),
    getPidfloDevicesError: () => ({
        type: types.GET_PIDFLO_DEVICES_ERROR
    }),
    getPidfloDevicesSuccess: (pidfloDevicesList, pageCount) => ({
        type: types.GET_PIDFLO_DEVICES_SUCCESS,
        data: pidfloDevicesList,
        pageCount
    })
};

// Selectors
const selectPidfloDevices = state => state.get('pidfloDevices', initialState);

export const makeSelectPidfloDevicesList = () =>
    createSelector(selectPidfloDevices, pidfloDevicesState => pidfloDevicesState.get('pidfloDevicesList'));

export const makeSelectPidfloDevicesListLoading = () =>
    createSelector(selectPidfloDevices, pidfloDevicesState => pidfloDevicesState.get('pidfloDevicesLoading'));

export const makeSelectPidfloDevicesPageCount = () =>
createSelector(selectPidfloDevices, pidfloDevicesState => pidfloDevicesState.get('pidfloDevicesListPageCount'));