import React, { useEffect, useState } from 'react';

import DownloadsListTable from '../../containers/DownloadsListTable/downloadsListTable';
import DownloadsAPI from '../../apis/downloadsApi';
import { source } from '../../apis/api';

export default function DownloadsList() {
    const [downloads, setDownloads] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        DownloadsAPI.getDownloads().then(response => {
            setLoading(false);
            setDownloads(response.data);
        }).catch(error => {});

        return () => {
            source.cancel();
        }
    },[]);

    return <DownloadsListTable downloads={downloads} loading={loading}/>
}