import { axiosInstance } from './api';
/* Cirrus Admin-Service /company Controller */
const LOGIN_ENDPOINT = 'admin-service/login/';
const FORGOT_ENDPOINT = 'auth-service/login/forgot/';
const RESET_ENDPOINT = 'auth-service/login/reset/';

export default class LoginAPI {

	static login(credentials) {
        const payload = {
            username: credentials.email,
            password: credentials.password
        }
		return axiosInstance.post(LOGIN_ENDPOINT, payload).then(response => response.data);
	}

    static forgotEndpoint(email) {
        return axiosInstance.post(FORGOT_ENDPOINT, email);
    }

    static resetEndpoint(resetData) {
        return axiosInstance.post(RESET_ENDPOINT, resetData);
    }
}
