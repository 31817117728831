import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import PidfloDevicesAPI from '../apis/pidfloDevicesApi';
import CompanyAPI  from '../apis/companyApi'; 
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as PidfloDevicesActions,
		 types as PidfloDevicesTypes } from '../reducers/pidfloDevices';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';

function* getPidfloDevicesList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(PidfloDevicesAPI.getPidfloDevicesById, companyId, action.page, action.pageSize, action.sortBy, action.direction, action.searchTerm);
		
		yield put(PidfloDevicesActions.getPidfloDevicesSuccess(response.data, response.headers['x-pagination-count']));
	} catch (error) {
		yield put(PidfloDevicesActions.getPidfloDevicesError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addPidfloDevices(action) {
	try {
		const tableProps = yield select(selectTableProps());
		
		const companyId = yield select(userCompanyId());
		yield put(AppActions.setModalLoading());
		yield call(PidfloDevicesAPI.addPidfloDevices, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(PidfloDevicesActions.getPidfloDevicesRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editPidfloDevices(action) {
	try {
		const tableProps = yield select(selectTableProps());

		const companyId = yield select(userCompanyId());
		yield put(AppActions.setModalLoading());
		yield call(PidfloDevicesAPI.editPidfloDevices, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(PidfloDevicesActions.getPidfloDevicesRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deletePidfloDevices(action) {
	try {
		const tableProps = yield select(selectTableProps());

		const companyId = yield select(userCompanyId());
		yield put(AppActions.setModalLoading());
		yield call(PidfloDevicesAPI.deletePidfloDevices, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(PidfloDevicesActions.getPidfloDevicesRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootPidfloDevicesSaga() {
	yield all([
		yield takeLatest(PidfloDevicesTypes.GET_PIDFLO_DEVICES_REQUEST, getPidfloDevicesList),
		yield takeLatest(PidfloDevicesTypes.ADD_PIDFLO_DEVICES, addPidfloDevices),
		yield takeLatest(PidfloDevicesTypes.EDIT_PIDFLO_DEVICES, editPidfloDevices),
		yield takeLatest(PidfloDevicesTypes.DELETE_PIDFLO_DEVICES, deletePidfloDevices)
	]);
}

export default rootPidfloDevicesSaga;
